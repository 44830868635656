export enum OrderType {
  Combine = 'combine',
  DS = 'ds',
  Sample = 'sample',
  PrivateInv = 'pi',
}

export enum OrderStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
  PartiallyShipped = 'partially_shipped',
  Processing = 'processing',
  Cancelled = 'cancelled',
  PendingProcessing = 'pending_processing',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Success = 'success',
  Pending = 'pending',
  Open = 'open',
  Error = 'error',
  Failure = 'failure',
  Partial = 'partial',
}

export enum OrderStatusTag {
  Default,
  Danger,
  Success,
}

export type ShippingAddress = {
  address1: string;
  address2: string;
  city: string;
  company: string;
  country: string;
  country_code: string;
  first_name: string;
  last_name: string;
  latitude: string;
  longitude: string;
  name: string;
  phone: string;
  province: string;
  province_code: string;
  zip: string;
  id?: number;
  customer_id?: number;
};

type preorderItem = {
  preorder?: string;
  variant_ids?: string[];
};

export type LineItem = {
  available_quantity?: number;
  fulfillment_status?: string;
  gift_card?: string;
  grams?: number;
  id: string;
  image_id?: number;
  name: string;
  order_id?: string;
  price?: number;
  product_id?: string;
  properties?: string;
  qty?: number;
  requires_shipping?: number;
  shopify_id?: string;
  shopify_product_id?: string;
  shopify_variant_id?: string | number;
  sku?: string;
  title?: string;
  item_discounts?: string;
  try_id?: string;
  variant_id?: string;
  variant_title?: string;
  image?: {
    id?: string;
    src?: string;
  };
  preorder?: preorderItem[];
};

export type CombinedOrder = {
  id: string;
  items_count: number;
  name: string;
  paid_at: string;
  shop_order_name: string;
  shop_order_shopify_id: string;
  total_items_price: string | number;
  total_shipping_price: string;
  total_items_discounts?: number | string;
  total_tax: number;
};

export type FulfillmentItem = LineItem & {
  qty: number;
  shopify_id: number | string;
  shopify_variant_id: number | string;
  sku: string;
  variant_id: string;
};

export type Fulfillment = {
  address?: string;
  notify_customer?: string;
  oversea_tracking_company?: string;
  oversea_tracking_number?: string;
  oversea_tracking_url?: string;
  shipment_status?: string;
  shopify_order_id?: string;
  status: string;
  name?: string;
  tracking_company?: string;
  tracking_number?: string;
  tracking_url?: string;
  items: FulfillmentItem[];
  id: string;
  order_id: string;
};

export type OrderItem = {
  last_error_msg?: string;
  last_error_type?: string;
  cart_id?: string;
  checkout_token?: string;
  closed_at?: string;
  combined_orders?: CombinedOrder[];
  contact_email?: string;
  contact_phone?: string;
  contact_phone_country?: string;
  created_at?: string;
  customer_id?: string;
  discount_code?: string;
  discount_codes?: string;
  financial_status?: string;
  fulfillment_status?: string;
  fulfillments?: Fulfillment[];
  id?: string;
  line_items?: LineItem[];
  name?: string;
  note?: string;
  note_attributes?: any[];
  order_number?: number;
  order_type?: OrderType;
  paid_at?: string;
  parent_order_id?: string;
  payment_method?: string;
  processed_at?: string;
  shipping_address?: ShippingAddress;
  shipping_method?: string;
  shop_id?: string;
  shop_order_id?: string;
  shop_order_shopify_id?: string;
  shop_order_name?: string;
  shop_order_number?: string;
  shop_processed_at?: string;
  shop_shopify_order_number?: string;
  shopify_created_at?: string;
  shopify_id?: string;
  shopify_order_number?: string;
  status?: OrderStatus;
  total_items_subtotal?: number;
  test?: string;
  total_items_discounts?: number | string;
  total_items_price?: number | string;
  total_price?: number;
  total_shipping_price?: number;
  total_tax?: number;
  total_weight?: number;
  updated_at?: string;
  shipping_address_route_type?: string;
  shop_order?: {
    shopify_id?: string;
    shop_id?: string;
  };
  total_paid?: number;
  paid_by_credit?: number;
  paid_by_card?: number;
  total_refunded?: number;
  refunded_by_credit?: number;
  refunded_by_card?: number;
  credit_reward_retracted?: number;
  credit_reward_issued?: number;
  items_count?: number;
  total_items_refunded?: number;
  total_shipping_refunded?: number;
  total_items_shall_refund?: number;
  total_shipping_shall_refund?: number;
  total_shipping_discounts?: number;
  total_shipping_subtotal?: number;
  total_shipping_price_preorder?: number;
  shipping_options?: { method: string; price: number; title: string; body_text: string }[];
};

type CountK = keyof OrderStatus;
type Count = {
  [key in CountK]: number;
};

export { Count };
