import { useInfiniteQuery } from 'react-query';

import { getSellerCollections } from '@/services/ds';

export default function useSellerCollections(params: any, enabled = true) {
  return useInfiniteQuery(
    ['sellerCollections', 'getSellerCollections', params],
    ({ pageParam = 1 }) => getSellerCollections({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
    }
  );
}
