import { Input, message } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { ElementRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modal';
import SellerCollectionTitleEditDialog from '@/components/SellerCollectionTitleEditDialog';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import { useDebounce, useDesktop } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import { useLastAddedSellerCollections, useSellerCollectionData } from '@/hooks/biz';
import useProduct from '@/hooks/queries/useProduct';
import usePutSellerCollection from '@/hooks/queries/usePutSellerCollections';
import useSellerCollectionByCollection from '@/hooks/queries/useSellerCollectionByCollection';
import useUpdateProductSellerCollection from '@/hooks/queries/useUpdateProductSellerCollection';
import { ERROR_CODES } from '@/services/constant';

import AddedToBoardNotification from '../AddedToBoardNotification';
import CollectionListItem from './CollectionListItem';
import css from './index.module.scss';

type Product = {
  product_id: string;
  cover: string;
  product_count: number;
  [key: string]: any;
};

type Config = {
  isDefaultSelected?: boolean;
  products?: Product[];
  collectionHandle?: string;
  showEdit?: boolean;
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
};

type CompHandle = {
  show: (config?: Config) => void;
};
type AddedToBoardNotificationHandle = ElementRef<typeof AddedToBoardNotification>;

const App = forwardRef<CompHandle>((props, ref) => {
  // common
  const { user } = useAuth();
  const isDesktop = useDesktop();
  const addedToBoardNotificationRef = useRef<AddedToBoardNotificationHandle>(null);

  // component ref
  const configRef = useRef<Config>({});
  // component state
  const [keyword, setKeyword] = useState('');
  const [curSC, setCurSC] = useState([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [innerProducts, setProducts] = useState([]);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [innerIsDefaultSelected, setIsDefaultSelected] = useState(false);
  const [innerShowEdit, setShowEdit] = useState(true);

  const putSC = usePutSellerCollection();
  const updateProductSellerCollection = useUpdateProductSellerCollection();
  const addSellerCollectionByCollection = useSellerCollectionByCollection();
  const debouncedKeyword = useDebounce(keyword, 200);
  const { sellerCollections, data: sellerCollectionsData } = useSellerCollectionData({ debouncedKeyword });
  const { update: updateLastAddedSellerCollections } = useLastAddedSellerCollections(sellerCollectionsData);
  const { isLoading } = sellerCollections;
  const productIds = useMemo(() => innerProducts.map((i) => i.id), [innerProducts]);
  const productProductIds = useMemo(() => _.uniq(innerProducts.map((i) => i.product_id)), [innerProducts]);
  const productsCount = useMemo(() => productProductIds.length, [productProductIds]);
  let buttonExtraText = 'product(s)';
  if (productsCount > 1) buttonExtraText = `${productsCount} products`;
  if (productsCount === 1) buttonExtraText = `1 product`;
  const isInEditMode = innerProducts?.length === 1 && innerIsDefaultSelected;
  const editableProduct = useMemo(() => innerProducts?.[0], [innerProducts]);
  const useProductResp = useProduct(editableProduct?.id, isInEditMode);
  const { data: productData } = useMemo(() => useProductResp, [useProductResp]);
  const productFavCollectionIds = useMemo(
    () => productData?.data?.rel?.fav_collection_ids || [],
    [productData?.data?.rel?.fav_collection_ids]
  );
  const createNewSuggestName = _.trim(debouncedKeyword);
  const showCreateNewSuggest =
    sellerCollections?.isFetched && !_.isEmpty(_.trim(createNewSuggestName)) && _.isEmpty(sellerCollectionsData);
  const [recentCollectionIds, setRecentCollectionIds] = useState<(string | number)[]>([]);
  const shownRecentCollections = useMemo(
    () => _.compact(_.map(recentCollectionIds, (id) => _.find(sellerCollectionsData, ['id', id])))?.slice(0, 3),
    [recentCollectionIds, sellerCollectionsData]
  );
  const showRecentCollections = !_.isEmpty(shownRecentCollections);

  const handleCreateNewCollection = useCallback(
    (newCollection) => {
      setCreateDialogVisible(false);
      if (newCollection?.data?.id) {
        setSelectedIds([...selectedIds, newCollection.data.id]);
        setRecentCollectionIds((prevState) => _.concat(newCollection.data.id, prevState));
      }
    },
    [selectedIds]
  );

  const cancelCreateNewCollection = () => {
    setCreateDialogVisible(false);
  };

  const updateSelectedIds = (val) => {
    setSelectedIds(val);
  };

  const updateLocalStorageData = (collectionIds) => {
    if (collectionIds.length < 1) {
      return;
    }

    const userId = user.id;
    const idsFromLocalStorageStr = localStorage.getItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId);
    const idsFromLocalStorageObj = JSON.parse(idsFromLocalStorageStr);

    // update recent collections local storage
    const recentFromLocalStorageStr = localStorage.getItem(LOCAL_STORAGE_KEYS.recentCollectionIds);
    const recentFromLocalStorageObj = JSON.parse(recentFromLocalStorageStr);
    const isRecentValidated = !!recentFromLocalStorageStr && !!Object.keys(recentFromLocalStorageObj)?.length;
    let newRecentForLocalStorageObj = {};

    if (isRecentValidated) {
      newRecentForLocalStorageObj = { ...idsFromLocalStorageObj, [userId]: _.slice(recentCollectionIds, 0, 10) };
    } else {
      newRecentForLocalStorageObj = { [userId]: _.slice(recentCollectionIds, 0, 10) };
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.recentCollectionIds, JSON.stringify(newRecentForLocalStorageObj));
    // update recent collections local storage

    updateLastAddedSellerCollections(collectionIds);
  };

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSuccessCallback = async () => {
    if (configRef.current?.onSuccessCallback) {
      await configRef.current.onSuccessCallback();
    }
  };

  const updateAPIReq = useMemo(() => {
    const [add, remove] = _.partition(sellerCollectionsData, (item) => selectedIds.includes(item?.id));
    const addCollectionIds = _.map(add, (item) => item?.id);
    const removeCollectionIds = _.map(remove, (item) => item?.id);

    return {
      product_id: editableProduct?.id,
      add_collection_ids: addCollectionIds || [],
      remove_collection_ids: removeCollectionIds || [],
    };
  }, [editableProduct?.id, selectedIds, sellerCollectionsData]);
  const updateAPI = () => {
    if (_.isEmpty(updateAPIReq.add_collection_ids) && _.isEmpty(updateAPIReq.remove_collection_ids)) return;

    const removeAll = updateAPIReq.remove_collection_ids.length === sellerCollectionsData.length;
    const noChange = _.isEqual(selectedIds, productFavCollectionIds);

    if (noChange) {
      handleClose();
      return;
    }

    updateProductSellerCollection.mutate(updateAPIReq, {
      onError(e) {
        message.error((e as Error).message || 'Added Failed.');
      },
      onSuccess() {
        updateLocalStorageData(updateAPIReq.add_collection_ids);
        if (removeAll) {
          message.success('Removed from boards');
        } else {
          addedToBoardNotificationRef.current.show();
        }
        handleClose();
      },
    });
  };

  const afterSuccess = async () => {
    await handleSuccessCallback();
    handleClose();
  };
  const putAPI = () => {
    putSC.mutate(
      {
        product_ids: productIds,
        collection_ids: selectedIds,
      },
      {
        onError(e) {
          if (e?.errno === ERROR_CODES.addToBoardsFailedForDiscontinued) {
            message.warning(e?.errmsg);
            afterSuccess();
          } else {
            message.error((e as Error)?.message || 'Added Failed');
          }
        },
        onSuccess() {
          updateLocalStorageData(selectedIds);
          addedToBoardNotificationRef.current.show();
          afterSuccess();
        },
      }
    );
  };

  const updateAPIByCollectionHandle = () => {
    addSellerCollectionByCollection.mutate(
      {
        collectionHandle: configRef.current?.collectionHandle,
        sellerCollectionIds: selectedIds,
      },
      {
        onError(e) {
          if (e?.errno === ERROR_CODES.addToBoardsFailedForDiscontinued) {
            message.warning(e?.errmsg);
            afterSuccess();
          } else {
            message.error((e as Error)?.message || 'Added Failed');
          }
        },
        onSuccess() {
          updateLocalStorageData(selectedIds);
          addedToBoardNotificationRef.current.show();
          afterSuccess();
        },
      }
    );
  };

  const handleFinish = () => {
    const finishHandlerContainer = [
      { condition: () => !!configRef.current?.collectionHandle, func: updateAPIByCollectionHandle },
      { condition: () => isInEditMode, func: updateAPI },
      { condition: () => !isInEditMode, func: putAPI },
    ];

    const finishHandler = finishHandlerContainer.filter(({ condition }) => !!condition())?.[0]?.func || (() => {});
    finishHandler();
  };

  const handleRecentCollectionChange = (checked: boolean, item: any) => {
    checked && setRecentCollectionIds((prevState) => _.concat(item?.id, _.without(prevState, item?.id)));
  };

  const handleShow = (config = {}) => {
    configRef.current = config;
    setVisible(true);
  };

  // setRecentCollectionIds
  useEffect(() => {
    if (visible) {
      if (_.isEmpty(recentCollectionIds)) {
        setRecentCollectionIds(
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.recentCollectionIds) ?? '{}')?.[String(user?.id)] ??
            productFavCollectionIds ??
            []
        );
      }
    }
  }, [productFavCollectionIds, recentCollectionIds, user?.id, visible]);

  // setSelectedIds
  useEffect(() => {
    if (visible) {
      if (isInEditMode) {
        setSelectedIds(productFavCollectionIds);
      } else {
        setSelectedIds([]);
      }
    }
  }, [isInEditMode, productFavCollectionIds, visible]);

  // setCurSC
  const sortSC = useCallback((origin, sortIds) => {
    const tmp = origin.filter(({ id: originId }) => sortIds.includes(originId));
    const tmp2 = origin.filter(({ id: originId }) => !sortIds.includes(originId));
    return [...tmp, ...tmp2];
  }, []);
  useEffect(() => {
    setCurSC(sortSC(sellerCollectionsData, productFavCollectionIds));
  }, [productFavCollectionIds, sellerCollectionsData, sortSC]);

  useEffect(() => {
    if (visible) {
      const { products = [], isDefaultSelected = false, showEdit } = configRef.current;
      setKeyword('');
      setProducts(products);
      setIsDefaultSelected(isDefaultSelected);
      setShowEdit(showEdit);
    }
  }, [visible]);

  useImperativeHandle(ref, () => ({
    show: handleShow,
  }));

  const isFinishButtonLoading =
    putSC.isLoading ||
    updateProductSellerCollection.isLoading ||
    useProductResp.isLoading ||
    addSellerCollectionByCollection.isLoading;
  let isFinishButtonDisabled = false;
  if (isFinishButtonLoading) {
    isFinishButtonDisabled = true;
  } else if (!isInEditMode) {
    isFinishButtonDisabled = !selectedIds?.length;
  }
  const modalProps = {
    className: css.ns_com_modal_select_boards_main,
    placement: isDesktop ? 'topRight' : 'center',
    showMask: true,
    fullscreen: 'mobile',
    title: 'Add to Boards',
    forceRender: true,
    visible,
    footer: isLoading ? undefined : (
      <>
        {!innerIsDefaultSelected && (
          <div className={css.button_extra}>Currently adding {buttonExtraText} to boards</div>
        )}
        <Button
          loading={isFinishButtonLoading}
          color="main"
          block
          onClick={handleFinish}
          disabled={isFinishButtonDisabled}
        >
          Finish
        </Button>
      </>
    ),
    onClose: handleClose,
  };

  return (
    <>
      <Modal {...modalProps}>
        <>
          <div className={css.collection_search_wrapper}>
            <Input
              size="large"
              allowClear
              enterKeyHint="search"
              prefix={<FiSearch />}
              placeholder="Search Boards"
              value={keyword}
              onPressEnter={(e) => setKeyword(_.trim(e.currentTarget.value))}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <div className={css.create_new_collection_btn_wrapper}>
            <Button.Add
              shape="default"
              className={cn(css.create_new_collection_btn, { [css.align_left]: showCreateNewSuggest })}
              onClick={() => setCreateDialogVisible(true)}
            >
              Create new Board
              {showCreateNewSuggest ? <span> &quot;{createNewSuggestName}&quot;</span> : null}
            </Button.Add>
          </div>

          <StatusViewRenderer
            isEmpty={_.isEmpty(curSC)}
            emptyTitle="No boards found"
            isLoading={isLoading}
            hasMore={sellerCollections.hasNextPage}
            loadMore={sellerCollections.fetchNextPage}
            statusStyle={{ marginTop: 64 }}
            className={css.collection_list_wrapper}
          >
            <Checkbox.Group value={selectedIds} onChange={updateSelectedIds}>
              <div className={css.collection_list}>
                {showRecentCollections && (
                  <>
                    <div className={css.collection_list_section_title}>Recent</div>
                    {shownRecentCollections?.map((collection) => (
                      <CollectionListItem
                        key={collection?.id}
                        data={collection}
                        onChange={handleRecentCollectionChange}
                      />
                    ))}
                    <div className={css.collection_list_section_title}>All Boards</div>
                  </>
                )}
                {curSC?.map((collection) => (
                  <CollectionListItem key={collection?.id} data={collection} onChange={handleRecentCollectionChange} />
                ))}
              </div>
            </Checkbox.Group>
          </StatusViewRenderer>

          <SellerCollectionTitleEditDialog
            className={css.seller_collection_title_edit_dialog}
            visible={createDialogVisible}
            placement={isDesktop ? 'topRight' : 'center'}
            initialValues={showCreateNewSuggest ? { title: createNewSuggestName } : undefined}
            values={{ title: createNewSuggestName }}
            onOk={handleCreateNewCollection}
            onCancel={cancelCreateNewCollection}
          />
        </>
      </Modal>
      <AddedToBoardNotification
        ref={addedToBoardNotificationRef}
        product={null}
        onShowAddToBoard={() => handleShow(configRef.current)}
        showEdit={innerShowEdit}
      />
    </>
  );
});

export default App;
