.import_btn {
  position: relative;
  padding: 0;
  width: 26px;
  height: 26px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border: none;

  // extend button touchable area
  &::after {
    content: '';
    position: absolute;
    top: -12px;
    left: -12px;
    right: -8px;
    bottom: -8px;
    pointer-events: auto;
  }

  :global {
    .button__icon {
      font-size: var(--font-size2);
      line-height: 0;
    }
  }

  &:global(.import-button--importing) {
    :global {
      .adm-loading {
        font-size: 10px;
      }
    }
  }
}

@at-root .is_pc {
  @media (hover: hover) {
    .import_btn:not(:global(.import-button--importing)) {
      transform: translateY(200%);
      transition: all 0.25s ease;
    }

    &:hover .import_btn {
      transform: translateY(0);
    }
  }
}
