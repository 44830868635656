.ns_com_address_dialog_edit_view_form {
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.4;
  :global {
    .ant-form-item {
      margin-bottom: var(--spacing-l);

      &.ant-form-item-has-error .ant-form-item-label > label {
        color: var(--color-danger);
      }
    }
    .ant-form-item-label {
      padding: 0 0 var(--spacing-s);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 500;

      & > label {
        height: auto;
      }
    }
    .ant-form-item-required::before {
      order: 1;
      margin-right: 0;
      margin-left: var(--spacing-xs);
    }

    .ant-divider-horizontal {
      margin: var(--spacing-l) 0;
      border-color: #e8e8e8;
    }

    .ant-form-item-explain {
      display: none;
    }
  }
  .input {
    border-color: var(--color-gray4);
    :global {
      .ant-select-selector.ant-select-selector {
        border-color: var(--color-gray4);
      }
    }
    // TODO: 注意这里需要同时改造phoneInput
    &:global(.phone-input) {
      border-width: 1px;
      border-color: var(--color-gray4);
      border-radius: 2px;
      box-shadow: none;
      :global {
        .phone-input__input {
          height: 40px;
          font-size: var(--font-size3);
          border-radius: 2px;
        }

        .selected-flag {
          :is(.iti-flag, .selected-dial-code) {
            font-size: var(--font-size3);
          }
        }
      }
    }
    &.zip_code {
      text-transform: uppercase;
    }
  }
  .input_group2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: end stretch;
    gap: var(--spacing-l);
    min-width: 0;
    max-width: 100%;

    &.last_showed_form_item_wrapper {
      > :global(.ant-form-item) {
        margin-bottom: 0 !important;
      }
    }

    & > :global(.ant-form-item) {
      width: 100%;
      max-width: 100%;
      min-width: 0 !important;
    }
    :global {
      .ant-select {
        max-width: 100%;
      }
    }
  }
}
