import { useMutation, useQueryClient } from 'react-query';

import { deleteShopProduct } from '@/services/ds';

export default function useDeleteShopProducts(shopId) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ shopProductId }: { shopProductId: number | string }) => deleteShopProduct(shopId, shopProductId),
    {
      onSuccess() {
        queryClient.invalidateQueries(['store', 'getStoreImportedProducts', shopId]);
      },
    }
  );
}
