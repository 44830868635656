import cn from 'classnames';

import css from './Brand.module.scss';

type Props = {
  brand: {
    handles: string[];
    name: string;
    bgURL: string;
    logoURL: string;
    bodyHtml: string;
  };
  qty: number;
};

const Brand = ({ brand, qty }: Props) => {
  if (!brand) return null;
  const { name, logoURL, bgURL, bodyHtml } = brand;
  const hasBg = bgURL;
  const hasLogo = logoURL;
  const hasCollectionName = !!name;

  return (
    <div className={cn(css.ns_com_collection_detail_brand_comp, { [css.has_bg]: hasBg }, { [css.has_logo]: hasLogo })}>
      {hasBg && (
        <div className={css.bg_img_wrapper}>
          <img className={css.bg_img} src={bgURL} alt="" />
        </div>
      )}
      <div className={cn(css.ns_com_brand_comp_content)}>
        <div className={cn(css.ns_com_brand_comp_logo_desc_content)}>
          {hasLogo && (
            <div className={cn(css.brand_logo_wrapper)}>
              <img src={logoURL} className={cn(css.brand_logo)} alt="logo" />
            </div>
          )}
          {hasCollectionName && (
            <div className={css.brand_name_wrapper}>
              <div className={css.brand_name}>{name}</div>
              <div className={css.desc}>{`${qty} products`}</div>
            </div>
          )}
        </div>
      </div>
      <div
        className={css.ns_com_brand_comp_introduction}
        dangerouslySetInnerHTML={{
          __html: bodyHtml,
        }}
      />
    </div>
  );
};

export default Brand;
