$prefixCls: signup-question-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app {
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(206, 228, 106, 0.5) 166%);
    }
  }

  &__content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr auto;
    place-items: center;
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
    overflow-y: auto;
  }

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  #signup-question-form {
    width: 100%;
  }
}
