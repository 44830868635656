$imgsize: 56px;
$imgsizeDesktop: 80px;

.ns_com_collection_detail_brand_comp {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  position: relative;
  overflow: hidden;

  .bg_img_wrapper {
    overflow: hidden;
    .bg_img {
      width: 100%;
    }
  }

  .ns_com_brand_comp_logo_desc_content {
    display: flex;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;
    color: var(--color-black);

    .brand_logo_wrapper {
      box-sizing: border-box;
      border: 1px solid #e8e8e8;
      border-radius: 50%;
      text-align: center;
      background: #fff;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-font-smoothing: antialiased;
      transform: translateZ(0);
    }

    .brand_name_wrapper {
      flex: 1;
      overflow: hidden;
      .brand_name {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-black);
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .desc {
        font-size: 14px;
        color: #424242;
        line-height: 1.2;
      }
    }

    .arrow {
      font-size: 18px;
    }
  }

  .ns_com_brand_comp_introduction {
    div {
      word-break: break-all;
    }

    .img_wrapper {
      display: grid;
      grid-gap: 10px;
      .img {
        width: 100%;
      }
    }
  }

  .ns_com_brand_comp_content {
    background-color: #f2f2f2;
  }
  .ns_com_brand_comp_logo_desc_content {
    .brand_logo {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .bg_img_wrapper {
      max-height: 200px;
    }

    .ns_com_brand_comp_introduction {
      padding-bottom: 12px;
      // 用于 intro 部分只展示图片时的布局
      .img_wrapper {
        grid-template-columns: repeat(2, 50%);
      }
    }

    &.has_logo {
      .ns_com_brand_comp_logo_desc_content {
        .brand_logo_wrapper {
          width: $imgsize;
          height: $imgsize;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .bg_img_wrapper {
      max-height: 340px;
    }

    .ns_com_brand_comp_introduction {
      .img_wrapper {
        grid-template-columns: repeat(4, 25%);
      }
    }

    &.has_logo {
      .ns_com_brand_comp_logo_desc_content {
        .brand_logo_wrapper {
          width: $imgsizeDesktop;
          height: $imgsizeDesktop;
        }
      }
    }

    &.has_logo.has_bg {
      .ns_com_brand_comp_content {
        position: relative;
      }
      .ns_com_brand_comp_logo_desc_content {
        align-items: flex-end;
        position: absolute;
        top: -52px;
      }
      .ns_com_brand_comp_introduction {
        margin-top: 32px;
      }
    }
  }

  :global {
    .kiwi_builder_flex_wrapper {
      display: flex;
      column-gap: 18px;
    }
    .kiwi_builder_flex_item {
      flex: 1;
      img {
        width: 100%;
      }
    }
  }
}
