.table_combine_order {
  .auto_link_wrapper {
    a {
      color: #424242 !important;
      text-decoration: underline !important;
    }
  }

  .copy_btn {
    display: inline-block;
    margin-left: var(--spacing-m);
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    background: #f5f5f5;
    border-radius: 99px;
    cursor: pointer;
  }

  :global {
    .ant-table {
      color: #424242;
      .ant-table-container {
        border: none !important;
      }
      .ant-table-thead > tr > th {
        background-color: transparent !important;
        font-size: 14px;
        font-weight: 500;
      }

      th,
      td {
        border: none !important;
      }
      table {
        border: none !important;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
        display: none;
      }
    }
  }
}
