.ns_com_modal_order_return_main {
  .content {
    height: 400px;
    overflow-y: auto;
  }

  :global {
    .modal__body {
      padding: 0;
    }
  }

  .modal_order_return_main_footer {
    text-align: center;
    :global {
      .adm-button {
        width: 250px;
        margin: 0 auto;
      }
    }
  }

  .modal_order_return_footer_tips {
    margin-top: 12px;
  }
}
