import { useMutation, useQueryClient } from 'react-query';

import { updatePaymentMethodsPriority } from '@/services/ds';

export default function usePutPaymentMethodsPriority() {
  const queryClient = useQueryClient();
  return useMutation((data: Parameters<typeof updatePaymentMethodsPriority>[0]) => updatePaymentMethodsPriority(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['paymentMethods']);
    },
  });
}
