import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import FavoriteButton from '@/components/FavoriteButton';
import ImportButton from '@/components/ImportButton';
import SkuTable from '@/components/SkuTable';
import { useDesktop } from '@/hooks';
import { CollectionProduct } from '@/types';
import { formatterPrice, getPriceRange } from '@/utils/util';

import RequestRestockButton from '../RequestRestockButton';
import css from './index.module.scss';

type Props = {
  data: CollectionProduct;
  isShow?: boolean;
  onOpenAddToCart?: (product: CollectionProduct) => void;
  isShowBuyButton?: boolean;
};

const ProductCardListView = React.memo(
  ({ data, isShow, onOpenAddToCart, isShowBuyButton = true }: Props) => {
    const isDesktop = useDesktop();
    const navigate = useNavigate();
    const { product, rel } = data;
    const {
      cover,
      price_max,
      price_min,
      compare_at_price_min,
      compare_at_price_max,
      id,
      inventory_quantity = 0,
      special_oos,
      drop_soon,
      out_of_stock,
      title,
      preorder,
    } = product || {};
    const { is_imported } = rel || {};
    const comparePrice = compare_at_price_max ?? 0;
    const showComparePrice = comparePrice > (price_max ?? 0);
    const saleRate = Math.max(1 - price_min / compare_at_price_min, 1 - price_max / compare_at_price_max) * 100;
    const inventoryQty = inventory_quantity || 0;
    const conditions = [
      {
        title: 'Preorder',
        condition: () => preorder?.length,
      },
      {
        title: 'Pre-Launch',
        condition: () => drop_soon?.at && moment().isBefore(drop_soon?.at),
      },
    ];
    const productLabelTitle = conditions.find(({ condition }) => condition())?.title;

    const isOutOfStock = !_.isNil(out_of_stock) && !!out_of_stock;
    const openProductDetail = useCallback(() => {
      const productDetailUrl = `/products/${product?.id}`;
      isDesktop ? window.open(productDetailUrl, '_blank') : navigate(productDetailUrl);
    }, [isDesktop, navigate, product?.id]);

    const handleOpenAddToCart = useCallback(() => {
      onOpenAddToCart?.(data);
    }, [data, onOpenAddToCart]);

    const buyButton = inventoryQty ? (
      <Button
        block
        shape="rounded"
        onClick={handleOpenAddToCart}
        className={css.buy_btn}
        disabled={typeof special_oos === 'boolean' ? special_oos : false}
      >
        Buy Stock
      </Button>
    ) : (
      <RequestRestockButton productId={data?.product?.id}>
        {({ onRequestRestock }) => (
          <Button className={css.request_restock_btn} block shape="rounded" onClick={onRequestRestock}>
            Request Restock
          </Button>
        )}
      </RequestRestockButton>
    );

    return (
      <div className={cn(css.product_card, css.grid_view)}>
        <div onClick={openProductDetail} className={css.img_wrapper}>
          <img className={css.img} src={cover} alt="" />
          <div className={css.label_wrapper}>
            {productLabelTitle && <div className={css.dropping_soon_label}>{productLabelTitle}</div>}
            {!productLabelTitle && isOutOfStock && (
              <span className={css.out_of_stock_label}>
                <i>
                  <FiAlertCircle />
                </i>
                Sold Out
              </span>
            )}
          </div>
        </div>
        <div className={css.price_wrapper}>
          <div className={css.price_sell}>{getPriceRange(price_min, price_max)}</div>
          {showComparePrice && (
            <div>
              <span className={css.price_compare}>${formatterPrice(comparePrice)}</span>
              <span className={css.price_sale_rate}>-{saleRate?.toFixed(0)}%</span>
            </div>
          )}
          {!!product?.master_style && <div className={css.style_tag}>Style #: {product?.master_style}</div>}
        </div>
        <div className={css.inventory_qty}>{inventoryQty.toLocaleString()}</div>
        <div className={css.sku_table_wrapper}>
          <div className={css.title} onClick={openProductDetail}>
            {title}
          </div>
          <SkuTable data={data} isShow={isShow} />
        </div>

        <div className={css.operations_wrapper}>
          <div>
            <ImportButton
              className={css.product_card_import_btn}
              importProductsQueryParams={{ product_ids: [id] }}
              buttonColor={is_imported ? 'default' : 'main'}
            >
              {is_imported ? 'Imported' : 'Import'}
            </ImportButton>
            {isShowBuyButton && buyButton}
          </div>
          <FavoriteButton className={css.favorite_btn} product={data} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

export default ProductCardListView;
