import { useMutation } from 'react-query';

import { checkoutOrder } from '@/services/ds';

export default function useCheckoutOrder() {
  return useMutation(
    ({
      id,
      ...params
    }: {
      id: number | string;
      payment_method_id: number;
      credit_to_apply: number;
      success_url?: string;
      cancel_url?: string;
    }) => checkoutOrder(id, params)
  );
}
