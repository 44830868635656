.sku_table {
  :global {
    .ant-table.ant-table-small .ant-table-footer,
    .ant-table.ant-table-small .ant-table-tbody > tr > td,
    .ant-table.ant-table-small .ant-table-thead > tr > th,
    .ant-table.ant-table-small .ant-table-title,
    .ant-table.ant-table-small tfoot > tr > td,
    .ant-table.ant-table-small tfoot > tr > th {
      padding: 4px;
    }
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          font-size: 12px;
          &:first-of-type {
            font-size: 14px;
          }
        }
      }
    }
  }
}
