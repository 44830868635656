.ns_com_favorite_button_main {
  @at-root .favorite_notification {
    :global {
      .notification__header {
        padding: var(--spacing-m) var(--spacing-xxl);
      }
    }

    &:global(.shape_default) {
      :global {
        .container {
          border-radius: 16px;
          .body {
            max-height: 480px;
            overflow-y: auto;
          }
        }
      }
    }

    .favorite_notification_title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: normal;
      min-width: 0;

      .title_img {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        object-fit: cover;
        padding-right: 12px;
      }

      .title_text {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;

        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .favorite_notification_body {
      .favorite_notification_item {
        display: flex;
        align-items: center;
        margin-top: 12px;
        cursor: pointer;
        &:first-child {
          margin-top: 4px;
        }
        .img_wrapper {
          display: flex;
          column-gap: 2px;
          .img {
            width: 37.5px;
            height: 50px;
            &:first-child {
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }
            &:last-child {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
            }
            &.no_src {
              background-color: #f5f5f5;
            }
          }
        }
        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;
          padding-left: 12px;
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .link {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}
