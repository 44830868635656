.order_page_modal_combine_orders {
  .order_page_modal_combine_orders_content {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    section {
      padding: 12px;
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #000;
        margin-bottom: 8px;
      }
      .select {
        width: 100%;
        &:global(.ant-select) {
          :global {
            .ant-select-selection-item > div {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
    .combined_cost {
      .item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #424242;
        padding-top: 8px;
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
  .order_page_modal_combine_orders_footer {
    padding-bottom: 30px;
    text-align: center;
    .total_cost {
      display: flex;
      justify-content: space-between;
      background-color: #f5fce5;
      padding: 12px;
      margin-bottom: 12px;
      span {
        font-weight: 500;
        font-size: 14px;
      }
    }
    button {
      width: 250px;
    }
  }
  :global {
    .modal__body {
      max-width: var(--content-small-max-width);
      max-height: 600px;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
    .modal__footer {
      text-align: center;
      button {
        width: 250px;
      }
    }
  }
}
.order_page_modal_combine_settings_confirm {
  .modal_combine_settings_confirm_content {
    text-align: center;
    font-size: 14px;
    color: #424242;
    .show_again {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      span {
        padding-left: 6px;
      }
    }
    button {
      width: 200px;
      margin-top: 16px;
    }
  }
  :global {
    .modal__body {
      margin: 0 auto;
      max-width: var(--content-small-max-width);
    }
  }
}
.combine_and_process_button_select_popup_wrapper {
  :global {
    .ant-select-item-option-content {
      white-space: normal;
      word-break: break-all;
    }
  }
}

.combine_and_process_select_dropdown_wrapper {
  .label {
    font-size: 14px;
    color: #999;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #c7c7c7;
  }

  .option {
    border-bottom: 1px solid #e8e8e8;
    padding: 12px 0;
    position: relative;
    white-space: normal;
    word-break: break-all;

    .selected_icon {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 36px;
      background: url(../../../../assets/images/tick.svg) no-repeat center center / 28px;
    }

    &.selected {
      padding: 12px 40px 12px 0;
      .selected_icon {
        display: block;
      }
    }
  }

  &:global(.ant-select-dropdown) {
    padding-top: 0;
    :global {
      .ant-select-item.ant-select-item-group {
        padding: 0 12px;
      }
      .ant-select-item-option-grouped {
        padding: 0 12px;
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #fff;
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #fff;
      }
      .ant-select-item,
      .ant-select-item-empty {
        min-height: auto;
      }
      .ant-select-item:last-child {
        .ant-select-item-option-content > div {
          border: none;
        }
      }
    }
  }
}
