import { useMutation, useQueryClient } from 'react-query';

import { setPassword } from '@/services/lynx';

export default function useSetPassword() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof setPassword>[0]) => setPassword(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('authUser');
    },
  });
}
