.ns_com_step_guide_dialog_main {
  :global {
    .modal__container {
      border-radius: 8px;
      overflow: hidden;

      @media screen and (min-width: 769px) {
        width: 620px;
        min-width: 620px;
        max-width: 620px;
      }
    }

    .modal__header {
      background: #f5f5f5;
      padding: 0 var(--spacing-l);
    }

    .modal__title {
      height: var(--spacing-m);
    }

    .modal__close-btn {
      padding: var(--spacing-m);
      right: 0;
      top: 0;
      transform: none;

      .react-icons {
        font-size: 32px;
        color: var(--color-white);
        background: #e3e3e3;
        border-radius: 99px;
      }
    }

    .modal__body {
      padding: 0;
    }
  }

  :global(.modal__footer) .footer {
    display: grid;
    grid-template-columns: 90px 1fr 90px;
    place-items: center;
    min-height: 39px;

    .footer_left {
      justify-self: start;
    }
    .footer_right {
      justify-self: end;
    }

    .steps_pagination {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: var(--color-black);
      font-size: 16px;
      line-height: 1.4;

      .steps_pagination_pager {
        margin: 0 var(--spacing-xxl);

        :nth-child(2) {
          margin: var(--spacing-s);
        }
      }

      .steps_pagination_prev_btn,
      .steps_pagination_next_btn {
        position: relative;
        cursor: pointer;

        // extend button touchable area
        &::after {
          content: '';
          position: absolute;
          top: -12px;
          left: -12px;
          right: -12px;
          bottom: -12px;
        }
      }

      :global {
        .swiper-button-disabled {
          color: #e8e8e8;
          cursor: not-allowed;
        }
      }
    }
  }

  &.single_step :global(.modal__footer) .footer {
    grid-template-columns: 1fr;

    & > :nth-child(1),
    & > :nth-child(2) {
      display: none;
    }

    & > :last-child {
      justify-self: center;
    }
  }

  .steps {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 210px;
      background-color: #f5f5f5;
      z-index: 0;
    }
  }

  .step {
    min-height: 340px;

    .step_cover {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 210px;
      background: #f5f5f5;
      overflow: hidden;

      .step_cover_image {
        max-width: 100%;
        max-height: 100%;
      }

      .step_cover_icon {
        font-style: normal;
        font-size: 80px;
      }
    }

    .step_info {
      margin-top: var(--spacing-xs);
      padding: var(--spacing-l);
      color: var(--color-gray5);
      font-size: var(--font-size3);
      line-height: 1.4;
    }

    .step_title {
      font-weight: 500;
    }

    .step_description {
      margin-top: var(--spacing-m);
    }
  }

  .dont_show_again {
    padding: var(--spacing-l);
  }
}
