import {
  AlphaCard,
  AlphaStack,
  Box,
  Divider,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from '@shopify/polaris';

const Skeleton = () => (
  <SkeletonPage primaryAction>
    <AlphaStack gap="5">
      <AlphaCard roundedAbove="sm" padding="0">
        <Box background="surface" padding="4" borderRadius="05">
          <SkeletonBodyText lines={2} />
        </Box>
        <Divider borderStyle="base" />
        <Box background="surface" padding="4" borderRadius="05">
          <SkeletonBodyText lines={2} />
        </Box>
        <Divider borderStyle="base" />
        <Box background="surface" padding="4" borderRadius="05">
          <SkeletonBodyText lines={2} />
        </Box>
      </AlphaCard>
    </AlphaStack>
  </SkeletonPage>
);

export default Skeleton;
