import { Result } from 'antd';
import cn from 'classnames';
import Helmet from 'react-helmet';

import logo from '@/assets/images/logo-gray.svg';
import Button from '@/components/Button';

const prefixCls = 'maintaining-page';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Maintaining({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>System Maintaining</title>
      </Helmet>

      <Result
        style={{
          position: 'absolute',
          width: '100%',
          top: '38.2%',
          transform: 'translateY(-50%)',
        }}
        icon={<img width={236} src={logo} alt="logo" />}
        title={<strong>Upgrading! Hang tight...</strong>}
        subTitle={
          <div>
            We&apos;re currently upgrading our system! We should be back by{' '}
            <strong className="text-black">[06:00]</strong> PST.
            <br />
            If you have urgent requests, please let us know at{' '}
            <a className="text-black text-bold text-muted text-underline" href="mailto:support@kiwidrop.com">
              support@kiwidrop.com
            </a>
            <br />
            or use the widget at the bottom left.
            <div>Rest assured: orders placed by your customers will be processed as usual during the upgrade time.</div>
          </div>
        }
        extra={
          <Button color="main" onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        }
      />
    </div>
  );
}

export default Maintaining;
