import { useModal } from '@ebay/nice-modal-react';
import { AlphaCard, Inline, Text } from '@shopify/polaris';
import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';

import FormModal from '@/pages/Addresses/components/FormModal';
import { Address } from '@/types';

import AddressesContext from './addressesContext';
import css from './NormalAddressItem.module.scss';

const NormalAddressItem = ({ data, showAction = true }: { data: Address; showAction?: boolean }) => {
  const formModal = useModal(FormModal);
  const { activeKey, setActiveKey, handleDeleteAddress, isSelectable } = useContext(AddressesContext);

  const userName = [data?.first_name, data?.last_name].join(' ') || data?.name;
  const description = _.compact([
    data?.address1,
    data?.address2,
    _.join(_.compact([data?.city, data?.province, data?.zip]), ', '),
    data?.country,
    _.join(_.compact([data?.phone_country, data?.phone]), ''),
  ]);

  const handleClick = useCallback(() => {
    if (!isSelectable) return;
    if (activeKey === data?.key) return;
    setActiveKey(data?.key);
  }, [activeKey, data?.key, isSelectable, setActiveKey]);

  const handleEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      formModal.show({ initialValues: data });
    },
    [data, formModal]
  );

  const handleDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      handleDeleteAddress?.(data);
    },
    [data, handleDeleteAddress]
  );

  return (
    <div
      className={cn(
        css.normal_shipping_item_wrapper,
        { [css.selectable]: isSelectable },
        { [css.active]: isSelectable && activeKey === data?.key }
      )}
      onClick={handleClick}
    >
      <AlphaCard>
        <Inline gap="2" blockAlign="center">
          <div style={{ flex: 1 }}>
            <Text variant="headingMd" as="h6">
              {userName}
            </Text>
            {description.map((text) => (
              <Text variant="bodyMd" as="p">
                {text}
              </Text>
            ))}
          </div>
          {showAction && (
            <Inline blockAlign="center" gap="1">
              <span onClick={handleEdit} className={css.address_item_action_button_wrapper}>
                <FiEdit3 fill="currentColor" />
              </span>
              <span onClick={handleDelete} className={css.address_item_action_button_wrapper}>
                <FiTrash2 size={18} strokeWidth={1.5} />
              </span>
            </Inline>
          )}
        </Inline>
      </AlphaCard>
    </div>
  );
};

export default NormalAddressItem;
