import 'react-intl-tel-input/dist/main.css';

import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import IntlTelInput, { CountryData, IntlTelInputProps } from 'react-intl-tel-input';
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries';

import envconfig from '@/constants/envconfig';

import css from './index.module.scss';

const defaultCountry = 'us';
const excludeCountries = envconfig.EXCLUDE_REGISTER_COUNTRIES;
const preferredCountries = ['us', 'ca', 'au'];
const allCountriesData = AllCountries.getCountries();

export interface Props extends IntlTelInputProps {
  className?: string;
  style?: React.CSSProperties;
  defaultDialCode?: string;
  onChange?: (phoneInfo: {
    number: string;
    fullNumber: string;
    country: string;
    dialCode: string;
    isValid: boolean;
  }) => void;
  onPressEnter?: () => void;
}

function PhoneInput({ className, style, defaultDialCode, onChange, onPressEnter, ...restProps }: Props): JSX.Element {
  const refIntlTelInput = useRef<IntlTelInput>(null);
  const forceCountryDialCode = defaultDialCode && restProps?.allowDropdown === false;

  const setCountryCode = () => {
    if (refIntlTelInput.current === null) return;
    const { flagDropDown, selectedCountryData } = refIntlTelInput.current;
    const flag = flagDropDown?.querySelector('.selected-flag > .iti-flag');
    flag && (flag.innerHTML = _.toUpper(selectedCountryData?.iso2 ?? defaultCountry));

    // force phone country dial code for internal user
    if (forceCountryDialCode) {
      const country = _.find(allCountriesData, { dialCode: defaultDialCode })?.iso2;
      flag && (flag.innerHTML = `${_.toUpper(country)} +${defaultDialCode}`);
    }
  };

  const handleChange = (number: string, fullNumber: string, countryData: CountryData, isValid: boolean) => {
    const { iso2, dialCode } = countryData;
    const isValidInput = /^\d*$/.test(number?.replace(/[\s()-]/g, ''));
    setCountryCode();

    isValidInput &&
      onChange?.({
        number,
        fullNumber,
        isValid,
        country: iso2 as string,
        dialCode: dialCode as string,
      });
  };

  useEffect(() => {
    setCountryCode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlTelInput
      ref={refIntlTelInput}
      containerClassName={cn(
        'intl-tel-input',
        'phone-input',
        className,
        {
          [css.dropdown_disabled]: restProps?.allowDropdown === false,
        },
        css.ns_com_phone_input_main
      )}
      inputClassName="phone-input__input"
      style={style}
      format={!forceCountryDialCode}
      formatOnInit={!forceCountryDialCode}
      nationalMode
      separateDialCode
      autoHideDialCode={false}
      defaultCountry={
        defaultDialCode
          ? _.find(allCountriesData, { dialCode: defaultDialCode })?.iso2 ?? defaultCountry
          : defaultCountry
      }
      excludeCountries={excludeCountries}
      preferredCountries={preferredCountries}
      onPhoneNumberChange={(isValid, number, countryData, fullNumber) =>
        handleChange(number, fullNumber, countryData, isValid)
      }
      onSelectFlag={(number, countryData, fullNumber, isValid) =>
        handleChange(number, fullNumber, countryData, isValid)
      }
      telInputProps={{ onKeyPress: (e) => e.key === 'Enter' && onPressEnter?.() }}
      {...restProps}
    />
  );
}

export default PhoneInput;
