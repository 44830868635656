.ns_com_shipping_address_dialog_main {
  &.list_view,
  &.list_inv_view {
    :global {
      .modal__body {
        background-color: var(--color-gray1);
      }
    }
  }

  :global {
    .modal__body {
      padding: 0 !important;
      background-color: #fff;
    }
    .modal__footer {
      border-top: none !important;
    }
  }

  @media screen and (min-width: 769px) {
    :global {
      .modal__container {
        max-width: 690px;
        min-height: 300px;
      }
      .modal__footer .button {
        width: 250px;
        margin: 0 auto;
      }
    }
  }
}
