import { AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { bootstrap, querySuggestionsIndex } from '.';

const searchClient = bootstrap();

function uniqBy(predicate) {
  return function runUniqBy(...rawSources) {
    const sources = rawSources.flat().filter(Boolean);
    const seen = new Set();

    return sources.map((source) => {
      const items = source.getItems().filter((item) => {
        const appliedItem = predicate({ source, item });
        const hasSeen = seen.has(appliedItem);

        seen.add(appliedItem);

        return !hasSeen;
      });

      return {
        ...source,
        getItems() {
          return items;
        },
      };
    });
  };
}

const removeDuplicates = uniqBy(({ source, item }) =>
  source.sourceId === 'querySuggestionsPlugin' ? item.query : item.label
);

function useAlgoliaAutocomplete() {
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<any>>();
  const recentSearchesPlugin = useMemo(
    () =>
      createLocalStorageRecentSearchesPlugin({
        key: 'RECENT_SEARCH',
        limit: 5,
      }),
    []
  );
  const querySuggestionsPlugin = useMemo(
    () =>
      createQuerySuggestionsPlugin({
        searchClient,
        indexName: querySuggestionsIndex,
        getSearchParams() {
          return { hitsPerPage: 5 };
        },
      }),
    []
  );

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        initialState: {
          query: keyword,
        },
        plugins: [recentSearchesPlugin, querySuggestionsPlugin],
        placeholder: 'Search for products...',
        autoFocus: true,
        openOnFocus: false,
        onStateChange({ state }: { state: AutocompleteState<any> }) {
          setAutocompleteState(state);
        },
        reshape({ sourcesBySourceId }) {
          const {
            recentSearchesPlugin: recentSearchesPluginSource,
            querySuggestionsPlugin: querySuggestionsPluginSource,
            ...rest
          } = sourcesBySourceId;

          return [removeDuplicates(recentSearchesPluginSource, querySuggestionsPluginSource), Object.values(rest)];
        },
      }),
    [keyword, querySuggestionsPlugin, recentSearchesPlugin]
  );

  return [autocomplete, autocompleteState, recentSearchesPlugin];
}

export default useAlgoliaAutocomplete;
