import { useMutation, useQueryClient } from 'react-query';

import { updateCombineSettings } from '@/services/ds';

export default function useOrderCombineSettingsUpdate() {
  const queryClient = useQueryClient();

  return useMutation((params?: any) => updateCombineSettings(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('useUserSettings');
      queryClient.invalidateQueries('orders');
    },
  });
}
