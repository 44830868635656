$p-breakpoints-sm-down: '(max-width: 30.6225em)';

:root {
  --pc-box-background: transparent !important;
}

.ns_or_stores_main_active {
  @media screen and #{$p-breakpoints-sm-down} {
    .stores_main_content {
      padding: 0 var(--p-space-4);
    }
  }

  :global {
    .Polaris-Avatar--sizeMedium {
      height: var(--pc-avatar-medium-size);
    }

    .Polaris-Button--primary {
      color: #000 !important;
    }
  }
}
