import { AlphaCard, AlphaStack, Box, Text } from '@shopify/polaris';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FiPlus } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import usePutPaymentMethodsPriority from '@/hooks/queries/usePutPaymentMethodsPriority';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';
import Empty from '@/pages/PaymentMethods/Empty';

import { PaymentMethodListViewProps, PaymentMethodTypeEnum } from '../types';
import { emitPaymentMethodChangeEvent, reorder } from '../util';
import ListItem from './ListItem';
import css from './ListView.module.scss';

export interface PaymentMethodListItemProps {
  data: any;
  onSelect?: (paymentMethod: any) => void;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const SortableList = ({ onDragEnd, cardListSource }: { onDragEnd: (data: any[]) => void; cardListSource: any[] }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
      {(provided) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {cardListSource.map((paymentMethod: any, index) => (
            <Draggable key={`${paymentMethod.id}`} draggableId={`${paymentMethod.id}`} index={index}>
              {(draggableProvided, draggableSnapshot) => (
                <div
                  ref={draggableProvided.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...draggableProvided.draggableProps}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...draggableProvided.dragHandleProps}
                  style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                >
                  <ListItem
                    key={paymentMethod.id}
                    isDefault={index === 0}
                    data={paymentMethod}
                    showDivider={cardListSource.length > 1 && index !== cardListSource.length - 1}
                    draggable
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

function ListView({ selectMode, data }: PaymentMethodListViewProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const putPaymentMethodsPriorityRQ = usePutPaymentMethodsPriority();
  let type = searchParams.get('dialog')?.split('/')?.[2] ?? searchParams.get('paymentMethodType');
  if (type && !_.includes(['card', 'bank'], type)) type = 'card';
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const sortableContainerRef = useRef(null);

  const bankList = useMemo(() => data?.filter?.((d) => d.type === PaymentMethodTypeEnum.Bank), [data]);
  const cardListOrigin = data?.filter?.((d) => d.type === PaymentMethodTypeEnum.Card);
  const [cardList, setCardList] = useState(cardListOrigin);

  const handleSelect = useCallback(
    (paymentMethod: any) => {
      emitPaymentMethodChangeEvent(paymentMethod);
      navigate(getUpdatedSearchParamsPath({ dialog: null, init: null, selectMode: null, paymentMethodType: null }), {
        state: location.state,
      });
    },
    [getUpdatedSearchParamsPath, location.state, navigate]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const newCardList = reorder(cardList, result.source.index, result.destination.index);
      setCardList(newCardList);

      putPaymentMethodsPriorityRQ.mutate({ payment_priority: newCardList.map(({ id }) => id) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleAdd = useCallback(() => {
    navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods' }), { replace: true });
  }, [getUpdatedSearchParamsPath, navigate]);

  const list = useMemo(() => {
    if (selectMode) {
      return (
        <AlphaStack>
          {cardList?.map((paymentMethod: any, index) => (
            <ListItem
              isDefault={index === 0}
              key={paymentMethod?.id}
              data={paymentMethod}
              onSelect={selectMode ? handleSelect : undefined}
              showDivider={cardList.length > 1 && index !== cardList.length - 1}
              showAction={!selectMode}
            />
          ))}
        </AlphaStack>
      );
    }

    return (
      <AlphaStack gap="5">
        {!!cardList.length && (
          <div>
            <Box
              paddingBlockEnd="2"
              paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
              paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
            >
              <Text variant="headingSm" as="h6">
                Cards
              </Text>
            </Box>

            <AlphaCard roundedAbove="sm" padding="0">
              <div ref={sortableContainerRef}>
                <SortableList cardListSource={cardList} onDragEnd={onDragEnd} />
              </div>
            </AlphaCard>
            <Box
              paddingBlockStart="2"
              paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
              paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
            >
              <Text as="h2" variant="bodySm" fontWeight="medium" color="subdued">
                If the default payment method is not available or declined, we will automatically attempt to charge your
                payment methods as backup in the order they are listed. To ensure uninterrupted service, we recommend
                keeping multiple payment methods on file and keeping them up-to-date.
              </Text>
            </Box>
          </div>
        )}

        {/* 如果是bank account，不允许拖拽 */}
        {!!bankList.length && (
          <div>
            <Box
              paddingBlockEnd="2"
              paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
              paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
            >
              <Text variant="headingSm" as="h6">
                Bank Accounts
              </Text>
            </Box>
            <AlphaCard roundedAbove="sm" padding="0">
              {bankList?.map((paymentMethod: any, index) => (
                <ListItem
                  key={paymentMethod?.id}
                  data={paymentMethod}
                  onSelect={selectMode ? handleSelect : undefined}
                  showDivider={bankList.length > 1 && index !== bankList.length - 1}
                />
              ))}
            </AlphaCard>
          </div>
        )}
      </AlphaStack>
    );
  }, [selectMode, cardList, onDragEnd, handleSelect, bankList]);

  return (
    <div className={css.ns_com_payment_methods_lists_main}>{data?.length ? list : <Empty onAdd={handleAdd} />}</div>
  );
}

export default ListView;
