.ns_com_payment_methods_lists_main {
  .empty {
    width: max-content;
    padding: var(--spacing-l);
    margin: 80px auto;
    color: var(--color-gray4);
    font-size: var(--font-size3);
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    background: rgba(232, 232, 232, 0.5);
    border-radius: 8px;
    cursor: pointer;

    .empty_icon {
      margin-bottom: var(--spacing-m);
    }
  }

  @media screen and (max-width: 768px) {
    .payment_method_list {
      gap: var(--spacing-m);
    }

    .empty {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }
}
