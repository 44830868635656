/* eslint-disable no-nested-ternary */
import { Divider, Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import SmsMarketingConsent from '@/components/MarketingConsent';
import PhoneInput, { Props as PhoneInputProps } from '@/components/PhoneInput';
import { useAuth, useSendPhoneCode } from '@/hooks/auth';
import useSendEmailCode from '@/hooks/queries/useSendEmailCode';
import useSetPassword from '@/hooks/queries/useSetPassword';
import useVerifyEmailCode from '@/hooks/queries/useVerifyEmailCode';
import useVerifyPhoneCode from '@/hooks/queries/useVerifyPhoneCode';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ViewType = 'email' | 'code' | 'phone' | 'phoneCode' | 'password';

function PasswordReset({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const sendEmailCode = useSendEmailCode();
  const sendPhoneCode = useSendPhoneCode();
  const verifyEmailCode = useVerifyEmailCode();
  const verifyPhoneCode = useVerifyPhoneCode();
  const setPassword = useSetPassword();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const isAutoVerifyEmail = !_.isEmpty(email) && !_.isEmpty(code);
  const forcePhoneCountry = searchParams.get('phone_country');
  const [currentView, setCurrentView] = useState<ViewType>(isAutoVerifyEmail ? 'code' : 'email');
  const [emailValue, setEmailValue] = useState(_.trim(email) || '');
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('');
  const [formattedPhoneValue, setFormattedPhoneValue] = useState('');
  const [codeValue, setCodeValue] = useState(code || '');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [confirmMarketConsent, setConfirmMarketConsent] = useState(null);
  const [showMarketConsentError, setShowMarketConsentError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const phone = phoneValue?.replace(/\D/g, '');
  const phoneCountryWithSymbol = `+${forcePhoneCountry ?? phoneCountry}`;
  const codeType = useRef('email');
  const redirectUrl = searchParams.get('redirect') || '/';
  const shouldDisableContinue =
    (currentView === 'email' && !isValidEmail(emailValue)) ||
    (currentView === 'phone' && _.isEmpty(phoneValue.trim())) ||
    (_.includes(['code', 'phoneCode'], currentView) && codeValue.trim().length < 6) ||
    (currentView === 'password' && (_.isEmpty(passwordValue) || _.isEmpty(confirmPasswordValue)));
  const codeInputRef = useRef(null);
  const pwdInputRef = useRef(null);

  const handleSuccess = () => {
    _.startsWith(redirectUrl, '/') ? navigate(redirectUrl) : window.location.assign(redirectUrl);
  };

  const sendCode = (onSuccess?: () => void, onError?: (error?: any) => void) => {
    setCodeValue('');
    setErrorMessage('');
    const onSettled = (data, error) => (error ? onError?.(error) : onSuccess?.());
    codeType.current === 'email'
      ? sendEmailCode.mutate({ email: _.trim(emailValue), scenario: 'reset-password' }, { onSettled })
      : sendPhoneCode.mutate(
          { phone, phone_country: phoneCountryWithSymbol, scenario: 'reset-password' },
          { onSettled }
        );
  };

  const verifyCode = (onSuccess?: (data: any) => void, onError?: (error?: any) => void) => {
    const onSettled = (data, error) => {
      if (error) {
        onError?.(error);
      } else {
        onSuccess?.(data);
      }
    };

    codeType.current === 'email'
      ? verifyEmailCode.mutate(
          { email: _.trim(emailValue), code: codeValue, scenario: 'reset-password' },
          { onSettled }
        )
      : verifyPhoneCode.mutate(
          {
            phone,
            phone_country: phoneCountryWithSymbol,
            code: codeValue,
            scenario: 'reset-password',
          },
          { onSettled }
        );
  };

  const handleSetPassword = () => {
    setPassword.mutate(
      { password: passwordValue },
      {
        onSettled: (data, error) => {
          if (error) {
            setErrorMessage((error as Error)?.message || 'Set Password Failure');
          } else {
            handleSuccess();

            if ('PasswordCredential' in window) {
              const credential = new (window as any).PasswordCredential({
                id: user?.email || _.trim(emailValue),
                password: passwordValue,
              });
              navigator.credentials.store(credential);
            }
          }
        },
      }
    );
  };

  const handleContinue = () => {
    setErrorMessage('');
    if (_.includes(['email', 'phone'], currentView)) {
      if (currentView === 'phone' && !confirmMarketConsent) {
        setShowMarketConsentError(true);
        return;
      }
      sendCode(
        () => setCurrentView(codeType.current === 'email' ? 'code' : 'phoneCode'),
        (error) => setErrorMessage((error as Error)?.message || 'SMS Code failed to send')
      );
    } else if (_.includes(['code', 'phoneCode'], currentView)) {
      verifyCode(
        () => setCurrentView('password'),
        (error) => setErrorMessage((error as Error)?.message || 'Invalid Code')
      );
    } else if (currentView === 'password') {
      if (passwordValue !== confirmPasswordValue) {
        setErrorMessage('Passwords do not match');
        return;
      }

      handleSetPassword();
    }
  };

  const handlePhoneValueChange: PhoneInputProps['onChange'] = ({ dialCode, number, fullNumber }) => {
    setPhoneValue(number);
    setPhoneCountry(dialCode);
    setFormattedPhoneValue(fullNumber);
  };

  const handleCodeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.validity.valid && setCodeValue(e.target.value);
  };

  const handleSendCodeToPhone = () => {
    codeType.current = 'phone';
    setCurrentView('phone');
    setCodeValue('');
    setErrorMessage('');
  };

  const handleSendCodeToEmail = () => {
    codeType.current = 'email';
    setCurrentView('email');
    setCodeValue('');
    setErrorMessage('');
  };

  const handleResendCode = () => {
    sendCode(null, (error) => setErrorMessage((error as Error)?.message || 'Code failed to send'));
  };

  useEffect(() => {
    if (isAutoVerifyEmail || !_.isEmpty(_.trim(emailValue))) {
      handleContinue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentView === 'code') {
      codeInputRef.current?.focus?.();
      return;
    }
    if (currentView === 'password') {
      pwdInputRef.current?.focus?.();
    }
  }, [currentView]);

  return (
    <div
      className={cn(prefixCls, className, `${prefixCls}--${currentView}-view`, {
        [`${prefixCls}--has-error`]: !!errorMessage,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Password Reset</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__form-wrapper`}>
          <h1 className={`${prefixCls}__title`}>
            {_.includes(['email', 'code', 'phone', 'phoneCode'], currentView) && 'Password Reset'}
            {currentView === 'password' && 'Set a new password'}
          </h1>
          <div className={`${prefixCls}__message`}>
            {currentView === 'email' && 'Enter your email to continue'}
            {currentView === 'phone' && 'Enter your phone number to continue'}
            {currentView === 'code' && (
              <>
                We’ve sent you a one-time login code to your email:
                <br />
                <strong>{_.trim(emailValue)}</strong>
              </>
            )}
            {currentView === 'phoneCode' && (
              <>
                Enter the 6-digit code sent to <strong>{formattedPhoneValue}</strong>
                <br />
                Please enter it within 10 minutes
              </>
            )}
            {currentView === 'password' && (
              <>
                Reset your password for your account
                <br />
                <strong>{codeType.current === 'email' ? _.trim(emailValue) : formattedPhoneValue}</strong>
              </>
            )}
          </div>
          <form id="passwordReset" method="post" onSubmit={(e) => e.preventDefault()} autoComplete="off">
            <div className={`${prefixCls}__input-wrapper`}>
              <Input
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-email`)}
                type="email"
                name="email"
                autoComplete="off"
                placeholder="Enter your email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                onPressEnter={handleContinue}
              />
              <PhoneInput
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-phone`)}
                value={phoneValue}
                defaultDialCode={forcePhoneCountry}
                allowDropdown={_.isNil(forcePhoneCountry)}
                onChange={handlePhoneValueChange}
                onPressEnter={handleContinue}
              />
              <Input
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-code`)}
                type="tel"
                autoComplete="off"
                maxLength={6}
                pattern="[0-9]*"
                placeholder="000000"
                value={codeValue}
                onChange={handleCodeValueChange}
                onPressEnter={handleContinue}
                ref={codeInputRef}
              />
              <Input.Password
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-password`)}
                type="password"
                name="password"
                autoComplete="password"
                placeholder="Enter Password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onPressEnter={handleContinue}
                ref={pwdInputRef}
              />
              <Input.Password
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-password`)}
                type="password"
                name="confirmPassword"
                autoComplete="off"
                placeholder="Confirm Password"
                value={confirmPasswordValue}
                onChange={(e) => setConfirmPasswordValue(e.target.value)}
                onPressEnter={handleContinue}
              />
            </div>
          </form>
          <div className={`${prefixCls}__error-message`}>{errorMessage}</div>
          {currentView === 'phone' && (
            <SmsMarketingConsent
              confirmMarketConsent={confirmMarketConsent}
              setConfirmMarketConsent={setConfirmMarketConsent}
              showMarketConsentError={showMarketConsentError}
              setShowMarketConsentError={setShowMarketConsentError}
            />
          )}
          <Button
            className={`${prefixCls}__continue-btn`}
            color="main"
            shape="rounded"
            loading={
              (currentView === 'email' && sendEmailCode.isLoading) ||
              (currentView === 'phone' && sendPhoneCode.isLoading) ||
              (currentView === 'code' && verifyEmailCode.isLoading) ||
              (currentView === 'phoneCode' && verifyPhoneCode.isLoading) ||
              (currentView === 'password' && setPassword.isLoading)
            }
            loadingText={
              _.includes(['email', 'phone'], currentView)
                ? 'Sending'
                : _.includes(['code', 'phoneCode'], currentView)
                ? 'Verifying'
                : 'Submitting'
            }
            disabled={shouldDisableContinue}
            onClick={handleContinue}
          >
            Continue
          </Button>

          <div className={`${prefixCls}__actions`}>
            {currentView === 'email' && (
              <Button underline fill="none" onClick={handleSendCodeToPhone}>
                Send Code to Phone
              </Button>
            )}
            {currentView === 'phone' && (
              <Button underline fill="none" onClick={handleSendCodeToEmail}>
                Send Code to Email
              </Button>
            )}
            {_.includes(['code', 'phoneCode'], currentView) && (
              <>
                <Button
                  underline
                  fill="none"
                  loading={sendEmailCode.isLoading || sendPhoneCode.isLoading}
                  loadingText="Sending"
                  onClick={handleResendCode}
                >
                  Resend Code
                </Button>
                <Divider type="vertical" />
                <Button
                  underline
                  fill="none"
                  onClick={currentView === 'code' ? handleSendCodeToPhone : handleSendCodeToEmail}
                >
                  {currentView === 'code' ? 'Send Code to Phone' : 'Send Code to Email'}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function isValidEmail(email = ''): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (_.isEmpty(_.trim(email))) return false;
  return re.test(String(email).toLowerCase());
}

export default PasswordReset;
