.guideModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  .title {
    font-size: 20px;
  }
  .index {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    margin: auto;
    font-size: 16px;
    background-color: var(--color-primary);
  }
  .rowItem {
    .title {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .desc {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .guideModal {
    width: auto;
  }
}

@media screen and (min-width: 769px) {
  .guideModal {
    width: 620px;
  }
}
