import { AlphaStack, Inline } from '@shopify/polaris';
import { message, Tooltip } from 'antd';
import { Tabs } from 'antd-mobile';
import cn from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { ElementRef, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiAlertCircle, FiCopy, FiDownload, FiPrinter } from 'react-icons/fi';
import { IoIosLock } from 'react-icons/io';
import { useParams, useSearchParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import AccessControl from '@/components/AccessControl';
import AutoLink from '@/components/AutoLink';
import SalesTimer from '@/components/biz/SalesTimer';
import useSalesTimerSetting from '@/components/biz/SalesTimer/useSalesTimerSetting';
import useSalesTimerShow, { SalesTimerPos } from '@/components/biz/SalesTimer/useSalesTimerShow';
import Button from '@/components/Button';
import FavoriteButton from '@/components/FavoriteButton';
import ImportButton from '@/components/ImportButton';
import Modal from '@/components/Modal';
import ModalPrintLiveHelper from '@/components/ModalPrintLiveHelper';
import ProductAddToCartDialog from '@/components/ProductAddToCartDialog';
import ProductTags, { ProductTagsPos } from '@/components/ProductTags';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import SkuTable from '@/components/SkuTable';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop } from '@/hooks';
import { useHotFlashSettings } from '@/hooks/biz';
import useProduct from '@/hooks/queries/useProduct';
import useProductImagesDownloadUrl from '@/hooks/queries/useProductImagesDownloadUrl';
import useRequestProductRestock from '@/hooks/queries/useRequestProductRestock';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useBrand from '@/hooks/useBrand';
import { getProductById } from '@/services/ds';
import { ProductPreviewMode, ProductStatus } from '@/types';
import {
  getBoostInfo,
  getVariantByOptions,
  getVariantOptions,
  getVariantsStockSummary,
  hasInvView,
  showDelayTag,
} from '@/utils/biz';
import { getUpdatedSearchParams } from '@/utils/util';

import Brand from './Brand';
import Boost from './components/Boost';
import HotFlash from './components/HotFlash';
import ModalReportImageCopyright from './components/ModalReportImageCopyright';
import ProductGallery from './components/ProductGallery';
import styles from './styles.scss';
import { getImageIndexByVariant, getProductServices, sanitize } from './util';

type ModalReportImageCopyrightHandle = React.ElementRef<typeof ModalReportImageCopyright>;
type ProductGalleryHandle = ElementRef<typeof ProductGallery>;

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const configs = [];

function ProductDetail({ className, style }: Props): JSX.Element | null {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addToCartDialogVisible, setAddToCartDialogVisible] = useState(false);
  const [buySamplesExceededDialogVisible, setBuySamplesExceededDialogVisible] = useState(false);
  const [importDroppingSoonNoticeDialogVisible, setImportDroppingSoonNoticeDialogVisible] = useState(false);
  const beforeShowImportDialogResolver = useRef<any>(null);
  const userSettings = useUserSettings();
  const product = useProduct(params.id);
  const productImagesDownloadUrl = useProductImagesDownloadUrl();
  const requestProductRestock = useRequestProductRestock();
  const isDesktop = useDesktop();
  const variantId = Number(searchParams.get('variant'));
  const productData = product?.data?.data;
  const images = productData?.product?.images;
  const options = productData?.product?.options;
  const variants = productData?.product?.variants;
  const currentVariant = useMemo(() => _.find(variants, { id: variantId }) ?? variants?.[0], [variants, variantId]);
  const variantsStockSummary = useMemo(() => getVariantsStockSummary(variants), [variants]);
  const price = currentVariant?.price;
  const comparePrice = currentVariant?.compare_at_price;
  const isPriceLocked = _.isNil(price);
  const isProductOutOfStock = !_.isNil(productData?.product?.out_of_stock) && !!productData?.product?.out_of_stock;
  const isCurrentVariantOutOfStock = currentVariant?.inventory_quantity < 1;
  const isProductActive = _.toLower(productData?.product?.status) === 'active';
  const isProductArchived = _.toLower(productData?.product?.status) === ProductStatus.Archived;
  const dropSoonInfo = useMemo(() => productData?.product?.drop_soon || {}, [productData?.product?.drop_soon]);
  const isProductDroppingSoon = dropSoonInfo?.at && moment().isBefore(dropSoonInfo?.at);
  const productServices = useMemo(() => getProductServices(productData?.product), [productData]);
  const showBoutique = !_.isEmpty(
    _.trim(
      sanitizeHtml(productData?.product?.body_html_no_import, {
        allowedTags: [],
        allowedAttributes: {},
      })
    ).replace(/^-/, '')
  );
  const brand = useBrand({ tags: productData?.product?.tags });
  const modalReportImageCopyrightRef = useRef<ModalReportImageCopyrightHandle>();
  const isShowDelayTag = showDelayTag(productData?.product?.tags);

  // inventory list view
  const isILV = !!searchParams.get('inv');
  const showSwitchModeBtn =
    [ProductPreviewMode.Grid, ProductPreviewMode.List].every((i) => productData?.product?.views?.includes(i)) &&
    (productData?.product?.option_color_idx || productData?.product?.option_size_idx) &&
    !isProductArchived;
  const SkuTableComp = useMemo(() => <SkuTable data={productData} isShow={isILV} />, [isILV, productData]);
  const productGalleryRef = useRef<ProductGalleryHandle>(null);
  const hotflashSettings = useHotFlashSettings();
  const inventoryQuantity = Math.max(Number(productData?.product?.inventory_quantity), 0);
  const showPrintButton = isDesktop && hasInvView(userSettings?.data?.data?.app_collection_views);
  const preOrderOrigin = useMemo(
    () => (Array.isArray(productData?.product?.preorder) ? productData?.product?.preorder : []),
    [productData?.product?.preorder]
  );
  const currentPreOrder = useMemo(
    () => preOrderOrigin?.find((p) => p?.variant_ids?.includes(currentVariant?.id)),
    [currentVariant, preOrderOrigin]
  );
  const isPreOrder = !!currentPreOrder?.preorder;

  const handleVariantChange = (group: any, value: string) => {
    const changedOptionKey = `option${group?.position}`;
    const newVariantOptions = getVariantOptions({ ...currentVariant, [changedOptionKey]: value });
    const newVariant = getVariantByOptions(variants, newVariantOptions);
    const imageIndex = getImageIndexByVariant(images, newVariant);

    if (_.isNil(newVariant?.id)) return;

    productGalleryRef.current?.inst?.slideToLoop(imageIndex);
    setSearchParams({ variant: newVariant.id }, { replace: true });
  };

  const handleCopyInfo = () => {
    const isSuccess = copyToClipboard(productData?.product?.body_text ?? '');
    isSuccess
      ? message.success({ key: 'copyProductInfo', content: 'Product Info Copied!' })
      : message.error({ key: 'copyProductInfo', content: 'Copy Failed!' });
  };

  const handleDownloadImages = () => {
    if (isDesktop) {
      productImagesDownloadUrl.mutate(params.id, {
        onSettled: (data, error) => {
          if (error || _.isEmpty(data?.data)) {
            message.error((error as Error)?.message || 'Load Download Url Failure!');
          } else {
            window.location.assign(data?.data);
          }
        },
      });
      return;
    }

    const activeIndex = productGalleryRef.current?.inst?.activeIndex;
    const src = images[activeIndex]?.src;
    window.open(src);
  };

  const handleBuySamples = () => {
    if (isProductDroppingSoon && !dropSoonInfo?.qty_avail) {
      setBuySamplesExceededDialogVisible(true);
      return;
    }

    setAddToCartDialogVisible(true);
  };

  const handleRequestRestock = () => {
    requestProductRestock.mutate(productData?.product?.id, {
      onSettled: (data, error) => {
        if (error) {
          message.error({ key: 'requestRestock', content: (error as Error)?.message || 'Request Restock Failure!' });
        } else {
          message.success({ key: 'requestRestock', content: 'Request Restock Sent!' });
        }
      },
    });
  };

  const isVariantOutOfStock = (group: any, value: string): boolean => {
    const { position = 1 } = group;
    const option1 = currentVariant?.option1;
    const option2 = currentVariant?.option2;

    if (position === 1) return variantsStockSummary?.[value]?.total < 1;
    if (position === 2) return variantsStockSummary?.[option1]?.children?.[value]?.total < 1;
    if (position === 3) return variantsStockSummary?.[option1]?.children?.[option2]?.children?.[value]?.total < 1;

    return false;
  };

  const openModalImageCopyRight = () => {
    modalReportImageCopyrightRef.current?.show();
  };

  const changeViewMode = () => {
    setSearchParams(getUpdatedSearchParams(searchParams, { inv: isILV ? '' : '1' }));
  };

  const hotFlashTimer = useMemo(() => {
    const setting = hotflashSettings?.tabs?.find(({ tag }) => productData?.product?.tags?.includes(tag));
    const hotflashTitle = hotflashSettings?.title || 'Hot Flash';

    if (!setting) return null;

    return <HotFlash title={hotflashTitle} setting={setting} />;
  }, [hotflashSettings?.tabs, hotflashSettings?.title, productData?.product?.tags]);

  const boostBanner = useMemo(() => {
    const { isShow, earnPrice, ratioStr } = getBoostInfo(productData?.product, price);
    const isShowBoost = isShow && !isPriceLocked;

    if (!isShowBoost) return null;

    return <Boost earnPrice={earnPrice} ratioStr={ratioStr} />;
  }, [isPriceLocked, price, productData?.product]);

  const salesTimerSetting = useSalesTimerSetting({ productTags: productData?.product?.tags });
  const isSalesTimerPosMathcd = useSalesTimerShow({ pos: salesTimerSetting?.pos, propPos: SalesTimerPos.PDP });

  const salesTimer = useMemo(() => {
    if (!salesTimerSetting) return null;
    if (!isSalesTimerPosMathcd) return null;
    return <SalesTimer pos={SalesTimerPos.PDP} productTags={productData?.product?.tags} />;
  }, [isSalesTimerPosMathcd, productData?.product?.tags, salesTimerSetting]);

  const contentHeader = useMemo(
    () => (
      <div className={`${prefixCls}__product-main-content-wrapper`}>
        {salesTimer || hotFlashTimer}
        {boostBanner}
      </div>
    ),
    [boostBanner, hotFlashTimer, salesTimer]
  );

  const productLabel = useMemo(() => {
    const config = configs.find((c) => productData?.product?.tags?.includes(c.tag));
    if (config) {
      return (
        <div className={`${prefixCls}-pre-order_wrapper`}>
          <AlphaStack gap="4">
            <Inline align="space-between">
              <strong>
                Drop-ship Launch Date: <span className={`${prefixCls}_underline`}>{config.start}</span>
              </strong>
              <strong>
                Drop-ship End Date: <span className={`${prefixCls}_underline`}>{config.end}</span>
              </strong>
            </Inline>
            <div>
              {config.text}
              <AutoLink target="_blank" to="https://help.kiwidrop.com/knowledge/about-intl-curated-pre-launch">
                More Details
              </AutoLink>
            </div>
          </AlphaStack>
        </div>
      );
    }
    if (isPreOrder) {
      const endDate = currentPreOrder?.ended_at
        ? momentTimezone(currentPreOrder?.ended_at).format('MMMM DD, YYYY')
        : null;
      // const userTimezone = momentTimezone.tz.guess();
      // const timezoneDesc = momentTimezone(currentPreOrder?.ended_at).tz(userTimezone).format('z');

      return (
        <div className={`${prefixCls}-pre-order_wrapper`}>
          <AlphaStack gap="4">
            <Inline align="space-between">
              {/* TODO: 临时隐藏，不展示此日期 */}
              {!!endDate && (
                <strong>
                  Preorder end date: <span className={`${prefixCls}_underline`}>{endDate}</span>
                </strong>
              )}
              <strong>
                Ready to ship date: <span className={`${prefixCls}_underline`}>{currentPreOrder?.preorder}</span>
              </strong>
            </Inline>
            <div>
              {`Preorder Product: Orders for this product are only accepted between the specified "Start Date" and "End Date" (These dates will be specified for each product within the "Notes for Retailers" Section on the product listing). Orders for preorder products will not be processed following the "End Date".`}
              {` `}
              <AutoLink target="_blank" to="https://help.kiwidrop.com/knowledge/preorder-knowledge-article">
                See Details
              </AutoLink>
            </div>
          </AlphaStack>
        </div>
      );
    }

    if (isProductDroppingSoon) {
      return (
        <div className={`${prefixCls}-dropping-soon-notice_wrapper`}>
          <div className={`${prefixCls}-dropping-soon-notice_wrapper-title`}>
            Launching {momentTimezone(dropSoonInfo?.at).tz(dropSoonInfo?.tz).format('LLL')}{' '}
            {momentTimezone(dropSoonInfo?.at).tz(dropSoonInfo?.tz).format('z')}
          </div>
          <div className={`${prefixCls}-dropping-soon-notice_wrapper-desc`}>
            Only sample purchases are permitted prior to launch. During this period you may buy a maximum of 2 samples
            for this listing. Any drop shipping orders containing this item will not be fulfilled during this time.
          </div>
        </div>
      );
    }

    return null;
  }, [
    currentPreOrder,
    dropSoonInfo?.at,
    dropSoonInfo?.tz,
    isPreOrder,
    isProductDroppingSoon,
    productData?.product?.tags,
  ]);

  return (
    <div
      className={cn(prefixCls, className, {
        [`${prefixCls}--price-locked`]: isPriceLocked,
        [`${prefixCls}--out-of-stock`]: isProductOutOfStock,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{productData?.product?.title ?? 'Details'}</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Product Details</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <StatusViewRenderer
          data={productData}
          error={product.error}
          isLoading={product.isLoading}
          statusStyle={{ marginTop: 64 }}
        >
          <div className={`${prefixCls}__product-main`}>
            {!isDesktop && contentHeader}

            <div className={`${prefixCls}__product-gallery-section`}>
              <ProductGallery data={productData} ref={productGalleryRef} />
            </div>

            <div className={`${prefixCls}__product-info-section`}>
              <div className={`${prefixCls}__product-label_wrapper`}>
                {isShowDelayTag && (
                  <div className={`${prefixCls}__delay_tag`}>
                    <span className={`${prefixCls}__delay_tag_dot`} />
                    Temporarily unavailable from 1/10 - 2/8 due to
                    <a
                      href="https://blog.kiwidrop.com/lunar-new-year-shipping-and-processing-delays"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Lunar New Year
                    </a>
                  </div>
                )}
                {isDesktop && contentHeader}
                {productLabel}
                <div className={`${prefixCls}__product-tags`}>
                  <ProductTags customStyleTags={productData?.product?.labels} pos={ProductTagsPos.PDP} />
                </div>
              </div>
              <div className={`${prefixCls}__product-title`}>{productData?.product?.title}</div>
              <div className={`${prefixCls}__layout-space-between`}>
                <div className={cn(`${prefixCls}__layout-item`, `${prefixCls}__product-price-group`)}>
                  {!isPriceLocked && <div className={`${prefixCls}__product-total-cost`}>Product Cost</div>}
                  <div className={`${prefixCls}__product-price`}>
                    {isPriceLocked ? (
                      <>
                        <IoIosLock />
                        Cost Locked
                      </>
                    ) : (
                      <>
                        ${price?.toFixed(2)}
                        {comparePrice > price && (
                          <div className={`${prefixCls}__product-compare-price`}>
                            <span className={`${prefixCls}__product-compare-price-amount`}>
                              ${comparePrice?.toFixed(2)}
                            </span>
                            <span className={`${prefixCls}__product-compare-price-sale-rate`}>
                              -{((1 - price / comparePrice) * 100)?.toFixed(0)}%
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className={`${prefixCls}__layout-item`}>
                  <div className={`${prefixCls}__product-secondary-actions`}>
                    <div className={`${prefixCls}__product-secondary-actions-btn`} onClick={handleCopyInfo}>
                      <span className={`${prefixCls}__product-secondary-actions-btn-icon`}>
                        <FiCopy />
                      </span>
                      <span className={`${prefixCls}__product-secondary-actions-btn-title`}>Info</span>
                    </div>
                    <div className={`${prefixCls}__product-secondary-actions-btn`} onClick={handleDownloadImages}>
                      <span className={`${prefixCls}__product-secondary-actions-btn-icon`}>
                        <FiDownload />
                      </span>
                      <span className={`${prefixCls}__product-secondary-actions-btn-title`}>Images</span>
                    </div>
                    {showPrintButton && (
                      <ModalPrintLiveHelper fetchData={() => getProductById(params.id).then((res) => [res?.data])}>
                        {({ toggler }) =>
                          isDesktop ? (
                            <div className={`${prefixCls}__product-secondary-actions-btn`} onClick={toggler}>
                              <span className={`${prefixCls}__product-secondary-actions-btn-icon`}>
                                <FiPrinter />
                              </span>
                              <span className={`${prefixCls}__product-secondary-actions-btn-title`}>Print</span>
                            </div>
                          ) : null
                        }
                      </ModalPrintLiveHelper>
                    )}
                  </div>
                </div>
              </div>

              <div className={cn(`${prefixCls}__product-options`, { show: !isILV })}>
                {options?.map((group: any) => (
                  <div key={group?.name} className={`${prefixCls}__product-options-group`}>
                    <div className={`${prefixCls}__product-options-group-title`}>{_.startCase(group?.name)}</div>
                    <div className={`${prefixCls}__product-options-group-options`}>
                      {group?.values?.map((value: string) => (
                        <div
                          key={value}
                          className={cn(`${prefixCls}__product-options-item`, {
                            [`${prefixCls}__product-options-item--active`]:
                              currentVariant?.[`option${group?.position}`] === value,
                            [`${prefixCls}__product-options-item--out-of-stock`]: isVariantOutOfStock(group, value),
                          })}
                          onClick={() => handleVariantChange(group, value)}
                        >
                          <div className={`${prefixCls}__product-options-item-label`}>{value}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {showSwitchModeBtn && (
                <>
                  {isILV && (
                    <div className={`${prefixCls}__product-inventory`}>
                      Total Quantity: {inventoryQuantity === 1 ? `${inventoryQuantity} pc` : `${inventoryQuantity} pcs`}
                    </div>
                  )}
                  <div className={cn(`${prefixCls}__product-variants`, { show: isILV })}>{SkuTableComp}</div>
                  <div className={`${prefixCls}__product-mode-toggler`} onClick={changeViewMode}>
                    {isILV ? 'View Variants' : 'View Inventory Quantity'}
                  </div>
                </>
              )}

              <div className={`${prefixCls}__product-services`}>
                <span
                  className={cn([
                    `${prefixCls}__product-services-title`,
                    `${prefixCls}__product-services-title-shipping`,
                  ])}
                  dangerouslySetInnerHTML={{ __html: sanitize(productServices?.[0].title) }}
                />
                {productServices?.slice(0, 2)?.map((item: any, index: number) => (
                  <div key={item?.lead ?? index} className={`${prefixCls}__product-services-item`}>
                    <span dangerouslySetInnerHTML={{ __html: sanitize(item?.lead) }} />{' '}
                    <span dangerouslySetInnerHTML={{ __html: sanitize(item?.text) }} />
                  </div>
                ))}
              </div>
              <div className={`${prefixCls}__product-services`}>
                <span
                  className={cn([
                    `${prefixCls}__product-services-title`,
                    `${prefixCls}__product-services-title-return`,
                  ])}
                  dangerouslySetInnerHTML={{ __html: sanitize(productServices?.[2].title) }}
                />
                {productServices?.slice(2)?.map((item: any, index: number) => (
                  <div key={item?.lead ?? index} className={`${prefixCls}__product-services-item`}>
                    <span dangerouslySetInnerHTML={{ __html: sanitize(item?.lead) }} />{' '}
                    <span dangerouslySetInnerHTML={{ __html: sanitize(item?.text) }} />
                  </div>
                ))}
              </div>

              {isProductActive && (
                <div className={`${prefixCls}__product-actions`}>
                  <AccessControl requireAuth>
                    {({ isReject, showRedirectDialog }) =>
                      isProductOutOfStock || isCurrentVariantOutOfStock ? (
                        <Button
                          className={`${prefixCls}__product-actions-request-restock-btn`}
                          block
                          shape="rounded"
                          onClick={isReject ? showRedirectDialog : handleRequestRestock}
                        >
                          Request Restock
                        </Button>
                      ) : (
                        <Button
                          className={`${prefixCls}__product-actions-buy-btn`}
                          block
                          shape="rounded"
                          onClick={isReject ? showRedirectDialog : handleBuySamples}
                          disabled={
                            typeof productData?.product?.special_oos === 'boolean'
                              ? productData.product.special_oos
                              : false
                          }
                        >
                          Buy Stock
                        </Button>
                      )
                    }
                  </AccessControl>
                  <ImportButton
                    buttonColor="main"
                    className={`${prefixCls}__product-actions-import-btn`}
                    product={productData}
                    importProductsQueryParams={{ product_ids: [productData?.product?.id] }}
                    beforeShowImportDialog={
                      isProductDroppingSoon
                        ? () =>
                            new Promise((resolve) => {
                              beforeShowImportDialogResolver.current = resolve;
                              setImportDroppingSoonNoticeDialogVisible(true);
                            })
                        : undefined
                    }
                  />
                  <FavoriteButton className={`${prefixCls}__product-actions-favorite-btn`} product={productData} />
                </div>
              )}

              <Brand brand={brand} />

              {showBoutique && (
                <div className={`${prefixCls}__product-boutique`}>
                  <div className={`${prefixCls}__product-boutique-title`}>
                    Notes for Retailers
                    <Tooltip
                      overlayClassName={`${prefixCls}__tooltip`}
                      color="white"
                      title={
                        <div className={`${prefixCls}__tooltip-content`}>
                          This information is for your eyes only,
                          <br />
                          and will not be imported to your store.
                        </div>
                      }
                      placement="bottom"
                      trigger={['hover', 'click']}
                    >
                      <FiAlertCircle />
                    </Tooltip>
                  </div>
                  <div
                    className={`${prefixCls}__product-boutique-body`}
                    dangerouslySetInnerHTML={{ __html: sanitize(productData?.product?.body_html_no_import) }}
                  />
                </div>
              )}

              <Tabs className={`${prefixCls}__product-info-tabs`}>
                <Tabs.Tab
                  title={<span className={`${prefixCls}__product-info-tabs-title`}>Description</span>}
                  key="description"
                >
                  <div
                    className={`${prefixCls}__product-description`}
                    dangerouslySetInnerHTML={{ __html: sanitize(productData?.product?.body_html) }}
                  />
                </Tabs.Tab>
              </Tabs>

              <div className={`${prefixCls}__product-report-image-copyright-wrapper`}>
                <Button
                  className={`${prefixCls}__product-report-image-copyright`}
                  block
                  color="default"
                  shape="rounded"
                  onClick={openModalImageCopyRight}
                >
                  Report an issue with the listing
                </Button>
              </div>
            </div>
          </div>
        </StatusViewRenderer>
      </div>
      {productData && (
        <ModalReportImageCopyright ref={modalReportImageCopyrightRef} productId={productData?.product?.id} />
      )}

      {isProductDroppingSoon && (
        <>
          <Modal
            className={`${prefixCls}__buy-samples-exceeded-dialog`}
            visible={buySamplesExceededDialogVisible}
            title="Exceeded Max Quantity"
            onClose={() => setBuySamplesExceededDialogVisible(false)}
          >
            <div style={{ textAlign: 'center', fontSize: 16 }}>
              During the sample buying period you are limited to a max of {dropSoonInfo?.qty_limit ?? 0} pieces per
              listing.
            </div>
            <Button
              style={{ width: 200, margin: '12px auto 0' }}
              block
              color="main"
              shape="rounded"
              onClick={() => setBuySamplesExceededDialogVisible(false)}
            >
              Back
            </Button>
          </Modal>

          <Modal
            className={`${prefixCls}__import-dropping-soon-notice-dialog`}
            visible={importDroppingSoonNoticeDialogVisible}
            title="Notice"
            onClose={() => setImportDroppingSoonNoticeDialogVisible(false)}
          >
            <div style={{ textAlign: 'center', fontSize: 16 }}>
              This item has not dropped yet. You may import this item but the sales channel will be turned off until the
              official drop date.
            </div>
            <Button
              style={{ width: 200, margin: '12px auto 0' }}
              block
              color="main"
              shape="rounded"
              onClick={() => {
                beforeShowImportDialogResolver.current?.();
                setImportDroppingSoonNoticeDialogVisible(false);
                beforeShowImportDialogResolver.current = null;
              }}
            >
              Continue Import
            </Button>
          </Modal>
        </>
      )}

      <ProductAddToCartDialog
        visible={addToCartDialogVisible}
        product={productData}
        defaultVariant={currentVariant}
        onClose={() => setAddToCartDialogVisible(false)}
      />
    </div>
  );
}

export default ProductDetail;
