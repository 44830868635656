import { Switch } from 'antd-mobile';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getUpdatedSearchParams } from '@/utils/util';

import css from './index.module.scss';

export enum ProductPreviewMode {
  Grid = 'grid',
  List = 'list',
}

const searchParamsKey = 'inv';

const App = React.memo(
  ({
    onChange,
    value,
    bordered = false,
  }: {
    value: ProductPreviewMode;
    bordered?: boolean;
    onChange: (value: ProductPreviewMode) => void;
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [productPreviewMode, setProductPreviewMode] = useState<ProductPreviewMode>(value);
    const checked = value === ProductPreviewMode.List;

    const handleClick = (isChecked: boolean) => {
      const val = isChecked ? ProductPreviewMode.List : ProductPreviewMode.Grid;
      setSearchParams(getUpdatedSearchParams(searchParams, { [searchParamsKey]: val }));
      setProductPreviewMode(val);
    };

    useEffect(() => {
      if (value !== productPreviewMode) {
        onChange(productPreviewMode);
      }
    }, [value, productPreviewMode, onChange]);

    useEffect(() => {
      setProductPreviewMode(
        searchParams.get(searchParamsKey)
          ? (searchParams.get(searchParamsKey) as ProductPreviewMode)
          : ProductPreviewMode.Grid
      );
    }, [searchParams]);

    return (
      <div className={cn(css.ns_com_inv_list_view_switch_main, { [css.bordered]: bordered })}>
        Inventory View
        <Switch className={css.inv_list_view_switch} checked={checked} onChange={handleClick} />
      </div>
    );
  }
);

export default App;
