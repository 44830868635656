.ns_com_brand_comp {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  margin-top: 12px;
  padding: var(--spacing-m) var(--spacing-l);
  cursor: pointer;
  color: var(--color-black);
  border-radius: 8px;

  .brand_logo_wrapper {
    width: 68px;
    height: 68px;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-font-smoothing: antialiased;
    transform: translateZ(0);
    .brand_logo {
      width: 100%;
    }
  }

  .brand_name_wrapper {
    flex: 1;
    padding-left: 8px;
    .brand_name {
      font-size: var(--font-size14);
      font-weight: 500;
      color: var(--color-black);
    }
    .desc {
      font-size: 14px;
      color: #424242;
    }
  }

  .arrow {
    font-size: 18px;
  }
}
