import { Select } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BiSortAlt2 } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

import css from './index.module.scss';

const SORT_OPTIONS = {
  options: [
    { label: 'Newest', value: 'published_at_desc' },
    { label: 'Oldest', value: 'published_at_asc' },
    { label: 'Cost: Low to High', value: 'price_asc' },
    { label: 'Cost: High to Low', value: 'price_desc' },
  ],
  placeholder: (
    <span className={css.sort_icon}>
      <BiSortAlt2 />
    </span>
  ),
};
export const defaultFiltersItems = [
  // {
  //   label: 'Inventory',
  //   type: 'inv',
  //   options: [
  //     { label: 'More than 50', value: '50' },
  //     { label: 'More than 100', value: '100' },
  //   ],
  // },
  {
    label: 'Ship From',
    type: 'ship-from',
    options: [
      { label: 'United States', value: 'us' },
      { label: 'International', value: 'intl' },
    ],
  },
  // {
  //   label: 'Shipping Time',
  //   type: 'shipping-time',
  //   groups: [
  //     {
  //       label: 'To US',
  //       options: [
  //         { label: '2-5 business days', value: 'us-2-5' },
  //         { label: '5-10 business days', value: 'us-7-10' },
  //         { label: '8-12 business days', value: 'us-8-12' },
  //       ],
  //     },
  //     {
  //       label: 'To Canada',
  //       options: [{ label: '10-20 business days', value: 'ca-10-20' }],
  //     },
  //     {
  //       label: 'To Anywhere Else',
  //       options: [{ label: '7-14 business days', value: 'other-7-14' }],
  //     },
  //   ],
  // },
];

const filtersTypes = _.map(defaultFiltersItems, 'type');

export interface ProductFiltersChangedData {
  filters?: { [key: string]: string }[];
  sort_by?: string;
}
export interface ProductFiltersBarProps {
  className?: string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  showSort?: boolean;
  onChange?: (data: ProductFiltersChangedData) => void;
  sortOptions?: { label?: string | number; value?: string | number }[];
}

export function ProductFiltersBar({
  className,
  style,
  title = 'Discover all',
  showSort = true,
  onChange = () => {},
  sortOptions = [],
}: ProductFiltersBarProps): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState(() => _.pick(Object.fromEntries(searchParams), filtersTypes));
  const [sort, setSort] = useState(searchParams.get('sort_by'));
  const [filtersItems, setFiltersItems] = useState(defaultFiltersItems);
  const lastChangeType = useRef<string>(undefined);
  const currentSortOptions = sortOptions?.length ? sortOptions : SORT_OPTIONS.options;

  const emitChange = (filtersParam, sortParam) => {
    // sync to url search params
    const newSearchParams = _.omitBy(
      {
        ..._.omit(Object.fromEntries(searchParams), filtersTypes.concat('sort_by')),
        ...filtersParam,
        sort_by: sortParam,
      },
      _.isEmpty
    );
    setSearchParams(newSearchParams, { replace: true });
  };

  const handleFiltersChange = (type: string, value: string) => {
    lastChangeType.current = type;
    const newFilters = _.omitBy({ ...filters, [type]: value }, _.isEmpty);
    setFilters(newFilters);
    // when shipping time changed will auto change the ship from filter one more time
    // so need to skip the onFiltersChange on this scene
    if (type === 'shipping-time' && !_.isEmpty(value) && _.isNil(filters?.['ship-from'])) return;
    emitChange(newFilters, sort);
  };

  const handleSortChange = (value) => {
    setSort(value);
    emitChange(filters, value);
  };

  const renderSelectOption = (option: any) => (
    <Select.Option key={option?.value} label={option?.label} value={option?.value}>
      {option?.label}
    </Select.Option>
  );
  const renderSelectGroupOption = (group) => (
    <Select.OptGroup key={group?.label} label={group?.label}>
      {group?.options.map(renderSelectOption)}
    </Select.OptGroup>
  );

  useEffect(() => {
    const items = _.cloneDeep(defaultFiltersItems);
    const isShippingTimeChanged = lastChangeType.current === 'shipping-time';
    let availableShipFrom: string;

    if (filters?.['shipping-time'] === 'us-2-5') {
      availableShipFrom = 'us';
      _.pullAt(items[0].options, 1);
      isShippingTimeChanged && filters?.['ship-from'] !== 'us' && handleFiltersChange('ship-from', 'us');
    } else if (_.includes(['us-7-10', 'us-8-12', 'ca-10-20', 'other-7-14'], filters?.['shipping-time'])) {
      availableShipFrom = 'intl';
      _.pullAt(items[0].options, 0);
      isShippingTimeChanged && filters?.['ship-from'] !== 'intl' && handleFiltersChange('ship-from', 'intl');
    }

    if (filters?.['ship-from'] === 'us' || availableShipFrom === 'us') {
      _.pullAt(items[1].groups, 1, 2);
      _.pullAt(items[1].groups[0].options, 1, 2);
    } else if (filters?.['ship-from'] === 'intl' || availableShipFrom === 'intl') {
      _.pullAt(items[1].groups[0].options, 0);
    }

    setFiltersItems(items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const query = Object.fromEntries(searchParams);
    const filtersQuery = _.pick(query, filtersTypes);
    setFilters(filtersQuery);
    setSort(query?.sort_by);

    // convert to api required format
    const curFilters = _.map(filtersQuery, (v, k) => ({ type: k, value: v }));

    onChange({
      filters: curFilters,
      sort_by: query?.sort_by,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className={cn(css.ns_com_product_filters_bar_main, className)} style={style}>
      <div className={css.items}>
        <div className={css.wrapper}>
          {!!showSort && (
            <Select
              showArrow={false}
              className={cn(css.select, css.sort, {
                [css.active]: !!sort,
              })}
              popupClassName={css.ns_or_dropdown}
              value={sort}
              allowClear
              clearIcon={<FiX />}
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              size="middle"
              placeholder={SORT_OPTIONS.placeholder}
              getPopupContainer={(triggerNode) => triggerNode?.closest(`.${css.ns_com_product_filters_bar_main}`)}
              onChange={handleSortChange}
            >
              {currentSortOptions.map(renderSelectOption)}
            </Select>
          )}
          {filtersItems.map((item) => (
            <Select
              key={item?.label}
              className={cn(css.select, {
                [css.active]: item?.type in filters,
              })}
              popupClassName={css.ns_or_dropdown}
              value={filters?.[item?.type]}
              allowClear
              clearIcon={<FiX />}
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              size="middle"
              placeholder={item?.label}
              getPopupContainer={(triggerNode) => triggerNode?.closest(`.${css.ns_com_product_filters_bar_main}`)}
              onChange={(value) => handleFiltersChange(item?.type, value)}
            >
              {!_.isEmpty(item?.groups)
                ? item?.groups.map(renderSelectGroupOption)
                : item?.options.map(renderSelectOption)}
            </Select>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductFiltersBar;
