import _ from 'lodash';
import { useMemo } from 'react';

import { Product } from '@/types';

export enum SKUTableType {
  Checkbox,
  Inventory,
}

const firstColumnWidth = 100;

const useSkuTable = (product: Product) =>
  useMemo(() => {
    const { variants, option_color_idx, option_size_idx, options } = product || { options: [], variants: [] };
    const defaultColorDataIndex = 'color';
    const defaultSizeDataIndex = 'size';
    const mapDataIndexToText = {
      [defaultSizeDataIndex]: 'ONE SIZE',
      [defaultColorDataIndex]: 'ONE COLOR',
    };
    const colorOption = `option${option_color_idx}`;
    const sizeOption = `option${option_size_idx}`;
    const sizeValues = options?.[Number(option_size_idx) - 1]?.values || [];

    const variantsGroupByColor = option_color_idx
      ? _.groupBy(variants, (item) => item[colorOption])
      : { [defaultColorDataIndex]: [] };
    const variantsGroupBySize = option_size_idx
      ? _.groupBy(variants, (item) => item[sizeOption])
      : { [defaultSizeDataIndex]: [] };
    const variantsQtyGroupByColorAndSize = _.reduce(
      variantsGroupByColor,
      (result, value, key) => {
        const sizeQty = _.reduce(
          _.groupBy(value || [], (item) => item[sizeOption] || defaultSizeDataIndex),
          (res, val, ky) => {
            const qty = Math.max(val?.[0]?.inventory_params?.orderable_quantity || 0, 0);
            const pv = Math.max(val?.[0]?.inventory_params?.private_quantity || 0, 0);
            res[ky] = qty === 0 && pv === 0 ? '-' : `${qty || ''}${pv ? `+${pv}` : ''}`;
            return res;
          },
          {}
        );
        // eslint-disable-next-line no-param-reassign
        result[key] = sizeQty;
        return result;
      },
      {}
    );
    const variantsQtyGroupBySize = _.reduce(
      variantsGroupBySize,
      (result, value, key) => {
        const qty = Math.max(
          _.reduce(
            value || [],
            (sum, { inventory_params }) => sum + Number(inventory_params?.orderable_quantity || 0),
            0
          ),
          0
        );
        const pv = Math.max(
          _.reduce(
            value || [],
            (sum, { inventory_params }) => sum + Number(inventory_params?.private_quantity || 0),
            0
          ),
          0
        );
        // eslint-disable-next-line no-param-reassign
        result[key] = qty === 0 && pv === 0 ? '-' : `${qty || ''}${pv ? `+${pv}` : ''}`;
        return result;
      },
      {}
    );

    const tableHeader = option_size_idx
      ? [defaultColorDataIndex, ...sizeValues]
      : [defaultColorDataIndex, defaultSizeDataIndex];
    const tableColumns = tableHeader.map((dataIndex, index) => {
      const title = index === 0 ? '' : mapDataIndexToText[dataIndex] || dataIndex;
      let columnWidth = title?.length > 6 ? title.length * 10 : 60;
      if (index === 0) {
        columnWidth = firstColumnWidth;
      }

      return {
        title,
        dataIndex: index === 0 ? defaultColorDataIndex : dataIndex,
        key: dataIndex,
        width: columnWidth,
        fixed: index === 0 ? 'left' : '',
        align: index === 0 ? 'left' : 'center',
        render(entity) {
          return entity;
        },
      };
    });

    let tableData = [];
    if (option_color_idx || option_size_idx) {
      if (option_color_idx) {
        tableData = Object.keys(variantsGroupByColor).map((color) => ({
          ...variantsQtyGroupByColorAndSize[color],
          [defaultColorDataIndex]: color,
        }));
      } else {
        tableData = Object.keys(variantsQtyGroupBySize)?.length
          ? [{ ...variantsQtyGroupBySize, [defaultColorDataIndex]: mapDataIndexToText[defaultColorDataIndex] }]
          : [];
      }
    }
    const tableWidth = tableColumns.map(({ width }) => width).reduce((total, cur) => Number(total) + Number(cur), 0);

    return {
      tableWidth,
      dataSource: tableData,
      columns: tableColumns,
    };
  }, [product]);

export default useSkuTable;
