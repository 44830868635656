import { message } from 'antd';
import Link from 'antd/lib/typography/Link';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import Input from '@/components/common/Input';
import { SESSION_STORAGE_KEYS } from '@/constants/app';
import useCreateOrder from '@/hooks/queries/useCreateOrder';
import usePassCheckoutLimit from '@/hooks/useCheckoutLimit';
import useDesktop from '@/hooks/useDesktop';
import CartContext from '@/pages/Cart/cartContext';
import { formatterPrice } from '@/utils/util';

import { variantsM } from '../../constant';
import useCartItems from '../../useCartItems';
import css from './index.module.scss';

interface Props {
  data: any;
  visible: boolean;
}

const App = ({ data, visible }: Props) => {
  const { normalSelectedIds, originCartItems, scrollToShowErrorMessage } = useContext(CartContext);
  const { inStockItemsSpuDimension } = useCartItems(originCartItems, normalSelectedIds);
  const { checkoutLimit, passCheckoutLimit } = usePassCheckoutLimit();

  const lineItems = useMemo(
    () =>
      originCartItems
        ?.filter(({ variant_id }) => normalSelectedIds.includes(variant_id))
        .map(({ variant_id, qty }) => ({ variant_id: Number(variant_id), qty: Number(qty) })),
    [normalSelectedIds, originCartItems]
  ) as { variant_id: number; qty: number }[];
  const lineItemsPriceTotal = useMemo(() => {
    const totalPrice = originCartItems
      ?.filter(({ variant_id }) => normalSelectedIds.includes(variant_id))
      .map(({ price, qty }) => ({ qty: Number(qty), price: Number(price) }))
      .reduce((total, { qty, price }) => total + price * qty, 0);
    return formatterPrice(totalPrice);
  }, [normalSelectedIds, originCartItems]);

  const createOrder = useCreateOrder();
  const navigate = useNavigate();
  const [currentNote, setCurrentNote] = useState('');
  const isDesktop = useDesktop();
  const disabled = checkoutLimit.isLoading || _.isEmpty(data) || !normalSelectedIds.length;

  const setCheckoutParams = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.cartOrderParams, JSON.stringify({ lineItems, note: currentNote }));
  };

  const handleNoteChange = (note: string) => {
    setCurrentNote(note);
  };

  const handleToCheckout = () => {
    const hasSelectedIds = !!normalSelectedIds?.length;
    const hasErrorMessage = !!inStockItemsSpuDimension.filter(({ showMessage }) => !!showMessage)?.length;
    if (!hasSelectedIds) {
      message.warning('Please select one product at least.');
      return;
    }
    if (hasErrorMessage) {
      scrollToShowErrorMessage();
      return;
    }
    const cartItemsKeyByVariantId = _.keyBy(originCartItems, (c) => c.variant_id);
    const productCounts = _(lineItems)
      .groupBy((li) => cartItemsKeyByVariantId[li.variant_id]?.product_id)
      .mapValues((lis) => _.sumBy(lis, (li) => li.qty))
      .value();
    if (!passCheckoutLimit(productCounts)) {
      return;
    }
    setCheckoutParams();
    navigate('/orders/cart/checkout');
  };

  return (
    <motion.div
      variants={isDesktop ? null : variantsM}
      animate={visible ? 'show' : 'hide'}
      className={css.ns_comp_cart_checkout_section_actions}
      initial={false}
    >
      <div>
        <div className={css.notes}>
          <div className={css.title}>Order Notes</div>
          <div className={css.input_wrapper}>
            <Input
              className={css.input}
              size="large"
              placeholder="Enter"
              value={currentNote}
              onChange={handleNoteChange}
            />
          </div>

          <div className={css.info_item}>
            <div className={css.label}>Subtotal</div>
            <div className={css.text}>${lineItemsPriceTotal}</div>
          </div>

          <Button
            color="primary"
            shape="rounded"
            block
            loading={createOrder.isLoading}
            loadingText="Processing"
            disabled={disabled}
            onClick={handleToCheckout}
            className={css.cart_checkout_section_actions_button}
          >
            To Checkout
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default App;
