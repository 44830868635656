import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import { ElementRef, useCallback, useMemo, useRef } from 'react';

import Button from '@/components/Button';
import Modal, { Props as ModalProps } from '@/components/Modal';
import envconfig from '@/constants/envconfig';
import useAddShippingAddress from '@/hooks/queries/useAddShippingAddress';
import useUpdateShippingAddress from '@/hooks/queries/useUpdateShippingAddress';
import { ShippingAddress } from '@/types';

import Form from './Form';
import css from './FormModal.module.scss';

const stripe = loadStripe(envconfig.STRIPE_KEY);

export interface ShippingAddressesDialogProps extends Omit<ModalProps, 'children'> {
  title?: string;
}

type FormHandle = ElementRef<typeof Form>;

const FormModal = NiceModal.create(({ initialValues }: { initialValues?: ShippingAddress }) => {
  const isEditMode = !!initialValues;
  const title = isEditMode ? 'Edit Address' : 'Add a New Address';
  const modal = useModal();
  const formRef = useRef<FormHandle>(null);

  const updateShippingAddressRQ = useUpdateShippingAddress();
  const addShippingAddressRQ = useAddShippingAddress();

  const isButtonLoading = updateShippingAddressRQ.isLoading || addShippingAddressRQ.isLoading;

  const handleClose = useCallback(() => {
    modal.hide();
  }, [modal]);

  const handleSave = useCallback(async () => {
    if (formRef.current?.elements) {
      const addressElement = formRef.current?.elements.getElement('address');
      const { complete, value } = await addressElement.getValue();

      if (complete) {
        const {
          firstName,
          lastName,
          phone,
          address: { line1, line2, city, state, postal_code, country },
        } = value;

        const values = {
          first_name: firstName,
          last_name: lastName,
          address1: line1,
          address2: line2,
          city,
          country_code: country,
          phone,
          zip: postal_code,
          province_code: state,
        };

        if (initialValues?.id) {
          updateShippingAddressRQ.mutate(
            { id: initialValues?.id, params: values },
            {
              onSuccess() {
                message.success('Edit Address Success');
                handleClose();
              },
              onError() {
                message.error('Edit Address Error');
              },
            }
          );
        } else {
          addShippingAddressRQ.mutate(
            { ...values, default: true },
            {
              onSuccess() {
                message.success('Add Address Success');
                handleClose();
              },
              onError() {
                message.error('Add Address Error');
              },
            }
          );
        }
      }
    }
  }, [addShippingAddressRQ, handleClose, initialValues?.id, updateShippingAddressRQ]);

  const footer = useMemo(
    () => (
      <Button block color="primary" shape="rounded" loading={isButtonLoading} loadingText="Saving" onClick={handleSave}>
        Save
      </Button>
    ),
    [handleSave, isButtonLoading]
  );

  return (
    <Modal
      className={css.ns_com_addresses_edit_dialog_main}
      fullscreen="mobile"
      title={title}
      onClose={handleClose}
      footer={footer}
      visible={modal.visible}
    >
      <Elements stripe={stripe}>
        <Form initialValues={initialValues} ref={formRef} />
      </Elements>
    </Modal>
  );
});

export default FormModal;
