.sidebar_pc_title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
}
.sidebar_pc_text {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
  cursor: pointer;
}
.sidebar_pc_blank {
  height: 15px;
}
.sidebar_item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a,
  a:hover {
    color: #000;
  }
}
