.ns_com_app_layout_main {
  --app-layout-padding-top: var(--nav-bar-mobile-height);
  --app-layout-padding-bottom: var(--tab-bar-height);
  $zIndex: var(--p-z-index-2);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-gray1);
  .nav_bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $zIndex;
  }

  .container {
    padding: var(--app-layout-padding-top) 0 var(--app-layout-padding-bottom);
  }

  .tab_bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: $zIndex;
  }

  .footer {
    margin-top: auto;
  }

  .error_boundary_wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 769px) {
    --app-layout-padding-top: var(--nav-bar-desktop-height);
    --app-layout-padding-bottom: 0;

    .tab_bar {
      display: none;
    }

    .error_boundary_wrapper {
      top: calc(50% - var(--nav-bar-desktop-height));
    }
  }
}
