import { Space } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import { ShareHeartIcon } from '@/components/Icon';
import CartContext from '@/pages/Cart/cartContext';
import { useDeleteItemsInCart } from '@/pages/Cart/hook';

import { variantsM } from '../../constant';
import SaveForLaterButton from '../SaveForLaterButton';
import css from './index.module.scss';

const confirmModalTitle = 'Remove Items';
const confirmModalContent = 'Remove selected items from cart?';

const App = () => {
  const { updateCart, updateCartAPI } = useDeleteItemsInCart();
  const {
    isAllSelected,
    toggleIsAllSelected,
    normalSelectedIds,
    soldoutSelectedIds,
    isInManagementMode,
    isAllSelectedDisabled,
  } = useContext(CartContext);
  const selectedIds = [...normalSelectedIds, ...soldoutSelectedIds];
  const productsCount = selectedIds.length;
  const disabledButton = productsCount === 0;
  const { isLoading } = updateCart;

  const toBeRemovedData = {
    items: selectedIds.map((id) => ({
      variant_id: Number(id),
      qty: 0,
    })),
  };
  const onDelete = async () => {
    await updateCartAPI(toBeRemovedData, {
      onSuccess() {},
      onError() {},
    });
  };
  const handleRemove = () => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        onDelete();
      },
    });
  };

  const handleSelectAll = (isChecked) => {
    toggleIsAllSelected(isChecked);
  };

  return (
    <motion.div
      initial={false}
      variants={variantsM}
      animate={isInManagementMode ? 'show' : 'hide'}
      className={css.ns_comp_cart_manage_section_actions}
    >
      <div className={css.button_wrapper}>
        <div className={css.left}>
          <Checkbox
            checked={isAllSelected}
            value={isAllSelected}
            onChange={handleSelectAll}
            disabled={isAllSelectedDisabled}
            shape="square_line"
          />
          <span className={cn(css.left_text_select_all, { [css.disabled]: isAllSelectedDisabled })}>All</span>
        </div>
        <div className={css.right}>
          <Space>
            <Button
              icon={<FiTrash2 size={16} strokeWidth={1.5} />}
              disabled={disabledButton}
              loading={isLoading}
              color="default"
              size="small"
              onClick={handleRemove}
            >
              Remove
            </Button>
            <SaveForLaterButton selectedIds={selectedIds} disabled={disabledButton}>
              <Button
                icon={<ShareHeartIcon stroke={disabledButton ? '#999' : '#000'} />}
                disabled={disabledButton}
                loading={isLoading}
                color="primary"
                size="small"
              >
                Save for Later
              </Button>
            </SaveForLaterButton>
          </Space>
        </div>
      </div>
      <div className={css.placeholder} />
    </motion.div>
  );
};

export default App;
