import { Icon } from '@shopify/polaris';
import { BankMajor, CreditCardMajor } from '@shopify/polaris-icons';
import cn from 'classnames';
import { AiOutlineBank, AiOutlineCreditCard } from 'react-icons/ai';

import visa from '@/assets/card-icons/visa.png';

import { PaymentMethodTypeEnum } from '../types';
import css from './BankCreditIcon.module.scss';

const BankCreditIcon = ({ data }: { data: any }) => {
  const isBank = data?.type === PaymentMethodTypeEnum.Bank;
  const isVisa = data?.detail?.brand === 'visa';

  if (isBank)
    return (
      <span className={css.payment_method_list_item_icon}>
        <Icon source={BankMajor} color="base" />
      </span>
    );

  if (isVisa) return <img className={cn(css.payment_method_list_item_icon, css.img)} src={visa} alt="" />;

  return (
    <span className={css.payment_method_list_item_icon}>
      <Icon source={CreditCardMajor} color="base" />
    </span>
  );
};

export default BankCreditIcon;
