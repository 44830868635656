.ns_com_sales_timer_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;

  .sales_timer_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px;
  }
}
