export const DEFAULT_OPTIONS = [
  {
    value: 'cost_price',
    label: 'Shopify Cost',
  },
  {
    value: 'price',
    label: 'Shopify Price',
  },
];

export const formField = {
  percent: 'val',
  options: 'target',
  shippingFee: 'shippingFee',
};
