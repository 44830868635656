/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { message } from 'antd';
import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useBeforeUnload, useLatest } from 'react-use';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useAuth } from '@/hooks/auth';
import usePaymentMethodsTokens from '@/hooks/queries/usePaymentMethodsTokens';
import useUpdatePaymentMethodResult from '@/hooks/queries/useUpdatePaymentMethodResult';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import { TYPES_MAP } from '../cons';
import { PaymentMethodEditViewProps, PaymentMethodFormProps, PaymentMethodFormRef } from '../types';
import { emitPaymentMethodChangeEvent } from '../util';
import css from './EditView.module.scss';

const EditView = forwardRef<PaymentMethodFormRef, PaymentMethodEditViewProps>(
  ({ inlineMode, onRenderFooter }, ref): JSX.Element => {
    const [searchParams] = useSearchParams();
    let type = searchParams.get('dialog')?.split('/')?.[2] ?? 'card';
    if (!_.includes(['card', 'bank'], type)) type = 'card';
    const paymentMethodsTokens = usePaymentMethodsTokens({ payment_method_types: [TYPES_MAP?.[type]] });
    const paymentMethodsTokensData = paymentMethodsTokens?.data?.data;
    const stripePromise = useMemo(() => {
      if (_.isEmpty(paymentMethodsTokensData?.public_key)) return null;
      return loadStripe(paymentMethodsTokensData?.public_key);
    }, [paymentMethodsTokensData?.public_key]);
    const options: StripeElementsOptions = {
      clientSecret: paymentMethodsTokensData?.client_secret,
      locale: 'en',
      loader: 'never',
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#cee46a',
          colorBackground: '#ffffff',
          colorText: '#000000',
          colorDanger: '#ff5050',
          fontFamily: '"Source Sans Pro", sans-serif',
        },
        rules: {
          '.Label': {
            fontWeight: '600',
          },
          '.Input::placeholder': {
            color: '#ccc',
          },
        },
      },
    };

    return (
      <StatusViewRenderer
        isEmpty={false}
        isLoading={paymentMethodsTokens.isLoading}
        statusStyle={{ margin: inlineMode ? '32px auto' : '64px auto' }}
        className={css.ns_com_payment_methods_dialog_edit_view_main}
      >
        <Elements stripe={stripePromise} options={options}>
          <PaymentMethodForm ref={ref} type={type} inlineMode={inlineMode} onRenderFooter={onRenderFooter} />
        </Elements>
      </StatusViewRenderer>
    );
  }
);

const PaymentMethodForm = forwardRef<PaymentMethodFormRef, PaymentMethodFormProps>(
  ({ type, inlineMode, onRenderFooter }, ref): JSX.Element => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const [searchParams] = useSearchParams();
    const isInit = !_.isNil(searchParams.get('init'));
    const updatePaymentMethodResult = useUpdatePaymentMethodResult();
    const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
    const [isReady, setIsReady] = useState(false);
    const [asDefault, setAsDefault] = useState(type !== 'bank');
    const [isSaving, setIsSaving] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [isAllComplete, setIsAllComplete] = useState(false);
    const latestAsDefault = useLatest(asDefault);

    useBeforeUnload(isChanged, 'You have unsaved changes, are you sure?');

    const handleSubmit = async (
      event: any,
      options?: { onSuccess?: (paymentMethod: any) => void; onError?: (error: unknown) => void }
    ) => {
      event?.preventDefault?.();

      if (!stripe || !elements) {
        return;
      }

      setIsSaving(true);

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        setIsSaving(false);

        if (_.isFunction(options?.onError)) {
          options.onError(error);
          return;
        }

        message.error(error.message);
      } else {
        updatePaymentMethodResult.mutate(
          {
            success: true,
            is_default: latestAsDefault.current,
            setup_intent_id: setupIntent?.id,
            payment_method_id: setupIntent?.payment_method,
          },
          {
            onSettled: (resData, err) => {
              setIsSaving(false);

              if (err) {
                if (_.isFunction(options?.onError)) {
                  options.onError(err);
                  return;
                }

                message.error((err as Error)?.message || 'Save Card Failure!');
              } else {
                if (_.isFunction(options?.onSuccess)) {
                  options.onSuccess(resData?.data);
                  return;
                }

                message.success('Save Card Success!');

                if (isInit) {
                  emitPaymentMethodChangeEvent(resData?.data);
                  navigate(
                    getUpdatedSearchParamsPath({ dialog: null, init: null, selectMode: null, paymentMethodType: null }),
                    {
                      state: location.state,
                      replace: true,
                    }
                  );
                  return;
                }

                // 如果是在payment页面，添加成功关闭弹窗
                if (window.location.pathname === '/payment-methods') {
                  // 去除所有参数，关闭弹窗
                  navigate(-1);
                  return;
                }

                navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/list' }), {
                  state: location.state,
                  replace: true,
                });
              }
            },
          }
        );
      }
    };

    useEffect(() => {
      onRenderFooter?.(
        !isReady ? undefined : (
          <Button
            block
            color="primary"
            shape="rounded"
            loading={isSaving}
            loadingText="Saving"
            disabled={!isAllComplete}
            onClick={handleSubmit}
          >
            Save Card
          </Button>
        )
      );

      return () => onRenderFooter?.(undefined);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, isSaving, isAllComplete, onRenderFooter]);

    useImperativeHandle(ref, () => ({
      save: () =>
        new Promise((resolve) => {
          handleSubmit(null, {
            onSuccess: (paymentMethod) => resolve({ error: null, paymentMethod }),
            onError: (error: Error) => resolve({ error, paymentMethod: null }),
          });
        }),
    }));

    return (
      <div className={css.payment_method_form}>
        <form onSubmit={handleSubmit}>
          {!isReady && (
            <StatusViewRenderer
              isEmpty={false}
              isLoading
              statusStyle={{ margin: inlineMode ? '32px auto' : '64px auto' }}
            />
          )}

          <div>
            <PaymentElement
              options={{
                terms: { card: 'never', usBankAccount: 'never' },
                wallets: { applePay: 'never', googlePay: 'never' },
                defaultValues: {
                  billingDetails: {
                    name: _.join(_.compact([user?.first_name, user?.last_name]), ' '),
                    email: user?.email || user?.email_pending,
                  },
                },
              }}
              onChange={(event) => {
                setIsChanged(!event.empty);
                setIsAllComplete(event.complete);
              }}
              onReady={() => setIsReady(true)}
            />

            {isReady && !inlineMode && type !== 'bank' && (
              <div className={css.set_as_default_wrapper}>
                <Checkbox checked={asDefault} onChange={(checked) => setAsDefault(checked)}>
                  Set as default
                </Checkbox>

                <div className={css.set_as_default_description}>
                  The default card will be used in auto checkout, and is the default payment method at checkout.
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
);

export default EditView;
