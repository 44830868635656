.ns_com_phone_input_main {
  .ns_or_phone_input {
    width: 100%;
    height: 44px;
    padding-left: 108px !important;
    padding-right: 8px !important;
    color: var(--color-black);
    font-size: var(--font-size5);
    line-height: 1.15;
    border: 0;
    border-radius: 5px;
    outline: none;

    &::placeholder {
      color: var(--color-gray3);
    }
  }
  &.dropdown_disabled {
    :global {
      .selected-flag .selected-dial-code {
        display: none !important;
      }
      .selected-flag .arrow {
        display: none;
      }
    }
  }
  // TODO: 注意此类名在 ShippingAddressesDialog 组件中有被用到
  &:global(.phone-input) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 0;
    border: 3px solid var(--color-gray5);
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  :global {
    .phone-input__input {
      // TODO: 注意此类名在 ShippingAddressesDialog 组件中有被用到
      width: 100%;
      height: 44px;
      padding-left: 108px !important;
      padding-right: 8px !important;
      color: var(--color-black);
      font-size: var(--font-size5);
      line-height: 1.15;
      border: 0;
      border-radius: 5px;
      outline: none;

      &::placeholder {
        color: var(--color-gray3);
      }
    }
    .selected-flag {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100px !important;
      border: 3px solid var(--color-white);
      border-radius: 5px;
      background: #f5f5f5 !important;
      outline: none;

      .iti-flag {
        width: auto;
        height: auto;
        font-size: var(--font-size4);
        line-height: 1.2;
        background: none;
        box-shadow: none;
      }

      .selected-dial-code {
        display: inline !important;
        margin-left: var(--spacing-xs);
        padding-left: 0 !important;
        // order: 3;
        font-size: var(--font-size4);
        line-height: 1.2;
        pointer-events: none;
      }

      .arrow {
        margin-left: var(--spacing-xxs) !important;
        font-size: 12px !important;
        transform: scale(0.7) !important;
        pointer-events: none;
      }
    }

    .country-list {
      width: 100%;
    }

    .iti-mobile .intl-tel-input.iti-container {
      top: var(--spacing-l);
      bottom: var(--spacing-l);
      left: var(--spacing-l);
      right: var(--spacing-l);
    }
  }
}
