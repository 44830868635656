import { message } from 'antd';
import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { updateCategoryForRetail } from '@/services/jennifer';

import { hideEditStateModal, showConfirmStateModal } from '../store';

export const useUpdateCatalogProduct = () => {
  const mutation = useMutation(updateCategoryForRetail, {
    // onSuccess() {
    //   console.log('useUpdateCatalogProduct mutation success');
    //   hideEditStateModal();
    //   showConfirmStateModal();
    // },
    // onError(error) {
    //   message.error(error || 'Unknown Error');
    // },
  });

  return useMemo(
    () => ({
      mutation,
    }),
    [mutation]
  );
};
