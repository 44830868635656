import Button, { Props as ButtonProps } from '@/components/Button';
import { useAuth } from '@/hooks/auth';

export interface Props extends ButtonProps {
  order?: any;
}

function OrderChangeAddressButton({ order, children = 'Change Address', ...resetProps }: Props): JSX.Element {
  const { user } = useAuth();
  let hubspotFormLink = 'https://share.hsforms.com/1C6vbdBJ5T-qlaUTqgBmZhAcdyi8';
  hubspotFormLink += `?return_order_number=${encodeURIComponent(order?.name)}`;
  hubspotFormLink += `&phone=${encodeURIComponent(user?.phone_country + user?.phone)}`;
  hubspotFormLink += `&email=${encodeURIComponent(order?.contact_email)}`;

  return (
    <Button size="small" color="default" target="_blank" link={hubspotFormLink} {...resetProps}>
      {children}
    </Button>
  );
}

export default OrderChangeAddressButton;
