import { message } from 'antd';
import { useState } from 'react';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modal';
import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import useOrderAddProcess from '@/hooks/queries/useOrderAddProcess';

import type { OrderItem } from '../../types';
import css from './index.module.scss';

type ModalProps = {
  selectedIds: string[];
  orders: OrderItem[];
  color?: 'main' | 'primary';
  successCallback?: () => void;
};

const App = ({ selectedIds, orders, color = 'main', successCallback = () => {} }: ModalProps) => {
  const { mutate, isLoading } = useOrderAddProcess();
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(true);
  const singleOrderNumber =
    selectedIds?.length === 1 ? orders.filter(({ id }) => id === selectedIds[0])?.[0]?.name : null;
  const oneOrderDesc = `This order ${singleOrderNumber} will begin to process`;
  const moreThanOneOrderDesc = `[${selectedIds?.length}] order[s] will begin to process`;
  const desc = singleOrderNumber ? oneOrderDesc : moreThanOneOrderDesc;

  const onProcess = (onSuccess?: () => void) => {
    mutate(
      { order_ids: selectedIds },
      {
        onSuccess() {
          successCallback?.();
          onSuccess?.();
          setVisible(false);
          message.success(`${selectedIds.length > 1 ? 'Orders' : 'Order'} processed successfully`);
        },
        onError(e: Error) {
          message.error(e.message);
        },
      }
    );
  };

  const toProcess = () => {
    const hideProcessOrdersModal = localStorage.getItem(LOCAL_STORAGE_KEYS.hideProcessOrdersModal);
    if (hideProcessOrdersModal) {
      onProcess();
    } else {
      setVisible(true);
    }
  };

  const confirmProcess = () => {
    onProcess(() => {
      if (checked) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.hideProcessOrdersModal, 'true');
      }
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Button loading={isLoading} color={color} size="small" onClick={toProcess}>
        Process
      </Button>
      <Modal
        title="Process Orders"
        visible={visible}
        onClose={onClose}
        className={css.order_page_modal_process_orders}
        footer={null}
      >
        <div className={css.modal_process_orders_content}>
          <div>{desc}</div>
          <Button loading={isLoading} shape="rounded" color="main" onClick={confirmProcess}>
            Confirm
          </Button>
          <div className={css.show_again}>
            <Checkbox color="primary" checked={checked} onChange={setChecked} />
            <span>Don’t show again</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default App;
