.EditStateModal_content {
  display: flex;
  flex-direction: column;
  .rowItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .btnWrap {
    width: 250px;
    margin: 20px auto 0;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .EditStateModal_content {
    gap: 20px;
    margin: -10px;
    .rowItem {
      padding: 0;
    }
    .btnWrap {
      width: 100%;
      margin: 0;
    }
  }
}
