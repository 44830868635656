import { useEffect, useMemo, useState } from 'react';

import { SHOW_GUIDE_STATE } from '../def';

export const useGuideState = () => {
  const [showGuideModalShow, setGuideModalShowState] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage) {
      const result = localStorage.getItem(SHOW_GUIDE_STATE);
      if (!result) {
        setGuideModalShowState(true);
      }
    }
  }, []);

  const disableGuideModal = () => {
    setGuideModalShowState(false);
    localStorage.setItem(SHOW_GUIDE_STATE, SHOW_GUIDE_STATE);
  };

  return useMemo(
    () => ({
      showGuideModalShow,
      disableGuideModal,
    }),
    [showGuideModalShow]
  );
};
