$thumbnailWidth: 26px;

.ns_com_product_list_item_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  cursor: pointer;

  .cover {
    position: relative;

    &:hover {
      .swiper_wrapper {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }

    .ns_or_image {
      width: 100%;
      // min-height: 240px;
      border-radius: 8px;
      position: relative;
      padding-top: 130%;
      :global {
        .adm-image-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
      }
    }

    .swiper_wrapper {
      --swiper-navigation-size: 12px;
      --swiper-pagination-color: var(--color-white);
      border-radius: 8px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      user-select: none;
      z-index: 0;
      position: relative;

      .swiper_pagination {
        bottom: 0;
        text-align: center;
        line-height: 18px;
      }
      .swiper_image_wrapper {
        width: 100%;
        border-radius: 8px;
        position: relative;
        padding-top: 130%;
        .img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        visibility: hidden;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        background-color: #ffffffbf;
        box-shadow: 0 2px 8px #0000001f;
        transition: all 0.2s ease;
        color: var(--color-black);
        &::after {
          position: absolute;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .swiper-button-prev {
        left: var(--spacing-m);
      }
      .swiper-button-next {
        right: var(--spacing-m);
      }

      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }

    // .mask_header {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   width: 100%;
    //   padding: var(--spacing-m) var(--spacing-m) var(--spacing-m);
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    //   z-index: 1;
    //   .product_tag {

    //   }
    // }

    .mask_footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: var(--spacing-m) var(--spacing-m) var(--spacing-m);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 26px;
      z-index: 1;
    }

    .mask_top_left_label {
      position: absolute;
      left: 0;
      top: 10px;
    }
  }

  .product_tag_wrapper {
    background: #ffcc17;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.3;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    padding: 4px 8px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    position: absolute;
    top: var(--spacing-m);
    left: 0;
  }

  .favorite_btn_wrapper {
    position: absolute;
    top: var(--spacing-m);
    right: var(--spacing-m);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background: #ffffffbf;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    z-index: 1;
  }
  .shipping_label_wrapper {
    position: absolute;
    top: var(--spacing-m);
    left: var(--spacing-m);
  }
  .favorite_btn {
    position: relative;
    width: 16px;
    height: 16px;

    // extend button touchable area
    &::after {
      content: '';
      position: absolute;
      top: -14px;
      left: -14px;
      right: -14px;
      bottom: -14px;
    }

    :global {
      .favorite-button__icon {
        width: 16px;
        height: 16px;
        stroke-width: 3;
      }

      .favorite-button__loading {
        transform: translate(-50%, -70%) scale(0.5);
      }
    }
    &:not(:global(.favorite-button--favorited)) {
      :global {
        .favorite-button__icon {
          fill: none;
        }
      }
    }
  }

  .title {
    margin-top: var(--spacing-xs);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
  }
  .price {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-xs);
    color: var(--color-black);
    font-size: var(--font-size2);
    font-weight: 500;
    line-height: 18px;

    .price_compare {
      margin-left: var(--spacing-xs);
      color: var(--color-gray4);
      font-weight: normal;
      text-decoration: line-through;
    }
    .price_sale_rate {
      display: inline-block;
      margin-left: var(--spacing-xs);
      padding: 1px 2px;
      color: var(--color-black);
      font-size: var(--font-size1);
      line-height: 1;
      font-weight: normal;
      background: #e3f4b1;
      border-radius: 2px;
    }
    .price_earn_boost {
      font-weight: 500;
      font-size: 9px;
      line-height: 100%;
      padding: 2px 4px;
      background: #ffcc17;
      border-radius: 2px;
      margin-left: 4px;
    }
  }
  .delay_tag {
    color: var(--color-danger);
    display: flex;
    align-items: center;
    .dot {
      padding: 0;
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: var(--color-danger);
    }
  }
  .tags {
    margin-top: var(--spacing-xs);

    &:empty {
      display: none;
    }
  }
  &.out_of_stock {
    .cover .image,
    .info {
      opacity: 0.4;
    }
    .cover {
      .mask_footer {
        justify-content: center;
      }
    }
  }
  &.price_locked {
    .price {
      display: flex;
      align-items: center;
      color: var(--color-gray5);
      font-weight: normal;
      :global {
        .react-icons {
          margin-right: var(--spacing-xs);
          font-size: 18px;
          line-height: 0;
        }
      }
    }
  }

  .status_label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-xxs) var(--spacing-xs);
    color: var(--color-white);
    font-size: var(--font-size1);
    line-height: 1.4;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

    i {
      margin-right: var(--spacing-xs);
      padding: 0;
      color: var(--color-danger);
      line-height: 0;
    }
  }

  .center_label_wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    display: none;

    &.product_label {
      display: block;
      background-color: rgba(242, 242, 242, 0.85);
    }

    &.hot_flash {
      display: block;
      background-color: #000000cc;
      color: #f5f5f5;
    }
  }

  .thumbnails_colors_wrapper {
    margin-top: var(--spacing-m);
    display: flex;
    .thumbnails_colors_item_wrapper {
      display: flex;
      .thumbnails_colors_item {
        overflow: hidden;
        box-sizing: border-box;
        width: $thumbnailWidth;
        height: $thumbnailWidth;
        border-radius: 100%;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-gray1);
        position: relative;
        border: 1px solid transparent;

        img {
          width: $thumbnailWidth;
          height: $thumbnailWidth;
          border-radius: 100%;
        }

        &.checked {
          border: 1px solid #000;
          background-color: var(--color-white);
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: calc($thumbnailWidth - 2px);
            height: calc($thumbnailWidth - 2px);
            border-radius: 100%;
            border: 1px solid #fff;
          }
        }

        &.disabled {
          // 划线
          &::before {
            content: '';
            width: calc($thumbnailWidth - 2px);
            height: 1px;
            background-color: #c1c1c1;
            transform: rotate(45deg);
            z-index: 1;
            position: absolute;
          }
          &::after {
            content: '';
            width: $thumbnailWidth;
            height: $thumbnailWidth;
            border-radius: 100%;
            z-index: 0;
            background: rgb(255 255 255 / 70%);
            position: absolute;
          }

          &.checked {
            background-color: var(--color-white);
            &::before {
              width: calc($thumbnailWidth - 4px);
              border-color: transparent;
              border: none;
              border-radius: 0;
            }
            &::after {
              width: calc($thumbnailWidth - 2px);
              height: calc($thumbnailWidth - 2px);
              border: 1px solid #fff;
            }
          }
        }

        &:not(:first-child) {
          margin-left: var(--spacing-m);
        }
      }
    }
    .thumbnails_colors_more {
      width: $thumbnailWidth;
      height: $thumbnailWidth;
      line-height: $thumbnailWidth;
      text-align: center;
      font-size: 12px;
      border-radius: 100%;
      margin-left: var(--spacing-m);
    }
  }

  @at-root .is_pc {
    @media (hover: hover) {
      .cover {
        .mask_header {
          overflow: hidden;
        }
        .mask_footer {
          overflow: hidden;
        }
      }

      .import_btn:not(:global(.import-button--importing)) {
        transform: translateY(200%);
        transition: all 0.25s ease;
      }

      &:hover .import_btn {
        transform: translateY(0);
      }
    }
  }
}
