import { FiChevronRight } from 'react-icons/fi';

import css from './index.module.scss';

interface Props {
  label?: string;
  buttonText?: string;
  onClick?: () => void;
}
const ArrowButton = (props: Props) => {
  const { onClick, label, buttonText } = props;
  return (
    <div className={css.arrow_button_box}>
      <span className={css.label}>{label}</span>
      <div className={css.btn} onClick={onClick}>
        {buttonText}
        <FiChevronRight strokeWidth={1} />
      </div>
    </div>
  );
};
export default ArrowButton;
