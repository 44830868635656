import { AlphaStack, Box, Checkbox, Inline, Spinner } from '@shopify/polaris';
import { Form, message } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import Modal, { Props as ModalProps } from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useDeleteShippingAddress from '@/hooks/queries/useDeleteShippingAddress';
import usePrivateShippingAddresses from '@/hooks/queries/usePrivateShippingAddresses';
import useShippingAddresses from '@/hooks/queries/useShippingAddresses';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import AddressesContext from './addressesContext';
import { ADDRESS_DIALOG_PATH, ADDRESSES_DIALOG_VIEW_TYPES } from './cons';
import type { EditViewProps } from './EditView';
import EditView from './EditView';
import css from './index.module.scss';
import ListView from './ListView';
import { AddressesDialogViewType } from './types';
import { emitAddressChangeEvent, getResetSearchParams } from './util';

export interface ShippingAddressesDialogProps extends Omit<ModalProps, 'children'> {}

const ListWithPrivateLazy = lazy(() => import('./ListWithPrivate'));

const confirmModalTitle = 'Remove Address';
const confirmModalContent = 'Remove this address from your Shipping Addresses?';

const getTitle = (viewType: AddressesDialogViewType) => {
  if (viewType === ADDRESSES_DIALOG_VIEW_TYPES.add) return 'Add a New Address';
  if (viewType === ADDRESSES_DIALOG_VIEW_TYPES.edit) return 'Edit Address';
  if (viewType === ADDRESSES_DIALOG_VIEW_TYPES.list_inv) return 'Ship to';
  return 'Shipping Addresses';
};

const ShippingAddressesDialog = ({ onClose, visible, ...restProps }: ShippingAddressesDialogProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const userSettingsRQ = useUserSettings();
  const shipping_address_remembered = userSettingsRQ?.data?.data?.shipping_address_remembered;

  const addressesRQ = useShippingAddresses();
  const privateAddressesRQ = usePrivateShippingAddresses();
  const addressesOrigin = useMemo(() => addressesRQ?.data?.data, [addressesRQ?.data]);
  const privateAddressesOrigin = useMemo(() => privateAddressesRQ?.data?.data, [privateAddressesRQ?.data]);
  const [checked, setChecked] = useState(false);

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [editViewState, setEditViewState] = useState<any>();
  const deleteShippingAddress = useDeleteShippingAddress();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const viewQ = useMemo(() => {
    const viewOrigin = searchParams.get('dialog')?.split('/')?.[1] as AddressesDialogViewType;
    if (!Object.keys(ADDRESSES_DIALOG_VIEW_TYPES).includes(viewOrigin)) return ADDRESSES_DIALOG_VIEW_TYPES.list;
    return viewOrigin;
  }, [searchParams]);
  const isSelectable = !!searchParams.get('selectMode');
  const addressKeyQ = searchParams.get('addressKey');
  const fromQ = searchParams.get('from');
  const hideRememberQ = searchParams.get('hideRemember');

  const showEditView = _.includes([ADDRESSES_DIALOG_VIEW_TYPES.add, ADDRESSES_DIALOG_VIEW_TYPES.edit], viewQ);
  const isPrivateListView = _.includes([ADDRESSES_DIALOG_VIEW_TYPES.list_inv], viewQ);

  const [activeKey, setActiveKey] = useState(null);

  const showRememberCheckbox = useMemo(() => {
    if (hideRememberQ) return false;

    if (isPrivateListView) {
      return !(!!activeKey && shipping_address_remembered === activeKey);
    }

    return true;
  }, [activeKey, hideRememberQ, isPrivateListView, shipping_address_remembered]);

  const handleSave = useCallback(() => {
    form.submit();
  }, [form]);

  const handleClose = useCallback(() => {
    navigate(getUpdatedSearchParamsPath(getResetSearchParams()), {
      state: location.state,
      replace: true,
    });
  }, [getUpdatedSearchParamsPath, location.state, navigate]);

  const handleDeleteAddress = async (address?: any) => {
    const id = address?.id ?? searchParams.get('dialog')?.split('/')?.[2] ?? '';

    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        deleteShippingAddress.mutate(id, {
          onSettled: (resData, error) => {
            error
              ? message.error((error as Error)?.message || 'Delete Address Failure!')
              : navigate(
                  getUpdatedSearchParamsPath({
                    dialog:
                      fromQ === ADDRESSES_DIALOG_VIEW_TYPES.list_inv
                        ? ADDRESS_DIALOG_PATH.list_inv
                        : ADDRESS_DIALOG_PATH.list,
                  }),
                  {
                    state: location.state,
                    replace: true,
                  }
                );
          },
        });
      },
    });
  };

  const onBack = useCallback(() => {
    if (showEditView) {
      navigate(
        getUpdatedSearchParamsPath({
          dialog:
            fromQ === ADDRESSES_DIALOG_VIEW_TYPES.list_inv ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
        }),
        { replace: true }
      );
      return;
    }

    handleClose();
  }, [fromQ, getUpdatedSearchParamsPath, handleClose, navigate, showEditView]);

  const handleSelect = useCallback(() => {
    if (!activeKey) return;
    const address = [...(addressesOrigin || []), ...(privateAddressesOrigin || [])].find(
      ({ key }) => activeKey === key
    );
    emitAddressChangeEvent({ address, user, remember_address: checked });
    navigate(getUpdatedSearchParamsPath(getResetSearchParams()), {
      state: location.state,
      replace: true,
    });
  }, [
    activeKey,
    addressesOrigin,
    checked,
    getUpdatedSearchParamsPath,
    location.state,
    navigate,
    privateAddressesOrigin,
    user,
  ]);

  const handleChecked = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  const title = getTitle(viewQ as AddressesDialogViewType);

  const headerActions =
    viewQ === ADDRESSES_DIALOG_VIEW_TYPES.edit
      ? [
          {
            className: css.delete_btn,
            icon: <FiTrash2 size={18} />,
            onClick: () => handleDeleteAddress(),
          },
        ]
      : undefined;

  const modalFooter = useMemo(() => {
    if (showEditView) {
      return (
        <Button
          block
          color="primary"
          shape="rounded"
          loading={editViewState?.isSaving}
          loadingText="Saving"
          onClick={handleSave}
        >
          Save
        </Button>
      );
    }

    if (isSelectable) {
      return (
        <AlphaStack>
          {showRememberCheckbox && (
            <Checkbox label="Use this address next time" checked={checked} onChange={handleChecked} />
          )}
          <Button
            block
            color="primary"
            shape="rounded"
            loadingText="Saving"
            onClick={handleSelect}
            disabled={!activeKey}
          >
            Save
          </Button>
        </AlphaStack>
      );
    }

    return null;
  }, [
    activeKey,
    checked,
    editViewState?.isSaving,
    handleChecked,
    handleSave,
    handleSelect,
    isSelectable,
    showEditView,
    showRememberCheckbox,
  ]);

  const content = useMemo(() => {
    if (showEditView)
      return (
        <Box padding="3">
          <EditView mode={viewQ as EditViewProps['mode']} form={form} onStateChange={setEditViewState} />
        </Box>
      );

    if (isPrivateListView) {
      return (
        <Suspense
          fallback={
            <Box paddingBlockEnd="16" paddingBlockStart="16">
              <Inline align="center">
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </Inline>
            </Box>
          }
        >
          <Box padding="3" paddingBlockStart="0">
            <ListWithPrivateLazy />
          </Box>
        </Suspense>
      );
    }

    return (
      <Box padding="3">
        <ListView />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isSelectable, showEditView, isPrivateListView, viewQ]);

  const modalClassNames = cn(
    css.ns_com_shipping_address_dialog_main,
    { [css.list_view]: viewQ === ADDRESSES_DIALOG_VIEW_TYPES.list },
    { [css.list_inv_view]: viewQ === ADDRESSES_DIALOG_VIEW_TYPES.list_inv },
    { [css.edit_view]: viewQ === ADDRESSES_DIALOG_VIEW_TYPES.edit },
    { [css.add_view]: viewQ === ADDRESSES_DIALOG_VIEW_TYPES.add },
    { [css.visible]: visible }
  );

  const contextValue = {
    isSelectable,
    activeKey,
    setActiveKey,
    handleDeleteAddress,
  };

  useEffect(() => {
    if (addressKeyQ) {
      setActiveKey(addressKeyQ);
    }
  }, [addressKeyQ]);

  return (
    <AddressesContext.Provider value={contextValue}>
      <Modal
        className={modalClassNames}
        fullscreen="mobile"
        title={title}
        showBack={[ADDRESSES_DIALOG_VIEW_TYPES.add, ADDRESSES_DIALOG_VIEW_TYPES.edit].includes(viewQ)}
        onBack={onBack}
        headerActions={headerActions}
        footer={modalFooter}
        onClose={handleClose}
        visible={visible}
        {...restProps}
      >
        {content}
      </Modal>
    </AddressesContext.Provider>
  );
};

export default ShippingAddressesDialog;
