import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AutoLink, { isEmptyTargetData } from '@/components/AutoLink';
import useSettings from '@/hooks/queries/useSettings';
import { clickNav } from '@/utils/ga';

import { NavItemTypes as ColumnItemTypes } from '../type';
import css from './index.module.scss';

const PCNavMenu = () => {
  const [hoverActiveKey, setHoverActiveKey] = useState('');
  const [currentNavKey, setCurrentNavKey] = useState('');
  const [activeColumns, setActiveColumns] = useState([]);
  const settings = useSettings();
  const navPCSettings = useMemo(
    () => settings?.data?.data?.['nav-pc']?.data?.filter((i) => !i.hidden),
    [settings?.data?.data]
  );
  const contentRef = useRef(null);
  const delayTimer = useRef(null);

  const clearDelayTimer = () => {
    if (delayTimer.current) {
      clearTimeout(delayTimer.current);
      delayTimer.current = null;
    }
  };
  const delaySetPopupVisible = (key?: string) => {
    const delay = 300;
    clearDelayTimer();
    if (delay) {
      delayTimer.current = window.setTimeout(() => {
        setHoverActiveKey(key || '');
        clearDelayTimer();
      }, delay);
    } else {
      setHoverActiveKey(key || '');
    }
  };

  const handleMouseEnter = (key) => {
    clearDelayTimer();
    delaySetPopupVisible(key);
  };

  const handleMouseLeave = () => {
    delaySetPopupVisible();
  };

  const handlePopupMouseEnter = () => {
    clearDelayTimer();
  };

  const handlePopupMouseLeave = () => {
    delaySetPopupVisible();
  };

  const handleLinkClick = () => {
    setCurrentNavKey(hoverActiveKey);
    setActiveColumns([]);
    setHoverActiveKey('');
  };

  const renderAutoLink = (i) => {
    const { type, target_type, target_data, image_url, title } = i;
    if (type === ColumnItemTypes.Blank) {
      return null;
    }

    if (type === ColumnItemTypes.Image) {
      if (!image_url) return null;

      return (
        <AutoLink targetType={target_type} targetData={target_data} onClick={handleLinkClick}>
          <img className={css.img} src={image_url} alt={`${title}`} />
          <div className={css.title}>{title}</div>
        </AutoLink>
      );
    }

    if (isEmptyTargetData(target_data)) {
      return title;
    }

    return (
      <AutoLink targetType={target_type} targetData={target_data} onClick={handleLinkClick}>
        {title}
      </AutoLink>
    );
  };

  useEffect(() => {
    if (hoverActiveKey && navPCSettings) {
      // !!!columns最多配置5个
      const curColumns = navPCSettings.filter((d, idx) => `${idx}` === hoverActiveKey)?.[0]?.columns?.slice(0, 5) || [];
      setActiveColumns(curColumns);
    } else {
      setActiveColumns([]);
    }
  }, [hoverActiveKey, navPCSettings]);

  if (!navPCSettings) return null;

  return (
    <div className={css.ns_com_pc_nav_manu_main}>
      <div className={css.basic_nav_wrapper}>
        <Swiper
          modules={[FreeMode, Navigation, Mousewheel]}
          direction="horizontal"
          slidesPerView="auto"
          freeMode
          mousewheel={{
            forceToAxis: true,
            thresholdTime: 300,
          }}
          navigation={{
            prevEl: '.navigation_prev',
            nextEl: '.navigation_next',
            disabledClass: css.disabled_navigation_button,
          }}
          className={css.nav_swiper_wrapper}
        >
          {navPCSettings.map(({ target_type, target_data, title }, index) => {
            const isTitleClickable = !!target_type && !!target_data;
            const key = `${index}`;
            const clickNavParams = {
              nav_parent_level_0: title,
              nav_parent_level_1: '',
              nav_target_type: target_type,
              nav_title: title,
            };

            return (
              <SwiperSlide key={key}>
                <div
                  className={cn(css.nav_item_wrapper, {
                    [css.active]: [currentNavKey, hoverActiveKey].includes(key),
                  })}
                >
                  <div
                    className={css.nav_item}
                    data-nav-key={key}
                    onMouseEnter={() => handleMouseEnter(key)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => clickNav(clickNavParams)}
                  >
                    <span className={css.title}>
                      {isTitleClickable ? (
                        <AutoLink targetType={target_type} targetData={target_data}>
                          {title}
                        </AutoLink>
                      ) : (
                        title
                      )}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className={cn(css.navigation_button_wrapper, 'navigation_prev')}
          onMouseEnter={handlePopupMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className={css.icon_wrapper}>
            <FiChevronLeft />
          </span>
        </div>
        <div
          className={cn(css.navigation_button_wrapper, css.next, 'navigation_next')}
          onMouseEnter={handlePopupMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className={css.icon_wrapper}>
            <FiChevronLeft />
          </span>
        </div>
      </div>
      {!!activeColumns.length && (
        <div
          className={css.content}
          ref={contentRef}
          onMouseEnter={handlePopupMouseEnter}
          onMouseLeave={handlePopupMouseLeave}
        >
          <div className={css.columns_wrapper}>
            {activeColumns.map((column, idx) => (
              <div
                className={css.column_item}
                // eslint-disable-next-line react/no-array-index-key
                key={`${idx}`}
                style={{ flexBasis: '20%' }}
              >
                {column.map((i, innerIdx) => {
                  if (i?.hidden) return null;
                  const type = i?.type;
                  const isValidType =
                    !!type &&
                    [ColumnItemTypes.Blank, ColumnItemTypes.Head, ColumnItemTypes.Image, ColumnItemTypes.Text].includes(
                      type
                    );
                  const innerKey = `${idx}-${innerIdx}`;
                  // 找到当前点击元素前面最近的一个head标签，作为直接父级
                  const parentTitle = column
                    .slice(0, innerIdx)
                    .find(({ type: columnType }) => columnType === ColumnItemTypes.Head)?.title;
                  const gaParams = {
                    nav_parent_level_0: navPCSettings[hoverActiveKey]?.title,
                    nav_parent_level_1: parentTitle,
                    nav_target_type: i?.target_type,
                    nav_title: i?.title || i?.image_url,
                  };

                  const handleClickNav = () => {
                    clickNav(gaParams);
                  };

                  if (!isValidType) {
                    return null;
                  }

                  return (
                    <div
                      key={innerKey}
                      className={cn(
                        css.type_wrapper,
                        { [css.head_wrapper]: i.type === ColumnItemTypes.Head },
                        { [css.img_wrapper]: i.type === ColumnItemTypes.Image },
                        { [css.text_wrapper]: i.type === ColumnItemTypes.Text },
                        { [css.blank_wrapper]: i.type === ColumnItemTypes.Blank }
                      )}
                      onClick={handleClickNav}
                    >
                      {renderAutoLink(i)}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PCNavMenu;
