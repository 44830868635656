import { ErrorBlock, Space } from 'antd-mobile';
import Helmet from 'react-helmet';
import { MdHomeFilled } from 'react-icons/md';

import imgNotFound from '@/assets/images/not-found.svg';
import Button from '@/components/Button';

const prefixCls = 'not-found-page';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function NotFound({ className, style }: Props): JSX.Element {
  return (
    <div className={className} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Page Not Found</title>
      </Helmet>

      <Space block direction="vertical" align="center" style={{ '--gap': 'var(--spacing-xxl)' }}>
        <ErrorBlock
          fullPage
          status="empty"
          image={imgNotFound}
          title="Page Not Found"
          description="The page is not exist, please check your url."
        />
        <Button color="main" link="/" icon={<MdHomeFilled />}>
          Goto Home
        </Button>
      </Space>
    </div>
  );
}

export default NotFound;
