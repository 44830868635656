import cn from 'classnames';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useMedia } from 'react-use';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Image from '@/components/Image';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import SellerCollectionTitleEditDialog from '@/components/SellerCollectionTitleEditDialog';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useSellerCollectionData } from '@/hooks/biz';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export interface TitleEditDialogProps {
  visible?: boolean;
  title?: string;
  onOk?: (title: string) => Promise<void>;
  onCancel?: () => void;
}

function SellerCollectionList({ className, style }: Props): JSX.Element {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { sellerCollections, data } = useSellerCollectionData();
  const isDesktop = useMedia('(min-width: 769px)');

  const closeModal = () => {
    setCreateDialogVisible(false);
  };

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Boards</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Boards</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Boards</h1>
        <StatusViewRenderer
          isEmpty={false}
          isLoading={sellerCollections.isLoading}
          hasMore={sellerCollections.hasNextPage}
          loadMore={sellerCollections.fetchNextPage}
          statusStyle={{ marginTop: 64 }}
        >
          <div className={`${prefixCls}__collection-list`}>
            {isDesktop && (
              <div key="createButtonItem" className={`${prefixCls}__collection-list-item`}>
                <Button.Add
                  className={`${prefixCls}__create-new-collection-btn`}
                  onClick={() => setCreateDialogVisible(true)}
                >
                  Create new Board
                </Button.Add>
              </div>
            )}

            {data?.map((collection) => (
              <CollectionListItem key={collection?.id} data={collection} />
            ))}
          </div>

          {!isDesktop && (
            <Button.Add
              className={`${prefixCls}__create-new-collection-btn`}
              onClick={() => setCreateDialogVisible(true)}
            >
              Create new Board
            </Button.Add>
          )}
        </StatusViewRenderer>
      </div>

      <SellerCollectionTitleEditDialog visible={createDialogVisible} onCancel={closeModal} onOk={closeModal} />
    </div>
  );
}

export function CollectionListItem({ data }: { data: any }): JSX.Element {
  // the maximum number of images to be shown is 3, and less than 3 will be left blank
  const shownImages: (string | undefined)[] = useMemo(
    () => _.times(3, (n) => data?.product_images?.[n]),
    [data?.product_images]
  );

  return (
    <AutoLink className={`${prefixCls}__collection-list-item`} to={`/seller-collections/${data?.id}`}>
      <div className={`${prefixCls}__collection-list-item-images-wrapper`}>
        <div className={`${prefixCls}__collection-list-item-images`}>
          {shownImages.map((image, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Image key={index} src={image ?? ''} fit="cover" />
          ))}
        </div>
      </div>
      <div className={`${prefixCls}__collection-list-item-footer`}>
        <div className={`${prefixCls}__collection-list-item-info`}>
          <div className={`${prefixCls}__collection-list-item-title`}>{data?.title}</div>
          <div className={`${prefixCls}__collection-list-item-total`}>
            {data?.product_count > 0 ? data?.product_count : 'No'}
            {Number(data?.product_count) === 1 ? ' item' : ' items'}
          </div>
        </div>
      </div>
    </AutoLink>
  );
}

export default SellerCollectionList;
