import { Divider, InputNumber, message } from 'antd';
import { Radio } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { AiOutlineBank, AiOutlineCreditCard } from 'react-icons/ai';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import OmittedName from '@/components/biz/OmittedName';
import Button from '@/components/Button';
import Image from '@/components/Image';
import { PaymentMethodTypeEnum } from '@/components/PaymentMethodsDialog/types';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useAuth } from '@/hooks/auth';
import usePaymentMethods from '@/hooks/queries/usePaymentMethods';
import useSettings from '@/hooks/queries/useSettings';
import useUpdateUserCredit from '@/hooks/queries/useUpdateUserCredit';
import useUserCreditDepositPreload from '@/hooks/queries/useUserCreditDepositPreload';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';
import { formatCredit, formatCurrency } from '@/utils/util';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function DepositFunds({ className, style }: Props): JSX.Element {
  const { user } = useAuth();
  const navigate = useNavigate();
  const appSettings = useSettings();
  const [amount, setAmount] = useState(0);
  const [debouncedAmount, setDebouncedAmount] = useState(amount);
  const [showError, setShowError] = useState(false);
  const paymentMethods = usePaymentMethods();
  const paymentMethodsData = paymentMethods?.data?.data?.payment_methods;
  const allowedPaymentMethodTypes = appSettings?.data?.data?.deposit?.data?.payment_method_types;
  const filteredPaymentMethods = _.filter(paymentMethodsData, (item) =>
    _.includes(allowedPaymentMethodTypes ?? [PaymentMethodTypeEnum.Card, PaymentMethodTypeEnum.Bank], item?.type)
  );
  const defaultPaymentMethod =
    _.find(filteredPaymentMethods, ['type', PaymentMethodTypeEnum.Bank])?.id ?? filteredPaymentMethods?.[0]?.id;
  const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState<any>(defaultPaymentMethod);
  const currentPaymentType = _.find(filteredPaymentMethods, ['id', currentPaymentMethodId])?.type;
  const userCreditDepositPreload = useUserCreditDepositPreload({
    customer_id: user?.id,
    price: debouncedAmount,
    payment_type: currentPaymentType,
  });
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const userCreditDepositPreloadData = userCreditDepositPreload?.data?.data;
  const computedInfo = userCreditDepositPreloadData?.current;
  const bannersData = userCreditDepositPreloadData?.banners;

  const updateUserCredit = useUpdateUserCredit();
  const minAmount = _.head<any>(userCreditDepositPreloadData?.quick_adds)?.price ?? 0;
  const maxAmount = _.last<any>(userCreditDepositPreloadData?.quick_adds)?.price ?? Infinity;

  useDebounce(() => setDebouncedAmount(amount), 200, [amount]);

  const handleSubmit = () => {
    if (amount < minAmount) {
      setShowError(true);
      return;
    }

    updateUserCredit.mutate(
      {
        customer_id: user?.id,
        price: amount,
        bonus: computedInfo?.bonus ?? 0,
        credits: computedInfo?.credits ?? 0,
        payment_method_id: Number(currentPaymentMethodId),
      },
      {
        onSettled: (data, error: Error) => {
          if (error) {
            message.error(error?.message || 'Preload Credits Failure!');
          } else {
            message.success('Preload Credits Success!');
            navigate('/kiwi-wallet', { replace: true });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (!currentPaymentMethodId) {
      setCurrentPaymentMethodId(defaultPaymentMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPaymentMethods, currentPaymentMethodId]);

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Preload Credits</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Preload Credits</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Preload Credits</h1>

        <StatusViewRenderer
          isEmpty={false}
          isLoading={paymentMethods?.isLoading || userCreditDepositPreload?.isLoading}
          statusStyle={{ marginTop: 64 }}
        >
          {!_.isEmpty(bannersData) && (
            <div className={`${prefixCls}__banner`}>
              <Swiper
                className={`${prefixCls}__banner-swiper`}
                loop={bannersData?.length > 1}
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                navigation
                modules={[Autoplay, Navigation]}
              >
                {bannersData?.map((item: any) => (
                  <SwiperSlide key={item?.image_url}>
                    <a href={item?.href || '#'}>
                      <Image className={`${prefixCls}__banner-image`} src={item?.image_url} fit="cover" />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          <div className={`${prefixCls}__section`}>
            <div className={`${prefixCls}__section-title`}>Amount</div>
            <div className={`${prefixCls}__section-body`}>
              <div className={`${prefixCls}__preload-amount-list`}>
                {userCreditDepositPreloadData?.quick_adds?.map((item) => (
                  <div
                    key={item?.price}
                    className={`${prefixCls}__preload-amount-list-item`}
                    onClick={() => setAmount(item?.price)}
                  >
                    <div className={`${prefixCls}__preload-amount-list-item-price`}>
                      {formatCurrency(item?.price, { precision: 0 })}
                    </div>
                    {item?.bonus > 0 && (
                      <div className={`${prefixCls}__preload-amount-list-item-extra`}>
                        +{formatCurrency(formatCredit(item?.bonus), { precision: 0 })} Extra
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className={`${prefixCls}__deposit-input-box`}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className={`${prefixCls}__deposit-input-box-title`} htmlFor="depositAmount">
                  Preload amount:
                </label>
                <div className={`${prefixCls}__deposit-input-box-amount`}>
                  <InputNumber
                    id="depositAmount"
                    size="large"
                    controls={false}
                    min={0}
                    max={maxAmount}
                    placeholder="$0.00"
                    value={amount}
                    formatter={(v, info) => (info.userTyping ? info.input : formatCurrency(v))}
                    parser={(v) => parseFloat(v?.replace(/\$|,/g, ''))}
                    onChange={(v) => setAmount(v)}
                  />
                  {amount < minAmount && showError && (
                    <div className={`${prefixCls}__deposit-input-box-error`}>
                      Minimum preload amount is {formatCurrency(minAmount)}
                    </div>
                  )}
                </div>
              </div>

              <div className={`${prefixCls}__extra-description`}>
                <div>Bonus credits:</div>
                <div>+{formatCurrency(formatCredit(computedInfo?.bonus))}</div>
              </div>

              <div className={`${prefixCls}__extra-description`}>
                <div>Total amount:</div>
                <div>{formatCurrency(formatCredit(computedInfo?.total))}</div>
              </div>
            </div>
          </div>

          <Divider />

          <div className={`${prefixCls}__section`}>
            <div className={`${prefixCls}__section-title`}>Pay from</div>
            <div className={`${prefixCls}__section-body`}>
              <Radio.Group value={currentPaymentMethodId} onChange={(v: string) => setCurrentPaymentMethodId(v)}>
                <div className={`${prefixCls}__bank-list`}>
                  {filteredPaymentMethods?.map((item) => {
                    const isBank = item?.type === PaymentMethodTypeEnum.Bank;

                    return (
                      <div key={item?.id} className={`${prefixCls}__bank-list-item`}>
                        <Radio
                          value={item?.id}
                          icon={(checked) => (checked ? <IoRadioButtonOn /> : <IoRadioButtonOff />)}
                        />
                        {isBank ? <AiOutlineBank /> : <AiOutlineCreditCard />}
                        <div className={`${prefixCls}__bank-list-item-info`}>
                          {isBank && <div className={`${prefixCls}__bank-list-item-label`}>PROMOTION +1% EXTRA</div>}
                          <div className={`${prefixCls}__bank-list-item-title`}>
                            <OmittedName
                              name={isBank ? item?.detail?.bank_name : _.upperFirst(item?.detail?.brand)}
                              last4={item?.detail?.last4}
                            />
                          </div>
                          {isBank && (
                            <div className={`${prefixCls}__bank-list-item-description`}>
                              Up to 4 day processing period
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Radio.Group>

              <Button.Add
                className={`${prefixCls}__add-bank-btn`}
                fill="none"
                bold={false}
                onClick={() =>
                  navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods', init: 1 }), {
                    replace: true,
                  })
                }
              >
                Add a new payment method
                {!_.some(filteredPaymentMethods, ['type', PaymentMethodTypeEnum.Bank]) && (
                  <div className={`${prefixCls}__add-bank-btn-label`}>
                    PROMOTION: Get 1% extra by linking a Bank account (ACH payment)
                  </div>
                )}
              </Button.Add>
            </div>
          </div>

          <div className={`${prefixCls}__actions`}>
            <Button
              block
              color="main"
              shape="rounded"
              disabled={amount <= 0 || !currentPaymentMethodId}
              loading={updateUserCredit?.isLoading}
              loadingText="Processing"
              onClick={handleSubmit}
            >
              Preload {formatCurrency(amount)}
            </Button>
          </div>
        </StatusViewRenderer>
      </div>
    </div>
  );
}

export default DepositFunds;
