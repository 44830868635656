import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IoIosLock } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import FavoriteButton from '@/components/FavoriteButton';
import ProductTags, { ProductTagsPos } from '@/components/ProductTags';
import { useDesktop } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import { checkIsSoldOut, getBoostInfo, showDelayTag } from '@/utils/biz';
import { getPriceRange, tailImageSrc } from '@/utils/util';

import FulfillLabel from '../FulfillLabel';
import { UpArrow } from '../Icon';
import CoverFooter from './CoverFooter';
import css from './ProductListItem.module.scss';

export interface ProductListItemProps {
  style?: React.CSSProperties;
  data?: any;
}

const ONETHUMBNAIL_WIDTH = 26 + 8;

const ProductListItemImage = React.memo(({ data }: { data: string }) => {
  const isDesktop = useDesktop();
  const src = isDesktop ? tailImageSrc(data) : tailImageSrc(data, { width: 324, height: 432 });

  return <img data-src={src} className={cn(css.img, 'swiper-lazy')} alt="" />;
});

function ProductListItem({ style, data }: ProductListItemProps): JSX.Element {
  const { user } = useAuth();
  const navigate = useNavigate();
  const isLogin = !_.isEmpty(user);
  const isOutOfStock = !_.isNil(data?.product?.out_of_stock) && !!data?.product?.out_of_stock;
  const isPriceLocked = _.isNil(data?.product?.price_range);
  const isDesktop = useDesktop();
  const comparePrice = data?.product?.compare_at_price_max ?? 0;
  const showComparePrice = comparePrice > (data?.product?.price_max ?? 0);
  const saleRate =
    Math.max(
      1 - data?.product?.price_min / data?.product?.compare_at_price_min,
      1 - data?.product?.price_max / data?.product?.compare_at_price_max
    ) * 100;

  const swiperRef = useRef(null);
  const [activeId, setActiveId] = useState<number>(0);
  const images = data?.product?.images || [];
  const thumbnailColors = useMemo(() => data?.product?.thumbnails_colors || [], [data]);
  const thumbnailsColorsWrapperRef = useRef(null);

  const isShowDelayTag = showDelayTag(data?.product?.tags);
  const { isShow: showBoost, earnPrice, ratioStr } = getBoostInfo(data?.product, data?.product?.price_max);

  const [showedThumbnailColors, setShowedThumbnailColors] = useState([]);
  const [moreThumbnailColors, setMoreThumbnailColors] = useState(null);

  useEffect(() => {
    if (thumbnailColors?.length) {
      const thumbnailsColorsWrapperWidth = thumbnailsColorsWrapperRef.current?.clientWidth;
      if (thumbnailsColorsWrapperWidth) {
        const maxThumbnailCount = thumbnailsColorsWrapperWidth
          ? Math.floor(thumbnailsColorsWrapperWidth / ONETHUMBNAIL_WIDTH)
          : 0;
        const thumbnailColorsCount = thumbnailColors?.length;
        const thumbnailColorsIsOver = maxThumbnailCount && thumbnailColorsCount > maxThumbnailCount;
        setShowedThumbnailColors(
          thumbnailColorsIsOver ? thumbnailColors.slice(0, maxThumbnailCount - 1) : thumbnailColors
        );
        setMoreThumbnailColors(thumbnailColorsIsOver ? thumbnailColors.slice(maxThumbnailCount - 1) : []);
      }
    }
  }, [thumbnailColors]);

  const navigateToProductDetail = () => {
    const productDetailUrl = `/products/${data?.product?.id}`;
    if (!isLogin) {
      navigate(`/login?redirect=${encodeURIComponent(productDetailUrl)}`);
      return;
    }

    isDesktop ? window.open(productDetailUrl, '_blank') : navigate(productDetailUrl);
  };

  const slideTo = (index) => swiperRef.current?.slideTo(index + 1);

  const handleClickThumbnailsColors = (imageIdx) => {
    setActiveId(imageIdx);
    slideTo(imageIdx);
  };

  const coverFooter = useMemo(() => <CoverFooter data={data} />, [data]);

  return (
    <div
      className={cn(css.ns_com_product_list_item_main, {
        [css.out_of_stock]: isOutOfStock,
        [css.price_locked]: isPriceLocked,
      })}
      style={style}
    >
      <div className={css.cover}>
        <Swiper
          className={css.swiper_wrapper}
          loop
          autoplay={false}
          navigation
          pagination={{
            el: css.swiper_pagination,
            clickable: true,
            renderBullet: null,
          }}
          modules={[Autoplay, Navigation, Pagination, Lazy]}
          onSlideChange={(curSwiper) => {
            const realIndex = curSwiper.activeIndex - 1;
            setActiveId(realIndex);
          }}
          onSwiper={(entity) => {
            swiperRef.current = entity;
          }}
          lazy
        >
          {images?.map((item: any) => (
            <SwiperSlide key={item}>
              <div className={css.swiper_image_wrapper} onClick={navigateToProductDetail}>
                <ProductListItemImage data={item} />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* absolute */}
        {showBoost && (
          <div className={css.product_tag_wrapper}>
            <UpArrow /> {ratioStr} Boost
          </div>
        )}
        <div className={css.favorite_btn_wrapper}>
          <FavoriteButton className={css.favorite_btn} product={data} />
        </div>
        {/* <div className={css.shipping_label_wrapper}>
          <FulfillLabel product={data?.product} />
        </div> */}
        {/* absolute */}
        <div className={css.mask_footer}>{coverFooter}</div>
      </div>
      <div className={css.thumbnails_colors_wrapper} ref={thumbnailsColorsWrapperRef}>
        <ThumbnailsColorsItems
          images={images}
          activeId={activeId}
          onChange={handleClickThumbnailsColors}
          data={showedThumbnailColors}
        />
        {!!moreThumbnailColors?.length && (
          <div className={css.thumbnails_colors_more} onClick={navigateToProductDetail}>
            +{moreThumbnailColors.length}
          </div>
        )}
      </div>
      <div className={css.info} onClick={navigateToProductDetail}>
        <div className={css.title}>{data?.product?.title}</div>
        <div className={css.price}>
          {isPriceLocked ? (
            <>
              <IoIosLock />
              Cost Locked
            </>
          ) : (
            <>
              <span className={css.price_sell}>
                {getPriceRange(data?.product?.price_min, data?.product?.price_max)}
              </span>
              {showComparePrice && (
                <>
                  <span className={css.price_compare}>${comparePrice?.toFixed(2)}</span>
                  <span className={css.price_sale_rate}>-{saleRate?.toFixed(0)}%</span>
                </>
              )}
              {showBoost && <span className={css.price_earn_boost}>Extra ${earnPrice} per sale!</span>}
            </>
          )}
        </div>
        {isShowDelayTag && (
          <div className={css.delay_tag}>
            <span className={css.dot} />
            Unavailable 1/10 - 2/8
          </div>
        )}
        <div className={css.tags}>
          <ProductTags customStyleTags={data?.product?.labels} pos={ProductTagsPos.PLP} />
        </div>
      </div>
    </div>
  );
}

function ThumbnailsColorsItems({
  data,
  activeId,
  images,
  onChange,
}: {
  data: any[];
  activeId?: number;
  images: string[];
  onChange: (imageIdx: number) => void;
}) {
  const handleClickItem = (imageIdx) => {
    onChange?.(imageIdx);
  };

  return (
    <div className={css.thumbnails_colors_item_wrapper}>
      {data?.map(({ image_idx, inv_qty }) => (
        <div
          className={cn(css.thumbnails_colors_item, {
            [css.disabled]: checkIsSoldOut(inv_qty),
            [css.checked]: typeof activeId === 'number' && activeId === image_idx,
          })}
          key={image_idx}
          onClick={() => handleClickItem(image_idx)}
        >
          <img src={tailImageSrc(images?.[image_idx], { width: 50, height: 50 })} alt="" />
        </div>
      ))}
    </div>
  );
}

export default ProductListItem;
