$prefixCls: deposit-funds-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;
  $actionsBarHeight: 64px;

  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: #{$actionsBarHeight};

        // safe area bottom patch
        --app-layout-padding-bottom: calc(
          #{$actionsBarHeight} - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l))
        );

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__banner {
    margin: var(--spacing-l) var(--spacing-l) var(--spacing-xxl);
    user-select: none;

    &-swiper {
      --swiper-navigation-color: var(--color-black);
      --swiper-navigation-size: 12px;
      border-radius: 8px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

      .swiper-button-prev,
      .swiper-button-next {
        visibility: hidden;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        background: var(--color-white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.2);
        }
      }

      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }

    &-image {
      --height: 125px;
    }
  }

  &__section {
    margin-top: var(--spacing-l);
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;

    &-title {
      margin: 0 var(--spacing-l) var(--spacing-m);
      color: var(--color-black);
      font-weight: 600;
    }
  }

  .ant-divider-horizontal {
    margin: var(--spacing-xxl) 0;
  }

  &__preload-amount-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-m);
    margin: var(--spacing-xxl) var(--spacing-l);

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
      background: var(--color-white);
      border-radius: 8px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      cursor: pointer;

      &-price {
        font-size: var(--font-size3);
        line-height: 1.4;
        font-weight: 600;
      }
      &-extra {
        position: absolute;
        top: 40px;
        color: var(--color-gray4);
        font-size: var(--font-size1);
        font-weight: 600;
        line-height: 1;
      }
    }
  }

  &__deposit-input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--spacing-l) var(--spacing-l) 0;

    &-title {
      color: var(--color-black);
    }

    &-amount {
      flex: 1;
      text-align: right;
      margin-left: var(--spacing-l);

      .ant-input-number {
        width: 120px;

        input {
          font-size: var(--font-size14);
          line-height: 1.3;
          text-align: right;
        }
      }
    }

    &-error {
      margin-top: var(--spacing-xs);
      color: var(--color-danger);
    }
  }

  &__extra-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    margin: 0 var(--spacing-l);
    font-size: var(--font-size14);
    line-height: 38px;
    color: #424242;
    font-weight: 500;
  }

  &__bank-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-m);
    margin-bottom: var(--spacing-xxl);

    &-item {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: var(--spacing-m) var(--spacing-l);
      background: var(--color-white);
      cursor: pointer;

      & > * + * {
        margin-left: var(--spacing-l);
      }

      .adm-radio {
        --icon-size: 26px;
        color: #424242;

        // extend radio box touchable area to item row
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      & > .react-icons {
        color: var(--color-gray4);
        font-size: 21px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: var(--color-black);
      }

      &-title {
        font-size: var(--font-size14);
        line-height: 1.3;
      }

      &-label {
        margin-bottom: var(--spacing-xs);
        padding: var(--spacing-xxs) var(--spacing-xs);
        color: #424242;
        font-size: 12px;
        line-height: 1;
        transform: scale(0.75);
        transform-origin: left center;
        background: #f5fce5;
        border-radius: 2px;
      }

      &-description {
        color: var(--color-gray4);
        font-size: var(--font-size12);
        line-height: 1.3;
      }
    }
  }

  &__add-bank-btn {
    margin: 0 var(--spacing-xs);
    width: auto !important;
    color: #424242 !important;
    font-size: var(--font-size14) !important;
    box-shadow: none !important;

    .button__inner-wrapper {
      justify-content: flex-start;

      .button__icon {
        position: static !important;
        left: auto !important;
        font-size: 30px !important;
      }

      .button__text {
        text-align: left;
      }
    }

    &-label {
      margin-top: var(--spacing-xxs);
      padding: var(--spacing-xxs) var(--spacing-xs);
      color: #424242;
      font-size: 12px;
      line-height: 1;
      transform: scale(0.75);
      transform-origin: 0 0;
      background: #f5fce5;
      border-radius: 2px;
      white-space: nowrap;
    }
  }

  &__actions {
    position: fixed;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: var(--spacing-m);
    left: 0;
    bottom: 0;
    width: 100%;
    height: $actionsBarHeight;
    z-index: 100;
    padding: var(--spacing-l);
    background: var(--color-white);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    // safe area bottom patch
    height: var(--app-layout-padding-bottom);
    padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));

    & > :only-child {
      display: block;
      width: 250px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      max-width: var(--content-small-max-width);
    }

    &__title {
      display: block;
    }

    &__banner {
      margin-left: 0;
      margin-right: 0;

      &-image {
        --height: 170px;
      }
    }

    &__section {
      &-title {
        margin-left: 0;
      }
    }

    &__preload-amount-list {
      margin-left: 0;
      margin-right: 0;
    }

    &__deposit-input-box {
      margin-left: 0;
      margin-right: 0;
      border-radius: 8px;
    }

    &__extra-description {
      margin-left: 0;
      margin-right: 0;
    }

    &__bank-list {
      &-item {
        border-radius: 8px;
      }
    }

    &__actions {
      position: static;
      margin: 30px auto;
      width: auto;
      height: auto;
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
  }
}
