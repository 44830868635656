import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import { useAuth } from '@/hooks/auth';
import { deleteSellerCollection } from '@/services/ds';

export default function useDeleteSellerCollection(queryParams?: any) {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(({ id }: { id: string }) => deleteSellerCollection(id), {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['sellerCollections', 'getSellerCollections', queryParams], (prevData: any) =>
        produce(prevData, (draft: any) => {
          // eslint-disable-next-line consistent-return
          _.forEach(draft?.pages, (page) => {
            const collectionIndex = _.findIndex(page?.data?.seller_collections, { id: variables.id });
            if (collectionIndex !== -1) {
              page.data.seller_collections.splice(collectionIndex, 1);
              return false;
            }
          });
        })
      );

      let lastCollectionId = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId) ?? '{}');
      if (!_.isPlainObject(lastCollectionId)) lastCollectionId = {};
      if (lastCollectionId?.[String(user?.id)] === variables.id) {
        const newLastCollectionId = _.omit(lastCollectionId, [String(user?.id)]);
        localStorage.setItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId, JSON.stringify(newLastCollectionId));
      }

      queryClient.invalidateQueries('sellerCollections');
    },
  });
}
