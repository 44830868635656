.ns_com_mobile_nav_menu {
  .body {
    width: 88vw;
    background-color: var(--color-white);
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
  }
  .image_menu_wrapper {
    padding: 0 20px;
    .item {
      .image_wrapper {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
      }
      .title {
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &.style_half {
      display: inline-block;
      width: 50%;
      padding: 0;
    }
    &.style_padding_top {
      padding-top: 26px;
    }
    &.style_left_item {
      padding-left: 20px;
      padding-right: 13px;
    }
    &.style_right_item {
      padding-left: 13px;
      padding-right: 20px;
    }
  }
  :global {
    .ant-menu {
      font-size: var(--font-size3);
    }
    .ant-menu-submenu-title .ant-menu-title-content a {
      color: inherit;
      display: block;
    }
    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
      min-height: 40px; // 保留之前给单个菜单设置的高度
    }
  }
}
