import { useModal } from '@ebay/nice-modal-react';
import { Box, Inline } from '@shopify/polaris';
import { FormInstance } from 'antd';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import { Props as ModalProps } from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import useShippingAddresses from '@/hooks/queries/useShippingAddresses';
import FormModal from '@/pages/Addresses/components/FormModal';

import css from './ListView.module.scss';
import NormalAddressItem from './NormalAddressItem';

export interface ShippingAddressesDialogProps extends Omit<ModalProps, 'children'> {}

export interface EditViewProps {
  mode: 'add' | 'edit';
  form: FormInstance;
  onStateChange: (state: any) => void;
}

const ListView = (): JSX.Element => {
  const formModal = useModal(FormModal);
  const [searchParams] = useSearchParams();
  const presetAddressKeyQ = searchParams.get('presetAddressKey');
  const addressesRQ = useShippingAddresses();
  const shippingAddressesOrigin = useMemo(() => addressesRQ?.data?.data || [], [addressesRQ?.data?.data]);
  const { hasPreSetAddress, preSetAddress } = useMemo(() => {
    const tmp = shippingAddressesOrigin.find(({ key }) => key === presetAddressKeyQ);
    return {
      hasPreSetAddress: !!tmp?.key,
      preSetAddress: tmp,
    };
  }, [presetAddressKeyQ, shippingAddressesOrigin]);

  const openAddAddressModal = useCallback(() => {
    formModal.show();
  }, [formModal]);

  const empty = useMemo(
    () => (
      <Box paddingBlockStart="16" paddingBlockEnd="16">
        <Inline align="center">No addresses found</Inline>
      </Box>
    ),
    []
  );

  const addressList = useMemo(
    () => (
      <div className={css.address_list}>
        {shippingAddressesOrigin?.map((address: any) => (
          <NormalAddressItem key={address?.id} data={address} />
        ))}
      </div>
    ),
    [shippingAddressesOrigin]
  );

  return (
    <StatusViewRenderer
      isEmpty={false}
      isLoading={addressesRQ.isLoading}
      statusStyle={{ marginTop: 64 }}
      className={css.ns_com_address_dialog_list_view}
    >
      <Box paddingBlockEnd={hasPreSetAddress ? '0' : '3'}>
        <Button.Add onClick={openAddAddressModal}>Add a new address</Button.Add>
      </Box>

      {hasPreSetAddress && (
        <>
          <div className={css.address_list_header}>Current Address</div>
          <div className={css.address_list}>
            <NormalAddressItem key={preSetAddress?.key} data={preSetAddress} showAction={false} />
          </div>
        </>
      )}
      {hasPreSetAddress && <div className={css.address_list_header}>Or select one of your shipping addresses</div>}
      {shippingAddressesOrigin?.length ? addressList : empty}
    </StatusViewRenderer>
  );
};

export default ListView;
