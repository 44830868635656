import { AlphaStack, Box } from '@shopify/polaris';

import AutoLink from '@/components/AutoLink';

import css from './Closed.module.scss';

const Closed = () => (
  <div className={css.ns_com_collection_preorder_closed}>
    <Box paddingBlockStart="4" paddingBlockEnd="4">
      <Box paddingBlockEnd="2">
        <p className="ds2_body_title">CLOSED PREORDERS</p>
      </Box>
      <AlphaStack gap="3">
        <p className="ds2_body_md">
          Orders for these preorder products have been closed and are no longer available for order, you can view our
          previous preorder styles here.
        </p>
        <ul className={css.ul}>
          <li>
            See Open Preorders <AutoLink to="/collections/open-preorders">HERE</AutoLink>
          </li>
          <li>
            See Ready to Ship Preorders (sometimes we have extras!){' '}
            <AutoLink to="/collections/ready-to-ship-preorders">HERE</AutoLink>
          </li>
        </ul>
      </AlphaStack>
    </Box>
  </div>
);

export default Closed;
