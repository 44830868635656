const App = ({ fill, stroke = '#424242' }: { fill?: string; stroke?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.3001 11.0436C6.61803 11.0436 5.25442 9.68003 5.25442 7.99797C5.25442 6.3159 6.61803 4.95229 8.3001 4.95229C9.98216 4.95229 11.3458 6.3159 11.3458 7.99797C11.3458 9.68003 9.98216 11.0436 8.3001 11.0436ZM14.1465 8.16536C14.1465 7.91223 14.1261 7.66319 14.0934 7.42231L15.5795 6.41797C15.9878 6.14443 16.1143 5.60552 15.8775 5.17684L14.7181 3.08243C14.4813 2.65375 13.9546 2.47819 13.5055 2.67416L11.7459 3.45395C11.3948 3.19674 11.0151 2.97219 10.6068 2.79664L10.4353 0.865534C10.3904 0.375613 9.98216 0.00408936 9.49224 0.00408936H7.09571C6.60578 0.00408936 6.19752 0.379695 6.15261 0.865534L5.98114 2.79664C5.57287 2.97219 5.19726 3.19674 4.84207 3.45395L3.08244 2.67416C2.63334 2.47411 2.10668 2.65375 1.86988 3.08243L0.718567 5.17684C0.481771 5.60552 0.612417 6.14443 1.0166 6.41797L2.5027 7.42231C2.47003 7.66727 2.44962 7.91223 2.44962 8.16536C2.44962 8.30825 2.46187 8.44706 2.47003 8.58995L1.01252 9.58204C0.608334 9.85558 0.477689 10.3945 0.714484 10.8232L1.87805 12.9258C2.11484 13.3544 2.63742 13.53 3.08652 13.334L4.57669 12.6808C5.00538 13.036 5.48713 13.3299 6.00563 13.5504L6.15261 15.1426C6.19752 15.6285 6.60578 16.0041 7.09571 16.0041H9.49632C9.98624 16.0041 10.3945 15.6326 10.4394 15.1426L10.5864 13.5504C11.1049 13.3299 11.5866 13.036 12.0153 12.6808L13.5055 13.334C13.9546 13.53 14.4772 13.3544 14.714 12.9258L15.8775 10.8232C16.1143 10.3945 15.9878 9.85558 15.5795 9.58204L14.122 8.58995C14.1302 8.45114 14.1424 8.30825 14.1424 8.16536H14.1465Z"
      fill="#424242"
    />
  </svg>
);

export default App;
