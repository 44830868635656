import { TabBar as AntTabBar } from 'antd-mobile';
import type { TabBarProps } from 'antd-mobile/es/components/tab-bar';
import cn from 'classnames';
import { FiUser } from 'react-icons/fi';
import { MdHomeFilled } from 'react-icons/md';
import { RiStoreLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';

import css from './index.module.scss';

const tabs = [
  {
    path: '/',
    title: 'Browse',
    icon: <MdHomeFilled className={css.icon_home} />,
  },
  {
    path: '/stores',
    title: 'Store',
    icon: <RiStoreLine />,
  },
  {
    path: '/account',
    title: 'Account',
    icon: <FiUser />,
  },
];

export type { TabBarProps };

function TabBar({ className, ...restProps }: TabBarProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AntTabBar
      className={cn(css.ns_com_tab_bar_main, className)}
      safeArea
      activeKey={location.pathname}
      onChange={(path) => navigate(path)}
      {...restProps}
    >
      {tabs.map((item) => (
        <AntTabBar.Item key={item.path} icon={item.icon} title={item.title} />
      ))}
    </AntTabBar>
  );
}

export default TabBar;
