/* eslint-disable eqeqeq,no-restricted-syntax,no-nested-ternary,no-plusplus */
import { Col, Divider, message, Row } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import _ from 'lodash';
import { ElementRef, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiCopy, FiEdit3, FiHeart, FiPrinter } from 'react-icons/fi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AutoLink, { TargetType } from '@/components/AutoLink';
import Button from '@/components/Button';
import ModalPrintLiveHelper from '@/components/ModalPrintLiveHelper';
import OrderChangeAddressButton from '@/components/OrderChangeAddressButton';
import OrderTrackButton from '@/components/OrderTrackButton';
import ProductsToLikeBoardButton from '@/components/ProductsToLikeBoardButton';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import { ADDRESS_DIALOG_PATH } from '@/components/ShippingAddressesDialog/cons';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import Tag from '@/components/Tag';
import Title from '@/components/Title';
import { SHIPPING_RULES_DEFAULT_ADDRESS_TAG_TEXT } from '@/constants/biz';
import { useAuth } from '@/hooks/auth';
import useCancelOrder from '@/hooks/queries/useCancelOrder';
import useOrder from '@/hooks/queries/useOrder';
import useSettings from '@/hooks/queries/useSettings';
import useStores from '@/hooks/queries/useStores';
import useUpdateOrder from '@/hooks/queries/useUpdateOrder';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useDesktop from '@/hooks/useDesktop';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';
import useOrderAdded from '@/hooks/useOrderAdded';
import ModalOrderReturn from '@/pages/OrderReturn/components/ModalOrderReturn';
import { CostPanel, OrderStatusTag } from '@/pages/Orders/components';
import TableCombineOrder from '@/pages/Orders/components/TableCombineOrder';
import { getProducts } from '@/services/ds';
import {
  getFormatAddress,
  getShopifyOrderDetailUrl,
  hasInvView,
  showShippingRulesDefaultAddressTag,
} from '@/utils/biz';
import { formatCredit, formatterPrice } from '@/utils/util';

import { OrderStatus, OrderType } from '../Orders/types';
import { formatterDate, getIsShipped, getRefreshedOrder } from '../Orders/util';
import OrderProducts from './components/OrderProducts';
import css from './styles.scss';

type ModalOrderReturnHandle = ElementRef<typeof ModalOrderReturn>;

const confirmModalTitle = 'Cancel Order';
const confirmModalContent = 'Confirm to cancel this order?';
const pageTitle = 'Order Details';

const isSupplierPath = (path) => /supplierOrders/g.test(path);

function OrdersDetail(): JSX.Element {
  const navigate = useNavigate();

  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const settingsRQ = useSettings();
  const { privateInvAddressSwitch } = useMemo(() => {
    const settingsData = settingsRQ?.data?.data;
    return {
      privateInvAddressSwitch: settingsData?.['private-inv-stock-up'],
    };
  }, [settingsRQ?.data?.data]);
  const { user } = useAuth();
  const isPrivateAddressesOn = !!privateInvAddressSwitch?.data;
  const stores = useStores();
  const isDesktop = useDesktop();
  const params = useParams();
  const location = useLocation();
  const orderId = params.orderId || '';
  const orderOrigin = useOrder(orderId);
  const updateOrder = useUpdateOrder();
  const cancelOrder = useCancelOrder();
  const userSettings = useUserSettings();
  const order = useMemo(() => {
    const orderData = orderOrigin?.data?.data?.order || {};
    const shopOrderData = orderOrigin?.data?.data?.shop_order || {};
    const lineItems = orderData?.line_items || [];
    const refreshedOrder = getRefreshedOrder(orderData);

    return {
      ...refreshedOrder,
      shop_order: shopOrderData,
      total_items: _.reduce(lineItems, (n, lineItem) => n + lineItem.qty / 1, 0),
    };
  }, [orderOrigin]);

  const isRetailer = user?.id === order.customer_id;

  const isSupplier = !isRetailer && isSupplierPath(location.pathname);

  const { fulfillments, unFulfillments } = order;
  const orderItems = [...fulfillments, ...unFulfillments];
  const shippingAddressOrigin = useMemo(() => order?.shipping_address || {}, [order?.shipping_address]);
  const customerInfo = getFormatAddress(shippingAddressOrigin);
  const isCombinedOrder = !!order?.combined_orders?.length;
  const [currentShippingAddress, setCurrentShippingAddress] = useState<any>(null);
  const { regardShopifyUnpaidSoldOutOrder } = useOrderAdded(order);
  const { shopify_domain } =
    stores?.data?.data?.shops?.filter(({ id }) => `${id}` === `${order?.shop_order?.shop_id}`)?.[0] || {};
  const shopifyOrderId = order?.shop_order?.shopify_id;
  const productLineItems = useMemo(
    () => (orderOrigin?.data?.data?.order?.line_items || []).map((i) => ({ ...i, id: i.product_id })),
    [orderOrigin]
  );
  const modalOrderReturnRef = useRef<ModalOrderReturnHandle>(null);
  const useShippingRulesDefaultAddress = showShippingRulesDefaultAddressTag(order?.shipping_address_route_type);
  const shopifyOrderDetailUrl = getShopifyOrderDetailUrl(shopifyOrderId, shopify_domain);

  // pi单不能编辑地址
  const showEditButton =
    [OrderStatus.Paid, OrderStatus.Unpaid].includes(order?.status) && OrderType.PrivateInv !== order?.order_type;

  const handleCancel = () => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        cancelOrder.mutate(orderId, {
          onSettled: (resData, error) => {
            error
              ? message.error((error as Error)?.message || 'Cancel Order Failure!')
              : message.success('Order Cancelled!');
          },
        });
      },
    });
  };

  const getOperateButton = () => {
    const buttons: any = [];
    switch (order?.status) {
      case 'unpaid':
        buttons.push(
          <Button key="cancel" shape="rounded" block onClick={handleCancel}>
            Cancel Order
          </Button>
        );
        if (regardShopifyUnpaidSoldOutOrder) {
          if (shopifyOrderDetailUrl) {
            buttons.push(
              <Button
                key="edit_order"
                color="primary"
                shape="rounded"
                block
                link={shopifyOrderDetailUrl}
                target="_blank"
              >
                Edit Order
              </Button>
            );
          }
        } else {
          buttons.push(
            <Button key="checkout" color="primary" shape="rounded" block link={`/orders/${orderId}/checkout`}>
              Check Out
            </Button>
          );
        }

        break;

      case 'shipped':
      case 'partially_shipped':
      case 'delivered':
      case 'cancelled':
        // buttons.push(<OrderTrackButton shape="rounded" fulfillments={order?.fulfillments} />);
        break;

      case 'paid':
      case 'pending':
        break;
      case 'processing':
        if (order?.order_type !== OrderType.PrivateInv) {
          buttons.push(
            <OrderChangeAddressButton key="changeAddress" size="middle" shape="rounded" block order={order} />
          );
        }

        break;

      default:
        break;
    }

    return buttons;
  };

  const refreshOrderDetail = () => {
    const orderParams = {
      dry_run: 0,
      shipping_address: {
        ...currentShippingAddress,
        id: Number(currentShippingAddress?.id),
        customer_id: Number(currentShippingAddress?.customer_id),
      },
    };

    updateOrder.mutate(
      { id: orderId as string, params: orderParams },
      {
        onSuccess() {
          message.success('Save Address Success!');
        },
        onError(err: Error) {
          message.success(err?.message || 'Save Address Failed!');
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const openReturn = (fulFillId) => {
    if (isDesktop) {
      modalOrderReturnRef.current.show({ fulFillId });
    } else {
      navigate(`/order-return/${orderId}${fulFillId ? `?ful=${fulFillId}` : ''}`);
    }
  };

  useEffect(() => {
    const handleShippingAddressChange = (e: CustomEvent) => {
      setCurrentShippingAddress(e?.detail?.address);
    };
    document.addEventListener('shippingAddresses:change', handleShippingAddressChange);
    return () => {
      document.removeEventListener('shippingAddresses:change', handleShippingAddressChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentShippingAddress) {
      refreshOrderDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShippingAddress]);

  const handleEdit = () => {
    if (useShippingRulesDefaultAddress) {
      return navigate(
        getUpdatedSearchParamsPath({
          dialog: isPrivateAddressesOn ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
          selectMode: 1,
          presetAddressKey: (shippingAddressOrigin as { key: string })?.key,
          addressKey: (shippingAddressOrigin as { key: string })?.key,
        }),
        {
          replace: true,
        }
      );
    }

    if (shopifyOrderId) {
      return window.open(shopifyOrderDetailUrl, '_blank');
    }

    return navigate(
      getUpdatedSearchParamsPath({
        dialog: isPrivateAddressesOn ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
        selectMode: 1,
        addressKey: (shippingAddressOrigin as { key: string })?.key,
      }),
      {
        replace: true,
      }
    );
  };
  const EditButton = () => (
    <div className={css.edit_address_button} onClick={handleEdit}>
      <span className={css.icon}>
        <FiEdit3 fill="currentColor" />
      </span>
      Edit
    </div>
  );

  const handleCopy = (text: string, messageText: string) => {
    const isSuccess = copyToClipboard(text ?? '');
    isSuccess
      ? message.success({ key: 'copyMessage', content: messageText })
      : message.error({ key: 'copyMessage', content: 'Copy Failed!' });
  };

  const renderCostSection = () => {
    const showActiveStyle = isCombinedOrder;

    return (
      <div className={cn(css.cost_block, { [css.active]: showActiveStyle })}>
        <div className={css.order_detail_page_section}>
          <div className={css.title}>Cost</div>
          <div className={css.content_wrapper}>
            <div className={css.content}>
              <CostPanel walletSelectable={false} order={order} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  // order info + cusomer shipping address
  const renderBasicInfo = () => {
    const { shop_order, name, created_at, paid_at } = order;
    const createdAt = formatterDate(created_at);
    const paidAt = formatterDate(paid_at);
    const showProcessAt = isCombinedOrder;
    const showPaidAt = !isCombinedOrder && !!paid_at;
    const showCreatedAt = !isCombinedOrder;
    const shopifyOrderName = shop_order?.name;

    return (
      <div className={css.info_block}>
        <div className={css.order_detail_page_section}>
          <div className={css.title}>Order Info</div>
          <div className={css.content}>
            {!!shopifyOrderName && (
              <div>
                Shopify Order{` `}
                <AutoLink className={css.link} targetData={shopifyOrderDetailUrl} targetType={TargetType.UrlExt}>
                  <strong>{shopifyOrderName}</strong>
                </AutoLink>
                <span
                  className={css.copy_btn}
                  onClick={() => handleCopy(shopifyOrderName, 'Shopify order number copied to clipboard!')}
                >
                  <FiCopy />
                </span>
              </div>
            )}
            <div>
              Kiwi Order <strong>{name}</strong>
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(name, 'KiwiDrop order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            </div>
            {showProcessAt && <div>Processed on: {createdAt}</div>}
            {showCreatedAt && <div>Created on: {createdAt}</div>}
            {showPaidAt && order?.paid_at && <div>Paid on: {paidAt}</div>}
          </div>
        </div>
        <div className={css.order_detail_page_section}>
          <div className={cn(css.title, css.customer_info)}>
            <span>Customer Info</span>
            {showEditButton && <EditButton />}
          </div>
          <div className={css.content}>
            {useShippingRulesDefaultAddress && (
              <div className={css.content_default_address_tag}>
                <Tag text={SHIPPING_RULES_DEFAULT_ADDRESS_TAG_TEXT} />
              </div>
            )}

            {Object.keys(customerInfo)
              .filter((key) => !!customerInfo[key])
              .map((key) => (
                <div key={key}>{customerInfo[key]}</div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderOrderProcusts = () => (
    <div className={css.products_block}>
      <div className={css.order_detail_page_section}>
        <div className={css.title}>
          <span>Products</span>
          {!isSupplier && (
            <div className={css.icons_wrapper}>
              {isDesktop && hasInvView(userSettings?.data?.data?.app_collection_views) && (
                <ModalPrintLiveHelper
                  fetchData={() =>
                    getProducts({ filters: { sku: order?.line_items?.map((i) => i?.sku) } }).then(
                      (res) => res?.data?.items
                    )
                  }
                >
                  {({ toggler }) =>
                    isDesktop ? (
                      <span className={css.desc_wrapper} onClick={toggler}>
                        <span className={css.icon}>
                          <FiPrinter />
                        </span>
                        <span>Print Live Helpers</span>
                      </span>
                    ) : null
                  }
                </ModalPrintLiveHelper>
              )}
              <ProductsToLikeBoardButton products={productLineItems}>
                {(openDialog) => (
                  <span className={css.desc_wrapper} onClick={openDialog}>
                    <span className={css.icon}>
                      <FiHeart />
                    </span>
                    <span>Save to Boards</span>
                  </span>
                )}
              </ProductsToLikeBoardButton>
            </div>
          )}
        </div>
        <div className={css.content_wrapper}>
          {orderItems?.map((item: any) => {
            const { status, tracking_url, id, shopify_variant_id, items } = item;
            const showTrackButton = !!tracking_url;
            const showReturnButton = getIsShipped(status);
            const packageId = id;

            if (!items || !items.length) return null;

            return (
              <div className={css.content} key={`${id}-${shopify_variant_id}`}>
                <div className={css.header}>
                  <OrderStatusTag status={status} />
                  <div>
                    {showReturnButton && !isSupplier && (
                      <Button size="small" onClick={() => openReturn(packageId)} loading={cancelOrder.isLoading}>
                        Return
                      </Button>
                    )}
                    {showTrackButton && (
                      <span style={{ paddingLeft: '8px' }}>
                        <OrderTrackButton fulfillment={item} />
                      </span>
                    )}
                  </div>
                </div>
                <OrderProducts data={items} order={item} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const renderCombineOrderTable = () => {
    if (!isCombinedOrder) return null;
    return (
      <div className={css.combine_order_block}>
        <div className={css.order_detail_page_section}>
          <div className={css.title}>Combined Orders</div>
          <div className={css.content}>
            <TableCombineOrder data={order} showLinkTo />
          </div>
        </div>
      </div>
    );
  };

  const renderRewards = () => {
    const { credit_reward_issued, credit_reward_retracted } = order;

    if ((!credit_reward_issued && !credit_reward_retracted) || isSupplier) {
      return null;
    }

    return (
      <div className={css.order_detail_page_section}>
        <div className={css.title}>Rewards</div>
        <div className={css.content}>
          {!!credit_reward_issued && (
            <div className={css.block_one_desc}>
              <span>Credits Issued</span>
              <span>${formatCredit(credit_reward_issued)}</span>
            </div>
          )}
          {!!credit_reward_retracted && (
            <div className={cn(css.block_one_desc, css.retracted)}>
              <span>Credits Retracted</span>
              <span>${formatCredit(credit_reward_retracted)}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderRefund = () => {
    const {
      total_refunded,
      refunded_by_card,
      refunded_by_credit,
      total_items_shall_refund,
      total_shipping_shall_refund,
    } = order;

    if (!(Number(total_refunded) > 0)) {
      return null;
    }

    return (
      <div className={css.refund_block}>
        <div className={cn(css.order_detail_page_section)}>
          <div className={css.title}>Refund</div>
          <div className={css.content}>
            <div className={cn(css.block_one_desc, css.total)}>
              <span>Total Shall Refund</span>
              <span>${formatterPrice(total_refunded)}</span>
            </div>
            <div className={cn(css.block_one_desc)}>
              <span>Product</span>
              <span>${formatterPrice(total_items_shall_refund)}</span>
            </div>
            <div className={cn(css.block_one_desc)}>
              <span>Shipping</span>
              <span>${formatterPrice(total_shipping_shall_refund)}</span>
            </div>
            <div className={cn(css.block_one_desc, css.total)}>
              <span>Total Refunded</span>
              <span>${formatterPrice(total_refunded)}</span>
            </div>
            {!!refunded_by_card && !!refunded_by_credit && <Divider dashed style={{ margin: '8px 0' }} />}
            {!!refunded_by_card && (
              <div className={css.block_one_desc}>
                <span>Refunded to Card</span>
                <span>${formatterPrice(refunded_by_card)}</span>
              </div>
            )}
            {!!refunded_by_credit && (
              <div className={css.block_one_desc}>
                <span>Refunded to Credits</span>
                <span>${formatCredit(refunded_by_credit)}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // 在这里区别展示pc，m（区别展示combine与非combine）模块顺序
  const sectionsPC = [
    [renderBasicInfo, renderCombineOrderTable, renderCostSection, renderRewards, renderRefund],
    [renderOrderProcusts],
  ];
  const sectionsMWhenCombined = [
    renderBasicInfo,
    renderCombineOrderTable,
    renderCostSection,
    renderRewards,
    renderRefund,
    renderOrderProcusts,
  ];
  const sectionsMWhenNotCombined = [
    renderBasicInfo,
    renderOrderProcusts,
    renderCostSection,
    renderRewards,
    renderRefund,
  ];
  const sectionsM = isCombinedOrder ? sectionsMWhenCombined : sectionsMWhenNotCombined;

  return (
    <div>
      <Helmet>
        <html lang="en" className={css.order_detail_page_active} />
        <title>{pageTitle}</title>
      </Helmet>

      <SecondaryNavBar className={css.secondary_nav_bar}>{pageTitle}</SecondaryNavBar>

      <div className={css.order_detail_page_content}>
        <Title title={pageTitle} showBackTo />

        <StatusViewRenderer
          error={orderOrigin.error}
          isLoading={orderOrigin.isLoading}
          isEmpty={false}
          statusStyle={{ marginTop: 64 }}
        >
          {isDesktop ? (
            <Row gutter={24}>
              {sectionsPC.map((funcs) => (
                <Col span={12} key={funcs.toString()}>
                  {funcs.map((func) => func())}
                </Col>
              ))}
            </Row>
          ) : (
            <div>{sectionsM.map((func) => func())}</div>
          )}
          {!isSupplier && (
            <div className={css.order_detail_page_button_actions}>
              {getOperateButton().map((button: any) => button)}
            </div>
          )}
        </StatusViewRenderer>
      </div>

      <ModalOrderReturn orderId={orderId} ref={modalOrderReturnRef} />
    </div>
  );
}

export default OrdersDetail;
