import { Divider, Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import { useAuth } from '@/hooks/auth';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ViewType = 'email' | 'password';

function EmailLogin({ className, style }: Props): JSX.Element {
  const { login, isLoggingIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState<ViewType>('email');
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUrl = searchParams.get('redirect') || '/';
  const shouldDisableContinue =
    (currentView === 'email' && !isValidEmail(emailValue)) ||
    (currentView === 'password' && _.isEmpty(passwordValue.trim()));
  const pwdInputRef = useRef(null);

  const handleLoginSuccess = () => {
    sessionStorage.setItem('fromLogined', 'true');
    _.startsWith(redirectUrl, '/') ? navigate(redirectUrl) : window.location.assign(redirectUrl);
  };

  const handleLogin = async () => {
    try {
      await login({ email: _.trim(emailValue), password: passwordValue });
      handleLoginSuccess();
    } catch (error) {
      setErrorMessage((error as Error)?.message || 'Password is incorrect');
    }
  };

  const handleContinue = () => {
    if (currentView === 'email') {
      setCurrentView('password');
    } else if (currentView === 'password') {
      handleLogin();
    }
  };

  const handleResetEmail = () => {
    setCurrentView('email');
    setEmailValue('');
    setPasswordValue('');
    setErrorMessage('');
  };

  useEffect(() => {
    if (currentView === 'password') {
      pwdInputRef.current?.focus?.();
    }
  }, [currentView]);

  return (
    <div
      className={cn(prefixCls, className, `${prefixCls}--${currentView}-view`, {
        [`${prefixCls}--has-error`]: !!errorMessage,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Email Login</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__form-wrapper`}>
          <h1 className={`${prefixCls}__title`}>Welcome to KiwiDrop!</h1>
          <div className={`${prefixCls}__message`}>
            {currentView === 'password' ? (
              <>
                Enter the password to
                <br />
                <strong>{emailValue}</strong>
              </>
            ) : (
              'You must already have a phone login to login via email'
            )}
          </div>
          <form id="loginForm" method="post" onSubmit={(e) => e.preventDefault()}>
            <div className={`${prefixCls}__input-wrapper`}>
              <Input
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-email`)}
                type="email"
                name="email"
                autoComplete="username email"
                placeholder="Enter your email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                onPressEnter={handleContinue}
              />
              <Input.Password
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-password`)}
                type="password"
                name="password"
                autoComplete="password"
                placeholder="Enter Password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onPressEnter={handleContinue}
                ref={pwdInputRef}
              />
            </div>
          </form>
          <div className={`${prefixCls}__error-message`}>{errorMessage}</div>
          <Button
            className={`${prefixCls}__continue-btn`}
            color="main"
            shape="rounded"
            loading={isLoggingIn}
            loadingText="Login"
            disabled={shouldDisableContinue}
            onClick={handleContinue}
          >
            Log in
          </Button>

          <div className={`${prefixCls}__actions`}>
            {currentView === 'password' ? (
              <>
                <Button underline fill="none" onClick={handleResetEmail}>
                  Change Account
                </Button>
                <Divider type="vertical" />
                <Button
                  underline
                  fill="none"
                  link={`/password-reset?email=${encodeURIComponent(_.trim(emailValue))}&redirect=${encodeURIComponent(
                    redirectUrl
                  )}`}
                >
                  Forgot Password?
                </Button>
              </>
            ) : (
              <>
                <Button underline fill="none" link={`/login?redirect=${encodeURIComponent(redirectUrl)}`}>
                  Log in with phone
                </Button>
                <Button underline fill="none" link={`/login?signup=1&redirect=${encodeURIComponent(redirectUrl)}`}>
                  Sign up with Phone
                </Button>
              </>
            )}
          </div>
        </div>

        {currentView === 'email' && (
          <div className={`${prefixCls}__footer`}>
            <div className={`${prefixCls}__footer-description`}>
              By clicking Continue, you agree to our
              <br />
              <AutoLink to="/terms-of-service">Terms of Service</AutoLink> and{' '}
              <AutoLink to="/privacy-policy">Privacy Policy</AutoLink>.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function isValidEmail(email = ''): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (_.isEmpty(_.trim(email))) return false;
  return re.test(String(email).toLowerCase());
}

export default EmailLogin;
