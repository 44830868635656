import { useMutation, useQueryClient } from 'react-query';

import { emailVerifyCode } from '@/services/lynx';

export default function useVerifyAccountEmailCode() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof emailVerifyCode>[0]) => emailVerifyCode(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('authUser');
    },
  });
}
