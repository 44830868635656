import cn from 'classnames';
import Helmet from 'react-helmet';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Modal from '@/components/Modal';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function OrderCheckoutResult({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { order, orderId, shopId } = Object.fromEntries(searchParams);

  const navigateToOrders = () => {
    navigate(`/orders${shopId ? `?shop_id=${shopId}` : ''}`);
  };

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Order Placed Successfully</title>
      </Helmet>

      <Modal
        className={`${prefixCls}__dialog`}
        visible
        title="&nbsp;"
        fullscreen="mobile"
        footer={
          <Button block color="primary" shape="rounded" onClick={navigateToOrders}>
            To Orders
          </Button>
        }
        maskClosable={false}
        onClose={navigateToOrders}
      >
        <div className={`${prefixCls}__result`}>
          <div className={`${prefixCls}__result-caption`}>Order Placed Successfully</div>
          <div className={`${prefixCls}__result-image`}>
            <IoIosCheckmarkCircle />
          </div>
          <div className={`${prefixCls}__result-description`}>
            Your order <strong>{order}</strong> has been placed. We’ll send you an email confirming your order.
          </div>

          <div className={`${prefixCls}__result-description`}>
            <AutoLink className="text-bold text-underline" to={`/orders/${orderId}`}>
              View Order Details
            </AutoLink>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default OrderCheckoutResult;
