import BackToLastPage from '@/components/BackToLastPage';
import { useDesktop } from '@/hooks';

import css from './index.module.scss';

interface Props {
  title: string;
  showBackTo?: boolean;
  desc?: React.ReactElement | string;
  extra?: React.ReactElement | string;
}
const App = ({ title, showBackTo = false, desc, extra }: Props) => {
  const isDesktop = useDesktop();

  if (!isDesktop) return null;
  return (
    <div className={css.ns_comp_title_for_page_wrapper}>
      <div className={css.left}>
        {showBackTo && <BackToLastPage className={css.back_button} />}
        <span className={css.title}>{title}</span>
        {!!desc && <span className={css.desc}>{desc}</span>}
      </div>
      {!!extra && extra}
    </div>
  );
};

export default App;
