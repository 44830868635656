enum NavItemTypes {
  Blank = 'blank',
  Head = 'head',
  Image = 'image',
  Text = 'text',
}

const placeholder = '';

export { NavItemTypes, placeholder };
