import cn from 'classnames';

import AutoLink from '@/components/AutoLink';

import { SideBarItem, SideBarItemType } from '../index';
import css from './index.module.scss';

const SideBarPC = ({ data }: { data: SideBarItem[] }) => {
  if (!data) return null;

  return (
    <>
      {data.map(({ title, label, type, target_data, target_type }, index) => {
        const showText = title || label;

        if (![SideBarItemType.Blank, SideBarItemType.Title, SideBarItemType.Text].includes(type)) return null;

        if (type === SideBarItemType.Blank) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={cn(css.sidebar_pc_blank)}>
              {showText}
            </div>
          );
        }

        return (
          <div
            key={showText}
            className={cn(
              { [css.sidebar_pc_title]: type === SideBarItemType.Title },
              { [css.sidebar_pc_text]: type === SideBarItemType.Text },
              css.sidebar_item
            )}
          >
            <AutoLink targetType={target_type} targetData={target_data}>
              {showText}
            </AutoLink>
          </div>
        );
      })}
    </>
  );
};

export default SideBarPC;
