import { useMemo } from 'react';

import { useBrandSettings } from '@/hooks/biz';

const useBrand = (props: { handle?: string; tags?: string[] }) => {
  const brandSettings = useBrandSettings();

  return useMemo(() => {
    const brand = brandSettings.filter(
      ({ handles = [], handle, tag }) =>
        handles.includes(props.handle) || (!!handle && handle === props.handle) || props.tags?.includes(tag)
    )?.[0];

    if (!brand) return null;

    return {
      ...brand,
      handle: brand?.handle || brand?.handles?.[0],
    };
  }, [brandSettings, props.handle, props.tags]);
};

export default useBrand;
