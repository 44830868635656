.collection_list_item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  padding: var(--spacing-xs);
  border-radius: 8px;
  background: var(--color-white);
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xs);
    width: 122px;
    height: 50px;
    margin-right: var(--spacing-m);

    :global {
      .image__fallback {
        .react-icons {
          display: none;
        }
      }
    }
  }

  .info {
    flex: 1;
    min-width: 0;
    margin-right: var(--spacing-xs);
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
  }

  .title {
    margin-bottom: var(--spacing-xxs);
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .actions {
    margin-right: var(--spacing-m);
    line-height: 0;

    // extend checkbox touchable area
    :global {
      .adm-checkbox::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
