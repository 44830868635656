import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';

const defaultProductServices = [
  {
    title: 'Delivery',
    lead: 'Flat shipping rate: $5.00',
    text: '<a href="https://help.kiwidrop.com/hc/en-us/articles/26041436896019-Shipping-Policy" target="_blank">See Details</a>',
  },
  {
    titleSuffix: undefined,
    lead: '<strong>5 – 10 Business Days</strong>',
    text: 'Shipping within the U.S. <a href="https://help.kiwidrop.com/hc/en-us/articles/26041436896019-Shipping-Policy" target="_blank">More regions</a>',
  },
  {
    title: 'Returns & Refunds Policy',
    lead: 'Most products support returns within 7 days.',
    text: '<a href="https://help.kiwidrop.com/hc/en-us/articles/26041505447571-Returns-Refunds-Policy" target="_blank">See more</a>',
  },
];

const getProductServices = ({
  id,
  tags,
  shipping_time,
  fulfilled_by_vendor,
  fulfilled_by,
  shipping_methods,
  return_refund_policy,
}: {
  id?: number;
  tags?: string[];
  shop_id?: number;
  fulfilled_by_vendor?: boolean;
  fulfilled_by?: string;
  shipping_time?: { days_from: number; days_to: number };
  shipping_methods?: { method: 'standard' | 'expedited'; price: number }[];
  return_refund_policy?: { text: string };
} = {}) => {
  const services = _.cloneDeep(defaultProductServices);
  if (fulfilled_by_vendor) services[0].title = `Delivery: <strong>Fulfilled by ${fulfilled_by || 'vendor'}</strong>`;
  const standardShippingPrice = shipping_methods?.find((sm) => sm.method === 'standard')?.price;
  if (standardShippingPrice !== undefined) {
    services[0].lead = `Flat shipping rate: $${standardShippingPrice.toFixed(2)}`;
  }
  if ([195868, 195867, 195866, 2140001056, 2140001040].includes(id)) {
    services[0].lead = `Flat rate shipping: $20. Enjoy free shipping on orders over $79.`;
  }
  if (shipping_time) {
    services[1].lead = `<strong>${_([shipping_time.days_from, shipping_time.days_to])
      .compact()
      .join(' - ')} Business Days</strong>`;
  } else {
    let shippingTime = '5 – 10';
    if (tags?.some((tag) => _.toLower(tag) === 'inventorylocation-us')) shippingTime = '2 – 5';
    if (tags?.some((tag) => _.toLower(tag) === 'low-customization:2-4days')) shippingTime = '8 – 12';

    if (shippingTime === '2 – 5') {
      services[1].lead = `<strong>Only available in the U.S.:</strong>`;
      services[1].text = `${shippingTime} Business Days Shipping.`;
    }
  }
  if (return_refund_policy) {
    services[2].lead = return_refund_policy.text;
  }

  return services;
};

const sanitize = (html = '') =>
  sanitizeHtml(html, {
    allowedTags: [
      'a',
      'b',
      'br',
      'i',
      'u',
      's',
      'em',
      'del',
      'big',
      'sup',
      'sub',
      'mark',
      'small',
      'strong',
      'span',
      'p',
      'ul',
      'ol',
      'li',
      'div',
    ],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });

const getImageIndexByVariant = (images: any[], variant: any): number =>
  Math.max(0, _.findIndex(images, { shopify_id: variant?.shopify_image_id }));

export { getImageIndexByVariant, getProductServices, sanitize };
