import { Image as AntImage, Skeleton } from 'antd-mobile';
import type { ImageProps } from 'antd-mobile/es/components/image';
import cn from 'classnames';
import { FiImage } from 'react-icons/fi';

import css from './index.module.scss';

export type { ImageProps };

function Image({ className, ...restProps }: ImageProps): JSX.Element {
  return (
    <AntImage
      className={cn(css.ns_com_image_main, 'image', className)}
      fallback={
        <div className={cn(css.fallback, 'image__fallback')}>
          <FiImage />
        </div>
      }
      placeholder={<Skeleton className={cn(css.placeholder, 'image__placeholder')} />}
      {...restProps}
    />
  );
}

export default Image;
