/* eslint-disable react/no-unused-prop-types */
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AutoLink from '@/components/AutoLink';

import css from './index.module.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  notices?: any[];
  closeable?: boolean;
  onClose?: () => void;
}

function NoticeBar({ className, style, notices }: Props): JSX.Element {
  const [activeSwiperIndex, setActiveSwiperIndex] = useState<number>(0);
  const shouldShowNotices = useMemo(() => getShouldShowNotices(notices), [notices]);
  const hasMultipleNotices = shouldShowNotices?.length > 1;
  const reStyle = {
    ...style,
    backgroundColor: shouldShowNotices[activeSwiperIndex]?.bg_color,
  };
  return (
    <div className={cn(css.ns_com_notice_bar_main, className)} style={reStyle}>
      <div className={css.content}>
        <Swiper
          className={css.ns_or_swiper}
          loop={hasMultipleNotices}
          preventClicksPropagation={false}
          autoplay={{
            delay: 8000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          navigation={hasMultipleNotices}
          modules={[Autoplay, Navigation]}
          onSlideChange={(swiper) => {
            setActiveSwiperIndex(swiper?.realIndex);
          }}
        >
          {shouldShowNotices?.map((notice: any) => (
            <SwiperSlide key={notice?.text}>
              <div className={css.notice}>
                <AutoLink targetType={notice?.target_type} targetData={notice?.target_data}>
                  <span dangerouslySetInnerHTML={{ __html: sanitize(notice?.text) }} />
                </AutoLink>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

function sanitize(html = ''): string {
  return sanitizeHtml(html, {
    allowedTags: ['a', 'b', 'br', 'i', 'u', 's', 'em', 'del', 'big', 'sup', 'sub', 'mark', 'small', 'strong', 'span'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });
}

export function getShouldShowNotices(notices: any[] = []): any[] {
  return _.filter(notices, (notice: any) =>
    moment().isBetween(notice?.not_valid_before, notice?.not_valid_after, undefined, '[]')
  );
}

export default NoticeBar;
