$coverHeight: 86px;

.ns_com_cart_product_list_main {
  .group {
    background: var(--color-white);
    .group_title_wrapper {
      padding: var(--spacing-l);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 500;
      background: #fbfbfb;
      border-bottom: 1px solid #c7c7c7;
      display: flex;
      justify-content: space-between;
      .group_title {
        display: flex;
        align-items: center;
        label {
          margin-right: 12px;
        }
      }
      .extra {
        color: #424242;
        font-size: 16px;
        font-weight: 500;
        img {
          margin-right: 4px;
          margin-top: -3px;
        }
      }
    }

    .group_list_wrapper {
      + .group_list_wrapper {
        border-top: 1px solid #e8e8e8;
        padding-top: 12px;
      }
      .cart_item_error_message {
        padding-bottom: 12;
        color: #ff5050;
      }
    }
    .group_list {
      display: grid;
      grid-template-columns: 100%;
      padding: var(--spacing-l);
      grid-row-gap: var(--spacing-l);

      &.spu_dimension {
        padding: 0;
      }
    }

    &.sold_out_group .group_title_wrapper {
      color: var(--color-danger);
      background-color: #fff5f5;
    }
  }
  .group + .group {
    margin-top: 20px;
  }

  .save_all_for_later_button {
    cursor: pointer;
    display: flex;
    align-items: center;
    .save_all_for_later_button_text {
      padding-left: 4px;
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .item_checkbox_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100;
      padding-right: 14px;
    }
    .item_cover {
      margin-right: var(--spacing-m);
      cursor: pointer;
      min-height: $coverHeight;
      max-height: 100px;
      height: 100%;
      position: relative;
      .item_cover_image {
        width: 75px;
        height: 100%;
        border-radius: 8px;
      }

      .item_footer {
        position: absolute;
        bottom: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;

        span {
          color: var(--p-text);
          background-color: #f2f2f2d9;
          display: inline-block;
          border-radius: 4px;
          padding: 0 8px;
          font-size: 10px;
          line-height: 1.4em;
          margin-bottom: 6px;
        }
      }
    }
    .item_info {
      height: 100%;
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: $coverHeight;
    }

    .item_info_box {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .item_title_variant_wrapper {
      .item_variant {
        margin: 4px 0;
      }
    }

    .item_fulfill_label {
      display: flex;
    }

    .item_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item_title {
        font-size: var(--font-size1);
        color: #000;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        cursor: pointer;
        margin-right: 10px;
      }

      .item_add_to_board_icon {
        display: flex;
        cursor: pointer;
        padding-right: 7px;
      }
    }
    .item_variant {
      font-size: var(--font-size1);
      color: #000;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
      // padding: 0 8px;
    }
    .item_price_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item_price {
        color: #000;
        font-size: 14px;
        line-height: 1.3;
      }
      .item_actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .actions_delete_button {
        cursor: pointer;
        display: flex;
      }
      .stepper_wrapper {
        margin-left: 14px;
      }
    }
    .item_alert {
      margin-top: var(--spacing-m);
      color: var(--color-danger);
      font-size: var(--font-size2);
      line-height: 1.3;
    }
    &.disabled {
      .item_cover,
      .item_title,
      .item_variant,
      .item_price,
      .stepper_wrapper {
        opacity: 0.4;
      }
    }
    .item_boost {
      width: fit-content;
      background: #ffcc17;
      border-radius: 2px;
      padding: 3px 4px;
      font-size: 9px;
      line-height: 1em;
      .boost {
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .group {
      border-radius: 8px;

      &:not(:first-child) {
        margin-top: var(--spacing-m);
      }

      &.sold_out_group {
        padding-top: 0;
      }

      .group_title_wrapper {
        border-radius: 8px 8px 0 0;
        border: none;
      }
    }
  }
}
