import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import shopifyIcon from '@/assets/images/shopify_icon.svg';
import AccessControl from '@/components/AccessControl';
import Button, { Props as ButtonProps } from '@/components/Button';
import ImportProductsDialog from '@/components/ImportProductsDialog';
import Notification from '@/components/Notification';
import { useDesktop } from '@/hooks';
import useTaskOperateProgress from '@/hooks/queries/useTaskOperateProgress';

import css from './index.module.scss';

export interface Props extends ButtonProps {
  product?: any;
  importProductsQueryParams?: any;
  importedText?: React.ReactNode;
  importedLabel?: React.ReactNode;
  showImportedLabel?: boolean;
  showImportingText?: boolean;
  beforeShowImportDialog?: () => Promise<void>;
  buttonColor?: 'primary' | 'main' | 'default';
}

type VisibleState = 'show' | 'shown' | 'hide' | 'hidden';

function ImportButton({
  className,
  product,
  importProductsQueryParams: importProductsQueryParamsProp,
  children = 'Import to Store',
  importedText = 'Imported',
  importedLabel = (
    <span className={css.imported_label}>
      <img src={shopifyIcon} alt="icon" />
    </span>
  ),
  showImportedLabel = false,
  showImportingText = true,
  beforeShowImportDialog,
  buttonColor = 'primary',
  ...resetProps
}: Props): JSX.Element {
  const isDesktop = useDesktop();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [importProductsQueryParams, setImportProductsQueryParams] = useState(importProductsQueryParamsProp);
  const importProgress = useTaskOperateProgress();
  const [importTaskInfo, setImportTaskInfo] = useState<any>({});
  const [importProgressData, setImportProgressData] = useState<any>();
  const [importProductsDialogVisible, setImportProductsDialogVisible] = useState<VisibleState>('hidden');
  const [progressNotificationVisible, setProgressNotificationVisible] = useState<VisibleState>('hidden');
  const progressNotificationVisibleRef = useRef(progressNotificationVisible);
  progressNotificationVisibleRef.current = progressNotificationVisible;
  const { progress = 0, total = 1, succeed = 0, failed = 0 } = importProgressData ?? {};
  const importProgressFinish = progress >= 100;
  const importProgressFinishRef = useRef(importProgressFinish);
  importProgressFinishRef.current = importProgressFinish;
  const importFailedProductsIds = _.map(_.reject(importProgressData?.result, { errno: 200 }), 'id');
  const isImported = product?.rel?.is_imported === 1;
  const isImporting = !importProgress.isIdle && !importProgressFinish;
  const lastImportProductsStatus = localStorage.getItem('lastImportProductsStatus') ?? 'draft';

  useEffect(() => {
    setImportProductsQueryParams(importProductsQueryParamsProp);
  }, [importProductsQueryParamsProp]);

  useEffect(() => {
    if (importProgressFinish && succeed > 0) {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('sellerCollections');
    }

    // when import finish and no failed items delay close Notification after 8s
    if (importProgressFinish && failed < 1) {
      setTimeout(() => {
        progressNotificationVisibleRef.current === 'shown' && setProgressNotificationVisible('hide');
      }, 8000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importProgressFinish, succeed, failed, progressNotificationVisibleRef]);

  const updateImportProductsProgress = (taskId: string, isInit = false) => {
    if (isInit) {
      setImportProgressData(undefined);
      importProgress.reset();
    }

    (isInit || !importProgressFinishRef.current) &&
      importProgress.mutate(taskId, {
        onSettled: (data) => {
          data?.data && setImportProgressData(data?.data);
          data?.data?.progress < 100 && setTimeout(() => updateImportProductsProgress(taskId), 3000);
        },
      });
  };

  const handleImportProductsStart = (shopId: string, taskId: string) => {
    setImportTaskInfo({ shopId, taskId });
    setImportProductsDialogVisible('hide');
    setProgressNotificationVisible('show');
    updateImportProductsProgress(taskId, true);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isImporting) return;

    if (_.isFunction(beforeShowImportDialog)) {
      beforeShowImportDialog()?.then(() => setImportProductsDialogVisible('show'));
      return;
    }

    setImportProductsDialogVisible('show');
  };

  const handleRetryFailedProductsClick = () => {
    if (importProductsDialogVisible !== 'hidden') return;

    setProgressNotificationVisible('hidden');
    setImportProductsQueryParams({ product_ids: importFailedProductsIds });
    setImportProductsDialogVisible('show');
  };

  let importProgressTitle: React.ReactNode;
  let importProgressDescription: React.ReactNode;

  if (importProgressFinish) {
    const productDeletedErrorMessage = (
      <div className={css.error_message}>Oops! The product{failed > 1 ? 's are' : ' is'} discontinued.</div>
    );
    const shopifyLimitErrorMessage = (
      <div className={css.error_message}>
        You have reached{' '}
        <a href="https://shopify.dev/api/usage/rate-limits#resource-based-rate-limits" target="_blank" rel="noreferrer">
          Shopify&#39;s daily product variant creation limit (1,000 per day)
        </a>
        . Come back and import again tomorrow!
      </div>
    );
    const hasProductDeletedError = _.some(importProgressData?.result, { errno: 72201 });
    const hasShopifyLimitError = _.some(importProgressData?.result, { errno: 72203 });
    // eslint-disable-next-line no-nested-ternary
    const errorMessage = hasShopifyLimitError
      ? shopifyLimitErrorMessage
      : hasProductDeletedError
      ? productDeletedErrorMessage
      : null;

    importProgressTitle = (
      <>
        {succeed} Product{succeed > 1 && 's'} Imported
      </>
    );
    importProgressDescription =
      failed > 0 ? (
        <>
          {failed} product{failed > 1 && 's'} failed to import
          <br />
          {errorMessage}
          {!errorMessage && (
            <Button color="danger" fill="none" underline onClick={handleRetryFailedProductsClick}>
              Retry Failed Product{failed > 1 && 's'}
            </Button>
          )}
        </>
      ) : null;
  } else {
    importProgressTitle = (
      <>
        Importing as <span className="text-underline">{_.capitalize(lastImportProductsStatus)}</span> to your store
      </>
    );
    importProgressDescription = 'Closing this won’t affect import';
  }

  return (
    <AccessControl requireAuth requireStore>
      {({ isReject, redirect }) => (
        <>
          {isImported && !isImporting && showImportedLabel ? (
            <span onClick={handleClick}>{importedLabel}</span>
          ) : (
            <Button
              className={cn(css.ns_com_import_button_main, className, {
                [`import-button--importing`]: isImporting,
              })}
              color={buttonColor}
              shape="rounded"
              loading={isImporting}
              loadingText={showImportingText ? 'Importing' : undefined}
              disabled={isImporting}
              onClick={
                isReject
                  ? (e) => {
                      e.stopPropagation();
                      navigate(redirect);
                    }
                  : handleClick
              }
              {...resetProps}
            >
              {isImported ? importedText : children}
            </Button>
          )}

          {importProductsDialogVisible !== 'hidden' && (
            <ImportProductsDialog
              visible={['show', 'shown'].includes(importProductsDialogVisible)}
              showMountAnimation
              importProductsQueryParams={importProductsQueryParams}
              onOk={handleImportProductsStart}
              onCancel={() => setImportProductsDialogVisible('hide')}
              afterShow={() => setImportProductsDialogVisible('shown')}
              afterClose={() => setImportProductsDialogVisible('hidden')}
            />
          )}

          {progressNotificationVisible !== 'hidden' && (
            <Notification
              placement={isDesktop ? 'topRight' : 'top'}
              color={!importProgressFinish ? 'black' : 'white'}
              visible={['show', 'shown'].includes(progressNotificationVisible)}
              duration={false}
              showMountAnimation
              loading={!importProgressFinish}
              title={importProgressTitle}
              description={importProgressDescription}
              progressPercent={importProgressFinish ? undefined : progress}
              progressText={`${succeed + failed}/${total} ${total > 1 ? 'items' : 'item'}`}
              onClose={() => setProgressNotificationVisible('hide')}
              afterShow={() => setProgressNotificationVisible('shown')}
              afterClose={() => setProgressNotificationVisible('hidden')}
              footer={
                <>
                  {importProgressFinish && failed > 0 && (
                    <Button block shape="rounded" link="mailto:support@kiwidrop.com">
                      Contact Support
                    </Button>
                  )}

                  {importProgressFinish && succeed > 0 && (
                    <Button color="primary" block shape="rounded" link={`/imported-products/${importTaskInfo?.shopId}`}>
                      View Imported Products
                    </Button>
                  )}
                </>
              }
            />
          )}
        </>
      )}
    </AccessControl>
  );
}

export default ImportButton;
