import produce from 'immer';

import api, { responseErrorHandler } from './api';

type JenniferPriceRuleTargetType = 'price' | 'cost_price';

type BaseResponseBody<T> = {
  errno: string;
  errmsg: string;
  data: T;
};

type PagingResponseType<T> = {
  pagination: {
    from: number;
    page_size: number;
    total: number;
    current: number;
    has_prev: boolean;
    has_next: boolean;
    total_page: number;
  };
  data: T[];
};

// --------------------------------------------------------
type JenniferPriceRuleResponseType = {
  export_price_rule: {
    val: number;
    target: JenniferPriceRuleTargetType;
  };
  shipping_method_settings: { method: string; price: number }[];
};

type JenniferPriceRuleRequestBody = {
  export_price_rule: {
    val: number;
    target: JenniferPriceRuleTargetType;
  };
};
// fetch sales / price rules
export async function getJenniferPriceRule(shopId?: string) {
  const res = await api.get<BaseResponseBody<JenniferPriceRuleResponseType>>(`/ds/v2/jennifer/${shopId}/settings`);
  responseErrorHandler(res);
  return res.data;
}

export const updateJenniferPriceRule = (shopId?: string) => async (params?: JenniferPriceRuleRequestBody) => {
  const res = await api.put<BaseResponseBody<JenniferPriceRuleResponseType>>(
    `/ds/v2/jennifer/${shopId}/settings`,
    params
  );
  responseErrorHandler(res);
  return res.data;
};

// --------------------------------------------------------
export type QuerySearchType = 'active' | 'inactive';

export type fetchCategoryForRetailParams = {
  keywords?: string;
  status?: QuerySearchType;
  page?: number;
};

type FetchCategoryForRetailRequestBody = {
  query?: string; // Optional
  filters?: { export_status: QuerySearchType }; // Optional
  pagination?: { page_size: number; page: number }; // Optional
};

export type CategoryForRetailListItemResponse = {
  id: number;
  title: string;
  cover: string;
  export_status: QuerySearchType;
  variants: [
    {
      id: number;
      product_id: number;
      price: number;
      cost_price: number;
      shopify_inventory_quantity: number;
      original_price: number;
    }
  ];
  price_min?: number; // Optional
  price_max?: number; // Optional
  original_price_min?: number; // Optional
  original_price_max?: number; // Optional
  inventory_quantity: number;
  cost_price_min: number; // Optional
  cost_price_max: number; // Optional
};

export type UpdateCategoryForRetailRequestBody = {
  products: {
    id: number;
    export_status?: QuerySearchType; // active, inactive Optional
    variants?: {
      id: number;
      price: number;
    }[];
  }[];
};

export type DeactivateCategoryForRetailRequestBody = {
  product_ids: number[];
};

// category for retail
export const fetchCategoryForRetail = (shopId?: string) => async (params?: fetchCategoryForRetailParams) => {
  // const body: FetchCategoryForRetailRequestBody = produce({}, (draft) => {
  // });

  const body: FetchCategoryForRetailRequestBody = {
    query: params.keywords,
    filters: {
      export_status: params.status,
    },
    pagination: {
      page: params.page,
      page_size: 10,
    },
  };

  const res = await api.get<BaseResponseBody<PagingResponseType<CategoryForRetailListItemResponse>>>(
    `/ds/v2/jennifer/${shopId}/products`,
    body
  );
  responseErrorHandler(res);
  return res.data;
};

export const updateCategoryForRetail = async (params?: UpdateCategoryForRetailRequestBody) => {
  const res = await api.put<BaseResponseBody<JenniferPriceRuleResponseType>>(`/ds/v2/jennifer-products`, params);
  responseErrorHandler(res);
  return res.data;
};

export const deactivateCategoryForRetail = async (params?: DeactivateCategoryForRetailRequestBody) => {
  const res = await api.put<BaseResponseBody<JenniferPriceRuleResponseType>>(
    `/ds/v2/jennifer-products/deactivate`,
    params
  );
  responseErrorHandler(res);
  return res.data;
};

// --------------------------------------------------------
type orderType = 'unpaid' | 'paid' | 'cancelled' | 'processing';
type MySalesOrdersRequestParams = {
  keyword?: string;
  status?: orderType;
};

export const getMySalesOrders = (shopId?: string) => async (params?: MySalesOrdersRequestParams) => {
  const res = await api.get<any>(`/ds/v2/jennifer/${shopId}/orders`, params);
  responseErrorHandler(res);
  return res.data;
};
// --------------------------------------------------------
// get payout list

type PayoutResponse = {
  payout_reports: {
    title: string;
    count: number;
    started_at: string;
    ended_at: string;
  }[];
};

export const getPayout = async (shopId?: string) => {
  const res = await api.get<BaseResponseBody<PayoutResponse>>(`/ds/v2/jennifer/${shopId}/payout-reports`);
  responseErrorHandler(res);
  return res.data;
};

type DownloadLinkResponse = {
  download_url: string;
};
export const getPayoutReportDownloadLink =
  (shopId?: string) => async (params: { started_at: string; ended_at: string }) => {
    const res = await api.get<BaseResponseBody<DownloadLinkResponse>>(
      `/ds/v2/jennifer/${shopId}/payout-reports/download`,
      params
    );
    responseErrorHandler(res);
    return res.data;
  };
//
