import { message } from 'antd';

import useUpdateCart from '@/hooks/queries/useUpdateCart';

const useDeleteItemsInCart = () => {
  const updateCart = useUpdateCart();

  const updateCartAPI = (
    req,
    config: {
      onSuccess?: () => void;
      onError?: () => void;
    }
  ) => {
    const { onSuccess, onError } = config;
    updateCart.mutate(req, {
      onError: (error) => {
        message.error((error as Error)?.message || 'Update Cart Failure!');
        onError?.();
      },
      onSuccess() {
        onSuccess?.();
      },
    });
  };

  return {
    updateCartAPI,
    updateCart,
  };
};

const placeholder = '';
export { placeholder, useDeleteItemsInCart };
