import { OrderItem, OrderStatus } from '@/pages/Orders/types';
import { checkIsOverBuy, checkIsSoldOut } from '@/utils/biz';

const defaultValue = {
  regardUnpaidSoldOutOrder: false,
  regardShopifyUnpaidSoldOutOrder: false,
  regardSoldOutItems: [],
};

const useOrderAdded = (order: OrderItem) => {
  if (!order || !order?.id) {
    return defaultValue;
  }

  const regardSoldOutItems = order?.line_items?.filter(
    ({ available_quantity, qty }) => checkIsSoldOut(available_quantity) || checkIsOverBuy(qty, available_quantity)
  );
  const regardUnpaidSoldOutOrder = !!regardSoldOutItems?.length && order?.status === OrderStatus.Unpaid;
  const regardShopifyUnpaidSoldOutOrder =
    regardUnpaidSoldOutOrder && (!!order?.shop_order_shopify_id || !!order?.shop_order?.shopify_id);

  return {
    regardUnpaidSoldOutOrder,
    regardShopifyUnpaidSoldOutOrder,
    regardSoldOutItems,
  };
};

export default useOrderAdded;
