$prefixCls: account-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
  }

  &__account-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--spacing-l);
    background: var(--color-white);
    border-radius: 4px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

    &-body {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    &-avatar {
      background: var(--color-primary);
    }

    &-info {
      flex: 1;
      min-width: 0;
      margin-left: var(--spacing-xxl);
    }

    &-name {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-phone {
      font-size: 16px;
      line-height: 1.4;
      margin-top: var(--spacing-m);
    }

    &-arrow {
      font-size: 16px;
      line-height: 1;
      margin-right: -3px;
    }

    &-footer {
      padding: var(--spacing-l) 0 0;
      margin-top: var(--spacing-l);
      border-top: 1px solid #f5f5f5;
    }

    &-referral-code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      cursor: pointer;

      &-text {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          margin-left: var(--spacing-m);
          font-style: normal;
          font-weight: normal;
          background: #f5f5f5;
          border-radius: 50%;
        }
      }
    }
  }

  &__list {
    --font-size: 16px;
    line-height: 1.4;
    margin-top: var(--spacing-l);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

    .adm-list-body {
      border-radius: 4px;
    }

    .adm-list-item-content-prefix {
      line-height: 0;
      font-size: 18px;
      color: var(--color-primary);
    }

    .adm-list-item-content-arrow {
      color: var(--color-black);
      font-size: 10px;
    }
  }

  &__build-info {
    position: sticky;
    bottom: 0;
    display: block;
    margin: 0 auto;
    color: var(--color-gray2);
    font-size: var(--font-size1);
    text-align: center;
    transform: scale(0.75);
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 0 0 var(--spacing-l);
    }

    &__account-card {
      border-radius: 0;
      box-shadow: none;
    }

    &__list {
      margin-top: var(--spacing-m);
      box-shadow: none;

      .adm-list-body {
        border-radius: 0;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__content {
      padding: 34px var(--spacing-l);
      max-width: var(--content-small-max-width);
    }
  }
}
