import { Frame, Page } from '@shopify/polaris';
import isEmpty from 'lodash/isEmpty';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';

import { EmptyContent } from './components/EmptyContent';
import { PayoutItem } from './components/PayoutItem';
import { usePayout } from './hooks/usePayout';
import styles from './index.module.scss';

const style = { marginTop: 64 };

const PayoutText = {
  title: 'Payout Reports',
};

function Payout(): JSX.Element {
  const params = useParams();

  const { data, isLoading, isError } = usePayout(params.shopId);

  return (
    <Frame>
      <Page>
        <div>
          <Helmet>
            <html lang="en" className={styles.payout} />
            <title>{PayoutText.title}</title>
          </Helmet>

          <SecondaryNavBar className={styles.secondary_nav_bar} backReplace>
            {PayoutText.title}
          </SecondaryNavBar>

          <div>
            <header className={styles.header}>
              <h1 className={styles.title}>{PayoutText.title}</h1>
            </header>

            {/* payout 暂不考虑翻页分页问题 */}

            <StatusViewRenderer
              statusStyle={style}
              isEmpty={isEmpty(data)}
              isLoading={isLoading}
              error={isError}
              emptyContent={<EmptyContent />}
            >
              <section className={styles.content}>
                {data?.length
                  ? data.map((item) => <PayoutItem key={item.title} data={item} shopId={params.shopId} />)
                  : null}
              </section>
            </StatusViewRenderer>
          </div>
        </div>
      </Page>
    </Frame>
  );
}

export default Payout;
