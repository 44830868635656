.ns_com_checkbox_main {
  --icon-size: 26px;
  --font-size: var(--font-size3);
  --gap: var(--spacing-m);
  color: var(--color-black);
  line-height: 1;
  &.primary {
    :global {
      input[type='checkbox']:checked + .adm-checkbox-custom-icon {
        svg {
          fill: #cee46a;
        }
      }
    }
  }
  .shape_circle {
    color: #000;
    &.disabled {
      color: #e8e8e8;
    }
  }
  :global {
    .adm-checkbox-custom-icon {
      color: #e8e8e8;
    }
    input[type='checkbox']:checked + .adm-checkbox-custom-icon {
      svg {
        fill: #000;
      }
    }
  }

  &:global(.adm-checkbox-checked .adm-checkbox-custom-icon) {
    color: var(--color-gray5);
  }

  &:global(.adm-checkbox-disabled) {
    position: relative;
    &:before {
      content: '';
      width: 1px;
      height: 100%;
      background-color: var(--color-gray4);
      display: block;
      position: absolute;
      left: 50%;
      border-radius: 2px;
      transform: translate(-1px) rotate(45deg);
    }
  }
}
