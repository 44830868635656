import { useQuery } from 'react-query';

import { getStores } from '@/services/ds';

export default function useStores(waitAuth?: boolean) {
  return useQuery(['store', 'getStores'], () => getStores({ page_size: 3000 }), {
    enabled: !waitAuth,
    staleTime: 1000,
  });
}
