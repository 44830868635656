.order_detail_page_active {
  $actionsBarHeight: 64px;

  .app-layout {
    --app-layout-padding-bottom: #{$actionsBarHeight};
    background: #f5f5f5;

    // safe area bottom patch
    --app-layout-padding-bottom: calc(
      #{$actionsBarHeight} - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l))
    );

    .order_detail_page_title {
      display: none;
      margin-bottom: var(--spacing-xxl);
      color: var(--color-black);
      font-size: 30px;
      font-weight: normal;
      line-height: 1.15;
    }

    .order_detail_page_content {
      margin: 0 auto;
    }

    .order_detail_page_section {
      margin-bottom: 8px;
      .title {
        padding: 10px;
        font-weight: 500;
        font-size: var(--section-header-font-size);
        color: #424242;
        &.customer_info {
          display: flex;
          justify-content: space-between;
          .content {
            line-height: 1.618;
          }

          .edit_address_button {
            font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
            a {
              color: #424242;
            }
            .icon {
              margin-right: var(--spacing-s);
              vertical-align: -0.125em;
            }
          }
        }
        .icons_wrapper {
          display: flex;
          gap: 16px;
          align-items: center;
        }
      }
      .content {
        padding: 10px;
        background-color: #fff;
        .content_default_address_tag {
          margin-bottom: 8px;
        }
        .link {
          text-decoration: underline;
          color: #000;
        }
        .copy_btn {
          display: inline-block;
          margin-left: var(--spacing-m);
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #f5f5f5;
          border-radius: 99px;
          cursor: pointer;
        }
      }
    }

    .block_one_desc {
      display: flex;
      justify-content: space-between;
    }

    .info_block {
      padding-bottom: 10px;
      .content {
        font-size: 14px;
        .edit_shopify_order_button {
          padding: 0 10px;
          text-decoration: underline;
          color: #000;
        }
      }
    }

    .products_block {
      .order_detail_page_section {
        .title {
          display: flex;
          justify-content: space-between;
          .desc_wrapper {
            font-size: 14px;
            color: #424242;
            display: flex;
            align-items: center;
            font-weight: 500;
            cursor: pointer;
            .icon {
              margin-right: 4px;
            }
          }
        }
        .content_wrapper {
          background-color: transparent;
          display: grid;
          grid-row-gap: 12px;
        }
        .content {
          padding: 0;
        }
        .header {
          padding: 12px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f5f5f5;
        }
      }
    }

    .order_detail_page_button_actions {
      position: fixed;
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      gap: var(--spacing-m);
      left: 0;
      bottom: 0;
      width: 100%;
      height: $actionsBarHeight;
      z-index: 100;
      padding: var(--spacing-l);
      background: var(--color-white);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

      // safe area bottom patch
      height: var(--app-layout-padding-bottom);
      padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));

      &:empty {
        display: none;
      }

      & > :only-child {
        display: block;
        width: 250px;
        margin: 0 auto;
      }
    }

    .combine_order_block {
      .order_detail_page_section {
        .content {
          padding: 2px;
        }
      }
    }

    .cost_block {
      .cost_block_total {
        background-color: #fff;
        padding: 0 12px 12px;
      }
      &.active {
        .cost_block_total {
          padding-top: 8px;
          background-color: #f5fce5;
        }
      }
    }

    .refund_block {
      .order_detail_page_section {
        .block_one_desc.total {
          font-weight: 500;
        }
        .block_one_desc.retracted {
          margin-top: 8px;
          padding-top: 8px;
          border-top: 1px solid #f5f5f5;
        }
      }
    }

    .red {
      color: var(--color-danger);
    }

    @media screen and (min-width: 769px) {
      .info_block {
        padding: 0;
      }

      .order_detail_page_section {
        .title {
          padding: 10px 0;
        }
        .content {
          border-radius: 12px;
          box-shadow: var(--ds2-box-shadow);
        }
      }

      .cost_block {
        .order_detail_page_section {
          .content_wrapper {
            border-radius: 12px;
            box-shadow: var(--ds2-box-shadow);
          }
          // .content {
          //   border-bottom-left-radius: 0;
          //   border-bottom-right-radius: 0;
          // }
          // .cost_block_total {
          //   border-bottom-left-radius: 12px;
          //   border-bottom-right-radius: 12px;
          // }
        }
      }

      .order_detail_page_button_actions {
        position: static;
        margin: 30px auto var(--spacing-l);
        width: auto;
        height: auto;
        padding: 0;
        background: transparent;
        box-shadow: none;
        max-width: 624px;
      }

      .secondary_nav_bar {
        display: none;
      }

      .order_detail_page_content {
        max-width: 900px;
        padding: 34px var(--spacing-l);
      }

      .order_detail_page_title {
        display: block;
      }
    }

    @media screen and (max-width: 768px) {
      --app-layout-padding-top: var(--secondary-nav-bar-height);

      &__nav-bar {
        display: none;
      }

      &__tab-bar {
        display: none;
      }
    }
  }
}
