$navHeight: 46px;
$contentHeight: 400px;
$contentTopPadding: 25px;
$contentBottomPadding: 38px;

.ns_com_pc_nav_manu_main {
  display: block;
  position: relative !important;
  box-sizing: border-box;
  height: $navHeight;
  .basic_nav_wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    .icon_wrapper {
      font-size: 16px;
      background: #eeeeee;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
    }
    .navigation_button_wrapper {
      height: $navHeight;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -30px;
      &.disabled_navigation_button {
        display: none;
      }
      &::before {
        content: '';
        width: 30px;
        height: 46px;
        background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff);
        position: absolute;
        top: 0;
        right: -30px;
        z-index: 2;
      }
      &.next {
        left: unset;
        right: -30px;
        .icon_wrapper {
          transform: rotate(180deg);
        }
        &::before {
          background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
          right: 30px;
        }
      }
    }
    .nav_swiper_wrapper {
      .swiper-slide {
        width: fit-content;
      }
    }
    .nav_item_wrapper {
      .nav_item {
        height: $navHeight;
        line-height: $navHeight;
        padding: 0 var(--spacing-l);
        box-sizing: border-box;
        font-size: var(--font-size3);
        color: var(--color-black);
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;
      }
      &:hover {
        &.active .nav_item .title {
          border-bottom: 2px solid var(--color-primary-medium);
          color: var(--color-primary-medium);
        }
      }
    }
  }
  .content {
    position: absolute;
    left: 0;
    width: 100%;
    height: $contentHeight;
    max-height: $contentHeight;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px 0px #00000033;
    .columns_wrapper {
      display: flex;
      overflow-x: hidden;
      margin: $contentTopPadding 0 $contentBottomPadding;
      padding: 0 100px;
      .column_item {
        flex: 0 0 20%; // 当子项为5时，5等分
        box-sizing: border-box;
        overflow-x: hidden;
        padding: 0 20px;
        // 设置最大宽度，防止配置上只有一个column时样式变形
        max-width: var(--content-small-max-width) !important;

        .type_wrapper {
          font-size: 14px;
          color: #000000;

          padding-bottom: 16px;
          &:last-child {
            padding-bottom: 0 !important;
          }
          a {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
              color: var(--color-primary-medium);
            }
          }
          &.blank_wrapper {
            padding-bottom: 20px;
          }
          &.head_wrapper {
            line-height: 120%;
            font-weight: 500;
          }
          &.text_wrapper {
            cursor: pointer;
            line-height: 24px;
            padding-bottom: 4px;
          }
          &.img_wrapper {
            cursor: pointer;
            .img {
              width: 100%;
              min-height: 100px;
            }
            .title {
              display: block;
              text-align: center;
              padding-top: 8px;
              font-weight: 500;
              line-height: 120%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
  :global {
    .swiper-slide {
      position: inherit;
    }
  }
}
