import { useMutation, useQueryClient } from 'react-query';

import { useLastAddedSellerCollections, useSellerCollectionData } from '@/hooks/biz';
import { updateProductSellerCollection } from '@/services/ds';

export default function useToggleProductFavorite() {
  const queryClient = useQueryClient();
  const { data: sellerCollectionsData } = useSellerCollectionData();
  const { ids, update } = useLastAddedSellerCollections(sellerCollectionsData);
  const defaultIds = sellerCollectionsData.filter((d) => !!d.is_default).map(({ id }) => id);
  const addCollectionIdsWhenFavorite = ids?.length ? ids : defaultIds;

  return useMutation(
    ({ id, isFavorite = true }: { id: string | string[] | undefined; isFavorite?: boolean }) =>
      updateProductSellerCollection({
        product_id: id,
        add_collection_ids: isFavorite ? addCollectionIdsWhenFavorite : [],
        remove_collection_ids: !isFavorite ? ids : [],
      }),
    {
      onSuccess: (data, variables) => {
        // 使用默认seller collection，则需要默认更新到localStorage中
        if (variables?.isFavorite && !ids?.length) {
          update(defaultIds);
        }
        queryClient.invalidateQueries('products');
        queryClient.invalidateQueries('sellerCollections');
      },
    }
  );
}
