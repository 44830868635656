$prefixCls: imported-products-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      .status_group_wrapper {
        margin-top: var(--spacing-m);
      }
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
        .status_group_wrapper {
          margin-left: var(--spacing-l);
          margin-right: var(--spacing-l);
        }
      }
      @media screen and (min-width: 769px) {
        .status_group_wrapper {
          margin-top: var(--spacing-l);
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__empty {
    padding: var(--spacing-l);
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;

    p {
      margin: 0;
    }

    .button {
      margin-top: 38px;
    }
  }

  &__status-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-l);
    color: var(--color-danger);
    font-size: var(--font-size3);
    line-height: 1.4;
  }

  &__product-list {
    padding: var(--spacing-l);
    margin-top: var(--spacing-m);
    background: var(--color-white);
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 0 0 var(--spacing-l);
    }

    &__store-select {
      margin: var(--spacing-l);
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
    }

    &__title {
      display: block;
    }

    &__product-list {
      margin-top: var(--spacing-l);
      border-radius: 4px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    }
  }
}
