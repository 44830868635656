import { AddressesDialogViewType } from './types';

const PRIVATE_ADDRESSES_TABS = [
  {
    id: 'customer-addresses',
    panelID: 'customer-addresses',
    content: 'Shipping Info',
  },
  {
    id: 'private-inv-addresses',
    panelID: 'private-inv-addresses',
    content: 'Private Inventory Info',
  },
];

const ADDRESS_DIALOG_PATH = {
  add: 'shipping-addresses/add',
  edit: (id: number) => `shipping-addresses/edit/${id}`,
  list: 'shipping-addresses/list',
  list_inv: 'shipping-addresses/list_inv',
};

const ADDRESSES_DIALOG_VIEW_TYPES: { [key: string]: AddressesDialogViewType } = {
  add: 'add',
  edit: 'edit',
  list: 'list',
  list_inv: 'list_inv',
};

export { ADDRESS_DIALOG_PATH, ADDRESSES_DIALOG_VIEW_TYPES, PRIVATE_ADDRESSES_TABS };
