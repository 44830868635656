const App = ({ fill, stroke = '#424242' }: { fill?: string; stroke?: string }) => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.17825 9.08968C6.74622 10.9613 8.87613 12.5247 10.2085 13.1818C12.5196 12.0398 17.2326 8.17427 17.2326 4.71204C17.2326 2.98545 16.2628 1.18182 13.7477 1.18182C12.1208 1.18182 10.7795 2.54587 10.2085 3.67427C9.63746 2.54587 8.29607 1.18182 6.66918 1.18182C4.15408 1.18182 3.18429 2.98092 3.18429 4.71204C3.18429 4.91143 3.19789 5.11083 3.22961 5.31022M7.97432 5.42805L9.73263 7.18636M9.73263 7.18636L7.97432 8.94466M9.73263 7.18636H1"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default App;
