import classNames from 'classnames';
import { useState } from 'react';

import AutoLink from '../AutoLink';
import Checkbox from '../Checkbox';
import styles from './styles.scss';

const { prefixCls } = styles;

export default function SmsMarketingConsent({
  confirmMarketConsent,
  setConfirmMarketConsent,
  showMarketConsentError,
  setShowMarketConsentError,
}) {
  return (
    <div
      className={classNames({
        [`${prefixCls}__marketing-consent`]: true,
        [`${prefixCls}__marketing-consent-error`]: showMarketConsentError,
      })}
    >
      <Checkbox
        id="sms_consent"
        shape="square"
        checked={confirmMarketConsent}
        onChange={(isChecked) => {
          setConfirmMarketConsent(isChecked);
          isChecked && setShowMarketConsentError(false);
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="sms_consent" className={`${prefixCls}__marketing-consent-tips`}>
        I agree with&nbsp;
        <AutoLink
          target="_blank"
          to="https://app.kiwidrop.com/privacy-policy"
          className={`${prefixCls}__marketing-consent-tips-link`}
        >
          Privacy Policy
        </AutoLink>
        &nbsp;and&nbsp;
        <AutoLink
          target="_blank"
          to="https://app.kiwidrop.com/terms-of-service"
          className={`${prefixCls}__marketing-consent-tips-link`}
        >
          Terms of Service
        </AutoLink>
        &nbsp;to opt-in to receive one-time verification codes and recurring account/order notifications from Kiwidrop.
        Text STOP to opt-out, text HELP for help. Message & data rates may apply.
      </label>
    </div>
  );
}
