$prefixCls: orders-checkout-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;
  $actionsBarHeight: 250px;

  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: #{$actionsBarHeight};

        // safe area bottom patch
        --app-layout-padding-bottom: calc(
          #{$actionsBarHeight} - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l))
        );

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__product-banner {
    background-color: var(--adm-color-primary);
    color: #000;
    padding: 12px;
    margin-top: -16px;
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
  }

  &__title_wrapper {
    margin-bottom: var(--spacing-xxl);
  }

  a {
    &:not(:hover) {
      color: var(--color-primary-medium);
    }

    .react-icons {
      margin-right: var(--spacing-s);
    }
  }

  .ant-input {
    border-color: #c7c7c7;
    border-radius: 4px;
  }

  &__section {
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
    background: var(--color-white);
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

    * + & {
      margin-top: var(--spacing-l);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-m) var(--spacing-l);
      border-bottom: 1px solid #f5f5f5;
    }

    &-title {
      font-size: var(--section-header-font-size);
      line-height: 1.2;
      font-weight: 500;
      &.notify_customer {
        display: flex;
        align-items: center;
      }
      &-text {
        margin-left: 6px;
      }
      &-extra {
        margin-top: var(--spacing-xs);
        color: var(--color-gray4);
        font-size: var(--font-size12);
        line-height: 1.3;
      }
    }

    &-body {
      padding: var(--spacing-m) var(--spacing-l);
      font-size: var(--section-text-font-size);

      &.#{$prefixCls}__shipping-method-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    &-footer {
      padding: var(--spacing-m) var(--spacing-l);
      background: #f5fce5;
      border-radius: 0 0 8px 8px;
    }

    &.original_address {
      padding: 8px 12px;
    }
    &-header-original_address {
      color: #999;
    }
    &-body-original_address {
      color: #000;
      margin-top: 4px;
    }
  }

  &__order-products-section {
    margin-top: var(--spacing-l);
  }

  &__shipping-info {
    &-shipping_rules-text {
      color: #999;
      padding-bottom: 4px;
    }
    &-text {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  &__shipping-method {
    display: grid;
    grid-auto-flow: row;
    gap: var(--spacing-l);

    &-item {
      padding: var(--spacing-l);
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      cursor: pointer;

      &--active {
        border-color: var(--color-black);
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--spacing-m);
        color: var(--color-black);
        font-size: var(--font-size14);
        font-weight: 500;
        line-height: 1.3;
      }

      &-title {
        display: flex;
        align-items: center;
        &-tag {
          background: #cee46a;
          border-radius: 2px;
          margin-left: 4px;
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 600;
          line-height: 1;
        }
      }

      &-body {
        color: var(--color-gray4);
        font-size: var(--font-size12);
        line-height: 1.3;
        white-space: pre;
      }
    }

    &-error {
      padding-top: 12px;
      padding-bottom: 4px;
      color: #ff0000;
    }
  }

  &__input-item {
    padding: var(--spacing-xs) 0 var(--spacing-l);
    margin-bottom: var(--spacing-l);
    border-bottom: 1px solid #f5f5f5;

    &-input-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        margin-left: var(--spacing-m);
      }
    }

    &-message {
      font-size: var(--font-size2);
      line-height: 1.3;
      margin-top: var(--spacing-l);
    }
  }

  &__discount-code-input input:not(:placeholder-shown) {
    text-transform: uppercase;
  }

  &__info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;

    & + & {
      margin-top: var(--spacing-m);
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-white);
    box-shadow: 0 2px 10px #0000000f;

    &-title {
      display: none;
      padding: var(--spacing-m);
      // margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-l);
      color: var(--color-white);
      font-size: 20px;
      line-height: 1.2;
      font-weight: 500;
      background: var(--color-gray5);
      border-radius: 8px 8px 0 0;
    }
  }

  &__info-item-wrapper {
    padding: 12px;
  }

  &__marketing-consent {
    padding: 0 12px 12px 12px;
  }

  &__marketing-consent-tips {
    font-size: 80%;
    line-height: 1.5;
  }

  &__marketing-consent-checkbox {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  &__marketing-consent-checkbox span {
    padding-left: 6px;
  }

  &__place-order-button {
    padding: 0 12px;
  }

  &__place-order-button-content {
    height: 56px;
  }

  &__preorder-shipping-wrapper {
    &-desc {
      color: var(--color-gray4);
      font-size: var(--font-size12);
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 0 0 var(--spacing-m);
    }

    &__section {
      border-radius: 0;
      box-shadow: none;

      * + & {
        margin-top: var(--spacing-m);
      }
    }

    &__order-products-section {
      margin-top: var(--spacing-m);
      border-radius: 0;
      box-shadow: none;
    }

    &__actions-wrapper {
      background-color: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 100;
      // height: $actionsBarHeight;
      // safe area bottom patch
      // height: var(--app-layout-padding-bottom);
      padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));
    }

    &__actions {
      box-shadow: none;
    }

    &__sold_out-error-section {
      border-radius: 0;
      margin: 0;
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      max-width: calc(784px + var(--spacing-l) * 2);
    }

    &__layout {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__main {
      width: 510px;
    }

    &__actions-wrapper {
      z-index: 1;
      position: sticky;
      top: calc(var(--app-layout-padding-top) + var(--spacing-l));
      left: auto;
      flex: 1;
      height: auto;
      margin-left: var(--spacing-l);
    }

    &__actions {
      flex: 1;
      border-radius: 8px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

      &-title {
        display: block;
      }
    }

    &__place-order-button {
      padding: 0 var(--spacing-m) var(--spacing-m);
    }

    &__product-banner {
      margin: 0;
      margin-bottom: 12px;
      border-radius: 8px;
    }
  }
}
