.ns_com_cart_page_switch_edit_button {
  cursor: pointer;
  line-height: 1;
  .center_wrapper {
    display: flex;
    align-items: center;
    .icon {
      height: 14px;
    }
  }
  .text {
    font-size: 16px;
    color: #000;
  }
}
