import _ from 'lodash';
import { useMemo } from 'react';

import { GET_SELLER_COLLECTION_PARAMS } from '@/constants/biz';
import { SellerCollection } from '@/types';

import useSellerCollections from '../queries/useSellerCollections';

type Props = {
  debouncedKeyword?: string;
};

const useSellerCollectionData = (
  props?: Props
): {
  data: SellerCollection[];
  sellerCollections: any;
} => {
  const { debouncedKeyword } = props || {};
  const sellerCollections = useSellerCollections(GET_SELLER_COLLECTION_PARAMS);

  return {
    sellerCollections,
    data: useMemo(
      () =>
        _.filter(
          _.partition(_.compact(sellerCollections?.data?.pages?.flatMap((page) => page?.data?.seller_collections)), {
            is_default: 1,
          }).flatMap((d) => d),
          (item) => {
            const q = _.toLower(_.trim(debouncedKeyword));
            if (_.isEmpty(q)) return true;
            return _.includes(_.toLower(item?.title), q);
          }
        ),
      [sellerCollections?.data?.pages, debouncedKeyword]
    ),
  };
};

export default useSellerCollectionData;
