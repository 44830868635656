import { AlphaStack, Box, Divider, IndexTable, Inline, Text } from '@shopify/polaris';
import { Link } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import { useDesktop } from '@/hooks';
import { CollectionProduct } from '@/types';

import css from './ListItem.module.scss';

type Props = {
  data?: {
    balance?: number;
    created_at?: string;
    customer_id?: number;
    id?: number;
    product_id?: number;
    product_variant_id?: number;
    total_added?: number;
    total_subtracted?: number;
    updated_at?: string;
    product?: CollectionProduct;
  };
  index?: number;
};

const ListItem = ({ data, index }: Props) => {
  const isDesktop = useDesktop();
  const { id, product, product_id, product_variant_id, balance } = data;

  const pdpURL = `/private-inv/${product_variant_id}`;

  if (!product?.product) return null;

  const { title, cover, variants } = product.product;
  const curVariant = variants.find((v) => product_variant_id === v.id);

  if (!curVariant?.id) return null;

  const { sku, title: skuTitle } = curVariant;
  const detailURL = `/products/${product_id}?variant=${product_variant_id}`;

  if (isDesktop) {
    return (
      <IndexTable.Row id={`${id}`} key={id} position={index}>
        <IndexTable.Cell>
          <img className={css.ns_com_private_inv_list_item_img} alt="" src={cover} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <AlphaStack>
            <Link to={detailURL} className={css.ns_com_private_inv_list_item_title}>
              {title}
            </Link>
            <Text variant="bodySm" as="p" color="subdued">
              {skuTitle}
            </Text>
          </AlphaStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="p" color="subdued">
            {sku}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end">
            {balance}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <AutoLink to={pdpURL}>
            <Text as="span" alignment="center">
              History &raquo;
            </Text>
          </AutoLink>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  }

  return (
    <div>
      <Box padding="4">
        <AlphaStack gap="1">
          <Inline align="start" gap="2">
            <div style={{ flex: 1 }}>
              <Link to={detailURL} className={css.ns_com_private_inv_list_item_title}>
                {title}
              </Link>
            </div>
          </Inline>
          <Inline align="start" gap="2">
            <img className={css.ns_com_private_inv_list_item_img} alt="" src={cover} />
            <div style={{ flex: 1 }}>
              <Inline align="space-between" blockAlign="center">
                <Text variant="bodySm" as="p" color="subdued">
                  {skuTitle}
                </Text>
                <Text as="span" alignment="end">
                  {balance}
                </Text>
              </Inline>
              <Inline align="space-between" blockAlign="center">
                <Text variant="bodySm" as="p" color="subdued">
                  {sku}
                </Text>
                <AutoLink to={pdpURL}>History &raquo;</AutoLink>
              </Inline>
            </div>
          </Inline>
        </AlphaStack>
      </Box>
      <Divider borderStyle="base" />
    </div>
  );
};

export default ListItem;
