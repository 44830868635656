.ns_com_collection_header_main {
  .ns_com_hot_flash_main {
    .hot_flash_title {
      text-align: center;
      height: 310px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffcc17 no-repeat center;
      background-size: cover;
      h1 {
        font-weight: 500;
        font-size: 52px;
        line-height: 130%;
        color: #000000;
        margin: 0;
        padding: 0;
      }
    }
    .hot_flash_footer {
      background-color: #000;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .text {
        color: #fff;
        margin-right: 16px;
      }
    }
    .hot_flash_title_wrapper {
      margin-top: 16px;
      h1,
      p {
        padding: 0;
        margin: 0;
      }
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #000000;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #424242;
      }
      .desc {
        margin-top: 16px;
      }
    }
  }

  @at-root .is_m {
    .ns_com_collection_header_main {
      .header_cover {
        margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-xxl);
        .image {
          width: 100%;
          height: 120px;
        }
      }

      .ns_com_hot_flash_main {
        .hot_flash_title {
          margin: -12px;
          margin-bottom: 0;
          height: 115px;
          h1 {
            font-size: 22px;
          }
        }
        .hot_flash_footer {
          justify-content: space-between;
          padding: 12px;
          margin: 0 -12px;
        }
      }

      &.preorder {
        .bg_img_wrapper {
          max-height: 200px;
        }
      }
    }
  }

  @at-root .is_pc {
    .ns_com_collection_header_main {
      .header_cover {
        margin: 0 auto 16px;
        .image {
          width: 100%;
          height: 298px;
        }
      }

      &.preorder {
        .bg_img_wrapper {
          max-height: 340px;
        }
      }
    }
  }
}
