import { IndexTable } from '@shopify/polaris';

import { useDesktop } from '@/hooks';

import ListItem from './ListItem';

type Props = {
  data?: any[];
  loading?: boolean;
};

const ListView = ({ data, loading }: Props) => {
  const isDesktop = useDesktop();
  const resourceName = {
    singular: 'record',
    plural: 'records',
  };

  const rowMarkup = data.map((d, index) => <ListItem data={d} index={index} />);

  return (
    <IndexTable
      condensed={!isDesktop}
      loading={loading}
      resourceName={resourceName}
      itemCount={data.length}
      headings={[{ title: 'Date' }, { title: 'Activity' }, { title: 'Quantity', alignment: 'end' }]}
      selectable={false}
    >
      {rowMarkup}
    </IndexTable>
  );
};

export default ListView;
