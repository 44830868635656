import { FiPlus, FiX } from 'react-icons/fi';

import { PRICE_RULE_TYPE } from '../constant';
import type PriceRuleType from '../types';

const CHARACTERS = {
  [PRICE_RULE_TYPE.add]: <FiPlus strokeWidth="1.5" />,
  [PRICE_RULE_TYPE.multiple]: <FiX strokeWidth="1.5" />,
  [PRICE_RULE_TYPE.margin]: <div />,
};

const PriceRuleCharacter = ({ type }: { type: PriceRuleType }) => CHARACTERS[type] || null;

export default PriceRuleCharacter;
