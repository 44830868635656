import { message } from 'antd';
import { memo, useCallback } from 'react';

import useRequestProductRestock from '@/hooks/queries/useRequestProductRestock';

const RequestRestockButton = memo(
  ({
    productId,
    children,
  }: {
    productId: string;
    children: (params: { onRequestRestock: () => void }) => React.ReactNode | Element;
  }) => {
    const requestProductRestock = useRequestProductRestock();

    const handleRequestRestock = useCallback(() => {
      requestProductRestock.mutate(productId, {
        onSettled: (data, error) => {
          if (error) {
            message.error({ key: 'requestRestock', content: (error as Error)?.message || 'Request Restock Failure!' });
          } else {
            message.success({ key: 'requestRestock', content: 'Request Restock Sent!' });
          }
        },
      });
    }, [productId, requestProductRestock]);

    return <div>{children({ onRequestRestock: handleRequestRestock })}</div>;
  }
);

export default RequestRestockButton;
