.cost_panel_comp {
  .cost_panel_comp_content {
    background-color: #fff;
    .red {
      color: var(--color-danger);
    }
    .cost_panel_comp_content_title {
      padding-right: 4px;
    }
    .block_one_desc {
      display: flex;
      justify-content: space-between;
      font-size: var(--section-text-font-size);
      line-height: 1.618;
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .pre_order_shipping {
        text-indent: 2em;
        color: var(--p-text-subdued);
      }
      &.kiwi_wallet {
        color: var(--color-primary-medium);
      }
    }
  }
}
.cost_panel_shipping_saving_tooltip {
  color: #000;
}
