@PREFIX: img-crop;

.@{PREFIX}-modal {
  .@{PREFIX}-container {
    position: relative;
    width: 100%;
    height: 40vh;
  }

  .@{PREFIX}-control {
    display: flex;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      margin-top: 16px;
    }

    &:last-of-type {
      margin-bottom: -8px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      &[disabled] {
        cursor: default;
      }
    }

    button + div:only-of-type {
      flex: 1;
      margin: 0 12px;
    }

    .ant-slider-handle::after {
      content: '';
      position: absolute;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
    }
  }

  .@{PREFIX}-control-zoom button {
    font-size: 24px;
  }

  .@{PREFIX}-control-rotate button {
    font-size: 22px;

    &:first-of-type {
      transform: rotate(-20deg);
    }

    &:last-of-type {
      transform: rotate(20deg);
    }
  }
}
