.order_status_tag_wrapper {
  display: flex;
  align-items: center;
  font-size: var(--section-text-font-size);
  .dot {
    padding: 0;
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: #d6d6d6;
    &.danger {
      background-color: rgba(255, 73, 73, 0.2);
      position: relative;
      width: 10px;
      height: 10px;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #ff4949;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.success {
      background-color: #a6cf03;
    }
  }
  .order_status_desc {
    font-size: var(--font-size3);
    font-weight: 500;
  }
}
