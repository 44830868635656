.ns_com_imported_product_list {
  display: grid;
  grid-template-columns: 100%;

  .item {
    $item: &;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--spacing-m) 0;
    border-bottom: 1px solid var(--color-gray1);
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .cover {
      margin-right: var(--spacing-m);

      .image {
        width: 66px;
        height: 86px;
        border-radius: 8px;
      }
    }

    .info {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .operate_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .operate_button.button {
          padding: 0 !important;
          color: #000 !important;
          font-size: var(--font-size2);
          line-height: 1.3 !important;
          background: none !important;
          text-decoration: underline;
          margin-right: 12px;
        }
      }
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--color-black);
      font-size: var(--font-size2);
      font-weight: 500;
      line-height: 1.3;
    }

    .prices {
      margin-top: var(--spacing-xs);
      color: var(--color-black);
      font-size: var(--font-size2);
      line-height: 1.3;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .sell {
        margin-left: var(--spacing-l);
      }
    }

    .tag_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: var(--color-gray4);
      font-size: var(--font-size2);
      line-height: 1.3;
      .label {
        padding: var(--spacing-xxs) var(--spacing-s);
        color: #000;
        background: #e8e8e8;
        border-radius: 2px;
      }
    }
    &.unavailable {
      .cover,
      .title,
      .prices {
        opacity: 0.5;
      }
    }
  }

  @media screen and (max-width: 391px) {
    .operate_wrapper .operate_button.button,
    .tag_wrapper .label {
      font-size: 12px !important;
    }
  }

  @at-root .delete_confirm_dialog_content {
    text-align: center;
    display: inline-block;
  }

  @at-root .tooltip {
    width: auto;
    max-width: 100%;

    .content {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
    }

    .title {
      font-weight: 500;
      margin-bottom: var(--spacing-m);
    }
    :global {
      .ant-tooltip-inner {
        padding: var(--spacing-m);
      }
    }

    @media screen and (max-width: 768px) {
      padding: var(--spacing-l);
      :global {
        .ant-tooltip-arrow {
          display: none;
        }
      }
    }
    @media screen and (min-width: 769px) {
      max-width: 360px;
    }
  }
}
