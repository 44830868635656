.ns_com_store_select_main {
  .select {
    width: 100%;
    background: var(--color-white);
    font-size: var(--font-size2);

    :global {
      .ant-select-selector {
        .ant-select-selection-item {
          font-weight: 500;
        }

        .ant-select-selection-overflow-item .ant-select-selection-item {
          background: #ebf9cf;
          border-color: transparent;
          border-radius: 4px;

          &-remove {
            font-size: 16px;
          }
        }
      }

      .ant-select-arrow {
        color: var(--color-black);
      }
    }

    .select_dropdown {
      background-color: var(--color-gray1);

      :global {
        .ant-select-item-option {
          align-items: center;
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background-color: var(--ant-primary-1);
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled, .ant-select-item-option-active) {
          background-color: transparent;
        }

        .ant-select-item-option-state {
          font-size: 20px;
          line-height: 0;
          margin-left: var(--spacing-m);
        }
      }
    }
  }
}
