.ns_com_payment_methods_dialog_list_item_main {
  cursor: pointer;

  .list_item_drag_btn_wrapper {
    cursor: move;
  }

  .test_button {
    :global {
      .Polaris-Button--plain svg {
        fill: var(--p-text);
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
    box-shadow: none;
  }
}
