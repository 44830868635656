import { useMemo } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AutoLink from '@/components/AutoLink';
import Image from '@/components/Image';
import { useDesktop } from '@/hooks';
import useSettings from '@/hooks/queries/useSettings';

import css from './Banner.module.scss';

const Banner = () => {
  const isDesktop = useDesktop();
  const settings = useSettings();
  const data = useMemo(
    () => settings?.data?.data?.['home-carousel']?.data?.[isDesktop ? 'carousel_pc' : 'carousel_mobile'],
    [isDesktop, settings?.data?.data]
  );

  if (!data) return null;

  return (
    <div className={css.ns_com_banner_swiper_main}>
      <Swiper
        className={css.swiper_wrapper}
        loop
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        navigation
        pagination={{
          el: css.swiper_pagination_wrapper,
          clickable: true,
          renderBullet: (index, cls) => `<span class="${cls}"></span>`,
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        {data?.map((item: any) => (
          <SwiperSlide key={item?.image_url}>
            <AutoLink targetType={item?.target_type} targetData={item?.target_data}>
              <Image className={css.swiper_image} src={item?.image_url} fit="cover" />
            </AutoLink>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={css.swiper_pagination_wrapper} />
    </div>
  );
};

export default Banner;
