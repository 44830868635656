import { useMemo } from 'react';

import useSettings from '../queries/useSettings';

type HotFlashSetting = {
  title?: string;
  desc?: string;
  link_all?: {
    link?: string;
    text?: string;
  };
  colors?: any;
  tabs?: {
    tag?: string;
    title?: string;
    handle?: string;
    ends_at?: string;
    begins_at?: string;
  }[];
};

const useHotFlashSettings = (): HotFlashSetting => {
  const settings = useSettings();

  return useMemo(() => {
    // hotflash始终要展示5个模块，不足5个需要补全
    const tabs = settings?.data?.data?.hotflash?.data?.tabs;

    if (!tabs?.length) return {};

    // 关闭：前端不补全
    // const len = 5 - tabs.length;
    // const addedPlaceholder = _.fill(new Array(len), {});
    // const newTabs = [...tabs, ...addedPlaceholder];

    return {
      ...(settings?.data?.data?.hotflash?.data || {}),
      tabs,
    };
  }, [settings?.data?.data?.hotflash?.data]);
};

export default useHotFlashSettings;
