import { Form, FormInstance, InputNumber } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Image from '@/components/Image';
import { getDefaultSellPrice } from '@/pages/PriceRules/price';

import css from './index.module.scss';

export interface ImportProductListProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any[];
  form: FormInstance<any>;
  priceRules?: any;
}

export interface ImportProductListItemProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any;
  form: FormInstance<any>;
  itemIndex: number;
  priceRules?: any;
}

export function ImportProductList({ className, style, data, form, priceRules }: ImportProductListProps): JSX.Element {
  return (
    <div className={cn(css.ns_com_import_product_list_main, className)} style={style}>
      {data?.map((item: any, index: number) => (
        <ImportProductListItem
          key={item?.product?.id}
          data={item}
          itemIndex={index}
          priceRules={priceRules}
          form={form}
        />
      ))}
    </div>
  );
}

export function ImportProductListItem({
  className,
  style,
  data,
  form,
  itemIndex,
  priceRules,
}: ImportProductListItemProps): JSX.Element {
  const navigate = useNavigate();
  const priceGroupedVariants = _.groupBy(data?.product?.variants, 'price');
  const isSinglePrice = _.size(priceGroupedVariants) <= 1;
  const formFieldBasePath = ['products', itemIndex];

  useEffect(() => {
    // update sell price when price rules change
    const values = _.cloneDeep(form.getFieldsValue(true));
    if (isSinglePrice) {
      const cost = Number(data?.product?.variants?.[0]?.price);
      _.set(values, [...formFieldBasePath, 'price'], getDefaultSellPrice(cost, priceRules));
      form.setFieldsValue(values);
    } else {
      _.forEach(_.values(priceGroupedVariants), (variants: any[], index: number) => {
        const cost = Number(variants?.[0]?.price);
        const pricePath = [...formFieldBasePath, 'variants', index, 'price'];
        _.set(values, pricePath, getDefaultSellPrice(cost, priceRules));
      });
      form.setFieldsValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRules]);

  const navigateToProductDetail = () => {
    navigate(`/products/${data?.product?.id}`);
  };

  return (
    <div className={cn(css.product_item, className)} style={style}>
      <div className={css.cover} onClick={navigateToProductDetail}>
        <Image className={css.image} src={data?.product?.cover} fit="cover" lazy />
      </div>
      <div className={css.info}>
        <div className={css.info_title} onClick={navigateToProductDetail}>
          {data?.product?.title}
        </div>
        <div className={css.variants_prices}>
          <Form.Item name={[...formFieldBasePath, 'id']} noStyle hidden initialValue={Number(data?.product?.id)}>
            <input type="hidden" />
          </Form.Item>
          {_.map(_.values(priceGroupedVariants), (variants: any[], index: number) => {
            const cost = Number(variants?.[0]?.price);
            const groupBasePath = ['variants', index];
            const pricePath = isSinglePrice
              ? [...formFieldBasePath, 'price']
              : [...formFieldBasePath, ...groupBasePath, 'price'];

            return (
              <div key={cost} className={css.group}>
                <div className={css.group_title}>
                  123{!isSinglePrice ? getVariantsPricesGroupTitle(variants) : null}
                </div>
                <div className={css.price_columns}>
                  <div className={css.price_columns_item}>
                    <div className={css.cost_wrapper}>
                      <div className={css.price_columns_item_title}>Product cost</div>
                      <div className={css.price}>${cost.toFixed(2)}</div>
                    </div>
                    <div className={css.earn_wrapper}>
                      <div className={css.price_columns_item_title}>Earn</div>
                      <div className={css.price}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            _.get(prevValues, pricePath) !== _.get(currentValues, pricePath)
                          }
                        >
                          {({ getFieldValue }) => {
                            const price = getFieldValue(pricePath) ?? getDefaultSellPrice(cost, priceRules);
                            return `$${(price - cost).toFixed(2)}`;
                          }}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className={css.price_columns_item}>
                    <div className={css.input_wrapper}>
                      <div className={css.price_columns_item_title}>Sell at</div>
                      <div className={css.price_columns_item_price}>
                        <Form.Item name={pricePath} noStyle initialValue={getDefaultSellPrice(cost, priceRules)}>
                          <InputNumber<number>
                            controls={false}
                            min={0}
                            precision={2}
                            formatter={(value, { userTyping }) => `$${userTyping ? value : Number(value).toFixed(2)}`}
                            parser={(value) => Number(value.replace(/\$/g, ''))}
                          />
                        </Form.Item>
                        {!isSinglePrice && (
                          <Form.Item
                            name={[...formFieldBasePath, ...groupBasePath, 'variant_ids']}
                            noStyle
                            hidden
                            initialValue={_.map(variants, (v) => v.id)}
                          >
                            <input type="hidden" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className={css.input_wrapper}>
                      <div className={css.price_columns_item_title}>Margin</div>
                      <div className={css.price_columns_item_price}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            _.get(prevValues, pricePath) !== _.get(currentValues, pricePath)
                          }
                        >
                          {() => {
                            const price = form.getFieldValue(pricePath) ?? getDefaultSellPrice(cost, priceRules);
                            return (
                              <InputNumber<number>
                                controls={false}
                                min={0}
                                max={99}
                                precision={0}
                                formatter={(value) => `${value}%`}
                                parser={(value) => Number(value.replace(/%/g, ''))}
                                value={Number(_.clamp(Number(((price - cost) / price) * 100), 0, 99).toFixed(0))}
                                onChange={(value) => {
                                  const values = _.cloneDeep(form.getFieldsValue(true));
                                  _.set(values, pricePath, Number((cost / (1 - value / 100)).toFixed(2)));
                                  form.setFieldsValue(values);
                                }}
                              />
                            );
                          }}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getVariantsPricesGroupTitle(variants: any[]) {
  const firstVariant = variants?.[0];
  const title = _.compact([firstVariant?.option1, firstVariant?.option2, firstVariant?.option3]).join(' / ');

  return variants?.length > 1 ? `${variants?.length} Variants` : title;
}

ImportProductList.Item = ImportProductListItem;
export default ImportProductList;
