$prefixCls: product-detail-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;
  $actionsBarHeight: 55px;

  &-active {
    .app-layout {
      background: var(--color-white);

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: #{$actionsBarHeight};

        // safe area bottom patch
        --app-layout-padding-bottom: calc(
          #{$actionsBarHeight} - var(--spacing-m) + max(env(safe-area-inset-bottom), var(--spacing-m))
        );

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    padding: 0 var(--spacing-l) var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-max-width);
  }

  &__layout {
    &-space-between {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__product {
    &-banner {
      background-color: var(--adm-color-primary);
      color: #000;
      margin: 0 -12px;
      padding: 12px;
    }

    &-gallery-section {
      position: relative;
      margin: 0 calc(-1 * var(--spacing-l));
      overflow: hidden;
    }

    &-info-section {
      margin-top: var(--spacing-l);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 400;
    }

    &-label_wrapper {
      display: flex;
      row-gap: var(--spacing-m);
      flex-direction: column;
      margin-bottom: var(--spacing-m);
    }

    &-tags {
      margin-bottom: var(--spacing-m);

      &:empty {
        display: none;
      }
    }

    &-title {
      margin-bottom: var(--spacing-m);
      font-size: var(--font-size2);
      line-height: 1.3;
      font-weight: 500;
      word-break: break-word;
    }

    &-compare-price {
      display: flex;
      align-items: center;
      margin-left: var(--spacing-m);
      color: var(--color-gray3);
      font-weight: 500;

      &-amount {
        text-decoration: line-through;
      }

      &-sale-rate {
        display: inline-block;
        margin-left: var(--spacing-s);
        padding: 2px 3px;
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1;
        font-weight: normal;
        background: #e3f4b1;
        border-radius: 2px;
      }
    }

    &-total-cost {
      font-size: var(--font-size2);
      line-height: 1.3;

      .react-icons {
        margin-left: var(--spacing-xs);
        color: #c7c7c7;
      }
    }

    &-price {
      display: flex;
      align-items: center;
      margin: 0;
      color: var(--color-black);
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;

      &-group {
        padding-left: var(--spacing-m);
        border-left: 1px solid #c7c7c7;
      }

      #{$root}--price-locked & {
        color: var(--color-gray5);
        font-size: var(--font-size3);
        font-weight: normal;

        .react-icons {
          margin-right: var(--spacing-xs);
          line-height: 0;
        }
      }
    }

    &-secondary-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: var(--color-black);
        user-select: none;
        cursor: pointer;

        &:hover,
        &:active {
          color: var(--color-primary);
        }

        & + & {
          margin-left: var(--spacing-xxl);
        }

        &-icon {
          width: 34px;
          height: 34px;
          border-radius: 100%;
          font-size: 20px;
          line-height: 34px;
          text-align: center;
          background: var(--color-white);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
        }

        &-title {
          font-size: var(--font-size1);
        }
      }
    }

    &-options {
      margin-top: var(--spacing-m);

      &-group {
        & + & {
          margin-top: var(--spacing-xs);
        }

        &-title {
          font-size: var(--font-size2);
        }

        &-options {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: var(--spacing-m);
          gap: var(--spacing-m);
        }
      }

      &-item {
        $item: &;

        &-label {
          padding: var(--spacing-s) var(--spacing-m);
          font-size: var(--font-size2);
          line-height: 1.3;
          word-break: break-all;
          border: 1px solid #ebebeb;
          border-radius: 4px;
          cursor: pointer;

          #{$item}--active & {
            border: 1px solid var(--color-gray4) !important;
          }

          #{$item}--out-of-stock & {
            color: #c7c7c7;
            border-color: #ebebeb;
          }

          #{$root}--out-of-stock & {
            color: #c7c7c7;
            border-color: #ebebeb;
          }
        }
      }
    }
    &-options {
      display: none;
      &.show {
        display: block;
      }
    }

    &-variants {
      display: none;
      &.show {
        display: block;
      }
    }

    &-inventory {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #111111;
      padding: 18px 0;
    }

    &-mode-toggler {
      font-size: 12px;
      line-height: 1.3;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 16px;
    }

    &-actions {
      position: fixed;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      left: 0;
      bottom: 0;
      width: 100%;
      height: $actionsBarHeight;
      z-index: 100;
      padding: var(--spacing-m) var(--spacing-l);
      margin-top: var(--spacing-m);
      background: var(--color-white);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 768px) {
        // safe area bottom patch
        height: var(--app-layout-padding-bottom);
        padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-m));
      }

      & > * {
        flex: 1;
      }

      & > * + * {
        margin-left: var(--spacing-m);
      }

      &-favorite-btn {
        flex: none;
      }
    }

    &-services {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-m) var(--spacing-l);
      margin-top: var(--spacing-xxl);
      font-size: var(--font-size1);
      line-height: 1.4;
      background: #f5f5f5;
      box-sizing: border-box;
      border-radius: 8px;
      line-height: 1.618;

      &-item {
        padding-left: 24px;

        & + & {
          margin-top: var(--spacing-xs);
        }

        a {
          color: currentColor;
          text-decoration: underline;
        }
      }

      &-title {
        padding-left: 24px;
        font-size: 14px;
        margin-bottom: 12px;
        line-height: 130%;
        font-weight: 500;

        background: url(../../assets/images/pdp-services-shipping.svg) no-repeat left top / 16px;
      }
      &-title-shipping {
        background-image: url(../../assets/images/pdp-services-shipping.svg);
      }
      &-title-return {
        background-image: url(../../assets/images/pdp-services-return.svg);
      }
    }

    &-info-tabs {
      --content-padding: 5px 0 0;
      margin-top: var(--spacing-m);

      &-title {
        font-size: var(--font-size3);
        font-weight: 500;
      }

      .adm-tabs-tab {
        padding: 0 0 5px;
        margin: 0;
        color: var(--color-black);
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;

        &-wrapper {
          padding: 0;
        }
      }

      .adm-tabs-tab-line {
        display: none;
      }

      .adm-tabs-header {
        border-bottom-color: #c7c7c7;
      }
    }

    &-boutique {
      margin: var(--spacing-l) auto;
      padding: var(--spacing-m) 0;
      border-radius: 2px;

      &-title {
        margin-bottom: var(--spacing-m);
        padding: 0 0 var(--spacing-s);
        color: #000;
        font-size: var(--font-size14);
        line-height: 1.4;
        font-weight: 500;
        border-bottom: 1px solid #c7c7c7;

        .react-icons {
          margin-left: var(--spacing-xs);
          font-size: 16px;
        }
      }
    }

    &-boutique-body,
    &-description {
      font-size: var(--font-size2);
      line-height: 1.3;
      font-weight: normal;

      p,
      div {
        margin: 0 0 var(--spacing-xs);
      }

      b,
      strong {
        font-weight: 500;
      }

      li {
        margin-left: 1em;
      }
    }

    &-report-image-copyright-wrapper {
      padding-top: 10px;
    }
    &-report-image-copyright {
      width: fit-content;
      padding-left: 24px;
      padding-right: 20px;
    }
  }

  &__booster_section {
    :global {
      .hot_flash_section {
        &.hide_radius {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .booster_section {
        &.hide_radius {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  &-pre-order_wrapper {
    padding: var(--spacing-l);
    background: var(--color-primary);
    border-radius: 8px;
    a {
      color: #000;
      text-decoration: underline;
    }
  }

  &-dropping-soon-notice_wrapper {
    padding: var(--spacing-l);
    background: var(--color-primary);
    line-height: 1.4;
    border-radius: 8px;
    &-title {
      color: var(--color-black);
      font-weight: 500;
      font-size: var(--font-size3);
    }
    &-desc {
      padding-top: 6px;
      font-size: 12px;
    }
  }

  &__tooltip {
    width: auto;
    max-width: 100%;

    &-content {
      color: var(--color-black);
      font-size: var(--font-size2);
      line-height: 1.3;
    }
  }

  &__delay_tag {
    color: var(--color-danger);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    a {
      color: inherit;
      text-decoration: underline;
      padding-left: 4px;
      &:hover {
        color: inherit;
      }
    }
    &_dot {
      padding: 0;
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: var(--color-danger);
    }
  }

  &_underline {
    text-decoration: underline;
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 0 var(--spacing-l) 68px;
    }

    &__product {
      &-title {
        font-size: var(--font-size16);
      }
      &-main {
        display: grid;
        grid-template-columns: 55fr 45fr;
        gap: 60px;
        width: 100%;
        margin-top: 34px;
      }

      &-actions {
        position: static;
        width: auto;
        padding: 0;
        background: transparent;
        box-shadow: none;
      }

      &-gallery-section {
        margin: 0;
      }

      &-info-section {
        margin-top: 0;
      }

      &-banner {
        margin: 0;
        border-radius: 8px;
      }
    }

    &__buy-samples-exceeded-dialog {
      .modal__container {
        max-width: var(--content-small-max-width);
      }
    }

    &__import-dropping-soon-notice-dialog {
      .modal__container {
        max-width: var(--content-small-max-width);
      }
    }

    &__product-main {
      &-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &-pre-order_wrapper {
      border-radius: 0;
      margin: 0 -12px 12px;
    }

    &-dropping-soon-notice_wrapper {
      border-radius: 0;
      margin: 0 -12px 12px;
    }

    &__product-main {
      &-content-wrapper {
        margin: 0 -12px;
      }
    }

    :global {
      .swiper-slide .adm-image .adm-image-img {
        background-color: #fff !important;
        object-fit: scale-down !important;
      }
    }
  }
}
