.search_autocomplete_wrapper {
  position: relative;

  // for pc
  .search_input_button {
    height: 40px;
    border: 1px solid #c7c7c7;
    padding: 12px;
    border-radius: 12px;
    font-size: 16px;
    line-height: 16px;
    cursor: text;
    position: relative;

    .search_icon {
      padding-right: 5px;
      font-size: 16px;
    }
    .keyword {
      color: #000;
    }
    .placeholder {
      color: #bfbfbf;
    }
    .scan_button_wrapper {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 12px;
      cursor: pointer;

      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #bfbfbf;
        margin-right: 8px;
      }
    }
  }

  // for m
  .search_button {
    font-size: var(--icon-size);
    color: var(--color-black);
    cursor: pointer;
    line-height: 0;
    position: relative;
  }
}
