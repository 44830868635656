$prefixCls: orders-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      .setting_btn_group {
        display: flex;
        justify-content: space-between;
        .right {
          padding-right: 12px;
        }
      }

      .process_box_wrapper {
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding: 12px 12px 54px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        border-top: 2px solid #c7c7c7;
        .process_box {
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 var(--spacing-l);
          .selected_ids {
            height: 30px;
            line-height: 30px;
            padding: 0 6px;
            background-color: #e8e8e8;
            border-radius: 4px;
            font-size: 14px;
            color: #000;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              font-size: 20px;
              color: #999999;
            }
            count {
              font-weight: 500;
              padding-left: 8px;
            }
          }
        }
      }

      .order_status_filter_wrapper {
        padding: 12px;
        -webkit-overflow-scrolling: touch;
        display: flex;
        align-items: center;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .empty_content {
        color: #999999;
        font-size: 16px;
        width: 250px;
        display: block;
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
        .process_box {
          position: fixed;
          margin-top: 0;
        }

        .order_status_filter_wrapper {
          overflow-x: auto;
        }
      }

      @media screen and (min-width: 769px) {
        --app-layout-padding-bottom: 50px;

        :global {
          .footer.app-layout__footer {
            display: none;
          }
        }
        .setting_btn_group {
          .right {
            padding-right: 0;
          }
        }
        .order_status_filter_wrapper {
          overflow: inherit;
          padding-left: 0;
          padding-right: 0;
        }
        .empty_content {
          width: auto;
        }
        .process_box_wrapper {
          padding: 12px 0 18px;
          .process_box {
            width: var(--content-small-max-width);
          }
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__filters {
    $filters: &;
    display: flex;
    min-width: 0;
    justify-content: space-between;
    align-items: center;

    &-bar {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
      gap: var(--spacing-l);
      padding: var(--spacing-l);
      padding-bottom: 0;
      color: var(--color-black);
      font-size: var(--font-size2);
      line-height: 1.3;
    }

    &-group {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: var(--spacing-m);

      &-scrollable {
        margin-right: calc(-1 * var(--spacing-l));
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }

        &-content {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: max-content;
          gap: var(--spacing-s);
          button {
            padding: 5px 10px;
          }
        }
      }
    }

    // 这是Button组件里面的全局样式，后面重构时记得:global
    .button {
      font-size: var(--font-size2);
      border: none;

      &.default {
        color: var(--color-gray4);
      }

      &.primary {
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        background: linear-gradient(270deg, #f5fce5 0%, rgba(245, 252, 229, 0.5) 115.54%), #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      }
    }
  }

  &__select {
    width: 100%;
    background: var(--color-white);
    font-size: var(--font-size2);

    .ant-select-selector {
      .ant-select-selection-item {
        font-weight: 500;
      }

      .ant-select-selection-overflow-item .ant-select-selection-item {
        background: #ebf9cf;
        border-color: transparent;
        border-radius: 4px;

        &-remove {
          font-size: 16px;
        }
      }
    }

    .ant-select-arrow {
      color: var(--color-black);
    }

    &-dropdown {
      background-color: var(--color-gray1);

      .ant-select-item-option {
        align-items: center;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: var(--ant-primary-1);
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled, .ant-select-item-option-active) {
        background-color: transparent;
      }

      .ant-select-item-option-state {
        font-size: 20px;
        line-height: 0;
        margin-left: var(--spacing-m);
      }
    }
  }

  &__input {
    width: 100%;
    background: var(--color-white);
    font-size: var(--font-size2);

    .ant-input-affix-wrapper {
      border-color: transparent !important;
    }
    .ant-input-clear-icon {
      font-size: var(--font-size3) !important;
    }

    .ant-input {
      font-size: var(--font-size2);
    }

    .ant-input-group-addon {
      display: none;
    }
  }

  &__sort-button {
    height: 100%;
    margin-left: var(--spacing-m);

    .button__icon {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 0;
    }
  }

  .ant-dropdown-menu-item {
    text-align: center;

    &.ant-dropdown-menu-item-selected {
      color: var(--color-black);
      font-weight: 500;
      background-color: transparent;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 0 0 var(--spacing-l);
    }

    &__order-list {
      .order-list__item {
        border-radius: 0;
        filter: none;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      max-width: var(--content-small-max-width);
    }

    &__filters {
      &-bar {
        padding: 0 0 var(--spacing-l);
      }
    }

    &__title {
      display: block;
    }
  }
}
