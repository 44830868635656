import { useEffect, useRef } from 'react';

import useValueRef from './useValueRef';

const useInterval = (callback, delay) => {
  const callbackRef = useValueRef(callback);
  const timerRef = useRef(null);

  const removeTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    if (delay === null) {
      removeTimer();
    } else {
      timerRef.current = setInterval(() => {
        callbackRef.current();
      }, delay);
    }
  }, [callbackRef, delay]);

  return removeTimer;
};

export default useInterval;
