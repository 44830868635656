import { Select } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import useStores from '@/hooks/queries/useStores';
import { GLOBAL_SUPPLIER_FIELD } from '@/utils/global.constant';

import css from './index.module.scss';

export interface OrderProductsCardProps {
  className?: string;
  style?: React.CSSProperties;
  redirectPath?: string;
  currentUser?: string;
}

export function StoreSelect({ className, style, redirectPath, currentUser }: OrderProductsCardProps): JSX.Element {
  const navigate = useNavigate();
  const params = useParams();
  const stores = useStores();
  const isSupplier = currentUser === GLOBAL_SUPPLIER_FIELD;

  const storesData = useMemo(() => {
    if (isSupplier && Array.isArray(stores?.data?.data?.shops)) {
      return stores?.data?.data?.shops.filter((item) => !isEmpty(item?.jennifer));
    }
    return stores?.data?.data?.shops;
  }, [isSupplier, stores?.data?.data?.shops]);

  const shopId = params?.shopId;

  const handleStoreChange = (id: string) => {
    navigate(generatePath(redirectPath, { shopId: id }), { replace: true });
  };

  return (
    <div className={cn(css.ns_com_store_select_main, className)} style={style}>
      <Select
        className={css.select}
        popupClassName={css.select_dropdown}
        size="large"
        loading={stores.isLoading}
        disabled={stores.isLoading}
        bordered={false}
        menuItemSelectedIcon={<IoIosCheckmarkCircle />}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        placeholder={stores.isLoading ? 'Loading Stores...' : 'Select'}
        value={stores.isLoading || _.isEmpty(shopId) ? undefined : shopId}
        onChange={handleStoreChange}
      >
        {storesData?.map((shop: any) => (
          <Select.Option key={shop?.id} value={shop?.id}>
            {shop?.name} ({shop?.shopify_domain})
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default StoreSelect;
