import currency from 'currency.js';
import _ from 'lodash';

import { OrderItem } from '../Orders/types';

const formatAddress = (shippingAddress) => {
  if (!shippingAddress) return '';
  return _.join(
    _.compact([
      _.join([shippingAddress?.first_name, shippingAddress?.last_name], ' '),
      shippingAddress?.address1,
      shippingAddress?.address2,
      _.join(_.compact([shippingAddress?.city, shippingAddress?.province, shippingAddress?.zip]), ', '),
      shippingAddress?.country,
      _.join(_.compact([shippingAddress?.phone_country, shippingAddress?.phone]), ''),
    ]),
    '\n'
  );
};

const generateOrderData = (currentOrder: OrderItem, creditToApply: number): OrderItem | null => {
  if (!currentOrder) return null;
  const result = _.clone(currentOrder);
  result.paid_by_credit = Math.min(creditToApply ?? 0, currency(result.total_price).intValue);
  return result;
};

export { formatAddress, generateOrderData };
