import { message } from 'antd';
import { ReactElement, useContext } from 'react';

import AppContext from '@/appContext';

import CartContext from '../../cartContext';
import { CHECK_PRODUCT_SELECTED } from '../../constant';
import { useDeleteItemsInCart } from '../../hook';

type Props = {
  children: string | ReactElement;
  selectedIds: string[];
  disabled?: boolean;
};

const App = ({ children, selectedIds, disabled = false }: Props) => {
  const { showModalSelectBoard } = useContext(AppContext);
  const { originCartItems } = useContext(CartContext);
  const { updateCartAPI } = useDeleteItemsInCart();

  const toBeRemovedData = {
    items: selectedIds.map((id) => ({
      variant_id: Number(id),
      qty: 0,
    })),
  };

  const onDelete = async () => {
    await updateCartAPI(toBeRemovedData, {
      onSuccess() {},
      onError() {},
    });
  };

  const handleShowModalSelectBoard = () => {
    const productLineItems = originCartItems
      .filter(({ variant_id }) => selectedIds.includes(variant_id))
      .map((d) => ({
        ...d,
        id: d.product_id,
      }));

    showModalSelectBoard({
      products: productLineItems,
      showEdit: false,
      onSuccessCallback: async () => {
        await onDelete();
      },
    });
  };

  const openModal = async () => {
    if (disabled) return;
    if (!selectedIds?.length) {
      message.warning(CHECK_PRODUCT_SELECTED);
      return;
    }
    handleShowModalSelectBoard();
  };
  return <div onClick={openModal}>{children}</div>;
};

export default App;
