.modal_bulk_select_sku_m {
  :global {
    .modal__header {
      .button {
        width: max-content;
        padding: 0;
        font-weight: 400;
      }

      .header_left .button {
        margin-left: -8px;
        .button__text {
          margin-left: 0;
        }
      }
    }

    .modal__footer {
      border-top: none !important;
    }
  }

  .product {
    .product_image {
      display: block;
      width: 150px;
      height: 200px;
      margin: 10px auto var(--spacing-l);
      border-radius: 8px;
    }

    .product_title {
      color: var(--color-black);
      font-size: var(--font-size14);
      line-height: 1.4;
    }

    .product_price {
      margin-top: var(--spacing-xs);
      color: var(--color-black);
      font-size: var(--font-size16);
      line-height: 1.4;
    }

    .product_options {
      .product_options_group {
        margin-top: var(--spacing-l);

        .product_options_group_title {
          font-size: var(--font-size14);
          line-height: 1.3;
          font-weight: 500;
        }

        .product_options_group_items {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;
          margin-top: var(--spacing-xs);
          gap: var(--spacing-m);
        }
      }

      .product_options_item {
        .product_options_item_label {
          min-width: 50px;
          padding: var(--spacing-s) var(--spacing-m);
          font-size: var(--font-size2);
          line-height: 1.3;
          text-align: center;
          word-break: break-all;
          border: 1px solid #ebebeb;
          border-radius: 9999px;
          cursor: pointer;
        }

        &.active .product_options_item_label {
          color: var(--color-white);
          background: var(--color-black);
          border-color: var(--color-black);
        }

        &.out_of_stock .product_options_item_label {
          color: #c7c7c7;
          background: #f8f8f8;
          border-color: transparent;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }

  .selected_variants {
    margin-top: var(--spacing-l);

    .selected_variants_title {
      margin-bottom: var(--spacing-m);
      color: var(--color-black);
      font-size: var(--font-size14);
      font-weight: 500;
      line-height: 1.3;
    }

    .selected_variants_empty {
      color: var(--color-gray4);
      font-size: var(--font-size14);
      font-weight: 500;
      line-height: 1.3;
    }

    .selected_variants_tags {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-m);

      :global {
        .ant-tag {
          margin: 0;
          padding: 6px 10px;
          background: #e8e8e8;
          border-color: transparent;
          border-radius: 6px;

          .ant-tag-close-icon {
            margin-left: 8px;
            font-size: 16px;
            line-height: 1;
            vertical-align: -3px;
          }
        }
      }
    }
  }

  .footer_navigation {
    .footer_navigation_buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      :global {
        .button {
          min-width: 70px;
          border: none;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
        }
      }
    }

    .footer_navigation_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--spacing-l);
      color: var(--color-gray4);
      font-size: var(--font-size12);
      font-weight: 500;
      line-height: 1.3;
    }
  }
}
