import { Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { GrConnect } from 'react-icons/gr';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLatest } from 'react-use';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import SmsMarketingConsent from '@/components/MarketingConsent';
import PhoneInput, { Props as PhoneInputProps } from '@/components/PhoneInput';
import { disableNewRegistration } from '@/constants/app';
import { useAuth, useSendPhoneCode } from '@/hooks/auth';
import { ERROR_CODES } from '@/services/constant';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ViewType = 'phone' | 'code';
type SceneType = 'connectStore' | undefined;

function Login({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  // const currentStore = useCurrentStore();
  const [searchParams] = useSearchParams();
  const isSignUp = !_.isNil(searchParams.get('signup'));
  const forcePhoneCountry = searchParams.get('phone_country');
  const { user, login, isLoggingIn } = useAuth();
  const latestUser = useLatest(user);
  const sendPhoneCode = useSendPhoneCode();
  const [currentView, setCurrentView] = useState<ViewType>('phone');
  const scene = searchParams.get('scene') as SceneType;
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('');
  const [confirmMarketConsent, setConfirmMarketConsent] = useState(false);
  const [showMarketConsentError, setShowMarketConsentError] = useState(false);
  const [formattedPhoneValue, setFormattedPhoneValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const phone = phoneValue?.replace(/\D/g, '');
  const phoneCountryWithSymbol = `+${forcePhoneCountry ?? phoneCountry}`;
  const redirectUrl = searchParams.get('redirect') || '/';
  const shouldDisableContinue =
    (currentView === 'phone' && _.isEmpty(phoneValue.trim())) ||
    (currentView === 'code' && codeValue.trim().length < 6);
  const codeInputRef = useRef(null);

  const handleLoginSuccess = () => {
    sessionStorage.setItem('fromLogined', 'true');

    if (_.isEmpty(latestUser.current?.email)) {
      const prohonForURL = phoneCountry && phoneCountry !== 'null' && phone ? `+${phoneCountry} ${phone}` : phone || '';
      navigate(`/add-email?${prohonForURL ? `phone=${encodeURIComponent(prohonForURL)}&` : ''}redirect=${redirectUrl}`);
      return;
    }

    // if (localStorage.getItem('isFirstLogined') !== 'true') {
    //   localStorage.setItem('isFirstLogined', 'true');
    //   navigate(_.isEmpty(currentStore) ? '/stores' : redirectUrl);
    //   return;
    // }

    _.startsWith(redirectUrl, '/') ? navigate(redirectUrl) : window.location.assign(redirectUrl);
  };

  const handleLogin = async () => {
    try {
      await login({
        phone,
        phone_country: phoneCountryWithSymbol,
        code: codeValue,
        scenario: isSignUp ? 'register' : 'login',
      });
      handleLoginSuccess();
    } catch (error) {
      setErrorMessage((error as Error)?.message || 'Invalid SMS Code');
    }
  };

  const sendCode = (onSuccess?: () => void, onError?: (error?: any) => void) => {
    setCodeValue('');
    setErrorMessage('');
    sendPhoneCode.mutate(
      { phone, phone_country: phoneCountryWithSymbol, scenario: isSignUp ? 'register' : 'login' },
      {
        onSuccess() {
          onSuccess?.();
        },
        onError(error) {
          onError?.(error);
        },
      }
    );
  };

  const handleContinue = () => {
    if (currentView === 'phone') {
      if (!confirmMarketConsent) {
        setShowMarketConsentError(true);
        return;
      }
      sendCode(
        () => setCurrentView('code'),
        (error) => {
          if (disableNewRegistration && error.errno === ERROR_CODES.phoneNotRegistered) {
            navigate(`/landing?phone=${phone}`);
            return;
          }
          if (error.errno === ERROR_CODES.sendingFrequency) {
            setCurrentView('code');
            return;
          }

          setErrorMessage((error as Error)?.message || 'SMS Code failed to send');
        }
      );
    } else if (currentView === 'code') {
      handleLogin();
    }
  };

  const handlePhoneValueChange: PhoneInputProps['onChange'] = ({ dialCode, number, fullNumber }) => {
    setPhoneValue(number);
    setPhoneCountry(dialCode);
    setFormattedPhoneValue(fullNumber);
  };

  const handleCodeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.validity.valid && setCodeValue(e.target.value);
  };

  const handleResetPhone = () => {
    setCurrentView('phone');
    setPhoneValue('');
    setCodeValue('');
    setErrorMessage('');
  };

  const handleResendCode = () => {
    sendCode(null, (error) => {
      if (error.errno === ERROR_CODES.sendingFrequency) {
        setErrorMessage(error.errmsg);
      }
    });
  };

  useEffect(() => {
    if (currentView === 'code') {
      codeInputRef.current?.focus?.();
    }
  }, [currentView]);

  return (
    <div
      className={cn(prefixCls, className, `${prefixCls}--${currentView}-view`, {
        [`${prefixCls}--has-error`]: !!errorMessage,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{isSignUp ? 'Sign up' : 'Login'}</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__form-wrapper`}>
          <div className={`${prefixCls}__header`}>
            {_.isEmpty(scene) && (
              <div className={`${prefixCls}__title`}>{isSignUp ? 'Sign up for KiwiDrop' : 'Log in to KiwiDrop!'}</div>
            )}
            {scene === 'connectStore' && (
              <>
                <div className={`${prefixCls}__header-icon`}>
                  <GrConnect />
                </div>
                <div className={`${prefixCls}__title`}>
                  Sign up to connect your
                  <br />
                  Shopify store
                </div>
                {searchParams.get('shop') && (
                  <div className={`${prefixCls}__subtitle`}>({searchParams.get('shop')})</div>
                )}
              </>
            )}
          </div>
          <div className={`${prefixCls}__message`}>
            {currentView === 'code' ? (
              <>
                Enter the 6-digit code sent to <strong>{formattedPhoneValue}</strong>
                <br />
                Please enter it within 10 minutes
              </>
            ) : (
              'Enter your phone number to continue'
            )}
          </div>
          <div className={`${prefixCls}__input-wrapper`}>
            <PhoneInput
              className={cn(`${prefixCls}__input`, `${prefixCls}__input-phone`)}
              value={phoneValue}
              defaultDialCode={forcePhoneCountry}
              allowDropdown={_.isNil(forcePhoneCountry)}
              onChange={handlePhoneValueChange}
              onPressEnter={handleContinue}
            />
            <Input
              ref={codeInputRef}
              className={cn(`${prefixCls}__input`, `${prefixCls}__input-code`)}
              type="tel"
              maxLength={6}
              pattern="[0-9]*"
              placeholder="000000"
              value={codeValue}
              onChange={handleCodeValueChange}
              onPressEnter={handleContinue}
            />
          </div>
          <div className={`${prefixCls}__error-message`}>{errorMessage}</div>
          {currentView === 'phone' && (
            <SmsMarketingConsent
              confirmMarketConsent={confirmMarketConsent}
              setConfirmMarketConsent={setConfirmMarketConsent}
              showMarketConsentError={showMarketConsentError}
              setShowMarketConsentError={setShowMarketConsentError}
            />
          )}
          <Button
            className={`${prefixCls}__continue-btn`}
            color="main"
            shape="rounded"
            loading={(sendPhoneCode.isLoading && currentView === 'phone') || isLoggingIn}
            loadingText={currentView === 'phone' ? 'Sending' : 'Login'}
            disabled={shouldDisableContinue}
            onClick={handleContinue}
          >
            {isSignUp ? 'Sign up' : 'Log in'}
          </Button>

          <div className={`${prefixCls}__actions`}>
            {currentView === 'code' ? (
              <>
                <Button
                  underline
                  fill="none"
                  loading={sendPhoneCode.isLoading}
                  loadingText="Sending"
                  onClick={handleResendCode}
                >
                  Resend Code
                </Button>
                <Button underline fill="none" onClick={handleResetPhone}>
                  Use Another Phone
                </Button>
              </>
            ) : (
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                fill="none"
                link={isSignUp ? `/login?redirect=${redirectUrl}` : `/login?signup=1&redirect=${redirectUrl}`}
                onClick={() => {
                  setCurrentView('phone');
                  setCodeValue('');
                  setErrorMessage('');
                }}
              >
                {isSignUp ? (
                  <>
                    Already have an account? <span className="text-underline">Log in</span>
                  </>
                ) : (
                  <>
                    Don’t have an account yet? <span className="text-underline">Create an account</span>
                  </>
                )}
              </Button>
            )}
          </div>
        </div>

        {currentView === 'phone' && (
          <div className={`${prefixCls}__footer`}>
            {!isSignUp && (
              <Button
                wrapperClassName={`${prefixCls}__footer-btn`}
                fill="none"
                link={`/email-login?redirect=${redirectUrl}`}
                onClick={() => {
                  setCurrentView('phone');
                  setCodeValue('');
                  setErrorMessage('');
                }}
              >
                Have an email login? <span className="text-underline">Log in with email</span>
              </Button>
            )}

            {/* <div className={`${prefixCls}__footer-description`}>
              By clicking Continue, you agree to our
              <br />
              <AutoLink to="/terms-of-service">Terms of Service</AutoLink> and{' '}
              <AutoLink to="/privacy-policy">Privacy Policy</AutoLink>.
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
