import { Divider, InputNumber, Select } from 'antd';
import cn from 'classnames';

import { OPTIONS, PRICE_RULE_TYPE } from '../constant';
import type PriceRuleType from '../types';
import css from './PriceRuleArgAndOp.module.scss';

interface PriceValue {
  price_arg: number;
  price_op: PriceRuleType;
}
interface Props {
  className?: string;
  value?: PriceValue;
  onChange?: (value: PriceValue) => void;
  disabled?: boolean;
}

const PriceRuleArgAndOp = (props: Props) => {
  const { className, value, onChange, disabled } = props;
  const isMargin = value && value.price_op === PRICE_RULE_TYPE.margin;

  const triggerChange = (changedValue: PriceValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const handlePriceArgChange = (val: number) => {
    triggerChange({ price_arg: val, price_op: value.price_op });
  };

  const handlePriceOpChange = (val: PriceRuleType) => {
    triggerChange({ price_op: val, price_arg: value.price_arg });
  };

  const priceArgPropsInCommon = {
    min: 0,
    disabled,
    controls: false,
    value: value.price_arg,
    onChange: handlePriceArgChange,
    bordered: false,
  };

  const priceArgPropsForMarginType = {
    ...priceArgPropsInCommon,
    max: 99,
    formatter: (val) => `${val}%`,
    precision: 0,
    parser: (val) => Number(val?.replace('%', '')),
  };

  const priceArgPropsForOtherType = {
    ...priceArgPropsInCommon,
    precision: 2,
  };

  const priceArgProps = isMargin ? priceArgPropsForMarginType : priceArgPropsForOtherType;

  return (
    <div className={cn(className, css.ns_com_number_input_with_select_main, { [css.disabled]: disabled })}>
      <InputNumber {...priceArgProps} />
      <Divider type="vertical" />
      <Select
        disabled={disabled}
        value={value?.price_op}
        placeholder="Select Rule"
        options={OPTIONS}
        onChange={handlePriceOpChange}
        bordered={false}
        className={css.ns_or_select_main}
      />
    </div>
  );
};

export default PriceRuleArgAndOp;
