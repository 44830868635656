import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import useStores from '@/hooks/queries/useStores';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function BrandAssetsBefore(): JSX.Element {
  const navigate = useNavigate();
  const stores = useStores();
  const shops = stores?.data?.data?.shops;
  const hasStore = !!shops?.length;

  if (hasStore) {
    const { id } = shops[0];
    navigate(`/brand-assets/${id}`, {
      replace: true,
    });
  } else {
    navigate('/stores', {
      replace: true,
    });
  }

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Brand Assets</title>
      </Helmet>
    </div>
  );
}

export default BrandAssetsBefore;
