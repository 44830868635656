$prefixCls: seller-collection-list-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      background: var(--color-white);

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-max-width);
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__create-new-collection-btn {
    position: fixed;
    width: auto;
    left: var(--spacing-l);
    right: var(--spacing-l);
    bottom: max(env(safe-area-inset-bottom), var(--spacing-l));
    z-index: 10;
  }

  &__collection-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
    grid-auto-rows: max-content;
    gap: var(--spacing-l);

    &-item {
      border-radius: 8px;
      background: var(--color-white);
      cursor: pointer;

      &-images-wrapper {
        position: relative;
        padding-top: 60%;
      }
      &-images {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1px;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        & > :first-child {
          grid-row: 1 / 3;
        }

        .image__fallback {
          .react-icons {
            display: none;
          }
        }
      }

      &-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: var(--spacing-xs);
        color: var(--color-black);
        font-size: 16px;
        line-height: 1.4;
      }

      &-info {
        flex: 1;
        min-width: 0;
      }

      &-title {
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: var(--font-size14);
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding-bottom: calc(var(--spacing-l) * 2 + 40px);
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
    }

    &__title {
      display: block;
    }

    &__create-new-collection-btn {
      --border-radius: 8px !important;
      position: relative;
      width: 100%;
      height: 100%;
      left: auto;
      right: auto;
      bottom: auto;
      z-index: auto;
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08) !important;

      .button__icon {
        position: static !important;
      }
    }

    &__collection-list {
      grid-template-columns: repeat(auto-fill, minmax(226px, 1fr));
      gap: 34px 24px;

      &-item {
        &:first-child {
          all: unset;
        }
      }
    }
  }
}
