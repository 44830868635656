import { Divider, message } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import Checkbox from '@/components/Checkbox';
import { ShareHeartIcon } from '@/components/Icon';
import CartContext from '@/pages/Cart/cartContext';
import { useDeleteItemsInCart } from '@/pages/Cart/hook';

import { CHECK_PRODUCT_SELECTED } from '../../constant';
import SaveForLaterButton from '../SaveForLaterButton';
import SwitchEditButton from '../SwitchEditButton';
import css from './index.module.scss';

const confirmModalTitle = 'Remove Items';
const confirmModalContent = 'Remove selected items from cart?';

const App = () => {
  const { updateCartAPI } = useDeleteItemsInCart();
  const {
    isAllSelected,
    toggleIsAllSelected,
    normalSelectedIds,
    soldoutSelectedIds,
    isInManagementMode,
    isAllSelectedDisabled,
  } = useContext(CartContext);
  const selectedIds = [...normalSelectedIds, ...soldoutSelectedIds];
  const isNoneSelected = !selectedIds.length;
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const toBeRemovedData = {
    items: selectedIds.map((id) => ({
      variant_id: Number(id),
      qty: 0,
    })),
  };

  const validate = () => {
    if (isNoneSelected) {
      message.warning(CHECK_PRODUCT_SELECTED);
      return Promise.reject();
    }
    return Promise.resolve();
  };
  const onDelete = async () => {
    await updateCartAPI(toBeRemovedData, {
      onSuccess() {},
      onError() {},
    });
  };
  const handleRemove = () => {
    validate().then(async () => {
      ModalAntd.alert({
        className: 'ns_com_modal_for_confirm',
        title: confirmModalTitle,
        content: confirmModalContent,
        showCloseButton: true,
        confirmText: 'Confirm',
        onConfirm() {
          onDelete();
        },
      });
    });
  };

  const handleSelectAll = (isChecked) => {
    toggleIsAllSelected(isChecked);
  };

  useEffect(() => {
    const listener = _.debounce(() => {
      const { scrollTop } = document.documentElement;
      setIsPageScrolled(scrollTop > 80);
    }, 300);
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(css.ns_comp_cart_manage_section_actions_pc, {
        [css.is_page_scrolled]: isPageScrolled,
      })}
    >
      <div className={cn(css.left, css.center_wrapper)}>
        <Checkbox
          checked={isAllSelected}
          value={isAllSelected}
          disabled={isAllSelectedDisabled}
          onChange={handleSelectAll}
          shape="square_line"
        />
        <span className={cn(css.text, css.text_select_all, { [css.disabled]: isAllSelectedDisabled })}>All</span>
      </div>
      <div className={cn(css.right, css.center_wrapper)}>
        {isInManagementMode ? (
          <>
            <span className={css.center_wrapper} onClick={handleRemove}>
              <FiTrash2 size={16} strokeWidth={1.5} />
              <span className={css.text}>Remove</span>
            </span>
            <Divider type="vertical" />
            <SaveForLaterButton selectedIds={selectedIds}>
              <span className={css.center_wrapper}>
                <ShareHeartIcon stroke="#000" />
                <span className={css.text}>Save for Later</span>
              </span>
            </SaveForLaterButton>
            <Divider type="vertical" />
          </>
        ) : null}
        <SwitchEditButton />
      </div>
    </div>
  );
};

export default App;
