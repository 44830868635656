import { message, Upload, UploadProps } from 'antd';
import cn from 'classnames';
import COS from 'cos-js-sdk-v5';
import _ from 'lodash';
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

import Button from '@/components/Button';
import Image from '@/components/Image';
import useUploadTokens from '@/hooks/queries/useUploadTokens';

import css from './ImgUploader.module.scss';

type Props = {
  onFileChange: (data: UploadProps['fileList']) => void;
  loading?: boolean;
  uploadButtonAlign?: 'center' | 'start';
};

const uploadTokensParams = {
  type: 'product-request',
};
const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
const MAX_SIZE = 5;
const MAX_COUNT = 10;

const ImgUploader: React.FunctionComponent<Props> = (props) => {
  const { onFileChange, loading, uploadButtonAlign = 'center' } = props;
  const uploadTokens = useUploadTokens(uploadTokensParams);
  const [fileList, setFileList] = useState<UploadProps['fileList']>([]);
  const [isUploading, setIsUploading] = useState(false);
  const uploadTokensData = uploadTokens?.data?.data;

  // COS config
  const cosKey = uploadTokensData?.files?.logo?.key;
  const cosBucket = uploadTokensData?.files?.logo?.bucket;
  const cosRegion = uploadTokensData?.files?.logo?.region;
  const cos = new COS({
    UseAccelerate: true,
    getAuthorization: async (options, callback) => {
      const result = await uploadTokens.refetch();
      const tokens = result?.data?.data?.tokens;
      const credentials = tokens?.credentials;

      if (result.isError) {
        message.error((result.error as Error).message);
        return;
      }

      result?.isSuccess &&
        tokens &&
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: tokens.startTime,
          ExpiredTime: tokens.expiredTime,
        });
    },
  });

  const updateFileList = (newFileList) => {
    setFileList(newFileList);
    onFileChange?.(newFileList);
  };
  const handleRequestUpload: UploadProps['customRequest'] = ({ file, onError, onProgress, onSuccess }) => {
    const filePath = `${cosKey}/${encodeURIComponent((file as any)?.name)}`;
    cos.putObject(
      {
        Bucket: cosBucket,
        Region: cosRegion,
        Key: filePath,
        StorageClass: 'STANDARD',
        Body: file,
        onProgress: ({ percent }) => {
          onProgress?.({ percent });
        },
      },
      (error, data) => {
        if (error) {
          onError?.(new Error(error?.message));
        } else {
          onSuccess?.(data);
        }
      }
    );
  };
  const beforeUpload = (file: any) => {
    try {
      const conditions = {
        'You can only upload jpg/png/jpeg image file!': () => validFileTypes.includes(file?.type),
        [`Image must smaller than ${MAX_SIZE}MB!`]: () => file?.size / 1024 / 1024 < MAX_SIZE,
      };
      const hasError = Object.keys(conditions).some((key) => {
        const callback = conditions[key];
        const isValid = callback();
        if (!isValid) {
          message.warning(key);
        }
        return !isValid;
      });
      return hasError ? Upload.LIST_IGNORE : true;
    } catch (e) {
      return Upload.LIST_IGNORE;
    }
  };
  const handleFileChange = (info: any) => {
    try {
      const newFileList = _.map([...info.fileList], (file) => {
        if (file?.response) {
          // eslint-disable-next-line no-param-reassign
          file.url = `//${file.response?.Location}`;
        }
        return file;
      });
      updateFileList(newFileList);
      setIsUploading(true);
      const isAllSettled = newFileList.every((i) => !!i.response);
      const isAllSuccess = newFileList.every((i) => !!i.url);
      if (isAllSettled) {
        setIsUploading(false);
        if (isAllSuccess) {
          message.success('Image Upload Success!');
        } else {
          message.error('Image Upload Failure!');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const deleteImg = (uid: string) => {
    const newFileList = fileList.filter((i) => i.uid !== uid);
    updateFileList(newFileList);
  };
  const handleToUpload = (e) => {
    const isOverMaxCount = fileList?.filter((i) => !!i?.url)?.length >= MAX_COUNT;
    if (isOverMaxCount) {
      message.warning(`Image maximum count is ${MAX_COUNT}!`);
      e.stopPropagation();
    }
  };

  const uploadButtonAlignStyle = {
    center: css.center,
    start: css.start,
  };

  return (
    <div className={css.ns_com_img_uploader_main}>
      {!_.isEmpty(fileList) && (
        <div className={css.file_list_wrapper}>
          {fileList?.map((file) => (
            <div key={file.uid} className={css.item}>
              <span className={css.del_btn} onClick={() => deleteImg(file.uid)}>
                <FiX strokeWidth="1.5" />
              </span>
              <Image className={css.img} src={file.url} fit="cover" />
            </div>
          ))}
        </div>
      )}
      <Upload
        className={cn(css.upload_btn_wrapper, uploadButtonAlignStyle[uploadButtonAlign])}
        name="file"
        listType="picture"
        accept={validFileTypes.join(', ')}
        fileList={fileList}
        showUploadList={false}
        customRequest={handleRequestUpload}
        beforeUpload={beforeUpload}
        onChange={handleFileChange}
        maxCount={MAX_COUNT}
        multiple
        disabled={isUploading}
      >
        <Button
          color="main"
          shape="rounded"
          loading={isUploading}
          loadingText="Uploading"
          className={css.upload_img_btn}
          onClick={handleToUpload}
          disabled={loading}
        >
          Upload Images
        </Button>
      </Upload>
    </div>
  );
};

export default React.memo(ImgUploader);
