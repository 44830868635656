import { Alert, Spin } from 'antd';
import { CenterPopup, List, Popup, SpinLoading } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FiChevronRight, FiX } from 'react-icons/fi';

import Button from '@/components/Button';
import { useAuth } from '@/hooks/auth';
import useUserCredit from '@/hooks/queries/useUserCredit';
import useDesktop from '@/hooks/useDesktop';
import { formatCredit } from '@/utils/util';

import css from './index.module.scss';

export interface KiwiWalletSelectProps {
  className?: string;
  style?: React.CSSProperties;
  order: any;
  onChange?: (value: number) => void;
  selectable?: boolean;
}

export function KiwiWalletSelect({
  className,
  style,
  order,
  onChange,
  selectable,
}: KiwiWalletSelectProps): JSX.Element {
  const { user } = useAuth();
  const userCredit = useUserCredit(user?.id);
  const userCreditData = userCredit?.data?.data?.credits ?? 0;
  const [currentValue, setCurrentValue] = useState<number>();
  const [popupVisible, setPopupVisible] = useState(false);
  const isDesktop = useDesktop();
  const orderTotalPrice = order?.total_price ?? 0;
  const maxAmount = _.max([_.min([orderTotalPrice * 100, userCreditData]), 0]);

  const handleChange = (value: number) => {
    setCurrentValue(value);
    setPopupVisible(false);
    onChange?.(value);
  };

  useEffect(() => {
    popupVisible && userCredit.refetch({ cancelRefetch: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupVisible]);

  useEffect(() => {
    setCurrentValue(maxAmount);
    onChange?.(maxAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCreditData]);

  useEffect(() => {
    // auto select kiwi wallet when initial
    currentValue > 0 && maxAmount > 0 && handleChange(maxAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxAmount]);

  const SelectPopup = isDesktop ? CenterPopup : Popup;

  const kiwiWalletSection = useMemo(() => {
    if (selectable) {
      return (
        <>
          <div className={css.label}>Kiwi Wallet</div>
          <div className={css.select} onClick={() => setPopupVisible(true)}>
            {!_.isUndefined(order?.paid_by_credit) ? (
              `${order?.paid_by_credit > 0 ? '-' : ''}$${formatCredit(order?.paid_by_credit)}`
            ) : (
              <span className={css.select_placeholder}>Select Amount</span>
            )}{' '}
            <FiChevronRight />
          </div>
        </>
      );
    }

    if (order?.paid_by_credit > 0) {
      return (
        <>
          <div className={css.label}>Kiwi Wallet</div>
          <div>-${formatCredit(order?.paid_by_credit)}</div>
        </>
      );
    }

    return null;
  }, [order?.paid_by_credit, selectable]);

  return (
    <div className={cn(css.ns_com_kiwi_wallet_select_main, className)} style={style}>
      <div className={css.inner_wrapper}>{kiwiWalletSection}</div>
      <SelectPopup
        bodyClassName={css.popup}
        visible={popupVisible}
        getContainer={null}
        onMaskClick={() => setPopupVisible(false)}
      >
        <Spin spinning={userCredit.isFetching} indicator={<SpinLoading color="primary" />}>
          <div className={css.popup_title}>
            Select Amount
            <span className={css.popup_close_btn} onClick={() => setPopupVisible(false)}>
              <FiX strokeWidth="1.5" />
            </span>
          </div>
          {userCredit.isError ? (
            <Alert
              banner
              type="error"
              message="An error occurred while loading data. Please try again!"
              action={<Button size="small">Retry</Button>}
              onClick={() => userCredit.refetch({ cancelRefetch: true })}
            />
          ) : (
            <>
              <div className={css.popup_total_info}>
                Total Available<span className={css.popup_amount}>${formatCredit(userCreditData)}</span>
              </div>
              <List className={css.popup_list}>
                {maxAmount > 0 && (
                  <List.Item
                    extra={<span className={css.popup_amount}>${formatCredit(maxAmount)}</span>}
                    onClick={() => handleChange(maxAmount)}
                    arrow={<FiChevronRight />}
                  >
                    Maximum Amount
                  </List.Item>
                )}
                <List.Item
                  extra={<span className={css.popup_amount}>$0.00</span>}
                  onClick={() => handleChange(0)}
                  arrow={<FiChevronRight />}
                >
                  None
                </List.Item>
              </List>
            </>
          )}
        </Spin>
      </SelectPopup>
    </div>
  );
}

export default KiwiWalletSelect;
