import { useAuth } from '@/hooks/auth';
import useOrder from '@/hooks/queries/useOrder';
import { getRefreshedOrder } from '@/pages/Orders/util';

const useOrderForReturn = (orderId: string, fulFillId: string) => {
  const { user } = useAuth();
  const orderService = useOrder(orderId, !!orderId);
  const orderData = orderService?.data?.data?.order;
  const contactEmail = orderData?.contact_email || '';
  const orderName = orderData?.name || '';
  const phone = user?.phone ? `${user?.phone_country}${user?.phone}` : '';
  const { fulfillments } = getRefreshedOrder(orderData);
  const orderTypeFromKCOrder = orderData?.combined_orders?.filter(
    (d) => fulfillments?.filter((f) => `${f.id}` === `${fulFillId}`)?.[0]?.order_id === d.id
  )?.[0]?.order_type;
  const orderType = orderTypeFromKCOrder || orderData?.order_type;

  const getLineItems = () => {
    const newFulfillments = fulFillId ? fulfillments.filter(({ id }) => `${fulFillId}` === `${id}`) : fulfillments;
    const oldLineItems = newFulfillments.map((ful) => ful?.items).reduce((total, cur) => [...total, ...cur], []);
    const lineItems = oldLineItems.map(({ image, ...rest }) => ({
      cover: image?.src || '',
      ...rest,
    }));

    return lineItems;
  };

  const lineItems = getLineItems();

  return {
    orderService,
    orderType,
    lineItems,
    orderName,
    contactEmail,
    phone,
  };
};

export default useOrderForReturn;
