import { Divider, Form, FormInstance, message, Select } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import { Input } from '@/components/common';
import PhoneInput from '@/components/PhoneInput';
import { useAuth } from '@/hooks/auth';
import useAddShippingAddress from '@/hooks/queries/useAddShippingAddress';
import useShippingAddresses from '@/hooks/queries/useShippingAddresses';
import useUpdateShippingAddress from '@/hooks/queries/useUpdateShippingAddress';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import AddressesContext from './addressesContext';
import { ADDRESS_DIALOG_PATH, ADDRESSES_DIALOG_VIEW_TYPES } from './cons';
import css from './EditView.module.scss';
import useCscDB from './hooks/useCscDB';
import InputCity from './InputCity';
import { emitAddressChangeEvent, getResetSearchParams } from './util';

type Options = {
  label: string;
  value: string;
  code: string;
};

export interface EditViewProps {
  mode: 'add' | 'edit';
  form: FormInstance;
  onStateChange: (state: any) => void;
}

const cityOptionMap = ({ name, stateCode, stateName, countryCode, countryName }) => ({
  label: name,
  value: name,
  code: name,
  stateCode,
  stateName,
  countryCode,
  countryName,
});
const stateOptionMap = ({ name, stateCode, countryCode }) => ({
  label: name,
  value: name,
  code: stateCode,
  countryCode,
});
const countryOptionMap = ({ name, countryCode }) => ({
  label: name,
  value: name,
  code: countryCode,
});

const DEFAULT_COUNTRY_NAME = 'United States';
const DEFAULT_COUNTRY_CODE = 'US';

const DEFAULT_ADDRESS = {
  address1: null,
  address2: null,
  city: null,
  company: null,
  country: DEFAULT_COUNTRY_NAME,
  country_code: DEFAULT_COUNTRY_CODE,
  default: false,
  first_name: null,
  id: null,
  last_name: null,
  latitude: null,
  longitude: null,
  phone: null,
  phone_country: null,
  province: null,
  province_code: null,
  zip: null,
};

const EditView = ({ mode, form, onStateChange }: EditViewProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [isChanged, setIsChanged] = useState(false);
  const id = searchParams.get('dialog')?.split('/')?.[2] ? Number(searchParams.get('dialog')?.split('/')?.[2]) : '';
  const isEdit = mode === 'edit';
  const isInit = !_.isNil(searchParams.get('init'));
  const fromQ = searchParams.get('from');
  const addShippingAddress = useAddShippingAddress();
  const updateShippingAddress = useUpdateShippingAddress();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();

  const addresses = useShippingAddresses(isEdit && !_.isEmpty(id));
  const getDefaultAddress = () => {
    if (isEdit) {
      return _.find(addresses?.data?.data, { id });
    }
    return DEFAULT_ADDRESS;
  };
  const defaultAddress = getDefaultAddress();
  const currentAddress = {
    ...defaultAddress,
    first_name: defaultAddress?.first_name ?? user?.first_name,
    last_name: defaultAddress?.last_name ?? user?.last_name,
    phone: defaultAddress?.phone ?? user?.phone,
    phone_country: defaultAddress?.phone_country ?? user?.phone_country,
  };
  const { countriesDB, statesDB, citiesDB } = useCscDB(); // address数据源
  const [countryOptions, setCountryOptions] = useState<Options[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<Options[]>([]);
  const [cityOptions, setCityOptions] = useState<Options[]>([]);

  useBeforeUnload(isChanged, 'You have unsaved changes, are you sure?');

  useEffect(() => {
    onStateChange({
      isSaving: addShippingAddress.isLoading || updateShippingAddress.isLoading,
    });
  }, [onStateChange, addShippingAddress.isLoading, updateShippingAddress.isLoading]);

  const handleFinish = (values: any) => {
    // eslint-disable-next-line no-param-reassign
    if (_.isNil(values?.province_code)) values.province_code = _.find(statesDB, { name: values.province })?.stateCode;

    const onSettled = (data: any, error: unknown) => {
      if (error || _.isEmpty(data)) {
        message.error((error as Error)?.message || 'Save Address Failure!');
      } else {
        message.success('Save Address Success!');

        if (isInit) {
          emitAddressChangeEvent({ user, address: data?.data });
          navigate(getUpdatedSearchParamsPath(getResetSearchParams()), {
            state: location.state,
            replace: true,
          });
          return;
        }

        navigate(
          getUpdatedSearchParamsPath({
            dialog:
              fromQ === ADDRESSES_DIALOG_VIEW_TYPES.list_inv ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
          }),
          {
            state: location.state,
            replace: true,
          }
        );
      }
    };

    if (isEdit) {
      updateShippingAddress.mutate({ id, params: values }, { onSettled });
    } else {
      addShippingAddress.mutate({ ...values, default: true }, { onSettled });
    }
  };

  const onFinishFailed = () => {
    message.error('Please fill in the required info.');
  };

  const handleValuesChange = (changedValues: any) => {
    const changed = _.some(changedValues, (value, key) => _.toString(currentAddress?.[key]) !== _.toString(value));
    setIsChanged(changed);
  };

  const handleChangeCountry = (val, option) => {
    form.setFieldsValue({ country_code: option.code, province: undefined, province_code: undefined, city: undefined });
    setProvinceOptions(statesDB.filter(({ countryName }) => val === countryName).map(stateOptionMap));
    setCityOptions(citiesDB.filter(({ countryName }) => val === countryName).map(cityOptionMap));
  };
  const handleChangeProvince = (val, option) => {
    form.setFieldsValue({ city: undefined, province_code: option.code });
    setCityOptions(
      citiesDB
        .filter(({ stateName, countryCode }) => val === stateName && option.countryCode === countryCode)
        .map(cityOptionMap)
    );
  };
  const handleCitySelected = ({ stateName: provinceName }) => {
    if (!form.getFieldValue('province')) {
      form.setFieldsValue({ province: provinceName });
    }
    setCityOptions(citiesDB.filter(({ stateName }) => provinceName === stateName).map(cityOptionMap));
  };
  useEffect(() => {
    form.resetFields();
  }, [form]);
  useEffect(() => {
    const defCountryCode = form.getFieldValue('country_code');
    const defState = form.getFieldValue('province');
    setCountryOptions(countriesDB.map(countryOptionMap));
    setProvinceOptions(statesDB.filter((state) => state.countryCode === defCountryCode).map(stateOptionMap));
    setCityOptions(
      citiesDB
        .filter((city) => {
          if (!defState) {
            return city.countryCode === defCountryCode;
          }
          return city.countryCode === defCountryCode && city.stateName === defState;
        })
        .map(cityOptionMap)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesDB, statesDB, citiesDB]);

  return (
    <Form
      className={css.ns_com_address_dialog_edit_view_form}
      form={form}
      name="shippingAddressForm"
      size="large"
      layout="vertical"
      initialValues={currentAddress}
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={css.input_group2}>
        <Form.Item name="first_name" label="First Name" rules={[{ required: true }]}>
          <Input className={css.input} placeholder="Johns" />
        </Form.Item>

        <Form.Item name="last_name" label="Last Name" rules={[{ required: true }]}>
          <Input className={css.input} placeholder="Hopkins" />
        </Form.Item>
      </div>

      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[{ required: true, pattern: /^[0-9]+$/ }]}
        getValueFromEvent={(e) => {
          form.setFieldsValue({ phone_country: `+${e.dialCode}` });
          return e?.number;
        }}
        getValueProps={(value) => ({
          value,
          defaultDialCode: (currentAddress?.phone_country ?? user?.phone_country)?.slice(1),
        })}
      >
        <PhoneInput className={css.input} format={false} formatOnInit={false} />
      </Form.Item>

      <Divider />

      <Form.Item name="address1" label="Street Address" rules={[{ required: true }]}>
        <Input className={css.input} placeholder="1234 Street Blvd." />
      </Form.Item>

      <Form.Item name="address2" label="Street Address (line 2)">
        <Input className={css.input} />
      </Form.Item>

      <div className={css.input_group2}>
        <Form.Item name="country" label="Country" rules={[{ required: true }]}>
          <Select
            className={css.input}
            placeholder="Select Country"
            options={countryOptions}
            onChange={handleChangeCountry}
          />
        </Form.Item>

        <Form.Item name="province" label="State" rules={[{ required: true }]}>
          <Select
            className={css.input}
            placeholder="--"
            options={provinceOptions}
            dropdownMatchSelectWidth={false}
            onChange={handleChangeProvince}
          />
        </Form.Item>
      </div>

      <div className={cn(css.input_group2, css.last_showed_form_item_wrapper)}>
        <Form.Item name="city" label="City" rules={[{ required: true }]}>
          <InputCity
            className={css.input}
            cityOptions={cityOptions}
            selectedCallback={handleCitySelected}
            placeholder="Portland"
          />
        </Form.Item>

        <Form.Item name="zip" label="Zip Code" rules={[{ required: true }]} normalize={(value) => _.toUpper(value)}>
          <Input className={cn(css.input, css.zip_code)} placeholder="88888" />
        </Form.Item>
      </div>

      <Form.Item name="province_code" hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name="phone_country" hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name="country_code" hidden>
        <input type="hidden" />
      </Form.Item>
    </Form>
  );
};

export default EditView;
