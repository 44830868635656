.ns_com_page_spinner_main {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  background: #ffffff8a;
  z-index: 12345;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
