import { useMutation, useQueryClient } from 'react-query';

import { addReportImageInfringement } from '@/services/ds';

export default function useReportImageInfringement() {
  const queryClient = useQueryClient();

  return useMutation((data: Parameters<typeof addReportImageInfringement>[0]) => addReportImageInfringement(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
    },
  });
}
