import { useMutation, useQueryClient } from 'react-query';

import { unbindStore } from '@/services/lynx';

export default function useUnbindStore() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof unbindStore>[0]) => unbindStore(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('getMessages');
    },
  });
}
