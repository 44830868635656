import currency from 'currency.js';
import _ from 'lodash';

const urlReg = /(https?:\/\/)?([-\w]+\.)+[-\w]+(\/[-\w .,~～∼/?%&=]*)?/i;
const isURL = (url: string): boolean => {
  if (typeof url !== 'string') return false;
  if (!url) return false;
  return !!url.match(urlReg);
};

const convertListProductsCoverImgs = (data = []) => {
  if (!Array.isArray(data)) return [];

  return data
    .filter((p) => isURL(p?.product?.cover))
    .map((p) => {
      const tailorImageParams = 'width=360&height=468&crop=center';
      const { product, ...rest } = p;
      return {
        ...rest,
        product: {
          ...product,
          cover: `${p?.product?.cover}${new URL(p?.product?.cover)?.search ? `&` : `?`}${tailorImageParams}`,
        },
      };
    });
};

const convertListProductsHotFlash = (data, hotFlash, isEnded) => {
  if (!Array.isArray(data)) return [];

  if (isEnded) return data;

  return data.map((p) => {
    const { product, ...rest } = p;
    return {
      ...rest,
      product,
      hotFlash,
    };
  });
};

const formatterPrice = (price: number | string): string => {
  const curPrice = typeof price === 'number' ? price : 0;
  if (curPrice) return curPrice.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1, ');
  return '0.00';
};

const getPriceRange = (priceMin, priceMax) => {
  const min = Number(priceMin);
  const max = Number(priceMax);

  if (min === max || !min) {
    return `$${formatterPrice(max)}`;
  }

  return `$${formatterPrice(min)} - $${formatterPrice(max)}`;
};

const formatCredit = (credit: number): string => ((credit || 0) / 100).toFixed(2);

const formatCurrency = (v: number | string, options?: currency.Options) => currency(v, options).format();

/**
 *
 * @param url : 图片url
 * @param param1 ：裁剪url相关参数，细节查看：https://cdn.shopify.com/
 * @returns 处理后url
 * width default  ：648（根据图片在不同屏幕上取到的最大值324，取其2倍）
 * height default ：864（图片高度432，取其3倍）
 *
 */
const tailImageSrc: (url: string, params?: { width: number; height: number }) => string = (url, params) => {
  try {
    if (!url) throw new Error();
    const width = params?.width || 648;
    const height = params?.height || 864;
    const { origin, pathname, search, hostname } = new URL(url);
    const cropParams = `width=${width}&height=${height}&crop=center`;
    if (hostname !== 'cdn.shopify.com') return url;
    return `${origin}${pathname}${search ? `${search}&${cropParams}` : `?${cropParams}`}`;
  } catch (e) {
    return '';
  }
};

const getUpdatedSearchParams = (searchParams, currentSearchParams = {}) =>
  _.omitBy({ ...Object.fromEntries(searchParams), ...currentSearchParams }, _.isEmpty);

const commonPrefix = (strArray: string[]): string => {
  if (!strArray || !strArray.length) {
    return '';
  }
  const str = strArray[0];
  let index = 0;
  while (index < str.length) {
    const prefix = str.slice(0, index + 1);
    for (let i = 1; i < strArray.length; i += 1) {
      if (!strArray[i].startsWith(prefix)) {
        return str.slice(0, index);
      }
    }
    index += 1;
  }
  return str;
};

export {
  commonPrefix,
  convertListProductsCoverImgs,
  convertListProductsHotFlash,
  formatCredit,
  formatCurrency,
  formatterPrice,
  getPriceRange,
  getUpdatedSearchParams,
  isURL,
  tailImageSrc,
};
