import { AlphaStack, Box } from '@shopify/polaris';

import css from './Open.module.scss';

const Open = () => (
  <div className={css.ns_com_collection_preorder_open}>
    <Box paddingBlockStart="4" paddingBlockEnd="4">
      <AlphaStack gap="8">
        <div>
          <Box paddingBlockEnd="2">
            <p className="ds2_body_title">OPEN PREORDERS</p>
          </Box>
          <AlphaStack gap="3">
            <p className="ds2_body_md">
              {`Preorder products will be launched and available to between the "Start Date" and the "End Date". You can
              see specified date on the product listing!`}
            </p>
            <p className="ds2_body_md">
              {`⏰ End Date: After the "End Date" orders will no longer be accepted. To ensure your order is processed,
              you must pay for your order with Kiwi for all preorder items within the "Start Date" and "End Date".`}
            </p>
            <p className="ds2_body_md">
              {`🚚 Ready to ship date: We will begin fulfilling Preorder products around the "Ship Date". The ship date is
              not a concrete guarantee but a good estimate provided to us by the vendor for these goods. These dates
              should be close but will not be exact.`}
            </p>
          </AlphaStack>
        </div>

        <div>
          <Box paddingBlockEnd="2">
            <p className="ds2_body_title">IMPORTANT INFO FOR PREORDER</p>
          </Box>

          <p className="ds2_body_md">
            <ol className={css.ol}>
              <li>
                To ensure your order for preorder products is processed, you will need to pay for the product(s) prior
                to the close of the preorder period.
              </li>
              <li>
                By placing an order for a preorder item, you are commiting to that order and agreeing to our no
                cancellations policy. No preorder items will be cancelable following the preorder end date.
              </li>
            </ol>
          </p>
        </div>
      </AlphaStack>
    </Box>
  </div>
);

export default Open;
