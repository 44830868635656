.ns_com_order_list_main {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--spacing-m);

  @at-root .ns_or_cancel_order_confirm_dialog {
    :global {
      .adm-dialog-footer {
        .adm-button:not(.adm-dialog-button-bold) {
          color: var(--color-black);
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--color-white);
    filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.08));
    padding: 0 12px;
    cursor: pointer;

    .error_message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-xs) var(--spacing-s);
      color: red;
      font-size: var(--font-size12);
      line-height: 1.3;
      background-color: #f4909033;
      border-radius: 4px;

      & > :first-child {
        flex: 1;
      }
      & > :last-child:not(:only-child) {
        margin-left: var(--spacing-m);

        :global(.button) {
          padding: 0;
          color: #424242;
        }
      }

      &.link {
        display: block;
        a,
        a:hover {
          color: red;
        }
      }
    }

    .combined_order_header {
      background-color: #fff;
      margin-top: 12px;
      border-bottom: 1px solid #c7c7c7;
      .item_header {
        border: none;
        padding: 0;
        flex: 1;
      }
      .arrow_icon {
        width: 20px;
        height: 20px;
        line-height: 24px;
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        color: #000;
        background: url(../../../../assets/images/arrow.svg) no-repeat center center / 10px;
        background-color: #f5f5f5;
        transform: rotate(90deg);
        transition: transform 0.24s;
        position: absolute;
        top: 2px;
        &.active {
          transform: rotate(180deg);
        }
      }
      .item_order_number {
        padding-left: 26px;
      }

      :global {
        .ant-collapse-header {
          padding: 0 0 14px;
        }
        .ant-collapse-content > .ant-collapse-content-box {
          padding: 0 0 16px;
        }
      }
    }

    .item_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: var(--spacing-l) 0;
      border-bottom: 1px solid #c7c7c7;
      &.cursor_pointer {
        cursor: pointer;
      }

      .item_header_info {
        flex: 1;
        min-width: 0;
        margin-right: var(--spacing-m);
        color: var(--color-gray4);
        font-size: var(--font-size3);
        line-height: 1.4;
      }

      .item_header_side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .item_time {
          color: #9999;
          font-size: var(--font-size1);
          line-height: 2;
          &.multiline {
            line-height: 1.4;
            height: 17px;
          }
        }
      }
    }

    .item_order_number {
      display: flex;
      align-items: center;
      .checkbox_wrapper {
        padding-right: 6px;
      }
      .order_no_wrapper {
        color: #424242;
        font-size: var(--font-size2);
        display: flex;
        align-items: center;
      }
      .order_no {
        cursor: pointer;
      }
      .kiwi_no {
        flex: 1;
        padding-left: 6px;
        color: #424242;
        font-size: 14px;
        line-height: 1.6;
      }

      strong {
        font-size: var(--font-size3);
        line-height: 1.4;
        font-weight: 500;
        color: #000;
      }
      :global {
        .adm-checkbox {
          align-items: baseline;
        }
      }
    }

    .item_desc {
      color: var(--color-gray5);
      font-size: var(--font-size2);
      line-height: 1.3;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .default_address_tag_wrapper {
        margin-left: 6px;
      }
    }

    .item_body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      cursor: pointer;
    }

    .item_group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: var(--spacing-l) 0;

      &:not(:first-child) {
        border-top: 1px solid #e8e8e8;
      }

      .item_group_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1.3;

        .item_group_header_info {
          flex: 1;
          min-width: 0;
          margin-right: var(--spacing-m);
        }

        .item_group_header_actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;

          & > * + * {
            margin-left: var(--spacing-m);
          }
        }
      }

      .item_group_body {
        display: block;
      }
    }

    .item_status {
      font-weight: normal;
      display: flex;
      align-items: center;
      .desc {
        padding-left: 8px;
      }
    }

    .item_images {
      margin-top: var(--spacing-m);
      margin-right: calc(-1 * var(--spacing-m));
      overflow: hidden;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      @media screen and (max-width: 768px) {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .item_images_wrapper {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 50px;
        gap: var(--spacing-xs);
        .item_images_wrapper_item {
          position: relative;
          .item_images_tag {
            background-color: #ffffffbf;
            font-size: 12px;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            text-align: center;
            &.sold_out {
              color: red;
            }
            &.over_buy {
              color: #000;
            }
          }
        }
      }

      :global {
        .image {
          width: 50px;
          height: 66px;
          border-radius: 4px;
        }
      }
    }
  }
  .space_bteween_box {
    display: flex;
    justify-content: space-between;
    .left {
      color: #424242;
      font-size: 14px;
    }
    .right {
      color: #999;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 769px) {
    .item {
      border-radius: 8px;
    }
  }
}
.order_list_cancel_order_message_wrapper {
  @media screen and (min-width: 769px) {
    &:global(.ant-message-notice) {
      :global {
        .ant-message-notice-content {
          width: 480px;
        }
      }
    }
  }
}
.ns_or_cancel_order_confirm_dialog {
  &:global(.adm-dialog) {
    :global {
      .adm-dialog-wrap {
        .adm-dialog-footer {
          button {
            color: var(--color-gray4);
          }
          :not(:first-child) {
            color: #000;
          }
        }
      }
    }
  }
}
