/* eslint-disable no-nested-ternary */
import { message, Tooltip } from 'antd';
import { Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useDefaultPaymentMethod from '@/hooks/queries/useDefaultPaymentMethod';
import useUpdateUserSettings from '@/hooks/queries/useOrderCombineSettingsUpdate';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import css from './index.module.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function OrderTrackButton({ className, style }: Props): JSX.Element {
  const { user } = useAuth();
  const paymentSettings = useUserSettings();
  const updatePaymentSettings = useUpdateUserSettings();
  const defaultPaymentMethod = useDefaultPaymentMethod();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const isNoPaymentMethod = defaultPaymentMethod.isFetched && _.isEmpty(defaultPaymentMethod.data);
  const paymentSettingsData = paymentSettings.data?.data;
  const [autoCheckout, setAutoCheckout] = useState(paymentSettingsData?.auto_checkout_activated ?? false);
  const [modalType, setModalType] = useState('noPaymentMethod');
  const [modalVisible, setModalVisible] = useState(false);
  const isAutoCheckoutInitialized = useRef(false);

  const handleToggleAutoCheckout = (checked) => {
    if (checked && localStorage.getItem('isFirstTurnOnAutoCheckout') !== 'true') {
      localStorage.setItem('isFirstTurnOnAutoCheckout', 'true');
      setModalType('firstTurnOn');
      setModalVisible(true);
      return;
    }

    // show email update dialog when user email is empty
    if (checked && _.isEmpty(user?.email || user?.email_pending)) {
      setModalType('noEmail');
      setModalVisible(true);
      return;
    }

    if (checked && isNoPaymentMethod) {
      setModalType('noPaymentMethod');
      setModalVisible(true);
    } else if (!checked) {
      setModalType('turnOffConfirm');
      setModalVisible(true);
    } else if (checked) {
      setAutoCheckout(checked);
    }
  };

  useEffect(() => {
    const handlePaymentMethodChange = (() => setAutoCheckout(true)) as EventListener;
    document.addEventListener('paymentMethods:change', handlePaymentMethodChange);

    return () => document.removeEventListener('paymentMethods:change', handlePaymentMethodChange);
  }, []);

  useEffect(() => {
    if (paymentSettings.isLoading) return;

    if (!isAutoCheckoutInitialized.current) isAutoCheckoutInitialized.current = true;
    setAutoCheckout(!!paymentSettingsData?.auto_checkout_activated);
  }, [paymentSettings, paymentSettingsData?.auto_checkout_activated]);

  useEffect(() => {
    if (!isAutoCheckoutInitialized.current || autoCheckout === !!paymentSettingsData?.auto_checkout_activated) return;

    updatePaymentSettings.mutate(
      { auto_checkout_activated: autoCheckout },
      {
        onSettled: (data, error) => {
          if (error) {
            message.error({ key: 'autoCheckout', content: (error as Error)?.message || 'Set Auto Checkout Failure!' });
            setAutoCheckout(!!paymentSettingsData?.auto_checkout_activated);
          }
        },
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCheckout]);

  return (
    <div className={cn(css.ns_com_auto_checkout_panel_main, className)} style={style}>
      <div>
        <span className={css.auto_checkout_title}>Auto Checkout</span>
        <Tooltip
          overlayClassName={css.auto_checkout_tooltip}
          color="white"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          title={
            <div className={css.auto_checkout_tooltip_content}>
              New orders will automatically be paid
              <br />
              for with your default payment method
              <br />
              set from your Saved Payments page.
            </div>
          }
          placement="bottom"
          trigger={['hover', 'click']}
        >
          <FiAlertCircle size={16} strokeWidth={2} color="#999" />
        </Tooltip>
      </div>

      <Switch
        checked={autoCheckout}
        loading={paymentSettings.isLoading || updatePaymentSettings.isLoading}
        onChange={handleToggleAutoCheckout}
      />

      <Modal
        className={css.auto_checkout_modal}
        visible={modalVisible}
        title={
          modalType === 'firstTurnOn'
            ? 'Turn on Auto Checkout'
            : modalType === 'noEmail'
            ? 'Add an email'
            : modalType === 'noPaymentMethod'
            ? 'Auto Checkout'
            : 'Turn off Auto Checkout?'
        }
        footer={
          modalType === 'firstTurnOn' ? (
            <Button
              block
              color="primary"
              onClick={() => {
                setModalVisible(false);
                handleToggleAutoCheckout(true);
              }}
            >
              Confirm
            </Button>
          ) : modalType === 'noEmail' ? (
            <Button block color="primary" link="/account/profile" onClick={() => setModalVisible(false)}>
              Account Info
            </Button>
          ) : modalType === 'noPaymentMethod' ? (
            <Button
              block
              color="primary"
              link={getUpdatedSearchParamsPath({ dialog: 'payment-methods/add', init: 1 })}
              replace
              onClick={() => setModalVisible(false)}
            >
              Add Payment Method
            </Button>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
              <Button
                block
                color="primary"
                onClick={() => {
                  setModalVisible(false);
                  setAutoCheckout(false);
                }}
              >
                Turn off
              </Button>
              <Button block onClick={() => setModalVisible(false)}>
                Back
              </Button>
            </div>
          )
        }
        maskClosable={false}
        onClose={() => setModalVisible(false)}
      >
        {modalType === 'firstTurnOn'
          ? 'New orders will automatically be paid for with your default payment method set from your Saved Payments page.'
          : modalType === 'noEmail'
          ? 'Please add an email to your account to receive updates to your orders.'
          : modalType === 'noPaymentMethod'
          ? 'In order to enable the Auto Checkout feature, you must have a saved payment method.'
          : 'When you turn off Auto Checkout, we will no longer automatically charge you for your orders. Keep in mind that there is a chance that products may go out of stock if you delay for too long. We will only reserve inventory once you pay.'}
      </Modal>
    </div>
  );
}

export default OrderTrackButton;
