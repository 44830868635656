import { useMutation, useQueryClient } from 'react-query';

import { addProcess } from '@/services/ds';

export default function useOrderAddProcess() {
  const queryClient = useQueryClient();

  return useMutation((params: { order_ids: string[] }) => addProcess(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
    },
  });
}
