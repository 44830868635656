import { Text } from '@shopify/polaris';
import cn from 'classnames';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import CountDown from '@/components/CountDown';
import ProductListItem from '@/components/ProductList/ProductListItem';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop } from '@/hooks';
import { useHotFlashSettings } from '@/hooks/biz';
import useTimeStatus, { TimeStatus } from '@/hooks/biz/useTimeStatus';
import useCollectionProducts from '@/hooks/queries/useCollectionProducts';

import css from './index.module.scss';

const TabLabel = ({
  data: d,
  colors,
  active,
  disabled,
  onCountDown,
}: {
  data: any;
  colors: any;
  active: boolean;
  disabled: boolean;
  onCountDown: (countDown) => void;
}) => {
  const collectionDetailURL = d?.handle ? `/collections/${d?.handle}` : '';
  // 控制是否处在活动中
  const { update, status } = useTimeStatus(d?.begins_at, d?.ends_at);

  const style = useMemo(() => {
    const getColor = () => {
      if (active) return colors?.active;
      if (disabled) return colors?.disabled;
      return colors?.default;
    };

    return {
      backgroundColor: getColor()?.bg_color || null,
      color: getColor()?.text_color || null,
    };
  }, [active, colors, disabled]);

  const handleFinish = () => {
    update();
  };

  const handleOnCountDown = (countDown) => {
    if (active && status === TimeStatus.Started) {
      onCountDown?.(countDown);
    }
  };

  return (
    <div className={css.tab_label_wrapper} style={style}>
      {disabled ? (
        <div>Coming Soon</div>
      ) : (
        <>
          <h3 className={css.title}>{d?.title}</h3>
          <AutoLink to={collectionDetailURL} className={css.desc}>
            Shop All
          </AutoLink>
          <div>
            <CountDown
              startTime={d?.begins_at || Date.now()}
              endTime={d?.ends_at}
              onFinish={handleFinish}
              onCountDown={handleOnCountDown}
            />
          </div>
        </>
      )}
    </div>
  );
};

const HotFlash = () => {
  const isDesktop = useDesktop();
  const { title, desc, colors, tabs = [], link_all } = useHotFlashSettings();
  const [activeKey, setActiveKey] = useState('');
  const activeHandle = tabs[activeKey]?.handle;
  const [productsMap, setProductsMap] = useState(null);
  const [countDownMap, setCountDownMap] = useState(null);

  const enabledRefreshProducts = !!activeHandle && !productsMap[activeKey];
  const productsOrigin = useCollectionProducts(
    activeHandle,
    {
      page: 1,
      page_size: 5,
    },
    enabledRefreshProducts
  );
  const products = useMemo(
    () => (productsOrigin?.data?.pages?.[0]?.data?.products || [])?.slice(0, 5),
    [productsOrigin?.data?.pages]
  );
  const productsData = productsMap?.[activeKey];
  const isLoading = !productsData?.length || !activeKey;

  const handleCountDown = useCallback(
    (val) => {
      setCountDownMap((state) => ({
        ...state,
        [activeKey]: val,
      }));
    },
    [activeKey]
  );

  const items = useMemo(
    () =>
      tabs.map((tab, key) => ({
        key: `${key}`,
        disabled: !tab?.handle,
        label: (
          <TabLabel
            data={tab}
            colors={colors}
            active={activeKey === `${key}`}
            disabled={!tab?.handle}
            onCountDown={handleCountDown}
          />
        ),
        // children: <TabChild data={productsMap?.[key]} />,
      })),
    [activeKey, colors, handleCountDown, tabs]
  );

  useEffect(() => {
    if (tabs?.length) {
      setActiveKey('0');
      setProductsMap(
        _.reduce(tabs, (result, value, index) =>
          // eslint-disable-next-line no-param-reassign
          ({
            ...(result || {}),
            [index]: null,
          })
        )
      );
      setCountDownMap(
        _.reduce(tabs, (result, value, index) => ({
          [index]: 0,
        }))
      );
    }
  }, [tabs]);

  useEffect(() => {
    setProductsMap((prev) => ({
      ...prev,
      [activeKey]: products?.map((d) => ({
        ...d,
        hotFlash: {
          countDown: countDownMap[activeKey],
        },
      })),
    }));
  }, [activeKey, countDownMap, products]);

  if (!tabs?.length) return null;

  return (
    <div className={css.ns_comp_hot_flash_main}>
      <header className={css.hot_flash_header}>
        <section>
          <Text variant="heading2xl" as="h3">
            {title}
          </Text>
          <p className={css.desc}>{desc}</p>
        </section>
        {!!link_all?.text && !!link_all?.link && (
          <Button className={css.hot_flash_shop_all_btn} shape="rounded" link={link_all.link} target="_blank">
            {link_all.text}
          </Button>
        )}
      </header>
      <div className={cn(css.hot_flash_content_wrapper, { [css.loading]: isLoading })}>
        <div
          className={css.tabs_wrapper}
          style={{ gridTemplateColumns: `repeat(${items.length}, minmax(${isDesktop ? '246px' : '200px'}, 1fr))` }}
        >
          {items.map(({ key, disabled, label }) => (
            <div
              className={cn(css.tab, { [css.disabled]: disabled }, { [css.active]: activeKey === key })}
              key={key}
              onClick={() => {
                if (disabled) return;
                setActiveKey(key);
              }}
            >
              {label}
            </div>
          ))}
        </div>
        <StatusViewRenderer isEmpty={false} isLoading={isLoading} statusStyle={{ marginTop: 32, marginBottom: 32 }}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <div className={css.hot_flash_products_wrapper}>
              {productsData?.map?.((product, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ProductListItem key={`${index}-${activeKey}`} data={product} />
              ))}
            </div>
          </motion.div>
        </StatusViewRenderer>
      </div>
    </div>
  );
};

export default HotFlash;
