$rightContentWidth: 600px;

.modal_bulk_select_sku_pc {
  .bulk_select_sku_pc_list_item {
    display: flex;
    padding-top: 36px;
    &:first-child {
      padding-top: 0;
    }
    .left {
      width: 104px;
      height: 140px;
      object-fit: cover;
      border-radius: 4px;
    }
    .right {
      flex: 1;
      padding-left: 16px;
      .title {
        font-size: 14px;
        color: #000;
        font-size: 16px;
        line-height: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: $rightContentWidth;
      }
      .price {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4rem;
        color: #000000;
        margin-top: 4px;
      }
    }
  }

  .form {
    max-height: 480px;
    min-height: 400px;
  }

  .sku_table {
    margin-top: 8px;
    width: $rightContentWidth;
    &:global(.ant-table-wrapper) {
      :global {
        .ant-table-thead > tr > th {
          font-weight: 400;
        }

        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th {
          border: none;
        }

        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th {
          background-color: #f5f5f580;
        }
      }
    }
  }

  .modal__body {
    margin-bottom: var(--spacing-l);
  }
  .modal__footer {
    button {
      width: 250px;
      margin: 0 auto;
    }
  }
}
