.status_group_comp {
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: var(--spacing-s);
    .btn {
      font-size: var(--font-size2);
      border: none;
      height: 30px;
      line-height: 1;
      color: var(--color-gray4);
      &.active {
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        background: linear-gradient(270deg, #f5fce5 0%, rgba(245, 252, 229, 0.5) 115.54%), #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
        font-weight: 500;
        color: #000;
      }
    }
  }
}
