/* eslint-disable react/button-has-type */
import { Form, message } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useMedia } from 'react-use';

import Button from '@/components/Button';
import { Input } from '@/components/common';
import Modal from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useUpdateUser from '@/hooks/queries/useUpdateUser';

import css from './index.module.scss';

export type ComponentHandle = {
  show: () => void;
  hide: () => void;
};

const ModalEditName = forwardRef<ComponentHandle>((props, ref) => {
  const isDesktop = useMedia('(min-width: 769px)');
  const { user } = useAuth();
  const updateUser = useUpdateUser();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const isNameExist = !!user?.first_name && !!user?.last_name;
  const [hasNameSection, notHasNameSection] = [
    {
      modalTitle: 'Edit Name',
      saveSuccessMessage: 'Name changed!',
    },
    {
      modalTitle: 'Set Up Name',
      saveSuccessMessage: 'Name set!',
    },
  ];
  const nameSection = isNameExist ? hasNameSection : notHasNameSection;

  const handleShow = () => {
    setVisible(true);
  };
  const handleHide = () => {
    setVisible(false);
  };
  const submit = () => {
    form.validateFields().then((values) => {
      updateUser.mutate(values, {
        onSuccess() {
          handleHide();
          message.success(nameSection.saveSuccessMessage);
        },
        onError(error: Error) {
          message.error(error.message || 'Failed!');
        },
      });
    });
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(user);
    } else {
      handleHide();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, user]);

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  return (
    <Modal
      visible={visible}
      onClose={handleHide}
      title={nameSection.modalTitle}
      className={css.modal_edit_name}
      fullscreen="mobile"
    >
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        layout={isDesktop ? 'inline' : 'vertical'}
        initialValues={{
          first_name: user?.first_name,
          last_name: user?.last_name,
        }}
      >
        <Form.Item name="first_name" label="First" rules={[{ required: true }]}>
          <Input size="large" maxLength={50} />
        </Form.Item>
        <Form.Item name="last_name" label="Last" rules={[{ required: true }]}>
          <Input size="large" maxLength={50} />
        </Form.Item>
      </Form>
      <div className={css.btn_box}>
        <Button block color="main" shape="rounded" onClick={submit} loading={updateUser.isLoading}>
          Save
        </Button>
      </div>
    </Modal>
  );
});

export default ModalEditName;
