import { useModal } from '@ebay/nice-modal-react';
import { AlphaCard, AlphaStack, Box, Frame, Page } from '@shopify/polaris';
import { lazy, Suspense, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import useShippingAddresses from '@/hooks/queries/useShippingAddresses';

import Empty from './components/Empty';
import FormModal from './components/FormModal';
import Skeleton from './components/Skeleton';
import css from './index.module.scss';

const List = lazy(() => import('./components/List'));

const Addresses = () => {
  const formModal = useModal(FormModal);
  const addressesRQ = useShippingAddresses();
  const addressesData = addressesRQ.data?.data || [];
  const hasAddresses = !!addressesData.length;

  const handleAdd = useCallback(() => {
    formModal.show();
  }, [formModal]);

  if (addressesRQ.isLoading) {
    return <Skeleton />;
  }

  return (
    <Suspense fallback={<Skeleton />}>
      <Frame>
        <Page title="Addresses" primaryAction={{ content: 'Add', onAction: handleAdd, type: 'primary' }}>
          <Helmet>
            <html lang="en" className={css.ns_or_addresses_main_active} />
            <title>Shipping Addresses</title>
          </Helmet>

          {hasAddresses ? (
            <Box paddingBlockEnd="4">
              <AlphaStack gap="5">
                <AlphaCard padding="0" roundedAbove="xl">
                  <List data={addressesData} />
                </AlphaCard>
              </AlphaStack>
            </Box>
          ) : (
            <Empty onAdd={handleAdd} />
          )}
        </Page>
      </Frame>
    </Suspense>
  );
};

export default Addresses;
