import cn from 'classnames';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';

import css from './index.module.scss';

type Option = {
  value: string;
  label: string;
  count?: number;
  showCount?: boolean;
};

type Props = {
  options: Option[];
  searchKey: 'status' | 'last_error_type';
};

const App = ({ options, searchKey }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get(searchKey) || '';

  // TODO：
  // last_error_type ，status 只能留一个，由于交互上要互斥
  const onF = (q) => {
    const newQ = {};

    Object.keys(q).forEach((key) => {
      if (key === 'last_error_type' && searchKey === 'status') {
        return;
      }
      if (key === 'status' && searchKey === 'last_error_type') {
        return;
      }
      newQ[key] = q[key];
    });

    return newQ;
  };

  const handleChange = (value: string) => {
    const newSearchParams = _.omitBy(onF({ ...Object.fromEntries(searchParams), [searchKey]: value }), _.isEmpty);
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <div className={css.order_status_filter_comp_scrollable}>
      <div className={css.content}>
        {options.map(({ label, value, showCount, count }) => (
          <span className={css.button_wrapper} key={label}>
            {showCount && (
              <span
                className={cn(css.count, {
                  [css.two_digits]: count > 9 && count < 100,
                  [css.three_digits]: count > 99,
                })}
              >
                {count > 99 ? '99+' : count}
              </span>
            )}
            <Button
              key={value}
              color={status === value ? 'primary' : 'default'}
              bold={false}
              onClick={() => handleChange(value)}
            >
              {label}
            </Button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default App;
