.priceContent {
  .set_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &_title {
      font-size: 20px;
    }
    &_secondary {
      font-size: 14px;
      p {
        color: #85858c;
      }
    }
    &_filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .inputWrapper {
        margin: 0;
        width: 30%;
      }
    }
  }
  .modalBody {
    margin-bottom: 15px;
    .title {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }
}
