import { useMutation, useQueryClient } from 'react-query';

import { bindStore } from '@/services/lynx';

export default function useBindStore() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof bindStore>[0]) => bindStore(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('store');
    },
  });
}
