$prefixCls: home-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__content {
    margin: 0 auto;
    max-width: var(--content-max-width);
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
  }

  &__product-list-title {
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 115%;
  }

  &__product-filters-bar-wrapper {
    margin: 0 auto;
    max-width: var(--content-max-width);
  }

  .sticky-outer-wrapper.active .sticky-inner-wrapper {
    width: 100% !important;
    padding: 0 var(--spacing-l);
    left: 0;
    background: var(--color-gray1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__content {
      padding: 34px 48px;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }
  }
}
