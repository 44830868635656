import Helmet from 'react-helmet';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';

import css from './success.module.scss';

function Success(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div className={css.ns_page_product_request_success_main}>
      <Helmet>
        <html lang="en" className={css.ns_page_product_request_success_active} />
        <title>Product Request</title>
      </Helmet>
      <SecondaryNavBar className={css.secondary_nav_bar}>Product Request</SecondaryNavBar>
      <div className={css.content}>
        <div className={css.content_info}>
          <div className={css.icon}>
            <IoIosCheckmarkCircle />
          </div>
          <div className={css.title}>Request Submitted!</div>
          <div className={css.desc}>
            We’ve recieved your request! Please check your email within the next 1-2 business days
          </div>
        </div>
        <div className={css.btn_wrapper}>
          <Button color="default" shape="rounded" block onClick={() => navigate(-1)}>
            Submit More Links
          </Button>
          <Button color="primary" shape="rounded" block onClick={() => navigate('/', { replace: true })}>
            Browse KiwiDrop
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Success;
