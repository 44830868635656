import OmittedName from '@/components/biz/OmittedName';

import { PaymentMethodTypeEnum } from '../types';

const PaymentMethodTitle = ({ data }: { data: any }) => {
  const isBank = data?.type === PaymentMethodTypeEnum.Bank;
  const name = isBank ? data?.detail?.bank_name : data?.detail?.brand;

  return <OmittedName name={name} last4={data?.detail?.last4} />;
};

export default PaymentMethodTitle;
