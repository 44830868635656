import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import Title from '@/components/Title';
import { SESSION_STORAGE_KEYS } from '@/constants/app';
import useCart from '@/hooks/queries/useCart';
import useDesktop from '@/hooks/useDesktop';

import CartContext from './cartContext';
import CartProductList from './components/CartProductList';
import CheckoutSection from './components/CheckoutSection';
import Empty from './components/Empty';
import ManageSectionM from './components/ManageSection';
import ManageSectionPC from './components/ManageSectionPC';
import SwitchEditButton from './components/SwitchEditButton';
import styles from './styles.scss';
import useCartItems from './useCartItems';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Cart({ className, style }: Props): JSX.Element {
  const isDesktop = useDesktop();
  const curPageRef = useRef(null);
  const cart = useCart();
  const cartProductData = useMemo(() => cart?.data?.data?.items || [], [cart?.data]);
  const isEmpty = !!cartProductData?.length === false;
  const [isInManagementMode, setIsInManagementMode] = useState(false);
  const [isNormalSelectedAll, setIsNormalSelectedAll] = useState(false);
  const [normalSelectedIds, setNormalSelectedIds] = useState([]);
  const [soldoutSelectedIds, setSoldoutSelectedIds] = useState([]);
  const [isSoldOutSelectedAll, setIsSoldOutSelectedAll] = useState(false);
  const [isSoldOutDisabled, setIsSoldOutDisabled] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllSelectedDisabled, setIsAllSelectedDisabled] = useState(false);
  const { soldOutItems, inStockItems, inStockItemsSpuDimension } = useCartItems(cartProductData, normalSelectedIds);

  const getShowCheckoutSection = () => {
    if (isDesktop) return true;
    return !isInManagementMode;
  };
  const showCheckoutSection = getShowCheckoutSection();
  const allIds = cartProductData?.map(({ variant_id }) => variant_id);
  const cartOrderParams = sessionStorage.getItem(SESSION_STORAGE_KEYS.cartOrderParams);
  const lineItemsFromSessionStorage = useMemo(() => JSON.parse(cartOrderParams)?.lineItems || [], [cartOrderParams]);

  // len related
  const inStockItemsLen = inStockItems.length;
  const soldOutItemsLen = soldOutItems.length;
  const normalSelectedIdsLen = normalSelectedIds.length;
  const soldoutSelectedIdsLen = soldoutSelectedIds.length;
  const allLen = allIds.length;

  // curSelected
  const selectedInNotManagementModeRef = useRef(null);

  // 触发全选，或者局部全选
  const toggleIsNormalSelectedAll = useCallback(
    (isChecked) => {
      const curIds = isChecked ? inStockItems.map((i) => i.variant_id) : [];
      setNormalSelectedIds(curIds);
    },
    [inStockItems]
  );
  const toggleIsSoldOutSelectedAll = useCallback(
    (isChecked) => {
      const curIds = isChecked ? soldOutItems.map((i) => i.variant_id) : [];
      setSoldoutSelectedIds(curIds);
    },
    [soldOutItems]
  );
  const toggleIsAllSelected = (isChecked) => {
    toggleIsNormalSelectedAll(isChecked);
    toggleIsSoldOutSelectedAll(isSoldOutDisabled ? false : isChecked);
  };

  // 更新局部商品数量
  const changeNormalSelectedIds = (ids: string[]) => {
    setNormalSelectedIds(ids);
  };
  const changeSoldoutSelectedIds = (ids: string[]) => {
    setSoldoutSelectedIds(ids);
  };

  // 切换模式，编辑模式时，默认全取消，回到非编辑模式，回填
  const toggleIsInManagementMode = () => {
    const newIsInManagementMode = !isInManagementMode;

    setIsInManagementMode(newIsInManagementMode);
    changeSoldoutSelectedIds([]);
    if (newIsInManagementMode) {
      selectedInNotManagementModeRef.current = normalSelectedIds;
      changeNormalSelectedIds([]);
    } else if (Array.isArray(selectedInNotManagementModeRef.current)) {
      const newNormalSelectedIds = selectedInNotManagementModeRef.current.filter(
        (variant_id) => !!inStockItems.filter((i) => i.variant_id === variant_id).length
      );
      changeNormalSelectedIds(newNormalSelectedIds);
    }
  };

  const batchUpdate = useCallback(() => {
    const newNormalSelectedIds = normalSelectedIds.filter(
      (variant_id) => !!inStockItems.filter((item) => item.variant_id === variant_id).length
    );
    const newSoldoutSelectedIds = soldoutSelectedIds.filter(
      (variant_id) => !!soldOutItems.filter((item) => item.variant_id === variant_id).length
    );
    changeNormalSelectedIds(newNormalSelectedIds);
    changeSoldoutSelectedIds(newSoldoutSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inStockItems, soldOutItems]);

  const right = (
    <div className={cn(`${prefixCls}__navbar_right`)}>
      <SwitchEditButton />
    </div>
  );
  // 被动设置isAll是否被选中
  useEffect(() => {
    let newIsAllSelected = normalSelectedIdsLen + soldoutSelectedIdsLen === allLen;
    if (isSoldOutDisabled) {
      newIsAllSelected = normalSelectedIdsLen === inStockItemsLen && inStockItemsLen > 0;
    }
    setIsAllSelected(newIsAllSelected);
  }, [allLen, inStockItemsLen, isSoldOutDisabled, normalSelectedIdsLen, soldoutSelectedIdsLen]);

  // /被动设置isAll是否能被点击
  useEffect(() => {
    // 没有可加购的商品，并且sold都不能点
    const disabledAllSelect = inStockItemsLen === 0 && isSoldOutDisabled;
    setIsAllSelectedDisabled(disabledAllSelect);
  }, [inStockItemsLen, isSoldOutDisabled]);

  //  被动设置normal products是否全被选中
  useEffect(() => {
    const newIsNormalSelectedAll = inStockItemsLen === normalSelectedIdsLen && normalSelectedIdsLen > 0;
    setIsNormalSelectedAll(newIsNormalSelectedAll);
  }, [inStockItemsLen, normalSelectedIdsLen]);

  // 被动设置sold out是否全被选中
  useEffect(() => {
    const newSoldOutSelectedAll = soldOutItemsLen === soldoutSelectedIdsLen && soldoutSelectedIdsLen > 0;
    setIsSoldOutSelectedAll(newSoldOutSelectedAll);
  }, [soldOutItemsLen, soldoutSelectedIdsLen]);
  // 被动设置sold out disabled
  useEffect(() => {
    setIsSoldOutDisabled(!isInManagementMode);
  }, [isInManagementMode]);
  // 做批量操作时，需要更新当前选中的元素,踢出无效值
  useEffect(() => {
    batchUpdate();
  }, [batchUpdate]);

  const initBySessionCartData = (data) => {
    setNormalSelectedIds(data);
  };
  const initByCartData = () => {
    const newNormalSelectedIds = inStockItems.map(({ variant_id }) => variant_id);
    setNormalSelectedIds(newNormalSelectedIds);
  };
  const initPage = useCallback(() => {
    if (curPageRef.current?.hasLoadedOnce) return;

    const idsFromSessionStorage = lineItemsFromSessionStorage?.map(({ variant_id }) => `${variant_id}`);
    const idsBothInCartAndSessionStorage = inStockItems
      .map(({ variant_id }) => variant_id)
      .filter((variant_id) => idsFromSessionStorage.includes(variant_id));
    const isUsingSessionCartData = !!idsBothInCartAndSessionStorage?.length;

    if (isUsingSessionCartData) {
      initBySessionCartData(idsBothInCartAndSessionStorage);
    } else {
      initByCartData();
    }
    setIsSoldOutDisabled(true);
    if (cart.isFetched && !!inStockItems?.length) {
      curPageRef.current = { hasLoadedOnce: true };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inStockItems, lineItemsFromSessionStorage]);
  useEffect(() => {
    initPage();
  }, [initPage]);

  const scrollToShowErrorMessage = () => {
    const firstDom = document.querySelector('#cart-product-list .error_message_wrapper.visible');
    if (firstDom) {
      const instockitemsIndex = firstDom.getAttribute('data-instockitems-index');
      const tmp = inStockItemsSpuDimension.slice(0, Number(instockitemsIndex));
      const prevItemsLen = tmp.reduce((total, cur) => {
        if (cur?.items?.length) {
          return total + cur?.items?.length;
        }
        return total;
      }, 0);

      let scrollTop = 0;
      if (prevItemsLen > 0) {
        // isdesktop: 108: nav + 38: notice
        // !isdesktop: 52: products头，12: 顶上的padding
        // common: 86: 图片高度，12: 每一个item下边距
        const commonHeight = prevItemsLen * (86 + 12);
        scrollTop = isDesktop ? commonHeight + 108 + 38 : commonHeight + 52 + 12;
      }
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  };

  const cartContextValue = {
    isNormalSelectedAll,
    normalSelectedIds,
    toggleIsNormalSelectedAll,
    changeNormalSelectedIds,

    isSoldOutSelectedAll,
    soldoutSelectedIds,
    toggleIsSoldOutSelectedAll,
    changeSoldoutSelectedIds,
    isSoldOutDisabled,

    isAllSelected,
    isAllSelectedDisabled,
    toggleIsAllSelected,

    isInManagementMode,
    toggleIsInManagementMode,
    originCartItems: cartProductData || [],
    inStockItems,
    soldOutItems,

    scrollToShowErrorMessage,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      <div className={cn(prefixCls, className, { [`${prefixCls}--empty`]: isEmpty })} style={style}>
        <Helmet>
          <html lang="en" className={cn(`${prefixCls}-active`, { management: isInManagementMode })} />
          <title>Cart</title>
        </Helmet>

        {!isDesktop && (
          <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`} right={right}>
            Cart
          </SecondaryNavBar>
        )}

        <div className={`${prefixCls}__content`}>
          <Title title="Cart" />

          <StatusViewRenderer
            data={cartProductData}
            error={cart.error}
            emptyContent={<Empty />}
            isLoading={cart.isLoading}
            statusStyle={{ marginTop: 64 }}
          >
            <div className={`${prefixCls}__content-layout`}>
              <div className={`${prefixCls}__content-layout_body_left`}>
                {isDesktop && <ManageSectionPC />}
                <CartProductList />
              </div>
              <div className={`${prefixCls}__content-layout_body_right`}>
                <CheckoutSection visible={showCheckoutSection} data={cart?.data?.data} />
                {!isDesktop && <ManageSectionM />}
              </div>
            </div>
          </StatusViewRenderer>
        </div>
      </div>
    </CartContext.Provider>
  );
}

export default Cart;
