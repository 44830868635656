/* eslint-disable react/no-unescaped-entities */
import cn from 'classnames';
import Helmet from 'react-helmet';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function IntellectualPropertyPolicy({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Intellectual Property Policy</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <h1>Intellectual Property Policy</h1>
        <p>
          KiwiDrop does fully respect the intellectual property rights (IPRs) of third parties and has a strict
          zero-tolerance policy against any counterfeit and/or infringement of intellectual property.
        </p>

        <p>
          We do regularly review the listings on our website in order to avoid any possible intellectual property
          infringement. We will remove any listing in violation of our policy based on our review.
        </p>

        <p>
          Despite our efforts to avoid any sale of infringing products on our website, due to the high number of
          products, suppliers, and maker partners, there are times at which we cannot detect or identify intellectual
          property infringements. That is why we strongly encourage our customers or IPRs holders to report any
          potential infringement on IPRs.
        </p>

        <p>
          <strong>
            If you believe your IPRs have been infringed, we urge you to let us know about them and to follow our
            procedure of complaint.
          </strong>
        </p>

        <h3>1. Eligible complaints include</h3>

        <p>
          Trademark Infringement: Unauthorized use of an identical or similar trademark on goods which are identical
          with, or similar to, the goods for which the trademark is registered.
        </p>

        <p>Patent Infringement: Unauthorized use of others’ granted Design Patent or Utility Patent.</p>

        <p>
          Copyright Infringement: Unauthorized use of the content or photos created by others or sell others’ copyright
          works, including books, software, etc.
        </p>

        <p>
          Other activities that are considered to have constituted intellectual property infringement by applicable
          laws.
        </p>

        <h3>2. Actions we may take against the infringing listings</h3>

        <p>
          It is KiwiDrop’s policy to expeditious response to clear notices of alleged IPR infringement. Our response to
          such notices may include the following:
        </p>

        <p>To remove from our website the alleged infringing listings.</p>

        <p>
          To inform the seller about your complaint and to provide your contact to them, so that you both can clarify
          the issue.
        </p>

        <p>
          To remove selling privileges and to withhold funds from the infringing sellers, as well as to destroy
          infringing inventory in our possession, if any, if the infringement is proved to be true or is upheld by any
          competent court.
        </p>

        <p>To terminate the user account of the infringing seller in case of willful infringement.</p>

        <p>
          To terminate the user account of the infringing seller immediately, under certain extreme situations where a
          lawsuit has been filed against KiwiDrop due to seller’s infringing activities, or where KiwiDrop is required
          by judiciary, enforcement or administrative authorities to terminate any account or take any similar measures.
        </p>

        <p>
          The infringement case will be recorded for one (1) year from the date in which any actions mentioned above
          have been taken.
        </p>

        <h3>3. Procedures of Complaint</h3>

        <p>
          If you believe your IPRs have been infringed, you can start a complaint by creating an account on our
          Intellectual Property Platform. Please note that you will be guided to file the following identity and
          intellectual property rights documents:
        </p>

        <p>
          Proof of identity: For an individual, a copy of national identity card, passport or other documents that can
          prove your identity. For an entity, a copy of the business registration certification.
        </p>

        <p>
          If you are not the IPRs owner, please also provide the identification document of the IPRs owner and a letter
          of authorization issued by the IPRs owner.
        </p>

        <p>Proof of IPRs: Such as a copy of official trademark/ patent/ copyright certificate.</p>

        <p>
          Once verified, you can submit hyperlinks of the listings and provide any additional evidences to support your
          allegations. We will preliminarily review your request and notify the sellers of your complaint. If any
          counter-notification is received, it will be forwarded for your response.
        </p>

        <h3>4. Procedures of Appeal</h3>

        <p>
          For the sellers - KiwiDrop will notify the complaints raised by IPRs owner along with its contact information
          when we received an IPRs infringement report. You can contact the complainant directly or file a
          counter-notification when you believe the removed listing doesn’t infringe the complainant IPRs.
        </p>

        <p>The removed listing will be reinstated under one of the following circumstances:</p>

        <p>The complainant withdraws the relevant complaint.</p>

        <p>
          You have sufficient, lawful and valid evidences showing that the removed listing doesn’t infringe the
          complainant IPRs.
        </p>
      </div>
    </div>
  );
}

export default IntellectualPropertyPolicy;
