import { useQuery } from 'react-query';

import { getUserCreditTransactions } from '@/services/ds';

export default function useUserCreditTransactions(params?: any) {
  return useQuery(['getUserCreditTransactions', params], () => getUserCreditTransactions(params), {
    enabled: !!params?.customer_id,
    keepPreviousData: true,
  });
}
