import { useMemo } from 'react';

import { SideBarItemType } from '@/components/biz/SideBar/index';
import useSettings from '@/hooks/queries/useSettings';

const useSideBar = (id) => {
  const settings = useSettings();
  const sideBarData = useMemo(() => {
    let sideBarDataOrigin = [];
    settings?.data?.data?.sidebar?.data?.forEach((d) => {
      if (d?.seller_collection_ids?.includes(id) || d?.handles?.includes(id)) {
        sideBarDataOrigin = d?.data || [];
      }
    });
    return sideBarDataOrigin;
  }, [id, settings]);
  return {
    sideBarPC: sideBarData,
    sideBarM: useMemo(
      () => sideBarData.filter((d) => d?.showOnM && d?.title && d.type !== SideBarItemType.Blank),
      [sideBarData]
    ),
  };
};

export default useSideBar;
