import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GrConnect } from 'react-icons/gr';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useAuth } from '@/hooks/auth';
import useAuthShopCallback from '@/hooks/queries/useAuthShopCallback';
import useBindStore from '@/hooks/queries/useBindStore';

import styles from './index.module.scss';

enum STATUS_ENUM {
  VERIFIED_FAILED = 'VERIFIED_FAILED',
  NEED_LOGIN = 'NEED_LOGIN',
  BOUNF_BY_OTHER_ACCOUNT = 'BOUNF_BY_OTHER_ACCOUNT',
  COMMON_ERROR = 'COMMON_ERROR',
}

const DEFAULT_BTN_TEXT = 'Close';
const { prefixCls } = styles;

export default function App() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchOrigin = Object.fromEntries(searchParams);
  const authShopCallback = useAuthShopCallback();
  const bindStore = useBindStore();
  const isInConnectMode = !!searchOrigin?.connect;
  const [waitingShops, setWaitingShops] = useState(true);
  const timerRef = useRef(null);

  const shopName =
    authShopCallback?.data?.data?.shop?.name ||
    authShopCallback?.error?.data?.shop?.name ||
    bindStore?.data?.data?.shop?.name ||
    bindStore?.error?.data?.shop?.name;

  const [status, setStatus] = useState(null);
  const showCommonError = [STATUS_ENUM.VERIFIED_FAILED, STATUS_ENUM.COMMON_ERROR].includes(status);

  const handleClose = () => {
    navigate('/', { replace: true });
  };

  const handleAction = () => {
    if (status === STATUS_ENUM.BOUNF_BY_OTHER_ACCOUNT) {
      navigate('/stores', { replace: true });
      return;
    }

    navigate('/', { replace: true });
  };

  const openLoginPage = () => {
    logout();
    navigate('/', { replace: true });
  };

  const setTimer = () => {
    const timer = setTimeout(() => {
      navigate('/stores', { replace: true });
    }, 6000);
    timerRef.current = timer;
  };

  const requestCallback = useCallback(() => {
    authShopCallback.mutate(searchOrigin, {
      onSuccess(resp) {
        // 认证成功，未登录，登录成功，回来发起connect请求
        const curShopName = resp?.data?.shop?.name;
        if (!user?.id && curShopName) {
          navigate(
            `/login?scene=connectStore&shop=${encodeURIComponent(curShopName)}&redirect=${encodeURIComponent(
              `/auth/shop/shopify/callback?connect=true&state=${searchOrigin?.state}`
            )}`,
            { replace: true }
          );
          return;
        }
        // 认证成功，已登录
        setTimer();
      },
      onError(e) {
        setWaitingShops(false);
        if (e?.code === 60201) {
          setStatus(STATUS_ENUM.VERIFIED_FAILED);
        } else if (e?.code === 60211) {
          // 验证通过、用户已登录、后端自动尝试 connect、Shop 绑定失败 - 已被他人绑定
          setStatus(STATUS_ENUM.BOUNF_BY_OTHER_ACCOUNT);
        } else {
          setStatus(STATUS_ENUM.COMMON_ERROR);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestConnect = useCallback(() => {
    bindStore.mutate(
      {
        state: searchOrigin?.state,
      },
      {
        onSuccess() {
          setTimer();
        },
        onError(error) {
          setWaitingShops(false);
          if (error?.code === 60211) {
            setStatus(STATUS_ENUM.BOUNF_BY_OTHER_ACCOUNT);
          } else {
            setStatus(STATUS_ENUM.COMMON_ERROR);
          }
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOrigin?.shop_id, searchOrigin?.state]);

  const getPageElements = () => {
    if ([STATUS_ENUM.COMMON_ERROR, STATUS_ENUM.VERIFIED_FAILED].includes(status)) {
      const apiErrorMsg = authShopCallback?.error?.message || bindStore?.error?.message;
      return [
        <>
          Whoops!
          <br />
          Something went wrong.
        </>,
        <>
          {!!apiErrorMsg && (
            <div className={cn(`${prefixCls}__result-description`, 'error-message')}>{apiErrorMsg}</div>
          )}
          <div className={cn(`${prefixCls}__result-description`)}>
            Reach out to us at <AutoLink to="mailto:support@kiwidrop.com">support@kiwidrop.com</AutoLink> about this
            issue.
          </div>
        </>,
        null,
      ];
    }

    if (status === STATUS_ENUM.BOUNF_BY_OTHER_ACCOUNT) {
      return [
        <>
          Whoops!
          <br />
          <br />
          {shopName && (
            <>
              {shopName}
              <br />
              is already connected another account.
            </>
          )}
          <div className={`${prefixCls}__result-description`}>
            You’re currently logged in to <strong>{`${user?.phone_country ?? ''}${user?.phone ?? ''}`}</strong>
          </div>
        </>,
        null,
        'Got it',
      ];
    }

    return [null, null, null];
  };
  const [caption, description, btnText] = getPageElements();
  const isPageLoading =
    waitingShops ||
    (isInConnectMode
      ? bindStore.isLoading || !(bindStore.isError || bindStore.isSuccess)
      : authShopCallback.isLoading || !(authShopCallback.isError || authShopCallback.isSuccess));

  useEffect(() => {
    if (isInConnectMode) {
      requestConnect();
    } else {
      requestCallback();
    }
  }, [requestCallback, requestConnect, isInConnectMode]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Connect Shop</title>
      </Helmet>
      <StatusViewRenderer isEmpty={false} isLoading={isPageLoading}>
        <Modal
          className={`${prefixCls}__dialog`}
          visible
          title={showCommonError ? 'Error' : 'Connect a store'}
          fullscreen="mobile"
          footer={
            <>
              <Button
                className={`${prefixCls}__result-ok-btn`}
                block
                color="primary"
                shape="rounded"
                loading={isPageLoading}
                disabled={isPageLoading}
                onClick={handleAction}
              >
                {btnText || DEFAULT_BTN_TEXT}
              </Button>
              {status === STATUS_ENUM.BOUNF_BY_OTHER_ACCOUNT && (
                <div className={`${prefixCls}__to_login_btn`} onClick={openLoginPage}>
                  Log in to another account
                </div>
              )}
            </>
          }
          maskClosable={false}
          onClose={handleClose}
        >
          <div className={`${prefixCls}__result`}>
            <div className={`${prefixCls}__result-image`}>{showCommonError ? <i>🤧</i> : <GrConnect />}</div>
            <div className={`${prefixCls}__result-caption`}>{caption}</div>
            {description}
          </div>
        </Modal>
      </StatusViewRenderer>
    </>
  );
}
