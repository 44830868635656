import { Col, Drawer, Row } from 'antd';
import { useMemo } from 'react';
import { FiX } from 'react-icons/fi';

import Modal from '@/components/Modal';
import useDesktop from '@/hooks/useDesktop';

import { useGuideState } from '../../hooks/useGuideState';
import { hideCatalogRetailGuide, useCatalogRetailStore } from '../../store';
import styles from './index.module.scss';

const Content = () => (
  <div className={styles.guideModal}>
    <h1 className={styles.title}>How to start</h1>
    <Row className={styles.rowItem}>
      <Col span={2} xs={3}>
        <span className={styles.index}>1</span>
      </Col>
      <Col span={22} xs={21}>
        <h2 className={styles.title}>Change products price</h2>
        <p className={styles.desc}>
          Modify your products Shopify retail price in order to be better sell on Kiwi. Select products to change
          products price. By default, we will set price for all your retail products as 70% of your Shopify selling
          price.
        </p>
      </Col>
    </Row>
    <Row className={styles.rowItem}>
      <Col span={2} xs={3}>
        <span className={styles.index}>2</span>
      </Col>
      <Col span={22} xs={21}>
        <h2 className={styles.title}>Set products as Active</h2>
        <p className={styles.desc}>
          Only Active kiwi status products will be shown in Kiwi network. Select products to change status.
        </p>
      </Col>
    </Row>
    <h1 className={styles.title}>How to stop retailing</h1>
    <Row className={styles.rowItem}>
      <Col span={2} xs={3}>
        <span className={styles.index}>1</span>
      </Col>
      <Col span={22} xs={21}>
        <h2 className={styles.title}>Change products status on Kiwi or Shopify</h2>
        <p className={styles.desc}>
          Inactive products on Kiwi: retailers won’t find and retail your inactive products. Draft products on Shopify:
          products as draft on your Shopify store won’t be shown in Kiwi retail catalog. Existing retailers won’t be
          able resell them.
        </p>
      </Col>
    </Row>
  </div>
);

const title = 'How it works';

export const Guider = () => {
  const isDesktop = useDesktop();
  const isGuideModalVisible = useCatalogRetailStore((state) => state.isGuideModalVisible);

  const { showGuideModalShow, disableGuideModal } = useGuideState();

  const show = useMemo(() => showGuideModalShow || isGuideModalVisible, [isGuideModalVisible, showGuideModalShow]);

  const handleCloseModal = () => {
    disableGuideModal();
    hideCatalogRetailGuide();
  };

  if (isDesktop) {
    return (
      <>
        <Modal fullscreen="mobile" title={title} visible={show} onClose={handleCloseModal}>
          <Content />
        </Modal>
      </>
    );
  }

  return (
    <Drawer
      title={
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          {title}
        </h1>
      }
      closable={false}
      extra={<FiX strokeWidth="1.5" onClick={handleCloseModal} />}
      placement="bottom"
      open={show}
      onClose={handleCloseModal}
      height="70%"
      bodyStyle={{
        padding: '5px',
      }}
    >
      <Content />
    </Drawer>
  );
};
