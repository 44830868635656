type ClickNavP = {
  nav_parent_level_0: string;
  nav_parent_level_1: string;
  nav_target_type: string;
  nav_title: string;
};

const clickNav = (params: ClickNavP) => {
  if ((window as any)?.dataLayer) {
    (window as any).dataLayer.push({
      event: 'gtm.clickTopNav',
      ...params,
    });
  }
};
const placeholder = '';

export { clickNav, placeholder };
