.ns_com_topic_list_main {
  margin: var(--spacing-xxl) 0;
  margin-right: calc(-1 * var(--spacing-l));

  .ns_or_swiper {
    --swiper-navigation-color: var(--color-black);
    --swiper-navigation-size: 12px;

    // edge fadeout effect
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 66px;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: linear-gradient(90deg, var(--color-gray1) 0%, rgba(245, 245, 245, 0) 84.31%);
    }

    &.is_beginning::before {
      display: none;
    }
    &.is_end::after {
      display: none;
    }

    &::after {
      left: auto;
      right: 0;
      transform: rotate(-180deg);
    }

    :global {
      .swiper-button-prev,
      .swiper-button-next {
        visibility: hidden;
        top: 60px;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        background: var(--color-white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.2);
        }
      }

      .swiper-button-disabled {
        display: none;
      }
    }

    &:hover {
      :global {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }

    @media screen and (max-width: 768px) {
      &::before,
      &::after {
        display: none;
      }

      :global {
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
    }
  }

  .topic {
    user-select: none;

    & > a {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      color: var(--color-black);
      font-size: var(--font-size2);
      line-height: 1.3;
      text-align: center;
    }

    .topic_title {
      margin-top: var(--spacing-m);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .topic_image {
      border-radius: 100%;
    }
  }

  .ns_or_swiper {
    :global {
      .swiper-slide {
        width: fit-content;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .topic {
      .topic_title {
        font-size: 10px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    margin: 34px 0;
    .topic {
      .topic_title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  }
}
