import { useContext } from 'react';

import lighting from '@/assets/images/lighting.svg';
import CountDown from '@/components/CountDown';
import Image from '@/components/Image';
import Title from '@/components/Title';

import Brand from './Brand';
import CollectionDetailContext from './collectionDetailContext';
import css from './Header.module.scss';

interface Props {
  brand?: any;
  collection?: any;
  productsTotal?: number;
}

const Header = ({ brand, collection, productsTotal }: Props) => {
  const title = collection?.title;
  const bodyHtml = collection?.body_html;
  const productsTotalDesc = `${productsTotal > 0 ? productsTotal : 'No'} ${productsTotal === 1 ? ' item' : ' items'}`;
  const { timeStatus, setting, setCountDown, hotFlashTitle, imageURL } = useContext(CollectionDetailContext);

  // hotflash
  if (timeStatus?.status) {
    return (
      <div className={css.ns_com_collection_header_main}>
        <div className={css.ns_com_hot_flash_main}>
          <div className={css.hot_flash_title} style={{ backgroundImage: imageURL ? `url(${imageURL})` : null }}>
            {!imageURL && <h1>{hotFlashTitle}</h1>}
          </div>
          <div className={css.hot_flash_footer}>
            <span className={css.text}>
              <img src={lighting} alt="" />
              {` `}
              {hotFlashTitle} {timeStatus?.desc}
            </span>
            <CountDown
              startTime={setting?.begins_at || Date.now()}
              endTime={setting?.ends_at}
              onFinish={timeStatus?.update}
              onCountDown={setCountDown}
            />
          </div>
          <div className={css.hot_flash_title_wrapper}>
            <h1>{title}</h1>
            <p className={css.suffix}>{productsTotalDesc}</p>
            <div
              className={css.desc}
              dangerouslySetInnerHTML={{
                __html: bodyHtml,
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  // brand
  if (brand) {
    return (
      <Brand
        brand={{
          ...brand,
          name: title,
        }}
        qty={productsTotal}
      />
    );
  }

  return (
    <div className={css.ns_com_collection_header_main}>
      {collection?.image && (
        <div className={css.header_cover}>
          <Image className={css.image} src={collection?.image} fit="cover" />
        </div>
      )}
      <Title title={title} desc={productsTotalDesc} showBackTo />
    </div>
  );
};

export default Header;
