import { IndexTable } from '@shopify/polaris';
import { useMemo } from 'react';

import { useDesktop } from '@/hooks';

import ListItem from './ListItem';

const ListView = ({ data, loading }: { data: any[]; loading: boolean }) => {
  const isDesktop = useDesktop();
  const resourceName = {
    singular: 'record',
    plural: 'records',
  };

  const rowMarkup = useMemo(
    // eslint-disable-next-line react/no-array-index-key
    () => data.map((d, index) => <ListItem key={index} data={d} index={index} />),
    [data]
  );

  return (
    <IndexTable
      condensed={!isDesktop}
      loading={loading}
      resourceName={resourceName}
      itemCount={data.length}
      headings={[
        { title: 'Product' },
        { title: 'Title' },
        { title: 'SKU' },
        { title: 'Quantity', alignment: 'end' },
        { title: '' },
      ]}
      selectable={false}
    >
      {rowMarkup}
    </IndexTable>
  );
};

export default ListView;
