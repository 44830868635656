.ns_or_store_detail_active {
  .store_detail_links_item_text {
    flex: 1;
    color: var(--p-text);
  }

  :global {
    .container {
      padding-bottom: var(--p-space-5);
    }
  }
}
