import { gray, green, red, yellow, teal, blue, orange, purple } from '../colors.mjs';

var color = {
  'color-bg-inverse': {
    value: gray[900],
    description: ''
  },
  'color-bg-inset-strong': {
    value: gray[800],
    description: ''
  },
  'color-bg-inverse-hover': {
    value: gray[800],
    description: ''
  },
  'color-bg-inverse-active': {
    value: gray[700],
    description: ''
  },
  'color-bg-strong-hover': {
    value: gray[500],
    description: ''
  },
  'color-bg-strong-active': {
    value: gray[500],
    description: ''
  },
  'color-bg-strong': {
    value: gray[400],
    description: ''
  },
  'color-bg-subdued-active': {
    value: gray[400],
    description: ''
  },
  'color-bg-disabled': {
    value: gray[300],
    description: ''
  },
  'color-bg-interactive-disabled': {
    value: gray[300],
    description: ''
  },
  'color-bg-app': {
    value: gray[200],
    description: ''
  },
  'color-bg-app-active': {
    value: gray[400],
    description: ''
  },
  'color-bg-app-hover': {
    value: gray[300],
    description: ''
  },
  'color-bg-app-selected': {
    value: gray[300],
    description: ''
  },
  'color-bg-active': {
    value: gray[300],
    description: ''
  },
  'color-bg-subdued-hover': {
    value: gray[300],
    description: ''
  },
  'color-bg-inset': {
    value: gray[200],
    description: ''
  },
  'color-bg-hover': {
    value: gray[200],
    description: ''
  },
  'color-bg-subdued': {
    value: gray[100],
    description: ''
  },
  'color-bg-input': {
    value: gray[50],
    description: ''
  },
  'color-bg': {
    value: gray[50],
    description: ''
  },
  'color-bg-primary-active': {
    value: green[900],
    description: ''
  },
  'color-bg-primary-hover': {
    value: green[800],
    description: ''
  },
  'color-bg-primary': {
    value: green[700],
    description: ''
  },
  'color-bg-success-strong': {
    value: green[600],
    description: ''
  },
  'color-bg-success': {
    value: green[300],
    description: ''
  },
  'color-bg-primary-subdued-active': {
    value: green[200],
    description: ''
  },
  'color-bg-success-subdued-active': {
    value: green[200],
    description: ''
  },
  'color-bg-success-subdued': {
    value: green[100],
    description: ''
  },
  'color-bg-primary-subdued-hover': {
    value: green[100],
    description: ''
  },
  'color-bg-success-subdued-hover': {
    value: green[50],
    description: ''
  },
  'color-bg-primary-subdued': {
    value: green[50],
    description: ''
  },
  'color-bg-primary-subdued-selected': {
    value: green[50],
    description: ''
  },
  'color-bg-critical-strong-active': {
    value: red[800],
    description: ''
  },
  'color-bg-critical-strong-hover': {
    value: red[700],
    description: ''
  },
  'color-bg-critical-strong': {
    value: red[600],
    description: ''
  },
  'color-bg-critical-subdued-active': {
    value: red[200],
    description: ''
  },
  'color-bg-critical': {
    value: red[200],
    description: ''
  },
  'color-bg-critical-subdued': {
    value: red[100],
    description: ''
  },
  'color-bg-critical-subdued-hover': {
    value: red[50],
    description: ''
  },
  'color-bg-caution-strong': {
    value: yellow[600],
    description: ''
  },
  'color-bg-caution': {
    value: yellow[300],
    description: ''
  },
  'color-bg-caution-subdued-active': {
    value: yellow[200],
    description: ''
  },
  'color-bg-caution-subdued': {
    value: yellow[100],
    description: ''
  },
  'color-bg-caution-subdued-hover': {
    value: yellow[50],
    description: ''
  },
  'color-bg-info-strong': {
    value: teal[600],
    description: ''
  },
  'color-bg-info-subdued-active': {
    value: teal[200],
    description: ''
  },
  'color-bg-info': {
    value: teal[200],
    description: ''
  },
  'color-bg-info-subdued': {
    value: teal[100],
    description: ''
  },
  'color-bg-info-subdued-hover': {
    value: teal[50],
    description: ''
  },
  'color-bg-interactive-active': {
    value: blue[800],
    description: ''
  },
  'color-bg-interactive-hover': {
    value: blue[700],
    description: ''
  },
  'color-bg-interactive': {
    value: blue[600],
    description: ''
  },
  'color-bg-interactive-subdued-active': {
    value: blue[200],
    description: ''
  },
  'color-bg-interactive-subdued-hover': {
    value: blue[100],
    description: ''
  },
  'color-bg-interactive-subdued': {
    value: blue[50],
    description: ''
  },
  'color-bg-interactive-selected': {
    value: blue[50],
    description: ''
  },
  'color-bg-warning': {
    value: orange[200],
    description: ''
  },
  'color-bg-magic-strong': {
    value: purple[500],
    description: ''
  },
  'color-bg-magic-hover': {
    value: purple[200],
    description: ''
  },
  'color-bg-magic': {
    value: purple[100],
    description: ''
  },
  'color-bg-magic-subdued-hover': {
    value: purple[100],
    description: ''
  },
  'color-bg-magic-subdued': {
    value: purple[50],
    description: ''
  },
  'color-border-input-hover': {
    value: gray[800],
    description: ''
  },
  'color-border-inverse': {
    value: gray[800],
    description: ''
  },
  'color-border-strong-hover': {
    value: gray[700],
    description: ''
  },
  'color-border-input': {
    value: gray[600],
    description: ''
  },
  'color-border-hover': {
    value: gray[600],
    description: ''
  },
  'color-border-strong': {
    value: gray[600],
    description: ''
  },
  'color-border': {
    value: gray[500],
    description: ''
  },
  'color-border-disabled': {
    value: gray[400],
    description: ''
  },
  'color-border-subdued': {
    value: gray[400],
    description: ''
  },
  'color-border-interactive-disabled': {
    value: gray[400],
    description: ''
  },
  'color-border-primary': {
    value: green[700],
    description: ''
  },
  'color-border-success': {
    value: green[600],
    description: ''
  },
  'color-border-success-subdued': {
    value: green[400],
    description: ''
  },
  'color-border-critical-active': {
    value: red[900],
    description: ''
  },
  'color-border-critical-hover': {
    value: red[800],
    description: ''
  },
  'color-border-critical': {
    value: red[600],
    description: ''
  },
  'color-border-critical-subdued': {
    value: red[200],
    description: ''
  },
  'color-border-caution': {
    value: yellow[600],
    description: ''
  },
  'color-border-caution-subdued': {
    value: yellow[400],
    description: ''
  },
  'color-border-info': {
    value: teal[500],
    description: ''
  },
  'color-border-info-subdued': {
    value: teal[400],
    description: ''
  },
  'color-border-interactive-active': {
    value: blue[800],
    description: ''
  },
  'color-border-interactive-hover': {
    value: blue[700],
    description: ''
  },
  'color-border-interactive': {
    value: blue[500],
    description: ''
  },
  'color-border-interactive-focus': {
    value: blue[500],
    description: ''
  },
  'color-border-interactive-subdued': {
    value: blue[200],
    description: ''
  },
  'color-border-magic-strong': {
    value: purple[500],
    description: ''
  },
  'color-border-magic': {
    value: purple[400],
    description: ''
  },
  'color-icon-hover': {
    value: gray[900],
    description: ''
  },
  'color-icon': {
    value: gray[800],
    description: ''
  },
  'color-icon-active': {
    value: gray[900],
    description: ''
  },
  'color-icon-subdued': {
    value: gray[700],
    description: ''
  },
  'color-icon-disabled': {
    value: gray[600],
    description: ''
  },
  'color-icon-interactive-disabled': {
    value: gray[600],
    description: ''
  },
  'color-icon-inverse': {
    value: gray[400],
    description: ''
  },
  'color-icon-on-color': {
    value: gray[50],
    description: ''
  },
  'color-icon-primary': {
    value: green[700],
    description: ''
  },
  'color-icon-success': {
    value: green[600],
    description: ''
  },
  'color-icon-critical': {
    value: red[600],
    description: ''
  },
  'color-icon-caution': {
    value: yellow[700],
    description: ''
  },
  'color-icon-info': {
    value: green[600],
    description: ''
  },
  'color-icon-warning': {
    value: orange[500],
    description: ''
  },
  'color-icon-interactive-active': {
    value: blue[800],
    description: ''
  },
  'color-icon-interactive-hover': {
    value: blue[700],
    description: ''
  },
  'color-icon-interactive': {
    value: blue[600],
    description: ''
  },
  'color-icon-interactive-inverse': {
    value: blue[400],
    description: ''
  },
  'color-icon-magic': {
    value: purple[600],
    description: ''
  },
  'color-text': {
    value: gray[900],
    description: ''
  },
  'color-text-subdued': {
    value: gray[800],
    description: ''
  },
  'color-text-disabled': {
    value: gray[700],
    description: ''
  },
  'color-text-interactive-disabled': {
    value: gray[700],
    description: ''
  },
  'color-text-inverse-subdued': {
    value: gray[600],
    description: ''
  },
  'color-text-inverse': {
    value: gray[200],
    description: ''
  },
  'color-text-on-color': {
    value: gray[50],
    description: ''
  },
  'color-text-success-strong': {
    value: green[900],
    description: ''
  },
  'color-text-success': {
    value: green[700],
    description: ''
  },
  'color-text-primary': {
    value: green[700],
    description: ''
  },
  'color-text-primary-hover': {
    value: green[800],
    description: ''
  },
  'color-text-critical-strong': {
    value: red[900],
    description: ''
  },
  'color-text-critical-active': {
    value: red[800],
    description: ''
  },
  'color-text-critical': {
    value: red[600],
    description: ''
  },
  'color-text-caution-strong': {
    value: yellow[900],
    description: ''
  },
  'color-text-caution': {
    value: yellow[800],
    description: ''
  },
  'color-text-info-strong': {
    value: teal[900],
    description: ''
  },
  'color-text-info': {
    value: teal[700],
    description: ''
  },
  'color-text-warning-strong': {
    value: orange[900],
    description: ''
  },
  'color-text-interactive-active': {
    value: blue[800],
    description: ''
  },
  'color-text-interactive-hover': {
    value: blue[700],
    description: ''
  },
  'color-text-interactive': {
    value: blue[600],
    description: ''
  },
  'color-text-interactive-inverse': {
    value: blue[400],
    description: ''
  },
  'color-text-magic-strong': {
    value: purple[800],
    description: ''
  },
  'color-text-magic': {
    value: purple[600],
    description: ''
  }
};

export { color };
