.ns_com_modal_print_live_helper_main {
  .modal_print_live_helper_content {
    display: flex;
    gap: 8px;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
    }
    .print_live_helper_select_wrapper {
      .input {
        border-radius: 6px;
      }
    }
    .print_demo_full_size_wrapper {
      background-color: #f5f5f5;
      padding: 12px 82px;
      min-height: 200px;
    }
    .print_demo_sample_wrapper {
      padding: 12px 138px;
      background-color: #f5f5f5;
    }
  }

  .modal_print_live_helper_footer {
    text-align: center;
    button {
      width: 200px;
    }
  }

  .footer_wrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    padding-top: 8px;
    margin: 0;
  }

  .print_live_helper_ref {
    @media print {
      @page {
        margin-top: 0;
        margin-bottom: 0;
      }
      body {
        padding-top: 1.6cm;
        padding-bottom: 1.6cm;
      }
    }
  }
}
