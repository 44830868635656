import cn from 'classnames';
import React, { useMemo } from 'react';

import Image from '@/components/Image';
import ProductTags, { ProductTagsPos } from '@/components/ProductTags';
import { checkIsOverBuy, checkIsSoldOut, getBoostInfo, getGroupedPreOrderProducts } from '@/utils/biz';

import AutoLink from '../AutoLink';
import { FulfillLabelGray } from '../FulfillLabel';
import { UpArrow } from '../Icon';
import css from './index.module.scss';

export interface OrderProductsCardProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any[];
}

export interface OrderProductListProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any[];
}

export interface OrderProductListItemProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any;
}

export function OrderProductsCard({ className, style, data }: OrderProductsCardProps): JSX.Element {
  return (
    <div className={cn(css.ns_com_order_products_card_main, className)} style={style}>
      <div className={css.header}>
        <div className={css.title_wrapper}>
          <span className={css.title}>Products</span>
          <span className={css.text}>
            {data?.length} item{(data as any)?.length > 1 && 's'}
          </span>
        </div>
      </div>
      <div className={css.body}>
        <OrderProductList data={data} />
      </div>
    </div>
  );
}

export function OrderProductList({ className, style, data }: OrderProductListProps): JSX.Element {
  const groupedData = useMemo(() => {
    const { data: curGroupedData } = getGroupedPreOrderProducts(data);
    return curGroupedData;
  }, [data]);

  return (
    <div className={cn(css.list, className)} style={style}>
      {Object.keys(groupedData)
        .sort()
        ?.map((key) => (
          <>
            {groupedData[key]?.map((item: any) => (
              <OrderProductListItem key={item?.variant_id} data={item} />
            ))}
          </>
        ))}
    </div>
  );
}

export function OrderProductListItem({ className, style, data }: OrderProductListItemProps): JSX.Element {
  const showSoldOut = checkIsSoldOut(data?.available_quantity);
  const showOverBuy = checkIsOverBuy(data?.qty, data?.available_quantity);
  const currentPreOrder = useMemo(
    () => data?.preorder?.find((p) => p?.variant_ids?.includes(data?.variant_id)),
    [data?.preorder, data?.variant_id]
  );
  const isPreOrder = !!currentPreOrder?.preorder;

  let tagText = '';
  if (showSoldOut) tagText = 'Sold Out';
  if (showOverBuy) tagText = `Only ${data?.available_quantity}`;
  const { isShow: showBoost, ratioStr } = getBoostInfo(data, data?.price);

  const productTag = useMemo(() => {
    if (isPreOrder) {
      return (
        <div className={css.list_item_tag}>
          <span className={css.pre_order}>Preorder</span>
        </div>
      );
    }

    if (tagText) {
      return (
        <div className={cn(css.list_item_tag, { [css.sold_out]: showSoldOut }, { [css.over_buy]: showOverBuy })}>
          {tagText}
        </div>
      );
    }

    return null;
  }, [isPreOrder, showOverBuy, showSoldOut, tagText]);

  return (
    <AutoLink
      className={cn(css.list_item, className)}
      style={style}
      to={`/products/${data?.product_id}?variant=${data?.variant_id}`}
    >
      <div className={css.list_item_cover}>
        <Image className={css.list_item_cover_image} src={data?.image?.src} fit="cover" lazy />
        {productTag}
      </div>
      <div className={css.list_item_info}>
        <div className={css.list_item_title} title={data?.title}>
          {data?.title}
        </div>
        <div className={css.list_item_body_item}>
          <div className={css.list_item_variant} title={data?.variant_title}>
            {data?.variant_title} x{data?.qty}
          </div>
          <div className={css.list_item_price}>${(data?.price ?? 0)?.toFixed(2)}</div>
        </div>
        <div className={css.list_item_fulfill_label}>
          <FulfillLabelGray product={data} />
        </div>
        <div className={css.list_item_tags}>
          <ProductTags customStyleTags={data?.labels} pos={ProductTagsPos.CHECKOUT} />
        </div>
        {showBoost && (
          <div className={css.boost_wrapper}>
            <span className={css.boost}>
              {ratioStr} Boost <UpArrow />
            </span>
          </div>
        )}
      </div>
    </AutoLink>
  );
}

OrderProductsCard.List = OrderProductList;
OrderProductsCard.ListItem = OrderProductListItem;
export default OrderProductsCard;
