import { AlphaStack, Box, Inline, Text } from '@shopify/polaris';
import { FiPlus } from 'react-icons/fi';

const Empty = ({ onAdd }: { onAdd: () => void }) => {
  const handleAdd = () => {
    if (onAdd) {
      onAdd();
    }
  };

  return (
    <Box paddingBlockStart="28">
      <Inline align="center">
        <div onClick={handleAdd} style={{ cursor: 'pointer' }}>
          <Box padding="8" paddingBlockStart="4" paddingBlockEnd="4" background="background-pressed" borderRadius="2">
            <AlphaStack align="center">
              <div>
                <FiPlus fontSize={32} strokeWidth={2.5} />
              </div>
              <Text as="p" variant="bodyMd" color="subdued">
                No Address found
              </Text>
              <Text as="p" variant="bodyMd" color="subdued">
                Add an address
              </Text>
            </AlphaStack>
          </Box>
        </div>
      </Inline>
    </Box>
  );
};

export default Empty;
