import { useMutation, useQueryClient } from 'react-query';

import { updateShopSettings } from '@/services/ds';

type UpdateShopSettingsParams = {
  app_drop_soon_early_access_days?: string;
  shipping_rule_default_address_activated?: boolean;
  shipping_rule_default_address?: any;
  shipping_rule_routing_activated?: boolean;
  shipping_rule_route_outside?: string;
  shipping_rule_route_to?: string;
  fulfillment_notify_customer?: boolean;
  brand_assets?: {
    logo?: string;
    invoice_message?: string;
  };
  inventory_location_id?: number;
};

export default function useUpdateShopSettings(shopId: string) {
  const queryClient = useQueryClient();
  return useMutation((params: UpdateShopSettingsParams) => updateShopSettings(shopId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries('shopSettings');
    },
  });
}
