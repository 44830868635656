$actionsBarHeight: 200px;

.ns_comp_cart_checkout_section_actions {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  width: 100%;
  height: var(--app-layout-padding-bottom);
  z-index: 100;
  padding: var(--spacing-l);
  background: var(--color-white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));

  .button {
    margin-top: var(--spacing-l);
  }

  .notes {
    .title {
      margin-bottom: var(--spacing-l);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 500;
    }

    .input {
      border-radius: 4px;
    }
  }

  .info_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-l) 0;
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
  }

  @media screen and (min-width: 769px) {
    position: sticky;
    z-index: 1;
    flex: 1;
    top: calc(var(--app-layout-padding-top) + var(--spacing-l));
    left: auto;
    width: auto;
    height: auto;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

    .cart_checkout_section_actions_button {
      margin-top: 0;
      width: 300px;
    }
  }
}
