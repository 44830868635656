import cn from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';

import Button, { Props as ButtonProps } from '@/components/Button';
import Modal from '@/components/Modal';

import css from './index.module.scss';

export interface Props extends ButtonProps {
  fulfillment?: any;
}

type VisibleState = 'show' | 'shown' | 'hide' | 'hidden';

function OrderTrackButton({ className, fulfillment, children = 'Track', ...resetProps }: Props): JSX.Element {
  const [dialogVisible, setDialogVisible] = useState<VisibleState>('hidden');

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (fulfillment?.oversea_tracking_number) {
      setDialogVisible('show');
    } else if (fulfillment?.tracking_url) {
      window.open(fulfillment?.tracking_url, '_blank');
    }
  };

  return (
    <>
      <Button
        className={cn(css.ns_com_order_track_button_main, className)}
        color="primary"
        size="small"
        disabled={_.isEmpty(fulfillment)}
        onClick={handleClick}
        {...resetProps}
      >
        {children}
      </Button>

      {fulfillment?.oversea_tracking_number && dialogVisible !== 'hidden' && (
        <Modal
          className={css.track_info_dialog}
          visible={['show', 'shown'].includes(dialogVisible)}
          title="Track Order"
          showAnimation="mobile"
          showMountAnimation
          onClose={() => setDialogVisible('hide')}
          afterShow={() => setDialogVisible('shown')}
          afterClose={() => setDialogVisible('hidden')}
        >
          <div className={css.track_info_dialog_content}>
            <div className={css.tracking_item}>
              <div className={css.tracking_item_title}>Domestic</div>
              <div className={css.tracking_item_content}>
                <div className={css.tracking_item_company}>{fulfillment?.tracking_company}</div>
                <div className={css.tracking_item_number}>{fulfillment?.tracking_number}</div>
                <div className={css.tracking_item_actions}>
                  <Button
                    shape="rounded"
                    fill="none"
                    icon={<FiExternalLink />}
                    link={fulfillment?.tracking_url}
                    target="_blank"
                  />
                </div>
              </div>
            </div>

            <div className={css.tracking_item}>
              <div className={css.tracking_item_title}>Overseas</div>
              <div className={css.tracking_item_content}>
                <div className={css.tracking_item_company}>{fulfillment?.oversea_tracking_company}</div>
                <div className={css.tracking_item_number}>{fulfillment?.oversea_tracking_number || '-'}</div>
                <div className={css.tracking_item_actions}>
                  <Button
                    shape="rounded"
                    fill="none"
                    icon={<FiExternalLink />}
                    link={
                      fulfillment?.oversea_tracking_url ||
                      `https://m.17track.net/en/track-details#nums=${fulfillment?.oversea_tracking_number}`
                    }
                    target="_blank"
                  />
                </div>
              </div>
            </div>

            <div className={css.track_info_dialog_message}>
              This package transfers from our overseas warehouse to our US warehouse. Only you will see the overseas
              tracking number.
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default OrderTrackButton;
