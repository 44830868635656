/**
 * TODO:
 * 由于一个packages中会有多个相同的sku，页面交互时需要使用唯一id
 * 所以，
 * 交互时使用 shopify_id , 最终提交时取 shopify_id对应的sku累计输入的数量，进入退货表单页面
 */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Checkbox from '@/components/Checkbox';
import Image from '@/components/Image';
import Stepper from '@/components/Stepper';
import type { Product } from '@/types';

import css from './index.module.scss';

type CompHandle = {
  getData: () => { sku: string; qty: number }[];
};

type NewProduct = Product & { curQty: number; qty: number; sku: string };
type Props = {
  data: NewProduct[];
  onChange?: (selectedShopifyIdsWithInvalidQty: string[]) => void;
};

const App = forwardRef<CompHandle, Props>(({ data = [], onChange }, ref) => {
  const navigate = useNavigate();
  const shopifyIDArr = data.map(({ shopify_id }) => shopify_id);
  // { [shopify_id]: qty }
  const [selectedShopifyIdMapQty, setSelectedShopifyIdMapQty] = useState<{ [key: string]: number }>({});
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const selectedShopifyIdsWithInvalidQty = Object.keys(selectedShopifyIdMapQty).filter(
    (shopify_id) => selectedShopifyIdMapQty[shopify_id] > 0
  );
  const qtyCount = Object.keys(selectedShopifyIdMapQty).length
    ? Object.values(selectedShopifyIdMapQty).reduce((total, qty) => total + qty, 0)
    : 0;

  const navigateToProductDetail = (productId, variantId) => {
    navigate(`/products/${productId}?variant=${variantId}`);
  };

  const changeSelectAll = (isChecked) => {
    setSelectedShopifyIdMapQty((prevState) => {
      const curPrevState = prevState;
      data.forEach(({ shopify_id, qty }) => {
        curPrevState[shopify_id] = isChecked ? qty : 0;
      });
      return { ...curPrevState };
    });
  };

  const changeSelectOne = (value) => {
    setSelectedShopifyIdMapQty((prevState) => {
      const curPrevState = prevState;
      Object.keys(curPrevState).forEach((shopify_id) => {
        const isChecked = value.includes(shopify_id);
        let count = 0;
        if (isChecked) {
          count = curPrevState[shopify_id] > 0 ? curPrevState[shopify_id] : 1;
        }
        curPrevState[shopify_id] = count;
      });
      return { ...curPrevState };
    });
  };

  const handleQtyChange = (shopify_id, val) => {
    setSelectedShopifyIdMapQty((prevState) => {
      const curPrevState = prevState;
      curPrevState[shopify_id] = val;
      return { ...curPrevState };
    });
  };

  const handleQtyBlur = (shopify_id, val, max) => {
    if (/\d+/.test(val)) {
      let newVal = val;
      if (val > max) {
        newVal = max;
      } else if (val < 0) {
        newVal = 0;
      }
      handleQtyChange(shopify_id, newVal);
    }
  };

  useState(() => {
    const newDataSource = {};
    data.forEach(({ shopify_id }) => {
      newDataSource[shopify_id] = 0;
    });
    setSelectedShopifyIdMapQty(newDataSource);
  }, [data]);

  useEffect(() => {
    const newIsSelectedAll =
      selectedShopifyIdsWithInvalidQty.length > 0 && selectedShopifyIdsWithInvalidQty.length === shopifyIDArr.length;
    setIsSelectedAll(newIsSelectedAll);
  }, [shopifyIDArr.length, selectedShopifyIdsWithInvalidQty.length]);

  useImperativeHandle(ref, () => ({
    getData() {
      const selectedData = selectedShopifyIdsWithInvalidQty.map((shopify_id) => ({
        sku: data.filter((d) => d.shopify_id === shopify_id)[0].sku,
        qty: selectedShopifyIdMapQty[shopify_id],
      }));

      console.log('selectedData', selectedData);

      return selectedData;
    },
  }));

  useEffect(() => {
    onChange?.(selectedShopifyIdsWithInvalidQty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShopifyIdsWithInvalidQty]);

  return (
    <div className={css.ns_com_list_select_product_and_qty}>
      <div className={css.check_all}>
        <Checkbox checked={isSelectedAll} onChange={changeSelectAll} />
        <span className={css.text}>All({qtyCount})</span>
      </div>
      <div className={css.products_items_wrapper}>
        <Checkbox.Group value={selectedShopifyIdsWithInvalidQty} onChange={changeSelectOne}>
          {data.map(({ shopify_id, cover, title, sku, qty, variant_title }) => (
            <section key={shopify_id} className={css.product_item_wrapper}>
              <span className={css.check_one_item_button_wrapper}>
                <Checkbox value={shopify_id} />
              </span>
              <div className={css.item_cover} onClick={() => navigateToProductDetail(1, 2)}>
                <Image className={css.item_cover_image} src={cover} fit="cover" lazy />
              </div>
              <div className={css.item_info_wrapper}>
                <div className={css.item_title} title={title} onClick={() => navigateToProductDetail(1, 2)}>
                  {title}
                </div>
                <div className={css.item_variant_title} title={variant_title}>
                  {variant_title}
                </div>
                <div className={css.item_sku} title={sku}>
                  {sku}
                </div>
                <div className={css.item_actions_wrapper}>
                  <div className={css.item_actions_count}>Purchased: {qty}</div>
                  <div className={css.item_actions}>
                    <Stepper
                      min={0}
                      max={qty ?? undefined}
                      digits={0}
                      value={selectedShopifyIdMapQty[shopify_id]}
                      onChange={(val) => handleQtyChange(shopify_id, val)}
                      onBlur={(e) => handleQtyBlur(shopify_id, Number(e.target.value), qty)}
                    />
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Checkbox.Group>
      </div>
    </div>
  );
});

export default App;
