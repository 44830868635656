.search_popup_comp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  z-index: 1000;
  transform: translateX(100%);

  .autocomplete_source {
    margin: 0;
    .link_item {
      display: block;
      color: #424242;
      cursor: pointer;

      &.stalled {
        color: #c7c7c7;
      }
      &[aria-selected='true'],
      &:hover {
        background-color: #f9f9f9;
      }
      .highlight_wrapper {
        line-height: 22px;
        padding-right: 6px;
        vertical-align: middle;
        font-weight: 500;
        padding: 0;
        font-size: 16px;
        mark {
          background-color: #fff;
          font-size: 16px;
          font-weight: 500;
          padding: 0;
        }
      }
      .clock_wrapper {
        padding-left: 4px;
        color: #c7c7c7;
      }
      svg {
        vertical-align: middle;
      }
    }
    &.recent_view_wrapper {
      padding-top: 8px;
      .source_name {
        color: #424242;
        font-size: var(--font-size14);
        padding-bottom: 8px;
        line-height: 1em;
      }
      .source_content {
        display: grid;
        grid-gap: 12px 12px;
      }
      .source_content_recent_labels {
        display: flex;
        padding-bottom: 8px;
        flex-flow: wrap;
        .label {
          padding: 4px 6px;
          background-color: #f5f5f5;
          border-radius: 4px;
          cursor: pointer;
          max-width: 128px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: var(--font-size14);
        }
      }
    }
  }

  .content {
    .autocomplete {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      & > form {
        margin: 0 12px;
        height: 48px;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #c7c7c7;
      }
      .panel {
        padding: 0 12px 12px;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }

  .header {
    padding: var(--spacing-l) var(--spacing-l) 0;
    margin-bottom: var(--spacing-l);
  }

  .body {
    flex: 1;
    padding: 0 var(--spacing-l) var(--spacing-l);
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    order: 3;
    position: relative;
    font-size: 16px;

    .icon {
      padding-right: 6px;
      font-size: 24px;
    }
    .input {
      flex: 1;
      appearance: none;
      color: #262627;
      font: inherit;
      height: 48px;
      width: 100%;
      background: none;
      border: 0;
      padding: 0;
      outline: none;
      &:focus-visible {
        outline: none;
      }
    }
    .search_input_suffix {
      display: flex;
      align-items: center;
    }
    .clear_button_wrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .clear_button {
        width: 16px;
        display: block;
        background: #c7c7c7;
        text-align: center;
        cursor: pointer;
        height: 16px;
        line-height: 16px;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
      }
    }
    .cancel_button {
      width: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    &.show {
      bottom: 0;
      .content {
        height: 100%;
        .autocomplete {
          height: 100%;
          .panel {
            flex: 1;
          }
        }
      }

      .autocomplete_source {
        .link_item {
          margin-top: 8px;
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    background-color: var(--color-white);
    z-index: 1000;
    transform: none !important;
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    opacity: 0;
    pointer-events: none;

    &.show {
      pointer-events: auto;
    }

    .search_bar {
      --height: 38px;

      :global {
        .adm-search-bar-suffix {
          display: none;
        }
      }
    }
    .content {
      .autocomplete {
        & > form {
          height: 40px;
        }
      }
      .panel {
        max-height: 400px;
      }
    }

    .autocomplete_source {
      &.recent_view_wrapper {
        .source_content {
          display: grid;
          grid-gap: 12px 12px;
          grid-template-columns: repeat(3, 1fr);
        }
      }
      .link_item {
        border: none;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    .input_wrapper {
      .input {
        height: 44px;
      }
      .icon {
        font-size: 16px;
      }
    }
  }
}
