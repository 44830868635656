.ns_com_nav_bar_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  a {
    color: var(--color-black);
  }
  .notice_bar {
    @at-root [data-notice-bar-active] {
      :global {
        .app-layout {
          --app-layout-padding-top: calc(var(--nav-bar-mobile-height) + var(--notice-bar-height));
        }
      }
    }
  }

  .mobile {
    --icon-size: 24px;
    display: flex;
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: var(--nav-bar-mobile-height);
    padding: 0 var(--spacing-l);
    background: var(--color-white);
    border-bottom: 1px solid #c7c7c7;

    .left,
    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-width: 64px;
      gap: var(--spacing-s);
    }
    .right {
      justify-content: flex-end;
    }

    .logo {
      width: auto;
      height: var(--icon-size);
    }

    .icon_btn {
      position: relative;
      font-size: var(--icon-size);
      color: var(--color-black);
      line-height: 0;
      user-select: none;
      cursor: pointer;

      // extend button touchable area
      &::after {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
      }
    }
  }

  .desktop {
    display: none;
    order: 2;
    padding: 24px 48px 12px;
    height: var(--nav-bar-desktop-height);
    background: var(--color-white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    .main {
      height: 38px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .main_item {
        &:not(:first-child) {
          margin-left: var(--spacing-xxl);
        }

        &:nth-of-type(2) {
          flex: 1;
          margin-right: calc(var(--spacing-xxl) * 3);
          margin-left: calc(var(--spacing-xxl) * 3);
        }

        &:last-child {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-left: auto;

          & > * + * {
            margin-left: 20px;
          }

          .icon_btn {
            line-height: 0;
            font-size: 20px;
          }
        }
      }
      &.new_pc_nav_menu {
        padding: 24px 0 0;
        .main {
          padding: 0 48px;
        }
      }
    }

    .logo {
      width: auto;
      height: 28px;
    }

    .search_bar {
      --height: 38px;
      position: relative;
      width: 100%;
      max-width: 380px;
    }

    .nav_menu_bar {
      display: block;
      margin: var(--spacing-xxl) auto 0;
      width: max-content;
      min-width: max-content;
      max-width: 100%;
      height: 18px;
    }
  }
  @media screen and (min-width: 769px) {
    .notice_bar {
      @at-root [data-notice-bar-active] {
        :global {
          .app-layout {
            --app-layout-padding-top: calc(var(--nav-bar-desktop-height) + var(--notice-bar-height));
          }
        }
      }
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
}
