import { useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { useMedia } from 'react-use';

import Button from '@/components/Button';
import Modal from '@/components/Modal';

import css from './Prompt.module.scss';

const Prompt = (props: { when?: boolean }) => {
  const { when = false } = props;
  const { navigator } = useContext<any>(UNSAFE_NavigationContext);
  const [nextLocation, setNextLocation] = useState(null);
  const [action, setAction] = useState();
  const [visible, setVisible] = useState(false);
  const [unblock, setUnblock] = useState(null);
  const isDesktop = useMedia('(min-width: 769px)');

  useEffect(() => {
    if (!when) {
      unblock?.();
    }
    if (!when || unblock) {
      return;
    }
    const cancel = navigator.block(({ action: nextAction, location }) => {
      if (when) {
        setVisible(true);
        setNextLocation(location);
        setAction(nextAction);
      }
      return false;
    });

    setUnblock(() => cancel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when, unblock]);

  useEffect(
    () => () => {
      unblock && unblock();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onConfirm = () => {
    unblock && unblock();
    if (action === 'PUSH') {
      navigator.push(nextLocation);
    } else if (action === 'POP') {
      navigator.go(-1);
    } else if (action === 'REPLACE') {
      navigator.replace(nextLocation);
    }
    setVisible(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      className={css.ns_com_price_rules_modal_main}
      title="You have unsaved changes"
      visible={visible}
      onClose={onClose}
      closable={isDesktop}
      footer={
        <>
          <Button onClick={onClose}>Keep Editing</Button>
          <Button onClick={onConfirm}>Leave</Button>
        </>
      }
    >
      {isDesktop ? 'Leaving without saving will delete all unsaved changes' : 'You have unsaved changes'}
    </Modal>
  );
};

export default Prompt;
