.ns_com_form_item_editor_main {
  font-size: var(--font-size16);

  :global {
    p {
      margin: 0;
      line-height: 1.4;
    }
  }

  @at-root :global {
    .ck.ck-balloon-panel {
      z-index: 3000 !important;
    }
  }
}
