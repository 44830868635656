import { useInfiniteQuery } from 'react-query';

import { getSearchProducts } from '@/services/ds';

export default function useSearchProducts(params: any, enabled?: boolean) {
  return useInfiniteQuery(
    ['products', 'getSearchProducts', params],
    ({ pageParam = 1 }) => getSearchProducts({ ...params, page: pageParam }),
    {
      enabled,
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
    }
  );
}
