import _ from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { getStoreImportedProducts } from '@/services/ds';

export default function useStoreImportedProducts(shopId?: string, params?: any) {
  return useInfiniteQuery(
    ['store', 'getStoreImportedProducts', shopId, params],
    ({ pageParam = 1 }) => {
      if (_.isNil(shopId)) throw new Error('missing store shopId');
      return getStoreImportedProducts(shopId, { ...params, page: pageParam });
    },
    {
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
    }
  );
}
