import { Form, Input, message } from 'antd';
import cn from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import PhoneInput from '@/components/PhoneInput';
import { useAuth } from '@/hooks/auth';
import useAccountPhoneSendCode from '@/hooks/queries/useAccountPhoneSendCode';
import useAccountPhoneVerifyCode from '@/hooks/queries/useAccountPhoneVerifyCode';

import css from './index.module.scss';

export type ComponentHandle = {
  show: () => void;
  hide: () => void;
};

enum Step {
  Phone,
  PhoneCode,
}

const ModalChangePhone = forwardRef<ComponentHandle>((props, ref) => {
  const { user } = useAuth();
  const [step, setStep] = useState(Step.Phone);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [newPhoneCountry, setNewPhoneCountry] = useState('');
  const accountPhoneSendCode = useAccountPhoneSendCode();
  const accountPhoneVerifyCode = useAccountPhoneVerifyCode();
  const isEmailLoginEnabled = !!user?.has_password;
  const phone = `${user?.phone_country || ''}${user?.phone || ''}`;
  const email = user?.email || user?.email_pending || null;

  const reset = () => {
    form.resetFields();
    setStep(Step.Phone);
  };
  const handleShow = () => {
    setVisible(true);
  };
  const handleHide = () => {
    setVisible(false);
  };
  const onContinue = () => {
    form.validateFields().then((values) => {
      if (step === Step.Phone) {
        accountPhoneSendCode.mutate(values, {
          onSuccess() {
            setNewPhone(values.phone);
            setNewPhoneCountry(values.phone_country);
            setStep(Step.PhoneCode);
          },
          onError(e: Error) {
            message.error(e.message || 'Failed.');
          },
        });
      }
    });
  };
  const onSubmit = () => {
    form.validateFields().then((values) => {
      accountPhoneVerifyCode.mutate(
        {
          ...values,
          ...{
            phone_country: newPhoneCountry,
            phone: newPhone,
          },
        },
        {
          onSuccess() {
            handleHide();
            message.success('Phone Number changed!');
          },
          onError(e: Error) {
            message.error(e.message || 'Failed');
          },
        }
      );
    });
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  useEffect(() => {
    if (!visible) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      className={css.modal_change_phone}
      visible={visible}
      onClose={handleHide}
      title="Change Phone Number"
      fullscreen="mobile"
    >
      <Form
        form={form}
        initialValues={{
          phone_country: user?.phone_country,
        }}
      >
        {step === Step.Phone && (
          <section className={cn(css.step, { [css.email_login_enabled]: isEmailLoginEnabled })}>
            {isEmailLoginEnabled && (
              <>
                <div className={cn(css.desc, css.password)}>
                  Enter your current password to <span>{email}</span>
                </div>
                <Form.Item name="password" rules={[{ required: true }]} className={css.form_item}>
                  <Input.Password
                    maxLength={50}
                    size="large"
                    iconRender={(isVisible) => (isVisible ? <FiEye /> : <FiEyeOff />)}
                  />
                </Form.Item>
              </>
            )}
            <div className={cn(css.desc, css.phone)}>
              Enter a new phone number to replace your current login <span>{phone}</span>
            </div>
            <Form.Item name="phone_country" hidden>
              <input type="hidden" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[{ required: true, pattern: /^[0-9]+$/ }]}
              getValueFromEvent={(e) => {
                form.setFieldsValue({ phone_country: `+${e.dialCode}` });
                return e?.number;
              }}
              getValueProps={(value) => ({
                value,
                defaultdialcode: (user?.phone_country ?? user?.phone_country)?.slice(1),
              })}
              className={css.form_item}
              validateTrigger="onFormFinish"
            >
              <PhoneInput className={css.phone_input} format={false} formatOnInit={false} />
            </Form.Item>
            <Button color="main" shape="rounded" onClick={onContinue} loading={accountPhoneSendCode.isLoading}>
              Continue
            </Button>
          </section>
        )}
        {step === Step.PhoneCode && (
          <section className={css.step}>
            <div className={cn(css.desc, css.code)}>
              <div>
                Enter the 6-digit code sent to <span>{`${newPhoneCountry || ''}${newPhone}`}</span>.
              </div>
              <div>Please enter it within 10 minutes</div>
            </div>
            <Form.Item
              name="code"
              rules={[{ required: true, pattern: /^[0-9]+$/ }]}
              className={cn(css.form_item, css.form_item_code)}
            >
              <Input size="large" className={css.code} type="tel" maxLength={6} placeholder="000000" />
            </Form.Item>
            <Button color="main" shape="rounded" onClick={onSubmit} loading={accountPhoneVerifyCode.isLoading}>
              Continue
            </Button>
          </section>
        )}
      </Form>
    </Modal>
  );
});

export default ModalChangePhone;
