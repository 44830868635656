.order_live_selling_modal_comp {
  .desc {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  :global {
    .modal__body {
      max-width: var(--content-small-max-width);
    }

    .modal__footer {
      text-align: center;
      button {
        width: 250px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .order_live_selling_modal_content {
      width: 500px;
      margin: 0 auto;
    }
    :global {
      .modal__body {
        max-height: 520px;
      }
    }
  }
}
