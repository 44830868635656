import { useLockScroll } from 'antd-mobile/es/utils/use-lock-scroll';
import cn from 'classnames';
import { motion, Variants } from 'framer-motion';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useMedia, usePrevious } from 'react-use';

import css from './index.module.scss';

const maskVariants: Variants = {
  show: {
    opacity: 1,
    transition: { from: 0, type: 'tween' },
  },
  hide: {
    opacity: 0,
    transition: { from: 1, type: 'tween' },
  },
};

const wrapperVariantsFromBottom: Variants = {
  show: {
    y: '0%',
    transition: { from: '130%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    y: '130%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

const wrapperVariantsFromRight: Variants = {
  show: {
    x: '0%',
    transition: { from: '100%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    x: '100%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  visible?: boolean;
  title?: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerActions?: { className?: string; icon: React.ReactNode; onClick: () => void }[];
  footer?: React.ReactNode;
  fullscreen?: boolean | 'mobile';
  forceRender?: boolean;
  showBack?: boolean;
  placement?: 'center' | 'topRight' | 'bottom';
  showAnimation?: boolean | 'mobile';
  showMountAnimation?: boolean;
  zIndex?: number;
  bordered?: boolean;
  closable?: boolean;
  closeIcon?: React.ReactNode;
  showMask?: boolean;
  maskClosable?: boolean;
  onBack?: () => void;
  backUrl?: string;
  onClose?: () => void;
  afterShow?: () => void;
  afterClose?: () => void;
  children: React.ReactNode;
}

function Modal({
  className,
  style,
  containerStyle,
  visible,
  title,
  headerLeft,
  headerRight,
  headerActions,
  footer,
  fullscreen,
  forceRender,
  showBack = false,
  placement = 'center',
  showAnimation = true,
  showMountAnimation = false,
  zIndex = 1000,
  bordered = true,
  closable = true,
  closeIcon,
  showMask = true,
  maskClosable = true,
  onBack,
  backUrl,
  onClose,
  afterShow,
  afterClose,
  children,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const prevVisible = usePrevious(visible);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMedia('(min-width: 769px)');
  const enableAnimation = showAnimation === 'mobile' ? !isDesktop : showAnimation;

  let wrapperVariants = wrapperVariantsFromBottom;
  if (_.includes(placement, 'topRight')) wrapperVariants = wrapperVariantsFromRight;

  // lock the page scroll when the popup is visible
  useLockScroll(scrollableRef, !!visible);

  useEffect(() => {
    if (enableAnimation || visible === prevVisible) return;
    visible ? afterShow?.() : afterClose?.();
  }, [visible, prevVisible, enableAnimation, afterShow, afterClose]);

  const headerLeftElement = useMemo(() => {
    if (headerLeft) return headerLeft;
    if (!showBack) return null;
    return (
      <div
        className={cn(css.icon_btn, 'modal__icon-btn', 'modal__back-btn')}
        onClick={onBack ?? (() => (backUrl ? navigate(backUrl) : navigate(-1)))}
      >
        <FiChevronLeft strokeWidth="1.5" />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backUrl, headerLeft, onBack, showBack]);

  return createPortal(
    <motion.div
      className={cn(css.ns_com_modal_main, 'modal', className, {
        [css.show]: !!visible,
        [css.animated]: enableAnimation,
        [css.bordered]: bordered,
        [css.fullscreen]: fullscreen === true,
        [css.fullscreen_mobile]: fullscreen === 'mobile',
        [css.page_mode]: showBack,
        [css.no_footer]: _.isEmpty(footer),
        [`${css[`placement_${placement}`]} modal--placement-${placement}`]: placement,
      })}
      style={style}
      initial={enableAnimation && showMountAnimation ? 'hide' : false}
      // eslint-disable-next-line no-nested-ternary
      animate={enableAnimation ? (visible ? 'show' : 'hide') : false}
      onAnimationComplete={(definition) => {
        if (definition === 'show') {
          afterShow?.();
        } else {
          afterClose?.();
        }
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {showMask && (
        <motion.div
          className={css.mask}
          style={{ zIndex }}
          variants={enableAnimation ? maskVariants : undefined}
          onClick={maskClosable ? onClose : undefined}
        />
      )}
      <motion.div
        ref={scrollableRef}
        className={css.wrapper}
        style={{ zIndex, ...containerStyle }}
        variants={enableAnimation ? wrapperVariants : undefined}
      >
        <div className={cn(css.container, 'modal__container')}>
          <div className={cn(css.header, 'modal__header')}>
            <div className={css.header_left}>{headerLeftElement}</div>
            <div className={cn(css.title, 'modal__title')}>{title}</div>
            <div className={css.header_right}>
              {headerRight ||
                (_.isEmpty(headerActions)
                  ? null
                  : headerActions?.map((action, index) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={cn(css.icon_btn, 'modal__icon-btn', action?.className)}
                        onClick={action.onClick}
                      >
                        {action.icon}
                      </div>
                    )))}
              {closable && (
                <div
                  className={cn(css.icon_btn, css.close_btn, 'modal__icon-btn', 'modal__close-btn')}
                  onClick={onClose}
                >
                  {closeIcon || <FiX strokeWidth="1.5" />}
                </div>
              )}
            </div>
          </div>
          <div className={cn(css.body, 'modal__body')}>{forceRender ? children : visible && children}</div>
          {footer && <div className={cn(css.footer, 'modal__footer')}>{footer}</div>}
        </div>
      </motion.div>
    </motion.div>,
    document.body
  );
}

export default Modal;
