.ns_com_check_inventory_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: var(--content-max-width);
  margin: 0 auto;
  padding: 36px 12px;
  box-sizing: content-box;

  .check_inventory_title {
    padding: 0;
    margin: 0;
    font-size: 30px;
    line-height: 115%;
    color: #000000;
  }

  .input_wrapper {
    width: 560px;
    border-radius: 99px;
    border-color: #999;
    border-width: 2px;
    text-align: center;
    input {
      text-align: center;
    }

    &.is_error {
      border-color: #ff5050;
    }
  }

  .check_inv_error {
    color: #ff5050;
    height: 20px;
    text-align: center;
    margin: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }

  .clear_btn {
    cursor: pointer;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #999999;
    text-align: center;
  }

  .status_view_renderer_wrapper {
    width: 100%;
    .inventory_list_wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
