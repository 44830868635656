.ns_com_product_gallery_main {
  --swiper-height: 400px; // 移动端固定高度
  --thumbs-size: 54px;
  position: relative;

  // 大图 swiper
  .swiper_wrapper {
    --swiper-navigation-color: var(--color-black);
    --swiper-navigation-size: 12px;

    .gallery_image {
      --height: var(--swiper-height);
      img {
        object-position: top;
      }
    }

    :global {
      .swiper-slide {
        width: 100%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        visibility: hidden;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        background: var(--color-white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.2);
        }
      }

      .swiper-button-disabled {
        cursor: not-allowed;
        pointer-events: auto;
      }

      .swiper-pagination-fraction {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        left: auto;
        bottom: auto;
        right: 10px;
        top: 10px;
        font-size: 12px;
        line-height: 1;
        border-radius: 99px;
        background: var(--color-white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        &-inner {
          transform: scale(0.8);
        }
      }
    }

    &:hover {
      :global {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }
  }

  // 小图 swiper
  .product_gallery_swiper_thumbs_wrapper {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: var(--thumbs-size);
    height: 100%;

    .swiper-slide {
      width: var(--thumbs-size);
      height: var(--thumbs-size);
      cursor: pointer;

      &-thumb-active {
        border: 2px solid var(--color-black);
      }
    }

    .img_wrapper {
      --width: 100%;
      --height: 100%;
    }
  }

  .out_of_stock_label {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
    padding: var(--spacing-l);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    color: var(--color-white);
    font-size: var(--font-size3);
    line-height: 1.4;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.8);
  }

  .product_label {
    z-index: 2;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    background-color: rgba(242, 242, 242, 0.85);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 5px 15px;
  }

  .shipping_label {
    z-index: 2;
    position: absolute;
    top: var(--spacing-m);
    left: var(--spacing-m);
  }

  @media screen and (min-width: 769px) {
    --swiper-height: 760px;
    padding-left: calc(var(--thumbs-size) + var(--spacing-xs));
    .swiper_wrapper {
      :global {
        .swiper-slide {
          width: 100%;
          height: 100%;
        }
      }
    }

    .product_gallery_swiper_thumbs_wrapper {
      display: block;
    }

    .product_label {
      padding: 4px 36px;
      font-size: 14px;
    }
  }
}
