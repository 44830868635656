.ns_page_price_rule_mobile_main {
  .main {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
  .store_select {
    margin: var(--spacing-l) 0;
  }
  .form_wrapper {
    color: var(--color-gray5);
    font-size: 16px;
    line-height: 1.4;
  }
  .form_item_section {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    margin-top: 16px;
    .header {
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      .padding {
        padding: 8px 0;
      }
      .left {
        padding-left: 12px;
      }
      .right {
        padding-right: 12px;
      }
      .form_item_section_title {
        font-size: 16px;
        font-weight: 500;
        color: #000;
      }
      .hint {
        color: #c7c7c7;
        align-items: center;
        display: flex;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .body {
      padding: 12px 12px 0;
      .right {
        flex: 1;
        display: block;
      }
      .text {
        color: #000;
        font-weight: 500;
      }
      .plus_or_x_wrapper {
        padding: 0 18px 0 12px;
      }
      .desc {
        font-size: 14px;
        padding: 10px 0;
        color: var(--color-gray4);
        border-bottom: 1px solid #e8e8e8;
        line-height: 18px;
      }
    }
    .footer {
      padding: 10px 12px 12px;
      .assign_cents_wrapper_title {
        padding: 0 6px 0 12px;
      }
      .hint_wrapper {
        color: #c7c7c7;
        height: 100%;
        padding: 0 6px;
        display: flex;
        align-items: center;
      }
      .price_demo_wrapper {
        padding: 0 6px 0 12px;
        font-size: 16px;
        color: #333;
      }
      .price_assign_cents_arg {
        width: 50px;
      }
      :global {
        .ant-input-number {
          width: 60px;
        }
      }
    }
  }
  .save_btn_wrapper {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    padding: 12px 50px 30px;
  }
}
.ns_page_price_rule_mobile_main_active {
  .ns_or_page_price_rule_mobile_popover {
    :global {
      .ant-tooltip-inner {
        color: var(--color-gray5);
      }
    }
  }
  :global {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height) !important;
        --app-layout-padding-bottom: 0 !important;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }
}
