import {
  ActionList,
  AlphaCard,
  AlphaStack,
  Badge,
  Button,
  Frame,
  Icon,
  Inline,
  Page,
  Popover,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  Toast,
} from '@shopify/polaris';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { ORIGIN_URLS } from '@/constants/app';
import useCurrentStore from '@/hooks/queries/useCurrentStore';
import useStores from '@/hooks/queries/useStores';
import useUnbindStore from '@/hooks/queries/useUnbindStore';
import useDebounce from '@/hooks/useDebounce';

import NoStore from '../NoStore';
import Skeleton from '../Skeleton/List';
import css from './index.module.scss';

const resourceName = {
  singular: 'Store',
  plural: 'Stores',
};

function StoreList() {
  const currentStore = useCurrentStore();
  const currentStoreId = currentStore?.data?.id;

  const stores = useStores(_.isEmpty(1));

  // 用输入值，过滤store

  // 由于disconnect接口成功后，shops列表并没有立即更新，前端需要设置一个定时器，6s后重新获取shop列表
  const [waitShops, setWaitShops] = useState(false);
  const unbindStore = useUnbindStore();
  const timerRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const lastInputValue = useDebounce(inputValue, 200);

  const storesDataOrigin = useMemo(() => stores?.data?.data?.shops || [], [stores?.data?.data?.shops]);
  const hasStores = !!storesDataOrigin.length;
  const storesData = useMemo(() => {
    if (!lastInputValue) return storesDataOrigin;
    return storesDataOrigin.filter(({ name }) => name.indexOf(lastInputValue) > -1);
  }, [storesDataOrigin, lastInputValue]);

  const isPageLoading = stores.isLoading || stores.isRefetching;
  const isButtonLoading = waitShops || unbindStore.isLoading;

  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [activeSuccessToast, setActiveSuccessToast] = useState(false);

  const toggleActiveSuccessToast = useCallback(() => setActiveSuccessToast((activeState) => !activeState), []);
  const toggleActive = useCallback(() => setActive((activeState) => !activeState), []);

  const toastMarkup = active && toastMessage ? <Toast content={toastMessage} error onDismiss={toggleActive} /> : null;
  const toastSuccessMarkup = activeSuccessToast ? (
    <Toast content="Disconnect Store Success!" onDismiss={toggleActive} />
  ) : null;

  const setTimer = () => {
    const timer = setTimeout(() => {
      stores.refetch();
      setWaitShops(false);
      toggleActiveSuccessToast();
    }, 6000);
    timerRef.current = timer;
  };

  const handleUnbindStore = (id: string) => {
    setWaitShops(true);
    unbindStore.mutate(
      { shop_id: id },
      {
        onSuccess: () => {
          setTimer();
        },
        onError: (error) => {
          setWaitShops(false);
          setActive(true);
          setToastMessage((error as Error)?.message || 'Disconnect Store Failure!');
        },
      }
    );
  };

  const handleInputChange = useCallback((value: string) => setInputValue(value), []);

  const [popoverActive, setPopoverActive] = useState<number>();

  const renderItem = (item: typeof storesDataOrigin[number]) => {
    const { id, name, shopify_domain } = item;

    return (
      <ResourceItem id={id}>
        <Inline wrap={false} blockAlign="center">
          <div style={{ flex: 1 }}>
            <Link
              to={`/stores/${id}`}
              onClick={() => {
                currentStore.setCurrentStore(id);
              }}
            >
              <Inline gap="2" blockAlign="center">
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                {currentStoreId === id && <Badge>Current</Badge>}
              </Inline>
              <Text as="p" color="subdued">
                {shopify_domain}
              </Text>
            </Link>
          </div>
          <Popover
            active={popoverActive === id}
            activator={
              <Button
                onClick={() => setPopoverActive(id)}
                plain
                removeUnderline
                icon={<Icon source={HorizontalDotsMinor} color="base" />}
              />
            }
            autofocusTarget="first-node"
            onClose={() => setPopoverActive(null)}
          >
            <ActionList
              actionRole="menuitem"
              items={[{ destructive: true, content: 'Disconnect', onAction: () => handleUnbindStore(id) }]}
            />
          </Popover>
        </Inline>
      </ResourceItem>
    );
  };

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  useEffect(() => {
    if (!active) {
      setToastMessage('');
    }
  }, [active]);

  if (isPageLoading) {
    return <Skeleton />;
  }

  return (
    <Frame>
      <Page
        title={resourceName.plural}
        primaryAction={
          <Button primary url={ORIGIN_URLS.installApp}>
            {hasStores ? 'Connect another store' : 'Connect a store'}
          </Button>
        }
      >
        <Helmet>
          <html lang="en" className={css.ns_or_stores_main_active} />
          <title>{resourceName.plural}</title>
        </Helmet>
        {hasStores ? (
          <AlphaStack gap="5">
            <AlphaCard roundedAbove="xl">
              <TextField
                placeholder="Search Store"
                value={inputValue}
                onChange={handleInputChange}
                label=""
                autoComplete="off"
                clearButton
                onClearButtonClick={() => handleInputChange('')}
              />
            </AlphaCard>
            <AlphaCard padding="0" roundedAbove="xl">
              <ResourceList resourceName={resourceName} items={storesData} renderItem={renderItem} />
            </AlphaCard>
          </AlphaStack>
        ) : (
          <NoStore />
        )}
        {toastMarkup}
        {toastSuccessMarkup}
      </Page>
    </Frame>
  );
}

export default StoreList;
