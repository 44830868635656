.ns_com_notification_main {
  .wrapper {
    position: fixed;
    display: grid;
    place-items: start end;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    pointer-events: none;
  }
  &.placement_topRight .wrapper {
    place-items: start end;
    transform: translateX(100%);
  }
  &.placement_bottom .wrapper {
    place-items: end center;
    transform: translateY(100%);
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 var(--spacing-l);
    width: calc(100% - var(--spacing-l) * 2);
    min-width: 0;
    border-radius: 6px;
    background: var(--color-white);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    pointer-events: auto;

    @media screen and (min-width: 769px) {
      width: auto;
      min-width: 360px;
      max-width: 520px;
    }
  }
  &.black .container {
    background: rgba(51, 51, 51, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.shape_default .container {
    border-radius: 6px;
  }
  &.shape_rounded .container {
    border-radius: 9999px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: var(--spacing-l) var(--spacing-l) var(--spacing-xs);
  }

  .icon {
    --size: 32px;
    margin-right: var(--spacing-l);
  }

  .message {
    flex: 1;
    min-width: 0;
    text-align: center;
  }
  &.has_icon .message {
    text-align: left;
  }

  .title {
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.black .title {
    color: var(--color-white);
  }

  .description {
    color: var(--color-gray5);
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;
  }
  &.black .description {
    color: var(--color-white);
  }

  .close_btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    padding: var(--spacing-s);
    line-height: 0;
    color: var(--color-gray5);
    cursor: pointer;

    :global {
      .react-icons {
        font-size: 24px;
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  &.black .close_btn {
    color: var(--color-white);
  }

  .body {
    padding: 0 var(--spacing-l) var(--spacing-l);
  }

  .footer {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: var(--spacing-m);
    place-items: center stretch;
    padding: var(--spacing-m) var(--spacing-l) var(--spacing-l);

    &:empty {
      display: none;
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: 0;
    border-radius: 0 0 6px 6px;
    overflow: hidden;

    .progress_text {
      padding: 0 var(--spacing-l);
      color: var(--color-primary);
      font-size: 12px;
      line-height: 1.4;
      margin-bottom: var(--spacing-xxs);
    }

    .progress_bar {
      margin: 0 -2px;
      width: calc(100% + 4px);
      height: 6px;
      line-height: 0;

      .ant-progress-bg {
        height: 6px !important;
      }
    }
  }
  &.black .progress .ant-progress-inner {
    background-color: #414141;
  }
}
