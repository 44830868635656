.ns_com_sold_out_error_main {
  box-shadow: 0 2px 10px #0000000f;
  background-color: #f4909033;
  padding: var(--spacing-xs) var(--spacing-s);
  margin-top: var(--spacing-l);
  border-radius: 8px;
  line-height: 1.4;

  .sold_out_error_line,
  .sold_out_error_link {
    color: red !important;
  }

  .sold_out_error_link {
    text-decoration: underline;
  }
}
