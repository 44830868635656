$prefixCls: inventory-location-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__desc {
    font-size: 14px;
    color: #999999;
    line-height: 1.3em;
    padding: 12px;
    &.contact {
      color: #000;
    }
  }

  &__address_list {
    &_header {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3em;
      padding: 10px 12px;
    }
    &_item {
      padding: 12px 0;
      background: #fff;
      &:not(:last-child) {
        border-bottom: 1px solid #e8e8e8;
      }

      &_icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #999;
        &.icon_checked {
          border-color: #424242;
          position: relative;
          &::after {
            position: absolute;
            display: block;
            content: '';
            width: 12px;
            height: 12px;
            background: #424242;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
          }
        }
      }
      &_content {
        font-size: 16px;
        line-height: 1.4em;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }

  &__empty {
    padding: var(--spacing-l);
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;

    p {
      margin: 0;
    }

    .button {
      margin-top: 38px;
    }
  }

  &__footer {
    width: 100%;
    height: 68px;
    z-index: 1;
    padding: var(--spacing-l);
    background: var(--color-white);
    padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 4px #00000040;
  }

  &__store-select_wrapper {
    padding: 0 12px;
  }

  &__store-select {
    margin-bottom: var(--spacing-l);
  }

  &__locations_card_wrapper {
    margin-top: 12px;
  }

  &__card {
    background-color: #fff;
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      height: 40px;

      &-left {
        font-size: var(--font-size3);
        line-height: 1.4em;
        font-weight: 500;
      }
    }

    &_body {
      border-top: 1px solid #e8e8e8;
      padding: 12px;
      &.locations {
        padding: 0 12px;
      }
      &-desc {
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 1.3em;
        color: #000;

        &.desc {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        &-grey {
          color: #999;
          font-size: var(--font-size14);
          line-height: 1.3em;

          &.hint {
            padding-top: 8px;
          }
        }

        &-wrapper {
          padding-top: 12px;
          margin-top: 12px;
          border-top: 1px solid #e8e8e8;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 0 0 var(--spacing-l);
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
    }

    &__title {
      display: block;
    }

    &__desc {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    &__address_list {
      &_header {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__footer {
      position: sticky;
      background-color: transparent;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      align-items: center;
      button {
        width: 250px;
      }
    }

    &__store-select_wrapper {
      padding: 0;
    }
  }
}
