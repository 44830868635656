import { AlphaCard, AlphaStack, Button, Icon, Inline, Page, ResourceItem, ResourceList, Text } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useStores from '@/hooks/queries/useStores';

import Skeleton from '../Skeleton/Detail';
import { getLinks, getSubMenuLists } from '../util';
import css from './index.module.scss';

const empty = () => null;

function StoreDetail() {
  const { id: paramID } = useParams();
  const navigate = useNavigate();
  const stores = useStores();
  const storesData = stores?.data?.data?.shops || [];
  const storeData = storesData.find((store) => store?.id === paramID) || {};
  const { name, shopify_domain, id } = storeData;
  const links = getLinks(id);
  const submenu = getSubMenuLists(id);
  const handleBack = () => navigate(-1);
  const isJennifer = !isEmpty(storeData.jennifer);

  if (stores.isLoading) {
    return <Skeleton />;
  }

  return (
    <Page title="Store Info" backAction={{ onAction: handleBack }}>
      <Helmet>
        <html lang="en" className={css.ns_or_store_detail_active} />
        <title>Store</title>
      </Helmet>

      <AlphaStack>
        <AlphaCard roundedAbove="xl">
          <Inline gap="025" wrap={false} blockAlign="center">
            <div style={{ flex: 1 }}>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {name}
              </Text>
              <p>{shopify_domain}</p>
            </div>
            <div>
              <Button plain monochrome onClick={handleBack}>
                Change
              </Button>
            </div>
          </Inline>
        </AlphaCard>

        <div
          style={{
            height: '20px',
          }}
        />

        {/* Retailer list */}
        <AlphaCard padding="0" roundedAbove="xl">
          <p
            style={{
              backgroundColor: '#F2F2F6',
              paddingLeft: '20px',
            }}
          >
            Dropship from kiwi
          </p>
          <ResourceList
            items={links}
            renderItem={({ title, to }) => (
              <ResourceItem id={title} onClick={empty}>
                <Inline wrap={false} blockAlign="center">
                  <Link className={css.store_detail_links_item_text} to={to}>
                    <Text as="p" fontWeight="semibold">
                      {title}
                    </Text>
                  </Link>
                  <Icon source={ChevronRightMinor} color="base" />
                </Inline>
              </ResourceItem>
            )}
          />
        </AlphaCard>
        {/* supplier list */}
        {isJennifer ? (
          <AlphaCard padding="0" roundedAbove="xl">
            <p
              style={{
                backgroundColor: '#FAE9E9',
                paddingLeft: '20px',
              }}
            >
              Sell on kiwi
            </p>
            <ResourceList
              items={submenu}
              renderItem={({ title, to }) => (
                <ResourceItem id={title} onClick={empty}>
                  <Inline wrap={false} blockAlign="center">
                    <Link className={css.store_detail_links_item_text} to={to}>
                      <Text as="p" fontWeight="semibold">
                        {title}
                      </Text>
                    </Link>
                    <Icon source={ChevronRightMinor} color="base" />
                  </Inline>
                </ResourceItem>
              )}
            />
          </AlphaCard>
        ) : null}
      </AlphaStack>
    </Page>
  );
}

export default StoreDetail;
