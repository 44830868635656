import { AlphaCard, AlphaStack, Box, Inline, Page, Pagination, TextField } from '@shopify/polaris';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import usePrivateInventoryTrans from '@/hooks/queries/usePrivateInventoryTrans';
import useDebounce from '@/hooks/useDebounce';

import css from './index.module.scss';
import ListView from './ListView';
import Skeleton from './Skeleton';

const pageSize = 10;

const Detail = () => {
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedValue = useDebounce(inputValue, 500);
  const pageRef = useRef(null);
  const { variantID } = useParams();
  const variantIDP = Number(variantID);
  const privateInventoryTransRQ = usePrivateInventoryTrans({
    pagination: { page: currentPage, page_size: pageSize },
    filters: {
      keyword: debouncedValue,
      variant_id: variantIDP,
    },
  });

  const dataOrigin = useMemo(
    () => _.compact(privateInventoryTransRQ?.data?.pages?.flatMap((page) => page?.data?.items)),
    [privateInventoryTransRQ?.data]
  );
  const pagination = useMemo(
    () => privateInventoryTransRQ?.data?.pages?.[0]?.data?.pagination,
    [privateInventoryTransRQ?.data?.pages]
  );

  const currentProduct = useMemo(() => dataOrigin?.[0]?.product, [dataOrigin]);
  const currentVariant = useMemo(
    () => dataOrigin?.[0]?.product?.product?.variants?.find((v) => variantIDP === v?.id) || {},
    [dataOrigin, variantIDP]
  );

  const handleInputChange = useCallback((val) => {
    setCurrentPage(1);
    setInputValue(val);
  }, []);

  const next = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const prev = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    if (privateInventoryTransRQ.isFetched) {
      pageRef.current = true;
    }
  }, [privateInventoryTransRQ.isFetched]);

  if (!pageRef.current && privateInventoryTransRQ.isLoading) {
    return <Skeleton />;
  }

  return (
    <Page
      title={currentProduct?.product?.title}
      backAction={{ content: 'Private Inventory', url: '/private-inv' }}
      subtitle={`${currentVariant?.title} - ${currentVariant?.sku}`}
    >
      <Helmet>
        <html lang="en" className={css.ns_or_payment_methods_main_active} />
        <title>Private Inventory Transactions</title>
      </Helmet>
      <Box paddingBlockEnd="3">
        <AlphaStack gap="5">
          <AlphaCard padding="0" roundedAbove="xl">
            <Box padding="4">
              <TextField
                placeholder="Order name / Description"
                value={inputValue}
                onChange={handleInputChange}
                label=""
                autoComplete="off"
                clearButton
                onClearButtonClick={() => handleInputChange('')}
              />
            </Box>
            <ListView data={dataOrigin} loading={privateInventoryTransRQ.isLoading} />
            <Box padding="4">
              <Inline align="center">
                <Pagination
                  hasPrevious={pagination?.has_prev}
                  onPrevious={prev}
                  hasNext={pagination?.has_next}
                  onNext={next}
                />
              </Inline>
            </Box>
          </AlphaCard>
        </AlphaStack>
      </Box>
    </Page>
  );
};

export default Detail;
