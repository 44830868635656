import _ from 'lodash';
import { useMemo } from 'react';

import Checkbox from '@/components/Checkbox';
import Image from '@/components/Image';

import css from './CollectionListItem.module.scss';

const CollectionListItem = ({
  data,
  onChange,
}: {
  data: any;
  onChange?: (checked: boolean, item: any) => void;
}): JSX.Element => {
  // the maximum number of images to be shown is 3, and less than 3 will be left blank
  const shownImages: (string | undefined)[] = useMemo(
    () => _.times(3, (n) => data?.product_images?.[n]),
    [data?.product_images]
  );

  return (
    <div className={css.collection_list_item}>
      <div className={css.images}>
        {shownImages.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Image key={index} src={image ?? ''} fit="cover" />
        ))}
      </div>
      <div className={css.info}>
        <div className={css.title}>{data?.title}</div>
      </div>
      <div className={css.actions}>
        <Checkbox value={data?.id} onChange={(checked) => onChange?.(checked, data)} />
      </div>
    </div>
  );
};

export default CollectionListItem;
