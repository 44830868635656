import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';

import { SideBarItem } from '../index';
import css from './index.module.scss';

const SideBarPC = ({ data }: { data: SideBarItem[] }) => {
  if (!data) return null;

  return (
    <div className={css.sidebar_m_wrapper}>
      {data.map(({ title, label, target_data, target_type }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Button size="mini" shape="rounded" color="main" key={index}>
          <AutoLink targetType={target_type} targetData={target_data}>
            {title || label}
          </AutoLink>
        </Button>
      ))}
    </div>
  );
};

export default SideBarPC;
