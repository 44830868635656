.order_page_modal_process_orders {
  .modal_process_orders_content {
    text-align: center;
    font-size: 14px;
    color: #424242;
    .show_again {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      span {
        padding-left: 6px;
      }
    }
    button {
      width: 200px;
      margin-top: 16px;
    }
  }
}
