import { useMutation, useQueryClient } from 'react-query';

import { sellerCollectionByCollection } from '@/services/ds';

export default function useSellerCollectionByCollection() {
  const queryClient = useQueryClient();
  return useMutation(
    (params: Parameters<typeof sellerCollectionByCollection>[0]) => sellerCollectionByCollection(params),
    {
      onSuccess() {
        queryClient.invalidateQueries(['sellerCollections', 'getSellerCollections']);
        queryClient.invalidateQueries(['products']);
      },
    }
  );
}
