import { AlphaCard, Box, Divider, Layout, SkeletonBodyText, SkeletonPage } from '@shopify/polaris';

const Detail = () => (
  <SkeletonPage>
    <Layout>
      <Layout.Section>
        <AlphaCard roundedAbove="xl">
          <SkeletonBodyText lines={2} />
        </AlphaCard>
      </Layout.Section>
      <Layout.Section>
        <AlphaCard roundedAbove="xl" padding="0">
          <Box padding="4">
            <SkeletonBodyText lines={1} />
          </Box>
          <Divider borderStyle="base" />
          <Box padding="4">
            <SkeletonBodyText lines={1} />
          </Box>
          <Divider borderStyle="base" />
          <Box padding="4">
            <SkeletonBodyText lines={1} />
          </Box>
          <Divider borderStyle="base" />
          <Box padding="4">
            <SkeletonBodyText lines={1} />
          </Box>
        </AlphaCard>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);

export default Detail;
