import { User } from '@/hooks/auth';

const getResetSearchParams = () => ({
  dialog: null,
  init: null,
  selectMode: null,
  addressKey: null,
  from: null,
  presetAddressKey: null,
  hideRemember: null,
});

const emitAddressChangeEvent = (data: { address: any; user?: User; remember_address?: boolean }) => {
  localStorage.removeItem('lastShippingAddressId');

  document.dispatchEvent(
    new CustomEvent('shippingAddresses:change', {
      detail: data || {},
    })
  );
};

export { emitAddressChangeEvent, getResetSearchParams };
