import { useQuery } from 'react-query';

import { getUserCreditDepositPreload } from '@/services/ds';

export default function useUserCreditDepositPreload(params?: Parameters<typeof getUserCreditDepositPreload>[0]) {
  return useQuery(['getUserCreditDepositPreload', params], () => getUserCreditDepositPreload(params), {
    enabled: !!params?.customer_id,
    keepPreviousData: true,
  });
}
