import { AlphaStack, Box, Divider, IndexTable, Inline, Text } from '@shopify/polaris';

import AutoLink from '@/components/AutoLink';
import { useDesktop } from '@/hooks';
import { formatterDate } from '@/pages/Orders/util';
import { CollectionProduct } from '@/types';

import { privateInventoryActivityTypeMaps } from '../cons';

type Props = {
  data?: {
    created_at?: string;
    customer_id?: string;
    delta?: number;
    description?: string;
    ending_balance?: string;
    id?: string;
    order_name?: string;
    product_id?: string;
    product_variant_id?: number;
    sku?: string;
    type?: string;
    updated_at?: string;
    product?: CollectionProduct;
    metadata?: { order_id?: string; order_name?: string };
  };
  index?: number;
};

const ListItem = ({ data, index }: Props) => {
  const { id, type, created_at, ending_balance, description, order_name, delta, metadata } = data || {};
  const isDesktop = useDesktop();
  const orderId = metadata?.order_id;

  if (!id) return null;

  if (isDesktop) {
    return (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{formatterDate(created_at, 'YYYY-MM-DD HH:mm:ss')}</IndexTable.Cell>
        <IndexTable.Cell>
          <AlphaStack>
            <Inline gap="2">
              <Text as="p" variant="bodyMd">
                {privateInventoryActivityTypeMaps[type]}
              </Text>
              {!!orderId && (
                <>
                  - <AutoLink to={`/orders/${orderId}`}>{order_name}</AutoLink>
                </>
              )}
            </Inline>
            <Text as="p" color="subdued">
              {description}
            </Text>
          </AlphaStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Inline align="end" gap="1" blockAlign="center">
            <Text as="span" color="subdued">
              ({delta > 0 ? `+${delta}` : `-${delta}`})
            </Text>
            <Text as="span" variant="bodyMd">
              {ending_balance}
            </Text>
          </Inline>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  }

  return (
    <>
      <Box padding="4">
        <Inline align="space-between" blockAlign="center">
          <div>
            <AlphaStack gap="1">
              <Text variant="bodySm" as="p">
                {formatterDate(created_at, 'YYYY-MM-DD HH:mm:ss')}
              </Text>
              <Inline align="start" gap="2">
                <Text as="p" variant="bodyMd">
                  {privateInventoryActivityTypeMaps[type]}
                </Text>
                {!!order_name && (
                  <>
                    - <AutoLink to={`/orders/${orderId}`}>{order_name}</AutoLink>
                  </>
                )}
              </Inline>
              {!!description && (
                <Text as="p" color="subdued">
                  {description}
                </Text>
              )}
            </AlphaStack>
          </div>
          <Inline blockAlign="center" align="center" gap="1">
            <Text as="span" color="subdued">
              ({delta > 0 ? `+${delta}` : `-${delta}`})
            </Text>
            <Text as="span" variant="bodyMd">
              {ending_balance}
            </Text>
          </Inline>
        </Inline>
      </Box>
      <Divider borderStyle="base" />
    </>
  );
};

export default ListItem;
