import { Input, Select, Space } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { FiX } from 'react-icons/fi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useLocation, useSearchParams } from 'react-router-dom';

import AutoCheckoutPanel from '@/components/AutoCheckoutPanel';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import useOrders from '@/hooks/queries/useOrders';
import useStores from '@/hooks/queries/useStores';

import {
  CombineAndProcessButton,
  CombineSettingsButton,
  OrderList,
  OrderStatusFilter,
  ProcessButton,
} from './components';
import { ORDER_STATUS_FILTER } from './constant';
import styles from './styles.scss';
import type { Count } from './types';
import { OrderStatus } from './types';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

enum OrderFilterDefaultKeys {
  ALL = 'all',
  SAMPLE = 'sample',
  PRIVATE_INV = 'pi',
}

const { prefixCls } = styles;

const removeFromArray = (arr, value) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

const getOption = ({
  label,
  value,
  count,
  showCount,
}: {
  label: string;
  value: string;
  count: number;
  showCount?: boolean;
}) => ({
  label,
  value,
  count,
  showCount,
});

const formatterOrderStatusFilterOptions = (count: Count) => {
  const countOptions = Object.keys(ORDER_STATUS_FILTER).map((key) => {
    const realCount = count[key] || 0;
    const showCount =
      [OrderStatus.Paid, OrderStatus.Unpaid, OrderStatus.Error].includes(key as OrderStatus) && realCount > 0;

    return getOption({
      label: ORDER_STATUS_FILTER[key],
      value: key,
      count: realCount,
      showCount,
    });
  });

  countOptions.unshift(getOption({ label: 'All', value: '', count: 0, showCount: false }));

  return countOptions;
};

const orderFilterDefault = {
  [OrderFilterDefaultKeys.ALL]: 'All Orders',
  [OrderFilterDefaultKeys.SAMPLE]: 'Sample Orders',
  [OrderFilterDefaultKeys.PRIVATE_INV]: 'Private Inventory Orders',
};

function Orders({ className, style }: Props): JSX.Element {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const stores = useStores();
  const shopId = searchParams.get('shopId');
  const status = searchParams.get('status');
  const last_error_type = searchParams.get('last_error_type');
  const keyword = _.trim(searchParams.get('orders_keyword'));
  const orderType = searchParams.get('orderType');
  const [keywordValue, setKeywordValue] = useState(keyword);
  const storesData = stores?.data?.data?.shops;
  const params = useMemo(
    () =>
      _.omitBy(
        {
          shop_id: shopId,
          status,
          keyword,
          order_type: shopId ? '' : orderType,
          last_error_type,
        },
        _.isEmpty
      ),
    [shopId, status, keyword, orderType, last_error_type]
  );
  const orders = useOrders(params);
  const ordersData = useMemo(
    () => _.compact(orders?.data?.pages?.flatMap((page) => page?.data?.orders)),
    [orders?.data]
  );
  const [selectedIds, setSelectedIds] = useState([]);
  const selectedIdsCount = selectedIds.length > 99 ? '99+' : selectedIds.length;
  const showProcessBox = !!selectedIds.length && OrderStatus.Paid === status;
  const ordersCountByType = orders?.data?.pages?.[0]?.data?.count || {};
  const orderStatusFilterOptions = formatterOrderStatusFilterOptions(ordersCountByType);

  useEffect(() => {
    setKeywordValue(_.trim(keyword));
  }, [keyword]);

  useEffect(() => {
    setSelectedIds([]);
  }, [orderType, shopId]);

  const handleStoreSelectFilterChange = (id: OrderFilterDefaultKeys) => {
    const orderFilterDefaultKeys = Object.keys(orderFilterDefault);
    const isDefaultType = orderFilterDefaultKeys.includes(`${id}`);
    const curOrderType = [OrderFilterDefaultKeys.SAMPLE, OrderFilterDefaultKeys.PRIVATE_INV].includes(id) ? id : '';

    const newSearchParams = _.omitBy(
      {
        ...Object.fromEntries(searchParams),
        shopId: isDefaultType ? null : id,
        orderType: curOrderType || null,
      },
      _.isEmpty
    );
    setSearchParams(newSearchParams, { replace: true });
  };

  const handleSearch = (value: string) => {
    const newSearchParams = _.omitBy({ ...Object.fromEntries(searchParams), orders_keyword: _.trim(value) }, _.isEmpty);
    setKeywordValue(_.trim(value));
    setSearchParams(newSearchParams, { replace: true });
  };

  const handleSelect = (id: string) => {
    if (!id) return;
    const newSelectedIds = [...selectedIds];
    if (selectedIds.includes(id)) {
      removeFromArray(newSelectedIds, id);
    } else {
      newSelectedIds.push(id);
    }
    setSelectedIds(newSelectedIds);
  };

  const onProcessSuccessCallback = () => {
    setSelectedIds([]);
  };

  const onCombineAndProcessSuccessCallback = () => {
    setSelectedIds([]);
  };

  const noOrdersHint = keyword
    ? `No orders found for ${keyword}. Check your search query and try again.`
    : 'No orders found. Check your search query and try again.';

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Orders</title>
      </Helmet>

      <SecondaryNavBar
        className={`${prefixCls}__secondary-nav-bar`}
        backReplace
        backUrl={_.get(location.state, 'backUrl', '/stores')}
      >
        Orders
      </SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Orders</h1>

        <div className={`${prefixCls}__filters-bar`}>
          <div className={`${prefixCls}__filters`}>
            <Select
              className={`${prefixCls}__select`}
              popupClassName={`${prefixCls}__select-dropdown`}
              size="large"
              loading={stores.isLoading}
              disabled={stores.isLoading}
              bordered={false}
              menuItemSelectedIcon={<IoIosCheckmarkCircle />}
              placeholder={shopId && stores.isLoading ? 'Loading Stores...' : 'Select'}
              value={shopId && stores.isLoading ? undefined : shopId ?? orderType ?? OrderFilterDefaultKeys.ALL}
              onChange={handleStoreSelectFilterChange}
            >
              {Object.keys(orderFilterDefault).map((key) => (
                <Select.Option key={key} value={key}>
                  {orderFilterDefault[key]}
                </Select.Option>
              ))}
              {storesData?.map((shop: any) => (
                <Select.Option key={shop?.id} value={`${shop?.id}`}>
                  {shop?.name} ({shop?.shopify_domain})
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className={`${prefixCls}__filters`}>
            <Input.Search
              className={`${prefixCls}__input`}
              size="large"
              allowClear
              placeholder="Search Orders"
              value={keywordValue}
              onChange={(e) => setKeywordValue(e.target.value)}
              onSearch={handleSearch}
            />
          </div>
        </div>
        <div className={styles.order_status_filter_wrapper}>
          <OrderStatusFilter options={orderStatusFilterOptions} searchKey="status" />
        </div>
        <div className={styles.setting_btn_group}>
          <div className={styles.left}>
            <AutoCheckoutPanel />
          </div>
          <div className={styles.right}>
            <CombineSettingsButton />
          </div>
        </div>
        <StatusViewRenderer
          data={ordersData}
          error={orders.error}
          isLoading={orders.isLoading}
          hasMore={orders.hasNextPage}
          loadMore={orders.fetchNextPage}
          statusStyle={{ marginTop: 64 }}
          emptyContent={<span className={styles.empty_content}>{noOrdersHint}</span>}
        >
          <OrderList
            className={`${prefixCls}__order-list`}
            data={ordersData}
            onSelect={handleSelect}
            selectedIds={selectedIds}
            viewer="retailer"
          />
        </StatusViewRenderer>
        {showProcessBox && (
          <div className={styles.process_box_wrapper}>
            <div className={styles.process_box}>
              <span className={styles.selected_ids} onClick={() => setSelectedIds([])}>
                <FiX />
                <span className={styles.count}>{selectedIdsCount} Orders</span>
              </span>
              <Space>
                <ProcessButton
                  successCallback={onProcessSuccessCallback}
                  selectedIds={selectedIds}
                  orders={ordersData}
                />
                {/* <CombineAndProcessButton
                  successCallback={onCombineAndProcessSuccessCallback}
                  selectedIds={selectedIds}
                /> */}
              </Space>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Orders;
