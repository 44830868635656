export const PRICE_RULE_TYPE = {
  multiple: 'multiple',
  add: 'add',
  margin: 'margin',
};

// to config price rule options for select
export const OPTIONS = [
  { label: 'Multiplier', value: PRICE_RULE_TYPE.multiple, op: 'x' },
  { label: 'Markup', value: PRICE_RULE_TYPE.add, op: '+' },
  { label: 'Margin', value: PRICE_RULE_TYPE.margin, op: '/' },
];

export const DEFAULT_PRICE_ARG_MAP = {
  [PRICE_RULE_TYPE.multiple]: 2,
  [PRICE_RULE_TYPE.add]: 0,
  [PRICE_RULE_TYPE.margin]: 50,
};

export const DEFAULT_PRICE_RULES = {
  // price switch
  price_enabled: 1,
  price_assign_cents_enabled: 0,

  // price operator type
  // Multiplier: multiple
  // Flat Markup: add
  price_op: PRICE_RULE_TYPE.multiple,

  // price operator value
  price_arg: DEFAULT_PRICE_ARG_MAP[PRICE_RULE_TYPE.multiple],

  // compare at price switch
  compare_at_price_enabled: 1,

  // compare at price operator type
  // Multiplier: multiple
  // Flat Markup: add
  compare_at_price_op: PRICE_RULE_TYPE.multiple,

  // compare at price operator value
  compare_at_price_arg: DEFAULT_PRICE_ARG_MAP[PRICE_RULE_TYPE.multiple],
  compare_at_price_assign_cents_enabled: 0,
};

export const DEMO_ALL_IN_COST = 10;

export default DEFAULT_PRICE_RULES;
