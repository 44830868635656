import type { UploadProps } from 'antd';
import { Form, message } from 'antd';
import cn from 'classnames';
import { forwardRef, useImperativeHandle, useState } from 'react';

import Button from '@/components/Button';
import { Input } from '@/components/common';
import Modal from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useReportImageInfringement from '@/hooks/queries/useReportImageInfringement';
import useDesktop from '@/hooks/useDesktop';
import ImgUploader from '@/pages/ProductRequest/components/ImgUploader';

import css from './ModalReportImageCopyright.module.scss';

const { Item } = Form;

type CompHandle = {
  show: () => void;
};

type CompProps = {
  productId: string;
};

const ImgUploaderFormItem = ({
  onChange,
  value,
}: {
  value?: UploadProps['fileList'];
  onChange?: (value: UploadProps['fileList']) => void;
}) => {
  const handleFileChange = (data) => {
    const newImgUrls = Array.isArray(data) && data.length ? data : [];
    onChange?.(newImgUrls);
  };

  return (
    <div className={cn(css.upload_img_wrapper, { [css.with_img]: value?.length })}>
      <ImgUploader onFileChange={handleFileChange} uploadButtonAlign="start" />
    </div>
  );
};

const App: React.ForwardRefRenderFunction<CompHandle, CompProps> = ({ productId }, forwardedRef) => {
  const reportImageInfringement = useReportImageInfringement();
  const isDesktop = useDesktop();
  const [visible, setVisible] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  const [form] = Form.useForm();
  const { user } = useAuth();
  const loading = reportImageInfringement.isLoading || imgUploading;

  const onClose = () => setVisible(false);

  const onAfterShow = () => {
    if (!isDesktop) {
      window.scrollTo(0, 200);
    }
  };

  const onSubmit = () => {
    form.validateFields().then(({ email, note, imgUrls }) => {
      const formdata = {
        product_id: Number(productId),
        img_urls: imgUrls.map(({ url }) => `https:${url}`),
        email,
        note: note || '',
      };

      reportImageInfringement.mutate(formdata, {
        onSuccess() {
          message.success('Submit Success.');
          onClose();
        },
        onError(e) {
          message.error((e as Error)?.message || 'Submit Failed.');
        },
      });
    });
  };

  const handleValuesChange = (changedValues) => {
    if ('imgUrls' in changedValues) {
      setImgUploading(changedValues.imgUrls?.some((file) => file?.status === 'uploading'));
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    show: () => {
      setVisible(true);
      form.setFieldsValue({
        email: user.email,
        note: '',
      });
    },
  }));

  return (
    <Modal
      visible={visible}
      className={cn(css.ns_com_report_image_copyright_modal_main)}
      title="Report Listing"
      onClose={onClose}
      // fix ios safari scroll issue
      afterShow={onAfterShow}
      footer={
        <div className={cn(css.ns_com_report_image_copyright_modal_footer, css.btn_wrapper)}>
          <Button color="main" shape="rounded" loading={loading} disabled={loading} onClick={onSubmit}>
            Submit
          </Button>
        </div>
      }
    >
      <Form
        requiredMark={false}
        form={form}
        colon={false}
        layout="vertical"
        className={cn(css.form_report_image_copyright, css.form_wrapper)}
        onValuesChange={handleValuesChange}
      >
        <div className={css.title_wrapper}>
          <div className={css.title}>{`What's wrong with this listing? Let us know here and we'll make it right!`}</div>
        </div>

        <Item
          name="email"
          label={
            <div className={css.custom_form_label}>
              <div className={css.main_text}>
                Email <span className={css.required_mark}>*</span>
              </div>
              <div className={css.desc}>Enter your email so we can follow up with you</div>
            </div>
          }
          rules={[{ required: true, message: 'Please complete this required field.' }]}
        >
          <Input maxLength={256} className={css.input} size="large" allowClear />
        </Item>
        <Item
          name="imgUrls"
          label={
            <div className={cn(css.custom_form_label, css.img_url)}>
              <div className={css.main_text}>
                Upload Images <span className={css.required_mark}>*</span>
              </div>
            </div>
          }
          rules={[{ required: true, message: 'Please complete this required field.' }]}
        >
          <ImgUploaderFormItem />
        </Item>
        <Item
          name="note"
          label={
            <div className={css.custom_form_label}>
              <div className={css.main_text}>
                What are you reporting? Please be specific and include detailed info below.
                <span className={css.required_mark}>*</span>
              </div>
              <div className={css.desc}>
                If this involves another boutique or your own boutique, please be sure to include a link as well as your
                ideal resolution.
              </div>
            </div>
          }
          rules={[{ required: true, message: 'Please complete this required field.' }]}
        >
          <Input.TextArea
            maxLength={1000}
            className={css.input}
            size="large"
            allowClear
            autoSize={{ minRows: 3, maxRows: 14 }}
          />
        </Item>
      </Form>
    </Modal>
  );
};

const ModalReportImageCopyright = forwardRef(App);
export default ModalReportImageCopyright;
