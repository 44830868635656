$prefixCls: terms-of-service-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  color: var(--color-black);
  font-size: var(--font-size3);
  line-height: 1.4;
  text-align: left;

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-max-width);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  ul {
    list-style-position: inside;
  }

  @media screen and (min-width: 769px) {
    &__content {
      padding: 34px var(--spacing-l);
    }
  }
}
