import { create } from 'apisauce';
import { useEffect, useState } from 'react';

import countriesDB from '@/constants/countriesDB';
import statesDB from '@/constants/statesDB';

type Country = {
  name?: string;
  countryName?: string;
  countryCode?: string;
};
type State = Country & {
  name?: string;
  stateCode?: string;
  stateName?: string;
};
type City = Country &
  State & {
    name?: string;
  };
type CscDB = {
  countriesDB: Country[];
  statesDB: State[];
  citiesDB: City[];
};
const initCscDB = {
  countriesDB,
  statesDB,
  citiesDB: [],
};

// ！！！历史原因，遗留的两个key值，请勿删除，最后一次修改时间 2022/06/27！！！
const localStorageKeyOld1 = 'cscv1';
const localStorageKeyOld2 = 'cscv1-cities';
localStorage.removeItem(localStorageKeyOld1);
localStorage.removeItem(localStorageKeyOld2);
// ！！！

const cityUrl = `csc-cities-v2.txt`;
const api = create({ baseURL: '/assets/csc/' });
const getData = (url: string) => api.get(url).then((res) => res.data);
const localStorageKey = 'csc-cities-v2';
const version = 'v2';

const useCscDB = () => {
  const [cscDB, setCscDB] = useState<CscDB>(initCscDB);

  useEffect(() => {
    const citiesDBFromLocalStorage = localStorage.getItem(localStorageKey);
    if (citiesDBFromLocalStorage && JSON.parse(citiesDBFromLocalStorage)?.version === version) {
      setCscDB({
        countriesDB,
        statesDB,
        citiesDB: JSON.parse(citiesDBFromLocalStorage)?.citiesDB,
      });
    } else {
      getData(cityUrl).then((citiesDB: City[]) => {
        if (citiesDB) {
          localStorage.setItem(localStorageKey, JSON.stringify({ version, citiesDB }));
          const db: CscDB = { countriesDB, statesDB, citiesDB };
          setCscDB(db);
        }
      });
    }
  }, []);

  return cscDB;
};

export default useCscDB;
