.ns_com_modal_main {
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.45);
    opacity: 0;
    pointer-events: none;
  }
  &.show .mask {
    pointer-events: auto;
  }
  &.show:not(.animated) .mask {
    opacity: 1 !important;
  }

  .wrapper {
    position: fixed;
    display: grid;
    place-items: center;
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: auto;
    pointer-events: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
  }
  &.placement_center .wrapper {
    place-items: center;
    transform: translateY(130%);
  }
  &.placement_topRight .wrapper {
    place-items: start end;
    transform: translateX(100%);
  }

  &.show:not(.animated) .wrapper {
    transform: none !important;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: var(--spacing-l);
    width: calc(100% - var(--spacing-l) * 2);
    min-width: 0;
    min-height: 0;
    max-height: calc(100% - var(--spacing-l) * 2);
    border-radius: 16px;
    background: var(--color-white);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
  }
  &.fullscreen .container {
    width: 100%;
    height: 100%;
    max-height: none;
    margin: auto;
  }

  .header {
    position: relative;
    display: grid;
    grid-template-columns: 48px 1fr 48px;
    place-items: center;
    padding: var(--spacing-l);

    .header_left {
      justify-self: start;
    }

    .header_right {
      justify-self: end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  &.bordered .header {
    border-bottom: 1px solid #c7c7c7;
  }

  .header > .title {
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-gray5);
    font-size: 24px;
    line-height: 0;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: var(--spacing-l);
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .header_left .icon_btn:first-child {
    margin-left: -6px;
  }
  .header_right .icon_btn:last-child {
    margin-right: -6px;
  }

  .body {
    position: relative;
    flex: 1;
    padding: var(--spacing-l);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  &.no_footer .body {
    border-radius: 0 0 16px 16px;
  }

  .footer {
    position: relative;
    padding: var(--spacing-l);
  }
  &.fullscreen .footer {
    // safe area bottom patch
    padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));
  }
  &.bordered .footer {
    border-top: 1px solid #c7c7c7;
  }

  @media screen and (max-width: 768px) {
    &.placement_bottom .wrapper {
      place-items: end end;
      transform: translateY(130%);
    }

    &.fullscreen_mobile .container {
      width: 100%;
      height: 100%;
      max-height: none;
      margin: auto;
    }

    &.placement_bottom .container {
      width: 100%;
      margin: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.page_mode {
      .container {
        border-radius: 0;

        .body {
          border-radius: 0;
        }
      }

      .close_btn {
        display: none;
      }
    }

    &.fullscreen_mobile .footer {
      // safe area bottom patch
      padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));
    }
  }

  @media screen and (min-width: 769px) {
    .container {
      width: auto;
      min-width: var(--content-small-max-width);
      max-width: calc(100% - var(--spacing-l) * 2);
      max-height: calc(100% - var(--spacing-l) * 2);
    }

    &.placement_bottom .container {
      max-width: var(--content-small-max-width);
    }
  }
}
