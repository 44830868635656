.ns_com_inv_list_view_switch_main {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 40px;
  white-space: nowrap;
  &.bordered {
    border: 1px solid #424242;
  }

  .inv_list_view_switch {
    margin-left: 12px;
    --adm-color-primary: #424242;
  }
}
