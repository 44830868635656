.ns_com_like_board_modal_main {
  .ns_com_like_board_modal.btn_wrapper {
    display: flex;
    justify-content: center;
    :global {
      button {
        width: 200px;
        &:first-child {
          margin-right: var(--spacing-xxl);
        }
      }
    }
  }

  .modal_like_board_edit.form_wrapper {
    .toggle_public {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .public_title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .public_desc {
      color: #999999;
      font-weight: 400;
    }
    &:global(.ant-form) {
      :global {
        .ant-form-item {
          margin-bottom: 16px;
        }
        .ant-form-item-label {
          font-weight: 500;
          padding-bottom: 6px;
          label {
            height: auto;
          }
        }
      }
    }
  }

  .ck_editor {
    a {
      color: var(--color-black);
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 769px) {
    .modal__container {
      width: var(--content-small-max-width);
    }

    .modal__body {
      padding: 0 12px 12px;
    }

    .modal_like_board_edit.form_wrapper {
      &:global(.ant-form) {
        :global {
          .ant-form-item {
            margin-bottom: 16px;
          }
          .ant-form-item-control {
            line-height: 40px;
          }
          .ant-form-item-label {
            padding-bottom: 0;
            label {
              height: 40px;
            }
          }
        }
      }
    }
  }
}
