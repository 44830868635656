import { useMutation, useQueryClient } from 'react-query';

import { cancelOrder } from '@/services/ds';

export default function useCancelOrder() {
  const queryClient = useQueryClient();

  return useMutation((id: string) => cancelOrder(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
    },
  });
}
