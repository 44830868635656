$prefixCls: password-reset-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app {
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(206, 228, 106, 0.5) 166%);
    }
  }

  &__content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr auto;
    place-items: center;
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  &__title {
    margin: 0 auto 70px;
    color: var(--color-black);
    font-weight: 500;
    font-size: 30px;
    line-height: 1.15;
    text-align: center;
  }

  &__input {
    &-wrapper {
      position: relative;
      width: 330px;
      height: 50px;

      #{$root}--password-view & {
        display: grid;
        height: auto;
        grid-auto-flow: row;
        gap: var(--spacing-xxl);
      }
    }

    &#{&} {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 330px;
      height: 50px;
      color: var(--color-black);
      font-size: var(--font-size5);
      line-height: 1.15;
      text-align: left;
      border: 3px solid var(--color-gray5) !important;
      border-right-width: 3px !important;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
      z-index: 0;
    }

    &-email {
      &#{&} {
        display: block;
        z-index: 1;

        #{$root}--email-view & {
          display: block;
          z-index: 2;
        }
      }
    }

    &-phone {
      font-size: inherit !important;
      line-height: inherit !important;

      #{$root}--phone-view & {
        display: flex;
        z-index: 2;
      }
      #{$root}--phoneCode-view & {
        display: none !important;
      }
    }

    &-code {
      &#{&} {
        width: 330px;
        height: 50px;
        color: var(--color-black);
        font-size: var(--font-size5);
        line-height: 1.15;
        text-align: center;
        letter-spacing: 0.4em;
        border: 3px solid var(--color-gray5) !important;
        border-right-width: 3px !important;
        border-radius: 8px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
        z-index: 0;

        #{$root}--code-view & {
          display: block;
          z-index: 2;
        }
        #{$root}--phoneCode-view & {
          display: block;
          z-index: 2;
        }
        #{$root}--password-view & {
          display: none !important;
        }
      }
    }

    &-password {
      &#{&} {
        input {
          font-size: inherit;
          line-height: 1.15;
          letter-spacing: 0.12em;

          &::placeholder {
            letter-spacing: normal;
          }
        }

        #{$root}--password-view & {
          display: inline-flex;
          position: relative;
          z-index: 3;
        }
      }
    }

    #{$root}--has-error & {
      border-color: var(--color-danger) !important;
    }
  }

  &__message {
    margin: 24px auto;
    min-height: 22px;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
    text-align: center;
  }

  &__error-message {
    margin: var(--spacing-l) auto;
    min-height: 20px;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
    text-align: center;
    word-break: break-all;

    #{$root}--has-error & {
      color: var(--color-danger);
    }
  }

  &__continue-btn {
    width: 250px;
  }

  &__set-up-later-btn {
    color: rgba(0, 0, 0, 0.35);
  }

  &__actions {
    margin-top: 24px;

    .ant-divider {
      border-color: var(--color-black);
      margin: 0;
    }

    .adm-button-disabled.adm-button-fill-none {
      background: transparent !important;
      text-decoration: none !important;
    }
  }
}
