import { ErrorBoundary } from '@sentry/react';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import Button from '@/components/Button';
import Footer from '@/components/Footer';
import Modal from '@/components/Modal';
import NavBar from '@/components/NavBar';
import PaymentMethodsDialog from '@/components/PaymentMethodsDialog';
import ShippingAddressesDialog from '@/components/ShippingAddressesDialog';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import TabBar from '@/components/TabBar';
import { ORIGIN_URLS } from '@/constants/app';
import { GLOBAL_PAGE_ERROR } from '@/constants/biz';
import useCurrentStore from '@/hooks/queries/useCurrentStore';

import css from './index.module.scss';

function AppLayout(): JSX.Element {
  const currentStore = useCurrentStore();
  const [searchParams] = useSearchParams();
  const [onboardingDialogVisible, setOnboardingDialogVisible] = useState('hidden');
  const isDesktop = useMedia('(min-width: 769px)');
  const dialogView = searchParams.get('dialog') ?? '';

  useEffect(() => {
    if (sessionStorage.getItem('fromLogined') && currentStore?.isFetchedAfterMount) {
      sessionStorage.removeItem('fromLogined');
      _.isEmpty(currentStore?.data) && setOnboardingDialogVisible('show');
    }
  }, [currentStore]);

  return (
    <div className={cn(css.ns_com_app_layout_main, 'app-layout')}>
      <NavBar className={cn(css.nav_bar, 'app-layout__nav-bar')} />
      <div className={cn(css.container, 'app-layout__container')}>
        <ErrorBoundary
          fallback={<StatusViewRenderer errorTitle={GLOBAL_PAGE_ERROR} error className={css.error_boundary_wrapper} />}
        >
          <Outlet />
        </ErrorBoundary>
      </div>
      {isDesktop && <Footer className={cn(css.footer, 'app-layout__footer')} />}
      <TabBar className={cn(css.tab_bar, 'app-layout__tab-bar')} />

      {/* dialog views */}
      <ShippingAddressesDialog visible={_.startsWith(dialogView, 'shipping-addresses')} />
      <PaymentMethodsDialog visible={_.startsWith(dialogView, 'payment-methods')} />

      {/* onboarding dialog */}
      {onboardingDialogVisible !== 'hidden' && (
        <Modal
          visible={['show', 'shown'].includes(onboardingDialogVisible)}
          title="Welcome!"
          closable={false}
          maskClosable={false}
          bordered={false}
          showMountAnimation
          footer={
            <>
              <Button
                style={{ width: 250, margin: '0 auto' }}
                block
                color="main"
                shape="rounded"
                link={ORIGIN_URLS.installApp}
              >
                Connect Store
              </Button>
              <Button
                style={{ width: 250, margin: '10px auto 0', color: 'rgba(0, 0, 0, 0.35)' }}
                block
                fill="none"
                onClick={() => setOnboardingDialogVisible('hide')}
              >
                Set up later
              </Button>
            </>
          }
          afterShow={() => setOnboardingDialogVisible('shown')}
          afterClose={() => setOnboardingDialogVisible('hidden')}
        >
          <div style={{ fontSize: 16, lineHeight: '1.4' }}>
            1. Connect a Shopify store.
            <br />
            2. Like products and build a board
            <br />
            3. Import your boards as collections!
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AppLayout;
