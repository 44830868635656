import cn from 'classnames';
import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import flattenChildren from 'react-keyed-flatten-children';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Image from '@/components/Image';
import Modal, { Props as ModalProps } from '@/components/Modal';

import css from './index.module.scss';

export interface StepGuideDialogProps extends ModalProps {
  dontShowAgainKey?: string;
  showFinishButton?: boolean;
  onFinish?: () => void;
}

export interface StepProps {
  className?: string;
  style?: React.CSSProperties;
  coverImage?: string;
  coverImageSize?: { width?: number; height?: number };
  coverIcon?: React.ReactNode;
  coverIconSize?: number;
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export function StepGuideDialog({
  className,
  style,
  children,
  dontShowAgainKey,
  showFinishButton,
  onFinish,
  onClose,
  ...restProps
}: StepGuideDialogProps): JSX.Element {
  const steps = flattenChildren(children);
  const isSingleStep = React.Children.count(steps) <= 1;
  const [isFinal, setIsFinal] = useState(isSingleStep);

  const handleFinish = () => {
    onFinish ? onFinish?.() : onClose?.();
  };

  const handleClose = () => {
    onFinish && isFinal ? handleFinish() : onClose?.();
  };

  const handleDontShowAgainChange = (checked: boolean) => {
    if (checked) {
      localStorage.setItem(dontShowAgainKey as string, 'true');
    } else {
      localStorage.removeItem(dontShowAgainKey as string);
    }
  };

  return (
    <Modal
      className={cn(css.ns_com_step_guide_dialog_main, className, { [css.single_step]: isSingleStep })}
      style={style}
      bordered={false}
      footer={
        isSingleStep && !showFinishButton ? undefined : (
          <div className={css.footer}>
            <div className={css.footer_left} />
            <div className={css.steps_pagination}>
              <div className={css.steps_pagination_prev_btn}>
                <FiChevronLeft strokeWidth={3} />
              </div>
              <div className={css.steps_pagination_pager} />
              <div className={css.steps_pagination_next_btn}>
                <FiChevronRight strokeWidth={3} />
              </div>
            </div>
            <div className={css.footer_right}>
              {showFinishButton && isFinal && (
                <Button color="primary" onClick={handleFinish}>
                  Finish
                </Button>
              )}
            </div>
          </div>
        )
      }
      zIndex={3000}
      onClose={handleClose}
      {...restProps}
    >
      <div className={css.content}>
        <div className={css.steps}>
          <Swiper
            navigation={{
              prevEl: `.${css.steps_pagination_prev_btn}`,
              nextEl: `.${css.steps_pagination_next_btn}`,
            }}
            pagination={{
              el: `.${css.steps_pagination_pager}`,
              type: 'fraction',
              renderFraction: (currentClass, totalClass) =>
                `<div class="swiper-pagination-fraction-inner">
                    <span class="${currentClass}"></span><span>of</span><span class="${totalClass}"></span>
                  </div>`,
            }}
            modules={[Navigation, Pagination]}
            onSlideChange={(swiper) => setIsFinal(swiper.isEnd)}
          >
            {steps?.map((step) => (
              <SwiperSlide key={(step as any)?.key}>{step}</SwiperSlide>
            ))}
          </Swiper>
        </div>
        {dontShowAgainKey && (
          <div className={css.dont_show_again}>
            <Checkbox onChange={handleDontShowAgainChange}>Don’t show again</Checkbox>
          </div>
        )}
      </div>
    </Modal>
  );
}

export function Step({
  className,
  style,
  coverImage,
  coverImageSize,
  coverIcon,
  coverIconSize,
  title,
  description,
}: StepProps): JSX.Element {
  return (
    <div className={cn(css.step, className)} style={style}>
      <div className={css.step_cover}>
        {coverIcon && (
          <div className={css.step_cover_icon} style={{ fontSize: coverIconSize }}>
            {coverIcon}
          </div>
        )}
        {coverImage && (
          <Image
            className={css.step_cover_image}
            width={coverImageSize?.width}
            height={coverImageSize?.height}
            src={coverImage}
            fit="cover"
          />
        )}
      </div>
      <div className={css.step_info}>
        <div className={css.step_title}>{title}</div>
        <div className={css.step_description}>{description}</div>
      </div>
    </div>
  );
}

StepGuideDialog.Step = Step;

export default StepGuideDialog;
