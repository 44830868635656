import { Input, message, Upload, UploadProps } from 'antd';
import cn from 'classnames';
import COS from 'cos-js-sdk-v5';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import previewImage from '@/assets/images/brand-assets-preview.png';
import previewLogo from '@/assets/images/brand-assets-preview-logo.png';
import Button from '@/components/Button';
import Image from '@/components/Image';
import ImgCrop from '@/components/ImgCrop';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useShopSettings from '@/hooks/queries/useShopSettings';
import useUpdateShopSettings from '@/hooks/queries/useUpdateShopSettings';
import useUploadTokens from '@/hooks/queries/useUploadTokens';

import styles from './styles.scss';

const { prefixCls } = styles;

const defaultNote =
  'Thank you so much for your order! We hope this package brightens your day and that you love it as much as we do. If you have any questions regarding your order, please contact us. We appreciate your support and we are so excited to have you as part of our community!';

const uploadTokensParams = {
  type: 'brand-assets',
};

const validFileTypes = ['image/jpeg', 'image/png'];

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function BrandAssets({ className, style }: Props): JSX.Element {
  const params = useParams();
  const { shopId } = params;
  const shopSettings = useShopSettings(shopId);
  const uploadTokens = useUploadTokens(uploadTokensParams);
  const updateShopSettings = useUpdateShopSettings(shopId);
  const brandAssetsData = shopSettings?.data?.data?.brand_assets;

  const uploadTokensData = uploadTokens?.data?.data;
  const [fileList, setFileList] = useState<UploadProps['fileList']>(null);
  const refUpload = useRef<any>();
  const [currentNote, setCurrentNote] = useState<string>(defaultNote);
  const [isUploading, setIsUploading] = useState(false);
  const imageUrl = fileList?.[0]?.url;
  const imageName = fileList?.[0]?.name;

  // initial default states
  useEffect(() => {
    setFileList(
      brandAssetsData?.logo
        ? [
            {
              uid: '-1',
              status: 'done',
              name: _.last(_.split(brandAssetsData?.logo, '/')) ?? '',
              url: brandAssetsData?.logo,
            },
          ]
        : null
    );
    setCurrentNote(brandAssetsData?.invoice_message || defaultNote);
  }, [brandAssetsData]);

  // COS config
  const cosKey = uploadTokensData?.files?.logo?.key;
  const cosBucket = uploadTokensData?.files?.logo?.bucket;
  const cosRegion = uploadTokensData?.files?.logo?.region;
  const cos = new COS({
    UseAccelerate: true,
    getAuthorization: async (options, callback) => {
      const result = await uploadTokens.refetch();
      const tokens = result?.data?.data?.tokens;
      const credentials = tokens?.credentials;

      if (result.isError) {
        message.error((result.error as Error).message);
        return;
      }

      result?.isSuccess &&
        tokens &&
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.sessionToken,
          StartTime: tokens.startTime,
          ExpiredTime: tokens.expiredTime,
        });
    },
  });

  const handleFileChange = (info: any) => {
    let newFileList = [...info.fileList];
    newFileList = _.map(newFileList, (file) => {
      if (file?.response) {
        // eslint-disable-next-line no-param-reassign
        file.url = `//${file.response?.Location}`;
      }
      return file;
    });
    setFileList(newFileList);

    if (info?.file?.status === 'uploading') {
      setIsUploading(true);
    } else if (info.file.status === 'error') {
      message.error('Image Upload Failure!');
      // eslint-disable-next-line no-console
      console.error(`Image Upload Failure: `, info?.file?.error);
      setIsUploading(false);
    } else if (info?.file?.status === 'done') {
      message.success('Image Upload Success!');
      setIsUploading(false);
    }
  };

  const handleRequestUpload: UploadProps['customRequest'] = ({ file, onError, onProgress, onSuccess }) => {
    const fileExt = _.toLower((file as any)?.name?.split('.').pop());
    const filePath = `${cosKey}.${fileExt}`;

    cos.putObject(
      {
        Bucket: cosBucket,
        Region: cosRegion,
        Key: filePath,
        StorageClass: 'STANDARD',
        Body: file,
        onProgress: ({ percent }) => {
          onProgress?.({ percent });
        },
      },
      (error, data) => {
        if (error) {
          onError?.(new Error(error?.message));
        } else {
          onSuccess?.(data);
        }
      }
    );
  };

  const handleEditImage = () => {
    refUpload?.current?.upload?.uploader?.fileInput?.click?.();
  };

  const handleDeleteImage = () => {
    setFileList([]);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNote(e.target.value);
  };

  const handleSave = () => {
    if (updateShopSettings.isLoading) return;

    if (_.isNil(shopId)) {
      message.error({ key: 'saveMessage', content: 'Missing Store shopId!' });
      return;
    }
    if (_.isEmpty(imageUrl)) {
      message.error({ key: 'saveMessage', content: 'Logo image is required!' });
      return;
    }
    if (_.isEmpty(currentNote)) {
      message.error({ key: 'saveMessage', content: 'Personalized Note is required!' });
      return;
    }

    updateShopSettings.mutate(
      {
        brand_assets: {
          logo: imageUrl,
          invoice_message: currentNote,
        },
      },
      {
        onSettled: (data, error) => {
          if (error) {
            message.error({ key: 'saveMessage', content: (error as Error)?.message || 'Save Failure!' });
          } else {
            message.success({ key: 'saveMessage', content: 'Save Success!' });
          }
        },
      }
    );
  };

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Brand Assets</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Brand Assets</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Brand Assets</h1>

        <StoreSelect className={`${prefixCls}__store-select`} redirectPath="/brand-assets/:shopId" />

        <StatusViewRenderer isEmpty={false} isLoading={shopSettings?.isLoading} statusStyle={{ marginTop: 64 }}>
          <div className={`${prefixCls}__layout`}>
            <div className={`${prefixCls}__main`}>
              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Upload your Logo</div>
                  <div className={`${prefixCls}__section-description`}>
                    Your logo will display on the invoice that will be sent along with the package.
                  </div>
                </div>
                <div className={`${prefixCls}__section-body`}>
                  <ImgCrop
                    modalTitle="Edit Image"
                    aspect={320 / 160}
                    minZoom={0.1}
                    maxZoom={3}
                    quality={0.8}
                    fillColor="#ffffff"
                    cropperProps={{
                      objectFit: 'auto-cover',
                      restrictPosition: false,
                    }}
                    beforeCrop={async (file) => {
                      const { width, height } = await getImageDimension(file);
                      if (width < 320 && height < 160) return false;
                      return true;
                    }}
                  >
                    <Upload
                      ref={refUpload}
                      className={`${prefixCls}__image-uploader`}
                      name="file"
                      listType="picture"
                      accept={validFileTypes.join(', ')}
                      fileList={fileList}
                      showUploadList={false}
                      maxCount={1}
                      customRequest={handleRequestUpload}
                      beforeUpload={checkFile}
                      onChange={handleFileChange}
                    >
                      {imageUrl ? (
                        <div className={`${prefixCls}__image-uploader-logo-wrapper`}>
                          <Image
                            className={`${prefixCls}__image-uploader-logo`}
                            src={replaceImageUrl(imageUrl)}
                            fit="contain"
                          />
                        </div>
                      ) : (
                        <div className={`${prefixCls}__image-uploader-btn-wrapper`}>
                          <Button color="main" shape="rounded" loading={isUploading} loadingText="Uploading">
                            Upload an Image
                          </Button>
                          <div className={`${prefixCls}__image-uploader-description`}>
                            {imageName ?? 'Recommended size: 320px * 160px'}
                          </div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                  {imageUrl && (
                    <div className={`${prefixCls}__image-uploader-actions`}>
                      <Button icon={<FiEdit3 fill="currentColor" />} shape="rounded" onClick={handleEditImage} />
                      <Button icon={<FiTrash2 />} shape="rounded" onClick={handleDeleteImage} />
                    </div>
                  )}
                </div>
              </div>

              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Personalized Note</div>
                </div>
                <div className={`${prefixCls}__section-body`} style={{ padding: 0, border: 'none' }}>
                  <Input.TextArea
                    className={`${prefixCls}__note-input`}
                    size="large"
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    showCount={{ formatter: ({ count, maxLength }) => `${count}/${maxLength}` }}
                    maxLength={280}
                    placeholder="Use this space to write a thank you note, returns guidance, support email, or more to your customers!"
                    value={currentNote}
                    onChange={handleNoteChange}
                  />
                </div>
                <div className={`${prefixCls}__section-footer`}>
                  <Button
                    color="main"
                    shape="rounded"
                    style={{ width: 120 }}
                    onClick={handleSave}
                    loading={updateShopSettings.isLoading}
                    loadingText="Saving"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className={`${prefixCls}__aside`}>
              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Preview</div>
                  <div className={`${prefixCls}__section-description`}>
                    Write a message to all your customers when they receive your item.
                  </div>
                </div>
                <div className={`${prefixCls}__section-body`}>
                  <div className={`${prefixCls}__preview`}>
                    <Image
                      className={`${prefixCls}__preview-logo-image`}
                      src={replaceImageUrl(imageUrl) || previewLogo}
                      fit="contain"
                    />
                    <div className={`${prefixCls}__preview-note`}>{currentNote || defaultNote}</div>
                    <Image className={`${prefixCls}__preview-page-image`} src={previewImage} fit="contain" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StatusViewRenderer>
      </div>
    </div>
  );
}

function checkFile(file: any) {
  const isValidType = validFileTypes.includes(file?.type);
  const isValidSize = file?.size / 1024 / 1024 < 2;

  if (!isValidType) {
    message.error('You can only upload jpg/png image file!');
    return false;
  }

  if (!isValidSize) {
    message.error('Image must smaller than 2MB!');
    return false;
  }

  return true;
}

async function getImageDimension(file: any): Promise<{ width: number; height: number }> {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve) => {
    reader.onload = () => {
      const img = new window.Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.src = reader.result as string;
    };
  });
}

function replaceImageUrl(url: any) {
  if (!_.isString(url)) return url;

  // eslint-disable-next-line prefer-template
  return url.replace(/cos\..+\.myqcloud\.com/i, 'cos.accelerate.myqcloud.com') + '?imageMogr2/quality/60/strip';
}

export default BrandAssets;
