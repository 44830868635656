.ns_com_email_update_dialog_main {
  :global {
    .modal__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0 !important;
      border-top: none !important;

      .button {
        width: 250px;
      }
    }

    // 重写了Checkbox组件的样式
    .checkbox {
      --icon-size: 24px;
      --font-size: var(--font-size2);
      color: var(--color-gray4);
      line-height: 1.3;
    }
  }

  .content {
    padding: var(--spacing-xxl) 0;

    .form {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;

      .form_item:not(:first-child) {
        margin-top: var(--spacing-l);
      }

      label {
        margin-right: var(--spacing-m);
      }

      .input_group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .form_input {
          flex: 1;
          border-color: var(--color-gray4);
          border-radius: 4px;
        }
      }
    }
  }
}
