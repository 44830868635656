$prefixCls: order-checkout-result-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__dialog {
    .modal__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__result {
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
    text-align: center;

    &-caption {
      margin: 0 auto;
      font-weight: 500;
    }

    &-image {
      margin: var(--spacing-xxl) auto;
      color: var(--color-primary);
      font-size: 40px;
      line-height: 0;
    }

    &-description {
      margin: 0 auto;

      & + & {
        margin-top: var(--spacing-xxl);
      }
    }
  }
}
