import { message } from 'antd';
import cn from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { FiUpload } from 'react-icons/fi';
import { useMedia } from 'react-use';

import cartImg from '@/assets/images/cart.png';
import filesImg from '@/assets/images/files.png';
import starImg from '@/assets/images/star.png';
import treeImg from '@/assets/images/tree.png';
import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import { useAuth } from '@/hooks/auth';

import styles from './index.scss';

const STEPS = [
  {
    key: 'files',
    icon: <img src={filesImg} alt="files" />,
    label: 'Send your code to your friends',
  },
  {
    key: 'cart',
    icon: <img src={cartImg} alt="cart" />,
    label: 'Get them to use it at check out',
  },
  {
    key: 'star',
    icon: <img src={starImg} alt="star" />,
    label: (
      <div>
        <div className={styles.center}>Get rewarded</div>
        <div style={{ color: '#999' }} className={styles.center}>
          Coming soon
        </div>
      </div>
    ),
  },
];

const App = () => {
  const { user } = useAuth();
  const isDesktop = useMedia('(min-width: 769px)');
  const referralCode = user?.referral_code || '';
  const shareMessage = `Check out KiwiDrop at app.kiwidrop.com. Use my code ${referralCode} at checkout to get 30$ off twice to buy stock and try their dropshipping services!`;
  const handleToClipbpard = () => {
    const isSuccess = copyToClipboard(shareMessage);
    isSuccess
      ? message.success({ key: 'copyReferralCode', content: 'Code saved to clipboard!' })
      : message.error({ key: 'copyReferralCode', content: 'Copy Failed!' });
  };

  const handleShare = () => {
    try {
      const shareData = {
        text: shareMessage,
      };
      if (isDesktop) {
        handleToClipbpard();
        return;
      }
      if (!window?.navigator?.canShare?.(shareData)) {
        throw Error();
      }
      window?.navigator?.share?.(shareData);
    } catch (err) {
      handleToClipbpard();
    }
  };

  return (
    <>
      <Helmet>
        <html lang="en" className={styles.referral_code_active} />
        <title>Referral Code</title>
      </Helmet>
      <SecondaryNavBar className={styles.secondary_nav_bar}>Referral Code</SecondaryNavBar>

      <div className={styles.body}>
        <h1 className={styles.title}>Referral Code</h1>
        <div className={styles.content}>
          <div className={cn(styles.logo, styles.center)}>
            <img src={treeImg} alt="share" />
          </div>
          <div className={styles.desc}>
            <div className={cn(styles.text, styles.center)}>Thanks for joining the Kiwi Club!</div>
            <div className={cn(styles.text, styles.center)}>
              Send your friends a $30 coupon that can be used twice to try our products and services!
            </div>
          </div>
          <div className={styles.steps}>
            {STEPS.map(({ icon, label, key }) => (
              <div key={key} className={styles.item}>
                <div className={styles.icon}>{icon}</div>
                <div className={cn(styles.label, styles.center)}>{label}</div>
              </div>
            ))}
          </div>
        </div>
        {!!referralCode && (
          <div className={styles.footer}>
            <div className={cn(styles.text, styles.center)}>Share your code to your friends!</div>
            <Button color="primary" shape="rounded" onClick={handleShare}>
              <FiUpload />
              <span>{referralCode}</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default App;
