import _ from 'lodash';
import { useQuery } from 'react-query';

import { getOrderById } from '@/services/ds';

export default function useOrder(id: string, enabled?: boolean) {
  return useQuery(
    ['orders', id],
    () => {
      if (_.isNil(id)) throw new Error('missing order id');
      return getOrderById(id);
    },
    { enabled }
  );
}
