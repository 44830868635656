import { useMutation, useQueryClient } from 'react-query';

import { tokenStorage } from '@/hooks/auth';
import { verifyPhoneCode } from '@/services/lynx';

export default function useVerifyPhoneCode() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof verifyPhoneCode>[0]) => verifyPhoneCode(params), {
    onSuccess: (data: any) => {
      tokenStorage.setToken(data?.data?.access_token);
      queryClient.setQueryData('authUser', data?.data?.customer);

      queryClient.invalidateQueries('authUser');
    },
  });
}
