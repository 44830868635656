.ns_com_number_input_with_select_main {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  &:not(.disabled):hover {
    border-color: var(--color-primary-light);
  }
  &.disabled {
    background-color: #f5f5f5;
    :global {
      .ant-input-number-disabled {
        background-color: transparent;
      }
    }
  }
  .ns_or_select_main {
    float: right;
    max-width: 108px;
  }
  :global {
    .ant-input {
      width: 60px;
    }
    .ant-select {
      width: 110px;
      padding-left: 0 !important;
    }
  }
}
