import cn from 'classnames';

import css from './index.module.scss';

export enum ProductTagsPos {
  PDP = 'pdp',
  PLP = 'plp',
  CART = 'cart',
  CHECKOUT = 'checkout',
  ORDER_DETAIL = 'order_detail',
}

type Tag = {
  text: string;
  bg_color?: string;
  text_color?: string;
  pos?: number;
};

type Props = {
  pos?: ProductTagsPos;
  customStyleTags?: Tag[];
  prefixTags?: Tag[];
};

const DEFAULT_BG_COLOR = '#000';
const DEFAULT_TEXT_COLOR = '#fff';
const POS_LIST = [
  ProductTagsPos.PLP,
  ProductTagsPos.PDP,
  ProductTagsPos.CART,
  ProductTagsPos.CHECKOUT,
  ProductTagsPos.ORDER_DETAIL,
];
const LARGE_STYLE_POS_LIST = [ProductTagsPos.PDP];

// eslint-disable-next-line no-bitwise
const matchedPos = (posIdx, allPos) => ((1 << posIdx) & allPos) === 1 << posIdx;
const showLargeStyle = (pos) => LARGE_STYLE_POS_LIST.includes(pos);

const ProductTags = ({ pos, customStyleTags, prefixTags = [] }: Props) => {
  let filteredCustomStyleTags = [];

  if (pos && customStyleTags?.length) {
    const curIdxInPosList = POS_LIST.indexOf(pos);
    filteredCustomStyleTags = customStyleTags.filter((tag) => matchedPos(curIdxInPosList, tag.pos));
  }

  const tags = [...prefixTags, ...filteredCustomStyleTags];

  if (!tags?.length) return null;

  return (
    <div className={css.ns_com_product_tags_main}>
      {tags.map(({ text, bg_color, text_color }) => (
        <span
          key={text}
          className={cn(css.tag, { [css.large]: showLargeStyle(pos) })}
          style={{ backgroundColor: bg_color || DEFAULT_BG_COLOR, color: text_color || DEFAULT_TEXT_COLOR }}
        >
          {text}
        </span>
      ))}
    </div>
  );
};

export default ProductTags;
