import { Box } from '@shopify/polaris';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Closed from './Closed';
import css from './index.module.scss';
import Open from './Open';
import Preorder from './Preorder';
import ReadyToShip from './ReadyToShip';

const collectionMap = {
  // for test
  // newonetest: <Closed />,
  'open-preorders': <Open />,
  'ready-to-ship-preorders': <ReadyToShip />,
  'closed-preorders': <Closed />,
  preorder: <Preorder />,
};

const App = ({ brand }: { brand: any }) => {
  const params = useParams();
  const collectionHandle = params.id;

  const currentCollection = useMemo(() => collectionMap[collectionHandle], [collectionHandle]);

  if (!collectionHandle) return null;

  return (
    <div className={css.ns_com_collection_preorder_wrapper}>
      <Box paddingBlockEnd={currentCollection ? '0' : '4'}>
        <div className={css.collection_preorder_header}>
          {!!brand?.bgURL && (
            <div className={css.bg_img_wrapper}>
              <img className={css.img} src={brand?.bgURL} alt="" />
            </div>
          )}
        </div>
      </Box>
      {currentCollection}
    </div>
  );
};

export default App;
