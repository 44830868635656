import { Form, Input, message } from 'antd';
import cn from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useAccountEmailSendCode from '@/hooks/queries/useAccountEmailSendCode';
import useAccountEmailVerifyCode from '@/hooks/queries/useAccountEmailVerifyCode';

import css from './index.module.scss';

export type ComponentHandle = {
  show: () => void;
  hide: () => void;
};

enum Step {
  Email,
  EmailCode,
}

const ModalUpdateEmail = forwardRef<ComponentHandle>((props, ref) => {
  const { user } = useAuth();
  const email = user?.email || user?.email_pending;
  const isEmailExist = !!email;
  const isEmailLoginEnabled = !!user?.has_password;
  const [step, setStep] = useState(Step.Email);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const accountEmailSendCode = useAccountEmailSendCode();
  const accountEmailVerifyCode = useAccountEmailVerifyCode();

  const [hasEmailSection, notHasEmailSection] = [
    {
      modalTitle: 'Change Email Address',
      saveSuccessMessage: 'Email changed!',
      hintToEnterEmail: 'Enter an email you’d like to change to',
      hintToEnterCode: 'Enter the code we sent to your new email',
    },
    {
      modalTitle: 'Set Up Email Address',
      saveSuccessMessage: 'Email set!',
      hintToEnterEmail: 'Enter an email you’d like to set up',
      hintToEnterCode: 'Enter the code we sent to your email',
    },
  ];
  const emailSection = isEmailExist ? hasEmailSection : notHasEmailSection;
  const { modalTitle, saveSuccessMessage, hintToEnterEmail, hintToEnterCode } = emailSection;

  const reset = () => {
    form.resetFields();
    setStep(Step.Email);
  };
  const handleShow = () => {
    setVisible(true);
  };
  const handleHide = () => {
    setVisible(false);
  };

  const onContinue = () => {
    form.validateFields().then((values) => {
      accountEmailSendCode.mutate(values, {
        onSuccess() {
          setStep(Step.EmailCode);
          setNewEmail(values.email);
        },
        onError(error: Error) {
          message.error(error.message || 'Failed!');
        },
      });
    });
  };
  const onSubmit = () => {
    form.validateFields().then((values) => {
      accountEmailVerifyCode.mutate(
        {
          ...values,
          ...{ email: newEmail },
        },
        {
          onSuccess() {
            handleHide();
            message.success(saveSuccessMessage);
          },
          onError(e: Error) {
            message.error(e.message || 'Failed');
          },
        }
      );
    });
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  useEffect(() => {
    if (!visible) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      className={css.modal_update_email}
      visible={visible}
      onClose={handleHide}
      title={modalTitle}
      fullscreen="mobile"
    >
      <Form form={form}>
        {step === Step.Email && (
          <section className={cn(css.step, { [css.email_login_enabled]: isEmailLoginEnabled })}>
            {isEmailLoginEnabled && (
              <>
                <div className={cn(css.desc, { [css.password]: isEmailLoginEnabled })}>
                  Enter your current password to <span>{email}</span>
                </div>
                <Form.Item name="password" rules={[{ required: true }]} className={css.form_item}>
                  <Input.Password
                    maxLength={50}
                    size="large"
                    iconRender={(isVisible) => (isVisible ? <FiEye /> : <FiEyeOff />)}
                  />
                </Form.Item>
              </>
            )}
            <div className={cn(css.desc, css.email)}>{hintToEnterEmail}</div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
              className={css.form_item}
            >
              <Input size="large" maxLength={100} />
            </Form.Item>
            <div className={css.button}>
              <Button color="main" shape="rounded" onClick={onContinue} loading={accountEmailSendCode.isLoading}>
                Continue
              </Button>
            </div>
          </section>
        )}
        {step === Step.EmailCode && (
          <section className={css.step}>
            <div className={cn(css.desc, css.code)}>
              <div>{hintToEnterCode}</div>
              <div>
                <span>{newEmail}</span>
              </div>
            </div>
            <Form.Item name="code" rules={[{ required: true }]} className={cn(css.form_item, css.form_item_code)}>
              <Input size="large" className={css.code} type="tel" maxLength={6} pattern="[0-9]*" placeholder="000000" />
            </Form.Item>
            <div className={css.button}>
              <Button color="main" shape="rounded" onClick={onSubmit} loading={accountEmailVerifyCode.isLoading}>
                Continue
              </Button>
            </div>
          </section>
        )}
      </Form>
    </Modal>
  );
});

export default ModalUpdateEmail;
