import { Input, message, UploadProps } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import ChromePNG from '@/assets/chrome.png';
import ProductRequestBg from '@/assets/images/product_request_bg.jpg';
import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import { addUrls } from '@/services/pickkiwi';
import { isURL } from '@/utils/util';

import ImgUploader from './components/ImgUploader';
import css from './index.module.scss';

const CHROME_PLUGIN_DOWNLOAD_LINK =
  'https://chrome.google.com/webstore/detail/a-kiwi-pick/cmgglgjgcgmhjcmlkbcfannfenmhfmdh';
// 最大链接数限制
const MAX_COUNT_SOURCE_URLS = 20;
// 所有链接字符数限制
const MAX_CHARACTER_SOURCE_URLS = 1000;

function ProductRequest(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [sourceUrls, setSourceUrls] = useState('');
  const [imgUrls, setImgUrls] = useState<UploadProps['fileList']>([]);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);

  const handleUrlsChange = (e) => {
    setSourceUrls(e.target.value);
  };
  const filterUrls = () => {
    const newUrls = sourceUrls
      .split('\n')
      .map((url) => {
        if (isURL(url.trim())) {
          return url.trim();
        }
        return null;
      })
      .filter((url) => !!url);
    return newUrls;
  };
  const validateUrls = async () => {
    let errorMessage = '';
    const newUrls = filterUrls();
    if (_.isEmpty(newUrls) && _.isEmpty(imgUrls)) {
      errorMessage = 'Please enter valid images or urls';
    }
    if (newUrls?.length > MAX_COUNT_SOURCE_URLS) {
      errorMessage = `Please check your url, the maximum count is ${MAX_COUNT_SOURCE_URLS}`;
    }
    if (errorMessage) {
      message.warning(errorMessage);
      throw new Error('');
    }
    return {
      sourceUrls: newUrls || [],
      imgUrls: imgUrls.filter((i) => !!i.url)?.map((i) => i.url) || [],
    };
  };
  const handleAddUrls = async () => {
    try {
      setLoading(true);
      const { sourceUrls: newSourceUrls, imgUrls: newImgUrl } = await validateUrls();
      const DEFAULT_CONFIG = {
        destination_country: 'US',
        order_type: 'Wholesale',
        plan_sell_currency: 'USD',
        product_category: 'Apparel',
        qty: 1,
        accept_similar: true,
      };
      const data = {
        ...DEFAULT_CONFIG,
        custom_product: {
          source_urls: newSourceUrls,
          images: newImgUrl,
        },
      };
      await addUrls(data);
      // 添加地址成功，跳转到成功页面
      navigate('/product-request-success');
    } catch (e) {
      e?.message && message.error(e?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = useCallback((data) => {
    setImgUrls(_.isEmpty(data) ? [] : data);
  }, []);

  useEffect(() => {
    const isUrlsEmpty = _.isEmpty(imgUrls) && _.isEmpty(sourceUrls);
    const isImgUploading = imgUrls?.some((file) => file?.status === 'uploading');
    setDisabled(isImgUploading || isUrlsEmpty);
  }, [sourceUrls, imgUrls]);

  return (
    <div className={css.ns_page_product_request_main}>
      <Helmet>
        <html lang="en" className={css.ns_page_product_request_active} />
        <title>Product Request</title>
      </Helmet>
      <SecondaryNavBar className={css.secondary_nav_bar}>Product Request</SecondaryNavBar>
      <div className={css.content}>
        <div className={cn(css.content_item, css.left)}>
          <img src={ProductRequestBg} alt="" />
        </div>
        <div className={cn(css.content_item, css.right)}>
          <div className={css.form_box}>
            <div className={css.img_upload_box}>
              <div className={css.title}>
                Add Images<span className={css.mark}>*</span>
              </div>
              <div className={css.img_upload_btn_wrapper}>
                <ImgUploader onFileChange={handleFileChange} loading={loading} />
              </div>
            </div>
            <div className={css.url_upload_box}>
              <div className={css.title}>
                Add URLs<span className={css.mark}>*</span>
              </div>
              <div className={css.hint}>Separate each URL with line break </div>
              <div className={css.input_wrapper}>
                <Input.TextArea
                  autoSize={false}
                  onChange={handleUrlsChange}
                  value={sourceUrls}
                  maxLength={MAX_CHARACTER_SOURCE_URLS}
                />
                {!sourceUrls && (
                  <div className={css.placeholder_wrapper}>
                    <div className={css.item}>https://www.pinterest.com/myself/mycoolboard</div>
                    <div className={css.item}>https://www.aliexpress.com/item/400130</div>
                    <div className={css.item}>https://www.revolve.com/anine-bing</div>
                  </div>
                )}
              </div>
            </div>
            <div className={css.submit_btn_wrapper}>
              <Button
                color="primary"
                shape="rounded"
                block
                loading={loading}
                loadingText="Processing"
                disabled={disabled}
                onClick={handleAddUrls}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className={css.others}>
            <div className={css.title}>Pick Chrome Extension</div>
            <div className={css.desc}>
              Want to source products faster? Pick any image of what you want to sell, and we’ll magically find you the
              drops at wholesale prices. Download our free chrome extension!
            </div>
            <div className={css.download_btn_wrapper}>
              <Button block onClick={() => window.open(CHROME_PLUGIN_DOWNLOAD_LINK, '_blank')}>
                <img src={ChromePNG} alt="chrome" /> <span>Download from Chrome Store</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductRequest;
