import { Avatar } from 'antd';
import { List } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import Helmet from 'react-helmet';
import { BiWallet } from 'react-icons/bi';
import { FaWarehouse } from 'react-icons/fa';
import {
  FiChevronRight,
  FiDollarSign,
  FiFileText,
  FiGift,
  FiHeart,
  FiHelpCircle,
  FiLogOut,
  FiMail,
  FiPackage,
  FiUser,
} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import envconfig from '@/constants/envconfig';
import { useAuth } from '@/hooks/auth';
import useUserCredit from '@/hooks/queries/useUserCredit';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';
import { formatCredit } from '@/utils/util';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Account({ className, style }: Props): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const userCredit = useUserCredit(user?.id);
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const displayName =
    ((user?.first_name || user?.last_name) && `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim()) ||
    user?.phone;
  const userCreditData = userCredit?.data?.data?.credits;

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Account</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__account-card`}>
          <div className={`${prefixCls}__account-card-body`} onClick={() => navigate('/account/profile')}>
            <Avatar
              className={`${prefixCls}__account-card-avatar`}
              size={64}
              icon={<FiUser />}
              src={(user as any)?.avatar}
            />
            <div className={`${prefixCls}__account-card-info`}>
              <div className={`${prefixCls}__account-card-name`}>{displayName}</div>
              <div className={`${prefixCls}__account-card-phone`}>
                {`${user?.phone_country ?? ''}${user?.phone ?? ''}`}
              </div>
            </div>

            <div className={`${prefixCls}__account-card-arrow`}>
              <FiChevronRight strokeWidth={1} />
            </div>
          </div>
        </div>

        <List className={`${prefixCls}__list`}>
          <List.Item
            prefix={<FiFileText />}
            onClick={() => navigate('/orders', { state: { backUrl: `${location.pathname}${location.search}` } })}
          >
            My Retail orders
          </List.Item>
          <List.Item prefix={<FaWarehouse />} onClick={() => navigate('/private-inv')}>
            My Inventory
          </List.Item>
          <List.Item prefix={<FiHeart />} onClick={() => navigate('/seller-collections')}>
            Boards
          </List.Item>
          <List.Item
            prefix={<BiWallet />}
            extra={_.isNumber(userCreditData) ? `$${formatCredit(userCreditData)}` : undefined}
            onClick={() => navigate('/kiwi-wallet')}
          >
            Kiwi Wallet
          </List.Item>
          <List.Item prefix={<FiDollarSign />} onClick={() => navigate('/payment-methods')}>
            Payment Methods
          </List.Item>
          <List.Item prefix={<FiPackage />} onClick={() => navigate('/addresses')}>
            Shipping Addresses
          </List.Item>
          <List.Item prefix={<FiUser />} onClick={() => navigate('/account/profile')}>
            Account Info
          </List.Item>
          <List.Item prefix={<FiGift />} onClick={() => navigate('/referral-code')}>
            Referral Code
          </List.Item>
          <List.Item
            prefix={<FiHelpCircle />}
            onClick={() => window.open('https://help.kiwidrop.com/knowledge', '_blank')}
          >
            Help Center
          </List.Item>
          <List.Item prefix={<FiMail />} onClick={() => window.open('mailto:support@kiwidrop.com')}>
            Customer Support
          </List.Item>
          <List.Item prefix={<FiLogOut />} onClick={() => logout()} arrow={false}>
            Log Out
          </List.Item>
        </List>
      </div>

      <div className={`${prefixCls}__build-info`}>version: {envconfig.PACKAGE_VERSION}</div>
    </div>
  );
}

export default Account;
