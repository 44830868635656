enum PriceRuleType {
  Multiple = 'multiple',
  Add = 'add',
  Margin = 'margin',
}

export interface PriceRules {
  price_op?: PriceRuleType;
  price_arg?: number;
  price_assign_cents_arg?: number;
  price_enabled?: 0 | 1;
  price_assign_cents_enabled?: 0 | 1;
}

export { PriceRuleType as default };
