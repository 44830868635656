$sectionHeight: 86px;

.ns_com_list_select_product_and_qty {
  .check_all {
    display: flex;
    align-items: center;
    padding: 12px;

    .text {
      padding-left: 12px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .products_items_wrapper {
    padding: 0 12px 12px;
    background: #fff;
    .product_item_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 12px;

      .check_one_item_button_wrapper {
        height: $sectionHeight;
        line-height: $sectionHeight;
        padding-right: 12px;
      }
      .item_cover {
        margin-right: var(--spacing-m);
        cursor: pointer;
        width: 66px;
        height: $sectionHeight;
        .item_cover_image {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .item_info_wrapper {
        flex: 1;
        min-width: 0;
      }

      .item_title {
        font-size: var(--font-size2);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #424242;
        cursor: pointer;
        display: block;
        line-height: 1.4;
      }

      .item_variant_title,
      .item_sku {
        font-size: var(--font-size2);
        line-height: 1.4;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
      }
      .item_variant_title {
        color: #000;
      }
      .item_sku {
        color: #999;
      }

      .item_actions_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #424242;
        font-size: var(--font-size2);
        line-height: 1.3;
        .item_actions_count {
          padding-right: 8px;
        }
      }
    }
  }
}
