import { allPaths } from '@/utils/path';

export const getLinks = (id: string) => [
  {
    title: 'My Orders',
    to: `/orders?shopId=${id}`,
  },
  {
    title: 'Imported Products',
    to: `/imported-products/${id ?? ''}`,
  },
  {
    title: 'Brand Assets',
    to: `/brand-assets/${id ?? ''}`,
  },
  {
    title: 'Price Rules',
    to: `/price-rules/${id ?? ''}`,
  },
  {
    title: 'Inventory Location',
    to: `/inventory-location/${id ?? ''}`,
  },
  {
    title: 'Shipping Rules',
    to: `/shipping-rules/${id ?? ''}`,
  },
];

export const a = 1;

export const getSubMenuLists = (id: string) => [
  {
    title: 'My Sales',
    to: `${allPaths.supplierSales}/${id}`,
  },
  {
    title: 'Manage Listings',
    to: `${allPaths.catalogForRetail}/${id}`,
  },
  {
    title: 'Selling Price Rules',
    to: `${allPaths.sellingPriceRules}/${id}`,
  },
  {
    title: 'Payout Report',
    to: `${allPaths.payout}/${id}`,
  },
];
