import { message } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import AutoLink from '@/components/AutoLink';
import Checkbox from '@/components/Checkbox';
import { FulfillLabelGray } from '@/components/FulfillLabel';
import { ShareHeartIcon, UpArrow } from '@/components/Icon';
import Image from '@/components/Image';
import ProductTags, { ProductTagsPos } from '@/components/ProductTags';
import Stepper from '@/components/Stepper';
import useUpdateCart from '@/hooks/queries/useUpdateCart';
import CartContext from '@/pages/Cart/cartContext';
import { CART_SECTION_TYPE } from '@/pages/Cart/constant';
import { checkIsOverBuy, checkIsSoldOut, getBoostInfo } from '@/utils/biz';

import useCartItems from '../../useCartItems';
import SaveForLaterButton from '../SaveForLaterButton';
import css from './index.module.scss';

interface CartProductListItemProps {
  data?: any;
  disabled?: boolean;
}

const confirmModalTitle = 'Remove Item';
const confirmModalContent = 'Remove this item from cart?';

function CartProductList(): JSX.Element {
  const {
    originCartItems,
    isSoldOutDisabled,

    isNormalSelectedAll,
    normalSelectedIds,
    toggleIsNormalSelectedAll,
    changeNormalSelectedIds,

    isSoldOutSelectedAll,
    soldoutSelectedIds,
    toggleIsSoldOutSelectedAll,
    changeSoldoutSelectedIds,
  } = useContext(CartContext);

  const { soldOutItems, inStockItemsSpuDimension } = useCartItems(originCartItems, normalSelectedIds);
  const soldOutIds = soldOutItems?.map(({ variant_id }) => variant_id);

  const newCartItems = [
    {
      title: 'Products',
      items: inStockItemsSpuDimension,
      type: CART_SECTION_TYPE.normal,
      onChaneAllSelected(isChecked) {
        toggleIsNormalSelectedAll(isChecked);
      },
      onChangeIds(ids) {
        changeNormalSelectedIds(ids);
      },
    },
    {
      title: 'Sold Out',
      items: soldOutItems,
      type: CART_SECTION_TYPE.soldout,
      onChaneAllSelected(isChecked) {
        toggleIsSoldOutSelectedAll(isChecked);
      },
      onChangeIds(ids) {
        changeSoldoutSelectedIds(ids);
      },
      extra: (
        <SaveForLaterButton selectedIds={soldOutIds}>
          <span className={css.save_all_for_later_button}>
            <ShareHeartIcon stroke="#000" />
            <span className={css.save_all_for_later_button_text}>Save all for later</span>
          </span>
        </SaveForLaterButton>
      ),
    },
  ];

  return (
    <div className={cn(css.ns_com_cart_product_list_main)}>
      {newCartItems.map(({ title, items, type, extra, onChaneAllSelected, onChangeIds }) => {
        if (_.isEmpty(items)) return null;
        let isSelectedAll = isNormalSelectedAll;
        let disabled = false;
        let value = normalSelectedIds;

        const isSoldOut = [CART_SECTION_TYPE.soldout].includes(type);
        if (isSoldOut) {
          isSelectedAll = isSoldOutSelectedAll;
          disabled = isSoldOutDisabled;
          value = soldoutSelectedIds;
        }

        return (
          <div className={cn(css.group, { [css.sold_out_group]: isSoldOut })} id="cart-product-list">
            <div className={css.group_title_wrapper}>
              <div className={css.group_title}>
                <Checkbox
                  shape="square_line"
                  disabled={disabled}
                  checked={isSelectedAll}
                  onChange={(isChecked) => onChaneAllSelected(isChecked)}
                />
                <span>{title}</span>
              </div>
              {!!extra && <span className={css.extra}>{extra}</span>}
            </div>
            <Checkbox.Group onChange={onChangeIds} value={value}>
              {/* normal cart items */}
              {!isSoldOut && (
                <div className={css.group_list}>
                  {items?.map(({ spu, items: itemsDimension, drop_soon, showMessage }: any, index) => (
                    <div key={spu} className={cn(css.group_list_wrapper)}>
                      <motion.div
                        initial={{ visibility: 'hidden', height: 0 }}
                        animate={showMessage ? 'open' : 'closed'}
                        variants={{
                          open: { visibility: 'visible', height: 'fit-content' },
                          closed: { visibility: 'hidden', height: 0 },
                        }}
                      >
                        {/* do not remove this classname */}
                        <div
                          className={cn(
                            css.cart_item_error_message,
                            'error_message_wrapper',
                            showMessage ? 'visible' : ''
                          )}
                          data-instockitems-index={index}
                        >
                          During the sample buying period you are limited to a max of {drop_soon?.qty_limit} pieces per
                          listing. You can still buy {drop_soon?.qty_avail}.
                        </div>
                      </motion.div>
                      <motion.div className={cn(css.group_list, css.spu_dimension)}>
                        {itemsDimension?.map((item: any) => (
                          <CartProductListItem disabled={disabled} key={item?.variant_id} data={item} />
                        ))}
                      </motion.div>
                    </div>
                  ))}
                </div>
              )}
              {/* soldout cart items */}
              {isSoldOut && (
                <div className={css.group_list}>
                  {items?.map((item: any) => (
                    <CartProductListItem disabled={disabled} key={item?.variant_id} data={item} />
                  ))}
                </div>
              )}
            </Checkbox.Group>
          </div>
        );
      })}
    </div>
  );
}

function CartProductListItem({ data, disabled }: CartProductListItemProps): JSX.Element {
  const updateCart = useUpdateCart();
  const [currentQuantity, setCurrentQuantity] = useState<number>(data?.qty ?? 0);
  const itemRef = useRef<HTMLDivElement>(null);
  const lastUpdatedQuantity = useRef(data?.qty ?? 0);
  const removeQtyDialogRef = useRef(false);
  const stepperRef = useRef(null);
  const disabledStepper = checkIsSoldOut(data?.available_quantity);
  const productDetailURL = `/products/${data?.product_id}?variant=${data?.variant_id}`;
  const { isShow: showBoost, earnPrice, ratioStr } = getBoostInfo(data, data?.price);

  const currentPreOrder = useMemo(
    () => data?.preorder?.find((p) => p?.variant_ids?.includes(data?.variant_id)),
    [data?.preorder, data?.variant_id]
  );
  const isPreOrder = !!currentPreOrder?.preorder;

  useEffect(() => {
    setCurrentQuantity(data?.qty ?? 0);
  }, [data?.qty]);

  // qty input support enter confirm
  useEffect(() => {
    const input = itemRef.current?.querySelector('.adm-stepper-input input');
    const handler = (e) => {
      if (e.key === 'Enter') e.target.blur();
    };
    input?.addEventListener('keypress', handler);

    return () => input?.removeEventListener('keypress', handler);
  }, []);

  const updateCartAPI = async (
    req,
    config: {
      onSuccess?: () => void;
      onError?: () => void;
    }
  ) => {
    const { onSuccess, onError } = config;
    try {
      await updateCart.mutateAsync({ items: req });
      onSuccess?.();
    } catch (error) {
      message.error((error as Error)?.message || 'Update Cart Failure!');
      onError?.();
    }
  };

  const handleUpdateCart = useMemo(
    () =>
      _.debounce((qty: number, config: { onSuccess: () => void; onError: () => void }) => {
        const req = [{ variant_id: Number(data?.variant_id), qty }];
        updateCartAPI(req, config);
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkNeedUpdateQty = async (curQty, callback: () => void) => {
    if (stepperRef.current?.isFocus) return;
    if (removeQtyDialogRef.current) return;
    if (currentQuantity > 0 && currentQuantity === curQty) return;
    if (curQty > 0) {
      callback();
      return;
    }

    removeQtyDialogRef.current = true;
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        callback?.();
        removeQtyDialogRef.current = false;
      },
      onClose() {
        removeQtyDialogRef.current = false;
      },
    });
  };
  const handleQtyChange = async (curQty: number) => {
    const onChange = () => {
      let realCurQty = curQty;
      if (checkIsOverBuy(curQty, data?.available_quantity)) {
        message.warning('This number exceeds the number of available items.');
        realCurQty = data?.available_quantity;
      }
      setCurrentQuantity(realCurQty);
      handleUpdateCart.flush();
      handleUpdateCart(realCurQty, {
        onSuccess: () => {
          lastUpdatedQuantity.current = realCurQty;
        },
        onError: () => {
          setCurrentQuantity(lastUpdatedQuantity.current);
        },
      });
    };

    checkNeedUpdateQty(curQty, onChange);
  };
  const handleDeleteOne = () => {
    setCurrentQuantity(0);
    handleUpdateCart.flush();
    handleUpdateCart(0, {
      onSuccess() {
        lastUpdatedQuantity.current = 0;
        message.success('Item removed from cart');
      },
      onError() {
        setCurrentQuantity(lastUpdatedQuantity.current);
      },
    });
  };

  const handleQtyBlur = (e) => {
    stepperRef.current.isFocus = false;
    handleQtyChange(Number(e.target.value));
  };

  const handleQtyFocus = () => {
    stepperRef.current = {
      isFocus: true,
    };
  };

  return (
    <div ref={itemRef} className={cn(css.item, { [css.disabled]: disabled })}>
      <div className={css.item_checkbox_wrapper}>
        <Checkbox shape="square_line" disabled={disabled} value={data?.variant_id} />
      </div>
      <AutoLink className={css.item_cover} to={productDetailURL}>
        <Image className={css.item_cover_image} src={data?.image} fit="cover" lazy />
        {isPreOrder && (
          <div className={css.item_footer}>
            <span>Preorder</span>
          </div>
        )}
      </AutoLink>
      <div className={css.item_info}>
        <div className={css.item_info_box}>
          <div className={css.item_title_variant_wrapper}>
            <div className={css.item_title_wrapper}>
              <AutoLink className={css.item_title} title={data?.title} to={productDetailURL}>
                {data?.title}
              </AutoLink>
              <SaveForLaterButton selectedIds={[data?.variant_id]}>
                <span className={css.item_add_to_board_icon}>
                  <ShareHeartIcon stroke="#000" />
                </span>
              </SaveForLaterButton>
            </div>
            <div className={css.item_variant} title={data?.variant_title}>
              {data?.variant_title}
            </div>
          </div>
          <div className={css.item_fulfill_label}>
            <FulfillLabelGray product={data} />
          </div>
          <ProductTags customStyleTags={data?.labels} pos={ProductTagsPos.CART} />
          {showBoost && (
            <div className={css.item_boost}>
              <span className={css.boost}>
                <UpArrow /> {ratioStr} Boost Reward
              </span>
              <span>Extra ${earnPrice} per sale!</span>
            </div>
          )}
        </div>
        <div className={css.item_price_wrapper}>
          <span className={css.item_price}>${(data?.price ?? 0)?.toFixed(2)}</span>
          <div className={css.item_actions}>
            <span className={css.actions_delete_button} onClick={handleDeleteOne}>
              <FiTrash2 size={16} strokeWidth={1.5} />
            </span>
            <Stepper
              className={css.stepper_wrapper}
              min={0}
              max={data?.available_quantity ?? undefined}
              digits={0}
              value={currentQuantity}
              onChange={handleQtyChange}
              onBlur={handleQtyBlur}
              onFocus={handleQtyFocus}
              disabled={disabledStepper}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartProductList;
