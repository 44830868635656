.ns_com_count_down_main {
  display: flex;
  .time_wrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;

    align-items: center;
    justify-content: center;
    .num {
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #000000;
    }
    .suffix {
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      color: #000000;
      margin-top: 2px;
    }
  }

  @at-root .is_m {
    .ns_com_count_down_main {
      gap: 4px;
      .time_wrapper {
        width: 32px;
        height: 36px;
      }
    }
  }

  @at-root .is_pc {
    .ns_com_count_down_main {
      gap: 8px;
      .time_wrapper {
        width: 40px;
        height: 40px;
      }
    }
  }
}
