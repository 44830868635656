import { Progress } from 'antd';
import { SpinLoading } from 'antd-mobile';
import cn from 'classnames';
import { motion, Variants } from 'framer-motion';
import _ from 'lodash';
import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FiX } from 'react-icons/fi';

import css from './index.module.scss';

const wrapperVariantsFromTop: Variants = {
  show: {
    y: '0%',
    transition: { from: '-100%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    y: '-100%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

const wrapperVariantsFromRight: Variants = {
  show: {
    x: '0%',
    transition: { from: '100%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    x: '100%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

const wrapperVariantsFromBottom: Variants = {
  show: {
    y: '0%',
    transition: { from: '100%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    y: '100%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  color?: 'black' | 'white';
  textAlign?: 'left' | 'center' | 'right';
  visible?: boolean;
  title?: React.ReactNode;
  description?: React.ReactNode;
  duration?: number | boolean;
  loading?: boolean;
  shape?: 'default' | 'rounded';
  placement?: 'top' | 'bottom' | 'topRight';
  progressPercent?: number;
  progressText?: React.ReactNode;
  showMountAnimation?: boolean;
  zIndex?: number;
  closable?: boolean;
  onClose?: () => void;
  afterShow?: () => void;
  afterClose?: () => void;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  mouseDelay?: boolean; // true：鼠标移入时，不自动消失
}

function Notification({
  className,
  style,
  color = 'white',
  textAlign,
  visible,
  title,
  description,
  duration = 3000,
  loading = false,
  shape = 'default',
  placement = 'top',
  progressPercent,
  progressText,
  showMountAnimation = false,
  zIndex = 1000,
  closable = true,
  onClose,
  afterShow,
  afterClose,
  footer,
  children,
  mouseDelay,
}: Props): JSX.Element {
  const timer = useRef<any>(null);
  const appLayout = document.querySelector('.app-layout');
  const offsetTop = appLayout
    ? parseInt(getComputedStyle(appLayout).getPropertyValue('--app-layout-padding-top') || '0', 10) +
      parseInt(getComputedStyle(appLayout).getPropertyValue('--spacing-l') || '0', 10)
    : 12;
  const offsetBottom = appLayout
    ? parseInt(getComputedStyle(appLayout).getPropertyValue('--app-layout-padding-bottom') || '0', 10) +
      parseInt(getComputedStyle(appLayout).getPropertyValue('--spacing-l') || '0', 10)
    : 12;
  const offsetStyle = _.omitBy(
    {
      top: _.includes(['top', 'topRight'], placement) ? offsetTop || 150 : undefined,
      bottom: _.includes(placement, 'bottom') ? offsetBottom || 62 : undefined,
    },
    _.isUndefined
  );

  let wrapperVariants = wrapperVariantsFromTop;
  if (_.includes(placement, 'top')) wrapperVariants = wrapperVariantsFromTop;
  if (_.includes(placement, 'topRight')) wrapperVariants = wrapperVariantsFromRight;
  if (_.includes(placement, 'bottom')) wrapperVariants = wrapperVariantsFromBottom;

  const handleMouseEnter = useCallback(() => {
    if (!mouseDelay) return;
    clearTimeout(timer.current);
  }, [mouseDelay]);

  const handleMouseLeave = useCallback(() => {
    if (!mouseDelay) return;
    onClose?.();
  }, [mouseDelay, onClose]);

  return createPortal(
    <motion.div
      className={cn(css.ns_com_notification_main, 'notification', className, {
        [css[color]]: color,
        [css.has_icon]: loading,
        [css[`shape_${shape}`]]: shape,
        [css[`placement_${placement}`]]: placement,
      })}
      style={style}
      initial={showMountAnimation ? 'hide' : false}
      animate={visible ? 'show' : 'hide'}
      onAnimationStart={() => clearTimeout(timer.current)}
      onAnimationComplete={(definition) => {
        if (definition === 'show') {
          afterShow?.();
          _.isNumber(duration) && (timer.current = setTimeout(() => onClose?.(), duration));
        } else {
          afterClose?.();
        }
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <motion.div className={css.wrapper} style={{ zIndex }} variants={wrapperVariants}>
        <div
          className={css.container}
          style={offsetStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={cn(css.header, 'notification__header')}>
            {loading && (
              <div className={css.icon}>
                <SpinLoading color="primary" />
              </div>
            )}
            <div className={css.message} style={{ textAlign: textAlign ? (textAlign as any) : undefined }}>
              <div className={css.title}>{title}</div>
              <div className={css.description}>{description}</div>
            </div>
            {closable && (
              <div className={cn(css.close_btn, 'notification__close-btn')} onClick={onClose}>
                <FiX strokeWidth="1.5" />
              </div>
            )}
          </div>
          {children && <div className={css.body}>{children}</div>}
          {footer && <div className={css.footer}>{footer}</div>}
          {_.isNumber(progressPercent) && (
            <div className={css.progress}>
              {progressText && <div className={css.progress_text}>{progressText}</div>}
              <Progress className={css.progress_bar} percent={progressPercent} showInfo={false} />
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>,
    document.body
  );
}

export default Notification;
