.ns_com_auto_checkout_panel_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--spacing-m);
  padding: 0 var(--spacing-l);
  height: 34px;

  .auto_checkout_title {
    margin-right: var(--spacing-m);
    color: #424242;
    font-size: var(--font-size3);
    font-weight: 500;
    line-height: 1.4;
    vertical-align: 1px;
  }

  .auto_checkout_tooltip {
    width: auto;
    max-width: 100%;
  }

  .auto_checkout_tooltip_content {
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
  }

  @at-root .auto_checkout_modal {
    :global {
      .modal__container {
        @media screen and (min-width: 769px) {
          width: var(--content-small-max-width);
        }
        @media screen and (max-width: 768px) {
          width: auto;
        }
      }

      .modal__body {
        font-size: var(--font-size3);
        line-height: 1.4;
        text-align: center;
      }
    }
  }

  :global {
    .adm-switch {
      margin-left: var(--spacing-xxl);
    }
  }

  @media screen and (min-width: 769px) {
    padding: 0;
  }
}
