.ns_modal_print_live_helper_sample {
  .section_wrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    padding: 6px 36px;
    gap: 2px;

    span {
      line-height: 130%;
      font-size: 14px;
    }

    .barcode_desc {
      line-height: 130%;
      font-size: 12px;
    }
  }

  .bold_700 {
    font-weight: 700;
  }
}
