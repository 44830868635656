import _ from 'lodash';
import { useQuery } from 'react-query';

import { getOrderByName } from '@/services/ds';

export default function useOrderByName(orderName: string) {
  return useQuery(['orderDetailByName'], () => {
    if (_.isNil(orderName)) throw new Error('missing order name');
    return getOrderByName(orderName);
  });
}
