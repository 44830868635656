import { AlphaStack, Box } from '@shopify/polaris';

import AutoLink from '@/components/AutoLink';

import css from './Preorder.module.scss';

const Preorder = () => (
  <div className={css.ns_com_collection_preorder_preorder}>
    <Box paddingBlockStart="3" paddingBlockEnd="3">
      <AlphaStack gap="6">
        <div>
          <p className="ds2_body_title">WHAT ARE PREORDERS?</p>
          <AlphaStack gap="3">
            <p className="ds2_body_md">
              We offer group of items that are available to order for a limited time and will be shipped and delivered
              at a future date. The Turnaround time will vary depending on different products, but is always specified
              on the product listing!{' '}
              <AutoLink to="https://help.kiwidrop.com/knowledge/preorder-knowledge-article" target="_blank">
                See Details
              </AutoLink>
            </p>

            <ul className={css.ul}>
              <li>
                <span className="ds2_body_md">
                  See Open Preorders <AutoLink to="/collections/open-preorders">HERE</AutoLink>
                </span>
              </li>
              <li>
                <span className="ds2_body_md">
                  See Ready to Ship Preorders (sometimes we have extras!){' '}
                  <AutoLink to="/collections/ready-to-ship-preorders">HERE</AutoLink>
                </span>
              </li>
              <li>
                <span className="ds2_body_md">
                  See Closed Preorders <AutoLink to="/collections/closed-preorders">HERE</AutoLink>
                </span>
              </li>
            </ul>

            <p className="ds2_body_md">
              You may sell preorder products directly to your customers during the preorder period and those orders will
              flow back through your Kiwi Admin like normal orders. You will accept the orders on your Shopify or
              CommentSold and pay for your order just like a normal order. Remember to manage your customers’
              expectation of shipping and create urgency for those goodies!
            </p>

            <p className="ds2_body_md">
              You can also place wholesale orders of preorder products to secure the trendiest and unique inventory for
              your future launch.
            </p>
          </AlphaStack>
        </div>
        <div>
          <p className="ds2_body_title">IMPORTANT INFO FOR PREORDER</p>
          <ol className={css.ol}>
            <li>
              Preorder products are not in stock at Kiwi. They are items provided by our vendors that we have contracted
              with them to offer through our preorder system.
            </li>
            <li>
              Mixed orders:
              <ol type="a" className={css.ol}>
                <li>
                  Orders containing both regular products and preorder products will flow to Kiwi smoothly. So you can
                  promote them together!
                </li>
                <li>
                  Preorder products will be charged a separate $5 order shipping fee as they will ship at a later date
                  from in-stock items.
                </li>
                <li>
                  Preorder products{` `}
                  <strong className={css.ns_com_collection_preorder_line}>
                    {`with the same "Ready to Ship Date" will be grouped as one batch and charged a $5 flat shipping fee
                    for once`}
                  </strong>
                  .
                  <AutoLink
                    to="https://help.kiwidrop.com/knowledge/preorder-knowledge-article#mixed-orders"
                    target="_blank"
                  >
                    {` `}See Details
                  </AutoLink>
                </li>
              </ol>
            </li>
            <li>
              Combining orders: Preorder products are not eligible for combining orders and thus the shipping refund.
              Only regular products in order can be combined and refunded.{' '}
              <AutoLink
                to="https://help.kiwidrop.com/knowledge/preorder-knowledge-article#combining-orders"
                target="_blank"
              >
                See Details
              </AutoLink>
            </li>
            <li>
              To ensure your order for preorder products is processed, you will need to pay for the product(s), prior to
              the close of the preorder period.
            </li>
            <li>
              {`Cancellation Policy: Like regular-product orders, orders containing preorder products *cannot* be canceled
              or refunded once it is moved to "Processing" status. Additionally, no preorder items will be cancelable
              after the preorder end date.`}
            </li>
          </ol>
        </div>
      </AlphaStack>
    </Box>
  </div>
);

export default Preorder;
