import { Input } from 'antd';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductCardListView from '@/components/biz/ProductCardListView';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import useUserSettings from '@/hooks/queries/useUserSettings';
import { getProducts } from '@/services/ds';
import { hasInvView } from '@/utils/biz';

import css from './index.module.scss';

const variants = {
  show: {
    opacity: 1,
  },
  halfShow: {
    opacity: 0.5,
  },
  hide: {
    opacity: 0,
  },
};

const CheckInventory = () => {
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);
  const inputRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userSettings = useUserSettings();
  const appCollectionViews = userSettings?.data?.data?.app_collection_views;

  const navigate = useNavigate();

  const isPageLoading = isLoading && !products.length;

  const animate = useMemo(() => {
    if (isLoading) {
      return 'halfShow';
    }
    return 'show';
  }, [isLoading]);

  const handlePressEnter = (e) => {
    const val = e.target.value.trim();
    if (!val) {
      setValue('');
      return;
    }
    setIsLoading(true);
    getProducts({ filters: { bar_sku: [value] } })
      .then((res) => {
        const newProducts = res?.data?.items;
        if (newProducts.length) {
          setProducts([...newProducts, ...products]);
          setIsError(false);
          setValue('');
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    if (isError) {
      setIsError(false);
    }
    setValue(e.target.value);
  };

  const handleClear = useCallback(() => {
    inputRef.current?.focus?.({
      cursor: 'start',
    });
    setIsError(false);
    setValue('');
    setProducts([]);
  }, []);

  useEffect(() => {
    if (userSettings.isSuccess && !hasInvView(appCollectionViews)) {
      navigate('/');
    }
  }, [appCollectionViews, navigate, userSettings.isSuccess]);

  const productsList = useMemo(
    () => (
      <>
        <div className={css.inventory_list_wrapper}>
          {products.map((product, idx) => (
            <ProductCardListView
              data={product}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              isShow
              onOpenAddToCart={() => {}}
              isShowBuyButton={false}
            />
          ))}
        </div>
      </>
    ),
    [products]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.focus?.({
        cursor: 'start',
      });
    }
  }, []);

  return (
    <div className={css.ns_com_check_inventory_main}>
      <h1 className={css.check_inventory_title}>Check Inventory</h1>
      <div>
        <Input
          className={cn(css.input_wrapper, { [css.is_error]: isError })}
          size="large"
          placeholder="Scan or enter a barcode/sku"
          onPressEnter={handlePressEnter}
          value={value}
          onChange={handleChange}
          maxLength={50}
          ref={inputRef}
          allowClear
        />
        <div className={css.check_inv_error}>{isError ? `Error: “${value}” was not found.` : ''}</div>
        {!!products?.length && (
          <div className={css.clear_btn} onClick={handleClear}>
            Clear Results
          </div>
        )}
      </div>
      <StatusViewRenderer
        className={css.status_view_renderer_wrapper}
        isEmpty={false}
        isLoading={isPageLoading}
        statusStyle={{ marginTop: 32, marginBottom: 32 }}
      >
        <motion.div variants={variants} transition={{ duration: 0.5 }} animate={animate}>
          {productsList}
        </motion.div>
      </StatusViewRenderer>
    </div>
  );
};

export default CheckInventory;
