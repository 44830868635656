import { memo } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import css from './OutOfStockTag.module.scss';

const OutOfStockTag = memo(() => (
  <div className={css.ns_com_out_of_stock_tag_wrapper}>
    <i>
      <FiAlertCircle />
    </i>
    Sold Out
  </div>
));

export default OutOfStockTag;
