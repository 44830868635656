import { ErrorBoundary } from '@sentry/react';
import cn from 'classnames';
import { ElementRef, useRef } from 'react';
import Helmet from 'react-helmet';

import ModalSelectBoards from '@/components/ModalSelectBoards';
import StatusViewRenderer from '@/components/StatusViewRenderer';

import styles from './App.scss';
import AppContext from './appContext';
import AppRouter from './AppRouter';
import { GLOBAL_PAGE_ERROR } from './constants/biz';
import envconfig from './constants/envconfig';
import { useDesktop } from './hooks';
import { useAuth } from './hooks/auth';

type ModalSelectBoardsComponentHandle = ElementRef<typeof ModalSelectBoards>;
const { prefixCls } = styles;

function App(): JSX.Element {
  const isDesktop = useDesktop();
  const { user } = useAuth();
  const modalSelectBoardsRef = useRef<ModalSelectBoardsComponentHandle>(null);

  const showModalSelectBoard = (config) => {
    modalSelectBoardsRef.current.show(config);
  };

  const appContextValue = {
    showModalSelectBoard,
  };

  return (
    <AppContext.Provider value={appContextValue}>
      <div className={cn(prefixCls, { is_m: !isDesktop }, { is_pc: isDesktop })}>
        {/* SEO related settings */}
        <Helmet defaultTitle="KiwiDrop" titleTemplate="%s | KiwiDrop">
          <html lang="en" data-version={envconfig.PACKAGE_VERSION} />
          <meta name="keywords" content="kiwidrop, KiwiDrop, Kiwi Drop" />
        </Helmet>

        <ErrorBoundary
          fallback={
            <StatusViewRenderer
              errorTitle={GLOBAL_PAGE_ERROR}
              error
              className={`${prefixCls}-error_boundary_wrapper`}
            />
          }
        >
          <AppRouter />
        </ErrorBoundary>

        <ModalSelectBoards ref={modalSelectBoardsRef} />
      </div>
    </AppContext.Provider>
  );
}

export default App;
