.ns_com_pre_order_tag_wrapper {
  white-space: nowrap;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 2px 8px #0000001f;
  background-color: #f2f2f2d9;

  @at-root .is_m {
    .ns_com_pre_order_tag_wrapper {
      padding: 4px 5px;
    }
  }

  @at-root .is_pc {
    .ns_com_pre_order_tag_wrapper {
      padding: 4px 26px;
    }
  }
}
