$prefixCls: search-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__content {
    margin: 0 auto;
    max-width: var(--content-max-width);
    padding-bottom: var(--spacing-l);
  }

  &__product-filters-bar-wrapper {
    margin: 0 auto;
    max-width: var(--content-max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sticky-outer-wrapper.active .sticky-inner-wrapper {
    width: 100% !important;
    padding: 0 var(--spacing-l);
    left: 0;
    background: var(--color-gray1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  }

  &__toolbar {
    margin: var(--spacing-l) 0;
    font-size: var(--font-size3);
    line-height: 1.4;
  }

  &__product-list-inv {
    flex-direction: column;
    row-gap: 12px;
    display: flex;
    margin-top: 24px;
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__content {
      padding-left: 48px;
      padding-right: 48px;
    }
  }

  @media screen and (min-width: 769px) {
    &__content {
      padding-top: 34px;
      padding-bottom: 34px;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }
  }
}
