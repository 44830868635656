import { OrderType } from '../Orders/types';

type Item = {
  sku: string;
  qty: number;
};

type Params = {
  items: Item[];
  email: string;
  orderType: OrderType;
  orderName: string;
  phone: string;
};

const ORDER_TYPE_FOR_HUBSPOT_FORM = {
  [OrderType.DS]: 'Drop shipping Order',
  [OrderType.Sample]: 'Sample Order',
};

const openHubspotReturnForm = ({ items, email, orderType, phone, orderName }: Params) => {
  const returningItems = items.map(({ sku, qty }) => `${sku} *${qty}`).join(', ');
  const returnOrderType = ORDER_TYPE_FOR_HUBSPOT_FORM[orderType];
  console.log('returnOrderType', returnOrderType);

  if (returningItems && returnOrderType) {
    const paramsStr = [
      `phone=${encodeURIComponent(phone)}`,
      `email=${encodeURIComponent(email)}`,
      `content=${encodeURIComponent(returningItems)}`,
      `return_order_type=${returnOrderType}`,
      `return_order_number=${orderName}`,
    ].join('&');
    const url = `https://share.hsforms.com/1oyQkS1liRjik9jHxcShllgcdyi8?${paramsStr}`;
    window.open(url, '_blank');
  }
};

const placeholder = '';

export { openHubspotReturnForm, placeholder };
