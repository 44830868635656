.ns_comp_title_for_page_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    .back_button {
      padding: 4px 10px 4px 0;
    }
    .title {
      color: var(--color-black);
      font-size: 30px;
      font-weight: normal;
      line-height: 1.15;
    }
    .desc {
      margin-left: 20px;
    }
  }
}
