import { AutoComplete } from 'antd';
import { useEffect, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';

type Option = {
  label: string;
  value: string;
  code: string;
  stateName?: string;
  stateCode?: string;
  countryCode?: string;
};
interface Props {
  value?: string;
  cityOptions?: Option[];
  onChange?: (data: string) => any;
  onBlur?: (data: any) => any;
  selectedCallback?: (value: object) => any;
  className?: string;
  placeholder?: string;
}

const InputCity = ({ value, cityOptions, onChange, selectedCallback, className, placeholder, onBlur }: Props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const lastValue = useDebounce(value, 200);

  useEffect(() => {
    if (lastValue) {
      const regex = new RegExp(lastValue, 'i');
      const filterOptions = cityOptions.filter(({ label }) => regex.test(label)).slice(0, 19);
      setOptions(filterOptions);
    }
  }, [lastValue, cityOptions]);

  useEffect(() => {
    if (!value) {
      setOptions([]);
    }
  }, [value]);

  const handleSelect = (val, option) => {
    selectedCallback?.(option);
  };

  const handleChange = (val: string) => {
    const replacedVal = val?.replace(/\s+/g, ' ');
    if (!replacedVal) {
      setOptions([]);
    }
    onChange?.(replacedVal);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = e?.target?.value;
    if (val) {
      onChange?.(val.trim());
    }
    onBlur?.(e);
  };

  return (
    <AutoComplete
      value={value}
      // onSelect={handleSelect}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      className={className}
    >
      {options.map((option: Option) => {
        const { value: val, stateName, stateCode, countryCode } = option;
        return (
          <AutoComplete.Option key={`${val}-${stateCode}-${countryCode}`} value={val} stateName={stateName}>
            <div
              onClick={() => {
                handleSelect(val, option);
              }}
            >
              <div>{val}</div>
              <div style={{ fontSize: 12, color: 'gray' }}>{`${stateCode}, ${countryCode}`}</div>
            </div>
          </AutoComplete.Option>
        );
      })}
    </AutoComplete>
  );
};

export default InputCity;
