import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

import StatusViewRenderer from '@/components/StatusViewRenderer';

import styles from './styles.scss';

const { prefixCls } = styles;

const SignupQuestion = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const email = searchParams.get('email') ? decodeURIComponent(searchParams.get('email')) : '';
  const phone = searchParams.get('phone') ? decodeURIComponent(searchParams.get('phone')) : '';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20806640',
          formId: 'd90fabed-559b-4b3f-84a0-f3857f801098',
          target: '#signup-question-form',
          formFields: {
            email: {
              value: email,
            },
            phone: {
              value: phone,
            },
          },
        });
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phone]);

  return (
    <div className={prefixCls}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>About You</title>
      </Helmet>
      <StatusViewRenderer
        isEmpty={false}
        isLoading={isLoading}
        statusStyle={{ marginTop: 64 }}
        spinLoadingColor="default"
      >
        <div className={`${prefixCls}__content`}>
          <div id="signup-question-form" />
        </div>
      </StatusViewRenderer>
    </div>
  );
};

export default SignupQuestion;
