import {
  AlphaCard,
  AlphaStack,
  Box,
  Columns,
  Divider,
  Inline,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  SkeletonThumbnail,
} from '@shopify/polaris';

const Skeleton = () => (
  <SkeletonPage primaryAction>
    <AlphaStack gap="5">
      <div>
        <Box
          paddingBlockEnd="2"
          paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
          paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
        >
          <SkeletonDisplayText size="small" />
        </Box>
        <AlphaCard roundedAbove="sm" padding="0">
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={1} />
          </Box>
          <Divider borderStyle="base" />
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={1} />
          </Box>
          <Divider borderStyle="base" />
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={1} />
          </Box>
        </AlphaCard>
        <Box
          paddingBlockStart="2"
          paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
          paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
        >
          <SkeletonBodyText lines={2} />
        </Box>
      </div>
      <div>
        <Box
          paddingBlockEnd="2"
          paddingInlineEnd={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
          paddingInlineStart={{ xs: '4', sm: '0', md: '0', lg: '0', xl: '0' }}
        >
          <SkeletonDisplayText size="small" />
        </Box>
        <AlphaCard roundedAbove="sm" padding="0">
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={2} />
          </Box>
          <Divider borderStyle="base" />
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={2} />
          </Box>
          <Divider borderStyle="base" />
          <Box background="surface" padding="4" borderRadius="05">
            <SkeletonBodyText lines={2} />
          </Box>
        </AlphaCard>
      </div>
    </AlphaStack>
  </SkeletonPage>
);

export default Skeleton;
