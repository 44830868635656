.ns_com_banner_swiper_main {
  margin-bottom: var(--spacing-l);
  user-select: none;

  .swiper_wrapper {
    --swiper-navigation-color: var(--color-black);
    --swiper-navigation-size: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

    .swiper_image {
      --height: auto;
    }

    :global {
      .swiper-button-prev,
      .swiper-button-next {
        visibility: hidden;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        background: var(--color-white);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &:hover {
      :global {
        .swiper-button-prev,
        .swiper-button-next {
          visibility: visible;
        }
      }
    }
  }

  .swiper_pagination_wrapper {
    --swiper-pagination-color: var(--color-white);
    --swiper-pagination-bullet-inactive-color: var(--color-gray4);
    --swiper-pagination-bullet-size: 5px;
    --swiper-pagination-bullet-horizontal-gap: var(--spacing-xs);
    bottom: 0;
    text-align: center;
    line-height: 18px;
  }
}
