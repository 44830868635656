import { Spinner, SpinnerProps } from '@shopify/polaris';

import css from './index.module.scss';

const PageSpinner = ({ size }: { size?: SpinnerProps['size'] }) => (
  <div className={css.ns_com_page_spinner_main}>
    <Spinner size={size || 'large'} />
  </div>
);

export default PageSpinner;
