import { useContext } from 'react';

import listIcon from '@/assets/images/list.svg';

import CartContext from '../../cartContext';
import css from './index.module.scss';

const App = () => {
  const { isInManagementMode, toggleIsInManagementMode } = useContext(CartContext);

  return (
    <span onClick={toggleIsInManagementMode} className={css.ns_com_cart_page_switch_edit_button}>
      {isInManagementMode ? (
        <span className={css.text}>Cancel</span>
      ) : (
        <span className={css.center_wrapper}>
          <img src={listIcon} alt="Manage" className={css.icon} />
          <span className={css.text} style={{ paddingLeft: '4px' }}>
            Select
          </span>
        </span>
      )}
    </span>
  );
};

export default App;
