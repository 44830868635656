import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getJenniferPriceRule, updateJenniferPriceRule } from '@/services/jennifer';

export const useSellingPrice = ({ id }: { id: string }) => {
  const { data, isLoading, refetch } = useQuery(['getJenniferPriceRule', id], () => getJenniferPriceRule(id), {
    enabled: !!id,
  });

  const mutation = useMutation(updateJenniferPriceRule(id));

  return useMemo(
    () => ({
      config:
        {
          target: data?.data?.export_price_rule?.target,
          val: (data?.data?.export_price_rule?.val || 0) * 100,
          shippingFee: data?.data?.shipping_method_settings[0].price,
          isLoading,
        } || null,
      updateConfig: mutation,
      refetch,
    }),
    [data?.data?.export_price_rule, data?.data?.shipping_method_settings, isLoading, mutation, refetch]
  );
};
