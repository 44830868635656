import { useCallback } from 'react';

import CountDown from '@/components/CountDown';
import useTimeStatus from '@/hooks/biz/useTimeStatus';

import css from './index.module.scss';
import useSalesTimerSetting from './useSalesTimerSetting';
import useSalesTimerShow, { SalesTimerPos } from './useSalesTimerShow';

const SalesTimer = ({
  onFinish,
  pos: propPos,
  productTags = [],
}: {
  onFinish?: () => void;
  pos: SalesTimerPos;
  productTags?: string[];
}) => {
  const salesTimerSetting = useSalesTimerSetting({ productTags });
  const {
    title,
    bg_color: bgColorOrigin,
    text_color: textColorOrigin,
    begins_at: beginsAtOrigin,
    ends_at: endsAtOrigin,
    pos,
  } = salesTimerSetting || {};
  const { desc } = useTimeStatus(beginsAtOrigin, endsAtOrigin);
  const isPosMatched = useSalesTimerShow({ pos, propPos });

  const bgColor = bgColorOrigin || '#000';
  const titleColor = textColorOrigin || '#fff';
  const startTime = beginsAtOrigin || `${Date.now()}`;

  const handleFinish = useCallback(() => {
    onFinish && onFinish?.();
  }, [onFinish]);

  if (!salesTimerSetting) return null;
  if (!isPosMatched) return null;

  return (
    <div className={css.ns_com_sales_timer_main} style={{ backgroundColor: bgColor }}>
      <div className={css.sales_timer_title} style={{ color: titleColor }}>
        {title} {desc}
      </div>
      <CountDown startTime={startTime} endTime={endsAtOrigin} onFinish={handleFinish} />
    </div>
  );
};

export default SalesTimer;
