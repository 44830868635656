/* eslint-disable  react/jsx-props-no-spreading */
import { useLockScroll } from 'antd-mobile/es/utils/use-lock-scroll';
import cn from 'classnames';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { FiClock, FiSearch, FiX } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useClickAway } from 'react-use';

import Button from '@/components/Button';
import { useDesktop } from '@/hooks';
import useHistoryProducts from '@/hooks/queries/useHistoryProducts';
import useHistorySearches from '@/hooks/queries/useHistorySearches';

import useAlgoliaAutocomplete from '../algolia/useAlgoliaAutocomplete';
import { highlightAttrKeys, SOURCE_ID_TO_SOURCE_NAME, variantsDesktop, variantsM } from '../constant';
import { RecentItem, SOURCE_ID, SuggestedItem } from '../types';
import Highlight from './Highlight';
import ProductItem from './ProductItem';
import css from './SearchPopup.module.scss';

type CompProps = {
  container: Element;
};

type CompHandle = {
  show: () => any;
};

type LinkItem = RecentItem | SuggestedItem;

const SearchPopup: React.ForwardRefRenderFunction<CompHandle, CompProps> = ({ container }, forwardedRef) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const historyProducts = useHistoryProducts();
  const historySearchKeywords = useHistorySearches();
  const historyProductsData = (historyProducts?.data?.data || [])?.map((i) => i.product);
  const historySearchKeywordsData = (historySearchKeywords?.data?.data || [])
    ?.filter((i) => !!i?.keyword)
    ?.map((i) => i);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isInSearchPage = pathname === '/search';
  const isDesktop = useDesktop();
  const [visible, setVisible] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, autocompleteState] = useAlgoliaAutocomplete();

  const motionConfig = {
    ref: contentRef,
    className: cn(css.search_popup_comp, { [css.show]: visible }),
    initial: false,
    variants: isDesktop ? variantsDesktop : variantsM,
    animate: visible ? 'show' : 'hide',
    onAnimationComplete() {
      if (visible) {
        // 打开弹窗，动画结束，光标聚焦，修复移动端，光标错位问题
        inputRef.current?.focus();
      }
    },
  };

  const updateSearchParams = (val: string) => {
    const newSearchParams = _.omitBy({ ...Object.fromEntries(searchParams), keyword: val }, _.isEmpty);
    setSearchParams(newSearchParams, { replace: true });
  };

  const onClickLinkItem = (item: LinkItem, highlightAttr: 'label' | 'query' | 'keyword') => {
    const val = item[highlightAttr];
    const searchUrl = `/search?keyword=${val}`;

    setVisible(false);

    if (isInSearchPage) {
      updateSearchParams(val);
    } else {
      navigate(searchUrl, {
        replace: true,
      });
    }
  };

  const onInputPressEnter = () => {
    const val = inputRef.current.value;
    const searchUrl = `/search?keyword=${encodeURIComponent(val)}`;

    setVisible(false);

    if (isInSearchPage) {
      updateSearchParams(val);
    } else {
      navigate(searchUrl, {
        replace: true,
      });
    }
  };

  const formPropsOrigin = autocomplete.getFormProps({ inputElement: inputRef.current });

  const formProps = {
    ...formPropsOrigin,
    onSubmit(e) {
      onInputPressEnter();
      e.preventDefault();
    },
    className: css.form,
    ref: formRef,
  };
  const inputPropsOrigin = autocomplete.getInputProps({} as any);
  const inputProps = {
    ...inputPropsOrigin,
    ref: inputRef,
    className: css.input,
    enterKeyHint: 'search',
    maxLength: 100,
    onClick() {
      inputRef.current?.focus();
    },
    autoFocus: false,
  };

  const hasQuery = !!autocompleteState?.query;
  const showSearchInputClear = hasQuery;
  const showDefaultRecentSearchSource = !hasQuery;
  const showCurrentSearchSource = hasQuery && !!autocompleteState?.collections?.length;
  const listProps = autocomplete.getListProps({});
  const rootProps = autocomplete.getRootProps();

  const collections = autocompleteState?.collections || [];

  useLockScroll(contentRef, visible);

  useClickAway(contentRef, () => {
    setVisible(false);
  });

  useImperativeHandle(forwardedRef, () => ({
    show: () => {
      setVisible(true);
    },
  }));

  useEffect(() => {
    autocomplete.setQuery(keyword);
  }, [autocomplete, keyword]);

  const popupKeyUp = (e) => {
    if (e.code === 'Enter') {
      onInputPressEnter();
    }
  };
  useEffect(() => {
    const contentRefDom = contentRef?.current;
    if (!contentRefDom) return () => {};

    contentRefDom.addEventListener('keyup', popupKeyUp, false);
    return () => {
      contentRefDom.removeEventListener('keyup', popupKeyUp, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!(formRef.current && panelRef.current && inputRef.current && autocomplete?.getEnvironmentProps)) {
      return () => {};
    }

    const { onTouchStart, onTouchMove } = autocomplete.getEnvironmentProps({
      formElement: formRef.current,
      panelElement: panelRef.current,
      inputElement: inputRef.current,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [autocomplete, formRef, panelRef, inputRef]);

  useEffect(() => {
    if (!visible) {
      // for m to close keyboard
      inputRef?.current?.blur?.();
      // reset current serch input value
      autocomplete.setQuery(keyword);
      autocomplete.setIsOpen(false);
    }
  }, [autocomplete, keyword, visible]);

  const onClearSearchInput = () => {
    autocomplete.setQuery('');
    autocomplete.setIsOpen(false);
  };

  const renderSearchInputSuffix = () => (
    <span className={css.search_input_suffix}>
      {showSearchInputClear && (
        <span className={css.clear_button_wrapper} onClick={onClearSearchInput}>
          <span className={css.clear_button}>
            <FiX />
          </span>
        </span>
      )}
      {!isDesktop && (
        <span
          className={css.cancel_button}
          onClick={() => {
            setVisible(false);
            onClearSearchInput();
          }}
        >
          Cancel
        </span>
      )}
    </span>
  );

  const renderForm = () => (
    <form {...formProps}>
      <div className={css.input_wrapper}>
        <span
          className={css.icon}
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          <FiSearch />
        </span>
        <input {...inputProps} />
        {renderSearchInputSuffix()}
      </div>
    </form>
  );

  const renderDefaultRecentSearchSource = () => {
    const showHistorySearchKeywordsData = !!historySearchKeywordsData.length;
    const showHistoryProductsData = !!historyProductsData.length;

    return (
      <div className={cn(css.autocomplete_source, css.recent_view_wrapper)}>
        {showHistorySearchKeywordsData && (
          <>
            <div className={css.source_name}>
              <strong>{SOURCE_ID_TO_SOURCE_NAME[SOURCE_ID.Recent]}</strong>
            </div>
            <div className={cn(css.source_content_recent_labels)}>
              {historySearchKeywordsData.map((item) => (
                <Button
                  key={item.keyword}
                  className={css.label}
                  color="default"
                  bold={false}
                  onClick={() => onClickLinkItem(item, 'keyword')}
                  size="small"
                  bordered={false}
                >
                  {item.keyword}
                </Button>
              ))}
            </div>
          </>
        )}
        {showHistoryProductsData && (
          <>
            <div className={css.source_name}>
              <strong>{SOURCE_ID_TO_SOURCE_NAME[SOURCE_ID.RecentlyViewed]}</strong>
            </div>
            <div className={cn(css.source_content)}>
              {historyProductsData.map((data) => (
                <ProductItem data={data} key={data.id} />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderCurrentSearchSource = () =>
    collections.map(({ source, items }) => {
      if (!items?.length) return null;

      const { sourceId } = source;

      const showClock = sourceId === SOURCE_ID.RecentSearchesPlugin;

      return (
        <ul key={sourceId} className={cn(css.query_recent_search_wrapper, css.autocomplete_source)} {...listProps}>
          {items.map((item) => {
            const highlightAttrKey = highlightAttrKeys[sourceId];
            const key = item[highlightAttrKey];

            return (
              <li
                className={cn(css.link_item, { [css.stalled]: autocompleteState.status === 'stalled' })}
                key={key}
                {...{
                  ...autocomplete.getItemProps({
                    item,
                    source,
                  }),
                  onClick: () => onClickLinkItem(item, highlightAttrKey),
                  onKeyUp: () => {
                    onClickLinkItem(item, highlightAttrKey);
                  },
                }}
              >
                <span className={css.highlight_wrapper}>
                  <Highlight hit={item} attribute={highlightAttrKey} index={item[highlightAttrKey]} />
                </span>
                {showClock && (
                  <span className={css.clock_wrapper}>
                    <FiClock />
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      );
    });

  return createPortal(
    <motion.div {...motionConfig}>
      <div className={css.content} ref={contentRef} {...rootProps}>
        <div className={css.autocomplete} ref={panelRef}>
          {renderForm()}
          <div className={css.panel}>
            {showCurrentSearchSource && renderCurrentSearchSource()}
            {showDefaultRecentSearchSource && renderDefaultRecentSearchSource()}
          </div>
        </div>
      </div>
    </motion.div>,
    container
  );
};

const App = forwardRef(SearchPopup);
export default App;
