import { TargetType } from '@/components/AutoLink';

export enum SideBarItemType {
  Title = 'title',
  Blank = 'blank',
  Text = 'text',
}

export type SideBarItem = {
  type: SideBarItemType;
  label: string | number;
  title: string | number;
  target_data?: string;
  target_type?: TargetType;
  showOnM?: boolean;
};
