.ns_com_secondary_nav_bar_main {
  --height: var(--secondary-nav-bar-height);
  --border-bottom: 1px var(--color-gray2) solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: var(--color-white);
  :global {
    .adm-nav-bar-title {
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 500;
    }
  }
}
