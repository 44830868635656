.ns_com_import_button_main {
  @at-root .imported_label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    color: var(--color-white);
    font-size: 16px;
    line-height: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  @at-root .error_message {
    margin-top: var(--spacing-m);
    color: var(--color-danger);

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
