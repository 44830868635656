import { DEFAULT_PRICE_RULES, PRICE_RULE_TYPE } from './constant';
import type { PriceRules } from './types';

/**
 * @param cost all in cost
 * @param priceRules : as name
 * @returns
 */
const calculateFormulas = {
  [PRICE_RULE_TYPE.add]: (cost, price_arg) => Number(cost) + Number(price_arg),
  [PRICE_RULE_TYPE.multiple]: (cost, price_arg) => Number(cost) * Number(price_arg),
  [PRICE_RULE_TYPE.margin]: (cost, price_arg) => cost / ((100 - Number(price_arg)) * 0.01),
};

const getDefaultSellPrice = (
  cost: number,
  { price_enabled, price_op, price_arg, price_assign_cents_arg, price_assign_cents_enabled }: PriceRules
): number => {
  const cents = Number(`0.${`${price_assign_cents_arg}`.padStart(2, '0')}`);
  const needCalculateCost = !!calculateFormulas[price_op];
  const calculatedCost = needCalculateCost ? calculateFormulas[price_op](cost, price_arg) : 0;

  if (!price_enabled) return cost * Number(DEFAULT_PRICE_RULES.price_arg);
  if (price_assign_cents_enabled) return Number(Number(Math.floor(calculatedCost) + cents).toFixed(2));
  return Number(calculatedCost.toFixed(2));
};

export { getDefaultSellPrice };
export { getDefaultSellPrice as default };
