.order_products {
  .expand_button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #999;
    font-size: 12px;
    padding-bottom: 6px;
  }
}
