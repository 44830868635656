import { message, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import copyToClipboard from 'copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';

import AutoLink, { TargetType } from '@/components/AutoLink';
import useStores from '@/hooks/queries/useStores';
import useDesktop from '@/hooks/useDesktop';
import { getShopifyOrderDetailUrl } from '@/utils/biz';
import { formatterPrice } from '@/utils/util';

import { CombinedOrder, OrderItem } from '../../types';
import { getOrderCostRelated } from '../../util';
import css from './index.module.scss';

type Props = {
  data: OrderItem;
  showLinkTo?: boolean;
};

const getKiwiOrderDetailUrl = (id: string) => `/orders/${id}`;

const App = ({ data, showLinkTo }: Props) => {
  const stores = useStores();
  const isDesktop = useDesktop();
  const { total_items_price, total_shipping_price, items_count } = getOrderCostRelated(data);

  const handleCopy = (text: string, messageText: string) => {
    const isSuccess = copyToClipboard(text ?? '');
    isSuccess
      ? message.success({ key: 'copyMessage', content: messageText })
      : message.error({ key: 'copyMessage', content: 'Copy Failed!' });
  };

  const columnsPC: ColumnsType<CombinedOrder> = [
    {
      title: 'Order #',
      width: '60px',
      render({ shop_id, shop_order_shopify_id, shop_order_name }) {
        if (shop_order_name) {
          const shopify_domain = stores?.data?.data?.shops?.filter(({ id }) => id === shop_id)?.[0]?.shopify_domain;
          const isShowLinkTo = showLinkTo && shop_order_shopify_id && shopify_domain;

          if (isShowLinkTo) {
            return (
              <div className={css.auto_link_wrapper}>
                <AutoLink
                  targetData={getShopifyOrderDetailUrl(shop_order_shopify_id, shopify_domain)}
                  targetType={TargetType.UrlExt}
                >
                  <strong>{shop_order_name}</strong>
                </AutoLink>
                <span
                  className={css.copy_btn}
                  onClick={() => handleCopy(shop_order_name, 'Shopify order number copied to clipboard!')}
                >
                  <FiCopy />
                </span>
              </div>
            );
          }
          return (
            <>
              <strong>{shop_order_name}</strong>{' '}
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(shop_order_name, 'Shopify order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            </>
          );
        }
        return '-';
      },
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Kiwi Order #</span>,
      width: '100px',
      render({ name, id }) {
        const isShowLinkTo = showLinkTo && !!id && !!name;
        if (isShowLinkTo) {
          return (
            <div className={css.auto_link_wrapper}>
              <AutoLink to={getKiwiOrderDetailUrl(id)}>{name}</AutoLink>
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(name, 'KiwiDrop order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            </div>
          );
        }
        return (
          <>
            <span>{name || '-'}</span>
            {name && (
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(name, 'KiwiDrop order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Items',
      dataIndex: 'items_count',
      width: '40px',
    },
    {
      title: 'Product',
      dataIndex: 'total_items_price',
      width: '80px',
      render(totalItemsPrice) {
        return `$${formatterPrice(totalItemsPrice)}`;
      },
    },
    {
      title: 'Shipping',
      dataIndex: 'total_shipping_price',
      width: '60px',
      render(totalShippingPrice) {
        return `$${formatterPrice(totalShippingPrice)}`;
      },
    },
  ];

  const columnsM: ColumnsType<CombinedOrder> = [
    {
      title: 'Order #',
      width: '60px',
      render({ shop_id, shop_order_shopify_id, shop_order_name }) {
        if (shop_order_name) {
          const shopify_domain = stores?.data?.data?.shops?.filter(({ id }) => id === shop_id)?.[0]?.shopify_domain;
          const isShowLinkTo = showLinkTo && shop_order_shopify_id && shopify_domain;

          if (isShowLinkTo) {
            return (
              <div className={css.auto_link_wrapper}>
                <AutoLink
                  targetData={getShopifyOrderDetailUrl(shop_order_shopify_id, shopify_domain)}
                  targetType={TargetType.UrlExt}
                >
                  <strong>{shop_order_name}</strong>
                </AutoLink>
                <span
                  className={css.copy_btn}
                  onClick={() => handleCopy(shop_order_name, 'Shopify order number copied to clipboard!')}
                >
                  <FiCopy />
                </span>
              </div>
            );
          }
          return (
            <div>
              <strong>{shop_order_name}</strong>
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(shop_order_name, 'Shopify order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            </div>
          );
        }
        return '-';
      },
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Kiwi Order #</span>,
      width: '100px',
      render({ name, id }) {
        const isShowLinkTo = showLinkTo && !!id && !!name;
        if (isShowLinkTo) {
          return (
            <div className={css.auto_link_wrapper}>
              <AutoLink to={getKiwiOrderDetailUrl(id)}>{name}</AutoLink>
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(name, 'KiwiDrop order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            </div>
          );
        }
        return (
          <>
            <span>{name || '-'}</span>
            {name && (
              <span
                className={css.copy_btn}
                onClick={() => handleCopy(name, 'KiwiDrop order number copied to clipboard!')}
              >
                <FiCopy />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Items',
      dataIndex: 'items_count',
      width: '40px',
    },
    {
      title: <div>Product</div>,
      width: '70px',
      render({ total_items_price: totalItemsPrice }) {
        return <div>{`$${formatterPrice(totalItemsPrice)}`}</div>;
      },
    },
    {
      title: <div>Shipping</div>,
      width: '70px',
      render({ total_shipping_price: totalShippingPrice }) {
        return <div>{`$${formatterPrice(totalShippingPrice)}`}</div>;
      },
    },
  ];

  const summaryPC = () => (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>Subtotal</Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2}>{items_count}</Table.Summary.Cell>
        <Table.Summary.Cell index={3}>${formatterPrice(total_items_price)}</Table.Summary.Cell>
        <Table.Summary.Cell index={4}>${formatterPrice(total_shipping_price)}</Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );

  const summaryM = () => (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>Subtotal</Table.Summary.Cell>
        <Table.Summary.Cell index={1} />
        <Table.Summary.Cell index={2}>{items_count}</Table.Summary.Cell>
        <Table.Summary.Cell index={3}>${formatterPrice(total_items_price)}</Table.Summary.Cell>
        <Table.Summary.Cell index={4}>${formatterPrice(total_shipping_price)}</Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );

  const columns = isDesktop ? columnsPC : columnsM;
  const summaryConfig = isDesktop ? summaryPC : summaryM;

  return (
    <Table
      scroll={{ x: '330px' }}
      className={css.table_combine_order}
      columns={columns}
      dataSource={data?.combined_orders || []}
      pagination={false}
      size="small"
      bordered={false}
      summary={summaryConfig}
    />
  );
};

export default App;
