import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';

import { addSellerCollection } from '@/services/ds';

export default function useAddSellerCollection(queryParams?: any) {
  const queryClient = useQueryClient();

  return useMutation(
    (values: { title: string; is_public: boolean; body_html?: string }) => addSellerCollection(values),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['sellerCollections', 'getSellerCollections', queryParams], (prevData: any) =>
          produce(prevData, (draft: any) => {
            draft?.pages?.at(-1)?.data?.seller_collections?.push(data?.data);
          })
        );

        queryClient.invalidateQueries('sellerCollections');
      },
    }
  );
}
