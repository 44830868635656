/**
 * ds related API
 */

import _ from 'lodash';

import { BUILDER_SETTING } from '@/constants/app';
import type { OrderItem } from '@/pages/Orders/types';
import CustomError from '@/utils/customError';

import api, { responseErrorHandler } from './api';

const URL_PREFIX = '/ds/v2/';

export async function getNavMenu(params?: any) {
  const res = await api.get<any>(`/ds/v2/app/nav`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getHomeProducts(params?: any) {
  const convertedParams = _.mapValues(params, (v) => (_.isArray(v) || _.isPlainObject(v) ? JSON.stringify(v) : v));
  const res = await api.get<any>(`/ds/v2/app/home-products`, convertedParams);
  responseErrorHandler(res);
  return res.data;
}

export async function getSettings(
  params: { type: string | string[] } = {
    type: Object.keys(BUILDER_SETTING).map((key) => BUILDER_SETTING[key]),
  }
) {
  const type = _.castArray(params?.type).join(',');
  const res = await api.get<any>(`/ds/v2/app/settings`, { ...params, type });
  responseErrorHandler(res);
  return res.data;
}

export async function getMessages(params?: any) {
  const res = await api.get<any>(`/ds/v2/app/messages`, _.merge({ page: 1, page_size: 3000, is_read: 0 }, params));
  responseErrorHandler(res);
  return res.data;
}

export async function updateMessagesReadStatus(params?: { messageIds?: number | number[]; isRead?: number | boolean }) {
  const res = await api.post<any>(`/ds/v2/app/message/is-read`, {
    message_ids: _.compact(_.castArray(params?.messageIds)),
    is_read: Number(params?.isRead ?? 1),
  });
  responseErrorHandler(res);
  return res.data;
}

export async function getTaskOperateProgress(taskId: string) {
  const res = await api.get<any>(`/ds/v2/app/operate-progress/${taskId}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getUploadTokens(params?: { type?: string }) {
  const res = await api.get<any>(`/ds/v2/app/upload-tokens`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getHistorySearches(params?: any) {
  const res = await api.get<any>(`/ds/v2/seller/history-searches`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getHistoryProducts(params?: any) {
  const res = await api.get<any>(`/ds/v2/seller/history-products`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getSearchProducts(params?: any) {
  const convertedParams = _.mapValues(params, (v) => (_.isArray(v) || _.isPlainObject(v) ? JSON.stringify(v) : v));
  const res = await api.get<any>(`/ds/v2/product/search`, convertedParams);
  responseErrorHandler(res);
  return res.data;
}

export async function getProductById(id: string) {
  const res = await api.get<any>(`/ds/v2/product/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getProductBySku(sku: string) {
  const res = await api.get<any>(`/ds/v2/product/sku/${sku}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getProducts(params) {
  const res = await api.get<any>(`/ds/v2/products`, { ...params });
  responseErrorHandler(res);
  return res.data;
}

export async function getProductImagesDownloadUrl(id: string) {
  const res = await api.get<any>(`/ds/v2/product/${id}/images/download`);
  responseErrorHandler(res);
  return res.data;
}

export async function requestProductRestock(ids: number | string | (number | string)[]) {
  const res = await api.post<any>(`/ds/v2/product/request-restock`, { product_ids: _.map(_.castArray(ids), Number) });
  responseErrorHandler(res);
  return res.data;
}

export async function getCollectionProducts(id: string, params?: any) {
  const convertedParams = _.mapValues(params, (v) => (_.isArray(v) || _.isPlainObject(v) ? JSON.stringify(v) : v));
  const res = await api.get<any>(`/ds/v2/collection/${id}/products`, convertedParams);
  responseErrorHandler(res);
  return res.data;
}

export async function getSellerCollections(params?: any) {
  const res = await api.get<any>(`/ds/v2/seller-collections`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addSellerCollection(values: { title: string; is_public: boolean; body_html?: string }) {
  const res = await api.post<any>(`/ds/v2/seller-collections`, values);
  // duplicated title error
  if (res.status === 403) throw new Error('DUPLICATED_TITLE');

  responseErrorHandler(res);
  return res.data;
}

export async function updateSellerCollection(id: string, title?: string, is_public?: boolean, body_html?: string) {
  const res = await api.post<any>(`/ds/v2/seller-collection/${id}`, { title, is_public, body_html });
  // duplicated title error
  if (res.status === 403) throw new Error('DUPLICATED_TITLE');

  responseErrorHandler(res);
  return res.data;
}

export async function deleteSellerCollection(id: string) {
  const res = await api.delete<any>(`/ds/v2/seller-collection/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getSellerCollectionProducts(id: string, params?: any) {
  const res = await api.get<any>(`/ds/v2/seller-collection/${id}/products`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addProductsToSellerCollection(ids: string[], collectionId?: string) {
  const res = await api.post<any>(`/ds/v2/seller-collection/${collectionId}/products`, { product_ids: ids });
  responseErrorHandler(res);
  return res.data;
}

export async function deleteProductsFromSellerCollection(ids: string[], collectionId?: string) {
  const res = await api.delete<any>(`/ds/v2/seller-collection/${collectionId}/products`, { product_ids: ids });
  responseErrorHandler(res);
  return res.data;
}

export async function updateProductSellerCollection(params?: any) {
  const res = await api.post<any>(`/ds/v2/seller-collection/change-product`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addProductsToLikes(ids: string[]) {
  const res = await api.post<any>(`/ds/v2/seller/likes/products`, { product_ids: ids });
  responseErrorHandler(res);
  return res.data;
}

export async function deleteProductsFromLikes(ids: string[]) {
  const res = await api.delete<any>(`/ds/v2/seller/likes/products`, { product_ids: ids });
  responseErrorHandler(res);
  return res.data;
}

export async function getStores(params?: any) {
  const res = await api.get<any>(`/ds/v2/shops`, params);
  responseErrorHandler(res);

  if (res?.data?.data?.shops) {
    for (let i = 0; i < res.data.data.shops.length; i += 1) {
      res.data.data.shops[i].id = String(res.data.data.shops[i].id);
    }
  }
  return res.data;
}

export async function getStoreInstallUrl(params?: { shop?: string }) {
  const res = await api.get<any>(`/ds/v2/shop/install`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function bindStore(params: { sign: string; shop_id: string | number }) {
  const res = await api.post<any>(`/ds/v2/shop/bind`, params);
  // store bound by another account
  if (res.status === 403) throw new Error('STORE_BOUND_BY_ANOTHER_ACCOUNT');

  responseErrorHandler(res);
  return res.data;
}

export async function unbindStore(params: { shop_id: string | number }) {
  const res = await api.post<any>(`/ds/v2/shop/unbind`, { ...params, shop_id: Number(params.shop_id) });

  responseErrorHandler(res);
  return res.data;
}

export async function getStoreById(shopId: string) {
  const res = await api.get<any>(`/ds/v2/shop/${shopId}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getStorePriceRules(shopId: string) {
  const res = await api.get<any>(`/ds/v2/shop/${shopId}/price-rules`);
  responseErrorHandler(res);
  return res.data;
}

export async function updateStorePriceRules(shopId: string, params?: any) {
  const res = await api.post<any>(`/ds/v2/shop/${shopId}/price-rules`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateStoreBrandAssets(shopId: string, params?: any) {
  const res = await api.post<any>(`/ds/v2/shop/${shopId}/brand-assets`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getImportProducts(params?: any) {
  const res = await api.post<any>(`/ds/v2/shop/prepare-import`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getStoreImportedProducts(shopId: string, params?: any) {
  const res = await api.get<any>(`/ds/v2/shop/${shopId}/products`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateStoreImportedProducts(shopId: string, params?: any) {
  const res = await api.post<any>(`/ds/v2/shop/${shopId}/import-product`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getOrders(params?: any) {
  const res = await api.get<any>(`/ds/v2/orders`, params, { timeout: 30000 });
  responseErrorHandler(res);
  return res.data;
}

export async function getOrderById(id: number | string) {
  const res = await api.get<any>(`/ds/v2/order/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getOrderByName(orderName: string) {
  const res = await api.get<any>(`/ds/v2/o/${orderName}`);
  responseErrorHandler(res);
  return res.data;
}

export async function cancelOrder(id: number | string) {
  const res = await api.post<any>(`/ds/v2/order/${id}/cancel`);
  responseErrorHandler(res);
  return res.data;
}

export async function checkoutOrder(
  id: number | string,
  params?: { payment_method_id: number; success_url?: string; cancel_url?: string }
) {
  const res = await api.post<any>(`/ds/v2/order/${id}/pay`, {
    ...params,
    payment_method_id: Number(params?.payment_method_id),
  });
  responseErrorHandler(res);
  return res.data;
}

export async function createOrder(params?: any) {
  const res = await api.post<any>(`/ds/v2/orders`, params);
  if (_.inRange(res?.data?.errno, 72400, 72500))
    throw new CustomError(res?.data?.errmsg ?? 'invalid discount code', 'DiscountCodeError', res?.data?.errno);
  responseErrorHandler(res);
  return res.data;
}

export async function updateOrder(id: number | string, params?: any) {
  const res = await api.post<any>(`/ds/v2/order/${id}`, params);
  if (_.inRange(res?.data?.errno, 72400, 72500))
    throw new CustomError(res?.data?.errmsg ?? 'invalid discount code', 'DiscountCodeError', res?.data?.errno);
  responseErrorHandler(res);
  return res.data;
}

export async function getCart() {
  const res = await api.get<any>(`/ds/v2/cart`);
  responseErrorHandler(res);
  return res.data;
}

export async function updateCart(params?: { note?: string; items?: any[]; append?: boolean }) {
  const res = await api.post<any>(`/ds/v2/cart`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getShippingAddresses() {
  const res = await api.get<any>(`/ds/v2/shipping-addresses`);
  responseErrorHandler(res);
  return res.data;
}

export async function addShippingAddress(params?: any) {
  const res = await api.post<any>(`/ds/v2/shipping-addresses`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateShippingAddress(id: number | string, params?: any) {
  const res = await api.post<any>(`/ds/v2/shipping-address/${id}`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function deleteShippingAddress(id: number | string) {
  const res = await api.delete<any>(`/ds/v2/shipping-address/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getPaymentMethods(params?: any) {
  const res = await api.get<any>(`/ds/v2/payment-methods`, _.merge({ page: 1, page_size: 3000 }, params));
  responseErrorHandler(res);
  return res.data;
}

export async function getPaymentMethodTokens(params?: any) {
  const res = await api.post<any>(`/ds/v2/payment-methods/try`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function setPaymentMethodAsDefault(id: number | string) {
  const res = await api.post<any>(`/ds/v2/payment-methods/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function deletePaymentMethod(id: number | string) {
  const res = await api.delete<any>(`/ds/v2/payment-methods/${id}`);
  responseErrorHandler(res);
  return res.data;
}

export async function getPaymentSettings() {
  const res = await api.get<any>(`/ds/v2/payment-settings`);
  responseErrorHandler(res);
  return res.data;
}

export async function updatePaymentSettings(params?: any) {
  const res = await api.post<any>(`/ds/v2/payment-settings`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updatePaymentMethodResult(params?: any) {
  const res = await api.post<any>(`/ds/v2/payment-methods/setup-intent-result`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updatePaymentMethodsPriority(params: { payment_priority: number[] }) {
  const res = await api.put<any>(`/ds/v2/payment-methods`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addProcess(params?: any) {
  const res = await api.post<any>(`${URL_PREFIX}orders/process`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getCombineOrder(params?: any) {
  const res = await api.post<any>(`${URL_PREFIX}orders/combine`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addCombineOrderComfirmation(params?: any) {
  const res = await api.post<any>(`${URL_PREFIX}orders/combine`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getUserSettings(params?: any) {
  const res = await api.get<any>(`${URL_PREFIX}customer-settings`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateCombineSettings(params?: any): Promise<OrderItem> {
  const res = await api.post<any>(`${URL_PREFIX}customer-settings`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function addReportImageInfringement(data) {
  const res = await api.post<{ product_id: number; note: string; img_url: string; email: string }>(
    `/ds/v2/product/image-infringement`,
    data
  );
  responseErrorHandler(res);
  return res.data;
}

export async function deleteShopProduct(shopId: number | string, shopProductId: number | string) {
  const res = await api.delete<any>(`/ds/v2/shop/${shopId}/products/${shopProductId}`);
  responseErrorHandler(res);
  return res.data;
}

export async function putSellerCollections(data) {
  const res = await api.put<{ product_ids: string[]; collection_ids: string[] }>(`ds/v2/seller-collections`, data);
  responseErrorHandler(res);
  return res.data;
}

export async function getUserCredit(userId?: number | string) {
  const res = await api.get<any>(`/ds/v2/customers/${userId}/credits`);
  responseErrorHandler(res);
  return res.data;
}

export async function getUserCreditDepositPreload(params?: any) {
  const res = await api.get<any>(`/ds/v2/customers/${params?.customer_id}/credit-deposit-preload`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getUserCreditTransactions(params?: any) {
  const res = await api.get<any>(`/ds/v2/credit-transactions`, _.merge({ page: 1, page_size: 3000 }, params));
  responseErrorHandler(res);
  return res.data;
}

export async function getUserCreditTransactionsExport(params?: any) {
  const res = await api.get<any>(`/ds/v2/credit-transactions/export`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateUserCredit(params?: any) {
  const res = await api.post<any>(`/ds/v2/customers/${params?.customer_id}/credit-deposits`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getInventoryLocations(shopId: string) {
  const res = await api.get<any>(`/ds/v2/shop/${shopId}/locations`);
  responseErrorHandler(res);
  return res.data;
}

export async function getShopSettings(shopId: string) {
  const res = await api.get<any>(`/ds/v2/shop/${shopId}/settings`);
  responseErrorHandler(res);
  return res.data;
}

export async function updateShopSettings(shopId: string, params: any) {
  const res = await api.put<any>(`/ds/v2/shop/${shopId}/settings`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function applyDefaultShippingAddress(shopId) {
  const res = await api.post(`/ds/v2/shop/${shopId}/refresh_shipping_address`);
  responseErrorHandler(res);
  return res.data;
}

export async function sellerCollectionByCollection(params: {
  collectionHandle: string;
  sellerCollectionIds: string[];
}) {
  const res = await api.post(`/ds/v2/collections/${params.collectionHandle}/seller-collections`, {
    seller_collection_ids: params.sellerCollectionIds,
  });
  responseErrorHandler(res);
  return res.data;
}

export async function getPrivateInventoryTrans(params) {
  const res = await api.get<any>(`/ds/v2/private-inventory-transactions`, { ...params });
  responseErrorHandler(res);
  return res.data;
}

export async function getPrivateInventoryBalance(params) {
  const res = await api.get<any>(`/ds/v2/private-inventory-balance`, { ...params });
  responseErrorHandler(res);
  return res.data;
}

export async function getPrivateShippingAddresses() {
  const res = await api.get<any>(`/ds/v2/private-inventory-shipping-addresses`);
  responseErrorHandler(res);
  return res.data;
}

export async function getCheckoutLimit(): Promise<{
  data: {
    limits: {
      collection_handle: string;
      min_quantity: number;
      collection_title: string;
      product_ids: number[];
    }[];
  };
}> {
  const res = await api.get<any>(`/ds/v2/app/checkout-limit`);
  responseErrorHandler(res);
  return res.data;
}
