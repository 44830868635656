$actionsBarHeight: 55px;
$border-color: #c7c7c7;

.ns_page_product_request_success_main {
  width: 590px;
  padding: 42px 0 422px;
  margin: 0 auto;
  font-size: var(--font-size3);
  color: var(--color-black);

  .content {
    padding: var(--spacing-xxl);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    background-color: var(--color-white);
    .icon {
      text-align: center;
      :global {
        svg {
          width: 30px;
          height: 30px;
          color: var(--color-primary-medium);
        }
      }
    }
    .title {
      font-weight: 500;
      text-align: center;
      padding: var(--spacing-m) 0;
    }
    .desc {
      line-height: 22.4px;
      text-align: center;
    }
    .btn_wrapper {
      display: flex;
      justify-content: center;
      padding-top: var(--spacing-xxl);
      :global {
        button {
          width: 200px;
          &:first-child {
            margin-right: var(--spacing-xxl);
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0;
      box-shadow: none;
      .content_info {
        height: calc(100vh - var(--nav-bar-mobile-height));
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--spacing-xxl);
      }
      .btn_wrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px 20px;
        background-color: var(--color-white);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      width: 100%;
      padding: 0;
      background-color: var(--color-white);
    }
  }

  @at-root .ns_page_product_request_success_active {
    @media screen and (min-width: 769px) {
      --app-layout-padding-top: 0;
      --app-layout-padding-bottom: 0;
      .secondary_nav_bar {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      :global {
        .app-layout {
          --app-layout-padding-top: 0;
          --app-layout-padding-bottom: 0;
          --color-gray1: #fff;
        }
        .nav_bar {
          display: none;
        }
        .tab_bar {
          display: none;
        }
      }
    }
  }
}
