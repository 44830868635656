.combine_settings_com {
  &.btn {
    background-color: #e8e8e8;
    border-radius: 4px;
    padding: 7px 8px;
    display: flex;
    align-items: center;
    border: none;
    color: #424242;
    .text {
      padding-left: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  > span {
    display: flex;
    align-items: center;
  }
}

.order_page_modal_combine_settings {
  .form {
    .holding_sec_desc {
      padding-top: 8px;
    }
    .form_item_wrapper {
      margin-bottom: 18px;
    }
    .form_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      .left {
        color: #000;
        font-weight: 500;
        font-size: 16px;
      }
    }
    .form_content {
      font-size: 14px;
      line-height: 1.3;
      color: #999;
      .live_selling_button {
        cursor: pointer;
        color: #424242;
        padding-top: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        .money {
          font-size: 20px;
          padding-right: 4px;
        }
        .text {
          text-decoration: underline;
        }
      }
      .delay_processing_ms_input {
        margin: 0 4px;
        width: 44px;
      }
      .delay_include_sample {
        display: flex;
        align-items: center;
        margin-top: 16px;
        .checkbox_desc {
          padding-left: 8px;
          color: #000;
        }
      }
    }
  }

  :global {
    .modal__body {
      margin: 0 auto;
      max-width: var(--content-small-max-width);
    }
    .modal__footer {
      text-align: center;
      button {
        width: 200px;
      }
    }
  }
}

.combine_settings_delay_processing_message_wrapper {
  @media screen and (min-width: 769px) {
    &:global(.ant-message-notice) {
      :global {
        .ant-message-notice-content {
          width: 480px;
        }
      }
    }
  }
}

.delay_processing_off_dialog_wrapper {
  &:global(.adm-dialog) {
    :global {
      .adm-dialog-wrap {
        .adm-dialog-footer {
          button {
            color: #000;
          }
        }
      }
    }
  }
}
