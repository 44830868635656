.ns_com_notice_bar_main {
  height: var(--notice-bar-height);
  padding: var(--spacing-m) var(--spacing-l);
  background: var(--color-black);

  .content {
    position: relative;
    margin: 0 auto;
  }

  .ns_or_swiper {
    --swiper-navigation-color: rgba(255, 255, 255, 0.5);
    --swiper-navigation-size: 12px;

    :global {
      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
      }
    }
  }

  .notice {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 0 16px;
    color: var(--color-white);
    font-size: var(--font-size3);
    line-height: 20px;
    text-align: center;

    & > a {
      flex: 1;
    }

    a {
      color: inherit !important;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
