import { message } from 'antd';
import { Badge } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import Modal, { Props as ModalProps } from '@/components/Modal';
import Notification from '@/components/Notification';
import Stepper from '@/components/Stepper';
import { SESSION_STORAGE_KEYS } from '@/constants/app';
import { useDesktop } from '@/hooks';
import useUpdateCart from '@/hooks/queries/useUpdateCart';
import { getVariantByOptions, getVariantOptions, getVariantsStockSummary } from '@/utils/biz';

import css from './index.module.scss';

export interface ProductAddToCartDialogProps extends Omit<ModalProps, 'children'> {
  product?: any;
  defaultVariant?: any;
}

export function ProductAddToCartDialog({
  className,
  product,
  defaultVariant,
  onClose,
  ...restProps
}: ProductAddToCartDialogProps): JSX.Element {
  const isDesktop = useDesktop();
  const navigate = useNavigate();
  const updateCart = useUpdateCart();
  const [currentVariant, setCurrentVariant] = useState<any>(defaultVariant);
  const [selectedVariants, setSelectedVariants] = useState<any>({});
  const [notificationVisible, setNotificationVisible] = useState(false);
  const options = product?.product?.options;
  const variants = product?.product?.variants;
  const summary = useMemo(() => getSummary(selectedVariants), [selectedVariants]);
  const variantsStockSummary = useMemo(() => getVariantsStockSummary(variants), [variants]);
  const addToCartItems = _.map(_.values(selectedVariants), (item) => _.omit(item, 'variant'));
  const isProductDroppingSoon = product?.product?.drop_soon?.at && moment().isBefore(product?.product?.drop_soon?.at);
  const isOverDroppingSoonMaxQty = isProductDroppingSoon && summary.total >= product?.product?.drop_soon?.qty_limit;

  const handleVariantOptionChange = (group: any, value: string) => {
    const changedOptionKey = `option${group?.position}`;
    const newVariantOptions = getVariantOptions({ ...currentVariant, [changedOptionKey]: value });
    const newVariant = getVariantByOptions(variants, newVariantOptions);
    if (_.isNil(newVariant?.id)) return;

    setCurrentVariant(newVariant);
  };

  const handleSelectedVariantsChange = (variant: any, qty: number) => {
    setSelectedVariants((prevState: any) => {
      const newState = { ...prevState, [variant?.id]: { variant, qty, variant_id: Number(variant?.id) } };
      if (qty <= 0) delete newState[variant?.id];
      return newState;
    });
  };

  const updateSessionStorage = () => {
    try {
      const cartOrderParams = sessionStorage.getItem(SESSION_STORAGE_KEYS.cartOrderParams);
      if (cartOrderParams) {
        const { lineItems: oldLineItems, note } = JSON.parse(cartOrderParams);
        const newToAddLineItems = addToCartItems.filter(
          (vid) => !oldLineItems.map(({ variant_id }) => variant_id).includes(vid)
        );
        const newCartOrderParams = {
          lineItems: [...newToAddLineItems, ...oldLineItems],
          note,
        };
        sessionStorage.setItem(SESSION_STORAGE_KEYS.cartOrderParams, JSON.stringify(newCartOrderParams));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleAddToCart = () => {
    updateCart.mutate(
      { items: addToCartItems, append: true },
      {
        onSuccess: () => {
          setNotificationVisible(true);
          onClose?.();
          setSelectedVariants({});
          updateSessionStorage();
        },
        onError: (error) => {
          message.error((error as Error)?.message || 'Add to Cart Failure!');
        },
      }
    );
  };

  const isVariantOutOfStock = (group: any, value: string): boolean => {
    const { position = 1 } = group;
    const option1 = currentVariant?.option1;
    const option2 = currentVariant?.option2;

    if (position === 1) return variantsStockSummary?.[value]?.total < 1;
    if (position === 2) return variantsStockSummary?.[option1]?.children?.[value]?.total < 1;
    if (position === 3) return variantsStockSummary?.[option1]?.children?.[option2]?.children?.[value]?.total < 1;

    return false;
  };

  useEffect(() => {
    defaultVariant && setCurrentVariant(defaultVariant);
  }, [defaultVariant]);

  useEffect(() => {
    // default set the current selected variant qty to 1 if it's not set
    if (
      restProps.visible &&
      defaultVariant?.inventory_quantity > 0 &&
      _.isEmpty(selectedVariants?.[defaultVariant?.id])
    ) {
      handleSelectedVariantsChange(defaultVariant, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVariant, restProps.visible]);

  return (
    <>
      <Modal
        className={cn(css.ns_com_product_add_to_cart_dialog_main, className)}
        title="Add to Cart"
        fullscreen="mobile"
        footer={
          <div className={css.ns_or_modal_footer}>
            <div className={css.summary}>
              <div>
                <strong>{summary.total}</strong> Item{summary.total > 1 && 's'}
              </div>
              <div>
                Total: <strong>${summary.price.toFixed(2)}</strong>
              </div>
            </div>
            {isProductDroppingSoon && (
              <div className={cn(css.dropping_soon_message, { [css.error]: isOverDroppingSoonMaxQty })}>
                Max of {product?.product?.drop_soon?.qty_limit ?? 0} samples per listing during the sample buying period
              </div>
            )}
            <Button
              block
              color="primary"
              shape="rounded"
              loading={updateCart.isLoading}
              loadingText="Adding to Cart"
              disabled={summary.total < 1}
              onClick={handleAddToCart}
            >
              Add to Cart
            </Button>
          </div>
        }
        onClose={onClose}
        {...restProps}
      >
        <div className={css.content}>
          <div className={css.product_options}>
            {options?.map((group: any, index: number) => {
              const isLastGroup = index === options.length - 1;
              return (
                <div
                  key={group?.name}
                  className={cn(css.group, {
                    [css.has_extra]: isLastGroup,
                  })}
                >
                  <div className={css.title}>{_.startCase(group?.name)}</div>
                  <div className={css.options}>
                    {group?.values?.map((value: string) => {
                      const optionKey = `option${group?.position}`;
                      const variant = getVariantByOptions(
                        variants,
                        getVariantOptions({ ...currentVariant, [optionKey]: value })
                      );
                      const badgeTotal = summary.optionGroupedTotal?.[optionKey]?.[value] ?? 0;
                      const isOutOfStock = isVariantOutOfStock(group, value);
                      const currentValue = selectedVariants?.[variant?.id]?.qty ?? 0;
                      const maxValue = isProductDroppingSoon
                        ? currentValue + (product?.product?.drop_soon?.qty_limit || 0) - summary?.total
                        : undefined;

                      return (
                        <div
                          key={value}
                          className={cn(css.item, {
                            [css.active]: currentVariant?.[optionKey] === value,
                            [css.out_of_stock]: isOutOfStock,
                          })}
                          onClick={isLastGroup ? undefined : () => handleVariantOptionChange(group, value)}
                        >
                          <div className={css.label}>
                            {value}
                            {!isLastGroup && badgeTotal > 0 && <Badge content={`x${badgeTotal}`} />}
                          </div>
                          {isLastGroup && (
                            <div className={css.extra}>
                              <div>${variant?.price?.toFixed(2)}</div>
                              <div>
                                <Stepper
                                  min={0}
                                  max={maxValue}
                                  digits={0}
                                  value={currentValue}
                                  disabled={isOutOfStock}
                                  onChange={(qty) => handleSelectedVariantsChange(variant, qty)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>

      <Notification
        placement={isDesktop ? 'topRight' : 'top'}
        className={css.ns_or_notification}
        color="black"
        visible={notificationVisible}
        textAlign="left"
        zIndex={2000}
        title={
          <div className={css.title_wrapper}>
            <span>Added to cart</span>
            <Button
              color="white"
              fill="none"
              underline
              style={{ padding: 0 }}
              onClick={() => {
                setNotificationVisible(false);
                navigate('/cart');
              }}
            >
              View Cart
            </Button>
          </div>
        }
        onClose={() => setNotificationVisible(false)}
      />
    </>
  );
}

function getSummary(selectedVariants: any): { total: number; price: number; optionGroupedTotal: any } {
  return _.values(selectedVariants).reduce(
    (acc, item) => {
      const { variant, qty } = item;
      const options = getVariantOptions(variant);

      acc.total += qty;
      acc.price += qty * (variant?.price ?? 0);

      // group total by option
      options.reduce((group, option, index) => {
        const optionKey = `option${index + 1}`;
        _.set(group, [optionKey, option], (group?.[optionKey]?.[option] ?? 0) + qty);
        return group;
      }, acc.optionGroupedTotal);

      return acc;
    },
    { total: 0, price: 0, optionGroupedTotal: {} }
  );
}

export default ProductAddToCartDialog;
