$prefixCls: collection-detail-page;
$sidebarWidth: 210px;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      --app-layout-padding-bottom: 0;

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: var(--content-max-width);
    padding-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
  }

  &__collection_desc {
    margin: 0 12px;
    border-top: 1px solid #dcdcdc;
    margin-top: 12px;
    padding-top: 12px;
    &_text {
      &_weight {
        font-weight: 500;
      }
      &_line_through {
        text-decoration: line-through;
      }
      &_under_line {
        text-decoration: underline;
      }
    }
  }

  &__product_filters_bar_wrapper {
    margin: 0 auto;
    // 用于 filter bar 在 sticky 时 宽度与列表类型保持一致
    max-width: var(--content-max-width);

    .sticky-outer-wrapper.active & {
      margin-top: 0 !important;
    }
  }

  &__content_wrapper {
    &.sidebar_pc {
      .sticky-outer-wrapper.active {
        .#{$prefixCls}__product_filters_bar_wrapper {
          margin-left: $sidebarWidth;
        }
      }
    }
  }

  .sticky-outer-wrapper.active .sticky-inner-wrapper {
    width: 100% !important;
    padding: 0 48px;
    left: 0;
    background: var(--color-gray1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  }

  &__product_filters_bar_wrapper.brand {
    border-top: 1px solid #ebebeb;
  }

  &__actions_extra_wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &-share-btn {
      &:global(.adm-button.adm-button-default) {
        border: none;
      }
    }
  }

  // sidebar
  &__content_wrapper {
    &.sidebar_pc {
      display: flex;
      padding: 0 48px;
      max-width: calc(var(--content-max-width) + 300px);
      margin: 0 auto;
      .#{$prefixCls}__content {
        flex: 1;
        max-width: none;
      }
      .#{$prefixCls}__sidebar_wrapper {
        padding-top: var(--spacing-l);
        padding-bottom: var(--spacing-l);
        padding-right: 26px;
        width: $sidebarWidth;
        box-sizing: border-box;
      }
    }
    &.sidebar_m {
      .#{$prefixCls}__sidebar_m_wrapper {
        padding-bottom: 12px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__toolbar {
      padding: 0;
    }
    &__product_filters_bar_wrapper {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__product_filters_bar_wrapper.brand {
      margin-top: 16px;
    }
    &__collection_desc {
      margin: 0;
      margin-top: 12px;
    }

    &__product-list {
      padding: 0;

      &-list-view {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }

    &__actions_extra_wrapper {
      justify-content: center;
    }

    &__collection_desc {
      border: none;
      margin-top: 0;
      padding-top: 0;
    }

    &__footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 12px 0;
      background: #fff;
      z-index: 1;
      button {
        width: 160px;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__content {
      padding: var(--spacing-l) 48px;
    }
    &__content_wrapper {
      &.sidebar_pc {
        &__content {
          padding: var(--spacing-l) 0;
        }
      }
    }
  }
}
