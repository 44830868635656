import { useMutation, useQueryClient } from 'react-query';

import { tokenStorage } from '@/hooks/auth';
import { verifyEmailCode } from '@/services/lynx';

export default function useVerifyEmailCode() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof verifyEmailCode>[0]) => verifyEmailCode(params), {
    onSuccess: (data: any) => {
      tokenStorage.setToken(data?.data?.access_token);
      queryClient.setQueryData('authUser', data?.data?.customer);

      queryClient.invalidateQueries('authUser');
    },
  });
}
