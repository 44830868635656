.ns_com_seller_collection_title_edit_dialog_main {
  .toggle_public {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .public_title {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .public_desc {
    color: #999999;
    font-weight: 400;
  }

  .input {
    :global {
      .ant-input {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
  .modal_like_board_edit {
    :global {
      .ant-form-item-label > label {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .ck_editor {
    a {
      color: var(--color-black);
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 769px) {
    .modal__container {
      width: var(--content-small-max-width);
    }
  }
}
