import cn from 'classnames';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';

import css from './index.module.scss';

interface Props {
  children?: React.ReactElement;
  className?: string;
}

const App = ({ children, className }: Props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  if (children) return <div onClick={handleClick}>{children}</div>;

  return (
    <Button
      className={cn(css.ns_com_back_to_last_page_button_wrapper, className)}
      onClick={handleClick}
      icon={<FiChevronLeft />}
      fill="none"
      shape="rounded"
    />
  );
};

export default App;
