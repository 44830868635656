import cn from 'classnames';
import React from 'react';

import Image from '@/components/Image';
import { getPriceRange } from '@/utils/util';

import css from './ProductItem.module.scss';

export interface ProductItemProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any;
}

function ProductItem({ className, style, data }: ProductItemProps): JSX.Element {
  const { cover = '', title = '', id, price_min, price_max } = data;
  const pdpURL = `/products/${id}`;

  return (
    <a className={css.product_item_wrapper} href={pdpURL} rel="noreferrer">
      <div className={cn(css.product_item, className)} style={style}>
        <div className={css.product_item_cover}>
          <Image className={css.product_item_cover_image} src={cover} fit="cover" lazy />
        </div>
        <div className={css.product_item_info}>
          <div className={css.product_item_title}>{title}</div>
          <div className={css.product_item_price}>{getPriceRange(price_min, price_max)}</div>
        </div>
      </div>
    </a>
  );
}

export default ProductItem;
