.ns_com_report_image_copyright_modal_main {
  .ns_com_report_image_copyright_modal_footer.btn_wrapper {
    display: flex;
    justify-content: center;
    :global {
      button {
        width: 200px;
        &:first-child {
          margin-right: var(--spacing-xxl);
        }
      }
    }
  }

  .form_report_image_copyright.form_wrapper {
    .title_wrapper {
      > .title {
        font-size: 16px;
        font-weight: 500;
      }
      padding-bottom: 12px;
    }
    .toggle_public {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .public_title {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .public_desc {
      color: #999999;
      font-weight: 400;
    }
    .custom_form_label {
      padding-bottom: 4px;

      &.img_url {
        padding-bottom: 0;
      }
      .main_text {
        font-size: 16px;
      }
      .desc {
        font-weight: 500;
      }
      .required_mark {
        color: var(--ant-error-color);
      }
    }
    .issue_img {
      width: 68px;
      max-height: 100px;
      border-radius: 4px;
    }
    .upload_img_wrapper {
      width: fit-content;
      padding-top: 12px;
      &.with_img {
        padding-top: 0;
      }
      :global {
        .adm-image {
          width: 68px;
        }
      }
    }
    &:global(.ant-form) {
      :global {
        .ant-form-item {
          margin-bottom: 12px;
        }
        .ant-form-item-label {
          font-weight: 500;
          padding-bottom: 0;
          label {
            height: auto;
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .modal__body {
      padding: 12px;
    }
    .form_report_image_copyright.form_wrapper {
      .custom_form_label {
        padding-bottom: 12px;
      }
    }
  }
}
