import { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { OrderItem } from '@/pages/Orders/types';

import OrderProduct from './OrderProduct';
import css from './OrderProducts.module.scss';

function OrderProducts({ data, order }: { data: any[]; order: OrderItem }): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const maxDisplay = 2;
  const showMore = data.length > maxDisplay;
  const toggleText = expanded ? 'Show Less' : 'Show More';
  const icon = expanded ? <FiChevronUp /> : <FiChevronDown />;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={css.order_products}>
      {data?.map((item, index) => {
        if (!expanded && index > maxDisplay - 1) {
          return null;
        }
        const { id, shopify_variant_id } = item;
        // eslint-disable-next-line react/no-array-index-key
        return <OrderProduct key={`${id}-${shopify_variant_id}-${index}`} data={item} status={order?.status} />;
      })}
      {showMore ? (
        <div className={css.expand_button} onClick={toggleExpand}>
          <span>{toggleText}</span>
          {icon}
        </div>
      ) : null}
    </div>
  );
}

export default OrderProducts;
