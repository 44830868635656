export const SHOW_GUIDE_STATE = 'SHOW_GUIDE_STATE';

export const QUERY_KEYWORDS = 'search';

export const QUERY_STATE = 'state';

export const DEFAULT_OPTIONS_PRICE = 'price';
export const DEFAULT_OPTIONS_PRICE_COST = 'cost_price';

export const percentField = 'percent';
export const priceTypeField = 'priceType';

export const DEFAULT_OPTIONS = [
  {
    value: DEFAULT_OPTIONS_PRICE,
    label: 'Shopify Price',
  },
  {
    value: DEFAULT_OPTIONS_PRICE_COST,
    label: 'Shopify Cost',
  },
];

export const calculateText = {
  [DEFAULT_OPTIONS_PRICE]: 'Shopify Price',
  [DEFAULT_OPTIONS_PRICE_COST]: 'Shopify Cost',
};
