$prefixCls: kiwi-wallet-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__section {
    margin-top: var(--spacing-l);
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
    background: var(--color-white);

    &-title {
      padding: var(--spacing-s) var(--spacing-l);
      color: var(--color-black);
      font-weight: 500;
      border-bottom: 1px solid #f5f5f5;
      border-radius: 4px;
    }
  }

  &__layout-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tooltip {
    width: auto;
    max-width: 100%;
  }

  &__empty {
    padding: var(--spacing-xxl) var(--spacing-l);
    color: var(--color-gray4);
    text-align: center;
    font-size: var(--font-size3);
    line-height: 1.4;
  }

  &__wallet-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--spacing-l);

    &-title {
      margin-bottom: var(--spacing-xs);
      color: var(--color-black);
      font-size: var(--font-size14);
      line-height: 1.3;
    }

    &-amount {
      font-size: 30px;
      line-height: 1.2;
    }

    &-items {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: var(--spacing-xs);

      &-title {
        display: flex;
        align-items: center;
        color: var(--color-black);
        font-size: var(--font-size14);
        line-height: 1.3;

        .react-icons {
          margin-left: var(--spacing-xs);
        }
      }

      &-amount {
        font-size: var(--font-size14);
        line-height: 1.3;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: var(--spacing-l);
      padding-top: var(--spacing-l);
      border-top: 1px solid #e8e8e8;

      .button {
        width: 250px;
      }
    }
  }

  &__auto-reload {
    padding: var(--spacing-l);

    &-description {
      color: var(--color-gray4);
      font-size: var(--font-size14);
      line-height: 1.3;
    }

    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--color-black);
      font-size: var(--font-size14);
      line-height: 1.3;

      .button {
        padding: 0;
        font-weight: 400;
      }
    }

    &-dialog {
      .modal__footer {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        gap: var(--spacing-m);
      }
    }

    &-form {
      color: var(--color-black);
      font-size: var(--font-size14);
      line-height: 1.3;

      label {
        font-weight: 500;
      }

      .ant-input-number {
        width: 100%;
        border-color: var(--color-gray4);
      }

      &-bank-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing-m);

        &-field {
          margin-bottom: 0;

          &--empty {
            .ant-form-item-label {
              padding-bottom: 0;
            }
            .ant-form-item-control {
              display: none;
            }
          }
        }

        &-item {
          display: flex;
          position: relative;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background: var(--color-white);
          cursor: pointer;

          & > * + * {
            margin-left: var(--spacing-l);
          }

          .adm-radio {
            --icon-size: 20px;
            color: #424242;

            // extend radio box touchable area to item row
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          & > .react-icons {
            color: var(--color-gray4);
            font-size: 21px;
          }

          &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            color: var(--color-black);
          }

          &-title {
            font-size: var(--font-size14);
            line-height: 1.3;
          }

          &-label {
            margin-bottom: var(--spacing-xs);
            padding: var(--spacing-xxs) var(--spacing-xs);
            color: #424242;
            font-size: 12px;
            line-height: 1;
            transform: scale(0.75);
            transform-origin: 0 100%;
            background: #f5fce5;
            border-radius: 2px;
          }

          &-description {
            color: var(--color-gray4);
            font-size: var(--font-size12);
            line-height: 1.3;
          }
        }
      }

      &-add-bank-btn {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: auto !important;
        color: #424242 !important;
        font-size: var(--font-size14) !important;
        box-shadow: none !important;

        .button__inner-wrapper {
          justify-content: flex-start;

          .button__icon {
            position: static !important;
            left: auto !important;
            font-size: 30px !important;
          }

          .button__text {
            text-align: left;
          }
        }

        &-label {
          margin-top: var(--spacing-xxs);
          padding: var(--spacing-xxs) var(--spacing-xs);
          color: #424242;
          font-size: 12px;
          line-height: 1;
          transform: scale(0.75);
          transform-origin: 0 0;
          background: #f5fce5;
          border-radius: 2px;
          white-space: nowrap;
        }
      }
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-m);
    margin: 0 4px;
    padding: var(--spacing-m);
    border-bottom: 1px solid #f5f5f5;

    .ant-input-affix-wrapper {
      border-radius: 4px;
      border-color: var(--color-gray4);
    }

    .ant-select {
      .ant-select-selector {
        border-color: var(--color-gray4);

        .ant-select-selection-placeholder {
          color: var(--color-black);
          padding-right: 11px;
        }
        .ant-select-selection-item {
          padding-left: 18px;
          padding-right: 0px;
        }
      }

      .ant-select-arrow {
        color: var(--color-black);
        right: auto;
        left: 11px;
      }
    }

    .button {
      width: 30px;
    }
  }

  &__table {
    margin: 0 4px;

    .ant-table-thead > tr > th {
      background: none;
      border-bottom-color: #f5f5f5;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody > tr > td {
      border-bottom-color: #f5f5f5;
    }

    .ant-pagination {
      padding: 0 var(--spacing-m);
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      max-width: var(--content-small-max-width);
    }

    &__title {
      display: block;
    }

    &__description {
      padding: 0;
    }
  }
}
