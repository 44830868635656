.ns_com_import_product_dialog_main {
  .options {
    position: relative;
    margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-l);

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      padding: 0 var(--spacing-l);
      border-bottom: 1px solid #c7c7c7;

      .label {
        font-size: 16px;
        line-height: 1.4;
        color: var(--color-black);
        margin-right: var(--spacing-m);
      }

      .control {
        flex: 1;
        min-width: 0;

        :global {
          .ant-select {
            width: 100%;
            .ant-select-selector {
              padding: 0 !important;

              .ant-select-selection-placeholder {
                text-align: right;
                right: 0;
              }

              .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                  background: #ebf9cf;
                  border-color: transparent;
                  border-radius: 4px;
                }
                .ant-select-selection-item-remove {
                  font-size: 16px;
                }
              }
            }

            .ant-select-arrow {
              right: 0;
            }
          }
        }

        .dropdown {
          left: 0 !important;
          background-color: var(--color-gray1);

          :global {
            .ant-select-item {
              font-size: var(--font-size3);
            }

            .ant-select-item-option {
              align-items: center;
            }

            .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
              background-color: var(--ant-primary-1);
            }

            .ant-select-item-option-selected:not(.ant-select-item-option-disabled, .ant-select-item-option-active) {
              background-color: transparent;
            }

            .ant-select-item-option-state {
              font-size: 20px;
              line-height: 0;
              margin-left: var(--spacing-m);
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    :global {
      .modal__container {
        min-height: min(520px, calc(100% - var(--spacing-l) * 2));
        width: var(--content-small-max-width);
      }

      .modal__footer .button {
        margin: 0 auto;
        width: 250px;
      }
    }
  }
}
