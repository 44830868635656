import useSettings from '../queries/useSettings';

const useBrandSettings = () => {
  const settings = useSettings();
  if (!Array.isArray(settings?.data?.data?.brand?.data)) return [];
  const brandSettings = settings?.data?.data?.brand?.data;
  return brandSettings.map(({ name, handle, handles, tag, bg_url, logo_url, body_html }) => ({
    name,
    logoURL: logo_url,
    bgURL: bg_url,
    bodyHtml: body_html,
    handle,
    handles,
    tag,
  }));
};

export default useBrandSettings;
