$prefixCls: login-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app {
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(206, 228, 106, 0.5) 166%);
    }
  }

  &__content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr auto;
    place-items: center;
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  &__header {
    display: grid;
    gap: var(--spacing-l);
    color: var(--color-black);
    font-weight: 500;
    text-align: center;
    word-break: break-all;

    &-icon {
      font-size: 60px;
      line-height: 0;
    }
  }

  &__title {
    margin: 0 auto 70px;
    color: var(--color-black);
    font-weight: 500;
    font-size: 30px;
    line-height: 1.15;
    text-align: center;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 1.2;
  }

  &__input {
    display: none;
    position: absolute;
    left: 0;
    top: 0;

    &-wrapper {
      position: relative;
      width: 330px;
      height: 50px;
    }

    &-phone {
      #{$root}--phone-view & {
        display: flex;
        z-index: 2;
      }
      #{$root}--code-view & {
        display: none !important;
      }
    }

    &-code {
      &#{&} {
        width: 330px;
        height: 50px;
        color: var(--color-black);
        font-size: var(--font-size5);
        line-height: 1.15;
        text-align: center;
        letter-spacing: 0.4em;
        border: 3px solid var(--color-gray5) !important;
        border-right-width: 3px !important;
        border-radius: 8px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
        z-index: 0;

        #{$root}--code-view & {
          display: block;
          z-index: 2;
        }
        #{$root}--phone-view & {
          display: none !important;
        }
      }
    }

    #{$root}--has-error & {
      border-color: var(--color-danger) !important;
    }
  }

  &__message {
    margin: 24px auto;
    min-height: 22px;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
    text-align: center;
  }

  &__error-message {
    margin: var(--spacing-l) auto;
    min-height: 20px;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3;
    text-align: center;

    #{$root}--has-error & {
      color: var(--color-danger);
    }
  }

  &__marketing-consent {
    max-width: 324px;
    display: flex;
    align-items: start;
    margin-bottom: 24px;
    box-sizing: border-box;
    border: 1px solid transparent;
    padding: 6px;
  }

  &__marketing-consent-tips {
    margin-left: 6px;
  }

  &__marketing-consent-error {
    border: 1px solid red;
  }

  &__continue-btn {
    width: 250px;
  }

  &__actions {
    margin-top: 24px;

    .ant-divider {
      border-color: var(--color-black);
      margin: 0;
    }

    .adm-button-disabled.adm-button-fill-none {
      background: transparent !important;
      text-decoration: none !important;
    }
  }

  &__footer-btn {
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;

    &-description {
      color: rgba(0, 0, 0, 0.5);
      font-size: var(--font-size1);
      line-height: 1.4;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__content {
      max-width: var(--content-max-width);
    }
  }
}
