import _ from 'lodash';
import { useMemo } from 'react';

export enum SalesTimerPos {
  PDP = 'pdp', // 1
  PLP = 'plp', // 2
}

const POS_LIST = [SalesTimerPos.PLP, SalesTimerPos.PDP];
// eslint-disable-next-line no-bitwise
const matchedPos = (posIdx, allPos) => ((1 << posIdx) & allPos) === 1 << posIdx;

const useSalesTimerShow = ({ pos, propPos }: { pos: SalesTimerPos; propPos: any }) => {
  const isPosMatched = useMemo(() => {
    if (_.isUndefined(pos)) return true;
    const curIdxInPosList = POS_LIST.indexOf(propPos);
    return matchedPos(curIdxInPosList, pos);
  }, [pos, propPos]);

  return isPosMatched;
};

export default useSalesTimerShow;
