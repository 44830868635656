import { Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import useBindEmail from '@/hooks/queries/useBindEmail';
import useSendBindEmailCaptcha from '@/hooks/queries/useSendBindEmailCaptcha';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ViewType = 'email' | 'code';

function EmailBind({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const isAutoBind = !_.isEmpty(email) && !_.isEmpty(code);
  const bindEmail = useBindEmail();
  const sendEmailCaptcha = useSendBindEmailCaptcha();
  const [currentView, setCurrentView] = useState<ViewType>(isAutoBind ? 'code' : 'email');
  const [emailValue, setEmailValue] = useState(isAutoBind ? (email as string) : '');
  const [codeValue, setCodeValue] = useState(isAutoBind ? (code as string) : '');
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUrl = searchParams.get('redirect') || '/';
  const shouldDisableContinue =
    (currentView === 'email' && _.isEmpty(emailValue.trim())) ||
    (currentView === 'code' && codeValue.trim().length < 6);

  const handleBindEmail = () => {
    bindEmail.mutate(
      { email: emailValue, captcha: codeValue },
      {
        onSuccess: () => {
          _.startsWith(redirectUrl, '/')
            ? navigate(redirectUrl, { replace: true })
            : window.location.replace(redirectUrl);
        },
        onError: () => setErrorMessage('Invalid Email Code'),
      }
    );
  };

  const sendCode = (onSuccess?: () => void, onError?: () => void) => {
    setCodeValue('');
    setErrorMessage('');
    sendEmailCaptcha.mutate(
      { email: emailValue },
      {
        onSettled: (data, error) => (error ? onError?.() : onSuccess?.()),
      }
    );
  };

  const handleContinue = () => {
    if (currentView === 'email') {
      sendCode(
        () => setCurrentView('code'),
        () => setErrorMessage('Code failed to send')
      );
    } else if (currentView === 'code') {
      handleBindEmail();
    }
  };

  const handleResetEmail = () => {
    setCurrentView('email');
    setEmailValue('');
    setCodeValue('');
  };

  // auto bind email (from email link scene) when has email and code params
  useEffect(() => {
    isAutoBind && handleBindEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoBind]);

  return (
    <div
      className={cn(prefixCls, className, `${prefixCls}--${currentView}-view`, {
        [`${prefixCls}--has-error`]: !!errorMessage,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Email Bind</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__form-wrapper`}>
          <h1 className={`${prefixCls}__title`}>Bind an Email</h1>
          <div className={`${prefixCls}__message`}>
            {currentView === 'code' ? `Enter the code sent to ${emailValue}` : null}
          </div>
          <div className={`${prefixCls}__input-wrapper`}>
            <Input
              className={cn(`${prefixCls}__input`, `${prefixCls}__input-email`)}
              type="email"
              placeholder="email@example.com"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              onPressEnter={handleContinue}
            />
            <Input
              className={cn(`${prefixCls}__input`, `${prefixCls}__input-code`)}
              type="tel"
              maxLength={6}
              pattern="[0-9]*"
              placeholder="000000"
              value={codeValue}
              onChange={(e) => e.target.validity.valid && setCodeValue(e.target.value)}
              onPressEnter={handleContinue}
            />
          </div>
          <div className={`${prefixCls}__error-message`}>{errorMessage}</div>
          <Button
            color="main"
            shape="rounded"
            loading={(sendEmailCaptcha.isLoading && currentView === 'email') || bindEmail.isLoading}
            loadingText={currentView === 'email' ? 'Sending' : 'Binding'}
            disabled={shouldDisableContinue}
            onClick={handleContinue}
          >
            Continue
          </Button>

          <div className={`${prefixCls}__footer`}>
            {currentView === 'code' && (
              <>
                <Button
                  fill="none"
                  loading={sendEmailCaptcha.isLoading}
                  loadingText="Sending"
                  onClick={() => sendCode()}
                >
                  Resend Code
                </Button>
                <Button fill="none" onClick={handleResetEmail}>
                  Use Another Email
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailBind;
