const UpArrow = ({
  fill = 'black',
  stroke = '#424242',
  width = '10',
  height = '9',
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path d="M9.81818 4.86682L4.86341 0L0 4.86682H3.11688V9H6.7013V4.86682H9.81818Z" fill={fill} />
  </svg>
);

export default UpArrow;
