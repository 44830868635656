.account_profile_page_active {
  .account_profile_page {
    .page_content {
      .main {
        color: var(--color-black);
        &.header {
          display: flex;
          justify-content: space-between;
        }
      }
      .desc {
        color: var(--color-gray4);
        padding-top: 10px;
      }
      .email {
        .section {
          padding: 12px;
        }
        .section ~ .section {
          border-top: 1px solid #e8e8e8;
        }
        :global {
          .ant-card-body {
            padding: 0;
          }
        }
      }

      :global {
        .ant-card {
          margin-top: 16px;
        }
      }
    }
  }
  .center {
    text-align: center;
  }
  .btn_box {
    button {
      width: 200px;
      margin: 0 auto;
    }
  }
  .modal_change_phone {
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        width: 200px;
      }
      .desc {
        word-break: break-word;
        text-align: center;
        margin-bottom: 24px;
        span {
          font-weight: 500;
        }
      }
      .desc.phone,
      .desc.code {
        margin-top: 140px;
      }
      &.email_login_enabled {
        .desc.password {
          margin-top: 140px;
        }
        .desc.phone {
          margin-top: 0;
        }
      }
    }
    .form_item_code {
      .code {
        color: var(--color-black);
        font-size: var(--font-size5);
        text-align: center;
        letter-spacing: 0.4em;
      }
    }
    .phone_input {
      border: 1px solid var(--color-gray4) !important;
      border-radius: 4px;
      box-shadow: none;
      input {
        height: 38px;
      }
    }
  }
  .modal_update_email {
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      .button {
        text-align: center;
        button {
          width: 200px;
        }
      }
      .desc {
        word-break: break-word;
        text-align: center;
        margin-bottom: 24px;
        span {
          font-weight: 500;
        }
      }
      .desc.email,
      .desc.code {
        margin-top: 140px;
      }
      &.email_login_enabled {
        .desc.password {
          margin-top: 140px;
        }
        .desc.email {
          margin-top: 0;
        }
      }
      .form_item_code {
        .code {
          color: var(--color-black);
          font-size: var(--font-size5);
          text-align: center;
          letter-spacing: 0.4em;
        }
      }
    }
  }
  .modal_edit_name {
    .modal__body {
      padding: 24px 12px;
    }
    .ant-form {
      .ant-form-item {
        margin-bottom: 16px;
      }
      .ant-form-item-label {
        font-weight: 500;
        padding-bottom: 6px;
        label {
          height: auto;
        }
      }
    }
  }
  .modal_email_login {
    .desc {
      padding-bottom: 12px;
      font-size: 16px;
    }
    button {
      width: 200px;
    }
  }
  .form_item {
    width: 350px;
  }

  :global {
    .app-layout {
      --app-layout-padding-bottom: 63px;
      background: #f5f5f5;

      // safe area bottom patch
      --app-layout-padding-bottom: calc(63px - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l)));

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
    .ant-card {
      .ant-card-head {
        padding: 0 12px;
        min-height: 34px;
        .ant-card-head-title {
          color: #000;
          font-weight: 500;
        }
      }
      .ant-card-head-title {
        padding: 6px 0;
      }
      .ant-card-extra {
        padding: 6px 0;
      }
      .ant-card-body {
        padding: 12px;
      }
    }
    .ant-form {
      .ant-input {
        border-color: var(--color-gray4);
      }
      .ant-input-affix-wrapper {
        border-radius: 4px;
        border-color: var(--color-gray4);
      }
      .ant-input-affix-wrapper-lg {
        padding: 5.5px 11px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .account_profile_page {
      .page_content {
        margin: 0 auto;
        padding: 34px var(--spacing-l);
        max-width: calc(var(--content-small-max-width) + var(--spacing-l) * 2);
      }
    }

    .secondary_nav_bar {
      display: none;
    }

    .modal_change_phone {
      .step {
        button {
          margin-top: 16px;
          margin-bottom: 12px;
        }
        .desc {
          margin-bottom: 28px;
          span {
            font-weight: 500;
          }
        }
        .desc.phone,
        .desc.code {
          margin-top: 0px;
        }
        &.email_login_enabled {
          .desc {
            margin-bottom: 12px;
          }
          .desc.password {
            margin-top: 0px;
          }
          .desc.phone {
            margin-top: 0;
          }
        }
      }
      :global {
        .modal__container {
          width: 600px;
        }
        .modal__body {
          padding: 12px 68px 0;
        }
      }
    }

    .modal_update_email {
      .step {
        .button {
          button {
            margin-top: 16px;
          }
        }
        .desc {
          margin-bottom: 28px;
          span {
            font-weight: 500;
          }
        }
        .desc.email,
        .desc.code {
          margin-top: 0px;
        }
        &.email_login_enabled {
          .desc {
            margin-bottom: 12px;
          }
          .desc.password {
            margin-top: 0px;
          }
          .desc.email {
            margin-top: 0;
          }
        }
      }
    }

    .modal_edit_name {
      .modal__body {
        padding: 20px 124px;
      }
      .ant-form {
        .ant-form-item {
          margin-bottom: 16px;
        }
        .ant-form-item-label {
          font-weight: 500;
          label {
            height: 40px;
          }
        }
      }
    }

    .modal_email_login {
      :global {
        .modal__container {
          width: 350px;
        }
      }
    }
  }
}
