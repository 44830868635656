import cn from 'classnames';

import Button from '@/components/Button';

import css from './index.module.scss';

type Props = {
  options: { label: string; value: string }[];
  active?: string;
  onChange?: (val: string) => void;
};
const StatusGroup = ({ options, active, onChange }: Props) => {
  if (!options) return null;

  const handleChange = (val?: string) => {
    const noCallback = val === active || !onChange;
    if (noCallback) return;
    onChange?.(val);
  };

  return (
    <div className={css.status_group_comp}>
      <div className={css.content}>
        {options.map(({ label, value }) => (
          <Button
            className={cn(css.btn, { [css.active]: active === value })}
            key={value}
            color="default"
            bold={false}
            onClick={() => handleChange(value)}
          >
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default StatusGroup;
