// 硬编码 collection handle，自定义不同的样式

const preOrderCollectionHandle = [
  // for test
  'newonetest',
  'open-preorders',
  'ready-to-ship-preorders',
  'closed-preorders',
  'preorder',
];

const p = 1;

export { p, preOrderCollectionHandle };
