.ns_com_pdp_hot_flash_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  padding: 12px;

  .hot_flash_desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
  }

  @at-root .is_m {
    border-radius: 0;
  }

  @at-root .is_pc {
    .ns_com_pdp_hot_flash_main {
      border-radius: 8px;
    }
  }
}
