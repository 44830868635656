import { AlphaStack } from '@shopify/polaris';
import cn from 'classnames';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import usePaymentMethods from '@/hooks/queries/usePaymentMethods';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import css from './index.module.scss';
import { PaymentMethodsDialogProps } from './types';

const ListView = React.lazy(() => import('./components/ListView'));
const MethodsView = React.lazy(() => import('./components/MethodsView'));
const EditView = React.lazy(() => import('./components/EditView'));

function PaymentMethodsDialog({ className, onClose, ...restProps }: PaymentMethodsDialogProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [footer, setFooter] = useState<any>();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const [, viewParam, typeParam] = searchParams.get('dialog')?.split('/') ?? [];

  // get view from search
  const view = useMemo(() => {
    if (!['list', 'add', 'methods'].includes(viewParam)) {
      return 'list';
    }

    return viewParam;
  }, [viewParam]);
  // get type from search
  const type = useMemo(() => {
    if (typeParam) return typeParam;
    if (searchParams.get('paymentMethodType')) return searchParams.get('paymentMethodType');
    const curType = typeParam ?? searchParams.get('paymentMethodType') ?? 'card';
    if (!['card', 'bank'].includes(curType)) return 'card';
    return curType;
  }, [searchParams, typeParam]);
  // get title from view
  const title = useMemo(() => {
    if (view === 'methods') return 'Add a payment method';

    if (view === 'add') {
      if (type === 'bank') return 'Link a bank';
      return 'Add a card';
    }

    return 'Payment Methods';
  }, [type, view]);
  const showEditView = _.includes(['add'], view);
  const showMethodsView = _.includes(['methods'], view);
  const isSelectMode = !_.isNil(searchParams.get('selectMode'));
  const paymentMethodsRQ = usePaymentMethods(!(showEditView || showMethodsView));
  const paymentMethodsData = useMemo(
    () =>
      Array.isArray(paymentMethodsRQ?.data?.data?.payment_methods) ? paymentMethodsRQ?.data?.data?.payment_methods : [],
    [paymentMethodsRQ?.data?.data?.payment_methods]
  );

  const showBack = useMemo(() => {
    // 如果在支付方式页面，并且是新增支付方式，弹窗上不展示返回按钮
    if (window.location.pathname === '/payment-methods' && showMethodsView) {
      return false;
    }

    return true;
  }, [showMethodsView]);

  const modalContent = useMemo(() => {
    if (showEditView) {
      return (
        <Suspense fallback={<></>}>
          <EditView onRenderFooter={setFooter} />
        </Suspense>
      );
    }

    if (showMethodsView) {
      return (
        <Suspense fallback={<></>}>
          <MethodsView />
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<></>}>
        <AlphaStack gap="4">
          <ListView selectMode={isSelectMode} data={paymentMethodsData} />
          <Button.Add
            className={css.add_payment_method_btn}
            replace
            link={getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods' })}
          >
            Add a payment method
          </Button.Add>
        </AlphaStack>
      </Suspense>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectMode, paymentMethodsData, showEditView, showMethodsView]);

  const handleClose = useCallback(() => {
    navigate(
      getUpdatedSearchParamsPath({ dialog: null, init: null, selectMode: null, paymentMethodType: null, h_ach: null }),
      {
        state: location.state,
        replace: true,
      }
    );
  }, [getUpdatedSearchParamsPath, location.state, navigate]);

  const handleBack = () => {
    if (showEditView) {
      navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods' }), { replace: true });
      return;
    }

    if (showMethodsView) {
      navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/list' }), { replace: true });
      return;
    }

    handleClose();
  };

  return (
    <Modal
      className={cn(css.ns_com_payment_methods_dialog_main, className, `${view}-view`)}
      title={title}
      showBack={showBack}
      onBack={handleBack}
      fullscreen="mobile"
      footer={footer}
      onClose={handleClose}
      {...restProps}
    >
      <div className={css.content}>{modalContent}</div>
    </Modal>
  );
}

export default PaymentMethodsDialog;
