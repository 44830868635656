import { message } from 'antd';
import { Modal as ModalAntd, Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useApplyDefaultShippingAddress from '@/hooks/queries/useApplyDefaultShippingAddress';
import useShopSettings from '@/hooks/queries/useShopSettings';
import useUpdateShopSettings from '@/hooks/queries/useUpdateShopSettings';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import styles from './styles.scss';

const { prefixCls } = styles;

const PAGE_TITLE = 'Shipping Rules';
const formatAddress = (shippingAddress) => {
  const customerInfo = {
    name: _.join([shippingAddress?.first_name, shippingAddress?.last_name], ' '),
    address1: shippingAddress?.address1,
    address2: shippingAddress?.address2,
    address: _.join(_.compact([shippingAddress?.city, shippingAddress?.province, shippingAddress?.zip]), ', '),
    country: shippingAddress?.country,
    phone: _.join(_.compact([shippingAddress?.phone_country, shippingAddress?.phone]), ''),
  };
  return Object.keys(customerInfo)
    .filter((key) => !!customerInfo[key])
    .map((key) => <div key={key}>{customerInfo[key]}</div>);
};

const confirmModalTitle = 'Confirm applying addresses';
const confirmModalContent =
  'This will apply your Default Address to all of your existing local pickup orders or orders without an address which are currently Holding or Unpaid.';

function App(): JSX.Element {
  const navigate = useNavigate();
  const { shopId } = useParams();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const shippingRules = useShopSettings(shopId, true);
  const updateShippingRules = useUpdateShopSettings(shopId);
  const updateShippingRulesForNotifyCustomer = useUpdateShopSettings(shopId);
  const applyDefaultShippingAddress = useApplyDefaultShippingAddress(shopId);
  const { shipping_rule_default_address_activated, shipping_rule_default_address, fulfillment_notify_customer } =
    shippingRules?.data?.data || {};
  const hasDefaultAddress = !!shipping_rule_default_address;
  const loading = shippingRules.isLoading || updateShippingRules.isLoading;

  const [currentAddressActivated, setCurrentAddressActivated] = useState(false);
  const [notifyCustomerActivate, setNotifyCustomerActivate] = useState(false);

  const showChangeAddressModal = () => {
    navigate(
      getUpdatedSearchParamsPath({
        dialog: 'shipping-addresses',
        selectMode: 1,
        presetAddressKey: shipping_rule_default_address?.key,
        hideRemember: 1,
      }),
      {
        replace: true,
      }
    );
  };

  const handleUpdateShippingRules = useCallback(
    (params) => {
      updateShippingRules.mutate(params, {
        onSuccess() {
          message.success('Success.');
        },
        onError() {
          message.error('Failed.');
        },
      });
    },
    [updateShippingRules]
  );

  const switchDefaultAddress = (isChecked) => {
    if (hasDefaultAddress) {
      setCurrentAddressActivated(isChecked);
      handleUpdateShippingRules({
        shipping_rule_default_address_activated: isChecked,
      });
      return;
    }

    // has no address, just open address dialog
    if (isChecked) {
      showChangeAddressModal();
    }
  };

  const switchNotifyCustomer = (isChecked) => {
    updateShippingRulesForNotifyCustomer.mutate(
      {
        fulfillment_notify_customer: isChecked,
      },
      {
        onSuccess() {
          setNotifyCustomerActivate(isChecked);
          message.success('Success.');
        },
        onError() {
          message.error('Failed.');
        },
      }
    );
  };

  const showApplyAddressToOrders = () => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        applyDefaultShippingAddress.mutate(null, {
          onSuccess() {
            message.success('Success.');
          },
        });
      },
    });
  };

  useEffect(() => {
    const handleShippingAddressChange = (e: CustomEvent) => {
      const curAddress = e.detail?.address;
      handleUpdateShippingRules({
        shipping_rule_default_address_activated: true,
        shipping_rule_default_address: e.detail?.address
          ? {
              ...curAddress,
              id: '',
            }
          : null,
      });
    };
    document.addEventListener('shippingAddresses:change', handleShippingAddressChange);

    return () => {
      document.removeEventListener('shippingAddresses:change', handleShippingAddressChange);
    };
  }, [handleUpdateShippingRules]);

  useEffect(() => {
    setCurrentAddressActivated(shippingRules?.data?.data?.shipping_rule_default_address_activated);
    setNotifyCustomerActivate(shippingRules?.data?.data?.fulfillment_notify_customer);
  }, [shippingRules?.data?.data]);

  return (
    <div className={cn(prefixCls)}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{PAGE_TITLE}</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>{PAGE_TITLE}</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>{PAGE_TITLE}</h1>

        <StoreSelect className={`${prefixCls}__store-select`} redirectPath="/shipping-rules/:shopId" />

        <section className={`${prefixCls}__card-wrapper`}>
          <div className={`${prefixCls}__card`}>
            <div className={`${prefixCls}__card_header`}>
              <div className={`${prefixCls}__card_header-left`}>Default Address (Local Pickup)</div>
              <div className={`${prefixCls}__card_header-right`}>
                <Switch
                  checked={currentAddressActivated}
                  defaultChecked={shipping_rule_default_address_activated}
                  onChange={switchDefaultAddress}
                  loading={loading}
                />
              </div>
            </div>
            <div className={`${prefixCls}__card_body`}>
              <StatusViewRenderer data={shippingRules?.data} error={shippingRules.error} isLoading={loading}>
                <div className={cn(`${prefixCls}__card_body-desc`)}>
                  {currentAddressActivated && hasDefaultAddress && (
                    <>
                      <div className={cn(`${prefixCls}__card_body-desc`, 'desc')}>
                        {formatAddress(shipping_rule_default_address)}
                      </div>
                      <Button color="main" size="mini" onClick={showChangeAddressModal} loading={loading}>
                        Change Address
                      </Button>
                    </>
                  )}
                  <div className={cn(`${prefixCls}__card_body-desc-grey`, 'hint')}>
                    {`We’ll ship to this address for Shopify Local Pickup orders, if an order has no Shipping Address present or if your CommentSold order shows "Local Pickup" notes. We will not mark these orders as Fulfilled on your Shopify.`}
                  </div>
                  <div className={cn(`${prefixCls}__card_body-desc-grey`, 'hint')}>
                    We won’t automatically update the addresses of existing orders if you change this address.
                  </div>
                  {currentAddressActivated && hasDefaultAddress && (
                    <div className={cn(`${prefixCls}__card_body-desc-wrapper`)}>
                      <Button
                        color="main"
                        size="mini"
                        onClick={showApplyAddressToOrders}
                        loading={applyDefaultShippingAddress.isLoading}
                      >
                        Apply address to existing orders
                      </Button>
                      <div className={cn(`${prefixCls}__card_body-desc-grey`, 'hint')}>
                        Change the addresses to the Default Address for existing local pickup orders and orders without
                        an address and are currently in Unpaid or Holding status.
                      </div>
                    </div>
                  )}
                </div>
              </StatusViewRenderer>
            </div>
          </div>
        </section>

        {/* notify customer */}
        <section className={`${prefixCls}__card-wrapper`}>
          <div className={`${prefixCls}__card`}>
            <div className={`${prefixCls}__card_header`}>
              <div className={`${prefixCls}__card_header-left`}>Notify Customer of Shipment</div>
              <div className={`${prefixCls}__card_header-right`}>
                <Switch
                  checked={notifyCustomerActivate}
                  defaultChecked={fulfillment_notify_customer}
                  onChange={switchNotifyCustomer}
                  loading={loading || updateShippingRulesForNotifyCustomer.isLoading}
                />
              </div>
            </div>
            <div className={`${prefixCls}__card_body`}>
              <div className={cn(`${prefixCls}__card_body-desc-grey`)}>
                Send a ‘Shipping Update’ notification from your Shopify to your customer when an order begins its
                fulfillment process from KiwiDrop. If you are using software or services outside of Shopify as your
                storefront, you might want to turn this off. This will set the default selection for the same setting on
                the checkout page.
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
