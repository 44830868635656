import { useMutation, useQueryClient } from 'react-query';

import { OrderItem } from '@/pages/Orders/types';
import { updateOrder } from '@/services/ds';

export default function useUpdateOrder() {
  const queryClient = useQueryClient();

  return useMutation<{ data: OrderItem }, unknown, { id: number | string; params: any }>(
    (args) => updateOrder(args.id, args.params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('orders');
      },
    }
  );
}
