import './styles.scss';

import { Modal } from 'antd';
import Link from 'antd/lib/typography/Link';
import _ from 'lodash';

import useCheckoutLimit from './queries/useCheckoutLimit';

const usePassCheckoutLimit = () => {
  const checkoutLimit = useCheckoutLimit();
  const passCheckoutLimit = (productCounts: { [product_id: number]: number }) => {
    const invalidConfig = checkoutLimit.data?.data?.limits?.find(({ product_ids, min_quantity }) => {
      const totalQty = _.sumBy(product_ids, (id) => productCounts[id] ?? 0);
      return totalQty !== 0 && totalQty < min_quantity;
    });
    if (invalidConfig) {
      Modal.warn({
        title: (
          <span>
            {`You need at least ${invalidConfig.min_quantity} listings from ${invalidConfig.collection_title} collection to participate in buying samples. `}
            <Link href={`/collections/${invalidConfig.collection_handle}`} underline>
              <span className="checkout-limit">Add more now!</span>
            </Link>
          </span>
        ),
        okButtonProps: { hidden: true },
        cancelButtonProps: { hidden: true },
        maskClosable: true,
      });
      return false;
    }
    return true;
  };
  return { passCheckoutLimit, checkoutLimit };
};
export default usePassCheckoutLimit;
