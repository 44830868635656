import { Button, message, Modal as Modal2 } from 'antd';

import Modal from '@/components/Modal';

import { useUpdateCatalogProduct } from '../../hooks/useUpdateCatalogProduct';
import { hideConfirmPriceModal, setCatalogProductsDraft, useCatalogRetailStore } from '../../store';

export const ConfirmPriceModal = ({ refetch }: { refetch: () => unknown }) => {
  const isConfirmPriceModalVisible = useCatalogRetailStore((state) => state.isConfirmPriceModalVisible);

  const draft = useCatalogRetailStore((state) => state.submitDraft);

  const { mutation } = useUpdateCatalogProduct();

  const handleClose = () => {
    setCatalogProductsDraft(null);
    hideConfirmPriceModal();
  };

  const handleClick = async () => {
    try {
      await mutation.mutateAsync(draft);
      refetch();
      hideConfirmPriceModal();
      Modal2.success({
        title: 'The update is being processed. It will need 10-30 seconds to take affect.',
        centered: true,
      });
    } catch (error) {
      message.error(error.message || 'Unknown Error');
    }
  };

  return (
    <Modal title="Confirm your changes" visible={isConfirmPriceModalVisible} onClose={handleClose}>
      <div
        style={{
          width: '620px',
        }}
      >
        <p
          style={{
            padding: '20px',
          }}
        >
          The changes you make will be applied to all of the variants you have selected. If you have selected multiple
          products, the changes will also be applied to all of the variants of those products.
        </p>
        <div
          style={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <Button loading={mutation.isLoading} type="primary" shape="round" block onClick={handleClick}>
            Yes, Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
