import useCountDown from '@/hooks/useCountDown';

import CountDownTag from './CountDownTag';

const SalesTimerTag = ({ timerSetting }: { timerSetting: any }) => {
  const { ends_at, begins_at } = timerSetting || {};
  const countDown = useCountDown({ beginsAt: begins_at || Date.now(), endsAt: ends_at });

  if (!timerSetting) return null;
  if (!ends_at) return null;
  if (!countDown) return null;

  return <CountDownTag countDown={countDown} />;
};

export default SalesTimerTag;
