import { DotLoading } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { ElementRef, useContext, useEffect, useRef, useState } from 'react';
import { FiHeart } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import AppContext from '@/appContext';
import AccessControl from '@/components/AccessControl';
import useToggleProductFavorite from '@/hooks/queries/useToggleProductFavorite';

import AddedToBoardNotification from '../AddedToBoardNotification';
import css from './index.module.scss';

type AddedToBoardNotificationHandle = ElementRef<typeof AddedToBoardNotification>;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  product?: any;
}

function FavoriteButton({ product, className, style }: Props): JSX.Element {
  const appContextValue = useContext(AppContext);
  const { showModalSelectBoard } = appContextValue;
  const navigate = useNavigate();
  const toggleFavorite = useToggleProductFavorite();
  const id = product?.product?.id ?? '';
  const [isFavorite, setIsFavorite] = useState(!!product?.rel?.is_favorite);
  const productLineItems = id ? [product.product] : [];
  const addedToBoardNotificationRef = useRef<AddedToBoardNotificationHandle>(null);
  const [notificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    setIsFavorite(!!product?.rel?.is_favorite);
  }, [product?.rel?.is_favorite]);

  const handleShowModalSelectBoard = () => {
    showModalSelectBoard({ isDefaultSelected: true, products: productLineItems });
  };

  const handleToggleFavorite = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (toggleFavorite.isLoading) return;

    if (isFavorite) {
      handleShowModalSelectBoard();
      return;
    }

    const targetFavorite = !isFavorite;
    toggleFavorite.mutate(
      { id, isFavorite: targetFavorite },
      {
        onSuccess: () => {
          setIsFavorite(targetFavorite);
          setNotificationVisible(!notificationVisible);
          addedToBoardNotificationRef.current.show();
        },
      }
    );
  };

  return (
    <AccessControl requireAuth>
      {({ isReject, redirect }) => (
        <>
          <span
            className={cn(css.ns_com_favorite_button_main, className, {
              [`${css.favorited} favorite-button--favorited`]: isFavorite,
            })}
            style={style}
            onClick={
              isReject
                ? (e) => {
                    e.stopPropagation();
                    navigate(redirect);
                  }
                : handleToggleFavorite
            }
          >
            <FiHeart strokeWidth="1.5" className={cn(css.icon, 'favorite-button__icon')} />
            {toggleFavorite.isLoading && <DotLoading className={cn(css.loading, 'favorite-button__loading')} />}
          </span>
          <AddedToBoardNotification
            ref={addedToBoardNotificationRef}
            product={product?.product}
            onShowAddToBoard={handleShowModalSelectBoard}
          />
        </>
      )}
    </AccessControl>
  );
}

export default FavoriteButton;
