.ns_com_private_inv_list_item_img {
  width: 28px;
  object-fit: scale-down;
}

.ns_com_private_inv_list_item_title {
  white-space: normal;
  word-break: break-word;
  color: var(--p-text);

  &:hover {
    text-decoration: underline;
    color: var(--p-text);
  }
}
