.ns_com_payment_methods_dialog_main {
  .add_payment_method_btn {
    position: sticky;
    bottom: var(--spacing-l);
    margin: var(--spacing-l) 0;
  }

  :global {
    .modal__footer {
      padding-top: 0;
      border-top: none !important;
    }
  }
  &:global(.list-view) {
    :global {
      .modal__body {
        background: var(--color-gray1);
      }
    }
  }

  @media screen and (min-width: 769px) {
    &:global(.list-view) {
      :global {
        .modal__back-btn {
          display: none;
        }
      }
    }
    :global {
      .modal__container {
        max-width: 690px;
        min-height: 300px;
      }
      .modal__footer .button {
        width: 250px;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .add_payment_method_btn {
      position: fixed;
      margin: 0;
      width: auto;
      left: var(--spacing-l);
      right: var(--spacing-l);
      bottom: var(--spacing-l);
    }

    &:global(.list-view) {
      // padding: 0 0 calc(var(--spacing-l) + 40px);
      padding: 0;
    }
  }
}
