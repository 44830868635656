.ns_com_order_products_card_main {
  background: var(--color-white);
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) var(--spacing-l);
    margin-bottom: var(--spacing-m);
    border-bottom: 1px solid #f5f5f5;

    .title_wrapper {
      .title {
        font-size: var(--section-header-font-size);
        line-height: 1.2;
        font-weight: 500;
      }
      .text {
        margin-left: var(--spacing-m);
        font-size: var(--section-text-font-size);
        line-height: 1.4;
        font-weight: normal;
      }
    }
  }

  .body {
    padding: 0 var(--spacing-l) var(--spacing-l);
  }

  .images {
    margin-right: calc(-1 * var(--spacing-l));
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    .images_wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 66px;
      gap: var(--spacing-xs);
    }

    .images_item {
      position: relative;

      :global {
        .image {
          width: 62px;
          height: 80px;
        }
      }

      span {
        position: absolute;
        left: 50%;
        bottom: var(--spacing-m);
        padding: 2px 4px;
        transform: translateX(-50%);
        font-size: var(--font-size1);
        line-height: 1.4;
        font-weight: 500;
        text-align: center;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 99px;
      }
    }
  }

  .list_item_tags {
    display: flex;
    justify-content: end;
  }

  .boost_wrapper {
    display: flex;
    justify-content: end;

    .boost {
      background: #ffcc17;
      border-radius: 2px;
      padding: 2px 4px;
      font-size: 10px;
      font-weight: 500;
      line-height: 100%;
      color: #000;
    }
    :global {
      .react-icons {
        margin-right: 0;
      }
    }
  }

  @at-root .order_product_list_dialog {
    .dialog_title {
      font-size: 20px;
      line-height: 1.2;
      font-weight: 500;

      span {
        margin-left: var(--spacing-l);
        font-size: 16px;
        line-height: 1.4;
        font-weight: normal;
      }

      :global {
        .react-icons {
          margin-left: var(--spacing-l);
        }
      }
    }

    :global {
      .modal__icon-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .react-icons {
          display: none;
        }
      }
    }
  }

  @at-root .list {
    display: grid;
    grid-template-columns: 100%;

    @at-root .list_item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--spacing-m) 0;
      border-bottom: 1px solid #f5f5f5;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      .list_item_cover {
        margin-right: var(--spacing-m);
        position: relative;

        .list_item_cover_image {
          width: 60px;
          height: 80px;
          border-radius: 4px;
        }
      }

      .list_item_info {
        flex: 1;
        min-width: 0;
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }

      .list_item_title {
        line-height: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
      }

      .list_item_body_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .list_item_fulfill_label {
        display: flex;
      }

      .list_item_variant {
        color: var(--color-black);
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1;
      }

      .list_item_price {
        color: #000;
        font-size: 14px;
        line-height: 1;
      }

      .list_item_quantity {
        margin-top: var(--spacing-xxs);
        color: var(--color-gray4);
      }

      .list_item_tag {
        font-size: 12px;
        line-height: 1.2em;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 0;
        &.sold_out {
          background: #ffffffbf;
          color: #ff0000;
        }
        &.over_buy {
          background: #ffffffbf;
          color: #000;
        }

        .pre_order {
          color: var(--p-text);
          background: #ffffffbf;
          border-radius: 4px;
          padding: 0 8px;
          display: inline-block;
          margin-bottom: 6px;
          font-size: 10px;
          line-height: 1.4em;
        }
      }
    }
  }
}
