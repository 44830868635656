import { Menu } from 'antd';
import { Popup } from 'antd-mobile';
import type { PopupProps } from 'antd-mobile/es/components/popup';
import cn from 'classnames';

import AutoLink, { isEmptyTargetData as getIsEmptyTargetData } from '@/components/AutoLink';
import useSettings from '@/hooks/queries/useSettings';
import { clickNav } from '@/utils/ga';

import css from './index.module.scss';

export interface Props extends PopupProps {
  onClose?: () => void;
  visible?: boolean;
}

type StyleImageProps = {
  styleHalf?: boolean;
  stylePaddingTop?: boolean;
  styleLeftItem?: boolean;
  styleRightItem?: boolean;
};

const findNumbersInOrder = (arr: number[]): number[][] => {
  let i = 0;
  const res = [];
  res[i] = [arr[0]];
  arr.reduce((prev, cur) => {
    // eslint-disable-next-line no-plusplus
    cur - prev === 1 ? res[i].push(cur) : (res[++i] = [cur]);
    return cur;
  });
  return res;
};
const findItemOfImageTypeIdxs = (arr: any): number[] | [] => {
  const tmp = [];
  arr.forEach((i, idx) => {
    if (i?.type === 'image') {
      tmp.push(idx);
    }
  });
  return tmp;
};
// 获取展示5，5分的图片索引
const getShowHalfIdxs = (items: any[]) => {
  const itemOfImageTypeIdxs = findItemOfImageTypeIdxs(items);
  if (itemOfImageTypeIdxs.length > 1) {
    const tmpRes = findNumbersInOrder(itemOfImageTypeIdxs)
      .filter((i) => i.length > 1)
      .map((i) => {
        if (i.length % 2 === 1) {
          i.pop();
        }
        return i;
      });
    return tmpRes;
  }
  return [[]];
};

const MobileNavMenu = (props: Props) => {
  const { visible, onClose } = props;
  const settings = useSettings();
  const navMobileSettings = settings?.data?.data?.['nav-mobile']?.data;

  const handleClose = () => {
    onClose?.();
  };

  const handleSubMenuClick = (gaParams) => {
    clickNav(gaParams);
  };

  const handleTitleClick = (gaParams) => {
    clickNav(gaParams);
    handleClose();
  };

  const renderItem = (
    i,
    ky,
    styleImageProps: StyleImageProps,
    options: { parentLevel0?: string; parentLevel1?: string }
  ) => {
    const { styleHalf, stylePaddingTop, styleLeftItem, styleRightItem } = styleImageProps;
    const { image_url, title, type, target_type, target_data } = i;
    const key = encodeURI(`${ky}-${title}`);
    const { parentLevel0, parentLevel1 } = options;

    const gaParams = {
      nav_parent_level_0: parentLevel0,
      nav_parent_level_1: parentLevel1,
      nav_target_type: target_type,
      nav_title: title,
    };

    let curDom = null;
    if (getIsEmptyTargetData(i?.target_data)) {
      curDom = (
        <Menu.Item className={css.ns_or_menu_item} onClick={() => handleTitleClick(gaParams)}>
          {title}
        </Menu.Item>
      );
    } else if (type === 'image') {
      curDom = !!image_url && (
        <div
          className={cn(css.image_menu_wrapper, {
            [css.style_half]: styleHalf,
            [css.style_padding_top]: stylePaddingTop,
            [css.style_left_item]: styleLeftItem,
            [css.style_right_item]: styleRightItem,
          })}
          key={key}
          onClick={() => handleTitleClick(gaParams)}
        >
          <AutoLink targetType={target_type} targetData={target_data} onClick={handleClose}>
            <div className={css.item}>
              <div className={css.image_wrapper}>
                <img src={image_url} alt={image_url} />
              </div>
              {title && <div className={css.title}>{title}</div>}
            </div>
          </AutoLink>
        </div>
      );
    } else {
      curDom = (
        <Menu.Item key={key} className={css.ns_or_menu_item} onClick={() => handleTitleClick(gaParams)}>
          <AutoLink targetType={target_type} targetData={target_data}>
            {title}
          </AutoLink>
        </Menu.Item>
      );
    }
    return curDom;
  };

  const renderMenuItems = (
    items: { target_data?: string; target_type?: string; title?: string; children?: any[] }[],
    options?: { parentLevel0?: string; parentLevel1?: string }
  ) => {
    if (!items?.length) return null;

    const { parentLevel0, parentLevel1 } = options || {};
    const styleHalfIdxsArr: number[][] = getShowHalfIdxs(items);

    return items.map((item, index) => {
      const { title, children, target_type } = item;
      const hasChildren = !!children?.length;
      const newKey = encodeURI(`${index}-${title}`);

      // 判断当前图片是否只占50%
      const styleHalf = !!styleHalfIdxsArr.filter((arr) => arr.includes(index))?.length;
      // 找到需要设置paddingtop的图片索引集合
      const stylePaddingTopIdxs = styleHalfIdxsArr.filter((arr) => arr.length > 2).map((arr) => arr.slice(2));
      // 判断当前图片是否设置paddingTop
      let stylePaddingTop = !!stylePaddingTopIdxs.filter((arr) => arr.includes(index))?.length;
      // 5，5分图片之后的最后一张图片
      if (!stylePaddingTop && styleHalfIdxsArr?.length) {
        stylePaddingTop = !!styleHalfIdxsArr.filter((arr) => index === arr[arr.length - 1] + 1)?.length;
      }
      let styleLeftItem = false;
      let styleRightItem = false;

      if (styleHalf) {
        styleHalfIdxsArr.forEach((arr) => {
          if (styleLeftItem) {
            return;
          }
          arr.forEach((i, idx) => {
            if (i === index && idx % 2 === 0) {
              styleLeftItem = true;
            }
          });
        });
        styleHalfIdxsArr.forEach((arr) => {
          if (styleRightItem) {
            return;
          }
          arr.forEach((i, idx) => {
            if (i === index && idx % 2 === 1) {
              styleRightItem = true;
            }
          });
        });
      }

      const realParentLevel0 = parentLevel0 || title;
      const gaParams = {
        nav_parent_level_0: realParentLevel0,
        nav_parent_level_1: title,
        nav_target_type: target_type,
        nav_title: title,
      };

      if (hasChildren) {
        return (
          <Menu.SubMenu key={newKey} title={title} onTitleClick={() => handleSubMenuClick(gaParams)}>
            {renderMenuItems(children, { parentLevel0: realParentLevel0, parentLevel1: parentLevel0 ? title : '' })}
          </Menu.SubMenu>
        );
      }

      return renderItem(
        item,
        newKey,
        {
          styleHalf,
          stylePaddingTop,
          styleLeftItem,
          styleRightItem,
        },
        {
          parentLevel0: realParentLevel0,
          parentLevel1: parentLevel1 || title,
        }
      );
    });
  };

  return (
    <Popup
      className={cn(css.ns_com_mobile_nav_menu)}
      bodyClassName={css.body}
      forceRender
      position="left"
      onMaskClick={onClose}
      visible={visible}
    >
      <Menu mode="inline">{renderMenuItems(navMobileSettings)}</Menu>
    </Popup>
  );
};

export default MobileNavMenu;
