import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import cn from 'classnames';
import React, { useRef } from 'react';

import css from './index.module.scss';

export interface Props {
  className?: string;
  value?: string;
  onChange?: (html: string) => void;
  height?: number;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
}

export default function FieldEditor({
  className,
  value,
  onChange,
  height = 160,
  disabled,
  placeholder,
  maxLength = 32 * 1024,
}: Props): JSX.Element {
  const editorRef = useRef<ClassicEditor>();

  const handleChange = (event, editor) => {
    // const strippedHtml = editor.getData()?.replace(/<[^>]+>/g, '');
    const data = editor.getData();
    if (data?.length > maxLength) return;
    onChange?.(data);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        removePlugins: [
          'Heading',
          'List',
          'BlockQuote',
          'MediaEmbed',
          'Indent',
          'Image',
          'EasyImage',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'CKFinderUploadAdapter',
          'CKFinder',
          'Table',
        ],
        image: {},
        placeholder,
      }}
      data={value}
      disabled={disabled}
      onReady={(editor) => {
        editorRef.current = editor;
        editor.editing.view.change((writer) => {
          writer.addClass(cn(css.ns_com_form_item_editor_main, className), editor.editing.view.document.getRoot());
          writer.setStyle('height', `${height}px`, editor.editing.view.document.getRoot());
        });
      }}
      onChange={handleChange}
      onBlur={handleChange}
    />
  );
}
