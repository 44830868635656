import _ from 'lodash';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { useAuth, User } from '@/hooks/auth';
import useCurrentStore from '@/hooks/queries/useCurrentStore';

export type RejectReason = 'noAuth' | 'noEmail' | 'noStore';

export interface FunctionChildrenArgs {
  isReject: boolean;
  isLoading: boolean;
  rejectReason?: RejectReason;
  user?: User;
  currentStore?: any;
  redirect?: string;
  showRedirectDialog?: () => void;
}

export interface Props {
  requireAuth?: boolean;
  requireEmail?: boolean;
  requireStore?: boolean;
  showRedirectDialog?: boolean;
  rejectRedirectUrl?: string | null | ((args: { rejectReason?: RejectReason; redirect?: string }) => string);
  children?: React.ReactNode | ((args: FunctionChildrenArgs) => React.ReactNode);
}

function AccessControl({
  requireAuth = true,
  requireEmail,
  requireStore,
  showRedirectDialog,
  rejectRedirectUrl,
  children,
}: Props): JSX.Element {
  const { user, isLoggingIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const currentStore = useCurrentStore(_.isEmpty(user));
  const [redirectDialogVisible, setRedirectDialogVisible] = useState(false);
  const loginUrl = '/login';
  const emailBindUrl = `/email-bind?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`;
  const storeUrl = '/stores';
  const isLoading = isLoggingIn || currentStore.isLoading;

  let isReject = false;
  let reason: RejectReason | undefined;
  let redirect: string | undefined;
  let redirectDialogTitle: string | undefined;
  let redirectDialogDescription: string | undefined;

  const handleRedirect = () => {
    setRedirectDialogVisible(false);
    redirect && navigate(redirect);
  };

  const handleShowRedirectDialog = () => {
    setRedirectDialogVisible(true);
  };

  const renderRedirectDialog = (visible?: boolean) => (
    <>
      {(visible ?? redirectDialogVisible) && (
        <Modal
          visible={visible ?? redirectDialogVisible}
          title={redirectDialogTitle}
          bordered={false}
          showMountAnimation
          footer={
            <Button block color="primary" shape="rounded" onClick={handleRedirect}>
              Continue
            </Button>
          }
          maskClosable={false}
          onClose={() => setRedirectDialogVisible(false)}
        >
          <div style={{ fontSize: 16, lineHeight: 1.4, textAlign: 'center' }}>{redirectDialogDescription}</div>
        </Modal>
      )}
    </>
  );

  if (requireAuth && _.isEmpty(user)) {
    isReject = true;
    reason = 'noAuth';
    redirect = loginUrl;
    redirectDialogTitle = 'Login Required';
    redirectDialogDescription = 'You must login to continue.';
  }

  if (!isReject && requireEmail && _.isEmpty(user?.email)) {
    isReject = true;
    reason = 'noEmail';
    redirect = emailBindUrl;
    redirectDialogTitle = 'Bind an Email Address';
    redirectDialogDescription = 'To continue, please bind an email address to associate with this account.';
  }

  if (!isReject && requireStore && _.isEmpty(currentStore?.data)) {
    isReject = true;
    reason = 'noStore';
    redirect = storeUrl;
    redirectDialogTitle = 'Connect a Store';
    redirectDialogDescription = 'To continue, please connect a store to associate with this account.';
  }

  if (rejectRedirectUrl === null) {
    redirect = undefined;
  } else if (rejectRedirectUrl) {
    redirect = _.isFunction(rejectRedirectUrl)
      ? rejectRedirectUrl({ rejectReason: reason, redirect })
      : rejectRedirectUrl;
  }

  isReject = isReject && !isLoading;

  if (isReject && redirect && !_.isFunction(children)) {
    if (showRedirectDialog) return renderRedirectDialog(true);

    return <Navigate to={redirect} replace />;
  }

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {_.isFunction(children)
        ? children({
            isReject,
            isLoading,
            rejectReason: reason,
            user,
            currentStore: currentStore?.data,
            redirect,
            showRedirectDialog: handleShowRedirectDialog,
          })
        : isReject
        ? null
        : children}
      {isReject && redirect && renderRedirectDialog()}
    </>
  );
}

export default AccessControl;
