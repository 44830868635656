import _ from 'lodash';

import { SESSION_STORAGE_KEYS } from '@/constants/app';
import { OrderStatus } from '@/pages/Orders/types';
import { Address, ProductPreviewMode, ShippingAddressRouteType } from '@/types';

import { formatterPrice } from './util';

const checkIsSoldOut = (available_quantity) => !!(Number(available_quantity) < 1);

const checkIsOverBuy = (qty, available_quantity) =>
  !checkIsSoldOut(Number(available_quantity)) && Number(qty) > Number(available_quantity);

const showOrderSoldOutOrOverBuyTag = (status) => [OrderStatus.Unpaid].includes(status);

const getFormatAddress = (shippingAddress: Address) => ({
  name: _.join(_.compact([shippingAddress?.first_name, shippingAddress?.last_name]), ' ') || shippingAddress?.name,
  address1: shippingAddress?.address1,
  address2: shippingAddress?.address2,
  address: _.join(_.compact([shippingAddress?.city, shippingAddress?.province, shippingAddress?.zip]), ', '),
  country: shippingAddress?.country,
  phone: _.join(_.compact([shippingAddress?.phone_country, shippingAddress?.phone]), ''),
});

const getAddressText = (address) => _.join(_.compact(Object.keys(address).map((key) => address[key])), '\n');

const showShippingRulesDefaultAddressTag = (shipping_address_route_type) =>
  [ShippingAddressRouteType.No_ADDRESS, ShippingAddressRouteType.OUTSIDE].includes(shipping_address_route_type);

const getShopifyOrderDetailUrl = (shopOrderShopifyId: string, shopifyDomain: string) =>
  !!shopOrderShopifyId && !!shopifyDomain ? `https://${shopifyDomain}/admin/orders/${shopOrderShopifyId}` : '';

const showDelayTag = (tags = []) => false;

function getVariantOptions(variant: { option1: string; option2: string; option3: string }): string[] {
  return _.compact([variant?.option1, variant?.option2, variant?.option3]);
}

function getVariantByOptions(variants: any[], options: string[]): any {
  return _.find(variants, (variant) => _.isEqual(getVariantOptions(variant), options));
}

function getVariantsStockSummary(variants: any[]): any {
  const summary = variants?.reduce((acc, variant) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { inventory_quantity = 0 } = variant;
    const options = getVariantOptions(variant);

    // group by option level
    options.reduce((group, option, index) => {
      let p = group;
      // eslint-disable-next-line no-multi-assign, no-param-reassign
      if (index > 0) p = group.children = group.children || {};

      p[option] = p[option] || { total: 0 };
      p[option].total += Math.max(inventory_quantity, 0);
      return p[option];
    }, acc);

    return acc;
  }, {});

  return summary ?? {};
}

const updateSessionStorageAfterAddToCart = (addToCartItems: { variant_id: string | number }[]) => {
  try {
    const cartOrderParams = sessionStorage.getItem(SESSION_STORAGE_KEYS.cartOrderParams);
    if (cartOrderParams) {
      const { lineItems: oldLineItems, note } = JSON.parse(cartOrderParams);
      const newToAddLineItems = addToCartItems.filter(
        (vid) => !oldLineItems.map(({ variant_id }) => variant_id).includes(vid)
      );
      const newCartOrderParams = {
        lineItems: [...newToAddLineItems, ...oldLineItems],
        note,
      };
      sessionStorage.setItem(SESSION_STORAGE_KEYS.cartOrderParams, JSON.stringify(newCartOrderParams));
    }
  } catch (e) {
    console.log(e);
  }
};

const getBoostInfo = (product, price) => {
  const ratio = product?.boost?.ratio;

  return {
    isShow: ratio > 0 && price > 0,
    ratio,
    ratioStr: `${parseInt(`${ratio * 100}`, 10)}%`,
    earnPrice: formatterPrice(price * ratio),
  };
};

const hasInvView = (views: ProductPreviewMode[]) => {
  if (!Array.isArray(views)) return false;
  if (!views.length) return false;

  return [ProductPreviewMode.Grid, ProductPreviewMode.List].every((i) => views.includes(i));
};

const getGroupedPreOrderProducts = (data: any[]) => {
  if (!Array.isArray(data)) {
    return {
      data: [],
      hasPreOrderProducts: false,
      isAllPreOrderProducts: false,
    };
  }

  const preOrders = _.uniqWith(
    _.flatMapDepth(
      (data || []).map(({ preorder }) => preorder),
      (n) => n
    ),
    _.isEqual
  );

  const groupedData = _.groupBy(data, (d) => {
    const findPreOrder = preOrders.filter(({ variant_ids }) => variant_ids.includes(d?.variant_id));
    return findPreOrder?.[0]?.preorder;
  });

  return {
    data: groupedData,
    hasNormalProducts: Object.keys(groupedData).some((key) => key === 'undefined'),
    hasPreOrderProducts: Object.keys(groupedData).some((key) => key !== 'undefined'),
  };
};

export {
  checkIsOverBuy,
  checkIsSoldOut,
  getAddressText,
  getBoostInfo,
  getFormatAddress,
  getGroupedPreOrderProducts,
  getShopifyOrderDetailUrl,
  getVariantByOptions,
  getVariantOptions,
  getVariantsStockSummary,
  hasInvView,
  showDelayTag,
  showOrderSoldOutOrOverBuyTag,
  showShippingRulesDefaultAddressTag,
  updateSessionStorageAfterAddToCart,
};
