.supplierSales {
  .header {
    .title {
      font-size: 26px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }
  .category {
    margin-bottom: 15px;
    .inputs {
      margin-bottom: 15px;
    }
    .categoryItem {
      background-color: #fff;
      padding: 5px;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;
      &.selected {
        background-color: #f5fce5;
      }
    }
    .order_status_filter_wrapper {
      padding: 0 12px;
      -webkit-overflow-scrolling: touch;
      display: flex;
      align-items: center;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      display: none;
    }

    .category {
      margin-bottom: 15px;
      .inputs {
        margin-bottom: 15px;
        padding: 0 10px;
      }
      .order_status_filter_wrapper {
        overflow-x: auto;
      }
    }
  }

  :global {
    @media screen and (max-width: 768px) {
      .app-layout {
        &__nav-bar {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    .order_status_filter_wrapper {
      overflow: inherit;
      padding-left: 0;
      padding-right: 0;
    }
    .secondaryNavBar {
      display: none;
    }
  }
}
