import { useMutation, useQueryClient } from 'react-query';

import { OrderItem } from '@/pages/Orders/types';
import { createOrder } from '@/services/ds';

export default function useCreateOrder() {
  const queryClient = useQueryClient();

  return useMutation<{ data: OrderItem }>((params: Parameters<typeof createOrder>[0]) => createOrder(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
    },
  });
}
