import _ from 'lodash';
import { useQuery } from 'react-query';

import { getStorePriceRules } from '@/services/ds';

export default function useStorePriceRules(shopId?: string) {
  return useQuery(['store', 'getStorePriceRules', shopId], () => getStorePriceRules(shopId as string), {
    enabled: !_.isNil(shopId),
    keepPreviousData: true,
  });
}
