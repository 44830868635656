.ns_comp_cart_manage_section_actions_pc {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  color: #000;
  position: sticky;
  top: calc(var(--app-layout-padding-top) + var(--spacing-l));
  background: #fff;
  margin-bottom: 12px;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0 2px 10px #0000000f;
  &.is_page_scrolled {
    box-shadow: 0px 0px 12px 4px #0000000f;
  }
  .center_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .text {
      padding-left: 4px;
      font-size: 16px;
      &.disabled {
        cursor: not-allowed;
      }
      &.text_select_all {
        padding-left: 12px;
      }
    }
  }
}
