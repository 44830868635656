/**
 * pickkiwi related API
 */
import envconfig from '@/constants/envconfig';

import api, { responseErrorHandler } from './api';

// eslint-disable-next-line import/prefer-default-export
export const addUrls = async (data?: any) => {
  const res = await api.post<any>(`/api/v2/pickkiwi/request/add`, data, {
    baseURL: envconfig.API_BASE_URL_FOR_PICKKIWI,
    headers: {
      'kw-auth-type': 'sso',
    },
  });
  responseErrorHandler(res);
  return res.data;
};
