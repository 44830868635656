import cn from 'classnames';
import _ from 'lodash';
import React from 'react';

import css from './index.module.scss';
import ProductListItem from './ProductListItem';

export interface ProductListProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any[];
}

export function ProductList({ className, style, data }: ProductListProps): JSX.Element {
  return (
    <div className={cn(css.ns_com_product_list_main, className)} style={style}>
      {data?.map((item: any) => (
        <ProductListItem key={item?.product?.id} data={item} />
      ))}
    </div>
  );
}

export default ProductList;
