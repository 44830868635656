import _ from 'lodash';
import { Link, LinkProps } from 'react-router-dom';

export enum TargetType {
  Url = 'url',
  UrlExt = 'url_ext',
  Product = 'product',
  Collection = 'collection',
  Collections = 'collections',
}
export interface Props extends Partial<LinkProps> {
  targetType?: TargetType;
  targetData?: string;
}

function AutoLink({ to = '#', children, targetType, targetData = '#', ...resetProps }: Props): JSX.Element {
  let link: string = to as string;
  let target = '_self';

  // business logic related
  if (targetType === TargetType.Url) {
    link = targetData;
  } else if (targetType === TargetType.UrlExt) {
    link = targetData;
    target = '_blank';
  } else if (targetType === TargetType.Product) {
    link = `/products/${targetData}`;
  } else if (targetType === TargetType.Collection || targetType === TargetType.Collections) {
    link = `/collections/${targetData}`;
  }

  if (isEmptyTargetData(targetData)) {
    target = '_self';
  }

  if (/^[^/]/i.test(link)) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <a href={link} target={target} {...resetProps}>
        {children}
      </a>
    );
  }

  return (
    <Link to={link} target={target} {...resetProps}>
      {children}
    </Link>
  );
}

export function isEmptyTargetData(targetData: any): boolean {
  return _.isNil(targetData) || targetData === '' || targetData === '#';
}

export default AutoLink;
