export const defaultPriceRules = {
  // price switch
  price_enabled: 1,

  // price operator type
  // Multiplier: multiple
  // Flat Markup: add
  price_op: 'multiple',

  // price operator value
  price_arg: 2,

  // price operator select options (custom field)
  price_operator_options: [
    { label: 'Multiplier', value: 'multiple' },
    { label: 'Flat Markup', value: 'add' },
  ],

  // compare at price switch
  compare_at_price_enabled: 1,

  // compare at price operator type
  // Multiplier: multiple
  // Flat Markup: add
  compare_at_price_op: 'multiple',

  // compare at price operator value
  compare_at_price_arg: 2,

  // compare at price operator select options (custom field)
  compare_at_price_operator_options: [
    { label: 'Multiplier', value: 'multiple' },
    { label: 'Flat Markup', value: 'add' },
  ],
};

export default defaultPriceRules;
