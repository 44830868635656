import cn from 'classnames';
import Helmet from 'react-helmet';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function CollectionList({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Categories</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <h1>Categories Page</h1>
      </div>
    </div>
  );
}

export default CollectionList;
