import { useQuery } from 'react-query';

import { getPaymentMethods } from '@/services/ds';

export default function usePaymentMethods(enabled?: boolean) {
  return useQuery('paymentMethods', () => getPaymentMethods(), {
    enabled,
    staleTime: 1000,
  });
}
