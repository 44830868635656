.ns_com_image_main {
  .fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
    color: var(--color-gray3);
    background: rgba(190, 190, 190, 0.2);
  }

  .placeholder {
    --width: 100%;
    --height: 100%;
    background: rgba(190, 190, 190, 0.2);
  }
}
