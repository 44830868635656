import _ from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

export default function useGetUpdatedSearchParamsPath() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const getUpdatedSearchParamsPath = (params: any) => {
    const updatedSearchParams = _.omitBy(
      _.merge(Object.fromEntries(searchParams), params),
      (v) => _.isNil(v) || v?.trim?.() === ''
    );
    const updatedPath = `${location.pathname}?${new URLSearchParams(updatedSearchParams).toString()}${location.hash}`;

    return updatedPath;
  };

  return getUpdatedSearchParamsPath;
}
