import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';

import useSettings from '@/hooks/queries/useSettings';
import usePosMatched, { MatchedPos } from '@/hooks/usePosMatched';
import { CollectionProduct } from '@/types';

import ImportButton from '../ImportButton';
import CountDownTag from './CountDownTag';
import css from './CoverFooter.module.scss';
import OutOfStockTag from './OutOfStockTag';
import PreLaunchTag from './PreLaunchTag';
import PreOrderTag from './PreOrderTag';
import SalesTimerTag from './SalesTimerTag';

const CoverFooter = ({ data }: { data: CollectionProduct }) => {
  const productTags = useMemo(() => data?.product?.tags || [], [data?.product?.tags]);
  const isOutOfStock = !_.isNil(data?.product?.out_of_stock) && !!data?.product?.out_of_stock;
  const isDroppingSoon = data?.product?.drop_soon?.at && moment().isBefore(data?.product?.drop_soon?.at);
  const isHotFlash = !!data?.hotFlash?.countDown;
  const isPreOrder = !!data?.product?.preorder?.length;

  const settings = useSettings();
  const timerSetting = useMemo(() => {
    const timerSettingOrigin = settings?.data?.data?.timer?.data;
    if (!Array.isArray(timerSettingOrigin)) return null;
    const tmp = timerSettingOrigin.filter((setting) => productTags?.some((tag) => setting?.tag.includes(tag)));
    // 取优先匹配到的第一个salestimer 配置
    return tmp?.[0];
  }, [productTags, settings?.data?.data]);
  const isSalesTimerPosMatched = usePosMatched(timerSetting?.pos, MatchedPos.PLP);
  const isSalesTimer = !!timerSetting && isSalesTimerPosMatched;

  const importButton = useMemo(
    () => (
      <ImportButton
        className={css.import_btn}
        color="acrylic"
        icon={<FaPlus />}
        showImportedLabel
        showImportingText={false}
        product={data}
        importProductsQueryParams={{ product_ids: [data?.product?.id] }}
      >
        {null}
      </ImportButton>
    ),
    [data]
  );

  const preOrderTag = useMemo(() => {
    if (!isPreOrder) return null;
    return <PreOrderTag />;
  }, [isPreOrder]);

  const outOfStockTag = useMemo(() => {
    if (!isOutOfStock) return null;
    return <OutOfStockTag />;
  }, [isOutOfStock]);

  const salesTimerTag = useMemo(() => {
    if (!isSalesTimer) return null;
    return <SalesTimerTag timerSetting={timerSetting} />;
  }, [isSalesTimer, timerSetting]);

  const preLaunchTag = useMemo(() => {
    if (!isDroppingSoon) return null;
    return <PreLaunchTag />;
  }, [isDroppingSoon]);

  const hotflashTag = useMemo(() => {
    if (!isHotFlash) return null;
    return <CountDownTag countDown={data?.hotFlash?.countDown} />;
  }, [data?.hotFlash?.countDown, isHotFlash]);

  // oos的商品不展示import按钮
  if (outOfStockTag) {
    return outOfStockTag;
  }

  // !!! 注意数组的顺序决定了组件展示的优先级
  const tagComponents = [preOrderTag, salesTimerTag, preLaunchTag, hotflashTag];
  const tagComponent = tagComponents.find((comp) => !!comp);

  return (
    <>
      {tagComponent}
      {importButton}
    </>
  );
};

export default CoverFooter;
