import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';

import { addShippingAddress } from '@/services/ds';

export default function useAddShippingAddress() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof addShippingAddress>[0]) => addShippingAddress(params), {
    onSuccess: (data) => {
      queryClient.setQueryData('shippingAddresses', (prevData: any) =>
        produce(prevData, (draft: any) => {
          draft?.data?.push(data?.data);
        })
      );

      queryClient.invalidateQueries('shippingAddresses');
    },
  });
}
