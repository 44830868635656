import cn from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';

import Button from '@/components/Button';
import Image from '@/components/Image';
import Notification from '@/components/Notification';
import { useDesktop } from '@/hooks';
import { useLastAddedSellerCollections, useSellerCollectionData } from '@/hooks/biz';
import { Product } from '@/types';

import css from './index.module.scss';

enum VisibleState {
  Show = 'show',
  Shown = 'shown',
  Hide = 'hide',
  Hidden = 'hidden',
}

type CompHandle = {
  show: () => void;
};

type Props = {
  product?: Product;
  onClose?: () => void;
  onShowAddToBoard?: () => void;
  showEdit?: boolean;
};

const formatLength = (arr, len) => {
  if (!Array.isArray(arr)) return [];

  if (arr.length > len) {
    return arr.slice(0, len);
  }
  return [...arr, ...new Array(len - arr.length)];
};

const App: React.ForwardRefRenderFunction<CompHandle, Props> = (
  { onClose, onShowAddToBoard, showEdit = true },
  forwardedRef
) => {
  const [notificationVisible, setNotificationVisible] = useState<VisibleState>(VisibleState.Hide);
  const isDesktop = useDesktop();

  const { data } = useSellerCollectionData();
  const { data: lastAddedSellerCollectionsData } = useLastAddedSellerCollections(data);

  const openPage = (sellerCollectionDataId) => {
    window.open(`/seller-collections/${sellerCollectionDataId}`, '_blank');
    setNotificationVisible(VisibleState.Hide);
  };

  const handleAddToBoard = () => {
    onShowAddToBoard?.();
  };

  useImperativeHandle(forwardedRef, () => ({
    show: () => {
      setNotificationVisible(VisibleState.Show);
    },
  }));

  useEffect(() => {
    if (![VisibleState.Show, VisibleState.Shown].includes(notificationVisible)) {
      onClose?.();
    }
  }, [notificationVisible, onClose]);

  if (![VisibleState.Show, VisibleState.Shown].includes(notificationVisible)) return null;

  return (
    <Notification
      className={css.favorite_notification}
      visible={[VisibleState.Show, VisibleState.Shown].includes(notificationVisible)}
      placement={isDesktop ? 'topRight' : 'bottom'}
      showMountAnimation
      textAlign="left"
      title={
        <div className={css.favorite_notification_title}>
          {/* {product?.cover && <Image className={css.title_img} src={product?.cover ?? ''} fit="cover" />} */}
          <span className={css.title_text}>Product(s) added to board</span>
          {showEdit && (
            <Button
              shape="rounded"
              onClick={() => {
                setNotificationVisible(VisibleState.Hide);
                handleAddToBoard();
              }}
              color="primary"
            >
              Edit
              {isDesktop ? <FiChevronDown /> : <FiChevronUp />}
            </Button>
          )}
        </div>
      }
      closable={false}
      duration={3000}
      onClose={() => setNotificationVisible(VisibleState.Hide)}
      afterShow={() => setNotificationVisible(VisibleState.Shown)}
      afterClose={() => setNotificationVisible(VisibleState.Hidden)}
      mouseDelay
    >
      <div className={css.favorite_notification_body}>
        {lastAddedSellerCollectionsData.map(({ id: sellerCollectionDataId, title, product_images }) => (
          <div
            key={sellerCollectionDataId}
            className={css.favorite_notification_item}
            onClick={() => openPage(sellerCollectionDataId)}
          >
            <div className={css.img_wrapper}>
              {formatLength(product_images, 3).map((src) =>
                src ? (
                  <Image key={src} className={css.img} src={src ?? ''} fit="cover" />
                ) : (
                  <div key={src} className={cn(css.img, css.no_src)} />
                )
              )}
            </div>
            <div className={css.title}>{title}</div>
            {isDesktop ? <div className={css.link}>View</div> : <FiChevronRight />}
          </div>
        ))}
      </div>
    </Notification>
  );
};

const AddedToBoardNotification = forwardRef(App);
export default AddedToBoardNotification;
