import { useCallback, useRef } from 'react';
import { BsUpcScan } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

import { useDesktop } from '@/hooks';
import useUserSettings from '@/hooks/queries/useUserSettings';
import { hasInvView } from '@/utils/biz';

import SearchPopup from './components/SearchPopup';
import css from './index.module.scss';

type SearchPopupHandle = React.ElementRef<typeof SearchPopup>;

const placeholder = 'Search for products...';

function SearchButton() {
  const isDesktop = useDesktop();
  const [searchParams] = useSearchParams();
  const userSettings = useUserSettings();
  const keyword = searchParams.get('keyword') || '';
  const searchPopupRef = useRef<SearchPopupHandle>(null);
  const autoCompleteWrapperRef = useRef(null);

  const openScanPage = useCallback((e) => {
    e.stopPropagation();
    window.open('/check-inv', '_blank');
  }, []);

  const searchButtonDesktop = (
    <div className={css.search_input_button}>
      <span className={css.search_icon}>
        <FiSearch />
      </span>
      {keyword ? (
        <span className={css.keyword}>{keyword}</span>
      ) : (
        <span className={css.placeholder}>{placeholder}</span>
      )}
      {hasInvView(userSettings?.data?.data?.app_collection_views) && (
        <span className={css.scan_button_wrapper} onClick={openScanPage}>
          <span className={css.text}>Scan</span>
          <BsUpcScan />
        </span>
      )}
    </div>
  );
  const searchButtonM = (
    <span className={css.search_button}>
      <FiSearch />
    </span>
  );
  const searchButton = isDesktop ? searchButtonDesktop : searchButtonM;
  const container = isDesktop ? autoCompleteWrapperRef.current : document.body;

  const openSearchPopup = () => {
    if (searchPopupRef.current) {
      searchPopupRef.current.show();
    }
  };

  return (
    <div className={css.search_autocomplete_wrapper} ref={autoCompleteWrapperRef}>
      <div onClick={openSearchPopup}>{searchButton}</div>
      {container && <SearchPopup ref={searchPopupRef} container={container} />}
    </div>
  );
}

export default SearchButton;
