import { FiChevronRight } from 'react-icons/fi';

import { Brand } from '@/types';

import css from './Brand.module.scss';

type Props = {
  brand: Brand;
};

const DESC = 'Browse more products';

const App = ({ brand }: Props) => {
  if (!brand) return null;
  const { name, logoURL, handle } = brand;
  const collectionURL = `/collections/${handle}`;

  return (
    <a className={css.ns_com_brand_comp} href={collectionURL}>
      <div className={css.brand_logo_wrapper}>
        <img src={logoURL} className={css.brand_logo} alt="logo" />
      </div>
      <div className={css.brand_name_wrapper}>
        <div className={css.brand_name}>{name}</div>
        <div className={css.desc}>{DESC}</div>
      </div>
      <div className={css.arrow}>
        <FiChevronRight strokeWidth={1} />
      </div>
    </a>
  );
};

export default App;
