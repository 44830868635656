.ns_com_favorite_button_main {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 0;
  text-align: center;
  user-select: none;
  transition: all 0.25s ease;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--color-danger) !important;
    font-size: 10px;
    line-height: 0;
    transform: translate(-50%, -70%);
  }
  &.favorited .loading {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .icon {
    width: 32px;
    height: 32px;
    fill: rgba(255, 255, 255, 0.75);
    stroke: var(--color-gray5);
    overflow: visible;
    cursor: pointer;
    transform-origin: center;
    transition: all 0.25s ease;
  }

  &:active {
    transform: scale(0.95) !important;

    .icon {
      stroke: var(--color-danger);
    }
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);

      .icon {
        stroke: var(--color-danger);
      }
    }
  }

  &.favorited .icon {
    fill: var(--color-danger);
    stroke: var(--color-danger);
  }

  &.favorited:active .icon {
    fill: #d03b3b;
    stroke: #d03b3b;
  }

  @media (hover: hover) {
    &.favorited:hover .icon {
      fill: #d03b3b;
      stroke: #d03b3b;
    }
  }

  @at-root .seller_collection_select_dialog:global(.modal--placement-topRight) {
    @media screen and (min-width: 769px) {
      :global {
        .modal__container {
          min-width: 360px;
          width: 360px;
          height: 585px;
          top: calc(120px + 38px);
        }
      }
    }
  }
}
