@at-root .ns_or_notification {
  .title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 24px;
  }
  :global {
    .notification__header {
      padding: var(--spacing-l);
    }

    .notification__close-btn {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
