import cn from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import Sticky from 'react-stickynode';

import InvListViewSwitch from '@/components/biz/InvListViewSwitch';
import ProductCardListView from '@/components/biz/ProductCardListView';
import SideBarM from '@/components/biz/SideBar/M';
import SideBarPC from '@/components/biz/SideBar/PC';
import Button from '@/components/Button';
import ImportButton from '@/components/ImportButton';
import ProductAddToCartDialog from '@/components/ProductAddToCartDialog';
import type { ProductFiltersChangedData } from '@/components/ProductFiltersBar';
import ProductFiltersBar from '@/components/ProductFiltersBar';
import ProductList from '@/components/ProductList';
import ProductsToLikeBoardButton from '@/components/ProductsToLikeBoardButton';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useHotFlashSettings } from '@/hooks/biz';
import useTimeStatus, { TimeStatus } from '@/hooks/biz/useTimeStatus';
import useCollectionProducts from '@/hooks/queries/useCollectionProducts';
import useBrand from '@/hooks/useBrand';
import useDesktop from '@/hooks/useDesktop';
import useSideBar from '@/hooks/useSideBar';
import { CollectionProduct } from '@/types';
import { convertListProductsCoverImgs } from '@/utils/util';

import CollectionDetailContext from './collectionDetailContext';
import CollectionPreOrder from './components/index';
import { preOrderCollectionHandle } from './cons';
import Header from './Header';
import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export enum ProductPreviewMode {
  Grid = 'grid',
  List = 'list',
}

const CUSTOM_COLLECTION_DESC = {
  'bright-af': (
    <div className={`${prefixCls}__collection_desc`}>
      <div className={`${prefixCls}__collection_desc_text`}>
        <div className={`${prefixCls}__collection_desc_text_weight`}>SAMPLE BOX DEAL</div>
        Get all 4 BrightAF teeth whitening items for only $29 (
        <span className={`${prefixCls}__collection_desc_text_line_through`}>$44.95</span>). Please note: only available
        for one sample purchase, and you must check out with{' '}
        <span className={`${prefixCls}__collection_desc_text_under_line`}>only these 4 items</span> in your cart.
      </div>
      <div className={`${prefixCls}__collection_desc_text`}>
        <span className={`${prefixCls}__collection_desc_text_weight`}>Code: BrightAF</span>
      </div>
    </div>
  ),
};

const sortOptionsForListView = [
  { label: 'Newest', value: 'published_at_desc' },
  { label: 'Oldest', value: 'published_at_asc' },
  { label: 'Qty: High to Low', value: 'inv_qty_desc' },
  { label: 'Qty: Low to High', value: 'inv_qty_asc' },
  { label: 'Cost: Low to High', value: 'price_asc' },
  { label: 'Cost: High to Low', value: 'price_desc' },
];

function CollectionDetail({ className, style }: Props): JSX.Element {
  const isDesktop = useDesktop();
  const params = useParams();
  const collectionHandle = params.id;
  const [filters, setFilters] = useState<ProductFiltersChangedData>();
  const productsParams = useMemo(
    () => ({
      page: 1,
      page_size: 60,
      ...filters,
    }),
    [filters]
  );

  // data
  const products = useCollectionProducts(collectionHandle, productsParams);
  const brand = useBrand({ handle: collectionHandle });

  const { collection, productsTotal, showSwitchModeBtn } = useMemo(() => {
    const curViews = products?.data?.pages?.[0]?.data?.views || [];
    return {
      collection: {
        ...products?.data?.pages?.[0]?.data?.collection,
        // 优先使用brand中配置的body_html
        body_html: brand?.bodyHtml || products?.data?.pages?.[0]?.data?.collection?.body_html,
      },
      views: curViews,
      productsTotal: products?.data?.pages?.[0]?.data?.pagination?.total || 0,
      showSwitchModeBtn: curViews.length
        ? isDesktop && curViews.includes(ProductPreviewMode.Grid) && curViews.includes(ProductPreviewMode.List)
        : undefined,
    };
  }, [brand?.bodyHtml, isDesktop, products?.data?.pages]);

  const productsIsSuccess = products?.isSuccess;
  const customCollectionDesc = CUSTOM_COLLECTION_DESC[collectionHandle];
  const collectionTitle = collection?.title || 'Collection Details';

  const [invViewMode, setInvViewMode] = useState<ProductPreviewMode>(ProductPreviewMode.Grid);
  const invModeUsedRef = useRef(null);
  const [addToCartDialogVisible, setAddToCartDialogVisible] = useState(false);
  const [addToCartProduct, setAddToCartProduct] = useState<CollectionProduct>();

  const showImportToStoreButton = productsTotal > 0 && productsTotal < 101;
  const showBrand = !!brand;
  const isShowListView =
    showSwitchModeBtn &&
    (invModeUsedRef.current || (!invModeUsedRef.current && invViewMode === ProductPreviewMode.List));

  const { sideBarPC, sideBarM } = useSideBar(collectionHandle);

  const productFiltersBar = useMemo(
    () => (
      <ProductFiltersBar
        onChange={setFilters}
        sortOptions={invViewMode === ProductPreviewMode.List ? sortOptionsForListView : []}
      />
    ),
    [invViewMode]
  );
  const sideBarPCData = useMemo(
    () =>
      sideBarPC.map((i) => ({
        ...i,
        target_data: invViewMode ? `${i.target_data}?view=${invViewMode}` : i.target_data,
      })),
    [sideBarPC, invViewMode]
  );

  const showSideBarPC = !!sideBarPC?.length && isDesktop;
  const showSideBarM = !!sideBarM?.length && !isDesktop;

  // hotflash
  const hotflashSettings = useHotFlashSettings();
  const setting = useMemo(
    () => hotflashSettings?.tabs?.find(({ handle }) => !!handle && handle === collectionHandle),
    [collectionHandle, hotflashSettings?.tabs]
  );
  const timeStatus = useTimeStatus(setting?.begins_at, setting?.ends_at);

  const [countDown, setCountDown] = useState(0);
  const isPreOrderCollection = preOrderCollectionHandle.includes(collectionHandle);

  // products
  const productsData = useMemo(() => {
    const newData = convertListProductsCoverImgs(
      _.compact(products?.data?.pages?.flatMap((page) => page?.data?.products))
    );
    // 如果是hot falsh，需要对数据做改造
    if ([TimeStatus.Coming, TimeStatus.Started].includes(timeStatus?.status)) {
      return newData.map((d) => ({
        ...d,
        hotFlash: {
          status: timeStatus?.status,
          countDown,
        },
      }));
    }
    return newData;
  }, [countDown, products?.data?.pages, timeStatus?.status]);

  const handleOpenAddToCart = useCallback((product) => {
    setAddToCartProduct(product);
    setAddToCartDialogVisible(true);
  }, []);

  useEffect(() => {
    if (invViewMode === ProductPreviewMode.List) {
      invModeUsedRef.current = true;
    }
  }, [invViewMode]);

  const collectionDetailContext = {
    timeStatus,
    setting,
    countDown,
    setCountDown,
    hotFlashTitle: hotflashSettings?.title || 'Hot Flash',
    imageURL: hotflashSettings?.image_url,
  };

  const secondaryNavBarMemo = useMemo(
    () => <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>{collectionTitle}</SecondaryNavBar>,
    [collectionTitle]
  );

  const productAddToCartDialogMemo = useMemo(
    () => (
      <ProductAddToCartDialog
        visible={addToCartDialogVisible}
        product={addToCartProduct}
        defaultVariant={addToCartProduct?.product?.variants?.[0]}
        onClose={() => setAddToCartDialogVisible(false)}
      />
    ),
    [addToCartDialogVisible, addToCartProduct]
  );

  const headerMemo = useMemo(
    () => <Header brand={brand} collection={collection} productsTotal={productsTotal} />,
    [brand, collection, productsTotal]
  );

  const sideBarPCMemo = useMemo(() => {
    if (!showSideBarPC) return null;

    return (
      <div className={`${prefixCls}__sidebar_wrapper`}>
        <SideBarPC data={sideBarPCData} />
      </div>
    );
  }, [showSideBarPC, sideBarPCData]);

  const sideBarMMemo = useMemo(() => {
    if (!showSideBarM) return null;

    return (
      <div className={`${prefixCls}__sidebar_m_wrapper`}>
        <SideBarM data={sideBarM} />
      </div>
    );
  }, [showSideBarM, sideBarM]);

  const handleInvViewMode = useCallback((val) => {
    setInvViewMode(val);
  }, []);

  const switchModeButtonMemo = useMemo(() => {
    if (!showSwitchModeBtn) return null;
    return <InvListViewSwitch value={invViewMode} onChange={handleInvViewMode} />;
  }, [handleInvViewMode, invViewMode, showSwitchModeBtn]);

  const extra = useMemo(
    () => (
      <>
        <ImportButton
          className={`${prefixCls}__actions_extra_wrapper-import-btn`}
          importProductsQueryParams={{ collection_handle: collection?.handle }}
          buttonColor="main"
        >
          Import Collection
        </ImportButton>
        <ProductsToLikeBoardButton collectionHandle={collection?.handle}>
          {(openDialog) => (
            <Button
              color="default"
              block
              // className={`${prefixCls}__actions_extra_wrapper-share-btn`}
              shape="rounded"
              onClick={openDialog}
            >
              <div className={`${prefixCls}__actions-share-btn_inner`}>Add to Board</div>
            </Button>
          )}
        </ProductsToLikeBoardButton>
      </>
    ),
    [collection?.handle]
  );

  const collectionPreOrder = useMemo(
    () => <CollectionPreOrder brand={brand} collection={collection} />,
    [brand, collection]
  );

  return (
    <CollectionDetailContext.Provider value={collectionDetailContext}>
      <div className={cn(prefixCls, className)} style={style}>
        <Helmet>
          <html lang="en" className={`${prefixCls}-active`} />
          <title>{collectionTitle}</title>
        </Helmet>
        {secondaryNavBarMemo}
        <div
          className={cn(`${prefixCls}__content_wrapper`, { sidebar_pc: showSideBarPC }, { sidebar_m: showSideBarM })}
        >
          {sideBarPCMemo}
          <div className={cn(`${prefixCls}__content`, { brand: showBrand })}>
            <div className={`${prefixCls}__header`}>
              {isPreOrderCollection ? (
                <>
                  {collectionPreOrder}
                  {sideBarMMemo}
                </>
              ) : (
                <>
                  {productsIsSuccess && headerMemo}
                  {customCollectionDesc}
                  {sideBarMMemo}
                </>
              )}
            </div>

            <Sticky top={isDesktop ? '.app-layout__nav-bar' : `.${prefixCls}__secondary-nav-bar`} innerZ={2}>
              <div className={cn(`${prefixCls}__product_filters_bar_wrapper`, { brand: showBrand })}>
                {productsIsSuccess && productFiltersBar}
                <div className={`${prefixCls}__actions_extra_wrapper`}>
                  {isDesktop && showImportToStoreButton && extra}
                  {switchModeButtonMemo}
                </div>
              </div>
            </Sticky>

            <StatusViewRenderer
              data={productsData}
              error={products.error}
              isLoading={products.isLoading}
              hasMore={products.hasNextPage}
              loadMore={products.fetchNextPage}
              statusStyle={{ marginTop: 64 }}
            >
              <div style={{ display: invViewMode === ProductPreviewMode.Grid ? 'block' : 'none' }}>
                {useMemo(
                  () => (
                    <ProductList className={`${prefixCls}__product-list`} data={productsData} />
                  ),
                  [productsData]
                )}
              </div>
              {isShowListView && (
                <div style={{ display: invViewMode === ProductPreviewMode.List ? 'block' : 'none' }}>
                  <div className={`${prefixCls}__product-list-list-view`}>
                    {productsData.map((productData, idx) => (
                      <ProductCardListView
                        data={productData}
                        key={productData?.product?.id || idx}
                        isShow={invViewMode === ProductPreviewMode.List}
                        onOpenAddToCart={handleOpenAddToCart}
                      />
                    ))}
                  </div>
                </div>
              )}
            </StatusViewRenderer>
            {!isDesktop && showImportToStoreButton && (
              <div className={cn(`${prefixCls}__footer`)}>
                <div className={`${prefixCls}__actions_extra_wrapper`}>{extra}</div>
              </div>
            )}
          </div>
        </div>
        {productAddToCartDialogMemo}
      </div>
    </CollectionDetailContext.Provider>
  );
}

export default CollectionDetail;
