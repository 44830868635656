.ns_com_account_menu_main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  & > * + * {
    margin-left: var(--spacing-xxl);
  }

  a:hover {
    color: var(--color-primary);
  }

  a:not(:hover) {
    color: var(--color-black);
  }

  .popover {
    .content_wrapper {
      width: 280px;
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 2;
      user-select: none;

      a:not(:hover) {
        color: var(--color-black);
      }
    }

    .header_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .body {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: var(--spacing-l) 24px;
        cursor: pointer;
      }

      .footer {
        padding: var(--spacing-l) 24px;
        margin-top: var(--spacing-l);
        border-top: 1px solid var(--color-gray1);
      }
    }

    .account_avatar {
      background: var(--color-primary);
    }

    .account_info {
      flex: 1;
      min-width: 0;
      margin-left: var(--spacing-l);
      line-height: 1.4;
    }

    .account_name {
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .account_phone {
      color: var(--color-gray4);
    }

    .account_referral_code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      cursor: pointer;

      .text {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          margin-left: var(--spacing-m);
          font-style: normal;
          font-weight: normal;
          background: #f5f5f5;
          border-radius: 50%;
        }
      }
    }

    .body_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: var(--spacing-l) 24px;
      border-top: 1px solid var(--color-gray1);
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        :global {
          .react-icons {
            margin-right: var(--spacing-m);
            font-size: 18px;
            line-height: 0;
          }
        }
      }
    }

    .menu_item {
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }
    }

    .submenu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 24px;
    }
    .submenu_title {
      color: var(--color-gray4);
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: var(--spacing-l) 24px;
      border-top: 1px solid var(--color-gray1);
    }
  }

  .account_btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    :global {
      .react-icons {
        font-size: 20px;
        margin-right: var(--spacing-m);
      }
    }
  }

  :global {
    .ant-popover-inner {
      border-radius: 8px;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-divider {
      width: auto;
      margin: var(--spacing-l) -24px;
      border-color: var(--color-gray1);
    }
  }
}
