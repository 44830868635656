import { TimeStatus } from './hooks/biz/useTimeStatus';

enum ShippingAddressRouteType {
  No_ADDRESS = 'local_pickup',
  OUTSIDE = 'outside',
}

export enum ProductStatus {
  Archived = 'archived',
  Active = 'active',
}

type Product = {
  id: string;
  variant_id: string;
  sku?: string;
  barcode?: string;
  price_range?: string;
  title?: string;
  cover?: string;
  inventory_quantity?: number;
  available_quantity?: number;
  available?: number;
  tags?: string[];
  price_max?: number;
  price_min?: number;
  compare_at_price_max?: number;
  compare_at_price_min?: number;
  special_oos?: boolean;
  master_style?: string;
  option_color_idx?: string;
  option_size_idx?: string;
  drop_soon?: {
    at?: string;
    qty_limit?: number;
    tz?: string;
  };
  out_of_stock?: number;
  staus?: ProductStatus;
  preorder?: any[];
  variants?: {
    title?: string;
    id?: number;
    sku?: string;
    price?: number;
  }[];
  [key: string]: any;
};

type Placeholder = string;

type Brand = {
  handle: string;
  name: string;
  bgURL: string;
  logoURL: string;
  introduction: string;
};

type Address = {
  name?: string;
  first_name?: string;
  last_name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  country?: string;
  zip?: string;
  phone_country?: string;
  phone?: string;
  id?: number;
  key?: string;
};

type CollectionProduct = {
  product: Product;
  rel: {
    fav_collection_ids?: string[];
    is_favorite?: number;
    is_imported?: number;
  };
  hotFlash?: {
    countDown?: number;
    status?: TimeStatus;
  };
};

type SellerCollection = {
  id: string;
  is_default?: boolean;
  is_public?: boolean;
  product_count?: string;
};

enum ProductPreviewMode {
  Grid = 'grid',
  List = 'list',
}

type ShippingAddress = {
  id?: number;
  key?: string;
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  country_code?: string;
  first_name?: string;
  last_name?: string;
  latitude?: string;
  longitude?: string;
  name?: string;
  phone?: string;
  phone_country?: string;
  province?: string;
  province_code?: string;
  zip?: string;
};

export {
  Address,
  Brand,
  CollectionProduct,
  Placeholder,
  Product,
  ProductPreviewMode,
  SellerCollection,
  ShippingAddress,
  ShippingAddressRouteType,
};
