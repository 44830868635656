import { PrivateInventoryActivityType } from './types';

export const privateInventoryActivityTypeMaps = {
  [PrivateInventoryActivityType.Stock_up]: 'Stock Up',
  [PrivateInventoryActivityType.Applied_To_Order]: 'Applied to order',
  [PrivateInventoryActivityType.Unapplied_From_Order]: 'Unapplied from order',
  [PrivateInventoryActivityType.Adjustment]: 'Manual adjustment',
};

export default privateInventoryActivityTypeMaps;
