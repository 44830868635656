import useOrderAdded from '@/hooks/useOrderAdded';

import { OrderItem } from '../Orders/types';
import css from './SoldOutError.module.scss';

type SoldOutErrorType = {
  order: OrderItem;
  shopifyOrderDetailUrl?: string;
};

const SoldOutError = ({ order, shopifyOrderDetailUrl }: SoldOutErrorType) => {
  const { regardShopifyUnpaidSoldOutOrder, regardSoldOutItems } = useOrderAdded(order);
  const soldOutItemsStr = regardSoldOutItems.map(({ sku }) => sku).join(',');

  if (regardShopifyUnpaidSoldOutOrder) {
    return (
      <div className={css.ns_com_sold_out_error_main}>
        {soldOutItemsStr} sold out. Remove these item(s) in Shopify and try again.{' '}
        <a
          className={css.sold_out_error_link}
          href={shopifyOrderDetailUrl}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          Go to Shopify
        </a>
      </div>
    );
  }

  return (
    <div className={css.sold_out_error_line}>
      <span>{soldOutItemsStr} sold out. You may cancel and place a new order.</span>
    </div>
  );
};

export default SoldOutError;
