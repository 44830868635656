import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { updateShippingAddress } from '@/services/ds';

export default function useUpdateShippingAddress() {
  const queryClient = useQueryClient();

  return useMutation((args: { id: number | string; params: any }) => updateShippingAddress(args.id, args.params), {
    onSuccess: (data, { id, params }) => {
      queryClient.setQueryData('shippingAddresses', (prevData: any) =>
        produce(prevData, (draft: any) => {
          const address = _.find(draft?.data, { id });
          if (address) _.assign(address, params);
        })
      );

      queryClient.invalidateQueries('shippingAddresses');
    },
  });
}
