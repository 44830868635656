// 指定code，前端定义接口文案
const ERROR_CODES_TO_ERROR_MESSAGES = {
  LYNX: {
    60101: 'Code not found.',
    60102: 'This code has expired. Please get a new code.',
    60103: 'This code has been used. Please get a new code.',
    60104: 'Sending frequency.',
    60106: 'Incorrect password.',
    // 60110: 'Failed to send verification code.', // 前端不再拦截此code，用接口的error message
    60111: `This phone/email has not been registered.`,
    60112: 'This phone/email has been registered.',
    60113: 'Incorrect phone/email format.',
    60114: 'This phone/email has been verified by another account.',
  },
};

const ERROR_CODES = {
  phoneNotRegistered: 60111,
  sendingFrequency: 60104,
  duplicatePaidOrder: 72588,
  addToBoardsFailedForDiscontinued: 9812,
  authShopConnectFailed: 60201,
  boundByOtherAccount: 60211,
};

const needCustomHandleErrorCodes = [
  ERROR_CODES.phoneNotRegistered,
  ERROR_CODES.sendingFrequency,
  ERROR_CODES.duplicatePaidOrder,
  ERROR_CODES.addToBoardsFailedForDiscontinued,
  ERROR_CODES.authShopConnectFailed,
  ERROR_CODES.boundByOtherAccount,
];

export { ERROR_CODES, ERROR_CODES_TO_ERROR_MESSAGES, needCustomHandleErrorCodes };
