import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import useTimeStatus, { TimeStatus } from '@/hooks/biz/useTimeStatus';

import css from './index.module.scss';
import useDuration from './useDuration';
import useInterval from './useInterval';

/**
 * 判断是否开始倒计时
 * 倒计时结束自动清除定时器
 * 对外部返回倒计时当前的时间
 */
const delay = 1000; // ms

const CountDown = ({
  startTime,
  endTime,
  onFinish,
  onCountDown,
}: {
  startTime: string;
  endTime: string;
  onFinish?: () => void;
  onCountDown?: (num: number) => void;
}) => {
  const { status, update } = useTimeStatus(startTime, endTime);

  const countDownInit = useMemo(() => {
    const startT = moment(startTime);
    const endT = moment(endTime);
    const currentT = moment();
    if (status === TimeStatus.Coming) {
      return startT.diff(currentT);
    }
    if (status === TimeStatus.Started) {
      return endT.diff(currentT);
    }
    return 0;
  }, [startTime, endTime, status]);

  const [countDown, setCountDown] = useState(countDownInit);
  const [days, hours, minutes, seconds] = useDuration(countDown);
  const durationData = [
    {
      data: days,
      label: 'Days',
    },
    {
      data: hours,
      label: 'Hrs',
    },
    {
      data: minutes,
      label: 'Mins',
    },
    {
      data: seconds,
      label: 'Sec',
    },
  ];

  useEffect(() => {
    setCountDown(countDownInit);
  }, [countDownInit]);

  // 倒计时
  useInterval(
    () => {
      const isEnd = countDown - delay <= 0;

      if (isEnd) {
        // 当前countdown结束，更新状态，进入下一countdown
        update();
        onFinish?.();
      }
      setCountDown((prev) => (isEnd ? 0 : prev - delay));
    },
    countDown === 0 ? null : delay
  );

  useEffect(() => {
    onCountDown?.(countDown);
  }, [countDown, onCountDown]);

  return (
    <div className={css.ns_com_count_down_main}>
      {durationData.map(({ data, label }) => (
        <div className={css.time_wrapper} key={label}>
          <span className={css.num}>{data}</span>
          <span className={css.suffix}>{label}</span>
        </div>
      ))}
    </div>
  );
};

export default CountDown;
