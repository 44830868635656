import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { updateCart } from '@/services/ds';

export default function useUpdateCart() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof updateCart>[0]) => updateCart(params), {
    onMutate: async (params) => {
      await queryClient.cancelQueries('cart');

      const prevData = queryClient.getQueryData<any>('cart');

      // Optimistically update to the new data
      const newData: any = produce(prevData, (draft: any) => {
        !_.isEmpty(params?.note) && _.set(draft, 'data.note', params.note);

        // eslint-disable-next-line consistent-return
        _.forEach(params?.items, (item) => {
          // just update current existing item
          const prevItemIndex = _.findIndex(draft?.data?.items, { variant_id: String(item?.variant_id) });
          if (prevItemIndex === -1) return;

          if (item.qty > 0) {
            _.set(draft, `data.items[${prevItemIndex}].qty`, item.qty);
          } else {
            draft.data.items.splice(prevItemIndex, 1);
          }
        });
      });
      queryClient.setQueryData('cart', newData);

      return { prevData, newData };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context: any) => {
      if (context?.prevData) {
        const rollbackData = produce(context.prevData, (draft: any) => {
          // not rollback note
          context?.newData?.data?.note && _.set(draft, 'data.note', context.newData.data.note);
        });
        queryClient.setQueryData('cart', rollbackData);
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries('cart');
    },
  });
}
