import { Image as AntImage } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FreeMode, Mousewheel, Navigation, Pagination, Swiper as SwiperClass, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import FulfillLabel from '@/components/FulfillLabel';
import Image from '@/components/Image';
import { CollectionProduct, ProductStatus } from '@/types';

import { getImageIndexByVariant } from '../util';
import css from './ProductGallery.module.scss';

type CompHandle = {
  inst: SwiperClass;
};

type Props = {
  data?: CollectionProduct;
};

const ProductGalleryComp: React.ForwardRefRenderFunction<CompHandle, Props> = ({ data }, forwardedRef) => {
  const { product } = data || {};
  const images = product?.images || [];
  const isProductOutOfStock = !_.isNil(product?.out_of_stock) && !!product?.out_of_stock;
  const dropSoonInfo = useMemo(() => product?.drop_soon || {}, [product?.drop_soon]);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [preview, setPreview] = useState({ visible: false, current: 0 });
  const swiperRef = useRef<SwiperClass>();
  const [searchParams] = useSearchParams();
  const variantIdDefault = Number(searchParams.get('variant'));
  const variants = product?.variants;
  const currentVariant = useMemo(
    () => _.find(variants, { id: variantIdDefault }) ?? variants?.[0],
    [variants, variantIdDefault]
  );

  const leftTopLabel = useMemo(() => {
    if (product?.status === ProductStatus.Archived) {
      return <div className={css.out_of_stock_label}>Discontinued</div>;
    }
    if (isProductOutOfStock) {
      return <div className={css.out_of_stock_label}>Sold Out</div>;
    }
    return null;
  }, [isProductOutOfStock, product?.status]);

  const conditions = [
    {
      title: 'Preorder',
      condition: () => product?.preorder?.length,
    },
    {
      title: 'Pre-Launch',
      condition: () => dropSoonInfo?.at && moment().isBefore(dropSoonInfo?.at),
    },
  ];
  const productLabelTitle = conditions.find(({ condition }) => condition())?.title;

  useImperativeHandle(forwardedRef, () => ({
    inst: swiperRef.current,
  }));

  return (
    <div className={css.ns_com_product_gallery_main}>
      <div style={{ position: 'relative' }}>
        {leftTopLabel}
        {productLabelTitle && <div className={css.product_label}>{productLabelTitle}</div>}
        {leftTopLabel ? (
          <></>
        ) : (
          <div className={css.shipping_label}>
            <FulfillLabel product={product} />
          </div>
        )}
        <Swiper
          className={css.swiper_wrapper}
          freeMode
          slidesPerView="auto"
          spaceBetween={4}
          navigation
          pagination={{
            type: 'fraction',
            renderFraction: (currentClass, totalClass) =>
              `<div class="swiper-pagination-fraction-inner">
              <span class="${currentClass}"></span>/<span class="${totalClass}"></span>
            </div>`,
          }}
          thumbs={{ swiper: thumbsSwiper, autoScrollOffset: 1 }}
          modules={[FreeMode, Navigation, Pagination, Thumbs]}
          initialSlide={variantIdDefault ? 0 : getImageIndexByVariant(images, currentVariant)}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {images?.map((item: any, index: number) => {
            const isSquare = !!item?.width && item?.width === item?.height;
            const fitMode = isSquare ? 'scale-down' : 'contain';
            return (
              <SwiperSlide key={item?.id}>
                <Image
                  className={css.gallery_image}
                  src={item?.src}
                  fit={fitMode}
                  onClick={() => setPreview({ visible: true, current: index })}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <Swiper
        className={css.product_gallery_swiper_thumbs_wrapper}
        direction="vertical"
        onSwiper={(swiperInstance) => setThumbsSwiper(swiperInstance)}
        spaceBetween={4}
        slidesPerView="auto"
        freeMode
        mousewheel
        watchSlidesProgress
        modules={[FreeMode, Mousewheel, Navigation, Thumbs]}
      >
        {images?.map((item: any) => (
          <SwiperSlide key={item?.id}>
            <Image className={css.img_wrapper} src={item?.src} fit="cover" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div style={{ display: 'none' }}>
        <AntImage.PreviewGroup
          preview={{
            ...preview,
            onVisibleChange: (visible) => setPreview((prevPreview) => ({ ...prevPreview, visible })),
          }}
        >
          {images?.map((item: any) => (
            <AntImage key={item?.id} src={item?.src} />
          ))}
        </AntImage.PreviewGroup>
      </div>
    </div>
  );
};

const ProductGallery = forwardRef(ProductGalleryComp);

export default ProductGallery;
