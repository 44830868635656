import { Form, message, Select } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import guideImage01 from '@/assets/images/guides/import-products-guide-01.png';
import guideImage02 from '@/assets/images/guides/import-products-guide-02.png';
import guideImage03 from '@/assets/images/guides/import-products-guide-03.png';
import Button from '@/components/Button';
import ImportProductList from '@/components/ImportProductList';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StepGuideDialog from '@/components/StepGuideDialog';
import defaultPriceRules from '@/constants/defaultPriceRules';
import useCurrentStore from '@/hooks/queries/useCurrentStore';
import useImportProducts from '@/hooks/queries/useImportProducts';
import useStorePriceRules from '@/hooks/queries/useStorePriceRules';
import useStores from '@/hooks/queries/useStores';
import useUpdateStoreImportedProducts from '@/hooks/queries/useUpdateStoreImportedProducts';

import css from './index.module.scss';
import usePreFillTags from './usePreFillTags';

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Active', value: 'active' },
];

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  importProductsQueryParams?: any;
  visible?: boolean;
  title?: string;
  okText?: string;
  showMountAnimation?: boolean;
  onOk?: (shopId: string, taskId: string) => void;
  onCancel?: () => void;
  afterShow?: () => void;
  afterClose?: () => void;
}

function ImportProductsDialog({
  className,
  style,
  importProductsQueryParams,
  visible,
  title = 'Import Products',
  okText = 'Import to Store',
  showMountAnimation,
  onOk,
  onCancel,
  afterShow,
  afterClose,
}: Props): JSX.Element {
  const stores = useStores();
  const currentStore = useCurrentStore();
  const importProducts = useImportProducts(importProductsQueryParams);
  const [form] = Form.useForm();
  const [shopId, setShopId] = useState(currentStore?.data?.id);
  const [tagsInputValue, setTagsInputValue] = useState('');
  const [guideDialogVisible, setGuideDialogVisible] = useState(false);
  const priceRules = useStorePriceRules(shopId);
  const updateImportedProducts = useUpdateStoreImportedProducts(shopId);
  const optionsRef = useRef<HTMLDivElement>(null);
  const storesData = stores?.data?.data?.shops;
  const importProductsData = importProducts?.data?.data;
  const priceRulesData = useMemo(
    () => _.merge({}, defaultPriceRules, priceRules?.data?.data?.price_rule),
    [priceRules?.data]
  );
  const isLoading = _.isNil(shopId) || importProducts.isLoading;
  const dontShowGuideDialogKey = 'dontShowImportProductsGuide';
  const isDontShowGuideDialog = () => localStorage.getItem(dontShowGuideDialogKey) === 'true';

  const preFillTags = usePreFillTags(importProductsData);

  useEffect(() => {
    setShopId(currentStore?.data?.id);
  }, [currentStore?.data]);

  useEffect(() => {
    form.setFieldsValue({ tags: preFillTags });
  }, [preFillTags, form]);

  const handleImportToStore = () => {
    const values = form.getFieldsValue();
    updateImportedProducts.mutate(values, {
      onSuccess: (data) => {
        !_.isEmpty(data?.data?.task_id) ? onOk?.(shopId, data.data.task_id) : message.error('Import Failure!');
      },
      onError: (error) => {
        message.error((error as Error)?.message || 'Import Failure!');
      },
    });
  };

  const getOptionsPopupContainer = () => optionsRef.current as HTMLElement;

  if (!visible) return null;
  return (
    <Modal
      className={cn(css.ns_com_import_product_dialog_main, className)}
      style={style}
      visible={visible}
      title={title}
      fullscreen="mobile"
      showMountAnimation={showMountAnimation}
      footer={
        <Button
          color="primary"
          block
          shape="rounded"
          loading={updateImportedProducts.isLoading || updateImportedProducts.isSuccess}
          loadingText="Importing"
          disabled={updateImportedProducts.isLoading || updateImportedProducts.isSuccess}
          onClick={() => form.submit()}
        >
          {okText}
        </Button>
      }
      closable={!updateImportedProducts.isLoading}
      onClose={onCancel}
      afterShow={afterShow}
      afterClose={afterClose}
    >
      <div>
        <StatusViewRenderer
          isEmpty={false}
          isLoading={isLoading}
          error={importProducts.error}
          statusStyle={{ marginTop: 64 }}
        >
          <Form
            form={form}
            name="priceRulesForm"
            preserve
            onFinish={isDontShowGuideDialog() ? handleImportToStore : () => setGuideDialogVisible(true)}
          >
            <div ref={optionsRef} className={css.options}>
              <div className={css.item}>
                <div className={css.control}>
                  <Select
                    popupClassName={css.dropdown}
                    dropdownStyle={{ width: '100%' }}
                    dropdownMatchSelectWidth={false}
                    listHeight={224}
                    size="large"
                    bordered={false}
                    menuItemSelectedIcon={<IoIosCheckmarkCircle />}
                    getPopupContainer={getOptionsPopupContainer}
                    placeholder="Select Store"
                    defaultValue={shopId}
                    onChange={(value: string) => setShopId(value)}
                  >
                    {storesData?.map((shop: any) => (
                      <Select.Option key={shop?.id} value={shop?.id}>
                        {shop?.name} ({shop?.shopify_domain})
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className={css.item}>
                <div className={css.label}>Import as</div>
                <div className={css.control}>
                  <Form.Item
                    name="status"
                    noStyle
                    initialValue={localStorage.getItem('lastImportProductsStatus') ?? 'draft'}
                  >
                    <Select
                      style={{ textAlign: 'right' }}
                      options={statusOptions}
                      popupClassName={css.dropdown}
                      dropdownStyle={{ width: '100%' }}
                      dropdownMatchSelectWidth={false}
                      size="large"
                      bordered={false}
                      menuItemSelectedIcon={<IoIosCheckmarkCircle />}
                      getPopupContainer={getOptionsPopupContainer}
                      placeholder="Select Status"
                      onChange={(value: string) => localStorage.setItem('lastImportProductsStatus', value)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className={css.item}>
                <div className={css.label}>Add Tags</div>
                <div className={css.control}>
                  <Form.Item name="tags" noStyle initialValue={[]}>
                    <Select
                      popupClassName={css.dropdown}
                      defaultActiveFirstOption={
                        !_.isEmpty(tagsInputValue) && !_.includes(form.getFieldValue('tags'), tagsInputValue)
                      }
                      dropdownStyle={{ width: '100%' }}
                      dropdownMatchSelectWidth={false}
                      listHeight={224}
                      mode="tags"
                      size="large"
                      bordered={false}
                      placeholder="Styles, Festivals, Feelings, be creative!"
                      menuItemSelectedIcon={<IoIosCheckmarkCircle />}
                      notFoundContent={null}
                      getPopupContainer={getOptionsPopupContainer}
                      onSearch={(value) => setTagsInputValue(value)}
                      onChange={() => setTagsInputValue('')}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <ImportProductList data={importProductsData} priceRules={priceRulesData} form={form} />
          </Form>
        </StatusViewRenderer>

        {!isDontShowGuideDialog() && (
          <StepGuideDialog
            visible={guideDialogVisible}
            showFinishButton
            dontShowAgainKey={dontShowGuideDialogKey}
            onClose={() => {
              setGuideDialogVisible(false);
              handleImportToStore();
            }}
          >
            <StepGuideDialog.Step
              coverImage={guideImage01}
              coverImageSize={{ width: 351, height: 174 }}
              title="Step 1: Find newly added KiwiDrop items"
              description={
                <>
                  1. Filter “Product vendor”
                  <br />
                  2. Sort by “Created (newest first)”
                </>
              }
            />
            <StepGuideDialog.Step
              coverImage={guideImage02}
              coverImageSize={{ width: 313, height: 210 }}
              title="Step 2: EDIT to show your vibe!"
              description="Leverage photos, descriptions, titles, and collections to add your personal touch!"
            />
            <StepGuideDialog.Step
              coverImage={guideImage03}
              coverImageSize={{ width: 249, height: 210 }}
              title="Step 3: Remember to set as Active"
              description="Make sure “Product status” is set to Active, and “Online Store” is added to available “Sales channels”"
            />
          </StepGuideDialog>
        )}
      </div>
    </Modal>
  );
}

export default ImportProductsDialog;
