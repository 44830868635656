import { Stepper as AntStepper } from 'antd-mobile';
import type { StepperProps } from 'antd-mobile/es/components/stepper';
import cn from 'classnames';

import css from './index.module.scss';

export type { StepperProps };

function Stepper({ className, ...restProps }: StepperProps): JSX.Element {
  return <AntStepper className={cn(css.ns_com_stepper_main, className)} {...restProps} />;
}

export default Stepper;
