import { Box, Button, Page } from '@shopify/polaris';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import ListView from '@/components/PaymentMethodsDialog/components/ListView';
import usePaymentMethods from '@/hooks/queries/usePaymentMethods';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';

import Empty from './Empty';
import css from './index.module.scss';
import Skeleton from './Skeleton';

const PaymentMethods = () => {
  const navigate = useNavigate();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const paymentMethodsRQ = usePaymentMethods();
  const paymentMethodsData = useMemo(
    () =>
      Array.isArray(paymentMethodsRQ?.data?.data?.payment_methods) ? paymentMethodsRQ?.data?.data?.payment_methods : [],
    [paymentMethodsRQ?.data?.data?.payment_methods]
  );
  const hasPaymentMethods = !!paymentMethodsData?.length;

  const handleAdd = () => {
    navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods' }));
  };

  if (paymentMethodsRQ.isLoading) {
    return <Skeleton />;
  }

  return (
    <Page
      title="Payment Methods"
      primaryAction={
        <Button primary onClick={handleAdd}>
          Add
        </Button>
      }
    >
      <Helmet>
        <html lang="en" className={css.ns_or_payment_methods_main_active} />
        <title>Payment Methods</title>
      </Helmet>
      {hasPaymentMethods ? (
        <Box paddingBlockEnd="4">
          <ListView data={paymentMethodsData} />
        </Box>
      ) : (
        <Empty onAdd={handleAdd} />
      )}
    </Page>
  );
};

export default PaymentMethods;
