import { useMutation, useQueryClient } from 'react-query';

import { bindEmail } from '@/services/sso';

export default function useBindEmail() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof bindEmail>[0]) => bindEmail(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('authUser');
    },
  });
}
