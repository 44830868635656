import { Button } from 'antd';
import { FC } from 'react';

import { getPayoutReportDownloadLink } from '@/services/jennifer';

import type { usePayout } from '../../hooks/usePayout';
import styles from './index.module.scss';

export const PayoutItem: FC<{ data: ReturnType<typeof usePayout>['data'][number]; shopId: string }> = ({
  data,
  shopId,
}) => (
  <div className={styles.row}>
    <span className={styles.title}>{data.title}</span>
    <Button
      type="primary"
      shape="round"
      className={styles.btn}
      onClick={() => {
        getPayoutReportDownloadLink(shopId)({
          started_at: data.started_at,
          ended_at: data.ended_at,
        }).then((res) => {
          if (res.data?.download_url) {
            window.open(res.data?.download_url);
          }
        });
      }}
    >
      Download
    </Button>
  </div>
);
