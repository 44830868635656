import { Input } from '@/components/common';

interface Props {
  value?: string;
  onChange?: (d: string) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
}

const InputNumber = (props: Props) => {
  const { value, onChange, disabled, placeholder, className } = props;
  const handleChange = (val: string) => {
    const newVal = `${val.match(/^\d{1,}/g)?.[0] || ''}`.slice(0, 2);
    onChange?.(newVal);
  };

  return (
    <span className={className}>
      <Input value={value} onChange={handleChange} disabled={disabled} placeholder={placeholder} />
    </span>
  );
};

export default InputNumber;
