const statesDB = [
  { name: 'Australian Capital Territory', countryCode: 'AU', countryName: 'Australia', stateCode: 'ACT' },
  { name: 'New South Wales', countryCode: 'AU', countryName: 'Australia', stateCode: 'NSW' },
  { name: 'Northern Territory', countryCode: 'AU', countryName: 'Australia', stateCode: 'NT' },
  { name: 'Queensland', countryCode: 'AU', countryName: 'Australia', stateCode: 'QLD' },
  { name: 'South Australia', countryCode: 'AU', countryName: 'Australia', stateCode: 'SA' },
  { name: 'Tasmania', countryCode: 'AU', countryName: 'Australia', stateCode: 'TAS' },
  { name: 'Victoria', countryCode: 'AU', countryName: 'Australia', stateCode: 'VIC' },
  { name: 'Western Australia', countryCode: 'AU', countryName: 'Australia', stateCode: 'WA' },
  { name: 'Alberta', countryCode: 'CA', countryName: 'Canada', stateCode: 'AB' },
  { name: 'British Columbia', countryCode: 'CA', countryName: 'Canada', stateCode: 'BC' },
  { name: 'Manitoba', countryCode: 'CA', countryName: 'Canada', stateCode: 'MB' },
  { name: 'New Brunswick', countryCode: 'CA', countryName: 'Canada', stateCode: 'NB' },
  { name: 'Newfoundland and Labrador', countryCode: 'CA', countryName: 'Canada', stateCode: 'NL' },
  { name: 'Northwest Territories', countryCode: 'CA', countryName: 'Canada', stateCode: 'NT' },
  { name: 'Nova Scotia', countryCode: 'CA', countryName: 'Canada', stateCode: 'NS' },
  { name: 'Nunavut', countryCode: 'CA', countryName: 'Canada', stateCode: 'NU' },
  { name: 'Ontario', countryCode: 'CA', countryName: 'Canada', stateCode: 'ON' },
  { name: 'Prince Edward Island', countryCode: 'CA', countryName: 'Canada', stateCode: 'PE' },
  { name: 'Quebec', countryCode: 'CA', countryName: 'Canada', stateCode: 'QC' },
  { name: 'Saskatchewan', countryCode: 'CA', countryName: 'Canada', stateCode: 'SK' },
  { name: 'Yukon', countryCode: 'CA', countryName: 'Canada', stateCode: 'YT' },
  { name: 'England', countryCode: 'GB', countryName: 'United Kingdom', stateCode: 'ENG' },
  { name: 'Northern Ireland', countryCode: 'GB', countryName: 'United Kingdom', stateCode: 'NIR' },
  { name: 'Scotland', countryCode: 'GB', countryName: 'United Kingdom', stateCode: 'SCT' },
  { name: 'Wales', countryCode: 'GB', countryName: 'United Kingdom', stateCode: 'WLS' },
  { name: 'Alabama', countryCode: 'US', countryName: 'United States', stateCode: 'AL' },
  { name: 'Alaska', countryCode: 'US', countryName: 'United States', stateCode: 'AK' },
  { name: 'Arizona', countryCode: 'US', countryName: 'United States', stateCode: 'AZ' },
  { name: 'Arkansas', countryCode: 'US', countryName: 'United States', stateCode: 'AR' },
  { name: 'California', countryCode: 'US', countryName: 'United States', stateCode: 'CA' },
  { name: 'Colorado', countryCode: 'US', countryName: 'United States', stateCode: 'CO' },
  { name: 'Connecticut', countryCode: 'US', countryName: 'United States', stateCode: 'CT' },
  { name: 'Delaware', countryCode: 'US', countryName: 'United States', stateCode: 'DE' },
  { name: 'District of Columbia', countryCode: 'US', countryName: 'United States', stateCode: 'DC' },
  { name: 'Florida', countryCode: 'US', countryName: 'United States', stateCode: 'FL' },
  { name: 'Georgia', countryCode: 'US', countryName: 'United States', stateCode: 'GA' },
  { name: 'Hawaii', countryCode: 'US', countryName: 'United States', stateCode: 'HI' },
  { name: 'Idaho', countryCode: 'US', countryName: 'United States', stateCode: 'ID' },
  { name: 'Illinois', countryCode: 'US', countryName: 'United States', stateCode: 'IL' },
  { name: 'Indiana', countryCode: 'US', countryName: 'United States', stateCode: 'IN' },
  { name: 'Iowa', countryCode: 'US', countryName: 'United States', stateCode: 'IA' },
  { name: 'Kansas', countryCode: 'US', countryName: 'United States', stateCode: 'KS' },
  { name: 'Kentucky', countryCode: 'US', countryName: 'United States', stateCode: 'KY' },
  { name: 'Louisiana', countryCode: 'US', countryName: 'United States', stateCode: 'LA' },
  { name: 'Maine', countryCode: 'US', countryName: 'United States', stateCode: 'ME' },
  { name: 'Maryland', countryCode: 'US', countryName: 'United States', stateCode: 'MD' },
  { name: 'Massachusetts', countryCode: 'US', countryName: 'United States', stateCode: 'MA' },
  { name: 'Michigan', countryCode: 'US', countryName: 'United States', stateCode: 'MI' },
  { name: 'Minnesota', countryCode: 'US', countryName: 'United States', stateCode: 'MN' },
  { name: 'Mississippi', countryCode: 'US', countryName: 'United States', stateCode: 'MS' },
  { name: 'Missouri', countryCode: 'US', countryName: 'United States', stateCode: 'MO' },
  { name: 'Montana', countryCode: 'US', countryName: 'United States', stateCode: 'MT' },
  { name: 'Nebraska', countryCode: 'US', countryName: 'United States', stateCode: 'NE' },
  { name: 'Nevada', countryCode: 'US', countryName: 'United States', stateCode: 'NV' },
  { name: 'New Hampshire', countryCode: 'US', countryName: 'United States', stateCode: 'NH' },
  { name: 'New Jersey', countryCode: 'US', countryName: 'United States', stateCode: 'NJ' },
  { name: 'New Mexico', countryCode: 'US', countryName: 'United States', stateCode: 'NM' },
  { name: 'New York', countryCode: 'US', countryName: 'United States', stateCode: 'NY' },
  { name: 'North Carolina', countryCode: 'US', countryName: 'United States', stateCode: 'NC' },
  { name: 'North Dakota', countryCode: 'US', countryName: 'United States', stateCode: 'ND' },
  { name: 'Ohio', countryCode: 'US', countryName: 'United States', stateCode: 'OH' },
  { name: 'Oklahoma', countryCode: 'US', countryName: 'United States', stateCode: 'OK' },
  { name: 'Oregon', countryCode: 'US', countryName: 'United States', stateCode: 'OR' },
  { name: 'Pennsylvania', countryCode: 'US', countryName: 'United States', stateCode: 'PA' },
  { name: 'Rhode Island', countryCode: 'US', countryName: 'United States', stateCode: 'RI' },
  { name: 'South Carolina', countryCode: 'US', countryName: 'United States', stateCode: 'SC' },
  { name: 'South Dakota', countryCode: 'US', countryName: 'United States', stateCode: 'SD' },
  { name: 'Tennessee', countryCode: 'US', countryName: 'United States', stateCode: 'TN' },
  { name: 'Texas', countryCode: 'US', countryName: 'United States', stateCode: 'TX' },
  { name: 'Utah', countryCode: 'US', countryName: 'United States', stateCode: 'UT' },
  { name: 'Vermont', countryCode: 'US', countryName: 'United States', stateCode: 'VT' },
  { name: 'Virginia', countryCode: 'US', countryName: 'United States', stateCode: 'VA' },
  { name: 'Washington', countryCode: 'US', countryName: 'United States', stateCode: 'WA' },
  { name: 'West Virginia', countryCode: 'US', countryName: 'United States', stateCode: 'WV' },
  { name: 'Wisconsin', countryCode: 'US', countryName: 'United States', stateCode: 'WI' },
  { name: 'Wyoming', countryCode: 'US', countryName: 'United States', stateCode: 'WY' },
];
export default statesDB;
