.catalog_retail_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dataTable {
  .action {
    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 0px -2px 4px 0px #00000026;
    .btnWrapper {
      width: 200px;
    }
    span {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    .action {
      position: fixed;
      bottom: var(--app-layout-padding-bottom);
    }
  }

  @media screen and (min-width: 769px) {
  }
}
