/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import type { CategoryForRetailListItemResponse, UpdateCategoryForRetailRequestBody } from '@/services/jennifer';

import { DEFAULT_OPTIONS } from './def';

type CatalogRetailType = {
  isGuideModalVisible: boolean;

  isEditPriceModalVisible: boolean;

  isEditStateModalVisible: boolean;

  isConfirmPriceModalVisible: boolean;

  isConfirmStateModalVisible: boolean;

  selectedProduct: CategoryForRetailListItemResponse[];

  calculateDep: typeof DEFAULT_OPTIONS[number]['value'];

  calculatePercent: number;

  submitDraft: UpdateCategoryForRetailRequestBody | null;
};

const DEFAULT_STORE_DATA = {
  isGuideModalVisible: false,

  isEditPriceModalVisible: false,
  isEditStateModalVisible: false,

  isConfirmPriceModalVisible: false,
  isConfirmStateModalVisible: false,

  selectedProduct: [],

  calculateDep: DEFAULT_OPTIONS[0].value,
  calculatePercent: 100,

  submitDraft: null,
};

export const useCatalogRetailStore = create(immer<CatalogRetailType>(() => DEFAULT_STORE_DATA));

// guide modal
export const showCatalogRetailGuide = () => useCatalogRetailStore.setState({ isGuideModalVisible: true });
export const hideCatalogRetailGuide = () => useCatalogRetailStore.setState({ isGuideModalVisible: false });

// edit price modal
export const showEditPriceModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isEditPriceModalVisible = true;
  });
export const hideEditPriceModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isEditPriceModalVisible = false;
  });

// edit state modal
export const showEditStateModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isEditStateModalVisible = true;
  });
export const hideEditStateModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isEditStateModalVisible = false;
  });

// confirm price
export const showConfirmPriceModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isConfirmPriceModalVisible = true;
  });
export const hideConfirmPriceModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isConfirmPriceModalVisible = false;
  });

// confirm state
export const showConfirmStateModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isConfirmStateModalVisible = true;
  });
export const hideConfirmStateModal = () =>
  useCatalogRetailStore.setState((state) => {
    state.isConfirmStateModalVisible = false;
  });

// update selected
export const setSelectedProducts = (params: CategoryForRetailListItemResponse[]) => {
  useCatalogRetailStore.setState((state) => {
    state.selectedProduct = params;
  });
};

export const setCalculateDep = (dep: {
  target: CatalogRetailType['calculateDep'];
  val: CatalogRetailType['calculatePercent'];
}) => {
  useCatalogRetailStore.setState((state) => {
    state.calculateDep = dep.target;
    state.calculatePercent = dep.val;
  });
};

export const setCatalogProductsDraft = (params: CatalogRetailType['submitDraft']) => {
  useCatalogRetailStore.setState((state) => {
    state.submitDraft = params;
  });
};

export const handleInit = () => {
  useCatalogRetailStore.setState(DEFAULT_STORE_DATA);
};
