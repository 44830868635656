import { Text } from '@shopify/polaris';
import _ from 'lodash';

const OmittedName = ({ name, last4 }: { name: string; last4: string }) => {
  if (!name || !last4) return null;

  return (
    <Text variant="bodyMd" as="p">
      {_.upperFirst(name)} <span style={{ verticalAlign: 'middle', fontWeight: 500 }}>••••</span> {last4}
    </Text>
  );
};

export default OmittedName;
