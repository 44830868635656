import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AutoLink from '@/components/AutoLink';
import Image from '@/components/Image';
import { useDesktop } from '@/hooks';
import useSettings from '@/hooks/queries/useSettings';

import css from './index.module.scss';

export function TopicList(): JSX.Element {
  const isDesktop = useDesktop();
  const settings = useSettings();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const layoutRef = useRef(null);
  const topicWidthRef = useRef(isDesktop ? 144 : 70);
  const data = useMemo(
    () => settings?.data?.data?.['home-carousel']?.data?.[isDesktop ? 'opmod_1_pc' : 'opmod_1_mobile'],
    [isDesktop, settings?.data?.data]
  );

  const updateReach = useCallback(({ isBeginning: curIsBeginning, isEnd: curIsEnd }) => {
    setIsBeginning(curIsBeginning);
    setIsEnd(curIsEnd);
  }, []);

  // 让topic始终展示1个半
  useEffect(() => {
    if (layoutRef.current) {
      if (!isDesktop) {
        topicWidthRef.current = parseInt(`${Math.floor(layoutRef.current.offsetWidth / 5)}`, 10);
      }
    }
  }, [isDesktop]);

  if (_.isEmpty(data)) return null;

  return (
    <div className={css.ns_com_topic_list_main} ref={layoutRef}>
      <Swiper
        className={cn(css.ns_or_swiper, { [css.is_beginning]: isBeginning }, { [css.is_end]: isEnd })}
        freeMode
        navigation
        slidesPerView="auto"
        spaceBetween={12}
        breakpoints={{
          769: { spaceBetween: 32 },
        }}
        modules={[FreeMode, Navigation]}
        onReachBeginning={updateReach}
        onReachEnd={updateReach}
      >
        {data?.map((topic: any) => (
          <SwiperSlide key={topic?.title}>
            <div className={css.topic} style={{ width: topicWidthRef.current }}>
              <AutoLink targetType={topic?.target_type} targetData={topic?.target_data}>
                <Image className={css.topic_image} src={topic?.image_url} fit="cover" />
                <div className={css.topic_title}>{topic?.title}</div>
              </AutoLink>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default TopicList;
