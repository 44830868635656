$prefixCls: store-connect-result-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__dialog {
    .modal__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__to_login_btn {
    text-align: center;
    text-decoration: underline;
    padding-top: var(--spacing-l);
    font-size: 14px;
    cursor: pointer;
  }

  &__result {
    color: var(--color-black);
    text-align: center;

    &-image {
      font-size: 60px;

      i {
        font-style: normal;
        font-size: 80px;
        line-height: 1;
      }
    }

    &-caption {
      margin: var(--spacing-xxl) auto;
      color: var(--color-black);
      font-size: 18px;
      line-height: 1.2;
      font-weight: 500;
      text-align: center;
    }

    &-description {
      margin: var(--spacing-xxl) auto;
      color: var(--color-black);
      font-size: var(--font-size2);
      line-height: 1.3;
      text-align: center;
      &.error-message {
        color: var(--color-gray4);
        margin: 0 auto;
      }

      a:not(:hover) {
        color: var(--color-black);
        text-decoration: underline;
      }
    }
  }
}
