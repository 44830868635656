import moment from 'moment';
import { useMemo, useState } from 'react';
import { useInterval } from 'react-use';

import useTimeStatus, { TimeStatus } from '@/hooks/biz/useTimeStatus';

const useCountDown = ({ beginsAt, endsAt, delay }: { beginsAt: string; endsAt: string; delay?: number }) => {
  const delayOrigin = delay || 1000;
  const { status, update } = useTimeStatus(beginsAt, endsAt);
  const countDownInit = useMemo(() => {
    const startT = moment(beginsAt);
    const endT = moment(endsAt);
    const currentT = moment();
    if (status === TimeStatus.Coming) {
      return startT.diff(currentT);
    }
    if (status === TimeStatus.Started) {
      return endT.diff(currentT);
    }
    return 0;
  }, [beginsAt, endsAt, status]);
  const [countDown, setCountDown] = useState(countDownInit);

  useInterval(
    () => {
      const isEnd = countDown - delayOrigin <= 0;

      if (isEnd) {
        // 当前countdown结束，更新状态，进入下一countdown
        update();
      }
      setCountDown((prev) => (isEnd ? 0 : prev - delayOrigin));
    },
    countDown === 0 ? null : delayOrigin
  );

  return countDown;
};

export default useCountDown;
