import Button from '@/components/Button';

import css from './index.module.scss';

function Empty(): JSX.Element {
  return (
    <div className={css.ns_cart_empty_comp}>
      <div className={css.title}>Your cart is empty</div>
      <Button color="primary" shape="rounded" link="/">
        Start Browsing
      </Button>
    </div>
  );
}

export default Empty;
