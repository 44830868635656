.ns_com_payment_methods_dialog_edit_view_main {
  .payment_method_form {
    color: var(--color-black);
    font-size: 16px;
    line-height: 1.4;

    .set_as_default_wrapper {
      margin-top: var(--spacing-xxl);

      .set_as_default_description {
        margin-top: var(--spacing-xs);
        color: var(--color-gray4);
        font-size: var(--font-size2);
        line-height: 1.3;
      }

      :global {
        .checkbox {
          font-weight: 500;
        }
        .checkbox.adm-checkbox-checked svg {
          color: var(--color-primary);
        }
      }
    }
  }
}
