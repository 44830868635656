.ns_com_boost_main {
  background: #ffcc17;
  padding: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ratio {
    font-weight: 500;
    margin-right: 8px;
  }

  @media screen and (max-width: 768px) {
  }

  @at-root .is_m {
    .ns_com_boost_main {
      border-radius: 0;
    }
  }

  @at-root .is_pc {
    .ns_com_boost_main {
      border-radius: 8px;
    }
  }
}
