import 'swiper/css/bundle';
import 'antd/dist/antd.variable.min.css';
import '@fontsource/jost/index.css';
import '@fontsource/jost/variable.css';
import '@fontsource/jost/variable-italic.css';
import '@shopify/polaris/build/esm/styles.css';
import './index.scss';

import NiceModal from '@ebay/nice-modal-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AppProvider, Button, LegacyCard, Page } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ConfigProvider as ADConfigProvider, message } from 'antd';
import adEnUS from 'antd/lib/locale/en_US';
import { ConfigProvider as ADMConfigProvider } from 'antd-mobile';
import admEnUS from 'antd-mobile/es/locales/en-US';
import moment from 'moment';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { IconContext } from 'react-icons';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '@/hooks/auth';

import App from './App';
import envconfig from './constants/envconfig';

moment.locale('en');

ADConfigProvider.config({
  theme: {
    primaryColor: '#cee46a',
    successColor: '#a6cf03',
    errorColor: '#ff5050',
    warningColor: '#ffd80b',
  },
});

message.config({ duration: 3 });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  dsn: 'https://4c27e7ee73a94ccb9391eafec31b0356@o1208560.ingest.sentry.io/6357862',
  integrations: [new BrowserTracing()],
  environment: envconfig.SENTRY_ENVIRONMENT,
  // debug: envconfig.SENTRY_ENVIRONMENT === 'test',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: envconfig.SENTRY_TRACES_SAMPLE_RATE,
});

render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ADConfigProvider locale={adEnUS}>
            <ADMConfigProvider locale={admEnUS}>
              <IconContext.Provider value={{ className: 'react-icons' }}>
                <AppProvider i18n={enTranslations}>
                  <NiceModal.Provider>
                    <App />
                  </NiceModal.Provider>
                </AppProvider>
              </IconContext.Provider>
            </ADMConfigProvider>
          </ADConfigProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
