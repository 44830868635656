import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import ImportedProductList from '@/components/ImportedProductList';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusGroup from '@/components/StatusGroup';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useStoreImportedProducts from '@/hooks/queries/useStoreImportedProducts';

import styles from './styles.scss';

const { prefixCls } = styles;

const queryParams = {
  page_size: 30,
};

const STATUS_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Discontinued', value: 'discontinued' },
  { label: 'Sold Out', value: 'out_of_stock' },
];

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function ImportedProducts({ className, style }: Props): JSX.Element {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status') ?? '';
  const [activeStatus, setActiveStatus] = useState(status);
  const products = useStoreImportedProducts(params?.shopId, {
    ...queryParams,
    status: activeStatus,
  });
  const productsData = useMemo(
    () => _.compact(products?.data?.pages?.flatMap((page) => page?.data?.products)),
    [products?.data]
  );

  useEffect(() => {
    const newSearchParams = _.omitBy(
      {
        ...Object.fromEntries(searchParams),
        status: activeStatus,
      },
      _.isEmpty
    );
    setSearchParams(newSearchParams, { replace: true });
  }, [activeStatus, searchParams, setSearchParams]);

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Imported Products</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Imported Products</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Imported Products</h1>

        <StoreSelect className={`${prefixCls}__store-select`} redirectPath="/imported-products/:shopId" />
        <div className="status_group_wrapper">
          <StatusGroup options={STATUS_OPTIONS} active={activeStatus} onChange={(val) => setActiveStatus(val)} />
        </div>
        <StatusViewRenderer
          data={productsData}
          error={products.error}
          emptyContent={<Empty />}
          isLoading={products.isLoading}
          hasMore={products.hasNextPage}
          loadMore={products.fetchNextPage}
          statusStyle={{ marginTop: 64 }}
        >
          <ImportedProductList className={`${prefixCls}__product-list`} data={productsData} />
        </StatusViewRenderer>
      </div>
    </div>
  );
}

function Empty(): JSX.Element {
  return (
    <div className={`${prefixCls}__empty`}>
      <p>
        No Products Found
        <br />
        <br />
        To Import products to your store, find a product you like and hit “import”
      </p>
      <Button color="primary" shape="rounded" link="/">
        Start Browsing
      </Button>
    </div>
  );
}

export default ImportedProducts;
