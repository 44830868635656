.ns_com_infinite_scroll_main {
  .content {
    width: 100%;
    text-align: center;

    :global {
      .adm-divider {
        border-color: var(--color-gray2);
      }
    }
  }
}
