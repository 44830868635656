.ns_com_out_of_stock_tag_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xxs) var(--spacing-xs);
  color: var(--color-white);
  font-size: var(--font-size1);
  line-height: 1.4;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

  i {
    margin-right: var(--spacing-xs);
    padding: 0;
    color: var(--color-danger);
    line-height: 0;
  }
}
