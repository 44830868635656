.ns_modal_print_live_helper_full_size {
  .bold_700 {
    font-weight: 700;
  }

  .section_wrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin: 0 auto;
    padding: 36px;

    section {
      padding: 24px 0;
      border-bottom: 1px solid #c7c7c7;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      &:last-child {
        border: none;
      }

      &.section_1 {
        .title {
          word-break: break-word;
        }
        span {
          font-size: 24px;
          line-height: 130%;
        }
        img {
          height: 30px;
        }
      }
      &.section_2 {
        span {
          font-size: 20px;
          line-height: 130%;
        }
      }
      &.section_3 {
        img {
          width: 262px;
          height: 350px;
        }
      }
      &.section_4 {
        gap: 2px;
      }
    }
  }

  &.demo {
    .section_wrapper {
      height: 474px;
      width: 412px;
      padding: 10px 18px;
      section {
        gap: 8px;
        padding: 8px 0;

        &.section_1 {
          span {
            font-size: 12px;
            line-height: 130%;
          }
          img {
            height: 15px;
          }
        }

        &.section_2 {
          span {
            font-size: 10px;
            line-height: 130%;
          }
        }
        &.section_3 {
          img {
            width: 122px;
            height: 164px;
          }
        }
        &.section_4 {
          gap: 0;
        }
      }
    }
  }
}
