@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin col {
  display: flex;
  flex-direction: column;
}

@mixin center {
  justify-content: center;
  align-items: center;
}

.sellingPriceRules {
  .sellingRow {
    @include row;
  }
  .sellingCol {
    @include col;
  }
  .header {
    .title {
      font-size: 26px;
      line-height: 1.4;
      margin-bottom: 25px;
    }
  }
  .form {
    @include col;
    gap: 15px;
    .numInputWrapper {
      width: 140px;
    }
    .inputs {
      gap: 15px;
      align-items: center;
    }
    .inputWrapper {
      margin: 0;
    }
  }
  .sectionBox {
    @include col;
    background-color: #fff;
    .header {
      padding: 10px;
      span {
        font-size: 16px;
        margin-right: 5px;
      }
    }
    .divider {
      margin: 0;
    }
    .sectionBox_content {
      padding: 10px;
    }
    .tips {
      font-size: 14px;
      color: #999999;
    }
  }
  .btnWrapper {
    margin-top: 160px;
  }

  @media screen and (max-width: 768px) {
    .header {
      display: none;
    }

    .btnWrapper {
      position: fixed;
      width: 100%;
      bottom: 80px;
      padding: 0 20px;
    }
    .sectionBox {
      padding: 10px 0;
      gap: 10px;
      .header {
        padding: 0px 16px;
        display: block;
      }
      .sectionBox_content {
        padding: 6px 16px;
        gap: 0;
      }
    }

    .app-layout {
      &__nav-bar {
        display: none;
      }
    }
  }

  @media screen and (min-width: 769px) {
  }
}
