import { NavBar } from 'antd-mobile';
import type { NavBarProps } from 'antd-mobile/es/components/nav-bar';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import css from './index.module.scss';

export interface Props extends NavBarProps {
  backUrl?: string;
  backReplace?: boolean;
  children: React.ReactNode;
}

function SecondaryNavBar({ className, backUrl, backReplace = true, children, ...restProps }: Props): JSX.Element {
  const navigate = useNavigate();

  const handleBack = () => {
    if (backUrl) {
      navigate(backUrl, { replace: backReplace });
    } else {
      window.history.length > 1 ? navigate(-1) : navigate('/', { replace: backReplace });
    }
  };

  return (
    <NavBar className={cn(css.ns_com_secondary_nav_bar_main, className)} onBack={handleBack} {...restProps}>
      {children}
    </NavBar>
  );
}

export default SecondaryNavBar;
