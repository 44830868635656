import { Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useState } from 'react';

import Button from '@/components/Button';
import Modal, { Props as ModalProps } from '@/components/Modal';
import { useAuth } from '@/hooks/auth';
import useAccountEmailSendCode from '@/hooks/queries/useAccountEmailSendCode';

import css from './index.module.scss';

export interface EmailUpdateDialogProps extends Omit<ModalProps, 'children'> {
  onOk?: (email: string) => void;
}

export function EmailUpdateDialog({ className, onOk, ...restProps }: EmailUpdateDialogProps): JSX.Element {
  const { user } = useAuth();
  const updateEmail = useAccountEmailSendCode();
  const [email, setEmail] = useState(user?.email || user?.email_pending || '');

  const handleSave = () => {
    if (_.isEmpty(email) || updateEmail.isLoading) return;

    updateEmail.mutate(
      { email, type: 'link' },
      {
        onSettled: () => {
          // ignore update email error
          onOk?.(email);
        },
      }
    );
  };

  return (
    <Modal
      className={cn(css.ns_com_email_update_dialog_main, className)}
      title="Please enter your email"
      footer={
        <Button
          block
          color="primary"
          shape="rounded"
          disabled={!isValidEmail(email)}
          loading={updateEmail?.isLoading}
          loadingText="Saving"
          onClick={handleSave}
        >
          Save
        </Button>
      }
      {...restProps}
    >
      <div className={css.content}>
        <div className={css.form}>
          <div className={css.form_item}>
            <div className={css.input_group}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="ns_id_email_update_dialog__email">Your Email</label>
              <Input
                id="ns_id_email_update_dialog__email"
                className={css.form_input}
                size="large"
                autoFocus
                placeholder="name@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onPressEnter={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function isValidEmail(email = ''): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (_.isEmpty(_.trim(email))) return false;
  return re.test(String(email).toLowerCase());
}

export default EmailUpdateDialog;
