enum SOURCE_ID {
  Recent = 'recent',
  Popular = 'popular',
  RecentlyViewed = 'recentlyViewed',
  QuerySuggestionsPlugin = 'querySuggestionsPlugin',
  RecentSearchesPlugin = 'recentSearchesPlugin',
}

type RecentItem = {
  query: string;
  objectID: string;
};

type SuggestedItem = {
  query: string;
  objectID: string;
};

export { RecentItem, SOURCE_ID, SuggestedItem };
