import lighting from '@/assets/images/lighting.svg';

import useDuration from '../CountDown/useDuration';
import css from './CountDownTag.module.scss';

const CountDownTag = ({ countDown }: { countDown: number }) => {
  const [days, hours, min, sec] = useDuration(countDown);
  let title = '';

  if (countDown > 0) {
    if (days) {
      title = `${days} day${days > 1 ? 's' : ''} left`;
    } else if (hours) {
      title = `${hours} hr${hours > 1 ? 's' : ''} left`;
    } else if (sec) {
      title = `1 hr left`;
    }
  }

  if (!title) return null;

  return (
    <div className={css.ns_com_count_down_tag_wrapper}>
      <span>
        <img src={lighting} alt="" />
        {` `}
        {title}
      </span>
    </div>
  );
};

export default CountDownTag;
