.ns_com_stepper_main {
  --height: 24px;
  --border: 1px solid #c4c4c4;
  --border-inner: 1px solid #c7c7c7;
  --border-radius: 4px;
  --input-font-size: var(--font-size2);
  --input-background-color: var(--color-white);
  --button-font-size: var(--font-size2);
  --button-text-color: var(--color-black);
  --button-background-color: var(--color-white);
  :global {
    .adm-stepper-input .adm-input-element {
      font-weight: 500;
    }
  }
}
