import { Frame, Page } from '@shopify/polaris';
import { Button, Divider, Form, Input, message, Popover, Select } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';

import { DEFAULT_OPTIONS, formField } from './def';
import styles from './index.local.scss';
import { useSellingPrice } from './useSellingPrice';

const subTitle =
  'This rule sets a default selling price on Kiwi when you set Shopify product to be active on Kiwi.  You can always change the price for particular products in "Manage Listing"';
const tips = {
  [DEFAULT_OPTIONS[0].value]:
    'if "cost per item" on your Shopify is $10, and you set 90%, the selling price you offer on kiwi will be $9; if you set 200%, it will be $20',
  [DEFAULT_OPTIONS[1].value]:
    'if "Price" on your Shopify is $30, and you set 60%, the selling price you offer on kiwi will be $18',
};

function SellingPriceRules(): JSX.Element {
  const params = useParams();
  const [form] = Form.useForm();
  const { config, updateConfig, refetch } = useSellingPrice({ id: params?.shopId });

  const [selected, setSelected] = useState<typeof DEFAULT_OPTIONS[number]['value']>('');

  const handleFinish = async (values) => {
    try {
      await updateConfig.mutateAsync({
        export_price_rule: {
          val: Number(values.val / 100),
          target: values.target,
        },
      });
      await refetch();
    } catch (error) {
      message.error(error?.message || 'Unknown Error');
    }
  };

  return (
    <Frame>
      <Page>
        <div>
          <Helmet>
            <html lang="en" className={styles.sellingPriceRules} />
            <title>Selling Price Rules</title>
          </Helmet>

          <SecondaryNavBar backReplace>Selling Price Rules</SecondaryNavBar>

          <div>
            <header className={styles.header}>
              <h1 className={styles.title}>Selling Price Rules</h1>
            </header>

            <div className={styles.rule_container}>
              <StatusViewRenderer isEmpty={false} isLoading={config.isLoading} statusStyle={{ marginTop: 64 }}>
                <Form
                  form={form}
                  name="priceRulesForm"
                  size="large"
                  preserve
                  initialValues={config}
                  onFinish={handleFinish}
                >
                  <div className={styles.form}>
                    <section className={styles.sectionBox}>
                      <header className={styles.header}>
                        <span>Pricing Rules for Kiwi Price</span>
                        <Popover
                          content="How to calculate: Shopify price * 0%  = Kiwi Price (shown on Kiwi)"
                          color="white"
                          placement="bottom"
                        >
                          <FiAlertCircle />
                        </Popover>
                        <p
                          style={{
                            fontSize: '14',
                            color: '#999',
                          }}
                        >
                          {subTitle}
                        </p>
                      </header>

                      <Divider className={styles.divider} />

                      <div className={styles.sectionBox_content}>
                        <div className={cn(styles.sellingRow, styles.inputs)}>
                          <Form.Item
                            rules={[{ required: true, message: '' }]}
                            name={formField.options}
                            className={styles.inputWrapper}
                          >
                            <Select
                              size="middle"
                              style={{ width: 140 }}
                              options={DEFAULT_OPTIONS}
                              onChange={setSelected}
                            />
                          </Form.Item>
                          <span>x</span>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                pattern: /^(-?\d+)(\.\d+)?$/g,
                                message: '',
                              },
                            ]}
                            name={formField.percent}
                            className={styles.inputWrapper}
                          >
                            <Input size="middle" suffix="%" />
                          </Form.Item>
                        </div>
                        <p className={styles.tips}>{tips[selected || config.target]}</p>
                      </div>
                    </section>

                    <section className={styles.sectionBox}>
                      <header className={styles.header}>
                        <span>Shipping Fee</span>
                      </header>
                      <Divider className={styles.divider} />

                      <div className={styles.sectionBox_content}>
                        <Form.Item className={styles.inputWrapper}>
                          <Input
                            disabled
                            bordered={false}
                            size="middle"
                            placeholder={`There will be a flat rate: $${config.shippingFee} per order`}
                          />
                        </Form.Item>
                      </div>
                    </section>
                  </div>

                  <Form.Item
                    wrapperCol={{
                      md: { offset: 9, span: 6 },
                    }}
                  >
                    <div className={styles.btnWrapper}>
                      <Button loading={updateConfig.isLoading} block type="primary" htmlType="submit" shape="round">
                        Save
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </StatusViewRenderer>
            </div>
          </div>
        </div>
      </Page>
    </Frame>
  );
}

export default SellingPriceRules;
