import _ from 'lodash';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuth } from '@/hooks/auth';

const BeforeLogined = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const isLogined = !_.isEmpty(user);
    if (isLogined) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <Outlet />;
};

export default BeforeLogined;
