import cn from 'classnames';
import Helmet from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Welcome({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');

  const handleSkipAuth = () => {
    // keep skip auth until close browser
    sessionStorage.setItem('skipAuth', 'true');
    navigate('/');
  };

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Welcome</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__message-card`}>
          <h1 className={`${prefixCls}__message-card-title`}>Welcome to Kiwidrop!</h1>
          <p className={`${prefixCls}__message-card-description`}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl mi egestas lectus tortor vehicula vitae
            commodo sagittis faucibus. Sed massa scelerisque quam urna suscipit nunc vulputate tempus libero.
          </p>
          <Button color="main" shape="rounded" link={`/login${from ? `?redirect=${encodeURIComponent(from)}` : ''}`}>
            Continue with Phone Number
          </Button>
        </div>
        <Button fill="none" onClick={handleSkipAuth}>
          Browse KiwiDrop
        </Button>
      </div>
    </div>
  );
}

export default Welcome;
