import { useMutation, useQueryClient } from 'react-query';

import { updateProductSellerCollection } from '@/services/ds';

type Params = {
  product_id: string;
  add_collection_ids?: string[];
  remove_collection_ids?: string[];
};

export default function useUpdateProductSellerCollection() {
  const queryClient = useQueryClient();

  return useMutation((params?: Params) => updateProductSellerCollection(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('sellerCollections');
    },
  });
}
