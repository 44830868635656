const emitPaymentMethodChangeEvent = (paymentMethod: any) => {
  document.dispatchEvent(
    new CustomEvent('paymentMethods:change', {
      detail: {
        paymentMethod,
      },
    })
  );
};

const reorder = (list: Iterable<any> | ArrayLike<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export { emitPaymentMethodChangeEvent, reorder };
