.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #f2f2f6;
  .title {
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
  }
}
