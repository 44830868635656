$prefixCls: email-bind-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app {
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(206, 228, 106, 0.5) 166%);
    }
  }

  &__content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr;
    place-items: center;
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  &__title {
    margin: 0;
    color: var(--color-black);
    font-weight: 500;
    font-size: 30px;
    line-height: 1.15;
    text-align: center;
  }

  &__input {
    &-wrapper {
      position: relative;
      width: 330px;
      height: 50px;
    }

    &#{&} {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 330px;
      height: 50px;
      color: var(--color-black);
      font-size: var(--font-size5);
      line-height: 1.15;
      text-align: center;
      border: 3px solid var(--color-gray5) !important;
      border-right-width: 3px !important;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
      z-index: 0;
    }

    &-email {
      &#{&} {
        display: block;
        z-index: 1;

        #{$root}--email-view & {
          display: block;
          z-index: 2;
        }
      }
    }

    &-code {
      &#{&} {
        letter-spacing: 0.4em;

        #{$root}--code-view & {
          display: block;
          z-index: 2;
        }
      }
    }

    #{$root}--has-error & {
      border-color: var(--color-danger) !important;
    }
  }

  &__message {
    margin: var(--spacing-l) auto;
    min-height: 22px;
    color: var(--color-black);
    font-size: var(--font-size3);
    line-height: 1.4;
    text-align: center;
  }

  &__error-message {
    margin: var(--spacing-l) auto;
    min-height: 20px;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.4;
    text-align: center;

    #{$root}--has-error & {
      color: var(--color-danger);
    }
  }

  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;

    & > * + * {
      margin-left: var(--spacing-xxl);
    }
  }
}
