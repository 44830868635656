import React, { memo } from 'react';
import { withNativeProps } from '../../utils/native-props';
export const SpinIcon = memo(props => {
  return withNativeProps(props, React.createElement("svg", {
    width: '28px',
    height: '28px',
    viewBox: '0 0 28 28'
  }, React.createElement("g", {
    stroke: 'none',
    strokeWidth: '1',
    fill: 'none',
    fillRule: 'evenodd'
  }, React.createElement("g", {
    transform: 'translate(-137.000000, -840.000000)',
    fill: '#1576FE'
  }, React.createElement("g", {
    transform: 'translate(80.000000, 823.000000)'
  }, React.createElement("g", {
    transform: 'translate(53.000000, 13.000000)'
  }, React.createElement("path", {
    d: 'M17.9996753,31.5 C10.5556724,31.5 4.5,25.4443275 4.5,18.0003247 C4.5,10.5563219 10.5556724,4.5 17.9996753,4.5 C18.5355492,4.5 18.9702974,4.93474816 18.9702974,5.47062208 C18.9702974,6.006496 18.5355492,6.44124416 17.9996753,6.44124416 C11.6261524,6.44124416 6.44124416,11.6267709 6.44124416,18.0002938 C6.44124416,24.3738167 11.6261524,29.5587249 17.9996753,29.5587249 C24.3731982,29.5587249 29.5587249,24.3738167 29.5587249,18.0002938 C29.5587249,14.7964616 28.2778291,11.8169616 25.9523687,9.61220279 C25.5637302,9.24317094 25.5473089,8.62893223 25.9157222,8.23967523 C26.2841356,7.84976878 26.8989928,7.83461537 27.2882498,8.20302872 C30.0042351,10.7787368 31.5,14.2580826 31.5,18.0002938 C31.5,25.4443275 25.4436781,31.5 17.9996753,31.5 Z'
  })))))));
});