import { Checkbox, Form, message, Tooltip } from 'antd';
import { Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useStorePriceRules from '@/hooks/queries/useStorePriceRules';
import useUpdateStorePriceRules from '@/hooks/queries/useUpdateStorePriceRules';
import PriceRuleArgAndOp from '@/pages/PriceRules/components/PriceRuleArgAndOp';
import { getDefaultSellPrice } from '@/pages/PriceRules/price';

import InputNumber from '../components/InputNumber';
import PriceRuleCharacter from '../components/PriceRuleCharacter';
import Prompt from '../components/Prompt';
import { DEFAULT_PRICE_ARG_MAP, DEFAULT_PRICE_RULES, DEMO_ALL_IN_COST, OPTIONS } from '../constant';
import PriceRuleType from '../types';
import css from './index.module.scss';

const normalizeBooleanToNumber = (value: boolean) => Number(value);

function PriceRules(): JSX.Element {
  const params = useParams();
  const [form] = Form.useForm();
  const priceRules = useStorePriceRules(params?.shopId);
  const updatePriceRules = useUpdateStorePriceRules(params?.shopId);
  const priceRulesData = useMemo(() => {
    const data = _.merge({}, DEFAULT_PRICE_RULES, priceRules?.data?.data?.price_rule);
    return {
      ...data,
      price_arg_and_op: {
        price_op: data.price_op,
        price_arg: data.price_arg,
      },
      compare_at_price_arg_and_op: {
        price_op: data.compare_at_price_op,
        price_arg: data.compare_at_price_arg,
      },
      price_assign_cents_arg: `${data.price_assign_cents_arg || ''}`.padStart(2, '0'),
      compare_at_price_assign_cents_arg: `${data.compare_at_price_assign_cents_arg || ''}`.padStart(2, '0'),
    };
  }, [priceRules?.data]);
  const [curPriceOp, setCurPriceOp] = useState(priceRulesData.price_arg_and_op.price_op);
  const [curCompareAtPriceOp, setCurCompareAtPriceOp] = useState(priceRulesData.compare_at_price_arg_and_op.price_op);
  const [priceEnabled, setPriceEnabled] = useState(priceRulesData.price_enabled);
  const [compareAtPriceEnabled, setCompareAtPriceEnabled] = useState(priceRulesData.compare_at_price_enabled);
  const [priceAssignCentsEnabled, setPriceAssignCentsEnabled] = useState(priceRulesData.price_assign_cents_enabled);
  const [compareAtPriceAssignCentsEnabled, setCompareAtPriceAssignCentsEnabled] = useState(
    priceRulesData.compare_at_price_assign_cents_enabled
  );
  const [defaultSellPrice, setDefaultSellPrice] = useState(0);
  const [defaultCompareAtSellPrice, setDefaultCompareAtSellPrice] = useState(0);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const formRef = useRef({});

  const priceSetting = {
    priceEnabled: {
      name: 'price_enabled',
    },
    priceArgAndOp: {
      name: 'price_arg_and_op',
      disabled: !priceEnabled,
    },
    priceAssignCentsEnabled: {
      name: 'price_assign_cents_enabled',
      disabled: !priceEnabled,
    },
    priceAssignCentsArg: {
      name: 'price_assign_cents_arg',
      disabled: priceEnabled ? !priceAssignCentsEnabled : !priceEnabled,
    },
    params: {
      sellPrice: defaultSellPrice,
      curPriceOp,
      title: 'Sell at Price',
      titleHint: `‘Sell at Price’ corresponds to the Price value on Shopify, your customers will pay.`,
      assignCentsHint: `This will modify the cents in your final Sell at Price.`,
    },
  };

  const compareArPriceSetting = {
    priceEnabled: {
      name: 'compare_at_price_enabled',
    },
    priceArgAndOp: {
      name: 'compare_at_price_arg_and_op',
      disabled: !compareAtPriceEnabled,
    },
    priceAssignCentsEnabled: {
      name: 'compare_at_price_assign_cents_enabled',
      disabled: !compareAtPriceEnabled,
    },
    priceAssignCentsArg: {
      name: 'compare_at_price_assign_cents_arg',
      disabled: compareAtPriceEnabled ? !compareAtPriceAssignCentsEnabled : !compareAtPriceEnabled,
    },
    params: {
      sellPrice: defaultCompareAtSellPrice,
      curPriceOp: curCompareAtPriceOp,
      title: 'Compare at Price',
      titleHint: `‘Compare at Price’ is a value on Shopify which will display on your store as a price with a
      strikethrough if your ‘Sell at Price’ is lower.`,
      assignCentsHint: `This will modify the cents in your final Compare at Price.`,
    },
  };
  const settings = [priceSetting, compareArPriceSetting];

  const getFormData = () => {
    const {
      price_arg_and_op,
      price_assign_cents_arg,
      compare_at_price_arg_and_op,
      compare_at_price_assign_cents_arg,
      ...rest
    } = form.getFieldsValue();
    const values = {
      ...rest,
      price_arg: price_arg_and_op.price_arg || 0,
      price_op: price_arg_and_op.price_op,
      price_assign_cents_arg: Number(`${price_assign_cents_arg || ''}`.padEnd(2, '0')),
      compare_at_price_arg: compare_at_price_arg_and_op.price_arg || 0,
      compare_at_price_op: compare_at_price_arg_and_op.price_op,
      compare_at_price_assign_cents_arg: Number(`${compare_at_price_assign_cents_arg || ''}`.padEnd(2, '0')),
    };
    return values;
  };

  const updateDefaultSellPrice = (values?: any) => {
    if (!values) return;
    const {
      price_arg,
      price_op,
      price_enabled,
      price_assign_cents_enabled,
      price_assign_cents_arg,
      compare_at_price_enabled,
      compare_at_price_arg,
      compare_at_price_op,
      compare_at_price_assign_cents_enabled,
      compare_at_price_assign_cents_arg,
    } = values;
    setDefaultSellPrice(
      getDefaultSellPrice(DEMO_ALL_IN_COST, {
        price_enabled,
        price_arg,
        price_op,
        price_assign_cents_enabled,
        price_assign_cents_arg,
      })
    );
    setDefaultCompareAtSellPrice(
      getDefaultSellPrice(DEMO_ALL_IN_COST, {
        price_enabled: compare_at_price_enabled,
        price_arg: compare_at_price_arg,
        price_op: compare_at_price_op,
        price_assign_cents_enabled: compare_at_price_assign_cents_enabled,
        price_assign_cents_arg: compare_at_price_assign_cents_arg,
      })
    );
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const {
      price_arg_and_op,
      compare_at_price_arg_and_op,
      price_enabled,
      compare_at_price_enabled,
      price_assign_cents_enabled,
      compare_at_price_assign_cents_enabled,
    } = allValues;

    if ('price_arg_and_op' in changedValues) {
      // price_op改变
      if (price_arg_and_op.price_op !== formRef?.current?.price_arg_and_op?.price_op) {
        form.setFieldsValue({
          price_arg_and_op: {
            price_arg: DEFAULT_PRICE_ARG_MAP[price_arg_and_op.price_op],
          },
        });
      }
    }

    if ('compare_at_price_arg_and_op' in changedValues) {
      // price_op改变
      if (compare_at_price_arg_and_op.price_op !== formRef?.current?.compare_at_price_arg_and_op?.price_op) {
        form.setFieldsValue({
          compare_at_price_arg_and_op: {
            price_arg: DEFAULT_PRICE_ARG_MAP[compare_at_price_arg_and_op.price_op],
          },
        });
      }
    }

    if ('price_enabled' in changedValues) {
      setPriceEnabled(price_enabled);
    }

    if ('compare_at_price_enabled' in changedValues) {
      setCompareAtPriceEnabled(compare_at_price_enabled);
    }

    if ('price_assign_cents_enabled' in changedValues) {
      setPriceAssignCentsEnabled(price_assign_cents_enabled);
    }

    if ('compare_at_price_assign_cents_enabled' in changedValues) {
      setCompareAtPriceAssignCentsEnabled(compare_at_price_assign_cents_enabled);
    }
    // 由于前面的几个表单项变化都会触发此操作，故直接放到当前位置
    setCurPriceOp(form.getFieldValue('price_arg_and_op').price_op);
    setCurCompareAtPriceOp(form.getFieldValue('compare_at_price_arg_and_op').price_op);
    updateDefaultSellPrice(getFormData());
    setSaveBtnDisabled(false);
    formRef.current = allValues;
  };

  const handleFinish = () => {
    updatePriceRules.mutate(getFormData(), {
      onSettled: (d, e: Error) => {
        if (e) {
          message.error(e?.message || `Sorry, we weren't able to save your changes. Please try again later.`);
          setSaveBtnDisabled(false);
        } else {
          message.success('Changes Saved!');
          setSaveBtnDisabled(true);
        }
      },
    });
  };

  const handleSave = () => {
    handleFinish();
  };

  const renderFormItem = (setting) => {
    const {
      priceEnabled: priceEnabledS,
      priceArgAndOp,
      priceAssignCentsEnabled: priceAssignCentsEnabledS,
      priceAssignCentsArg,
      params: paramsS,
    } = setting;
    const { curPriceOp: curPriceOpP, title, titleHint, assignCentsHint, sellPrice } = paramsS;
    const value = form.getFieldValue(priceArgAndOp.name);
    const curPriceArg = Number(value?.price_arg);
    const curOp = OPTIONS.find((i) => i.value === value?.price_op)?.op || 'x';
    const expressions =
      curPriceOpP === PriceRuleType.Margin
        ? `(${DEMO_ALL_IN_COST} ${curOp} (${`100% - ${curPriceArg}%`})  = ${sellPrice})`
        : `(${DEMO_ALL_IN_COST} ${curOp} ${curPriceArg}  = ${sellPrice})`;

    return (
      <div className={css.form_item_section}>
        <div className={cn(css.header, css.padding)}>
          <Form.Item name={priceEnabledS.name} valuePropName="checked" noStyle normalize={normalizeBooleanToNumber}>
            <Switch />
          </Form.Item>
          <div className={cn(css.form_item_section_title)}>{title}</div>
          <Tooltip
            overlayClassName={css.ns_or_page_price_rule_pc_popover}
            color="white"
            title={<span>{titleHint}</span>}
            placement="top"
            trigger="hover"
          >
            <div className={cn(css.hint)}>
              <FiAlertCircle />
            </div>
          </Tooltip>
        </div>
        <div className={css.body}>
          <div className={css.main}>
            <div className={css.cost_wrapper}>
              <div className={css.text}>Product cost</div>
              <span className={css.plus_or_x_wrapper}>
                <PriceRuleCharacter type={curPriceOpP} />
              </span>
              <Form.Item name={priceArgAndOp.name} noStyle>
                <PriceRuleArgAndOp disabled={priceArgAndOp.disabled} className={css.form_item_wrapper} />
              </Form.Item>
            </div>
            <div className={css.assign_cents_wrapper}>
              <Form.Item
                name={priceAssignCentsEnabledS.name}
                noStyle
                valuePropName="checked"
                normalize={normalizeBooleanToNumber}
              >
                <Checkbox disabled={priceAssignCentsEnabledS.disabled} />
              </Form.Item>
              <span className={cn(css.assign_cents_wrapper_title, css.text)}>Assign Cents</span>
              <Tooltip
                overlayClassName={css.ns_or_page_price_rule_pc_popover}
                color="white"
                title={<span>{assignCentsHint}</span>}
                placement="top"
                trigger="hover"
              >
                <div className={css.hint_wrapper}>
                  <FiAlertCircle />
                </div>
              </Tooltip>
              {!priceAssignCentsArg.disabled && (
                <>
                  <div className={css.price_demo_wrapper}>$XX.</div>
                  <Form.Item name={priceAssignCentsArg.name} noStyle>
                    <InputNumber
                      disabled={priceAssignCentsArg.disabled}
                      placeholder="99"
                      className={css.price_assign_cents_arg}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
          <div className={css.desc}>
            {priceAssignCentsArg.disabled
              ? ` If the Product cost is at ${DEMO_ALL_IN_COST}, the default Sell at Price will be set to ${sellPrice} ${expressions} at import.`
              : ` If the Product cost is at ${DEMO_ALL_IN_COST}, the default Sell at Price will be set to ${sellPrice} at import.`}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setCurPriceOp(priceRulesData.price_arg_and_op.price_op);
    setCurCompareAtPriceOp(priceRulesData.compare_at_price_arg_and_op.price_op);
    setPriceEnabled(priceRulesData.price_enabled);
    setCompareAtPriceEnabled(priceRulesData.compare_at_price_enabled);
    setPriceAssignCentsEnabled(priceRulesData.price_assign_cents_enabled);
    setCompareAtPriceAssignCentsEnabled(priceRulesData.compare_at_price_assign_cents_enabled);
    updateDefaultSellPrice(priceRulesData);
    form.setFieldsValue(priceRulesData);
  }, [form, priceRulesData]);

  return (
    <div className={css.ns_page_price_rule_pc_main}>
      <Helmet>
        <html lang="en" className={css.ns_page_price_rule_pc_main_active} />
        <title>Price Rules</title>
      </Helmet>

      <div className={css.content_wrapper}>
        <div className={css.title}>Price Rules</div>
        <StoreSelect redirectPath="/price-rules/:shopId" />
        <StatusViewRenderer isEmpty={false} isLoading={priceRules.isLoading} statusStyle={{ marginTop: 64 }}>
          <Form
            className={css.form_wrapper}
            form={form}
            name="priceRulesForm"
            size="large"
            preserve
            initialValues={priceRulesData}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
          >
            {settings.map((setting) => renderFormItem(setting))}
            <Button
              className={css.save_btn_wrapper}
              color="primary"
              shape="rounded"
              block
              loading={updatePriceRules.isLoading}
              loadingText="Saving"
              onClick={handleSave}
              disabled={saveBtnDisabled}
            >
              Save
            </Button>
          </Form>
        </StatusViewRenderer>
      </div>
      <Prompt when={!saveBtnDisabled} />
    </div>
  );
}

export default PriceRules;
