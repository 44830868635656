$prefixCls: brand-assets-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;

  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;
        background: var(--color-white);

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l);
    margin: 0 auto;
  }

  &__title {
    display: none;
    margin-bottom: var(--spacing-xxl);
    color: var(--color-black);
    font-size: 30px;
    font-weight: normal;
    line-height: 1.15;
  }

  &__aside {
    margin-top: var(--spacing-l);
  }

  &__section {
    color: var(--color-gray5);
    font-size: var(--font-size3);
    line-height: 1.4;

    & + & {
      margin-top: var(--spacing-l);
    }

    &-title {
      color: var(--color-black);
      font-weight: 500;
    }

    &-description {
      margin-top: var(--spacing-m);
    }

    &-body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px;
      margin-top: var(--spacing-m);
      background: var(--color-white);
      border: 1px solid #f5f5f5;
      border-radius: 4px;

      & > * + * {
        margin-top: var(--spacing-m);
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: var(--spacing-m);
    }
  }

  &__image-uploader {
    cursor: pointer;

    &-logo {
      img {
        width: auto;
        height: auto;
        max-width: 320px;
        max-height: 160px;
      }

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 160px;
      }
    }

    &-btn-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 28px 0;
    }

    &-description {
      margin-top: var(--spacing-m);
      color: #c7c7c7;
    }

    &-actions {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      right: var(--spacing-m);
      top: var(--spacing-m);
      margin-top: 0;

      & > * + * {
        margin-left: var(--spacing-m);
      }

      .button {
        border: none;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      }
    }
  }

  &__note-input {
    width: 100%;
    padding-bottom: 10px;
    border: 1px solid var(--color-gray4);
    border-radius: 4px;

    textarea {
      width: 100%;
      padding: 10px var(--spacing-l);
      margin-bottom: 10px;
      color: var(--color-black);
      border: none;
      resize: none;
    }

    &::after {
      margin-right: var(--spacing-l);
      color: var(--color-gray4);
      font-size: var(--font-size2);
      line-height: 1.3;
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    & > * + * {
      margin-top: var(--spacing-m);
    }

    &-logo-image {
      max-width: 320px;
      max-height: 160px;
    }

    &-note {
      color: var(--color-black);
      word-break: break-word;
      white-space: pre-line;
    }

    &-page-image {
      width: 100%;
    }
  }

  &__store-select {
    margin-bottom: var(--spacing-l);
  }

  @media screen and (max-width: 768px) {
    &__store-select {
      margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-l);
      padding: var(--spacing-l);
      background: var(--color-gray1);
    }
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      max-width: calc(950px + var(--spacing-l) * 2);
    }

    &__layout {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__title {
      display: block;
    }

    &__main {
      width: 367px;
      min-width: 367px;
    }

    &__aside {
      margin-left: 24px;
      margin-top: 0;

      #{$root}__section {
        text-align: center;

        &-description {
          display: none;
        }
      }
    }
  }
}
