.earned_credits_wrapper {
  background: linear-gradient(90deg, #dbf68b 0%, rgba(223, 245, 159, 0.476042) 40.94%, rgba(227, 244, 177, 0) 100%),
    #ffffff;
  padding: 12px;
  margin-top: var(--spacing-l);
  cursor: pointer;

  .price_wrapper {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #000000;
    }
    .price {
      font-weight: 400;
      font-size: 30px;
      line-height: 100%;
      color: #000000;
    }
  }
  .show_details_btn {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: end;

    .arrow_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #000;
      background: url(../../assets/images/arrow.svg) no-repeat center center / 10px;
      transform: rotate(90deg);
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .show_details_btn {
      justify-content: center;
    }
  }
}
