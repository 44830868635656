const shopifyImportFeaturePath = {
  supplierSales: '/supplier-sales',
  catalogForRetail: '/catalog-for-retail',
  payout: '/payout',
  sellingPriceRules: '/selling-price-rules',
  supplierOrderDetail: '/supplier-order-detail',
} as const;

export const allPaths = {
  ...shopifyImportFeaturePath,
};
