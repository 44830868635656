import { AlphaCard, AlphaStack, Box, Divider, SkeletonBodyText, SkeletonPage } from '@shopify/polaris';

const Skeleton = () => (
  <SkeletonPage>
    <AlphaStack gap="5">
      <AlphaCard roundedAbove="xl" padding="0">
        <Box padding="4">
          <SkeletonBodyText lines={1} />
        </Box>
        <Divider borderStyle="base" />
        <Box padding="4">
          <SkeletonBodyText lines={1} />
        </Box>
        <Divider borderStyle="base" />
        <Box padding="4">
          <SkeletonBodyText lines={1} />
        </Box>
        <Divider borderStyle="base" />
        <Box padding="4">
          <SkeletonBodyText lines={1} />
        </Box>
      </AlphaCard>
    </AlphaStack>
  </SkeletonPage>
);

export default Skeleton;
