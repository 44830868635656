/** h_ach: hide ach */

import {
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
} from 'antd';
import { Radio, Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { AiOutlineBank, AiOutlineCreditCard } from 'react-icons/ai';
import { FaFilter } from 'react-icons/fa';
import { FiAlertCircle, FiChevronRight, FiMoreVertical, FiSearch } from 'react-icons/fi';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import OmittedName from '@/components/biz/OmittedName';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { PaymentMethodTypeEnum } from '@/components/PaymentMethodsDialog/types';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop, useGetUpdatedSearchParamsPath } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import useUpdateUserSettings from '@/hooks/queries/useOrderCombineSettingsUpdate';
import usePaymentMethods from '@/hooks/queries/usePaymentMethods';
import useSettings from '@/hooks/queries/useSettings';
import useUserCredit from '@/hooks/queries/useUserCredit';
import useUserCreditTransactions from '@/hooks/queries/useUserCreditTransactions';
import useUserCreditTransactionsExport from '@/hooks/queries/useUserCreditTransactionsExport';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useNavHeight from '@/hooks/useNavHeight';
import { formatCredit, formatCurrency } from '@/utils/util';

import EarnedCredits from './EarnedCredits';
import styles from './styles.scss';

const { prefixCls } = styles;

const transactionTypes = {
  adjustment: 'Adjustment',
  deposit: 'Preload',
  applied_to_order: 'Order Payment',
  unapplied_from_order: 'Order Refund',
  credit_reward_retracted: 'Credit Reward Retracted',
  credit_reward_issued: 'Credit Reward Issued',
};

const pageSize = 30;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

interface DataType {
  id: string;
  created_at: string;
  metadata: any;
  type: string;
  amount: number;
  status: string;
  description: string;
}

function KiwiWallet({ className, style }: Props): JSX.Element {
  const { user } = useAuth();
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const userCredit = useUserCredit(user?.id);
  const userCreditTransactions = useUserCreditTransactions({
    page,
    page_size: pageSize,
    customer_id: user?.id,
    keyword,
    type,
  });
  const userCreditTransactionsExport = useUserCreditTransactionsExport();
  const userCreditCreditRewards = userCredit?.data?.data?.credit_reward_issued ?? 0;
  const userCreditCompleted = userCredit?.data?.data?.completed ?? 0;
  const userCreditProcessing = userCredit?.data?.data?.processing ?? 0;
  const userCreditTotal = userCreditCompleted + userCreditProcessing;

  const userCreditTransactionsData = userCreditTransactions?.data?.data?.data;
  const exportUrl = userCreditTransactionsExport?.data?.data?.download_url;
  const isDesktop = useDesktop();

  const walletHistoryData: DataType[] = userCreditTransactionsData;

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Processed',
      dataIndex: 'created_at',
      render: (v, row) => (
        <Space direction="vertical" size={2}>
          <div className="text-muted" style={{ fontSize: 12 }}>
            {moment(v).format('YYYY-MM-DD')}
          </div>
          <div>
            {transactionTypes[row?.type] ?? _.startCase(row?.type)}&ensp;{row?.metadata?.order_name}
          </div>
          <div style={{ fontSize: 14 }}>{row?.description}</div>
        </Space>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: (v, row) => {
        const isFailed = row?.status === 'failed';
        return (
          <Space direction="vertical" size={2}>
            {row?.status !== 'completed' && (
              <div className={cn(isFailed ? 'text-error' : 'text-muted')} style={{ fontSize: 12 }}>
                {_.startCase(row?.status)}
              </div>
            )}
            <div
              className={cn({ 'text-delete': isFailed, 'text-muted2': ['failed', 'processing'].includes(row?.status) })}
              style={{ fontSize: 16 }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {v > 0 ? '+' : v < 0 ? '-' : ''}
              {formatCurrency(formatCredit(Math.abs(v)))}
            </div>
          </Space>
        );
      },
    },
  ];

  const navHeight = useNavHeight();
  const historyRef = useRef(null);

  const showCreditRewards = () => {
    setType('credit_reward_issued');

    const { offsetTop } = historyRef.current;
    window.scrollTo({
      top: offsetTop - navHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Kiwi Wallet</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>Kiwi Wallet</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>Kiwi Wallet</h1>

        <StatusViewRenderer isEmpty={false} isLoading={userCredit.isLoading} statusStyle={{ marginTop: 64 }}>
          <div className={`${prefixCls}__section`}>
            <div className={`${prefixCls}__section-body`}>
              <div className={`${prefixCls}__wallet-info`}>
                <div className={`${prefixCls}__wallet-info-title`}>Total in Kiwi Wallet</div>
                <div className={`${prefixCls}__wallet-info-amount`}>
                  {formatCurrency(formatCredit(userCreditTotal))}
                </div>

                <div className={`${prefixCls}__wallet-info-items`}>
                  <div className={`${prefixCls}__wallet-info-item`}>
                    <div className={`${prefixCls}__wallet-info-item-title`}>Available Credits</div>
                    <div className={`${prefixCls}__wallet-info-item-amount`}>
                      {formatCurrency(formatCredit(userCreditCompleted))}
                    </div>
                  </div>
                  <div className={`${prefixCls}__wallet-info-item`}>
                    <div className={`${prefixCls}__wallet-info-item-title`}>
                      Processing
                      <Tooltip
                        overlayClassName={`${prefixCls}__tooltip`}
                        color="white"
                        showArrow={false}
                        title={
                          <div className="text-black">
                            These credits will be processed and become
                            <br />
                            available in your Kiwi Wallet soon.
                          </div>
                        }
                        placement="bottom"
                        trigger={['hover', 'click']}
                      >
                        <FiAlertCircle size={16} color="#999" />
                      </Tooltip>
                    </div>
                    <div className={`${prefixCls}__wallet-info-item-amount`}>
                      {formatCurrency(formatCredit(userCreditProcessing))}
                    </div>
                  </div>
                </div>
                <div className={`${prefixCls}__wallet-info-actions`}>
                  <Button block color="primary" shape="rounded" link="/deposit-funds">
                    Preload Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <EarnedCredits creditRewards={userCreditCreditRewards} showCreditRewards={showCreditRewards} />

          <AutoReloadSection />

          <div className={`${prefixCls}__section`} ref={historyRef}>
            <div className={`${prefixCls}__section-title`}>History</div>
            <div className={`${prefixCls}__section-body`}>
              <div className={`${prefixCls}__filters`}>
                <Input
                  size="large"
                  allowClear
                  enterKeyHint="search"
                  prefix={<FiSearch />}
                  placeholder="Search Transactions"
                  onPressEnter={(e) => setKeyword(e.currentTarget.value)}
                  onChange={(e) => e.nativeEvent.type === 'click' && e.target.value === '' && setKeyword('')}
                />

                <Select
                  value={type}
                  className={`${prefixCls}__select`}
                  popupClassName={`${prefixCls}__select-dropdown`}
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<FaFilter />}
                  allowClear
                  size="large"
                  placeholder=""
                  options={_.map(_.entries(transactionTypes), (item) => ({ label: item[1], value: item[0] }))}
                  onChange={(value) => setType(value)}
                />

                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item disabled={!exportUrl}>
                        <a rel="noopener noreferrer" href={exportUrl} download="export">
                          Export
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button size="large" icon={<FiMoreVertical />} />
                </Dropdown>
              </div>

              {_.isEmpty(walletHistoryData) ? (
                <div className={`${prefixCls}__empty`}>No History found</div>
              ) : (
                <Table
                  className={`${prefixCls}__table`}
                  loading={userCreditTransactions.isLoading || userCreditTransactions.isFetching}
                  rowKey="id"
                  size="middle"
                  bordered={false}
                  showHeader={false}
                  pagination={{
                    size: 'default',
                    pageSize,
                    position: [isDesktop ? 'bottomRight' : 'bottomCenter'],
                    current: page,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    total: userCreditTransactions?.data?.data?.pagination?.total,
                  }}
                  columns={columns}
                  dataSource={walletHistoryData}
                  onChange={(pagination) => setPage(pagination.current)}
                />
              )}
            </div>
          </div>
        </StatusViewRenderer>
      </div>
    </div>
  );
}

function AutoReloadSection(): JSX.Element {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const userSettings = useUserSettings();
  const updateUserSettings = useUpdateUserSettings();
  const paymentMethods = usePaymentMethods();
  const appSettings = useSettings();
  const userSettingsData = userSettings.data?.data;
  const paymentMethodsData = paymentMethods?.data?.data?.payment_methods;
  const valueLimit = appSettings?.data?.data?.deposit?.data?.limit;
  const allowedPaymentMethodTypes = appSettings?.data?.data?.deposit?.data?.payment_method_types;
  const filteredPaymentMethods = _.filter(paymentMethodsData, (item) =>
    // auto reload hide ach
    // _.includes(allowedPaymentMethodTypes ?? ['card', 'us_bank_account'], item?.type)
    _.includes(['card'], item?.type)
  );
  const defaultPaymentMethod = _.find(filteredPaymentMethods, ['type', 'card'])?.id ?? filteredPaymentMethods?.[0]?.id;
  const [autoReload, setAutoReload] = useState(userSettingsData?.credits_auto_reload_activated ?? false);
  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);
  const whenBelowValue = Form.useWatch('credits_auto_reload_when_below', form);
  const reloadToValue = Form.useWatch('credits_auto_reload_reload_to', form);

  const saveSettings = (settings?: any, action = 'Auto Reload') => {
    const formValues = form.getFieldsValue(true) ?? {};
    const params = settings ?? {
      ...formValues,
      credits_auto_reload_activated: autoReload,
      credits_auto_reload_payment_method_id: Number(formValues?.credits_auto_reload_payment_method_id),
    };

    updateUserSettings.mutate(params, {
      onSettled: (data, error) => {
        if (error) {
          message.error({ key: 'autoReload', content: (error as Error)?.message || `Set ${action} Failure!` });
        } else {
          if (action === 'Auto Reload') setSettingsDialogVisible(false);
          message.success({ key: 'autoReload', content: `Set ${action} Success!` });
        }
      },
    });
  };

  const handleSave = () => {
    saveSettings();
  };

  const handleCancel = () => {
    setAutoReload(!!userSettingsData?.credits_auto_reload_activated);
    setSettingsDialogVisible(false);
  };

  useEffect(() => {
    if (userSettings.isLoading || _.isEmpty(userSettingsData)) return;

    const hasPaymentMethod = _.some(filteredPaymentMethods, [
      'id',
      userSettingsData?.credits_auto_reload_payment_method_id,
    ]);

    form.setFieldsValue({
      credits_auto_reload_when_below: userSettingsData?.credits_auto_reload_when_below ?? 0,
      credits_auto_reload_reload_to: userSettingsData?.credits_auto_reload_reload_to ?? 0,
      credits_auto_reload_payment_method_id: hasPaymentMethod
        ? userSettingsData?.credits_auto_reload_payment_method_id
        : defaultPaymentMethod,
    });
    setAutoReload(!!userSettingsData?.credits_auto_reload_activated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsData?.credits_auto_reload_activated, defaultPaymentMethod]);

  return (
    <>
      <div className={`${prefixCls}__section`}>
        <div className={`${prefixCls}__section-title`}>
          <div className={`${prefixCls}__layout-space-between`}>
            Auto Reload
            <Switch
              checked={autoReload}
              loading={userSettings.isLoading || updateUserSettings.isLoading}
              onChange={(checked) => {
                setAutoReload(checked);
                if (checked) {
                  setSettingsDialogVisible(true);
                } else {
                  userSettingsData?.credits_auto_reload_activated &&
                    saveSettings({ credits_auto_reload_activated: false });
                }
              }}
            />
          </div>
        </div>
        <div className={`${prefixCls}__section-body`}>
          <div className={`${prefixCls}__auto-reload`}>
            {!autoReload && (
              <div className={`${prefixCls}__auto-reload-description`}>
                Set an amount to automatically reload your Kiwi Credits so you don&lsquo;t forget any more.
              </div>
            )}
            {autoReload && (
              <div className={`${prefixCls}__auto-reload-info`}>
                <span>
                  When below {formatCurrency(formatCredit(userSettingsData?.credits_auto_reload_when_below))}, reload to{' '}
                  {formatCurrency(formatCredit(userSettingsData?.credits_auto_reload_reload_to))}
                </span>
                <Button size="small" fill="none" onClick={() => setSettingsDialogVisible(true)}>
                  Edit <FiChevronRight />
                </Button>
              </div>
            )}
            <Modal
              className={`${prefixCls}__auto-reload-dialog`}
              visible={settingsDialogVisible}
              zIndex={800}
              title="Auto Reload"
              fullscreen="mobile"
              onClose={handleCancel}
              footer={
                <>
                  <Button shape="rounded" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    color="main"
                    shape="rounded"
                    disabled={!(whenBelowValue > 0 && reloadToValue > 0 && filteredPaymentMethods?.length > 0)}
                    loading={updateUserSettings?.isLoading}
                    loadingText="Saving"
                    onClick={form.submit}
                  >
                    Save Changes
                  </Button>
                </>
              }
            >
              <Form className={`${prefixCls}__auto-reload-form`} form={form} layout="vertical" onFinish={handleSave}>
                <Form.Item
                  name="credits_auto_reload_when_below"
                  label="When below"
                  normalize={(value) => value * 100}
                  getValueProps={(value) => ({ value: value / 100 })}
                  rules={[
                    {
                      type: 'number',
                      min: (valueLimit?.min_auto_reload_when_below ?? 0) / 100,
                      message: `Minimum amount is ${formatCurrency(
                        (valueLimit?.min_auto_reload_when_below ?? 0) / 100
                      )}`,
                    },
                    {
                      type: 'number',
                      max: valueLimit?.max_auto_reload_when_below ?? 0 / 100,
                      message: `Maximum amount is ${formatCurrency(
                        (valueLimit?.max_auto_reload_when_below ?? 0) / 100
                      )}`,
                    },
                  ]}
                >
                  <InputNumber<number>
                    controls={false}
                    min={0}
                    placeholder="$20.00"
                    formatter={(v, info) => (info.userTyping ? info.input : formatCurrency(v))}
                    parser={(v) => parseFloat(v?.replace(/\$|,/g, ''))}
                  />
                </Form.Item>
                <Form.Item
                  name="credits_auto_reload_reload_to"
                  label="Reload to"
                  normalize={(value) => value * 100}
                  getValueProps={(value) => ({ value: value / 100 })}
                  dependencies={['credits_auto_reload_when_below']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        const whenBelow = getFieldValue('credits_auto_reload_when_below');
                        const diff = value - whenBelow;
                        if (diff < valueLimit?.min_deposit) {
                          return Promise.reject(
                            new Error(
                              `'Recharge to' must be greater than 'When below' by ${formatCurrency(
                                (valueLimit?.min_deposit ?? 0) / 100
                              )}`
                            )
                          );
                        }
                        if (diff > valueLimit?.max_deposit) {
                          return Promise.reject(
                            new Error(
                              `'When below' must be less than 'Recharge to' by ${formatCurrency(
                                (valueLimit?.max_deposit ?? 0) / 100
                              )}`
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber<number>
                    controls={false}
                    min={0}
                    placeholder="$100.00"
                    formatter={(v, info) => (info.userTyping ? info.input : formatCurrency(v))}
                    parser={(v) => parseFloat(v?.replace(/\$|,/g, ''))}
                  />
                </Form.Item>
                <Form.Item
                  className={cn(`${prefixCls}__auto-reload-form-bank-list-field`, {
                    [`${prefixCls}__auto-reload-form-bank-list-field--empty`]: _.isEmpty(filteredPaymentMethods),
                  })}
                  name="credits_auto_reload_payment_method_id"
                  label="Pay from"
                >
                  <Radio.Group>
                    <div className={`${prefixCls}__auto-reload-form-bank-list`}>
                      {filteredPaymentMethods?.map((item) => {
                        const isBank = item?.type === PaymentMethodTypeEnum.Bank;

                        return (
                          <div key={item?.id} className={`${prefixCls}__auto-reload-form-bank-list-item`}>
                            <Radio
                              value={item?.id}
                              icon={(checked) => (checked ? <IoRadioButtonOn /> : <IoRadioButtonOff />)}
                            />
                            {isBank ? <AiOutlineBank /> : <AiOutlineCreditCard />}
                            <div className={`${prefixCls}__auto-reload-form-bank-list-item-info`}>
                              {isBank && (
                                <div className={`${prefixCls}__auto-reload-form-bank-list-item-label`}>
                                  PROMOTION +1% EXTRA
                                </div>
                              )}
                              <div className={`${prefixCls}__auto-reload-form-bank-list-item-title`}>
                                <OmittedName
                                  name={isBank ? item?.detail?.bank_name : _.upperFirst(item?.detail?.brand)}
                                  last4={item?.detail?.last4}
                                />
                              </div>
                              {isBank && (
                                <div className={`${prefixCls}__auto-reload-form-bank-list-item-description`}>
                                  Up to 4 day processing period
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Radio.Group>
                </Form.Item>
                <Button.Add
                  className={`${prefixCls}__auto-reload-form-add-bank-btn`}
                  fill="none"
                  bold={false}
                  onClick={() =>
                    navigate(getUpdatedSearchParamsPath({ dialog: 'payment-methods/methods', init: 1, h_ach: 1 }), {
                      replace: true,
                    })
                  }
                >
                  Add a new payment method
                  {/* hide ach when auto reload */}
                  {/* {!_.some(filteredPaymentMethods, ['type', 'us_bank_account']) && (
                    <div className={`${prefixCls}__auto-reload-form-add-bank-btn-label`}>
                      PROMOTION: Get 1% extra by linking a Bank account (ACH payment)
                    </div>
                  )} */}
                </Button.Add>
              </Form>
            </Modal>
          </div>
        </div>
      </div>

      <div className={`${prefixCls}__section`}>
        <div className={`${prefixCls}__section-title`}>
          <div className={`${prefixCls}__layout-space-between`}>
            Auto Apply in Checkout
            <Switch
              checked={userSettingsData?.credit_auto_apply}
              loading={userSettings.isLoading || updateUserSettings.isLoading}
              onChange={(checked) => {
                saveSettings({ credit_auto_apply: checked }, 'Use with Auto Checkout');
              }}
            />
          </div>
        </div>
        <div className={`${prefixCls}__section-body`}>
          <div className={`${prefixCls}__auto-reload`}>
            <div className={`${prefixCls}__auto-reload-description`}>
              Automatically use Kiwi Wallet credits for orders only when you have enough credits to cover the whole
              order and auto checkout is turned on.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KiwiWallet;
