import { useMemo } from 'react';

import useSettings from '@/hooks/queries/useSettings';

const useSalesTimerSetting = ({ productTags }: { productTags: string[] }) => {
  const settings = useSettings();
  const timerSetting = useMemo(() => {
    const timerSettingOrigin = settings?.data?.data?.timer?.data;
    if (!Array.isArray(timerSettingOrigin)) return null;
    const tmp = timerSettingOrigin.filter((setting) => productTags?.some((tag) => setting?.tag.includes(tag)));
    // 取优先匹配到的第一个salestimer 配置
    return tmp?.[0];
  }, [productTags, settings?.data?.data]);

  return timerSetting;
};

export default useSalesTimerSetting;
