$actionsBarHeight: 85px;
$border-color: #c7c7c7;

.ns_page_product_request_main {
  .content {
    margin: 0 auto;
    display: flex;
    .content_item {
      flex: 1;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    }
    .left {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      font-size: var(--font-size3);
      color: var(--color-black);
      background-color: var(--color-white);
      .title {
        font-weight: 500;
        .mark {
          color: var(--color-danger);
        }
      }
      .form_box {
        box-sizing: border-box;
        padding: 24px;
        .img_upload_box {
          display: none;
          padding-bottom: var(--spacing-xxl);
          .img_upload_btn_wrapper {
            display: flex;
            justify-content: center;
            button {
              width: 135px;
              background-color: var(--color-black);
              color: var(--color-white);
              border-color: var(--color-black);
            }
          }
        }
        .url_upload_box {
          .hint {
            padding-top: var(--spacing-xs);
            color: var(--color-gray3);
          }
          .input_wrapper {
            margin: var(--spacing-l) 0;
            height: 225px;
            position: relative;
            background-color: var(--color-white);
            textarea {
              z-index: 1;
              background-color: transparent;
            }
            .placeholder_wrapper {
              z-index: 0;
              position: absolute;
              top: 2px;
              left: 12px;
              color: grey;
              width: calc(100% - 24px);
              .item {
                word-break: break-all;
              }
            }
            :global {
              textarea {
                height: 100%;
                resize: none;
              }
            }
          }
        }
        .submit_btn_wrapper {
          display: flex;
          justify-content: center;
          z-index: 10;
          button {
            width: 135px;
          }
        }
      }
      .others {
        box-sizing: border-box;
        padding: var(--spacing-l) + var(--spacing-l);
        border-top: 1px solid $border-color;
        .desc {
          line-height: 140%;
          padding: var(--spacing-m) 0 var(--spacing-xxl);
        }
        .download_btn_wrapper {
          text-align: center;
          display: flex;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
          span {
            white-space: nowrap;
          }
          :global {
            .adm-button {
              display: flex;
              width: 260px;
              padding: 10px var(--spacing-xl);
              background-color: var(--color-black);
              color: var(--color-white);
              border-color: var(--color-black);
              border-radius: 6px;
              font-size: var(--font-size3);
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .content {
      padding: 42px 0;
      max-width: var(--content-max-width);
    }
    .secondary_nav_bar {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      flex-direction: column;
      padding-top: 0;
      .content_item {
        box-shadow: none;
      }
      .right {
        background-color: inherit;
        .form_box {
          padding: var(--spacing-xxl) var(--spacing-m) 96px;
          .img_upload_box {
            display: block;
          }
          .submit_btn_wrapper {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            padding: var(--spacing-l) 0 30px;
            background: #fff;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
          }
        }
        .others {
          display: none;
        }
      }
    }
    @at-root .ns_page_product_request_active {
      background: var(--color-white);
      :global {
        .app-layout {
          --app-layout-padding-top: var(--secondary-nav-bar-height);
          // --app-layout-padding-bottom: #{$actionsBarHeight};
          --app-layout-padding-bottom: 130px;
        }
        .nav_bar {
          display: none;
        }
        .tab_bar {
          display: none;
        }
      }
    }
  }
}
