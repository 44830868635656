.order_status_filter_comp_scrollable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: var(--spacing-s);
    .button_wrapper {
      position: relative;
      .count {
        z-index: 1;
        position: absolute;
        top: -4px;
        left: -4px;
        width: 14px;
        height: 14px;
        padding: 0 2px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        border-radius: 7px;
        background-color: #000;
        color: #fff;
        &.two_digits {
          width: 20px;
        }
        &.three_digits {
          width: 26px;
        }
      }
    }
    .button {
      font-size: var(--font-size2);
      border: none;
      padding: 5px 10px;

      &.default {
        color: var(--color-gray4);
      }

      &.primary {
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        background: linear-gradient(270deg, #f5fce5 0%, rgba(245, 252, 229, 0.5) 115.54%), #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
