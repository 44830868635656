import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import Notification from '@/components/Notification';
import { useDesktop } from '@/hooks';
import { updateSessionStorageAfterAddToCart } from '@/utils/biz';

import css from './index.module.scss';
import ModalBulkSelectSKUM from './m/ModalBulkSelectSKU';
import ModalBulkSelectSKUPC from './pc/ModalBulkSelectSKU';

type Props = {
  title: string;
  children: (params: { show: () => void }) => React.ReactNode | Element;
};

const App = ({ title, children }: Props) => {
  const isDesktop = useDesktop();
  const [visible, setVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const navigate = useNavigate();

  const show = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  // 加购成功后回调
  const afterSuccessCallback = (addToCartValues) => {
    setNotificationVisible(true);
    updateSessionStorageAfterAddToCart(addToCartValues);
  };

  const modalProps = {
    visible,
    title,
    onClose: close,
    afterSuccessCallback,
  };

  const childrenProps = {
    show,
  };

  const CurrentModal = isDesktop ? ModalBulkSelectSKUPC : ModalBulkSelectSKUM;

  return (
    <>
      <CurrentModal {...modalProps} />
      {children(childrenProps)}
      <Notification
        placement={isDesktop ? 'topRight' : 'top'}
        className={css.ns_or_notification}
        color="black"
        visible={notificationVisible}
        textAlign="left"
        zIndex={2000}
        title={
          <div className={css.title_wrapper}>
            <span>Added to cart</span>
            <Button
              color="white"
              fill="none"
              underline
              style={{ padding: 0 }}
              onClick={() => {
                setNotificationVisible(false);
                navigate('/cart');
              }}
            >
              View Cart
            </Button>
          </div>
        }
        onClose={() => setNotificationVisible(false)}
      />
    </>
  );
};

export default App;
