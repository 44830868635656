import { GoArrowUp } from 'react-icons/go';

import { formatCredit } from '@/utils/util';

import css from './EarnedCredits.module.scss';

const EarnedCredits = ({
  creditRewards,
  showCreditRewards,
}: {
  creditRewards: number;
  showCreditRewards: () => void;
}) => {
  const showDetails = () => {
    showCreditRewards?.();
  };

  return (
    <div className={css.earned_credits_wrapper} onClick={showDetails}>
      <div className={css.price_wrapper}>
        <span className={css.title}>
          <GoArrowUp /> You have earned
        </span>
        <span className={css.price}>${formatCredit(creditRewards)}</span>
      </div>
      <div className={css.show_details_btn}>
        <span>Show details</span> <span className={css.arrow_icon} />
      </div>
    </div>
  );
};

export default EarnedCredits;
