/*
 * @Author: xiaoman
 * @Date: 2022-06-29 17:33:35
 * @Last Modified by: xiaoman
 * @Last Modified time: 2022-06-29 18:38:19
 */
import { Input as AntdInput } from 'antd';
import type { InputProps } from 'antd/lib/input';
import React from 'react';

interface Props extends InputProps {
  onChange?: (data?: any) => void;
  onBlur?: (data?: any) => void;
  forwardedRef?: any;
}

const withWrapper = (Component) => {
  const InnerComponent = (props: Props) => {
    const { forwardedRef, onChange, onBlur, ...rest } = props;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value?.replace(/\s+/g, ' ');
      onChange?.(val);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val) {
        onChange?.(val.trim());
      }
      onBlur?.(e);
    };
    return <Component ref={forwardedRef} onChange={handleChange} onBlur={handleBlur} {...rest} />;
  };

  return React.forwardRef((props: Props, ref) => <InnerComponent {...props} forwardedRef={ref} />);
};

// TODO： ts定义
const Input: any = withWrapper(AntdInput);
Input.Password = withWrapper(AntdInput.Password);
Input.TextArea = withWrapper(AntdInput.TextArea);
Input.Search = withWrapper(AntdInput.Search);
Input.Group = withWrapper(AntdInput.Group);

export default Input;
