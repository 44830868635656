/**
 * API request wrapper
 */

import * as Sentry from '@sentry/react';
import { ApisauceConfig, create } from 'apisauce';
import _ from 'lodash';

import envconfig from '@/constants/envconfig';
import { tokenStorage } from '@/hooks/auth';

import { ERROR_CODES_TO_ERROR_MESSAGES, needCustomHandleErrorCodes } from './constant';

const defaultApiConfig: ApisauceConfig = {
  baseURL: envconfig.API_BASE_URL,
  timeout: 1000 * 10,
  withCredentials: false,
};

const api = create(defaultApiConfig);

api.addRequestTransform((request) => {
  if (tokenStorage.getToken() && !('Authorization' in request.headers)) {
    request.headers.Authorization = `Bearer ${tokenStorage.getToken()}`;
  }

  if (envconfig.JUST_FOR_DEBUG_X_KIWI_USER_ID !== null && process.env.NODE_ENV !== 'production') {
    request.headers['X-Kiwi-User-Id'] = envconfig.JUST_FOR_DEBUG_X_KIWI_USER_ID;
  }
});

api.addResponseTransform((response) => {
  if (!response.ok || !_.isEmpty(response?.data?.errmsg)) {
    const errorMsg = response?.data?.errmsg || 'Unknown Error';
    // eslint-disable-next-line no-console
    console.error(response?.data?.errmsg || response.problem);

    // send the error to sentry
    Sentry.captureException(new Error(`Request Error: ${errorMsg}`), { extra: { response } });
  }
});

// temporary replace request to directly url
// api.addRequestTransform((request) => {
//   if (request?.url?.startsWith('/ds/')) {
//     request.url = request.url.replace('/ds/', 'https://api.ds.jumpo.club/');
//   } else if (request?.url?.startsWith('/crux/')) {
//     request.url = request.url.replace('/crux/', 'https://api.crux.jumpo.club/');
//   } else if (request?.url?.startsWith('/sso/')) {
//     request.url = request.url.replace('/sso/', 'https://api.sso.jumpo.club/');
//   }
// });

export function responseErrorHandler(res) {
  const newResponse = res.data;
  const needCustomHandle = needCustomHandleErrorCodes.includes(res?.data?.errno);
  const isLynxServer = res.config.baseURL === envconfig.API_BASE_URL_FOR_LYNX;
  const needCustomLynxErrorMEssage =
    isLynxServer && Object.keys(ERROR_CODES_TO_ERROR_MESSAGES.LYNX).includes(String(newResponse?.errno));

  if (needCustomLynxErrorMEssage) {
    newResponse.errmsg =
      ERROR_CODES_TO_ERROR_MESSAGES.LYNX[newResponse?.errno] || newResponse?.errmsg || 'Network Error.';
  }

  if (needCustomHandle) {
    throw newResponse;
  }

  if (!res.ok || !_.isEmpty(newResponse?.errmsg)) {
    throw new Error(newResponse?.errmsg || `${res?.problem}${res?.status ? ` (${res?.status})` : ''}`);
  }
}

export default api;
