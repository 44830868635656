import { Form, Input, message } from 'antd';
import { Dialog, Switch } from 'antd-mobile';
import cn from 'classnames';
import { ElementRef, useEffect, useRef, useState } from 'react';

import Button from '@/components/Button';
import { GearIcon } from '@/components/Icon';
import Modal from '@/components/Modal';
import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import useOrderCombineSettingsUpdate from '@/hooks/queries/useOrderCombineSettingsUpdate';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useDesktop from '@/hooks/useDesktop';

import css from './index.module.scss';
import ModalLiveSelling from './ModalLiveSelling';

type ModalEditNameComponentHandle = ElementRef<typeof ModalLiveSelling>;

const oneHourToMS = 1000 * 60 * 60;
const DEFAULT_DELAY_PROCESSING_HOUR = 24;
const TITLE = 'Hold & Combine';

const App = () => {
  const { isLoading, data, isFetching } = useUserSettings();
  const { mutate, isLoading: isUpdating } = useOrderCombineSettingsUpdate();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const isDesktop = useDesktop();
  const isButtonLoading = isLoading || isFetching || isUpdating;
  const [disabledAutoCombine, setDisabledAutoCombine] = useState(false);
  const [disabledSampleNodelay, setDisabledSampleNodelay] = useState(false);
  const modalLiveSellingRef = useRef<ModalEditNameComponentHandle>();

  const openLiveSellingModal = () => {
    modalLiveSellingRef?.current?.show();
  };

  const settings = [
    {
      title: 'Holding Period',
      content: (
        <>
          <div>
            Hold my orders for
            <Form.Item name="delay_processing_ms" noStyle>
              <Input className={css.delay_processing_ms_input} disabled />
            </Form.Item>
            hrs after checkout
          </div>
          <div className={css.holding_sec_desc}>
            You can combine orders, manually process or cancel orders during the holding period. After holding we will
            move your orders to “Processing”.
          </div>
        </>
      ),
      formItem: (
        <Form.Item name="delay_processing" valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>
      ),
    },
    {
      title: 'Auto Combine',
      content: (
        <>
          <div>
            After the holding period expires we will automatically combine and process orders that contain matching
            customer names and addresses.
          </div>
          {/* <div className={css.delay_include_sample}>
            <Form.Item name="delay_include_sample" valuePropName="checked" noStyle>
              <Checkbox color="primary" disabled={disabledSampleNodelay} />
            </Form.Item>
            <span className={css.checkbox_desc}>Apply settings to sample orders</span>
          </div> */}
          <div className={css.live_selling_button} onClick={openLiveSellingModal}>
            {/* <span className={css.money}>💸</span> */}
            <strong className={css.text}>Learn how auto-combine saves you money!</strong>
          </div>
        </>
      ),
      formItem: (
        <Form.Item name="delay_auto_combine" valuePropName="checked" noStyle>
          <Switch disabled={disabledAutoCombine} />
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    if (visible && data?.data) {
      const values = data.data;
      const { delay_processing, delay_after_ms } = values;

      form.setFieldsValue({
        ...values,
        delay_processing_ms: Math.round(delay_after_ms / oneHourToMS),
      });
      setDisabledAutoCombine(!delay_processing);
      setDisabledSampleNodelay(!delay_processing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, data]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onValuesChange = (val) => {
    if ('delay_processing' in val) {
      const { delay_processing } = val;
      // 关闭hold period，立即关闭另外两项
      if (!delay_processing) {
        form.setFieldsValue({
          delay_auto_combine: false,
          delay_include_sample: false,
        });
      }
      setDisabledAutoCombine(!delay_processing);
      setDisabledSampleNodelay(!delay_processing);
      if (!delay_processing) {
        Dialog.alert({
          className: css.delay_processing_off_dialog_wrapper,
          content: `Once you turn off Holding Period, all orders with the Holding status will be moved to Processing. If Auto Combine was also turned on, we will combine all valid orders before moving them to Processing.`,
          confirmText: 'OK',
          closeOnMaskClick: true,
        });
      }
    }
  };

  const openCombineSettingsModal = () => {
    setVisible(true);
  };

  const toOpenCombineSettingsModal = () => {
    const hideLiveSellingModal = localStorage.getItem(LOCAL_STORAGE_KEYS.hideLiveSellingModal);
    if (hideLiveSellingModal) {
      openCombineSettingsModal();
    } else {
      openLiveSellingModal();
    }
  };

  const onSubmit = () => {
    const { delay_processing_ms, ...values } = form.getFieldsValue();
    mutate(
      {
        ...values,
      },
      {
        onSuccess() {
          message.success('Successfully');
          setVisible(false);
        },
        onError(e: Error) {
          message.error(e.message);
        },
      }
    );
  };

  return (
    <>
      <Button className={cn(css.combine_settings_com, css.btn)} onClick={toOpenCombineSettingsModal}>
        <GearIcon />
        <span className={css.text}>{TITLE}</span>
      </Button>
      <Modal
        title={TITLE}
        visible={visible}
        onClose={onClose}
        className={css.order_page_modal_combine_settings}
        fullscreen={!isDesktop}
        footer={
          <Button loading={isButtonLoading} color="main" shape="rounded" onClick={onSubmit}>
            Save
          </Button>
        }
      >
        <Form form={form} className={css.form} onValuesChange={onValuesChange}>
          {settings.map(({ title, content, formItem }) => (
            <div className={css.form_item_wrapper} key={title}>
              <div className={css.form_header}>
                <span className={css.left}>{title}</span>
                <div className={css.right}>{formItem}</div>
              </div>
              <div className={css.form_content}>{content}</div>
            </div>
          ))}
        </Form>
      </Modal>
      <ModalLiveSelling ref={modalLiveSellingRef} onContinue={openCombineSettingsModal} />
    </>
  );
};

export default App;
