.ns_com_product_list_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-auto-rows: max-content;
  gap: var(--spacing-xl) var(--spacing-m);

  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(auto-fill, minmax(218px, 1fr));
    gap: 36px 40px;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, calc((100% - var(--spacing-m)) / 2)) !important;
  }
}
