import { Divider, DotLoading, InfiniteScroll as AntInfiniteScroll } from 'antd-mobile';
import type { InfiniteScrollProps } from 'antd-mobile/es/components/infinite-scroll';
import cn from 'classnames';

import css from './index.module.scss';

export type { InfiniteScrollProps };

function InfiniteScroll({ className, hasMore, ...restProps }: InfiniteScrollProps): JSX.Element {
  return (
    <AntInfiniteScroll className={cn(css.ns_com_infinite_scroll_main, className)} hasMore={hasMore} {...restProps}>
      <div className={css.content}>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <Divider>No More</Divider>
        )}
      </div>
    </AntInfiniteScroll>
  );
}

export default InfiniteScroll;
