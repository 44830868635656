import { useMutation, useQueryClient } from 'react-query';

import { emailVerifyCode } from '@/services/lynx';

const useAccountEmailVerifyCode = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => emailVerifyCode(data), {
    onSuccess() {
      queryClient.invalidateQueries('authUser');
    },
  });
};

export default useAccountEmailVerifyCode;
