import { Button } from 'antd';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useStore from '@/hooks/queries/useStore';

import styles from './index.module.scss';

export const EmptyContent: FC = () => {
  const params = useParams();

  const store = useStore(params.shopId);

  const shopifyUrl = useMemo(() => {
    const url = store.data?.data?.shop?.shopify_domain;
    if (!url) {
      return 'https://www.shopify.com';
    }
    if (/^https?:/.test(url)) {
      return url;
    }
    return `https://${url}`;
  }, [store]);

  const handleClick = () => {
    window.open(shopifyUrl);
  };

  return (
    <div className={styles.empty}>
      <p>No payout Found</p>
      <p>To start selling, go to manage Products From Shopify</p>
      <Button shape="round" type="primary" onClick={handleClick}>
        Manage Products From Shopify
      </Button>
    </div>
  );
};
