import { Col, Input, Row, Select } from 'antd';
import debounce from 'lodash/debounce';
import { ChangeEvent as ReactChangeEvent, useCallback, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';

import { QUERY_KEYWORDS, QUERY_STATE } from '../../def';
import styles from './index.modules.scss';

const selectOptions = [
  {
    label: 'Show All Status',
    value: '',
  },
  {
    label: 'Show Active',
    value: 'active',
  },
  {
    label: 'Show Inactive',
    value: 'inactive',
  },
];

const options = {
  replace: true,
};

export const Filter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const changeCallbackRef = useRef(
    debounce((e: ReactChangeEvent<HTMLInputElement>) => {
      setSearchParams(
        {
          ...Object.fromEntries(searchParams),
          [QUERY_KEYWORDS]: e.target.value,
        },
        options
      );
    }, 500)
  );

  const handleSelectChange = (state) => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams),
        [QUERY_STATE]: state,
      },
      options
    );
  };

  return (
    <div className={styles.filter}>
      <Row>
        <Col sm={{ span: 20 }} xs={{ span: 16 }}>
          <Input
            placeholder="title, tag, vendor..."
            bordered
            prefix={<FiSearch />}
            style={{
              borderRadius: '20px',
            }}
            defaultValue={searchParams.get(QUERY_KEYWORDS)}
            onChange={changeCallbackRef.current}
          />
        </Col>
        <Col sm={{ span: 3, offset: 1 }} xs={{ span: 8 }}>
          <Select
            style={{
              width: '100%',
            }}
            bordered={false}
            options={selectOptions}
            onChange={handleSelectChange}
            defaultValue={searchParams.get(QUERY_STATE) || ''}
          />
        </Col>
      </Row>
    </div>
  );
};
