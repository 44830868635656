import { AlphaStack, Box } from '@shopify/polaris';

import css from './ReadyToShip.module.scss';

const ReadyToShip = () => (
  <div className={css.ns_com_collection_preorder_ready_to_ship}>
    <Box paddingBlockStart="4" paddingBlockEnd="4">
      <Box paddingBlockEnd="2">
        <p className="ds2_body_title">READY TO SHIP PREORDERS</p>
      </Box>
      <AlphaStack gap="3">
        <p className="ds2_body_md">
          Orders for these preorder products have been closed, but extras are expected, you can still place orders
          within the existing stock range.
        </p>
        <p className="ds2_body_md">
          {`⏰ Ready to ship date: We will begin fulfilling Preorder products around the "Ship Date". The ship date is not
          a concrete guarantee but a good estimate provided to us by the vendor for these goods. These dates should be
          close but will not be exact. You can see specified date on the product listing!`}
        </p>
      </AlphaStack>
    </Box>
  </div>
);

export default ReadyToShip;
