import moment from 'moment';
import { useMemo } from 'react';

const useDuration = (countDown) =>
  useMemo(() => {
    if (countDown > 0) {
      const durationTime = moment.duration(countDown);
      return [durationTime.days(), durationTime.hours(), durationTime.minutes(), durationTime.seconds()];
    }
    return [0, 0, 0, 0];
  }, [countDown]);

export default useDuration;
