.ns_com_fulfill_label.shipping_label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 99px;
  border: 1px solid var(--shades-200, #e1e1e8);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  color: var(--shades-800, #2d2d33);
  font-weight: 600;
  text-transform: uppercase;
  gap: 2px;
  z-index: 1;
}

.ns_com_fulfill_label.shipping_label span {
  zoom: 0.66;
  margin: -12px 0px;
  font-weight: 600;
  white-space: nowrap;
}

.ns_com_fulfill_label.shipping_label_gray {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  gap: 4px;
  z-index: 1;
}

.ns_com_fulfill_label.shipping_label_gray span {
  color: var(--shades-500, #85858c);
  zoom: 0.83;
  margin: -12px 0px;
  font-weight: 500;
  white-space: nowrap;
}
