import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { updateSellerCollection } from '@/services/ds';

export default function useUpdateSellerCollection(queryParams?: any) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, title, is_public, body_html }: { id: string; title?: string; is_public?: boolean; body_html?: string }) =>
      updateSellerCollection(id, title, is_public, body_html),
    {
      onSuccess: (data, variables) => {
        // update seller collections list
        queryClient.setQueryData(['sellerCollections', 'getSellerCollections', queryParams], (prevData: any) =>
          produce(prevData, (draft: any) => {
            // eslint-disable-next-line consistent-return
            _.forEach(draft?.pages, (page) => {
              const collection = _.find(page?.data?.seller_collections, { id: variables.id });
              if (collection) {
                collection.title = variables.title;
                collection.body_html = variables.body_html;
                return false;
              }
            });
          })
        );

        // update seller collection detail
        queryClient.setQueryData(
          ['sellerCollections', 'getSellerCollectionProducts', variables.id, queryParams],
          (prevData: any) =>
            produce(prevData, (draft: any) => {
              _.set(
                draft,
                'pages[0].data.seller_collection.title',
                variables.title || prevData?.pages?.[0]?.data?.seller_collection?.title
              );
              _.set(
                draft,
                'pages[0].data.seller_collection.body_html',
                variables.body_html || prevData?.pages?.[0]?.data?.seller_collection?.body_html
              );
            })
        );

        queryClient.invalidateQueries('sellerCollections');
      },
    }
  );
}
