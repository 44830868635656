import cn from 'classnames';
import JsBarcode from 'jsbarcode';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { LOGO_BASE64 } from '@/constants/biz';
import { CollectionProduct } from '@/types';
import { commonPrefix, getPriceRange } from '@/utils/util';

import { DEFAULT_DATA } from './cons';
import css from './FullSize.module.scss';

const FullSize = ({ data, isDemo = false }: { data?: CollectionProduct; isDemo?: boolean }) => {
  const { product } = data || DEFAULT_DATA;
  const { title, price_min, price_max, options = [], variants = [], cover, master_style } = product;
  const barcodeImgRef = useRef(null);
  const skuVariants = variants.map(({ sku }) => sku);
  const commonSkuPrefix = _.trim(commonPrefix(skuVariants), '-');

  useEffect(() => {
    if (commonSkuPrefix && barcodeImgRef.current) {
      JsBarcode(barcodeImgRef.current, commonSkuPrefix, {
        height: isDemo ? 30 : 40,
        displayValue: false,
        margin: 0,
        width: 2,
      });
    }
  }, [isDemo, commonSkuPrefix]);

  return (
    <div className={cn(css.ns_modal_print_live_helper_full_size, { demo: isDemo })}>
      <div className={css.section_wrapper}>
        <section className={css.section_1}>
          <img className={css.logo} src={LOGO_BASE64} alt="Kiwi & Drop" />
          <span className={css.title}>{title}</span>
          <span>{getPriceRange(price_min, price_max)}</span>
          <span className={css.bold_700}>Style #: {master_style}</span>
        </section>
        <section className={css.section_2}>
          {options.map(({ name, values }) => (
            <span key={name}>
              <span className={css.bold_700}>
                {name} ({values?.length}):
              </span>{' '}
              {values?.join(', ')}
            </span>
          ))}
        </section>
        <section className={css.section_3}>
          <img src={cover} alt="logo" />
        </section>
        <section className={css.section_4}>
          {commonSkuPrefix && <img ref={barcodeImgRef} alt="" />}
          {commonSkuPrefix && <span>{commonSkuPrefix}</span>}
        </section>
      </div>
    </div>
  );
};

export default FullSize;
