.product_card {
  &.grid_view {
    background: #fff;
    padding: 16px;
    display: flex;
    column-gap: 12px;
    border-radius: 12px;

    .img_wrapper {
      position: relative;
      height: fit-content;
      .label_wrapper {
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .dropping_soon_label {
        white-space: nowrap;
        background-color: #f2f2f2d9;
        border-radius: 4px;
        padding: 2px 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 130%;
        box-shadow: 0 2px 8px #0000001f;
      }
      .out_of_stock_label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: var(--spacing-xxs) var(--spacing-xs);
        color: var(--color-white);
        font-size: var(--font-size1);
        line-height: 1.4;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

        i {
          margin-right: var(--spacing-xs);
          padding: 0;
          color: var(--color-danger);
          line-height: 0;
        }
      }
    }
    .img {
      width: 100px;
      height: 130px;
      border-radius: 8px;
      cursor: pointer;
    }
    .price_wrapper {
      display: flex;
      flex-direction: column;
      column-gap: 4px;
      width: 160px;
      .price_sell {
        width: 190px;
        color: #000;
        font-weight: 500;
        font-size: 14px;
      }
      .price_compare {
        color: #c1c1c1;
        font-weight: 500;
        font-size: 14px;
        text-decoration: line-through;
      }
      .price_sale_rate {
        background: #e3f4b1;
        height: fit-content;
        width: fit-content;
        padding: 0 4px;
        margin-left: 4px;
      }
      .style_tag {
        margin-top: 12px;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .inventory_qty {
      width: 60px;
    }
    .sku_table_wrapper {
      flex: 1;
      z-index: 0;
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 8px;
        cursor: pointer;
      }
    }
    .operations_wrapper {
      padding-left: 12px;
      display: flex;
      .product_card_import_btn,
      .buy_btn,
      .request_restock_btn {
        width: 140px;
        &:global(.adm-button-default) {
          box-shadow: 0px 2px 8px rgb(0 0 0 / 12%);
          border: none;
        }
      }
      .buy_btn,
      .request_restock_btn {
        margin-top: 6px;
      }
      .favorite_btn {
        background: #fff;
        box-shadow: 0px 2px 8px rgb(0 0 0 / 12%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        margin-left: 10px;
        justify-content: center;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
