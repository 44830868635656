import { useMutation } from 'react-query';

import { getCombineOrder } from '@/services/ds';

type Params = {
  dry_run: boolean;
  order_ids: string[];
};

type AddParams = Params & { shipping_address: string };

function useOrderAddCombine() {
  return useMutation((params: AddParams) => getCombineOrder(params));
}

function useOrderGetCombine() {
  return useMutation((params: Params) => getCombineOrder(params));
}

export { useOrderAddCombine, useOrderGetCombine };
