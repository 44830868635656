import { useQuery } from 'react-query';

import { getPrivateShippingAddresses } from '@/services/ds';

export default function usePrivateShippingAddresses(enabled?: boolean) {
  return useQuery('privateShippingAddresses', () => getPrivateShippingAddresses(), {
    enabled,
    staleTime: 1000,
  });
}
