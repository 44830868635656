.ns_com_img_uploader_main {
  width: 100%;
  .file_list_wrapper {
    display: flex;
    overflow-x: auto;
    margin: var(--spacing-l) 0;
    .item {
      margin-right: 10px;
      position: relative;
      .img {
        border-radius: 8px;
        width: 92px;
        height: 92px;
      }
      .del_btn {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--color-white);
        cursor: pointer;
      }
    }
  }
  .upload_btn_wrapper {
    display: flex;
    button {
      width: 135px;
    }
    &.center {
      justify-content: center;
    }
  }
}
