.referral_code_active {
  .center {
    text-align: center;
  }
  .body {
    .title {
      display: none;
    }
    .content {
      padding: 0 12px;
      .logo {
        padding-top: 117px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .desc {
        padding-top: 34px;
        font-size: 16px;
        .text {
          text-align: center;
        }
      }
      .steps {
        border-top: 1px solid #e8e8e8;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        padding-top: 24px;
        .item {
          flex: 1;
          margin: 0 7px;
          img {
            width: 36px;
            height: 36px;
          }
          .icon {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            margin: 0 auto;
            border-radius: 50%;
            background-color: #e3f4b1;
          }
          .label {
            margin-top: 12px;
            font-size: 14px;
            line-height: 18px;
            color: #000;
          }
        }
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8px 62px 28px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      .text {
        color: #999999;
        font-size: 16px;
      }
      button {
        margin-top: 8px;
        width: 100%;
        span {
          margin-left: 8px;
        }
      }
    }
  }
  :global {
    .app-layout {
      --app-layout-padding-bottom: 63px;
      background: #fff;
      // safe area bottom patch
      --app-layout-padding-bottom: calc(63px - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l)));

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: 769px) {
    .secondary_nav_bar {
      display: none;
    }
    .body {
      max-width: var(--content-small-max-width);
      margin: 0 auto;
      padding: 34px var(--spacing-l) 0;
      .title {
        display: block;
      }
      .content {
        background-color: #fff;
        .logo {
          padding-top: 24px;
        }
        .text + .text {
          padding: 0 80px; // to insure newline
        }
      }
      .footer {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        box-shadow: none;
        padding-top: 24px;
        background-color: #fff;
      }
    }
    :global {
      .app-layout {
        background-color: var(--color-gray1);
      }
    }
  }
}
