.product_item_wrapper {
  .product_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    background: var(--color-white);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    color: #424242;

    &:active {
      opacity: 0.6;
    }

    &:not(:first-child) {
      margin-top: var(--spacing-xxl);
    }

    .product_item_cover {
      position: relative;

      .product_item_cover_image {
        width: 75px;
        height: 100px;
      }
    }

    .product_item_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
      min-width: 0;
      padding: var(--spacing-m);
    }

    .product_item_title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: var(--spacing-xs);
      font-size: var(--font-size3);
      line-height: 1.4;
    }

    .product_item_price {
      margin-top: var(--spacing-xs);
      font-size: var(--font-size2);
      font-weight: 500;
      line-height: 1.3;
    }
  }
}
