import { Checkbox, Form, message, Switch, Tooltip } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useStorePriceRules from '@/hooks/queries/useStorePriceRules';
import useUpdateStorePriceRules from '@/hooks/queries/useUpdateStorePriceRules';
import PriceRuleArgAndOp from '@/pages/PriceRules/components/PriceRuleArgAndOp';
import { getDefaultSellPrice } from '@/pages/PriceRules/price';

import InputNumber from '../components/InputNumber';
import PriceRuleCharacter from '../components/PriceRuleCharacter';
import Prompt from '../components/Prompt';
import { DEFAULT_PRICE_ARG_MAP, DEFAULT_PRICE_RULES, DEMO_ALL_IN_COST, OPTIONS } from '../constant';
import PriceRuleType from '../types';
import css from './index.module.scss';

function PriceRules(): JSX.Element {
  const params = useParams();
  const [form] = Form.useForm();
  const priceRules = useStorePriceRules(params?.shopId);
  const updatePriceRules = useUpdateStorePriceRules(params?.shopId);
  const priceRulesData = useMemo(() => {
    const data = _.merge({}, DEFAULT_PRICE_RULES, priceRules?.data?.data?.price_rule);
    return {
      ...data,
      price_arg_and_op: {
        price_op: data.price_op,
        price_arg: data.price_arg,
      },
      compare_at_price_arg_and_op: {
        price_op: data.compare_at_price_op,
        price_arg: data.compare_at_price_arg,
      },
      price_assign_cents_arg: `${data.price_assign_cents_arg || ''}`.padStart(2, '0'),
      compare_at_price_assign_cents_arg: `${data.compare_at_price_assign_cents_arg || ''}`.padStart(2, '0'),
    };
  }, [priceRules?.data]);
  const [curPriceOp, setCurPriceOp] = useState(priceRulesData.price_arg_and_op.price_op);
  const [curCompareAtPriceOp, setCurCompareAtPriceOp] = useState(priceRulesData.compare_at_price_arg_and_op.price_op);
  const [priceEnabled, setPriceEnabled] = useState(priceRulesData.price_enabled);
  const [compareAtPriceEnabled, setCompareAtPriceEnabled] = useState(priceRulesData.compare_at_price_enabled);
  const [priceAssignCentsEnabled, setPriceAssignCentsEnabled] = useState(priceRulesData.price_assign_cents_enabled);
  const [compareAtPriceAssignCentsEnabled, setCompareAtPriceAssignCentsEnabled] = useState(
    priceRulesData.compare_at_price_assign_cents_enabled
  );
  const [defaultSellPrice, setDefaultSellPrice] = useState(0);
  const [defaultCompareAtSellPrice, setDefaultCompareAtSellPrice] = useState(0);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const formRef = useRef({});

  const priceSetting = {
    priceEnabled: {
      name: 'price_enabled',
    },
    priceArgAndOp: {
      name: 'price_arg_and_op',
      disabled: !priceEnabled,
    },
    priceAssignCentsEnabled: {
      name: 'price_assign_cents_enabled',
      disabled: !priceEnabled,
    },
    priceAssignCentsArg: {
      name: 'price_assign_cents_arg',
      disabled: priceEnabled ? !priceAssignCentsEnabled : !priceEnabled,
    },
    params: {
      sellPrice: defaultSellPrice,
      curPriceOp,
      title: 'Sell at Price',
      titleHint: `‘Sell at Price’ corresponds to the Price value on Shopify, your customers will pay.`,
      assignCentsHint: `This will modify the cents in your final Sell at Price.`,
    },
  };

  const compareArPriceSetting = {
    priceEnabled: {
      name: 'compare_at_price_enabled',
    },
    priceArgAndOp: {
      name: 'compare_at_price_arg_and_op',
      disabled: !compareAtPriceEnabled,
    },
    priceAssignCentsEnabled: {
      name: 'compare_at_price_assign_cents_enabled',
      disabled: !compareAtPriceEnabled,
    },
    priceAssignCentsArg: {
      name: 'compare_at_price_assign_cents_arg',
      disabled: compareAtPriceEnabled ? !compareAtPriceAssignCentsEnabled : !compareAtPriceEnabled,
    },
    params: {
      sellPrice: defaultCompareAtSellPrice,
      curPriceOp: curCompareAtPriceOp,
      title: 'Compare at Price',
      titleHint: `‘Compare at Price’ is a value on Shopify which will display on your store as a price with a
    strikethrough if your ‘Sell at Price’ is lower.`,
      assignCentsHint: `This will modify the cents in your final Compare at Price.`,
    },
  };

  const settings = [priceSetting, compareArPriceSetting];

  const getFormData = () => {
    const {
      price_arg_and_op,
      compare_at_price_arg_and_op,
      price_assign_cents_arg,
      compare_at_price_assign_cents_arg,
      ...rest
    } = form.getFieldsValue();
    const values = {
      ...rest,
      price_arg: price_arg_and_op.price_arg || 0,
      price_op: price_arg_and_op.price_op,
      price_assign_cents_arg: Number(`${price_assign_cents_arg || ''}`.padEnd(2, '0')),
      compare_at_price_arg: compare_at_price_arg_and_op.price_arg || 0,
      compare_at_price_op: compare_at_price_arg_and_op.price_op,
      compare_at_price_assign_cents_arg: Number(`${compare_at_price_assign_cents_arg || ''}`.padEnd(2, '0')),
    };
    return values;
  };

  const updateDefaultSellPrice = (values?: any) => {
    if (!values) return;
    const {
      price_arg,
      price_op,
      price_enabled,
      price_assign_cents_enabled,
      price_assign_cents_arg,
      compare_at_price_enabled,
      compare_at_price_arg,
      compare_at_price_op,
      compare_at_price_assign_cents_enabled,
      compare_at_price_assign_cents_arg,
    } = values;
    setDefaultSellPrice(
      getDefaultSellPrice(DEMO_ALL_IN_COST, {
        price_enabled,
        price_arg,
        price_op,
        price_assign_cents_enabled,
        price_assign_cents_arg,
      })
    );
    setDefaultCompareAtSellPrice(
      getDefaultSellPrice(DEMO_ALL_IN_COST, {
        price_enabled: compare_at_price_enabled,
        price_arg: compare_at_price_arg,
        price_op: compare_at_price_op,
        price_assign_cents_enabled: compare_at_price_assign_cents_enabled,
        price_assign_cents_arg: compare_at_price_assign_cents_arg,
      })
    );
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const {
      price_arg_and_op,
      compare_at_price_arg_and_op,
      price_enabled,
      compare_at_price_enabled,
      price_assign_cents_enabled,
      compare_at_price_assign_cents_enabled,
    } = allValues;

    if ('price_arg_and_op' in changedValues) {
      // price_op改变
      if (price_arg_and_op.price_op !== formRef?.current?.price_arg_and_op?.price_op) {
        form.setFieldsValue({
          price_arg_and_op: {
            price_arg: DEFAULT_PRICE_ARG_MAP[price_arg_and_op.price_op],
          },
        });
      }
    }

    if ('compare_at_price_arg_and_op' in changedValues) {
      // price_op改变
      if (compare_at_price_arg_and_op.price_op !== formRef?.current?.compare_at_price_arg_and_op?.price_op) {
        form.setFieldsValue({
          compare_at_price_arg_and_op: {
            price_arg: DEFAULT_PRICE_ARG_MAP[compare_at_price_arg_and_op.price_op],
          },
        });
      }
    }

    if ('price_enabled' in changedValues) {
      setPriceEnabled(price_enabled);
    }

    if ('compare_at_price_enabled' in changedValues) {
      setCompareAtPriceEnabled(compare_at_price_enabled);
    }

    if ('price_assign_cents_enabled' in changedValues) {
      setPriceAssignCentsEnabled(price_assign_cents_enabled);
    }

    if ('compare_at_price_assign_cents_enabled' in changedValues) {
      setCompareAtPriceAssignCentsEnabled(compare_at_price_assign_cents_enabled);
    }
    // 由于前面的几个表单项变化都会触发此操作，故直接放到当前位置
    setCurPriceOp(form.getFieldValue('price_arg_and_op').price_op);
    setCurCompareAtPriceOp(form.getFieldValue('compare_at_price_arg_and_op').price_op);
    updateDefaultSellPrice(getFormData());
    setSaveBtnDisabled(false);
    formRef.current = allValues;
  };

  const handleFinish = () => {
    updatePriceRules.mutate(getFormData(), {
      onSettled: (d, e: Error) => {
        if (e) {
          message.error(e?.message || `Sorry, we weren't able to save your changes. Please try again later.`);
        } else {
          message.success('Changes Saved!');
        }
        setSaveBtnDisabled(true);
      },
    });
  };

  const handleSave = () => {
    handleFinish();
  };

  const normalizeBooleanToNumber = (value: boolean) => Number(value);

  const renderItem = (setting) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { priceEnabled, priceArgAndOp, priceAssignCentsEnabled, priceAssignCentsArg, params } = setting;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { curPriceOp, title, titleHint, assignCentsHint, sellPrice } = params;
    const value = form.getFieldValue(priceArgAndOp.name);
    const curPriceArg = Number(value?.price_arg);
    const curOp = OPTIONS.find((i) => i.value === value?.price_op)?.op || 'x';
    const expressions =
      curPriceOp === PriceRuleType.Margin
        ? `(${DEMO_ALL_IN_COST} ${curOp} (${`100% - ${curPriceArg}%`})  = ${sellPrice})`
        : `(${DEMO_ALL_IN_COST} ${curOp} ${curPriceArg}  = ${sellPrice})`;

    return (
      <div className={css.form_item_section}>
        <div className={cn(css.header, css.main)}>
          <div className={css.left}>
            <div className={cn(css.form_item_section_title, css.padding)}>{title}</div>
            <Tooltip
              overlayClassName={css.ns_or_page_price_rule_mobile_popover}
              color="white"
              title={<span>{titleHint}</span>}
              placement="bottom"
              trigger="hover"
            >
              <div className={cn(css.hint, css.padding)}>
                <FiAlertCircle />
              </div>
            </Tooltip>
          </div>
          <div className={css.right}>
            <Form.Item name={priceEnabled.name} valuePropName="checked" noStyle normalize={normalizeBooleanToNumber}>
              <Switch />
            </Form.Item>
          </div>
        </div>
        <div className={css.body}>
          <div className={css.main}>
            <div className={css.left}>
              <div className={css.text}>Product cost</div>
              <span className={css.plus_or_x_wrapper}>
                <PriceRuleCharacter type={curPriceOp} />
              </span>
            </div>
            <div className={css.right}>
              <Form.Item name={priceArgAndOp.name} noStyle>
                <PriceRuleArgAndOp disabled={priceArgAndOp.disabled} className={css.form_item_wrapper} />
              </Form.Item>
            </div>
          </div>
          <div className={css.desc}>
            {priceAssignCentsArg.disabled
              ? ` If the Product cost is at ${DEMO_ALL_IN_COST}, the default Sell at Price will be set to ${sellPrice} ${expressions} at import.`
              : ` If the Product cost is at ${DEMO_ALL_IN_COST}, the default Sell at Price will be set to ${sellPrice} at import.`}
          </div>
        </div>
        <div className={cn(css.footer, css.main)}>
          <div className={css.left}>
            <Form.Item
              name={priceAssignCentsEnabled.name}
              noStyle
              valuePropName="checked"
              normalize={normalizeBooleanToNumber}
            >
              <Checkbox disabled={priceAssignCentsEnabled.disabled} />
            </Form.Item>
            <span className={cn(css.assign_cents_wrapper_title, css.text)}>Assign Cents</span>
            <div className={css.hint_wrapper}>
              <Tooltip
                overlayClassName={css.ns_or_page_price_rule_mobile_popover}
                color="white"
                title={<span>{assignCentsHint}</span>}
                placement="bottom"
                trigger="hover"
              >
                <FiAlertCircle />
              </Tooltip>
            </div>
          </div>
          {!priceAssignCentsArg.disabled && (
            <div className={css.right}>
              <div className={css.price_demo_wrapper}>$XX.</div>
              <Form.Item name={priceAssignCentsArg.name} noStyle>
                <InputNumber
                  className={css.price_assign_cents_arg}
                  disabled={priceAssignCentsArg.disabled}
                  placeholder="99"
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setCurPriceOp(priceRulesData.price_arg_and_op.price_op);
    setCurCompareAtPriceOp(priceRulesData.compare_at_price_arg_and_op.price_op);
    setPriceEnabled(priceRulesData.price_enabled);
    setCompareAtPriceEnabled(priceRulesData.compare_at_price_enabled);
    setPriceAssignCentsEnabled(priceRulesData.price_assign_cents_enabled);
    setCompareAtPriceAssignCentsEnabled(priceRulesData.compare_at_price_assign_cents_enabled);
    updateDefaultSellPrice(priceRulesData);
    form.setFieldsValue(priceRulesData);
  }, [form, priceRulesData]);

  return (
    <div className={css.ns_page_price_rule_mobile_main}>
      <Helmet>
        <html lang="en" className={css.ns_page_price_rule_mobile_main_active} />
        <title>Price Rules</title>
      </Helmet>

      <div>
        <SecondaryNavBar>Price Rules</SecondaryNavBar>
        <StoreSelect className={css.store_select} redirectPath="/price-rules/:shopId" />
        <StatusViewRenderer isEmpty={false} isLoading={priceRules.isLoading} statusStyle={{ marginTop: 64 }}>
          <Form
            className={css.form_wrapper}
            form={form}
            name="priceRulesForm"
            size="large"
            preserve
            initialValues={priceRulesData}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
          >
            {settings.map((setting) => renderItem(setting))}
            <div className={css.save_btn_wrapper}>
              <Button
                color="primary"
                shape="rounded"
                block
                loading={updatePriceRules.isLoading}
                loadingText="Saving"
                onClick={handleSave}
                disabled={saveBtnDisabled}
              >
                Save
              </Button>
            </div>
          </Form>
        </StatusViewRenderer>
      </div>
      <Prompt when={!saveBtnDisabled} />
    </div>
  );
}

export default PriceRules;
