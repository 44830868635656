import { Card, message } from 'antd';
import { useRef, useState } from 'react';
import Helmet from 'react-helmet';

import Button from '@/components/Button';
import Modal from '@/components/Modal';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import { useAuth } from '@/hooks/auth';
import useSendEmailCode from '@/hooks/queries/useSendEmailCode';

import ArrowButton from './ArrowButton';
import css from './index.module.scss';
import type { ComponentHandle as ModalChangePhoneHandle } from './ModalChangePhone';
import ModalChangePhone from './ModalChangePhone';
import type { ComponentHandle as ModalEditNameComponentHandle } from './ModalEditName';
import ModalEditName from './ModalEditName';
import type { ComponentHandle as ModalUpdateEmailHandle } from './ModalUpdateEmail';
import ModalUpdateEmail from './ModalUpdateEmail';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const authScenario = {
  resetPassword: 'reset-password',
};

function AccountProfile(): JSX.Element {
  const { user } = useAuth();
  const authEmailSendCode = useSendEmailCode();
  const modalEditNameRef = useRef<ModalEditNameComponentHandle>(null);
  const modalChangePhoneRef = useRef<ModalChangePhoneHandle>(null);
  const modalUpdateEmailRef = useRef<ModalUpdateEmailHandle>(null);

  const [emailUpdatePasswordVisible, setEmailUpdatePasswordVisible] = useState(false);

  const username = user?.first_name || user?.last_name ? `${user?.first_name || ''} ${user?.last_name || ''}` : null;
  const phone = `${user?.phone_country || ''}${user?.phone || ''}`;
  const email = user?.email || user?.email_pending || null;

  const isEmailLoginEnabled = !!user?.has_password;

  const openEditName = () => {
    modalEditNameRef.current.show();
  };
  const openChangePhoneNumberModal = () => {
    modalChangePhoneRef.current.show();
  };
  const openChangeEmailModal = () => {
    modalUpdateEmailRef.current.show();
  };
  const openEmailLoginModal = () => {
    setEmailUpdatePasswordVisible(true);
  };
  const hideEmailLoginModal = () => {
    setEmailUpdatePasswordVisible(false);
  };
  const submitEmailUpdatePassword = () => {
    const values = {
      scenario: authScenario.resetPassword,
      email,
      type: 'link',
    };
    authEmailSendCode.mutate(values, {
      onSuccess() {
        openEmailLoginModal();
      },
      onError(e: Error) {
        message.error(e.message);
      },
    });
  };

  const [emailLoginEnabledSection, emailLoginNotEnabledSection] = [
    {
      buttonLabel: 'Email Password Login',
      buttonLabelDesc: 'Change the password to your email login here',
      buttonText: 'Reset Password',
      onClick: submitEmailUpdatePassword,
      modalTitle: 'Reset Instructions Sent!',
      modalBody: `We sent instructions to reset your password to ${email}. Please check both your inbox and spam folder.`,
    },
    {
      buttonLabel: 'Email Password Login',
      buttonLabelDesc: 'Add a password to enable logging in with your email above. ',
      buttonText: 'Set up',
      onClick: submitEmailUpdatePassword,
      modalTitle: 'Setup Instructions Sent!',
      modalBody: `We sent instructions to set a password to ${email}. Please check both your inbox and spam folder.`,
    },
  ];
  const [nameExistSection, nameNotExistSection] = [
    {
      label: username,
      buttonText: 'Edit',
    },
    {
      label: 'No Name',
      buttonText: 'Add',
    },
  ];
  const [emailExistSection, emailNotExistSection] = [
    {
      label: email,
      buttonText: 'Change',
    },
    {
      label: 'No Email',
      buttonText: 'Add',
    },
  ];
  const emailUpdatePasswordSection = isEmailLoginEnabled ? emailLoginEnabledSection : emailLoginNotEnabledSection;
  const nameSection = username ? nameExistSection : nameNotExistSection;
  const emailSection = email ? emailExistSection : emailNotExistSection;

  return (
    <div className={css.account_profile_page}>
      <Helmet>
        <html lang="en" className={css.account_profile_page_active} />
        <title>Edit Profile</title>
      </Helmet>

      <SecondaryNavBar className={css.secondary_nav_bar}>Edit Profile</SecondaryNavBar>

      <div className={css.page_content}>
        <Card title="Name" bordered={false}>
          <ArrowButton label={nameSection.label} buttonText={nameSection.buttonText} onClick={openEditName} />
        </Card>
        <Card title="Phone Number" bordered={false}>
          <ArrowButton label={phone} buttonText="Change" onClick={openChangePhoneNumberModal} />
          <div className={css.desc}>This is your primary login method. We’ll only send login verification codes. </div>
        </Card>
        <Card title="Email" bordered={false} className={css.email}>
          <section className={css.section}>
            <ArrowButton
              label={emailSection.label}
              buttonText={emailSection.buttonText}
              onClick={openChangeEmailModal}
            />
            <div className={css.desc}>
              We’ll send you order updates, product changes, and other support updates to this inbox.
            </div>
          </section>
          {/* only email exist (not in pending), can not set up | reset password */}
          {!!user?.email && (
            <section className={css.section}>
              <ArrowButton
                label={emailUpdatePasswordSection.buttonLabel}
                buttonText={emailUpdatePasswordSection.buttonText}
                onClick={emailUpdatePasswordSection.onClick}
              />
              <div className={css.desc}>{emailUpdatePasswordSection.buttonLabelDesc}</div>
            </section>
          )}
        </Card>
      </div>
      <ModalEditName ref={modalEditNameRef} />
      <ModalChangePhone ref={modalChangePhoneRef} />
      <ModalUpdateEmail ref={modalUpdateEmailRef} />
      {/* 此弹窗用于两个场景：1: set up password 2: reset password */}
      <Modal
        visible={emailUpdatePasswordVisible}
        onClose={hideEmailLoginModal}
        title={emailUpdatePasswordSection.modalTitle}
        className={css.modal_email_login}
      >
        <div className={css.center}>
          <div className={css.desc}>{emailUpdatePasswordSection.modalBody}</div>
          <Button color="main" shape="rounded" onClick={hideEmailLoginModal}>
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AccountProfile;
