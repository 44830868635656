import { useContext } from 'react';

import AppContext from '@/appContext';

type Props = {
  children: (openDialog: () => void) => React.ReactNode;
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
  collectionHandle?: string;
  products?: { [key: string]: any; id: string }[];
};

const ProductsToLikeBoardButton = ({
  products,
  children,
  collectionHandle,
  onSuccessCallback,
  onErrorCallback,
}: Props) => {
  const { showModalSelectBoard } = useContext(AppContext);

  const openLikeBoardDialog = () => {
    const config = {
      products,
      collectionHandle,
      onSuccessCallback,
      onErrorCallback,
    };

    showModalSelectBoard(config);
  };

  return <>{children(openLikeBoardDialog)}</>;
};

export default ProductsToLikeBoardButton;
