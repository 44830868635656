import { Form, FormInstance, InputNumber } from 'antd';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import set from 'lodash/set';
import size from 'lodash/size';
import values from 'lodash/values';
import React, { FC, memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Image from '@/components/Image';
import { CategoryForRetailListItemResponse } from '@/services/jennifer';

import { calculateText, DEFAULT_OPTIONS_PRICE_COST } from '../../def';
import { useCatalogRetailStore } from '../../store';
import css from './index.module.scss';

export interface EditProductListProps {
  className?: string;
  style?: React.CSSProperties;
  data?: CategoryForRetailListItemResponse[];
  form: FormInstance<any>;
}

export interface EditProductListItemProps {
  className?: string;
  style?: React.CSSProperties;
  data?: CategoryForRetailListItemResponse;
  form: FormInstance<any>;
  itemIndex: number;
}

function getVariantsPricesGroupTitle(variants: any[]) {
  const firstVariant = variants?.[0];
  const title = compact([firstVariant?.option1, firstVariant?.option2, firstVariant?.option3]).join(' / ');

  return variants?.length > 1 ? `${variants?.length} Variants` : title;
}

export const EditProductListItem: FC<EditProductListItemProps> = memo(({ className, style, data, form, itemIndex }) => {
  const [isDangerValue, setDangerValue] = useState<boolean>(false);

  const navigate = useNavigate();
  const calculateDep = useCatalogRetailStore((state) => state.calculateDep);

  const priceGroupedVariants = useMemo(() => groupBy(data?.variants, 'price'), [data?.variants]);

  const isSinglePrice = useMemo(() => size(priceGroupedVariants) <= 1, [priceGroupedVariants]);

  const formFieldBasePath = useMemo(() => ['products', itemIndex], [itemIndex]);

  const navigateToProductDetail = () => {
    navigate(`/products/${data?.id}`);
  };

  return (
    <div className={cn(css.product_item, className)} style={style}>
      <div className={css.cover} onClick={navigateToProductDetail}>
        <Image className={css.image} src={data?.cover} fit="cover" lazy />
      </div>
      <div className={css.info}>
        <div className={css.info_title} onClick={navigateToProductDetail}>
          {data?.title}
        </div>
        <div className={css.variants_prices}>
          <Form.Item name={[...formFieldBasePath, 'id']} noStyle hidden initialValue={Number(data?.id)}>
            <input type="hidden" />
          </Form.Item>

          {map(values(priceGroupedVariants), (variants, index: number) => {
            const shopifyPrice = variants?.[0]?.original_price;
            const shopifyCostPrice = variants?.[0]?.cost_price;

            const currentPrice = variants?.[0]?.price;
            const groupBasePath = ['variants', index];

            const pricePath = [...formFieldBasePath, ...groupBasePath, 'price'];

            return (
              <div key={shopifyPrice} className={css.group}>
                <div className={css.group_title}>{!isSinglePrice ? getVariantsPricesGroupTitle(variants) : null}</div>
                <div className={css.price_columns}>
                  {/* label col */}
                  <div className={css.price_columns_item}>
                    <div className={css.cost_wrapper}>
                      <div className={css.price_columns_item_title}>Shopify Price</div>
                      <div className={css.price}>${shopifyPrice?.toFixed(2) || 0}</div>
                    </div>
                    <div className={css.cost_wrapper}>
                      <div className={css.price_columns_item_title}>Shopify Cost</div>
                      <div
                        className={cn(
                          css.price,
                          calculateDep === DEFAULT_OPTIONS_PRICE_COST && !shopifyCostPrice ? css.text_red : ''
                        )}
                      >
                        ${shopifyCostPrice?.toFixed(2) || 0}
                      </div>
                    </div>
                  </div>

                  {/* input col */}
                  <div className={css.price_columns_item}>
                    <div className={css.input_wrapper}>
                      <div className={css.price_columns_item_title}>Kiwi Price</div>
                      <div className={cn(css.price_columns_item_price)}>
                        <Form.Item name={pricePath} noStyle initialValue={currentPrice}>
                          <InputNumber<number>
                            status={isDangerValue && calculateDep === DEFAULT_OPTIONS_PRICE_COST ? 'error' : ''}
                            controls={false}
                            min={0}
                            precision={2}
                            formatter={(value, { userTyping }) => {
                              if (Number(value) === 0) {
                                setDangerValue(true);
                              }
                              return `$${userTyping ? value : Number(value).toFixed(2)}`;
                            }}
                            parser={(value) => Number(value.replace(/\$/g, ''))}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[...formFieldBasePath, ...groupBasePath, 'id']}
                          noStyle
                          hidden
                          initialValue={map(variants, (v) => v.id)}
                        >
                          <input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name={[...formFieldBasePath, ...groupBasePath, 'shopifyPrice']}
                          noStyle
                          hidden
                          initialValue={shopifyPrice}
                        >
                          <input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          name={[...formFieldBasePath, ...groupBasePath, 'shopifyCostPrice']}
                          noStyle
                          hidden
                          initialValue={shopifyCostPrice}
                        >
                          <input type="hidden" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={css.input_wrapper}>
                      <div className={css.price_columns_item_title}>% of {calculateText[calculateDep]}</div>
                      <div className={css.price_columns_item_price}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            get(prevValues, pricePath) !== get(currentValues, pricePath)
                          }
                        >
                          {() => {
                            const depPrice = calculateDep === 'price' ? shopifyPrice : shopifyCostPrice;
                            const price = form.getFieldValue(pricePath);
                            const val = depPrice ? Number(((price / depPrice) * 100).toFixed(0)) : 0;

                            return (
                              <InputNumber<number>
                                controls={false}
                                precision={0}
                                formatter={(value) => `${value}%`}
                                parser={(value) => Number(value.replace(/%/g, ''))}
                                value={val}
                                onChange={(value) => {
                                  const cacheValues = cloneDeep(form.getFieldsValue(true));
                                  set(cacheValues, pricePath, Number(((depPrice * value) / 100).toFixed(2)));

                                  form.setFieldsValue(cacheValues);
                                }}
                              />
                            );
                          }}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export const EditProductList: FC<EditProductListProps> = ({ className, style, data, form }) => (
  <div className={cn(css.ns_com_import_product_list_main, className)} style={style}>
    {data?.map((item: any, index: number) => (
      <EditProductListItem key={item?.id} data={item} itemIndex={index} form={form} />
    ))}
  </div>
);
