import { Avatar, Divider, Popover } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { FiHeart, FiUser } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import AccessControl from '@/components/AccessControl';
import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import { useAuth } from '@/hooks/auth';
import useStores from '@/hooks/queries/useStores';

import { SubRetailerMenu } from './components/SubRetailerMenu';
import { SubSupplierMenu } from './components/SubSupplierMenu';
import css from './index.module.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function AccountMenu({ className, style }: Props): JSX.Element {
  const location = useLocation();
  const { user, logout } = useAuth();
  const stores = useStores(_.isEmpty(user));
  const isLogin = !_.isEmpty(user);
  const storesData = stores?.data?.data?.shops;

  const displayName =
    ((user?.first_name || user?.last_name) && `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim()) ||
    user?.phone;

  const popoverContent = (
    <AccessControl requireStore>
      {({ isReject: isNoStore, showRedirectDialog }) => {
        const handleNoStoreClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          showRedirectDialog?.();
        };
        return (
          <div className={css.content_wrapper}>
            <div className={css.header_wrapper}>
              <AutoLink to="/account/profile">
                <div className={css.body}>
                  <Avatar className={css.account_avatar} size={50} icon={<FiUser />} src={(user as any)?.avatar} />
                  <div className={cn(css.account_info)}>
                    <div className={css.account_name}>{displayName}</div>
                    <div className={css.account_phone}>{`${user?.phone_country ?? ''}${user?.phone ?? ''}`}</div>
                  </div>
                </div>
              </AutoLink>
            </div>
            <div className={css.body_wrapper}>
              <AutoLink to="/orders" state={{ backUrl: `${location.pathname}${location.search}` }}>
                My Orders
              </AutoLink>
              <AutoLink to="/private-inv">My Inventory</AutoLink>
              <AutoLink to="/seller-collections">
                <FiHeart /> Boards
              </AutoLink>
              <AutoLink to="/kiwi-wallet">Kiwi Wallet</AutoLink>
              <AutoLink to="/payment-methods">Payment Methods</AutoLink>
              <AutoLink to="/addresses">Shipping Addresses</AutoLink>
              {storesData?.map((store: any) => (
                <Fragment key={store?.id}>
                  <Divider />
                  <div className={css.submenu_title}>{store?.name || store?.shopify_domain}</div>
                  <div className={css.submenu}>
                    {/* As a Retailer menus */}
                    <SubRetailerMenu store={store} isNoStore={isNoStore} callback={handleNoStoreClick} />
                    {/* new add menu: As a supplier menus */}
                    <SubSupplierMenu store={store} />
                  </div>
                </Fragment>
              ))}
            </div>
            <div className={css.footer}>
              <AutoLink to="/stores">{_.isEmpty(storesData) ? '+ Connect a store' : 'Manage stores'}</AutoLink>
              <AutoLink to="/referral-code">Referral Code</AutoLink>
              <AutoLink to="https://help.kiwidrop.com/knowledge">Help Center</AutoLink>
              <Divider />
              <div className={css.menu_item} onClick={() => logout()}>
                Log Out
              </div>
            </div>
          </div>
        );
      }}
    </AccessControl>
  );

  return (
    <div className={cn(css.ns_com_account_menu_main, className)} style={style}>
      {isLogin ? (
        <Popover
          overlayClassName={css.popover}
          title={false}
          content={popoverContent}
          placement="bottom"
          arrowPointAtCenter
          getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
        >
          <div className={css.account_btn}>
            <FiUser />
            <span>Account</span>
          </div>
        </Popover>
      ) : (
        <Button
          style={{ width: 100 }}
          color="primary"
          shape="rounded"
          link={`/login?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
        >
          Log In
        </Button>
      )}
    </div>
  );
}

export default AccountMenu;
