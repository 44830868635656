.ns_com_product_filters_bar_main {
  position: relative;
  margin: 0;

  .sort_icon {
    color: #000;
  }
  .title {
    display: none;
    color: var(--color-black);
    font-size: 20px;
    line-height: 1.15;
    font-weight: 500;
    border-bottom: 1px solid #ebebeb;
  }

  .items {
    padding: var(--spacing-l) 0;
    padding-bottom: var(--spacing-l);
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > * + * {
        margin-left: var(--spacing-m);
      }
    }
  }

  .select {
    &:global(.ant-select-focused:not(.ant-select-disabled)) {
      :global {
        .ant-select-selector {
          box-shadow: none;
        }
      }
    }
    :global {
      .ant-select-selector {
        font-size: var(--font-size2);
        border-color: transparent !important;
        border-radius: 6px !important;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
        transition: none !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
      .ant-select-selection-placeholder {
        color: var(--color-black);
        font-weight: 500;
      }

      .ant-select-arrow {
        color: var(--color-black);
      }
      .ant-select-clear {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0;
        padding-right: var(--spacing-m);
        right: 0;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        color: var(--color-white);
        opacity: 1;
        background: transparent;

        .react-icons {
          font-size: 18px;
        }
      }
    }
    &.active {
      :global {
        .ant-select-selector {
          color: var(--color-white);
          background: var(--color-gray5) !important;
        }
        .ant-select-arrow {
          color: var(--color-white);
          opacity: 0;
        }
      }
    }
    &.sort.active {
      :global {
        .ant-select-selection-item {
          padding-right: 18px; // 由于sort默认情况下不展示下拉箭头，导致选中某项后，删除按钮会覆盖选中内容
        }
      }
    }
  }
  @at-root .ns_or_dropdown {
    &:global(.ant-select-dropdown) {
      border-radius: 4px;
      padding: 6px 0;
      :global {
        .ant-select-item,
        .ant-select-item-empty {
          padding: 6px 12px;
        }
      }
    }
    :global {
      // because of limited options，no need to show scroll
      .rc-virtual-list-holder {
        max-height: 300px !important;
      }
      .ant-select-item-group {
        padding: 5px 0;
        margin: 0 12px;
        color: var(--color-gray4);
        font-size: var(--font-size3);
      }

      .ant-select-item-option-grouped {
        padding-left: 12px;
      }

      .ant-select-item-option-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--color-black);
        font-size: var(--font-size3);
      }
    }
  }

  @media screen and (min-width: 769px) {
    border-top: none;

    .title:not(:empty) {
      display: block;
      margin-bottom: var(--spacing-l);
    }

    .items {
      padding-top: var(--spacing-xxl);
      padding-bottom: var(--spacing-xxl);

      & > * + * {
        margin-left: var(--spacing-xxl);
      }
    }

    .select {
      :global {
        .ant-select-selector {
          font-size: var(--font-size3);
        }
      }
    }
  }
}
