import { useMutation, useQueryClient } from 'react-query';

import { updateUserCredit } from '@/services/ds';

export default function useUpdateUserCredit() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof updateUserCredit>[0]) => updateUserCredit(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('getUserCredit');
    },
  });
}
