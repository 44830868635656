import { useMemo } from 'react';
import { useMutation } from 'react-query';

import { deactivateCategoryForRetail } from '@/services/jennifer';

export const useDeactivateCatalogProduct = () => {
  const mutation = useMutation(deactivateCategoryForRetail, {
    // onSuccess() {
    //   console.log('useUpdateCatalogProduct mutation success');
    //   hideEditStateModal();
    //   showConfirmStateModal();
    // },
    // onError(error) {
    //   message.error(error || 'Unknown Error');
    // },
  });

  return useMemo(
    () => ({
      mutation,
    }),
    [mutation]
  );
};
