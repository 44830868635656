import { Checkbox as AntCheckbox } from 'antd-mobile';
import type { CheckboxValue } from 'antd-mobile/es/components/checkbox';
import cn from 'classnames';
import React from 'react';
import {
  IoCheckbox,
  IoCheckmarkCircleSharp,
  IoEllipseOutline,
  IoEllipseSharp,
  IoSquare,
  IoSquareOutline,
} from 'react-icons/io5';

import css from './index.module.scss';

export type CheckboxGroupProps = React.ComponentProps<typeof AntCheckbox.Group>;
export type CheckboxProps = React.ComponentProps<typeof AntCheckbox> & {
  color?: 'main' | 'primary';
  shape?: 'circle' | 'square' | 'square_line';
};
export type { CheckboxValue };

function Checkbox({
  className,
  children,
  color,
  shape = 'square',
  disabled,
  ...restProps
}: CheckboxProps): JSX.Element {
  const renderIcon = (isChecked) => {
    if (shape === 'square') {
      return isChecked ? <IoCheckbox /> : <IoSquare />;
    }
    if (shape === 'circle') {
      if (disabled) {
        return (
          <span className={cn(css.shape_circle, css.disabled)}>
            <IoEllipseSharp />
          </span>
        );
      }
      return (
        <span className={cn(css.shape_circle, { [css.checked]: isChecked })}>
          {isChecked ? <IoCheckmarkCircleSharp /> : <IoEllipseOutline />}
        </span>
      );
    }
    if (shape === 'square_line') {
      if (disabled) {
        return (
          <span className={cn(css.shape_circle, css.disabled)}>
            <IoSquare />
          </span>
        );
      }
      return (
        <span className={cn(css.shape_circle, { [css.checked]: isChecked })}>
          {isChecked ? <IoCheckbox /> : <IoSquareOutline />}
        </span>
      );
    }
    return null;
  };

  return (
    /**
     * 保留 checkbox 是因为在下面位置有样式重写
     * components/EmailUpdateDialog/index.module.scss
     *    .ns_com_email_update_dialog_main .checkbox
     */
    <AntCheckbox
      className={cn(css.ns_com_checkbox_main, 'checkbox', className, { [css.primary]: color === 'primary' })}
      icon={renderIcon}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </AntCheckbox>
  );
}

Checkbox.Group = AntCheckbox.Group;

export default Checkbox;
