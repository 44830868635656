.order_product_item {
  display: flex;
  padding: 12px;
  &:not(:first-child) {
    padding-top: 0;
  }
  .img {
    cursor: pointer;
    width: 68px;
    height: 90px;
    border-radius: 4px;
    position: relative;
    .item_images_tag {
      background-color: #ffffffbf;
      font-size: 12px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
      &.sold_out {
        color: red;
      }
      &.over_buy {
        color: #000;
      }
    }

    :global {
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
  .info {
    flex: 1;
    padding-left: 8px;
  }
  .info_title {
    display: -webkit-box;
    max-height: 2.6em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-black);
    font-size: var(--font-size2);
    line-height: 1.3em;
    cursor: pointer;
  }
  .info_sku {
    color: var(--color-gray3);
    font-size: var(--section-text-font-size);
    margin-top: 4px;
  }
  .info_desc {
    display: flex;
    justify-content: space-between;
    font-size: var(--section-text-font-size);
    .sku {
      color: var(--color-gray4);
    }
    .price {
      color: #424242;
    }
  }
  .info_fulfill_label {
    display: flex;
  }
  .info_preorder_tag {
    font-size: 9px;
    line-height: 1;
    font-weight: 500;
    padding: 2px;
    border-radius: 2px;
    word-break: keep-all;
    white-space: nowrap;
    color: #fff;
    background-color: #000;
    width: fit-content;
    margin-top: 4px;
    display: inline-block;
  }
}
