.ns_com_back_to_last_page_button_wrapper {
  &:global(.adm-button) {
    :global {
      padding: 0;
      .icon {
        font-size: initial;
      }
    }
  }
}
