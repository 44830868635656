.ns_com_collection_preorder_wrapper {
  .collection_preorder_header {
    .bg_img_wrapper {
      overflow: hidden;
      .img {
        width: 100%;
      }
    }
  }
}
