const CART_SECTION_TYPE = {
  normal: 'normal',
  soldout: 'soldout',
};

const CHECK_PRODUCT_SELECTED = 'Please select at least one product';

const variantsM = {
  show: {
    y: '0%',
    transition: { from: '100%', type: 'spring', stiffness: 300, damping: 30 },
  },
  hide: {
    y: '100%',
    transition: { from: '0%', type: 'spring', stiffness: 400, damping: 30 },
  },
};

export { CART_SECTION_TYPE, CHECK_PRODUCT_SELECTED, variantsM };
