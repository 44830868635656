import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { FulfillLabelGray } from '@/components/FulfillLabel';
import Image from '@/components/Image';
import type { LineItem as OrderLineItem, OrderStatus } from '@/pages/Orders/types';
import { checkIsOverBuy, checkIsSoldOut, showOrderSoldOutOrOverBuyTag } from '@/utils/biz';
import { formatterPrice } from '@/utils/util';

import css from './OrderProduct.module.scss';

const OrderProduct = ({ data, status }: { data: OrderLineItem; status?: OrderStatus }) => {
  const navigate = useNavigate();
  const { title, variant_title, price, image, qty, sku, available_quantity } = data;
  const isSoldOut = checkIsSoldOut(available_quantity);
  const isOverBuy = checkIsOverBuy(qty, available_quantity);
  let tagText = '';
  if (isSoldOut) tagText = 'Sold Out';
  if (isOverBuy) tagText = `Only ${available_quantity}`;
  const showTag = showOrderSoldOutOrOverBuyTag(status) && !!tagText;

  const navigateToProductDetail = () => {
    navigate(`/products/${data?.product_id}?variant=${data?.variant_id}`);
  };

  return (
    <div className={css.order_product_item}>
      <div className={css.img} onClick={navigateToProductDetail}>
        <Image src={image?.src} fit="cover" lazy />
        {showTag && (
          <div
            className={cn(css.item_images_tag, {
              [css.sold_out]: isSoldOut,
              [css.over_buy]: isOverBuy,
            })}
          >
            {tagText}
          </div>
        )}
      </div>
      <div className={css.info}>
        <div className={css.info_title} onClick={navigateToProductDetail}>
          {title}
        </div>
        <div className={css.info_sku}>{sku}</div>
        <div className={css.info_desc}>
          <span className={css.sku}>
            <span>{variant_title}</span>
            <span>&nbsp;&nbsp;x{qty}</span>
          </span>
          <span className={css.price}>${formatterPrice(price)}</span>
        </div>
        <div className={css.info_fulfill_label}>
          <FulfillLabelGray product={data} />
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
