import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { updatePaymentMethodResult } from '@/services/ds';

export default function useUpdatePaymentMethodResult() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof updatePaymentMethodResult>[0]) => updatePaymentMethodResult(params), {
    onSuccess: (data) => {
      queryClient.setQueryData('paymentMethods', (prevData: any) =>
        produce(prevData, (draft: any) => {
          if (data?.data?.is_default) {
            const prevDefaultPaymentMethod = _.find(draft?.data?.payment_methods, { is_default: true });
            if (prevDefaultPaymentMethod) prevDefaultPaymentMethod.is_default = false;
          }

          draft?.data?.payment_methods?.push(data?.data);
        })
      );

      queryClient.invalidateQueries('paymentMethods');
    },
  });
}
