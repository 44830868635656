.ns_page_price_rule_pc_main {
  .title {
    font-size: 36px;
    padding: 0 0 16px;
  }
  .content_wrapper {
    margin: 0 auto;
    padding: 50px 0;
    max-width: calc(var(--content-small-max-width) + var(--spacing-l) * 2);
    .form_wrapper {
      color: var(--color-gray5);
      font-size: 16px;
      line-height: 1.4;
    }
    .form_item_section {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
      background-color: #fff;
      border-radius: 4px;
      margin-top: 16px;
      .header {
        border-bottom: 1px solid #e8e8e8;
        padding: 0 10px;
        display: flex;
        align-items: center;
        &.padding {
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .form_item_section_title {
          padding-left: 12px;
          font-size: 16px;
          font-weight: 500;
          color: #000;
        }
        .hint {
          color: #c7c7c7;
          align-items: center;
          display: flex;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      .body {
        padding: 12px 10px;
        .main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text {
            color: #000;
            font-weight: 500;
          }
          .cost_wrapper {
            display: flex;
            align-items: center;
            .plus_or_x_wrapper {
              padding: 0 12px;
            }
            .checkbox_wrapper {
              padding: 0 12px 0 18px;
            }
          }
          .assign_cents_wrapper {
            display: flex;
            align-items: center;
            padding-left: 18px;
            .assign_cents_wrapper_title {
              padding-left: 12px;
            }
            .hint_wrapper {
              color: #c7c7c7;
              height: 20px;
              line-height: 22px;
              padding: 0 12px 0 6px;
            }
            .price_demo_wrapper {
              padding-right: 6px;
              font-size: 16px;
              color: #333;
            }
            .price_assign_cents_arg {
              width: 50px;
            }
            :global {
              .ant-input-number {
                width: 60px;
              }
            }
          }
        }
        .desc {
          padding-top: 10px;
          font-size: 14px;
          color: var(--color-gray4);
          height: 40px;
          line-height: 18px;
        }
      }
    }
    .save_btn_wrapper {
      margin: var(--spacing-l) auto;
      width: 250px;
    }
  }
}
.ns_page_price_rule_pc_main_active {
  .ns_or_page_price_rule_pc_popover {
    max-width: 255px;
    :global {
      .ant-tooltip-inner {
        color: var(--color-gray5);
      }
    }
  }
  :global {
    .app-layout {
      @media screen and (min-width: 769px) {
        &__nav-bar {
          display: block;
        }

        &__tab-bar {
          display: none !important;
        }
      }
    }
  }
}
