import cn from 'classnames';

import lighting from '@/assets/images/lighting.svg';
import CountDown from '@/components/CountDown';
import useTimeStatus from '@/hooks/biz/useTimeStatus';

import css from './HotFlash.module.scss';

type Props = {
  setting?: any;
  className?: string;
  title?: string;
};

const HotFlash = ({ setting, className, title }: Props) => {
  const { desc } = useTimeStatus(setting?.begins_at, setting?.ends_at);

  const handleFinish = () => {};

  return (
    <div className={cn(css.ns_com_pdp_hot_flash_main, className)}>
      <div className={css.hot_flash_desc}>
        <img src={lighting} alt="" />
        {` `}
        {title} {desc}
      </div>
      <CountDown startTime={setting?.begins_at || Date.now()} endTime={setting?.ends_at} onFinish={handleFinish} />
    </div>
  );
};

export default HotFlash;
