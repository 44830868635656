import { Button, Col, Divider, Drawer, Form, Input, message, Row, Select } from 'antd';
import { useEffect, useMemo } from 'react';
import { FiX } from 'react-icons/fi';

import Modal from '@/components/Modal';
import { useDesktop } from '@/hooks';

import { calculateText, DEFAULT_OPTIONS, DEFAULT_OPTIONS_PRICE, percentField, priceTypeField } from '../../def';
import {
  hideEditPriceModal,
  setCalculateDep,
  setCatalogProductsDraft,
  showConfirmPriceModal,
  useCatalogRetailStore,
} from '../../store';
import { EditProductList } from '../editPriceList';
import styles from './index.module.scss';

const editorText = {
  title: 'Set Kiwi Price',
  secondary: 'Set Kiwi Price for All',
};

export const EditPriceModal = () => {
  const [form] = Form.useForm();

  const isDesktop = useDesktop();

  const calculateDep = useCatalogRetailStore((state) => state.calculateDep);
  const calculatePercent = useCatalogRetailStore((state) => state.calculatePercent);
  const isEditPriceModalVisible = useCatalogRetailStore((state) => state.isEditPriceModalVisible);
  const selectedProduct = useCatalogRetailStore((state) => state.selectedProduct);

  useEffect(() => {
    form.resetFields();
  }, [selectedProduct, form]);

  const totalVariantCounts = useMemo(
    () => selectedProduct.reduce((pre, crr) => pre + crr.variants.length, 0),
    [selectedProduct]
  );

  const handleApplyGlobalSetting = () => {
    const currentPercent = form.getFieldValue(percentField);
    const currentPriceType = form.getFieldValue(priceTypeField);

    if (!/^(-?\d+)(\.\d+)?$/g.test(currentPercent)) {
      form.getFieldError(percentField);
      message.warn('error');
      return;
    }
    const formDetail = form.getFieldValue('products');

    const result = formDetail.map((outer) => {
      const { variants } = outer;

      const newVariant = variants.map((inner) => {
        const priceCorrect = currentPriceType === DEFAULT_OPTIONS_PRICE ? inner.shopifyPrice : inner.shopifyCostPrice;
        priceCorrect * (currentPercent / 100);
        return {
          ...inner,
          price: priceCorrect * (currentPercent / 100),
        };
      });

      return {
        ...outer,
        variants: newVariant,
      };
    });

    form.setFieldsValue({ ...formDetail, products: result });

    setCalculateDep({
      target: currentPriceType,
      val: currentPercent,
    });
  };

  const handleFinish = async () => {
    const { products } = form.getFieldsValue(true);
    const params = products.map((outer) => {
      const { variants } = outer;
      const allVariants = [];

      variants.forEach((variant) => {
        const { id, price } = variant;
        const ids = id.map((idItem) => ({
          id: idItem,
          price: Number(Number(price).toFixed(2)),
        }));
        allVariants.push(...ids);
      });

      return {
        id: outer.id,
        variants: allVariants,
      };
    });

    setCatalogProductsDraft({ products: params });
    hideEditPriceModal();
    showConfirmPriceModal();
  };

  const content = (
    <div className={styles.priceContent}>
      <Form form={form}>
        <header className={styles.set_header}>
          <h1 className={styles.set_header_title}>{editorText.secondary}</h1>
          <div className={styles.set_header_secondary}>
            <h2>
              Set Kiwi Price for {selectedProduct.length} Products ({totalVariantCounts} Variants)
            </h2>
            <p>
              How to calculate: {calculateText[calculateDep] || 'Shopify Price'} * {calculatePercent || 0}% = Kiwi Price
            </p>
          </div>

          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 20 }} className={styles.set_header_filter}>
              <Form.Item name={priceTypeField} className={styles.inputWrapper} initialValue={calculateDep}>
                <Select size="middle" style={{ width: '100%' }} onChange={() => {}} options={DEFAULT_OPTIONS} />
              </Form.Item>
              <div>x</div>
              <Form.Item name={percentField} className={styles.inputWrapper} initialValue={calculatePercent}>
                <Input size="middle" suffix="%" />
              </Form.Item>
              <Form.Item className={styles.inputWrapper}>
                <Button type="primary" shape="round" block onClick={handleApplyGlobalSetting}>
                  Apply
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </header>
        <Divider />
        <div className={styles.modalBody}>
          <h1 className={styles.title}>Set Price Individually</h1>
          <div>
            <EditProductList form={form} data={selectedProduct} />
          </div>
        </div>
        <div
          style={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <Button block shape="round" type="primary" onClick={handleFinish}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );

  if (isDesktop) {
    return (
      <Modal title={editorText.title} onClose={hideEditPriceModal} visible={isEditPriceModalVisible}>
        {content}
      </Modal>
    );
  }

  return (
    <Drawer
      closable={false}
      title={editorText.title}
      placement="bottom"
      open={isEditPriceModalVisible}
      onClose={hideEditPriceModal}
      height={600}
      headerStyle={{
        textAlign: 'center',
        borderRadius: '8px 8px 0 0',
      }}
      extra={<FiX strokeWidth="1.5" onClick={hideEditPriceModal} />}
    >
      {content}
    </Drawer>
  );
};
