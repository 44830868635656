$prefixCls: welcome-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app {
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(206, 228, 106, 0.5) 166%);
    }
  }

  &__content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-auto-rows: 1fr auto;
    place-items: center;
    padding: var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-small-max-width);
  }

  &__message-card {
    padding: 35px 28px;
    height: max-content;
    text-align: center;
    background: var(--color-white);
    border-radius: 16px;

    &-title {
      color: var(--color-black);
      font-size: 30px;
      font-weight: 500;
      line-height: 1.15;
      margin-bottom: 28px;
    }

    &-description {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      margin-bottom: 52px;
    }
  }
}
