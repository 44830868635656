.ns_com_order_track_button_main {
  @at-root .track_info_dialog {
    @media screen and (min-width: 769px) {
      :global {
        .modal__container {
          width: 400px;
          min-width: 400px;
          max-width: 400px;
        }
      }
    }

    .track_info_dialog_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      font-size: var(--font-size3);
      line-height: 1.8;
    }

    .tracking_item {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;

      &:not(:first-child) {
        margin-top: var(--spacing-xxl);
      }

      .tracking_item_title {
        margin-bottom: var(--spacing-m);
        font-size: var(--font-size2);
        font-weight: 500;
        line-height: 1.3;
      }

      .tracking_item_content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 0;
        padding: var(--spacing-s) var(--spacing-l);
        border: 1px solid #dedede;
        border-radius: 4px;
        background: var(--color-white);
      }

      .tracking_item_company {
        margin-right: var(--spacing-l);
        text-transform: uppercase;

        &:empty {
          display: none;
        }
      }

      .tracking_item_number {
        flex: 1;
      }

      .tracking_item_actions {
        margin-left: var(--spacing-l);

        :global {
          .button {
            margin-right: calc(-1 * var(--spacing-l));

            .button__icon {
              font-size: 16px;
              line-height: 1;
            }
          }
        }
      }
    }

    .track_info_dialog_message {
      margin-top: var(--spacing-s);
      color: var(--color-gray4);
      font-size: var(--font-size2);
      line-height: 1.3;
    }
  }
}
