/* eslint-disable react/jsx-sort-props */
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import AccessControl from '@/components/AccessControl';
import AppLayout from '@/components/AppLayout';
import BeforeLogined from '@/components/BeforeLogined';
import { disableNewRegistration, disableUnauthorizedAccess, isSystemMaintaining } from '@/constants/app';
import Account from '@/pages/Account';
import AccountProfile from '@/pages/AccountProfile';
import AddEmail from '@/pages/AddEmail';
import BrandAssets from '@/pages/BrandAssets';
import BrandAssetsBefore from '@/pages/BrandAssetsBefore';
import Builder from '@/pages/builder';
import Cart from '@/pages/Cart';
import CollectionDetail from '@/pages/CollectionDetail';
import CollectionList from '@/pages/CollectionList';
import DepositFunds from '@/pages/DepositFunds';
import EmailBind from '@/pages/EmailBind';
import EmailLogin from '@/pages/EmailLogin';
import Home from '@/pages/Home';
import ImportedProducts from '@/pages/ImportedProducts';
import IntellectualPropertyPolicy from '@/pages/IntellectualPropertyPolicy';
import InventoryLocation from '@/pages/InventoryLocation';
import KiwiWallet from '@/pages/KiwiWallet';
import Landing from '@/pages/Landing';
import Login from '@/pages/Login';
import Maintaining from '@/pages/Maintaining';
import NotFound from '@/pages/NotFound';
import OrderCheckoutResult from '@/pages/OrderCheckoutResult';
import OrderReturn from '@/pages/OrderReturn';
import Orders from '@/pages/Orders';
import OrdersCheckout from '@/pages/OrdersCheckout';
import OrdersDetail from '@/pages/OrdersDetail';
import PasswordReset from '@/pages/PasswordReset';
import PriceRules from '@/pages/PriceRules';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import ProductDetail from '@/pages/ProductDetail';
import ProductRequest from '@/pages/ProductRequest';
import ProductRequestSuccess from '@/pages/ProductRequest/Success';
import RedirectToOrderDetail from '@/pages/RedirectToOrderDetail';
import ReferralCode from '@/pages/ReferralCode';
import Search from '@/pages/Search';
import SellerCollectionDetail from '@/pages/SellerCollectionDetail';
import SellerCollectionList from '@/pages/SellerCollectionList';
import ShippingRules from '@/pages/ShippingRules';
import SignupQuestion from '@/pages/SignupQuestion';
import StoreDetail from '@/pages/Store/Detail';
import StoreLandingCallback from '@/pages/Store/LandingCallback';
import StoreLandingInstall from '@/pages/Store/LandingInstall';
import StoreList from '@/pages/Store/List';
import StoreConnectResult from '@/pages/StoreConnectResult';
import TermsOfService from '@/pages/TermsOfService';
import Welcome from '@/pages/Welcome';

import Addresses from './pages/Addresses';
import CatalogForRetail from './pages/CatalogForRetail';
import CheckInventory from './pages/CheckInventory';
import PaymentMethods from './pages/PaymentMethods';
import Payout from './pages/Payout';
import PrivateInvDetail from './pages/PrivateInv/Detail';
import PrivateInv from './pages/PrivateInv/List';
import SellingPriceRules from './pages/SellingPriceRules';
import SupplierSales from './pages/SupplierSales';
import { allPaths } from './utils/path';

function Router(): JSX.Element {
  if (isSystemMaintaining) return <Maintaining />;

  return (
    <Routes>
      {/* primary pages routes (contain NavBar and TabBar layout) */}
      <Route
        path="/"
        element={
          <AccessControl
            requireAuth={disableNewRegistration || disableUnauthorizedAccess}
            rejectRedirectUrl={disableNewRegistration ? '/landing' : undefined}
          >
            <AppLayout />
          </AccessControl>
        }
      >
        <Route index element={<Home />} />
        <Route
          path="/stores"
          element={
            <AccessControl>
              <StoreList />
            </AccessControl>
          }
        />
        <Route
          path="/stores/:id"
          element={
            <AccessControl>
              <StoreDetail />
            </AccessControl>
          }
        />
        <Route
          path="/imported-products/:shopId"
          element={
            <AccessControl>
              <ImportedProducts />
            </AccessControl>
          }
        />
        <Route
          path="/price-rules/:shopId"
          element={
            <AccessControl>
              <PriceRules />
            </AccessControl>
          }
        />
        <Route path="/shipping-rules/:shopId" element={<ShippingRules />} />
        <Route
          path="/brand-assets"
          element={
            <AccessControl>
              <BrandAssetsBefore />
            </AccessControl>
          }
        />
        <Route
          path="/brand-assets/:shopId"
          element={
            <AccessControl>
              <BrandAssets />
            </AccessControl>
          }
        />
        <Route path="/inventory-location/:shopId" element={<InventoryLocation />} />
        <Route
          path="/orders"
          element={
            <AccessControl>
              <Orders />
            </AccessControl>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <AccessControl>
              <OrdersDetail />
            </AccessControl>
          }
        />
        <Route
          path={`${allPaths.supplierOrderDetail}/:orderId`}
          element={
            <AccessControl>
              <OrdersDetail />
            </AccessControl>
          }
        />
        <Route
          path="/o/:orderName"
          element={
            <AccessControl>
              <RedirectToOrderDetail />
            </AccessControl>
          }
        />
        <Route
          path="/order-return/:orderId"
          element={
            <AccessControl>
              <OrderReturn />
            </AccessControl>
          }
        />
        <Route
          path="/orders/:orderId/checkout"
          element={
            <AccessControl>
              <OrdersCheckout />
            </AccessControl>
          }
        />
        <Route
          path="/account"
          element={
            <AccessControl>
              <Account />
            </AccessControl>
          }
        />
        <Route
          path="/account/profile"
          element={
            <AccessControl>
              <AccountProfile />
            </AccessControl>
          }
        />
        <Route
          path="/kiwi-wallet"
          element={
            <AccessControl>
              <KiwiWallet />
            </AccessControl>
          }
        />
        <Route
          path="/deposit-funds"
          element={
            <AccessControl>
              <DepositFunds />
            </AccessControl>
          }
        />
        <Route path="/search" element={<Search />} />
        <Route
          path="/products/:id"
          element={
            <AccessControl>
              <ProductDetail />
            </AccessControl>
          }
        />
        <Route path="/collections" element={<CollectionList />} />
        <Route path="/collections/:id" element={<CollectionDetail />} />
        <Route
          path="/seller-collections"
          element={
            <AccessControl>
              <SellerCollectionList />
            </AccessControl>
          }
        />
        <Route
          path="/seller-collections/:id"
          element={
            <AccessControl>
              <SellerCollectionDetail />
            </AccessControl>
          }
        />
        <Route
          path="/cart"
          element={
            <AccessControl>
              <Cart />
            </AccessControl>
          }
        />
        <Route
          path="/product-request"
          element={
            <AccessControl>
              <ProductRequest />
            </AccessControl>
          }
        />
        <Route
          path="/product-request-success"
          element={
            <AccessControl>
              <ProductRequestSuccess />
            </AccessControl>
          }
        />
        <Route path="/builder" element={<Builder />} />
        <Route path="/referral-code" element={<ReferralCode />} />
        <Route path="/check-inv" element={<CheckInventory />} />
        <Route path="/payment-methods" element={<PaymentMethods />} />
        <Route path="/private-inv" element={<PrivateInv />} />
        <Route path="/private-inv/:variantID" element={<PrivateInvDetail />} />
        <Route path="/addresses" element={<Addresses />} />

        {/* 2023/07/17 update add new routes. feature about shopify import */}
        <Route
          path={`${allPaths.supplierSales}/:shopId`}
          element={
            <AccessControl>
              <SupplierSales />
            </AccessControl>
          }
        />
        <Route
          path={`${allPaths.catalogForRetail}/:shopId`}
          element={
            <AccessControl>
              <CatalogForRetail />
            </AccessControl>
          }
        />
        <Route
          path={`${allPaths.sellingPriceRules}/:shopId`}
          element={
            <AccessControl>
              <SellingPriceRules />
            </AccessControl>
          }
        />
        <Route
          path={`${allPaths.payout}/:shopId`}
          element={
            <AccessControl>
              <Payout />
            </AccessControl>
          }
        />
      </Route>

      {/* secondary pages routes (not contain NavBar and TabBar layout) */}
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/" element={<BeforeLogined />}>
        <Route path="/landing" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/email-login" element={<EmailLogin />} />
      </Route>
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route
        path="/add-email"
        element={
          <AccessControl>
            <AddEmail />
          </AccessControl>
        }
      />
      <Route
        path="/signup-question"
        element={
          // <AccessControl>
          <SignupQuestion />
          // </AccessControl>
        }
      />
      <Route
        path="/email-bind"
        element={
          <AccessControl>
            <EmailBind />
          </AccessControl>
        }
      />
      <Route path="/store-connect-result" element={<StoreConnectResult />} />
      <Route path="/order-checkout-result" element={<OrderCheckoutResult />} />

      {/* store connect routing */}
      {/* ！！！安装过程直接由同名接口代理，不走页面 ！！！ */}
      <Route path="/auth/shop/shopify/install" element={<StoreLandingInstall />} />
      <Route path="/auth/shop/shopify/callback" element={<StoreLandingCallback />} />

      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/intellectual-property-policy" element={<IntellectualPropertyPolicy />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
