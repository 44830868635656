import { message, Tag } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiChevronLeft } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import Image from '@/components/Image';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import useSellerCollectionProducts from '@/hooks/queries/useSellerCollectionProducts';
import useUpdateCart from '@/hooks/queries/useUpdateCart';
import { getVariantByOptions, getVariantsStockSummary } from '@/utils/biz';
import { getPriceRange } from '@/utils/util';

import css from './index.module.scss';

const queryParams = {
  page_size: 30,
};

type Props = {
  visible: boolean;
  title: string;
  onClose: () => void;
  afterSuccessCallback: (addToCartValues: any[]) => void;
};

const App = ({ visible = false, title, onClose, afterSuccessCallback, ...restProps }: Props) => {
  const params = useParams();
  const collectionId = params.id;
  const updateCart = useUpdateCart();
  const products = useSellerCollectionProducts(collectionId, queryParams);
  const productsData = useMemo(
    () => _.compact(products?.data?.pages?.flatMap((page) => page?.data?.products)),
    [products?.data]
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const product = productsData[currentIndex]?.product;
  const options = product?.options;
  const variants = product?.variants;
  const variantsStockSummary = useMemo(() => getVariantsStockSummary(variants), [variants]);
  const isProductSoldOut = !!product?.out_of_stock || !product?.available;
  const [currentOptions, setCurrentOptions] = useState<string[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<any>({});
  const addToCartItems = _.map(_.values(selectedVariants), (item) => _.omit(item, 'variant'));
  const productSelectedVariants = _.pickBy(selectedVariants, (item) => item?.variant?.product_id === product?.id);
  const productsTotal = products?.data?.pages?.[0]?.data?.pagination?.total;

  const handleClose = () => {
    setCurrentIndex(0);
    setCurrentOptions([]);
    setSelectedVariants({});
    onClose?.();
  };

  const handleAddToCart = () => {
    if (!addToCartItems.length) {
      message.warning('Please at least choose one product.');
      return;
    }

    updateCart.mutate(
      { items: addToCartItems, append: true },
      {
        onSuccess: () => {
          handleClose();
          afterSuccessCallback?.(addToCartItems);
        },
        onError: (error) => {
          message.error((error as Error)?.message || 'Add to Cart Failure!');
        },
      }
    );
  };

  const handleVariantOptionChange = (group: any, value: string) => {
    const changedOptionIndex = group?.position - 1;
    const newOptions = currentOptions?.slice(0, changedOptionIndex + 1);
    newOptions[changedOptionIndex] = value;
    setCurrentOptions(newOptions);

    const variant = getVariantByOptions(variants, newOptions);
    if (_.isNil(variant?.id)) return;
    if (changedOptionIndex === options.length - 1) setCurrentOptions(_.initial(newOptions));
    setSelectedVariants((prevState: any) => {
      const newState = { ...prevState, [variant?.id]: { variant, qty: 1, variant_id: Number(variant?.id) } };
      if (variant?.id in prevState) delete newState[variant?.id];
      return newState;
    });
  };

  const isOptionActive = (group: any, value: string): boolean => {
    const { position } = group;
    if (currentOptions?.[position - 1] === value) return true;
    if (
      _.some(selectedVariants, (item) => {
        const { variant } = item;
        if (variant?.product_id !== product?.id) return false;
        if (position === 1 && _.isEmpty(currentOptions) && value === variant?.option1) return true;
        if (position === 2 && variant?.option1 === currentOptions?.[0] && value === variant?.option2) return true;
        if (
          position === 3 &&
          variant?.option1 === currentOptions?.[0] &&
          variant?.option2 === currentOptions?.[1] &&
          value === variant?.option3
        )
          return true;

        return false;
      })
    )
      return true;

    return false;
  };

  const isVariantOutOfStock = (group: any, value: string): boolean => {
    const { position = 1 } = group;
    const option1 = currentOptions?.[0];
    const option2 = currentOptions?.[1];

    if (position === 1) return variantsStockSummary?.[value]?.total < 1;
    if (position === 2) return variantsStockSummary?.[option1]?.children?.[value]?.total < 1;
    if (position === 3) return variantsStockSummary?.[option1]?.children?.[option2]?.children?.[value]?.total < 1;

    return false;
  };

  useEffect(() => {
    setCurrentOptions(
      _.map(_.initial(options), (group: any) => _.find(group?.values, (value) => !isVariantOutOfStock(group, value)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, options]);

  return (
    <Modal
      className={css.modal_bulk_select_sku_m}
      visible={visible}
      title={title}
      fullscreen
      closable={false}
      headerLeft={
        <Button block fill="none" icon={<FiChevronLeft strokeWidth={2} size={24} onClick={handleClose} />}>
          Exit
        </Button>
      }
      headerRight={
        <Button block fill="none" loading={updateCart.isLoading} onClick={handleAddToCart}>
          Finish & Add
        </Button>
      }
      footer={
        <div className={css.footer_navigation}>
          <div className={css.footer_navigation_buttons}>
            <Button
              size="large"
              shape="rounded"
              icon={<FiArrowLeft />}
              disabled={products?.isLoading || currentIndex < 1}
              onClick={() => {
                setCurrentOptions([]);
                setCurrentIndex((prev) => prev - 1);
              }}
            />
            <Button
              size="large"
              shape="rounded"
              icon={<FiArrowRight />}
              disabled={products?.isLoading || currentIndex >= productsTotal - 1}
              onClick={() => {
                if (productsData?.length - currentIndex < 5 && products.hasNextPage) products.fetchNextPage();
                setCurrentOptions([]);
                setCurrentIndex((prev) => prev + 1);
              }}
            />
          </div>
          <div className={css.footer_navigation_info}>
            <span>Previous Listing</span>
            <span>
              {currentIndex + 1} of {productsTotal} Listings
            </span>
            <span>Next Listing</span>
          </div>
        </div>
      }
      onClose={handleClose}
      {...restProps}
    >
      <StatusViewRenderer
        data={productsData}
        error={products.error}
        isLoading={products.isLoading}
        statusStyle={{ marginTop: 64 }}
      >
        <div className={css.content}>
          <div className={css.product}>
            <div className={css.product_cover}>
              <Image className={css.product_image} src={product?.cover} fit="contain" />
            </div>
            <div className={css.product_info}>
              <div className={css.product_title}>{product?.title}</div>
              <div className={css.product_price}>{getPriceRange(product?.price_min, product?.price_max)}</div>
            </div>
            <div className={css.product_options}>
              {options?.map((group: any) => (
                <div key={group?.name} className={css.product_options_group}>
                  <div className={css.product_options_group_title}>{_.startCase(group?.name)}</div>
                  <div className={css.product_options_group_items}>
                    {group?.values?.map((value: string) => {
                      const isDisabled = isProductSoldOut || isVariantOutOfStock(group, value);
                      return (
                        <div
                          key={value}
                          className={cn(css.product_options_item, {
                            [css.active]: !isDisabled && isOptionActive(group, value),
                            [css.out_of_stock]: isDisabled,
                          })}
                          onClick={isDisabled ? undefined : () => handleVariantOptionChange(group, value)}
                        >
                          <div className={css.product_options_item_label}>{value}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div className={css.selected_variants}>
              <div className={css.selected_variants_title}>Selected Variants</div>
              <div className={css.selected_variants_tags}>
                {_.isEmpty(productSelectedVariants) ? (
                  <div className={css.selected_variants_empty}>None added for this product. Start adding variants!</div>
                ) : (
                  _.map(productSelectedVariants, (item) => (
                    <Tag
                      key={item?.variant_id}
                      closable
                      onClose={() =>
                        setSelectedVariants((prevState) => {
                          const newState = _.omit(prevState, item?.variant_id);
                          if (_.isEmpty(newState)) setCurrentOptions([]);
                          return newState;
                        })
                      }
                    >
                      {item?.variant?.title}
                    </Tag>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </StatusViewRenderer>
    </Modal>
  );
};

export default App;
