$prefixCls: seller-collection-detail-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 63px;

        // safe area bottom patch
        --app-layout-padding-bottom: calc(63px - var(--spacing-l) + max(env(safe-area-inset-bottom), var(--spacing-l)));

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__content {
    padding: var(--spacing-l) 0;
    margin: 0 auto;
    max-width: var(--content-max-width);
  }

  &__content_header {
    display: flex;
    align-items: center;
    flex-direction: column;
    &-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 1.15;
      text-align: center;
    }

    &-creator {
      text-align: center;
      font-size: 14px;
      line-height: 1.15;
      padding-top: 4px;
    }

    &-desc {
      text-align: center;
      word-break: break-word;
      font-size: var(--font-size16);
      p {
        margin-bottom: 0;
      }

      a {
        color: #000;
        text-decoration: underline;
      }
    }

    &-operations_wrapper {
      padding-top: 16px;
      display: flex;
      align-items: center;
      align-items: center;
      column-gap: 16px;
    }

    &-operations_wrapper-2 {
      padding-top: 16px;
      align-items: center;
      display: flex;
    }
  }

  &__actions {
    &-edit-btn {
      &.m {
        padding-right: 0;
      }
      .text {
        margin-left: 8px;
      }
    }
    &-import-btn {
      background-color: #424242;
      border-color: #424242;
    }
    &-public-btn {
      align-items: center;
      display: flex;
      --adm-color-primary: #424242;
      &-text {
        font-size: 16px;
        font-weight: 500;
        padding-right: 8px;
        color: #424242;
      }
    }
  }

  &__actions {
    &-bulk-add-to-cart,
    &-import-btn,
    &-edit-btn,
    &-share-btn {
      span {
        white-space: nowrap;
      }
    }

    &-edit-btn,
    &-bulk-add-to-cart,
    &-share-btn {
      border: none;
      color: #424242;
    }

    &-share-btn {
      &_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          padding: 3px 0;
        }
        &_text {
          margin-left: 8px;
        }
      }
    }
  }

  &__product-list {
    margin-top: 24px;
  }

  &__product-list-inv {
    flex-direction: column;
    row-gap: 12px;
    display: flex;
    margin-top: 24px;
  }

  &__toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 1.4;
  }

  // sidebar
  &__content_wrapper {
    &.sidebar_pc {
      display: flex;
      padding: 0 48px;
      max-width: calc(var(--content-max-width) + 300px);
      margin: 0 auto;
      .#{$prefixCls}__content {
        flex: 1;
      }
      .#{$prefixCls}__sidebar_wrapper {
        padding-top: 34px;
        padding-bottom: 34px;
        padding-right: var(--spacing-l);
      }
    }
  }
  &__sidebar_wrapper {
    width: 200px;
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding-top: 34px;
      padding-bottom: 34px;
    }

    &__content_header {
      &-desc {
        width: 600px;
        padding-top: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding-left: var(--spacing-l);
      padding-right: var(--spacing-l);
    }

    &__content_header {
      &-desc {
        padding-top: 0px;
      }

      &-title {
        display: none;
      }

      &-operations {
        &_wrapper {
          z-index: 100;
          padding: var(--spacing-m);
          background: var(--color-white);
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          left: 0;
          box-shadow: 0 0 4px #00000040;
          column-gap: 8px;
          justify-content: center;
          & > * {
            width: 180px;
          }
        }
      }
    }

    &__product-list {
      margin-top: 16px;
    }

    &__actions {
      &-share-btn {
        width: 60px;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    &__content {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}
