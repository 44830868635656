import { useMutation, useQueryClient } from 'react-query';

import { updateMessagesReadStatus } from '@/services/ds';

export default function useUpdateMessagesReadStatus() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof updateMessagesReadStatus>[0]) => updateMessagesReadStatus(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('getMessages');
    },
  });
}
