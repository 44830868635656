import { OrderStatus, OrderStatusTag } from './types';

// 订单状态历史原因，有一个partially_shipped状态，但是，这个状态不需要显示在页面上，所以在做订单筛选时，要注意此状态需要与processing合并，传给后端做查询
export const ORDER_STATUS_FILTER = {
  [OrderStatus.Unpaid]: 'Unpaid',
  [OrderStatus.Paid]: 'Holding',
  [`${OrderStatus.Processing},${OrderStatus.PartiallyShipped}`]: 'Processing',
  [OrderStatus.Shipped]: 'Shipped',
  [OrderStatus.Cancelled]: 'Cancelled',
  [OrderStatus.Error]: 'Errors',
};

const ORDER_STATUS_MAP = {
  [OrderStatus.Paid]: ['Holding', OrderStatusTag.Success],
  [OrderStatus.Unpaid]: ['Unpaid', OrderStatusTag.Danger],
  [OrderStatus.Processing]: ['Processing', OrderStatusTag.Default],
  [OrderStatus.Shipped]: ['Shipped', OrderStatusTag.Success],
  [OrderStatus.PartiallyShipped]: ['Partially Shipped', OrderStatusTag.Default],
  [OrderStatus.Cancelled]: ['Cancelled', OrderStatusTag.Default],
  [OrderStatus.Pending]: ['Processing', OrderStatusTag.Default],
  [OrderStatus.Open]: ['Processing', OrderStatusTag.Default],
  [OrderStatus.Error]: ['Failed', OrderStatusTag.Danger],
  [OrderStatus.Failure]: ['Failed', OrderStatusTag.Danger],
  [OrderStatus.Partial]: ['Processing', OrderStatusTag.Default],
  [OrderStatus.Success]: ['Shipped', OrderStatusTag.Success],
};

const ORDER_STATUS_MAP_UNFULFILLED = {
  [OrderStatus.Paid]: [OrderStatus.Paid],
  [OrderStatus.Unpaid]: [OrderStatus.Unpaid],
  [OrderStatus.Processing]: [OrderStatus.Processing],
  [OrderStatus.Shipped]: [OrderStatus.Processing],
  [OrderStatus.PartiallyShipped]: [OrderStatus.Processing],
  [OrderStatus.Cancelled]: [OrderStatus.Cancelled],
  [OrderStatus.Pending]: [OrderStatus.Processing],
  [OrderStatus.Open]: [OrderStatus.Processing],
  [OrderStatus.Error]: [OrderStatus.Error],
  [OrderStatus.Failure]: [OrderStatus.Error],
  [OrderStatus.Partial]: [OrderStatus.Processing],
  [OrderStatus.Success]: [OrderStatus.Processing],
};

const defaultShippingAddress = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  country: '',
  country_code: '',
  first_name: '',
  last_name: '',
  latitude: '',
  longitude: '',
  name: '',
  phone: '',
  province: '',
  province_code: '',
  zip: '',
};

const defaultCombineOrder = {
  total_shipping_shall_refund: 0,
  total_price: 0,
  total_tax: 0,
  total_items_price: 0,
  combined_orders: [],
};

const defaultOrder = {
  combined_orders: [],
};

export { defaultCombineOrder, defaultOrder, defaultShippingAddress, ORDER_STATUS_MAP, ORDER_STATUS_MAP_UNFULFILLED };
