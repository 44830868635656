import vaguePic from '@/assets/images/vague_pic.png';

const DEFAULT_SKU = '1234567ABCDE';

const DEFAULT_DATA = {
  product: {
    title: 'Product Title',
    id: 230109002,
    price_max: 10,
    price_min: 10,
    options: [
      {
        name: 'Size',
        values: ['S', 'M', 'L', 'XL', '1X', '2X', '3X'],
      },
      {
        name: 'Color',
        values: ['Red', 'Blue', 'Green', 'Black'],
      },
    ],
    variants: [
      {
        sku: DEFAULT_SKU,
        barcode: DEFAULT_SKU,
      },
    ],
    cover: vaguePic,
    master_style: 'STY-L3',
    brand: {
      name: 'Brand',
    },
  },
};

export default DEFAULT_DATA;

export { DEFAULT_DATA, DEFAULT_SKU };
