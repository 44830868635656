/* eslint-disable no-restricted-syntax */
import { Collapse, message } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import React, { ElementRef, useMemo, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AutoLink, { TargetType } from '@/components/AutoLink';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Image from '@/components/Image';
import OrderChangeAddressButton from '@/components/OrderChangeAddressButton';
import OrderTrackButton from '@/components/OrderTrackButton';
import Tag from '@/components/Tag';
import { SHIPPING_RULES_DEFAULT_ADDRESS_TAG_TEXT } from '@/constants/biz';
import { useDesktop } from '@/hooks';
import useCancelOrder from '@/hooks/queries/useCancelOrder';
import useStores from '@/hooks/queries/useStores';
import useOrderAdded from '@/hooks/useOrderAdded';
import ModalOrderReturn from '@/pages/OrderReturn/components/ModalOrderReturn';
import {
  checkIsOverBuy,
  checkIsSoldOut,
  getShopifyOrderDetailUrl,
  showOrderSoldOutOrOverBuyTag,
  showShippingRulesDefaultAddressTag,
} from '@/utils/biz';

import { defaultShippingAddress } from '../../constant';
import type { OrderItem } from '../../types';
import { OrderStatus, OrderType } from '../../types';
import { formatterDate, getIsShipped, getOrderTypeDesc, getRefreshedOrder } from '../../util';
import OrderStatusTag from '../OrderStatusTag';
import ProcessButton from '../ProcessButton';
import css from './index.module.scss';

type ModalOrderReturnHandle = ElementRef<typeof ModalOrderReturn>;

const { Panel } = Collapse;

type OrderListProps = {
  className?: string;
  style?: React.CSSProperties;
  data?: OrderItem[];
  onSelect?: (id: string) => void;
  selectedIds?: string[];
  showSecondaryAction?: boolean;
  orderDetailPath?: string;
  viewer: 'supplier' | 'retailer';
};

type OrderListItemProps = {
  className?: string;
  style?: React.CSSProperties;
  data?: OrderItem;
  onSelect?: (id: string) => void;
  isSelected?: boolean;
  onReturn?: (orderId: string, packageId: string) => void;
  showSecondaryAction?: boolean;
  orderDetailPath?: string;
  viewer: 'supplier' | 'retailer';
};

const confirmModalTitle = 'Cancel Order';
const confirmModalContent = 'Confirm to cancel this order?';
const DEFAULT_DATE_FORMAT = 'MM/DD/YY';

function OrderList({
  className,
  style,
  data,
  onSelect,
  selectedIds,
  showSecondaryAction = true,
  orderDetailPath = '',
  viewer,
}: OrderListProps): JSX.Element {
  const navigate = useNavigate();
  const modalOrderReturnRef = useRef<ModalOrderReturnHandle>(null);
  const isDesktop = useDesktop();
  const [orderId, setOrderId] = useState('');

  const openReturnModal = (curOrderId, packageId) => {
    setOrderId(curOrderId);
    if (isDesktop) {
      modalOrderReturnRef.current.show({ fulFillId: packageId });
    } else {
      navigate(`/order-return/${curOrderId}${packageId ? `?ful=${packageId}` : ''}`);
    }
  };

  return (
    <>
      <div className={cn(css.ns_com_order_list_main, 'order-list', className)} style={style}>
        {data?.map((item: any) => (
          <OrderListItem
            key={item?.id}
            data={item}
            onSelect={onSelect}
            isSelected={selectedIds.includes(item.id)}
            onReturn={openReturnModal}
            showSecondaryAction={showSecondaryAction}
            orderDetailPath={orderDetailPath}
            viewer={viewer}
          />
        ))}
      </div>
      <ModalOrderReturn orderId={orderId} ref={modalOrderReturnRef} />
    </>
  );
}

const Header = ({
  data,
  isSelected,
  onSelect,
  url,
  viewer,
}: {
  data?: OrderItem;
  isSelected?: boolean;
  onSelect?: (id: string) => void;
  url: string;
  viewer: 'supplier' | 'retailer';
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const statusFromSearchParams = searchParams.get('status');

  if (!data) return null;

  const {
    shipping_address,
    name: kiwiOrderName,
    paid_at,
    order_type,
    combined_orders = [],
    shipping_address_route_type,
    shopify_order_number,
    shop_shopify_order_number,
  } = data;
  const { first_name, last_name } = shipping_address || defaultShippingAddress;

  const orderNo = getOrderTypeDesc(data);

  const showPaidAt = !!paid_at;
  const showCheckbox = !!statusFromSearchParams && _.toLower(statusFromSearchParams) === OrderStatus.Paid;
  const sellerName = `${first_name || ''} ${last_name || ''}`;
  const isCombinedOrder = order_type === OrderType.Combine;
  const showDefaultAddressTag = showShippingRulesDefaultAddressTag(shipping_address_route_type);

  const handleSelectOrders = (id: string) => {
    if (!id) return;
    if (!onSelect) return;
    onSelect(id);
  };

  const openPage = (e) => {
    e.stopPropagation();
    navigate(url);
  };

  if (isCombinedOrder) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Collapse
          expandIcon={({ isActive }) => <span className={cn(css.arrow_icon, { [css.active]: isActive })} />}
          bordered={false}
          className={css.combined_order_header}
        >
          <Panel
            header={
              <div className={css.item_header}>
                <div className={css.item_header_info}>
                  <div className={css.item_order_number}>
                    <strong>Combined Order</strong>
                    <AutoLink targetType={TargetType.Url} targetData={url}>
                      <span className={css.kiwi_no} onClick={openPage}>
                        {kiwiOrderName}
                      </span>
                    </AutoLink>
                  </div>
                  <AutoLink targetType={TargetType.Url} targetData={url}>
                    <div className={css.item_desc} title={sellerName}>
                      {sellerName}
                    </div>
                  </AutoLink>
                </div>
                <AutoLink targetType={TargetType.Url} targetData={url}>
                  <div className={css.item_header_side}>
                    <div className={css.item_time}>Combined {formatterDate(data?.created_at, DEFAULT_DATE_FORMAT)}</div>
                  </div>
                </AutoLink>
              </div>
            }
            key="1"
          >
            <AutoLink targetType={TargetType.Url} targetData={url}>
              <div>
                {combined_orders.map(
                  ({ shop_order_name, name: combined_order_name, paid_at: combined_order_paid_at }) => (
                    <div key={combined_order_name} className={cn(css.space_bteween_box)}>
                      <span className={css.left}>
                        <strong>{shop_order_name} </strong>
                        <span>&nbsp;&nbsp;{combined_order_name}</span>
                      </span>
                      <span className={css.right}>
                        Paid {formatterDate(combined_order_paid_at, DEFAULT_DATE_FORMAT)}
                      </span>
                    </div>
                  )
                )}
              </div>
            </AutoLink>
          </Panel>
        </Collapse>
      </div>
    );
  }

  return (
    <AutoLink targetType={TargetType.Url} targetData={url}>
      <div className={cn(css.item_header, css.cursor_pointer)}>
        <div className={css.item_header_info}>
          <div className={css.item_order_number}>
            {showCheckbox && (
              <span
                className={css.checkbox_wrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  id={`checkbox_${kiwiOrderName}`}
                  color="primary"
                  checked={isSelected}
                  onChange={() => handleSelectOrders(data.id)}
                />
              </span>
            )}
            <span className={css.order_no_wrapper}>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span>
                {viewer === 'supplier' ? (
                  <span style={{ fontSize: 'var(--font-size3)' }}>
                    <label htmlFor={`checkbox_${kiwiOrderName}`}>
                      <span>{`${orderNo} → `}</span>
                    </label>
                    <strong>{kiwiOrderName}</strong>
                    {shopify_order_number && <span style={{ color: '#9999' }}>{` (${shopify_order_number})`}</span>}
                  </span>
                ) : (
                  <span style={{ fontSize: 'var(--font-size3)' }}>
                    <label htmlFor={`checkbox_${kiwiOrderName}`}>
                      <strong>{orderNo}</strong>
                      {shop_shopify_order_number && (
                        <span style={{ color: '#9999' }}>{` (${shop_shopify_order_number})`}</span>
                      )}
                      <span> → </span>
                    </label>
                    <span>{kiwiOrderName}</span>
                  </span>
                )}
              </span>
            </span>
          </div>
          <div className={css.item_desc} title={sellerName}>
            <span>{sellerName}</span>
            {showDefaultAddressTag && (
              <span className={css.default_address_tag_wrapper}>
                <Tag text={SHIPPING_RULES_DEFAULT_ADDRESS_TAG_TEXT} />
              </span>
            )}
          </div>
        </div>
        <div className={css.item_header_side}>
          <div className={cn(css.item_time, { [css.multiline]: showPaidAt })}>
            Created {formatterDate(data?.created_at, DEFAULT_DATE_FORMAT)}
          </div>
          {showPaidAt && (
            <div className={cn(css.item_time, { [css.multiline]: showPaidAt })}>
              Paid {formatterDate(data?.paid_at, DEFAULT_DATE_FORMAT)}
            </div>
          )}
        </div>
      </div>
    </AutoLink>
  );
};

function ErrorMessage({ data }: { data: OrderItem }): any {
  const stores = useStores();
  const { regardUnpaidSoldOutOrder, regardShopifyUnpaidSoldOutOrder, regardSoldOutItems } = useOrderAdded(data);

  const { shop_id, shop_order_shopify_id, last_error_msg, last_error_type } = data;
  const shopifyDomain = stores?.data?.data?.shops?.filter(({ id }) => `${id}` === `${shop_id}`)?.[0]?.shopify_domain;
  const shopifyOrderDetailUrl = getShopifyOrderDetailUrl(shop_order_shopify_id, shopifyDomain);
  const shopifyOrderDetailLink = (
    <a href={shopifyOrderDetailUrl} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
      Go to Shopify
    </a>
  );

  const showActionLink = shop_order_shopify_id && shopifyDomain;
  const messageMap = {
    'items.oos': {
      action: shopifyOrderDetailLink,
      readMoreUrl: 'https://help.kiwidrop.com/knowledge/order-issues#sold-out',
    },
    'shipping.no_address': {
      action: (
        <>
          Please{' '}
          <AutoLink to={`/shipping-rules/${shop_id}`} onClick={(e) => e.stopPropagation()}>
            set up default address
          </AutoLink>
        </>
      ),
      readMoreUrl: 'https://help.kiwidrop.com/knowledge/order-issues#address-missing',
    },
    'shopify.shipping_address': {
      action: shopifyOrderDetailLink,
      readMoreUrl: 'https://help.kiwidrop.com/knowledge/order-issues#address-invalid',
    },
  };
  const soldOutItemsStr = _.sumBy(regardSoldOutItems, (soi) => soi.qty);

  if (regardUnpaidSoldOutOrder) {
    if (regardShopifyUnpaidSoldOutOrder) {
      return (
        <div className={cn(css.error_message, css.link)}>
          {soldOutItemsStr} items(s) sold out. Remove these item(s) in Shopify and try again. {shopifyOrderDetailLink}
        </div>
      );
    }

    return (
      <div className={css.error_message}>
        <span>{soldOutItemsStr} items(s) sold out. You may cancel and place a new order.</span>
      </div>
    );
  }

  if (!last_error_msg) return null;

  return (
    <div className={css.error_message}>
      <span>
        {last_error_msg} {showActionLink ? messageMap?.[last_error_type]?.action : null}
      </span>
      {messageMap?.[last_error_type]?.readMoreUrl && (
        <Button
          size="mini"
          fill="none"
          target="_blank"
          link={messageMap?.[last_error_type]?.readMoreUrl}
          onClick={(e) => e.stopPropagation()}
        >
          Read More <FiAlertCircle />
        </Button>
      )}
    </div>
  );
}

function OrderListItem({
  className,
  style,
  data,
  onSelect,
  isSelected,
  onReturn,
  showSecondaryAction = true,
  orderDetailPath = '',
  viewer,
}: OrderListItemProps): JSX.Element {
  const { shop_id, shop_order_shopify_id, order_type } = data;

  const { regardShopifyUnpaidSoldOutOrder } = useOrderAdded(data);

  const cancelOrder = useCancelOrder();
  const stores = useStores();
  const shopifyDomain = stores?.data?.data?.shops?.filter(({ id }) => `${id}` === `${shop_id}`)?.[0]?.shopify_domain;
  const shopifyOrderDetailUrl = getShopifyOrderDetailUrl(shop_order_shopify_id, shopifyDomain);
  const { fulfillments, unFulfillments } = useMemo(() => getRefreshedOrder(data), [data]);
  const packages = [...fulfillments, ...unFulfillments];
  const itemLinkedURL = orderDetailPath ? `/${orderDetailPath.replace(/\//, '')}/${data.id}` : `/orders/${data.id}`;
  const showErrorMessgae = data?.status !== OrderStatus.Cancelled;

  const handleCancelOrder = async () => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        cancelOrder.mutate(data.id, {
          onSettled: (resData, error) => {
            error
              ? message.error((error as Error)?.message || 'Cancel Order Failure!')
              : message.success('Order Cancelled!');
          },
        });
      },
    });
  };

  return (
    <div className={cn(css.item, className)} style={style}>
      <Header data={data} isSelected={isSelected} onSelect={onSelect} url={itemLinkedURL} viewer={viewer} />
      {showErrorMessgae && <ErrorMessage data={data} />}

      <div className={css.item_body}>
        {packages?.map((item: any, index: number) => {
          const { status, items, tracking_url, order_id, id: packageID } = item;
          const productItemsCnt =
            items?.length > 0 ? items.map(({ qty }) => qty || 0).reduce((prev, cur) => prev + cur) : 0;
          const productItemsCntDesc = productItemsCnt > 1 ? `${productItemsCnt} items` : `${productItemsCnt} item`;
          const orderStatus = _.toLower(item?.status);
          const showTrackButton = !!tracking_url;
          const showReturnButton = getIsShipped(status) && showSecondaryAction;
          const orderIdForReturn = order_type === OrderType.Combine ? order_id : data.id;
          const showCancelButton =
            !!orderStatus && (OrderStatus.Unpaid === orderStatus || OrderStatus.Paid === orderStatus);
          const showProcessButton = orderStatus === OrderStatus.Paid;
          const showChangeAddressButton =
            orderStatus === OrderStatus.Processing && order_type !== OrderType.PrivateInv && showSecondaryAction;
          const showProductTag = showOrderSoldOutOrOverBuyTag(status);
          const showEditOrderButton = regardShopifyUnpaidSoldOutOrder;
          const showCheckoutButton = orderStatus === OrderStatus.Unpaid && !showEditOrderButton;

          if (!items || !items.length) return null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={css.item_group} key={index}>
              <div className={css.item_group_header}>
                <div className={css.item_group_header_info}>
                  <div className={css.item_status}>
                    <OrderStatusTag status={status} />
                    <span className={css.desc}>{productItemsCntDesc}</span>
                  </div>
                </div>
                <div className={css.item_group_header_actions} onClick={(e) => e.stopPropagation()}>
                  {showCancelButton && (
                    <Button size="small" onClick={handleCancelOrder} loading={cancelOrder.isLoading}>
                      Cancel
                    </Button>
                  )}
                  {showCheckoutButton && (
                    <Button size="small" color="primary" link={`/orders/${data?.id}/checkout`}>
                      Checkout
                    </Button>
                  )}
                  {showEditOrderButton && (
                    <Button size="small" color="primary" link={shopifyOrderDetailUrl} target="_blank">
                      Edit Order
                    </Button>
                  )}
                  {showReturnButton && (
                    <Button size="small" color="default" onClick={() => onReturn(orderIdForReturn, packageID)}>
                      Return
                    </Button>
                  )}
                  {showChangeAddressButton && <OrderChangeAddressButton order={data} />}
                  {showTrackButton && <OrderTrackButton fulfillment={item} />}
                  {showProcessButton && <ProcessButton color="primary" selectedIds={[data.id]} orders={[data]} />}
                </div>
              </div>
              <AutoLink targetType={TargetType.Url} targetData={itemLinkedURL}>
                <div className={css.item_group_body}>
                  <div className={css.item_images}>
                    <div className={css.item_images_wrapper}>
                      {items.map((lineItem: any, lineIndex: number) => {
                        const isSoldOut = checkIsSoldOut(lineItem?.available_quantity);
                        const isOverBuy = checkIsOverBuy(lineItem?.quantity, lineItem?.available_quantity);
                        let tagText = '';
                        if (isSoldOut) tagText = 'Sold Out';
                        if (isOverBuy) tagText = `Only ${lineItem?.available_quantity}`;

                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={lineIndex} className={css.item_images_wrapper_item}>
                            <Image src={lineItem?.image?.src} fit="cover" lazy />
                            {showProductTag && !!tagText && (
                              <div
                                className={cn(css.item_images_tag, {
                                  [css.sold_out]: isSoldOut,
                                  [css.over_buy]: isOverBuy,
                                })}
                              >
                                {tagText}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </AutoLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OrderList;
