import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { updateStoreImportedProducts } from '@/services/ds';

export default function useUpdateStoreImportedProducts(shopId?: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (params?: any) => {
      if (_.isNil(shopId)) throw new Error('missing store shopId');
      return updateStoreImportedProducts(shopId, params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['store', 'getStoreImportedProducts', shopId]);
      },
    }
  );
}
