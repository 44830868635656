.ns_com_product_tags_main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 4px;
  .tag {
    font-size: 9px;
    line-height: 1em;
    font-weight: 500;
    padding: 3px 4px;
    border-radius: 2px;
    word-break: keep-all;
    white-space: nowrap;

    &.large {
      font-size: 12px;
      line-height: 1;
      padding: 3px 4px;
    }
  }
}
