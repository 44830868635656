import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { deletePaymentMethod } from '@/services/ds';

export default function useDeletePaymentMethod() {
  const queryClient = useQueryClient();

  return useMutation((id: number | string) => deletePaymentMethod(id), {
    onSuccess: (data, id) => {
      queryClient.setQueryData('paymentMethods', (prevData: any) =>
        produce(prevData, (draft: any) => {
          const paymentMethodIndex = _.findIndex(draft?.data?.payment_methods, { id });
          if (paymentMethodIndex !== -1) draft?.data?.payment_methods.splice(paymentMethodIndex, 1);
        })
      );

      queryClient.invalidateQueries('paymentMethods');
    },
  });
}
