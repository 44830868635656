import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import Sticky from 'react-stickynode';

import InvListViewSwitch, { ProductPreviewMode } from '@/components/biz/InvListViewSwitch';
import ProductCardListView from '@/components/biz/ProductCardListView';
import type { ProductFiltersChangedData } from '@/components/ProductFiltersBar';
import ProductFiltersBar from '@/components/ProductFiltersBar';
import ProductList from '@/components/ProductList';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop } from '@/hooks';
import useSearchProducts from '@/hooks/queries/useSearchProducts';
import useUserSettings from '@/hooks/queries/useUserSettings';
import { hasInvView } from '@/utils/biz';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Search({ className, style }: Props): JSX.Element {
  const isDesktop = useDesktop();
  const userSettings = useUserSettings();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') || '';
  const [filters, setFilters] = useState<ProductFiltersChangedData>();
  const productsParams = useMemo(
    () => ({
      keyword,
      ...filters,
      page: 1,
      page_size: 60,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );
  const products = useSearchProducts(productsParams, !_.isNil(filters));
  if (products.isSuccess) {
    queryClient.invalidateQueries('getHistorySearches');
  }
  const productsData = useMemo(
    () => _.compact(products?.data?.pages?.flatMap((page) => page?.data?.products)),
    [products?.data]
  );

  // ilv
  const showInvViewButton = isDesktop && hasInvView(userSettings?.data?.data?.app_collection_views);
  const [invViewMode, setInvViewMode] = useState<ProductPreviewMode>(ProductPreviewMode.Grid);
  const invViewUsedRef = useRef(null);
  const showInvView =
    showInvViewButton &&
    (invViewUsedRef.current || (!invViewUsedRef.current && invViewMode === ProductPreviewMode.List));

  const handleFilters = useCallback((val) => {
    setFilters(val);
  }, []);

  const handleChangeInvViewMode = useCallback((value) => {
    setInvViewMode(value);
  }, []);

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{keyword}</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__toolbar`}>
          Results for <strong>{`'${keyword}'`}</strong> {products?.data?.pages?.[0]?.data?.pagination?.total} Items
        </div>

        <Sticky top=".app-layout__nav-bar" innerZ={2}>
          <div className={`${prefixCls}__product-filters-bar-wrapper`}>
            <ProductFiltersBar onChange={handleFilters} />
            <div>
              {showInvViewButton && <InvListViewSwitch value={invViewMode} onChange={handleChangeInvViewMode} />}
            </div>
          </div>
        </Sticky>

        <StatusViewRenderer
          data={productsData}
          error={products.error}
          isLoading={products.isLoading}
          hasMore={products.hasNextPage}
          loadMore={products.fetchNextPage}
          statusStyle={{ marginTop: 64 }}
        >
          <div style={{ display: invViewMode === ProductPreviewMode.Grid ? 'block' : 'none' }}>
            <ProductList className={`${prefixCls}__product-list`} data={productsData} />
          </div>

          {showInvView && (
            <div style={{ display: invViewMode === ProductPreviewMode.List ? 'block' : 'none' }}>
              <div className={`${prefixCls}__product-list-inv`}>
                {productsData.map((productData, idx) => (
                  <ProductCardListView
                    data={productData}
                    key={productData?.product?.id || idx}
                    isShow={invViewMode === ProductPreviewMode.List}
                    isShowBuyButton={false}
                  />
                ))}
              </div>
            </div>
          )}
        </StatusViewRenderer>
      </div>
    </div>
  );
}

export default Search;
