import { Box, Inline, Spinner } from '@shopify/polaris';
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { GOOGLE_MAP_API_KEY } from '@/constants/app';
import { ShippingAddress } from '@/types';

import css from './Form.module.scss';

type Props = {
  initialValues?: ShippingAddress;
};

const Form = forwardRef(({ initialValues }: Props, ref) => {
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(true);

  const defaultValuesOrigin = useMemo(() => {
    if (!initialValues) return {};

    const { first_name, last_name, address1, address2, city, country_code, phone, zip, province_code } = initialValues;

    return {
      firstName: first_name,
      lastName: last_name,
      phone,
      address: {
        line1: address1,
        line2: address2,
        city,
        state: province_code,
        postal_code: zip,
        country: country_code,
      },
    };
  }, [initialValues]);

  const handleReady = useCallback(() => {
    setIsLoading(false);
  }, []);

  useImperativeHandle(ref, () => ({
    elements,
  }));

  return (
    <form className={css.ns_com_addresses_form_main}>
      <div className={css.addresses_form_mask} style={{ display: isLoading ? 'block' : 'none' }}>
        <Box paddingBlockStart="28" paddingBlockEnd="28">
          <Inline align="center">
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </Inline>
        </Box>
      </div>

      <AddressElement
        options={{
          mode: 'shipping',
          defaultValues: defaultValuesOrigin,
          fields: {
            phone: 'always',
          },
          display: {
            name: 'split',
          },
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: GOOGLE_MAP_API_KEY,
          },
          allowedCountries: ['US', 'CA', 'AU', 'GB'],
          validation: {
            phone: {
              required: 'always',
            },
          },
        }}
        onReady={handleReady}
      />
    </form>
  );
});

export default Form;
