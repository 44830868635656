import { Select } from 'antd';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';

import Button from '@/components/Button';
import { useReactToPrint } from '@/components/common/ReactToPrint.tsx';
import Modal from '@/components/Modal';

import FullSize from './FullSize';
import css from './index.module.scss';
import Sample from './Sample';

type Props = {
  children: (params: { toggler: () => void }) => React.ReactNode | Element;
  onFinish?: () => void;
  fetchData?: () => any; // TODO: 必须是一个promise
};

const formatType = {
  fullSize: 'full_size',
  sample: 'sample',
};
const initFormat = formatType.fullSize;

const options = [
  {
    value: formatType.fullSize,
    label: 'Full Size ( 8.5” x 11” )',
  },
  {
    value: formatType.sample,
    label: 'Simple* ( 1” x 3” )  ',
  },
];

const motionProps = {
  initial: { height: 200 },
  variants: {
    open: { height: 'fit-content' },
    closed: { height: 200 },
  },
  transition: { duration: 0.3, type: 'tween' },
};

const ModalPrintLiveHelper = ({ children, fetchData }: Props) => {
  const [visible, setVisible] = useState(false);
  const [formatVal, setFormatVal] = useState(initFormat);
  const printContentRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggler = () => {
    setVisible((prevState) => !prevState);
  };

  const handleFinish = useReactToPrint({
    content: () => printContentRef.current,
    onBeforeGetContent: () => {
      setLoading(true);
    },
    onAfterPrint() {
      setLoading(false);
    },
  });

  const childrenProps = {
    toggler,
  };

  useEffect(() => {
    if (!visible) {
      setFormatVal(initFormat);
      setLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      fetchData().then((res) => {
        setProducts(res || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const productList = useMemo(
    () =>
      products?.map((product, index) =>
        formatVal === formatType.fullSize ? (
          <div
            key={product?.product?.id}
            style={{ pageBreakAfter: index === products.length - 1 ? 'unset' : 'always' }}
          >
            <FullSize data={product} />
          </div>
        ) : (
          <div key={product?.id} style={{ pageBreakAfter: index === products.length - 1 ? 'unset' : 'always' }}>
            <Sample data={product} />
          </div>
        )
      ),
    [formatVal, products]
  );

  if (!children) return null;

  return (
    <>
      {children(childrenProps)}
      <Modal
        visible={visible}
        title="Print Live Helper"
        onClose={toggler}
        className={css.ns_com_modal_print_live_helper_main}
        footer={
          <div className={css.modal_print_live_helper_footer}>
            <Button shape="rounded" color="main" onClick={handleFinish} loading={loading}>
              Print
            </Button>
          </div>
        }
      >
        <div className={css.modal_print_live_helper_content}>
          <div className={css.title}>Format</div>
          <div className={css.print_live_helper_select_wrapper}>
            <Select
              className={css.input}
              defaultValue={formatType.fullSize}
              style={{ width: '100%' }}
              options={options}
              onChange={setFormatVal}
              size="large"
            />
          </div>
          {formatVal === formatType.fullSize && (
            <motion.div
              {...motionProps}
              animate={formatVal === formatType.fullSize ? 'open' : 'closed'}
              className={css.print_demo_full_size_wrapper}
            >
              <FullSize isDemo />
            </motion.div>
          )}
          {formatVal === formatType.sample && (
            <motion.div {...motionProps} animate={formatVal === formatType.sample ? 'open' : 'closed'}>
              <div className={css.print_demo_sample_wrapper}>
                <Sample isDemo />
              </div>
              <p className={css.footer_wrapper}>*Please note you will need a special printer for this format</p>
            </motion.div>
          )}
        </div>
        <div style={{ width: 'min-content', height: 0, overflow: 'hidden' }}>
          <div
            ref={printContentRef}
            style={{ display: visible ? 'block' : 'none' }}
            className={css.print_live_helper_ref}
          >
            {productList}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalPrintLiveHelper;
