import { useMutation } from 'react-query';
import { AuthProviderConfig, initReactQueryAuth } from 'react-query-auth';

import StatusViewRenderer from '@/components/StatusViewRenderer';
import { getUser, loginByEmail, sendPhoneCode, verifyPhoneCode as loginByPhone } from '@/services/lynx';

export interface User {
  id?: string;
  encrypted_id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  email_pending?: string;
  email_verified?: number;
  email_accept_marketing?: number;
  phone?: string;
  phone_country?: string;
  referral_code?: string;
  typeforms_responded?: string[];
  has_password?: boolean;
}

export const tokenStorage = {
  getToken: () => localStorage.getItem('token'),
  setToken: (token: string) => localStorage.setItem('token', token),
  clearToken: () => localStorage.removeItem('token'),
};

async function loadUser() {
  let user = null;

  if (tokenStorage.getToken()) {
    try {
      const res = await getUser();
      user = res?.data;
    } catch (error) {
      (error as Error)?.message === 'AUTH_TOKEN_INVALID' && tokenStorage.clearToken();
    }
  }

  return user;
}

async function loginFn(params: Parameters<typeof loginByPhone>[0] | Parameters<typeof loginByEmail>[0]) {
  let res = null;

  if ('phone' in params) {
    res = await loginByPhone(params);
  } else if ('email' in params) {
    res = await loginByEmail(params);
  }

  tokenStorage.setToken(res?.data?.access_token);

  return res?.data?.customer;
}

async function logoutFn() {
  tokenStorage.clearToken();
}

const authConfig: AuthProviderConfig<User> = {
  key: 'authUser',
  loadUser,
  loginFn,
  registerFn: loginFn,
  logoutFn,
  waitInitial: true,
  LoaderComponent: () => <StatusViewRenderer isLoading statusStyle={{ marginTop: 64 }} />,
  // LoaderComponent: () => <></>,
  ErrorComponent: ({ error }) => <StatusViewRenderer error={error} statusStyle={{ marginTop: 64 }} />,
};

export const { AuthProvider, AuthConsumer, useAuth } = initReactQueryAuth<User>(authConfig);

export function useSendPhoneCode() {
  return useMutation((params: Parameters<typeof sendPhoneCode>[0]) => sendPhoneCode(params));
}
