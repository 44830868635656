import { Frame, Page } from '@shopify/polaris';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { FiInfo } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import StoreSelect from '@/components/StoreSelect';
import { GLOBAL_SUPPLIER_FIELD } from '@/utils/global.constant';
import { allPaths } from '@/utils/path';

import { useSellingPrice } from '../SellingPriceRules/useSellingPrice';
import { DataTable } from './components/DataTable';
import { Filter } from './components/Filter';
import { Guider } from './components/Guide';
import styles from './index.module.scss';
import { handleInit, setCalculateDep, showCatalogRetailGuide } from './store';

function CatalogForRetail(): JSX.Element {
  const params = useParams();
  const { config } = useSellingPrice({
    id: params.shopId,
  });

  useEffect(() => {
    const { target, val } = config;
    setCalculateDep({
      target,
      val,
    });
  }, [config]);

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <Frame>
      <Page>
        <Helmet>
          <html lang="en" className={styles.catalogForRetail} />
          <title>Manage Listings</title>
        </Helmet>

        <SecondaryNavBar backReplace>Manage Listings</SecondaryNavBar>

        <header className={styles.header}>
          <h1 className={styles.title}>Manage Listings</h1>
          <div className={styles.secondary} onClick={showCatalogRetailGuide}>
            <span>How It Works</span>
            <FiInfo />
          </div>
        </header>
        <div className={styles.retailContainer}>
          {/* selector */}
          <StoreSelect redirectPath={`${allPaths.catalogForRetail}/:shopId`} currentUser={GLOBAL_SUPPLIER_FIELD} />
          {/* filter */}
          <Filter />
          {/* tables */}
          <DataTable />
          {/* guide module */}
          <Guider />
        </div>
      </Page>
    </Frame>
  );
}

export default CatalogForRetail;
