/**
 * account related API
 */
import _ from 'lodash';

import envconfig from '@/constants/envconfig';
import type { User } from '@/hooks/auth';

import api, { responseErrorHandler } from './api';

const baseURL = envconfig.API_BASE_URL_FOR_LYNX;

export async function verifyPhoneCode(params?: {
  phone: string;
  phone_country: string;
  code: string;
  scenario?: string;
  nonce?: string;
  password?: string;
  sms_marketing_consent?: number | boolean;
}) {
  const scenario = params?.scenario ?? 'login';
  const args = { ...params, scenario };
  _.isBoolean(args.sms_marketing_consent) && (args.sms_marketing_consent = Number(args.sms_marketing_consent));

  const res = await api.post<any>(`/app/auth/customer/phone/verify-code`, args, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

export async function sendPhoneCode(params?: { phone: string; phone_country: string; scenario?: string }) {
  const scenario = params?.scenario ?? 'login';
  const args = { ...params, scenario };

  const res = await api.post<any>(`/app/auth/customer/phone/send-code`, args, { baseURL });

  responseErrorHandler(res);
  return res.data;
}

export async function getUser() {
  const res = await api.get<any>(`/app/account/customer`, {}, { baseURL });
  // token invalid or expired
  if (res.status === 401) throw new Error('AUTH_TOKEN_INVALID');
  responseErrorHandler(res);
  return res.data;
}

export async function updateUser(params: User) {
  const res = await api.post<any>(`/app/account/customer`, params, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

export async function loginByEmail(params?: { email: string; password?: string }) {
  const res = await api.post<any>(`/app/auth/customer/email/login`, params, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

export async function sendEmailCode(params?: { email: string; scenario?: string; type?: string }) {
  const scenario = params?.scenario ?? 'login';
  const args = { ...params, scenario };

  const res = await api.post<any>(`/app/auth/customer/email/send-code`, args, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

export async function verifyEmailCode(params?: {
  email: string;
  code: string;
  scenario?: string;
  password?: string;
  email_marketing_consent?: number | boolean;
}) {
  const scenario = params?.scenario ?? 'login';
  const args = { ...params, scenario };
  _.isBoolean(args.email_marketing_consent) && (args.email_marketing_consent = Number(args.email_marketing_consent));

  const res = await api.post<any>(`/app/auth/customer/email/verify-code`, args, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

export async function setPassword(params?: { password: string }) {
  const res = await api.post<any>(`/app/account/customer/password`, params, { baseURL });
  responseErrorHandler(res);
  return res.data;
}

// account
export async function phoneSendCode(params?: any) {
  const res = await api.post<any>(`/app/account/customer/phone/send-code`, params, {
    baseURL,
  });
  responseErrorHandler(res);
  return res.data;
}

export async function phoneVerifyCode(params?: any) {
  const res = await api.post<any>(`/app/account/customer/phone/verify-code`, params, {
    baseURL,
  });
  responseErrorHandler(res);
  return res.data;
}

export type EmailSendCodeParams = { email?: string; password?: string; type?: string };
export async function emailSendCode(params?: EmailSendCodeParams) {
  const res = await api.post<any>(`/app/account/customer/email/send-code`, params, {
    baseURL,
  });
  responseErrorHandler(res);
  return res.data;
}

export async function emailVerifyCode(params?: any) {
  const res = await api.post<any>(`/app/account/customer/email/verify-code`, params, {
    baseURL,
  });
  responseErrorHandler(res);
  return res.data;
}
// account end

/** **************** app/auth/shop start /***************** */
export async function bindStore(params: { state: string }) {
  const res = await api.post<any>(`/app/auth/shop/connect`, params, {
    baseURL,
  });

  // Shop 未找到
  if (res.status === 404) throw new Error('SHOP_IS_NOT_EXIST');

  responseErrorHandler(res);
  return res.data;
}

export async function unbindStore(params: { shop_id: string | number }) {
  const res = await api.post<any>(
    '/app/auth/shop/disconnect',
    { shop_id: Number(params.shop_id) },
    {
      baseURL,
    }
  );

  responseErrorHandler(res);
  return res.data;
}

export async function authShopCallback(params: {
  code: string;
  hmac: string;
  shop: string;
  state: string;
  timestamp: number;
}) {
  const res = await api.post<any>('app/auth/shop/shopify/callback', params, {
    baseURL,
  });

  responseErrorHandler(res);
  return res.data;
}

/** **************** app/auth/shop end /***************** */
