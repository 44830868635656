import { AlphaCard, AlphaStack, Text } from '@shopify/polaris';

const NoStore = () => (
  <AlphaCard>
    <AlphaStack gap="5">
      <Text variant="headingXl" as="h4" alignment="center">
        Connect your store and start selling instantly!
      </Text>
      <AlphaStack gap="1">
        <Text variant="bodyLg" as="p" alignment="center">
          ❤️ 7 Day Return Period.
        </Text>
        <Text variant="bodyLg" as="p" alignment="center">
          👍 100% Quality Control.
        </Text>
        <Text variant="bodyLg" as="p" alignment="center">
          🤗 By boutique owners, for boutique owners.
        </Text>
        <Text variant="bodyLg" as="p" alignment="center">
          ✨ Do what you love, we&apos;ll do the rest!
        </Text>
      </AlphaStack>
    </AlphaStack>
  </AlphaCard>
);

export default NoStore;
