$prefixCls: collection-list-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__content {
    padding: 0 var(--spacing-l) var(--spacing-l);
    margin: 0 auto;
    max-width: var(--content-max-width);
  }
}
