import { ElementRef, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import List from '@/pages/OrderReturn/components/List';

import styles from './styles.scss';
import useOrderForReturn from './useOrderForReturn';
import { openHubspotReturnForm } from './util';

type ListComponentHandle = ElementRef<typeof List>;

const { prefixCls } = styles;

const App = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const fulFillId = searchParams.get('ful');
  const listRef = useRef<ListComponentHandle>(null);

  const { orderService, orderType, contactEmail, phone, lineItems, orderName } = useOrderForReturn(orderId, fulFillId);
  const [disabled, setDisabled] = useState(false);

  const open = () => {
    const selectedItems = listRef.current.getData();
    openHubspotReturnForm({
      items: selectedItems,
      email: contactEmail,
      orderType,
      phone,
      orderName,
    });
  };

  const handleChangeCallback = (selectedSkuS: string[]) => {
    setDisabled(!selectedSkuS?.length);
  };

  return (
    <div className={`${prefixCls}`}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Select Items to Return</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`} backReplace>
        Select Items to Return
      </SecondaryNavBar>

      <StatusViewRenderer
        data={lineItems}
        error={orderService.error}
        isLoading={orderService.isLoading}
        statusStyle={{ marginTop: 64 }}
        emptyContent={
          <span className={styles.empty_content}>No order found. Check your search query and try again.</span>
        }
      >
        <List data={lineItems} ref={listRef} onChange={handleChangeCallback} />
        <div className={`${prefixCls}__bottom_button_wrapper`}>
          <Button onClick={open} shape="rounded" color="primary" disabled={disabled}>
            Next
          </Button>
          <div className={`${prefixCls}__bottom_tips`}>
            {`Following return request approval, please submit return tracking here: `}
            <AutoLink
              to={`https://share.hsforms.com/1w7sUxljcRW2kvAgqsKAseAcdyi8?return_order_number=${orderName}`}
              target="_blank"
            >
              Return Tracking Upload
            </AutoLink>
          </div>
        </div>
      </StatusViewRenderer>
    </div>
  );
};

export default App;
