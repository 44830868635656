import {
  ActionList,
  AlphaCard,
  AlphaStack,
  Box,
  Button,
  Inline,
  Page,
  Pagination,
  Popover,
  TextField,
} from '@shopify/polaris';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaFilter } from 'react-icons/fa';

import usePrivateInventoryBalance from '@/hooks/queries/usePrivateInventoryBalance';
import useDebounce from '@/hooks/useDebounce';

import css from './index.module.scss';
import ListView from './ListView';
import Skeleton from './Skeleton';

const pageSize = 50;

const PrivateInv = () => {
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedValue = useDebounce(inputValue, 500);
  const [addressType, setAddressType] = useState(null);
  const pageRef = useRef(null);

  const privateInventoryBalanceRQ = usePrivateInventoryBalance({
    pagination: { page: currentPage, page_size: pageSize },
    filters: {
      keyword: debouncedValue,
      addressType,
    },
  });
  const dataOrigin = useMemo(
    () => _.compact(privateInventoryBalanceRQ?.data?.pages?.flatMap((page) => page?.data?.items)),
    [privateInventoryBalanceRQ?.data]
  );
  const pagination = useMemo(
    () => privateInventoryBalanceRQ?.data?.pages?.[0]?.data?.pagination,
    [privateInventoryBalanceRQ?.data?.pages]
  );

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActiveOld) => !popoverActiveOld), []);

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      <FaFilter />
    </Button>
  );

  const handleInputChange = useCallback((val) => {
    setCurrentPage(1);
    setInputValue(val);
  }, []);

  const next = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const prev = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    if (privateInventoryBalanceRQ.isFetched) {
      pageRef.current = true;
    }
  }, [privateInventoryBalanceRQ.isFetched]);

  if (!pageRef.current && privateInventoryBalanceRQ.isLoading) {
    return <Skeleton />;
  }

  return (
    <Page title="My Inventory">
      <Helmet>
        <html lang="en" className={css.ns_or_payment_methods_main_active} />
        <title>My Inventory</title>
      </Helmet>

      <Box>
        <AlphaStack gap="5">
          <AlphaCard padding="0" roundedAbove="xl">
            <Box padding="4">
              <Inline gap="2">
                <div style={{ flex: 1 }}>
                  <TextField
                    placeholder="Title / SKU"
                    value={inputValue}
                    onChange={handleInputChange}
                    label=""
                    autoComplete="off"
                    clearButton
                    onClearButtonClick={() => handleInputChange('')}
                  />
                </div>
                <Popover
                  active={popoverActive}
                  activator={activator}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[
                      {
                        content: <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>US Warehouse</span>,
                        onAction: () => {
                          setAddressType('us');
                        },
                      },
                      {
                        content: <span style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>CN Warehouse</span>,
                        onAction: () => {
                          setAddressType('cn');
                        },
                      },
                    ]}
                  />
                </Popover>
              </Inline>
            </Box>
            <ListView data={dataOrigin} loading={privateInventoryBalanceRQ.isLoading} />
            <Box padding="4">
              <Inline align="center">
                <Pagination
                  hasPrevious={pagination?.has_prev}
                  onPrevious={prev}
                  hasNext={pagination?.has_next}
                  onNext={next}
                />
              </Inline>
            </Box>
          </AlphaCard>
        </AlphaStack>
      </Box>
    </Page>
  );
};

export default PrivateInv;
