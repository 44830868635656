import { useInfiniteQuery } from 'react-query';

import { getMySalesOrders } from '@/services/jennifer';

export default function useSupplierSales(id: string, params?: any) {
  return useInfiniteQuery(
    ['orders', params],
    ({ pageParam = 1 }) => getMySalesOrders(id)({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
    }
  );
}
