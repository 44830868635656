import { message, Tooltip } from 'antd';
import { Modal } from 'antd-mobile';
import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import Image from '@/components/Image';
import useDeleteShopProducts from '@/hooks/queries/useDeleteShopProducts';
import useRequestProductRestock from '@/hooks/queries/useRequestProductRestock';
import useStores from '@/hooks/queries/useStores';
import { getPriceRange } from '@/utils/util';

import AutoLink from '../AutoLink';
import css from './index.module.scss';

export interface ImportedProductListProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any[];
}

export interface ImportedProductListItemProps {
  className?: string;
  style?: React.CSSProperties;
  data?: any;
}

const confirmModalTitle = 'Delete Product';
const confirmModalContent = 'Confirm deleting this product from your Shopify store and Imported Products on Kiwidrop?';

export function ImportedProductList({ className, style, data }: ImportedProductListProps): JSX.Element {
  return (
    <div className={cn(css.ns_com_imported_product_list, className)} style={style}>
      {data?.map((item: any) => (
        <ImportedProductListItem key={item?.product?.id} data={item} />
      ))}
    </div>
  );
}

export function ImportedProductListItem({ className, style, data }: ImportedProductListItemProps): JSX.Element {
  const params = useParams();
  const shopId = params?.shopId;
  const requestProductRestock = useRequestProductRestock();
  const deleteShopProducts = useDeleteShopProducts(shopId);
  const isOutOfStock = !!data?.product?.out_of_stock;
  const isUnavailable = !data?.product?.available;
  const showRequestRestock = isOutOfStock && !isUnavailable;
  const showDeleteButton = isUnavailable;
  let status = '';
  if (isOutOfStock) {
    status = 'Sold Out';
  }
  if (isUnavailable) {
    status = 'Discontinued';
  }
  const showStatusTag = !!status;

  const stores = useStores();
  const storesData = stores?.data?.data?.shops;
  const curShopData = storesData?.filter((i) => i?.id === shopId)?.[0];

  const handleRequestRestock = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    requestProductRestock.mutate(data?.product?.id, {
      onSettled: (resData, error) => {
        if (error) {
          message.error({ key: 'requestRestock', content: (error as Error)?.message || 'Request Restock Failure!' });
        } else {
          message.success({ key: 'requestRestock', content: 'Request Restock Sent!' });
        }
      },
    });
  };

  const openEditOnShopify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!curShopData?.shopify_domain) return;
    const shopifyDomain = curShopData.shopify_domain;
    const shopifyId = data?.shop_product?.shopify_id;
    const url = `https://${shopifyDomain}/admin/products/${shopifyId}`;
    window.open(url);
  };

  const openDeleteProduct = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    Modal.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        deleteShopProducts.mutate(
          {
            shopProductId: data.shop_product.id,
          },
          {
            onSuccess() {
              message.success('Delete Success');
            },
            onError() {
              message.success('Delete Failure');
            },
          }
        );
      },
    });
  };

  return (
    <div
      className={cn(css.item, className, {
        [css.out_of_stock]: isOutOfStock,
        [css.unavailable]: isUnavailable,
      })}
      style={style}
    >
      <div className={css.cover}>
        <AutoLink to={`/products/${data?.product?.id}`}>
          <Image className={css.image} src={data?.product?.cover} fit="cover" lazy />
        </AutoLink>
      </div>
      <AutoLink className={css.info} to={`/products/${data?.product?.id}`}>
        <div>
          <div className={css.title}>{data?.product?.title}</div>
          <div className={css.prices}>
            <span>Cost: {getPriceRange(data?.product?.price_min, data?.product?.price_max)}</span>
            <span className={css.sell}>
              Sell at: {getPriceRange(data?.shop_product?.price_min, data?.shop_product?.price_max)}
            </span>
          </div>
        </div>
        <div className={css.operate_wrapper}>
          <div>
            <Button className={css.operate_button} fill="none" size="mini" bold={false} onClick={openEditOnShopify}>
              Edit on Shopify
            </Button>
            {showDeleteButton && (
              <Button
                loading={deleteShopProducts.isLoading}
                loadingText="Deleting"
                className={css.operate_button}
                fill="none"
                size="mini"
                bold={false}
                onClick={openDeleteProduct}
              >
                Delete
              </Button>
            )}
            {showRequestRestock && (
              <Button
                className={css.operate_button}
                fill="none"
                size="mini"
                bold={false}
                loading={requestProductRestock.isLoading}
                loadingText="Sending"
                onClick={handleRequestRestock}
              >
                Request Restock
              </Button>
            )}
          </div>
          {showStatusTag && (
            <div className={css.tag_wrapper}>
              <Tooltip
                overlayClassName={css.tooltip}
                color="white"
                placement="topLeft"
                arrowPointAtCenter
                trigger="click"
                title={
                  <div className={css.content} onClick={(e) => e.stopPropagation()}>
                    {isUnavailable ? (
                      <>
                        <div className={css.title}>This item is discontinued</div>
                        <div>
                          We have discontinued this product due to quality-related issues. Please remove it from your
                          store.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={css.title}>This item is sold out</div>
                        <div>
                          You can request restock to let us know that the product needs priority for restocking.
                        </div>
                      </>
                    )}
                  </div>
                }
              >
                <span className={css.label} onClick={(e) => e.stopPropagation()}>
                  {status}
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </AutoLink>
    </div>
  );
}

ImportedProductList.Item = ImportedProductListItem;
export default ImportedProductList;
