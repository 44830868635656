import { useQuery } from 'react-query';

import { getShippingAddresses } from '@/services/ds';

export default function useShippingAddresses(enabled?: boolean) {
  return useQuery('shippingAddresses', () => getShippingAddresses(), {
    enabled,
    staleTime: 1000,
  });
}
