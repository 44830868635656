import _ from 'lodash';
import moment from 'moment';

import { ORDER_STATUS_FILTER, ORDER_STATUS_MAP, ORDER_STATUS_MAP_UNFULFILLED } from './constant';
import { Fulfillment, OrderItem, OrderStatus, OrderType } from './types';

interface RefreshedOrderItem extends OrderItem {
  fulfillments: Fulfillment[];
  unFulfillments: Fulfillment[];
}

const getRefreshedOrder = (order: OrderItem): RefreshedOrderItem => {
  const orderStatus = order?.status;
  const lineItemsOrigin = order?.line_items || []; // 需要完成物流的包裹
  const fulfillmentsOrigin = order?.fulfillments || []; // 已经完成物流的包裹
  const fulfillments = fulfillmentsOrigin.filter(({ status }) => status !== OrderStatus.Cancelled);
  const unFulfillments = [];
  const unFulfillmentItems = [];

  fulfillments.forEach((fulfillment) => {
    // 给fulfillments.items中的每一个item添加一些line_item上的属性,除了 qty（fulfillment 需要保留自己的 qty ）
    const fulfillItems = fulfillment?.items || [];

    fulfillItems.forEach((fulfillItem, index) => {
      const lineItem = lineItemsOrigin.find(({ shopify_id }) => `${shopify_id}` === `${fulfillItem.shopify_id}`);
      if (!lineItem) return;
      const { qty, ...rest } = lineItem;
      const refreshedFulfillItem = {
        ...fulfillItem,
        ...rest,
        cover: lineItem?.image?.src || '',
      };
      fulfillItems[index] = refreshedFulfillItem;
    });
  });

  const lineItemsFulfillQuantitySetMap: { [key: string]: number } = {};
  const lineItemsFulfillQuantityMap: { [key: string]: number } = {};
  const lineItemsQuantityMap: { [key: string]: number } = lineItemsOrigin.reduce((total, { qty, shopify_id }) => {
    const curTotal = { ...total };
    curTotal[shopify_id] = curTotal[shopify_id] ? curTotal[shopify_id] + qty : qty;
    return curTotal;
  }, {});

  fulfillments.forEach(({ items }) => {
    const lineItemFulfillQuantityMap = items.reduce((total, { qty, shopify_id }) => {
      const curTotal = { ...total };
      curTotal[shopify_id] = curTotal[shopify_id] ? curTotal[shopify_id] + qty : qty;
      return curTotal;
    }, {});
    Object.keys(lineItemFulfillQuantityMap).forEach((key) => {
      lineItemsFulfillQuantityMap[key] = lineItemsFulfillQuantityMap[key]
        ? lineItemsFulfillQuantityMap[key] + lineItemFulfillQuantityMap[key]
        : lineItemFulfillQuantityMap[key];
    });
  });
  lineItemsOrigin.forEach((lineItem) => {
    const { shopify_id, qty } = lineItem;
    const totalQty = lineItemsQuantityMap[shopify_id] ?? 0;
    const fulfilledQty = lineItemsFulfillQuantityMap[shopify_id] ?? 0;
    const fulfilledSetQty = lineItemsFulfillQuantitySetMap[shopify_id] ?? 0;
    const unFulfilledQty = totalQty - fulfilledQty - fulfilledSetQty;
    const q = Math.min(unFulfilledQty, qty);
    if (q > 0) {
      lineItemsFulfillQuantitySetMap[shopify_id] = _.get(lineItemsFulfillQuantitySetMap, shopify_id, 0) + q;
      unFulfillmentItems.push({
        ...lineItem,
        qty: q,
      });
    }
  });

  unFulfillments.push({
    items: unFulfillmentItems,
    status: ORDER_STATUS_MAP_UNFULFILLED[orderStatus]?.[0],
  });

  return {
    ...order,
    fulfillments,
    unFulfillments,
  };
};

const formatterDate = (d: string, format = 'MM/DD/YY, HH:mm:ss') => {
  if (!d) return null;
  return moment(d).format(format);
};

const getOrderCostRelated = (order: OrderItem) => {
  if (!order) {
    return {
      total_paid: 0,
      paid_by_credit: 0,
      paid_by_card: 0,
      total_refunded: 0,
      total_items_price: 0,
      total_shipping_price: 0,
      total_items_discounts: 0,
      total_tax: 0,
      total_price: 0,
      items_count: 0,
      total_shipping_shall_refund: 0,
      total_items_subtotal: 0,
      total_shipping_discounts: 0,
      total_shipping_subtotal: 0,
      total_shipping_price_preorder: 0,
    };
  }

  const {
    total_items_price,
    total_shipping_price,
    total_items_discounts,
    total_tax,
    total_price,
    combined_orders,
    total_shipping_shall_refund,
    total_paid,
    paid_by_credit,
    paid_by_card,
    total_refunded,
    total_items_subtotal,
    total_shipping_discounts,
    total_shipping_subtotal,
    total_shipping_price_preorder,
  } = order;
  const isCombinedOrder = order.order_type === OrderType.Combine;

  let itemsCount = 0;
  let totalItemsPrice = 0;
  let shippingSubtotal = 0;

  (combined_orders || []).forEach(
    ({
      items_count,
      total_items_price: total_items_price_combined,
      total_shipping_price: total_shipping_price_combined,
    }) => {
      itemsCount += Number(items_count || 0);
      totalItemsPrice += Number(total_items_price_combined || 0);
      shippingSubtotal += Number(total_shipping_price_combined || 0);
    }
  );

  return {
    total_tax,
    total_price,
    total_paid,
    paid_by_credit,
    paid_by_card,
    total_refunded,
    total_items_price: isCombinedOrder ? totalItemsPrice : total_items_price,
    total_shipping_price: isCombinedOrder ? shippingSubtotal : total_shipping_price,
    total_items_discounts,
    total_shipping_shall_refund,
    items_count: isCombinedOrder ? itemsCount : 0,
    total_items_subtotal,
    total_shipping_discounts,
    total_shipping_subtotal,
    total_shipping_price_preorder,
  };
};

const getIsShipped = (status) => {
  if (!status) return false;
  const [desc] = ORDER_STATUS_MAP[status];
  return desc === ORDER_STATUS_FILTER[OrderStatus.Shipped];
};

const getOrderTypeDesc = (order: OrderItem) => {
  const { order_type, shop_shopify_order_number, shop_order_name } = order || {};
  if (order_type === OrderType.Sample) return 'SAMPLE';
  if (order_type === OrderType.PrivateInv) return 'PRIVATE INVENTORY';
  return shop_order_name ?? `#${shop_shopify_order_number}`;
};

export default getRefreshedOrder;
export { formatterDate, getIsShipped, getOrderCostRelated, getOrderTypeDesc, getRefreshedOrder };
