import { Props as ModalProps } from '@/components/Modal';

export interface PaymentMethodsDialogProps extends Omit<ModalProps, 'children'> {}

export interface PaymentMethodFormRef {
  save: () => Promise<{ error: Error | null; paymentMethod: any }>;
}

export interface PaymentMethodEditViewProps {
  inlineMode?: boolean;
  onRenderFooter?: (footer: any) => void;
}

export interface PaymentMethodFormProps {
  type?: string;
  inlineMode?: boolean;
  onRenderFooter?: (footer: any) => void;
}

export interface PaymentMethodListViewProps {
  selectMode?: boolean;
  data?: any[];
}

export interface PaymentMethodListItemProps {
  data: any;
  onSelect?: (paymentMethod: any) => void;
  draggable?: boolean;
  isDefault?: boolean;
  showDivider?: boolean;
  showAction?: boolean;
}

enum ModalViewTypeEnum {
  List = 'list',
  Add = 'add',
  Methods = 'methods',
}

enum PaymentMethodTypeEnum {
  Bank = 'us_bank_account',
  Card = 'card',
}

export { ModalViewTypeEnum, PaymentMethodTypeEnum };
