$prefixCls: marketing-consent;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &__marketing-consent {
    max-width: 324px;
    display: flex;
    align-items: start;
    margin-bottom: 24px;
    box-sizing: border-box;
    border: 1px solid transparent;
    padding: 6px;
  }

  &__marketing-consent-tips {
    margin-left: 6px;
    margin-top: 3px;
  }

  &__marketing-consent-tips-link {
    text-decoration: underline;
    color: var(--p-text);
  }

  &__marketing-consent-error {
    border: 1px solid red;
  }
}
