import { Frame, Page } from '@shopify/polaris';
import { Input } from 'antd';
import _ from 'lodash';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import trim from 'lodash/trim';
import { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';

import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { allPaths } from '@/utils/path';

import OrderList from '../Orders/components/OrderList';
import OrderStatusFilter from '../Orders/components/OrderStatusFilter';
import { ORDER_STATUS_FILTER } from '../Orders/constant';
import { Count, OrderStatus } from '../Orders/types';
import useSupplierSales from './hooks/useSupplierSales';
import styles from './index.local.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const getOption = ({
  label,
  value,
  count,
  showCount,
}: {
  label: string;
  value: string;
  count: number;
  showCount?: boolean;
}) => ({
  label,
  value,
  count,
  showCount,
});

const formatterOrderStatusFilterOptions = (count: Count) => {
  const countOptions = Object.keys(ORDER_STATUS_FILTER).map((key) => {
    const realCount = count[key] || 0;
    const showCount =
      [OrderStatus.Paid, OrderStatus.Unpaid, OrderStatus.Error].includes(key as OrderStatus) && realCount > 0;

    return getOption({
      label: ORDER_STATUS_FILTER[key],
      value: key,
      count: realCount,
      showCount,
    });
  });

  countOptions.unshift(getOption({ label: 'All', value: '', count: 0, showCount: false }));

  return countOptions;
};

function SupplierSales(): JSX.Element {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const keyword = _.trim(searchParams.get('orders_keyword'));

  const [keywordValue, setKeywordValue] = useState(keyword);

  const requestParams = useMemo(
    () =>
      omitBy(
        {
          status,
          keyword,
        },
        isEmpty
      ),
    [status, keyword]
  );
  const orders = useSupplierSales(params.shopId, requestParams);

  const ordersData = useMemo(() => compact(orders?.data?.pages?.flatMap((page) => page?.data?.orders)), [orders?.data]);

  // console.log(orders, ordersData);

  const ordersCountByType = orders?.data?.pages?.[0]?.data?.count || {};
  const orderStatusFilterOptions = formatterOrderStatusFilterOptions(ordersCountByType);

  const handleSearch = (value: string) => {
    const newSearchParams = omitBy({ ...Object.fromEntries(searchParams), orders_keyword: trim(value) }, isEmpty);
    setKeywordValue(trim(value));
    setSearchParams(newSearchParams, { replace: true });
  };

  const noOrdersHint = keyword
    ? `No orders found for ${keyword}. Check your search query and try again.`
    : 'No orders found. Check your search query and try again.';

  return (
    <Frame>
      <Page>
        {/* head meta */}
        <Helmet>
          <html lang="en" className={styles.supplierSales} />
          <title>My Sales</title>
        </Helmet>

        <SecondaryNavBar className={styles.secondaryNavBar} backReplace>
          My Sales
        </SecondaryNavBar>

        {/* content */}
        <div>
          {/* title */}
          <section className={styles.header}>
            <h1 className={styles.title}>My Sales</h1>
          </section>

          {/* filter */}
          <section className={styles.category}>
            <div>
              <Input.Search
                className={styles.inputs}
                size="large"
                allowClear
                placeholder="Search Orders"
                value={keywordValue}
                onChange={(e) => setKeywordValue(e.target.value)}
                onSearch={handleSearch}
              />
            </div>

            <div className={styles.order_status_filter_wrapper}>
              <OrderStatusFilter options={orderStatusFilterOptions} searchKey="status" />
            </div>
          </section>
          {/* table */}
          <StatusViewRenderer
            data={ordersData}
            error={orders.error}
            isLoading={orders.isLoading}
            hasMore={orders.hasNextPage}
            loadMore={orders.fetchNextPage}
            statusStyle={{ marginTop: 64 }}
            emptyContent={<span className={styles.empty_content}>{noOrdersHint}</span>}
          >
            <OrderList
              data={ordersData}
              onSelect={() => null}
              selectedIds={[null]}
              showSecondaryAction={false}
              orderDetailPath={allPaths.supplierOrderDetail}
              viewer="supplier"
            />
          </StatusViewRenderer>
        </div>
      </Page>
    </Frame>
  );
}

export default SupplierSales;
