import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';

import AutoLink from '@/components/AutoLink';
import { allPaths } from '@/utils/path';

type BaseStoreType = {
  id: string | number;
  jennifer: null | Record<string, unknown>;
};
type StoreTypes = BaseStoreType;

const subMenuLists = [
  {
    title: 'My Sales',
    getTo: (store: StoreTypes) => `${allPaths.supplierSales}/${store.id}`,
  },
  {
    title: 'Manage Listings',
    getTo: (store: StoreTypes) => `${allPaths.catalogForRetail}/${store.id}`,
  },
  {
    title: 'Selling Price Rules',
    getTo: (store: StoreTypes) => `${allPaths.sellingPriceRules}/${store.id}`,
  },
  {
    title: 'Payout Report',
    getTo: (store: StoreTypes) => `${allPaths.payout}/${store.id}`,
  },
];

export const SubSupplierMenu: FC<{
  store: StoreTypes;
}> = ({ store }) => {
  if (isEmpty(store.jennifer)) {
    return null;
  }
  return (
    <>
      <p
        style={{
          paddingLeft: '10px',
          backgroundColor: '#FAE9E9',
          margin: '10px 0',
        }}
      >
        Sell on Kiwi
      </p>

      {subMenuLists.map(({ title, getTo }) => (
        <AutoLink key={title} to={getTo(store)}>
          {title}
        </AutoLink>
      ))}
    </>
  );
};
