$prefixCls: app;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  min-height: 100vh;
  &-error_boundary_wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

:global {
  .adm-switch-checkbox {
    --width: 46px;
    --height: 26px;
    background: #e8e8e8;

    &::before {
      background: #e8e8e8;
    }

    .adm-switch-spin-icon > g > g {
      fill: var(--color-primary);
    }
  }

  .adm-button.adm-button-primary,
  .adm-button.adm-button-default {
    font-size: var(--font-size16);
  }

  .adm-button.adm-button-mini {
    font-size: var(--font-size12);
    font-weight: 400;
  }

  .adm-button.adm-button-small {
    font-size: var(--font-size3);
    font-weight: 400;
  }

  .Polaris-Button--primary {
    color: #000 !important;
  }
}
