import produce from 'immer';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { deleteShippingAddress } from '@/services/ds';

export default function useDeleteShippingAddress() {
  const queryClient = useQueryClient();

  return useMutation((id: number | string) => deleteShippingAddress(id), {
    onSuccess: (data, id) => {
      queryClient.setQueryData('shippingAddresses', (prevData: any) =>
        produce(prevData, (draft: any) => {
          const addressIndex = _.findIndex(draft?.data, { id });
          if (addressIndex !== -1) draft?.data?.splice(addressIndex, 1);
        })
      );

      queryClient.invalidateQueries('shippingAddresses');
    },
  });
}
