import { Divider } from 'antd';
// import cn from 'classnames';
import currency from 'currency.js';
import _ from 'lodash';
import { useCallback } from 'react';

import KiwiWalletSelect from '@/components/KiwiWalletSelect';
import type { OrderItem } from '@/pages/Orders/types';
import { OrderStatus } from '@/pages/Orders/types';
import { formatCredit, formatterPrice } from '@/utils/util';

import { getOrderCostRelated } from '../../util';
import css from './index.module.scss';

type Props = {
  order: OrderItem;
  walletSelectable?: boolean;
  onChangeKiwiWallet?: (val?: number) => void;
};

const CostPanel = ({ order, walletSelectable, onChangeKiwiWallet }: Props) => {
  const {
    total_items_price,
    total_shipping_price,
    total_tax,
    total_items_discounts,
    total_items_subtotal,
    total_shipping_discounts,
    total_shipping_subtotal,
    total_price,
    paid_by_credit,
    paid_by_card,
    total_shipping_price_preorder,
  } = getOrderCostRelated(order);
  const isUnPaidOrder = order?.status === OrderStatus.Unpaid;

  const updateKiwiWallet = useCallback(
    (value) => {
      if (onChangeKiwiWallet) {
        onChangeKiwiWallet(value);
      }
    },
    [onChangeKiwiWallet]
  );

  return (
    <div className={css.cost_panel_comp}>
      <div className={css.cost_panel_comp_content}>
        {/* product */}
        {!!total_items_discounts && (
          <>
            <div className={css.block_one_desc}>
              <span>Product Price </span>
              <span>${formatterPrice(total_items_price)}</span>
            </div>
            <div className={css.block_one_desc}>
              <span>Product Discount {order?.discount_code && ` - ${order?.discount_code}`} </span>
              <span>-${formatterPrice(total_items_discounts)}</span>
            </div>
          </>
        )}
        <div className={css.block_one_desc}>
          <span>Product Subtotal </span>
          <span>{total_items_subtotal ? `$${formatterPrice(total_items_subtotal)}` : '-'}</span>
        </div>
        {/* shipping */}
        {!!total_shipping_discounts && (
          <>
            <div className={css.block_one_desc}>
              <span>Shipping Price {order?.shipping_method && `- (${_.upperFirst(order?.shipping_method)})`} </span>
              <span>${formatterPrice(total_shipping_price)}</span>
            </div>
            <div className={css.block_one_desc}>
              <span>Shipping Discount{order?.discount_code && ` - ${order?.discount_code}`} </span>
              <span>-${formatterPrice(total_shipping_discounts)}</span>
            </div>
          </>
        )}
        <div className={css.block_one_desc}>
          <span>Shipping Subtotal </span>
          <span>{total_shipping_subtotal ? `$${formatterPrice(total_shipping_subtotal)}` : '-'}</span>
        </div>
        {!!total_shipping_price_preorder && (
          <div className={css.block_one_desc}>
            <span className={css.pre_order_shipping}>incl. Preorder</span>
            <span>${formatterPrice(total_shipping_price_preorder)}</span>
          </div>
        )}
        {/* Tax */}
        <div className={css.block_one_desc}>
          <span>Tax </span>
          <span>{total_tax ? `$${formatterPrice(total_tax)}` : '-'}</span>
        </div>
        {/* 注意：unpaid状态单子要和其他状态区分展示 */}
        {isUnPaidOrder ? (
          <>
            <Divider dashed style={{ margin: '8px 0' }} />
            <div className={css.block_one_desc}>
              <strong>Total Price</strong>
              <span>{total_price ? `$${formatterPrice(total_price)}` : '-'}</span>
            </div>
            <Divider dashed style={{ margin: '8px 0' }} />
            <KiwiWalletSelect order={order} onChange={updateKiwiWallet} selectable={walletSelectable} />
            <Divider dashed style={{ margin: '8px 0' }} />
            <div className={css.block_one_desc}>
              <strong>Total to Pay</strong>
              <strong>
                $
                {formatterPrice(
                  currency(total_price).subtract(paid_by_card).subtract(currency(paid_by_credit).divide(100).value)
                    .value
                )}
              </strong>
            </div>
          </>
        ) : (
          <>
            <Divider dashed style={{ margin: '8px 0' }} />
            <div className={css.block_one_desc}>
              <strong>Total Price</strong>
              <strong>{total_price ? `$${formatterPrice(total_price)}` : '-'}</strong>
            </div>
            {!!paid_by_card && (
              <div className={css.block_one_desc}>
                <span>Paid by Card</span>
                <span>${formatterPrice(paid_by_card)}</span>
              </div>
            )}
            {!!paid_by_credit && (
              <div className={css.block_one_desc}>
                <span>Paid by Credits</span>
                <span>${formatCredit(paid_by_credit)}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CostPanel;
