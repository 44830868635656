.ns_com_import_product_list_main {
  display: grid;
  grid-template-columns: 100%;
  gap: var(--spacing-xxl);
  .product_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .cover {
    margin-right: var(--spacing-m);
    cursor: pointer;
    .image {
      width: 90px;
      height: 120px;
      border-radius: 4px;
    }
  }
  .info {
    flex: 1;
    min-width: 0;
  }
  .info_title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--color-black);
    font-size: var(--font-size2);
    font-weight: 500;
    line-height: 1.3;
    cursor: pointer;
  }
  .variants_prices {
    .group {
      margin-top: var(--spacing-s);
      .group_title {
        margin-bottom: var(--spacing-xs);
        min-height: 11px;
        color: var(--color-gray5);
        font-size: var(--font-size2);
        font-weight: 500;
        line-height: 1.3;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:empty {
          display: none;
        }
      }
      .price_columns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }
      .price_columns_item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: var(--color-gray5);
        font-size: var(--font-size2);
        font-weight: normal;
        line-height: 1.3;
        .text_red {
          color: #ff0000;
        }
      }
    }
  }
  .price_columns_item_title {
    color: var(--color-gray4);
    font-size: var(--font-size1);
    line-height: 1.4;
  }

  .input_wrapper {
    position: relative;
    padding-top: 9px;

    &:not(:first-child) {
      margin-top: var(--spacing-xs);
    }

    .price_columns_item_title {
      position: absolute;
      z-index: 2;
      padding: 0 var(--spacing-xxs);
      margin-left: var(--spacing-xs);
      margin-top: -9px;
      line-height: 18px;
      background: var(--color-white);
    }
  }

  @at-root.price_columns_item_price {
    :global {
      .ant-input-number {
        width: 122px;
        border: 1px solid var(--color-gray4);
        border-radius: 4px;
      }

      .ant-input-number {
        border-radius: 4px;
      }

      .ant-input-number-input {
        padding: 0 var(--spacing-m) !important;
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1.3;
        text-align: right;
        border-radius: 4px;
      }
    }
  }
}
