import { forwardRef, useImperativeHandle, useState } from 'react';

import ImageTimeline from '@/assets/images/combine_setting_timeline.png';
import Button from '@/components/Button';
import Image from '@/components/Image';
import Modal from '@/components/Modal';
import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import useDesktop from '@/hooks/useDesktop';

import css from './ModalLiveSelling.module.scss';

type Props = {
  onContinue: () => void;
};
type Ref = {
  show: () => void;
  hide: () => void;
};

const App = forwardRef<Ref, Props>(({ onContinue }, ref) => {
  const isDesktop = useDesktop();
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const handleContinue = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.hideLiveSellingModal, 'true');
    onClose();
    onContinue?.();
  };

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
    hide: onClose,
  }));

  return (
    <Modal
      className={css.order_live_selling_modal_comp}
      title="How to Auto Combine"
      visible={visible}
      onClose={onClose}
      fullscreen={!isDesktop}
      footer={
        <Button shape="rounded" color="primary" onClick={handleContinue}>
          Continue to Settings
        </Button>
      }
    >
      <div className={css.order_live_selling_modal_content}>
        <div className="banner-swiper-pagination" />
        <div className={css.desc}>Here’s how we combine orders</div>
        <div>
          <Image src={ImageTimeline} fit="cover" />
        </div>
      </div>
    </Modal>
  );
});

export default App;
