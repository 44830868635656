import { Button, Drawer, message, Modal as Modal2 } from 'antd';
import { FiX } from 'react-icons/fi';

import Modal from '@/components/Modal';
import { useDesktop } from '@/hooks';

import { useDeactivateCatalogProduct } from '../../hooks/useDeactivateCatalogProduct';
import { hideConfirmStateModal, hideEditStateModal, useCatalogRetailStore } from '../../store';
import styles from './index.module.scss';

export const EditStateModal = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const isDesktop = useDesktop();

  const selectedProduct = useCatalogRetailStore((state) => state.selectedProduct);
  const isEditStateModalVisible = useCatalogRetailStore((state) => state.isEditStateModalVisible);

  const { mutation } = useDeactivateCatalogProduct();

  const handleClick = async () => {
    try {
      await mutation.mutateAsync({
        product_ids: selectedProduct.map((item) => item.id),
      });
      hideEditStateModal();
      refetch();
      // showConfirmStateModal();
      Modal2.success({
        title: 'The update is being processed. It will need 10-30 seconds to take affect.',
        centered: true,
      });
    } catch (error) {
      message.error(error.message || 'Unknown Error');
    }
  };

  const content = (
    <div className={styles.EditStateModal_content}>
      <div>
        <p>
          Are you sure to deactivate{' '}
          {selectedProduct?.length > 1 ? `these ${selectedProduct.length} products` : 'this product'}?
        </p>
      </div>
      <div className={styles.btnWrap}>
        <Button loading={mutation.isLoading} block shape="round" type="primary" onClick={handleClick}>
          Yes
        </Button>
      </div>
    </div>
  );

  if (isDesktop) {
    return (
      <Modal title="Deactivate" onClose={hideEditStateModal} visible={isEditStateModalVisible}>
        {content}
      </Modal>
    );
  }

  return (
    <Drawer
      closable={false}
      title="Deactivate"
      placement="bottom"
      open={isEditStateModalVisible}
      onClose={hideEditStateModal}
      height={200}
      headerStyle={{
        textAlign: 'center',
        borderRadius: '8px 8px 0 0',
      }}
      extra={<FiX strokeWidth="1.5" onClick={hideEditStateModal} />}
    >
      {content}
    </Drawer>
  );
};

export const EditPriceSuccessModal = () => {
  const isConfirmStateModalVisible = useCatalogRetailStore((state) => state.isConfirmStateModalVisible);
  return (
    <Modal visible={isConfirmStateModalVisible} title="Congrats! " onClose={hideConfirmStateModal}>
      <p
        style={{
          padding: '20px 0 ',
        }}
      >
        Active products are ready to go and showing in Kiwi network!
      </p>
      <div
        style={{
          width: '50%',
          margin: '0 auto',
        }}
      >
        <Button shape="round" block onClick={hideConfirmStateModal} type="primary">
          Ok
        </Button>
      </div>
    </Modal>
  );
};
