import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { getSettings } from '@/services/ds';

interface BuilderPreviewSettings {
  data?: {
    builderSetting?: any;
  };
}

const isInPreviewMode = window.location.pathname === '/builder';
export default function useSettings(params?: any) {
  const settingsRef = useRef(null);

  const queryClient = useQueryClient();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isInPreviewMode) {
      const handler = (data: BuilderPreviewSettings) => {
        if (data?.data?.builderSetting) {
          settingsRef.current = {
            data: data?.data?.builderSetting,
          };
          queryClient.invalidateQueries('getSettings');
        }
      };
      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }
  }, [queryClient]);

  return useQuery(
    ['getSettings', params],
    isInPreviewMode ? () => Promise.resolve(settingsRef.current) : () => getSettings(params)
  );
}
