import cn from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { BsUpcScan } from 'react-icons/bs';
import { FiHeart, FiMenu, FiShoppingCart } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import onboardingImage02 from '@/assets/images/guides/onboarding-02.png';
import onboardingImage03 from '@/assets/images/guides/onboarding-03.png';
import onboardingImage04 from '@/assets/images/guides/onboarding-04.png';
import onboardingImage05 from '@/assets/images/guides/onboarding-05.png';
import AccountMenu from '@/components/AccountMenu';
import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import PCNavMenu from '@/components/NavBar/PCNavMenu';
import NoticeBar, { getShouldShowNotices } from '@/components/NoticeBar';
import StepGuideDialog from '@/components/StepGuideDialog';
import { LOGO_BASE64 } from '@/constants/biz';
import { useDesktop } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import useMessages from '@/hooks/queries/useMessages';
import useSettings from '@/hooks/queries/useSettings';
import useUpdateMessagesReadStatus from '@/hooks/queries/useUpdateMessagesReadStatus';

import css from './index.module.scss';
import MobileNavMenu from './MobileNavMenu';
import SearchButton from './SearchButton';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function NavBar({ className, style }: Props): JSX.Element {
  const location = useLocation();
  const isDesktop = useDesktop();
  const { user } = useAuth();
  const isLogin = !_.isEmpty(user);
  const settings = useSettings();
  const messages = useMessages();
  const updateMessagesReadStatus = useUpdateMessagesReadStatus();
  const [noticeBarVisible, setNoticeBarVisible] = useState(true);
  const [mobileNavMenuVisible, setMobileNavMenuVisible] = useState(false);
  const [onboardingDialogVisible, setOnboardingDialogVisible] = useState(false);
  const notices = settings?.data?.data?.['global-registry']?.data?.notices;
  const shouldShowNotice = !_.isEmpty(getShouldShowNotices(notices));
  const lastMessage = messages?.data?.data?.messages?.[0];
  const shouldShowOnboarding =
    _.includes(['shop-install', 'shop-uninstall'], lastMessage?.type) && lastMessage?.is_read === 0;

  useEffect(() => {
    if (shouldShowNotice && noticeBarVisible) {
      document.documentElement.dataset.noticeBarActive = '';
    } else {
      delete document.documentElement.dataset.noticeBarActive;
    }

    return () => {
      delete document.documentElement.dataset.noticeBarActive;
    };
  }, [shouldShowNotice, noticeBarVisible]);

  useEffect(() => {
    // update messages when location changed
    messages.refetch({ cancelRefetch: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    shouldShowOnboarding && setOnboardingDialogVisible(true);
  }, [messages.data, shouldShowOnboarding]);

  const handleOnboardingDialogClose = () => {
    setOnboardingDialogVisible(false);
    updateMessagesReadStatus.mutate({ messageIds: lastMessage?.id });
  };

  // const handleScan = useCallback((e) => {
  //   e.stopPropagation();
  //   console.log(999);

  //   //  打开新页面
  // }, []);

  return (
    <div className={cn(css.ns_com_nav_bar_main, className)} style={style}>
      {shouldShowNotice && noticeBarVisible && (
        <NoticeBar className={css.notice_bar} closeable notices={notices} onClose={() => setNoticeBarVisible(false)} />
      )}

      {/* mobile NavBar */}
      {!isDesktop && (
        <div className={css.mobile}>
          <div className={css.left}>
            {/* <span onClick={handleScan}>
              <BsUpcScan />
            </span> */}
            <div className={css.icon_btn} onClick={() => setMobileNavMenuVisible(true)}>
              <FiMenu />
            </div>
            <SearchButton />
          </div>
          <div>
            <AutoLink to="/">
              <img className={css.logo} src={LOGO_BASE64} alt="Kiwi & Drop" />
            </AutoLink>
          </div>
          <div className={css.right}>
            {isLogin ? (
              <>
                <AutoLink to="/seller-collections">
                  <div className={css.icon_btn}>
                    <FiHeart />
                  </div>
                </AutoLink>
                <AutoLink to="/cart">
                  <div className={css.icon_btn}>
                    <FiShoppingCart />
                  </div>
                </AutoLink>
              </>
            ) : (
              <Button
                color="primary"
                size="small"
                shape="rounded"
                link={`/login?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
              >
                Log In
              </Button>
            )}
          </div>
          <MobileNavMenu visible={mobileNavMenuVisible} onClose={() => setMobileNavMenuVisible(false)} />
        </div>
      )}

      {/* desktop NavBar */}
      {isDesktop && (
        <div className={cn(css.desktop, [css.new_pc_nav_menu])}>
          <div className={css.main}>
            <div className={css.main_item}>
              <AutoLink to="/">
                <img className={css.logo} src={LOGO_BASE64} alt="Kiwi & Drop" />
              </AutoLink>
            </div>

            <div className={css.main_item}>
              <SearchButton />
            </div>

            <div className={css.main_item}>
              {isLogin && (
                <>
                  <AutoLink to="/seller-collections">
                    <div className={css.icon_btn}>
                      <FiHeart />
                    </div>
                  </AutoLink>
                  <AutoLink to="/cart">
                    <div className={css.icon_btn}>
                      <FiShoppingCart />
                    </div>
                  </AutoLink>
                </>
              )}
              <AccountMenu />
            </div>
          </div>
          <PCNavMenu />
        </div>
      )}

      {/* store install/uninstall success related onboarding dialog */}
      {shouldShowOnboarding && (
        <StepGuideDialog visible={onboardingDialogVisible} showFinishButton onClose={handleOnboardingDialogClose}>
          {lastMessage?.type === 'shop-uninstall' && (
            <StepGuideDialog.Step
              coverIcon="🤧"
              title={
                <>
                  {lastMessage?.data?.name} ({lastMessage?.data?.shopify_domain}) has been disconnected from KiwiDrop.
                </>
              }
              description="We’ll miss you!"
            />
          )}
          {lastMessage?.type === 'shop-install' && (
            <>
              <StepGuideDialog.Step
                coverIcon="😁"
                title="Getting Started!"
                description="Welcome to the KiwiDrop! Unlock the magic by following this quick guide!"
              />
              <StepGuideDialog.Step
                coverImage={onboardingImage02}
                coverImageSize={{ width: 335, height: 210 }}
                title="Import a Product"
                description="Press the Import to Store button to add a product to your online store."
              />
              <StepGuideDialog.Step
                coverImage={onboardingImage03}
                coverImageSize={{ width: 319, height: 210 }}
                title="Check Inventory & Handle Orders"
                description="Go to “Store” to 1) Check the inventory status of imported products and 2) Handle orders all in one place."
              />
              <StepGuideDialog.Step
                coverImage={onboardingImage04}
                coverImageSize={{ width: 322, height: 210 }}
                title="Check Out"
                description="Once your Shopify receives orders, find the order under “Orders”,  tap “Check Out” to start the fulfillment process."
              />
              <StepGuideDialog.Step
                coverImage={onboardingImage05}
                coverImageSize={{ width: 322, height: 210 }}
                title="Stay Updated"
                description="Track your order easily by filtering your orders to “Shipped”."
              />
            </>
          )}
        </StepGuideDialog>
      )}
    </div>
  );
}

export default NavBar;
