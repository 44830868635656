import { Form, message, Select } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modal';
import { LOCAL_STORAGE_KEYS } from '@/constants/app';
import { useOrderAddCombine, useOrderGetCombine } from '@/hooks/queries/useOrderCombine';
import useDesktop from '@/hooks/useDesktop';
import { formatterPrice } from '@/utils/util';

import { defaultCombineOrder } from '../../constant';
import type { ShippingAddress } from '../../types';
import { getOrderCostRelated } from '../../util';
import CostPanel from '../CostPanel';
import TableCombineOrder from '../TableCombineOrder';
import css from './index.module.scss';

const { Option, OptGroup } = Select;

type Props = {
  selectedIds: string[];
  successCallback?: () => void;
};

const formatterShippingAddress = (data: ShippingAddress): string => {
  const { address1, address2, city, first_name, last_name, province, zip } = data;
  const name = _.join(_.compact([first_name, last_name].map((i) => i?.trim())), ', ');
  const addressDetail = `${address1 || address2 || ''}`.trim();
  const cityProvince = _.join(_.compact([city, province, zip].map((i) => i?.trim())), ', ');
  const description = _.join(_.compact([name, addressDetail, cityProvince].map((i) => i?.trim())), ', ');
  return description;
};

const formatterShippingAddresses = ({
  forCustomer,
  forIndividual,
}: {
  forCustomer: ShippingAddress[];
  forIndividual: ShippingAddress[];
}) => [
  {
    groupLabel: 'Customer Addresses',
    options: forCustomer.map((item) => ({
      label: `${formatterShippingAddress(item)}`,
      value: JSON.stringify(item),
    })),
  },
  {
    groupLabel: 'Your Addresses',
    options: forIndividual.map((item) => ({
      label: `${formatterShippingAddress(item)}`,
      value: JSON.stringify(item),
    })),
  },
];

const App = ({ selectedIds, successCallback = () => {} }: Props) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const isDesktop = useDesktop();
  const [checked, setChecked] = useState(true);
  const [visible, setVisible] = useState(false);
  const [comfirmationModalVisible, setComfirmationModalVisible] = useState(false);
  const { isLoading, mutate, data } = useOrderGetCombine();
  const { isLoading: isAddLoading, mutate: addMutate } = useOrderAddCombine();

  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');

  const shippingAddresses = formatterShippingAddresses({
    forCustomer: data?.data?.customer_addresses || [],
    forIndividual: data?.data?.individual_addresses || [],
  });
  const order = data?.data?.order || defaultCombineOrder;
  const defaultShippingAddress = order?.shipping_address;
  const { total_price } = getOrderCostRelated(order);
  const disabledCombineAndProcessButton = selectedIds?.length < 2;

  const getCombineOrder = (onSuccess?: () => void) => {
    mutate(
      {
        dry_run: true,
        order_ids: selectedIds,
      },
      {
        onSuccess() {
          onSuccess?.();
        },
        onError(e: Error) {
          message.error(e.message);
        },
      }
    );
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onCloseConfirmModal = () => {
    setComfirmationModalVisible(false);
  };

  const confirmCombineAndProcess = (onSuccess?: () => void) => {
    const shippingAddress = JSON.parse(form.getFieldValue('shippingAddress'));
    addMutate(
      {
        dry_run: false,
        order_ids: selectedIds,
        shipping_address: shippingAddress,
      },
      {
        onSuccess() {
          successCallback?.();
          message.success(`${selectedIds?.length > 1 ? 'Orders' : 'Order'} combined successfully`);
          onSuccess?.();
          queryClient.invalidateQueries('orders');
        },
        onError(e: Error) {
          message.error(e?.message);
        },
      }
    );
  };

  const toCombineAndProcess = () => {
    getCombineOrder(() => {
      setVisible(true);
    });
  };

  const handleCombineAndProcess = () => {
    const hideCombineAndProcessComfirmationModal = localStorage.getItem(
      LOCAL_STORAGE_KEYS.hideCombineAndProcessComfirmationModal
    );
    form.validateFields().then(() => {
      if (hideCombineAndProcessComfirmationModal) {
        confirmCombineAndProcess();
      } else {
        setComfirmationModalVisible(true);
      }
    });
  };

  const toConfirm = () => {
    confirmCombineAndProcess(() => {
      if (checked) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.hideCombineAndProcessComfirmationModal, 'true');
      }
    });
  };

  const handleValuesChange = (values) => {
    setSelectedShippingAddress(values?.shippingAddress || '');
  };

  useEffect(() => {
    if (defaultShippingAddress) {
      const value = JSON.stringify(defaultShippingAddress);
      form.setFieldsValue({
        shippingAddress: value,
      });
      setSelectedShippingAddress(value);
    }
  }, [defaultShippingAddress, form]);

  const sections = [
    {
      title: 'Ship to',
      content: (
        <Form form={form} onValuesChange={handleValuesChange}>
          <Form.Item name="shippingAddress" rules={[{ required: true, message: 'Field is required.' }]}>
            <Select className={css.select} allowClear popupClassName={css.combine_and_process_select_dropdown_wrapper}>
              {shippingAddresses.map(({ groupLabel, options }) => (
                <OptGroup label={<div className={css.label}>{groupLabel}</div>}>
                  {options.map(({ value, label }) => (
                    <Option value={value} key={value}>
                      <div className={cn(css.option, { [css.selected]: selectedShippingAddress === value })}>
                        {label}
                        <span className={css.selected_icon} />
                      </div>
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Orders',
      content: <TableCombineOrder data={order} />,
    },
    {
      title: 'Cost',
      content: (
        <div className={css.combined_cost}>
          <CostPanel order={order} walletSelectable={false} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Button
        disabled={disabledCombineAndProcessButton || isLoading}
        loading={isLoading}
        color="main"
        size="small"
        onClick={toCombineAndProcess}
      >
        Combine & Process
      </Button>
      <Modal
        title="Combine Orders"
        visible={visible}
        onClose={onClose}
        className={css.order_page_modal_combine_orders}
        fullscreen={!isDesktop}
        footer={null}
      >
        <>
          <div className={css.order_page_modal_combine_orders_content}>
            {sections.map(({ title, content }) => (
              <section key={title}>
                <div className={css.title}>{title}</div>
                <div className={css.content}>{content}</div>
              </section>
            ))}
          </div>
          <div className={css.order_page_modal_combine_orders_footer}>
            <div className={css.total_cost}>
              <span>Total Price</span>
              <span>${formatterPrice(total_price)}</span>
            </div>
            <Button loading={isAddLoading} color="primary" shape="rounded" onClick={handleCombineAndProcess}>
              Combine & Process
            </Button>
          </div>
        </>
      </Modal>
      <Modal
        title="Combine Orders"
        visible={comfirmationModalVisible}
        onClose={onCloseConfirmModal}
        className={cn(css.order_page_modal_combine_settings_confirm, isDesktop ? css.pc : css.m)}
        footer={null}
      >
        <div className={css.modal_combine_settings_confirm_content}>
          <div>
            We’ll be combine your orders and then process them. Make sure the address is correct as you will not be able
            to make changes to this order.
          </div>
          <Button loading={isAddLoading} shape="rounded" color="main" onClick={toConfirm}>
            Confirm
          </Button>
          <div className={css.show_again}>
            <Checkbox color="primary" checked={checked} onChange={setChecked} />
            <span>Don’t show again</span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default App;
