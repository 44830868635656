import { ActionList, Badge, Box, Button, Divider, Icon, Inline, Popover } from '@shopify/polaris';
import { DragHandleMinor, HorizontalDotsMinor } from '@shopify/polaris-icons';
import { message } from 'antd';
import { Modal as ModalAntd } from 'antd-mobile';
import { useCallback, useState } from 'react';

import useDeletePaymentMethod from '@/hooks/queries/useDeletePaymentMethod';

import { DEL_CONTENT_DEFAULT_TEXT, DEL_CONTENT_TEXT } from '../cons';
import { PaymentMethodListItemProps, PaymentMethodTypeEnum } from '../types';
import BankCreditIcon from './BankCreditIcon';
import css from './ListItem.module.scss';
import PaymentMethodTitle from './PaymentMethodTitle';

const ListItem = ({
  data,
  onSelect,
  draggable = false,
  isDefault = false,
  showDivider = false,
  showAction = true,
}: PaymentMethodListItemProps): JSX.Element => {
  const deletePaymentMethod = useDeletePaymentMethod();
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActivState) => !popoverActivState), []);
  const isBank = data?.type === PaymentMethodTypeEnum.Bank;

  const handleDelete = useCallback(() => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: isBank ? 'Remove Bank Account' : 'Remove Card',
      content: isDefault ? DEL_CONTENT_DEFAULT_TEXT : DEL_CONTENT_TEXT,
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        deletePaymentMethod.mutate(data?.id, {
          onSettled: (resData, error) => {
            error ? message.error((error as Error)?.message || 'Delete Failure!') : message.success('Delete Success!');
          },
        });
      },
    });
  }, [data?.id, deletePaymentMethod, isBank, isDefault]);

  const activator = (
    <Button
      onClick={togglePopoverActive}
      plain
      removeUnderline
      icon={<Icon source={HorizontalDotsMinor} color="base" />}
    />
  );

  return (
    <>
      <Box background="surface" padding="4" borderRadius="05">
        <div className={css.ns_com_payment_methods_dialog_list_item_main} onClick={() => onSelect?.(data)}>
          <Inline wrap={false} blockAlign="center" align="start">
            <div style={{ flex: 1 }}>
              <Inline gap="2" wrap={false} blockAlign="center" align="start">
                {draggable && (
                  <span className={css.list_item_drag_btn_wrapper}>
                    <Icon source={DragHandleMinor} color="base" />
                  </span>
                )}
                <Inline gap="2" wrap={false} blockAlign="center" align="start">
                  <BankCreditIcon data={data} />
                  <PaymentMethodTitle data={data} />
                  {isDefault && <Badge size="small">Default</Badge>}
                </Inline>
              </Inline>
            </div>
            {showAction && (
              <Popover
                active={popoverActive}
                activator={activator}
                autofocusTarget="first-node"
                onClose={togglePopoverActive}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[{ destructive: true, content: 'Delete', onAction: handleDelete }]}
                />
              </Popover>
            )}
          </Inline>
        </div>
      </Box>

      {showDivider && <Divider borderStyle="base" />}
    </>
  );
};

export default ListItem;
