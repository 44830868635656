$prefixCls: landing-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app {
      padding-top: 1px;
      background: url(../../assets/images/landing-bg-mobile.jpg) no-repeat center top / 100% auto;
      background-color: var(--color-white);

      @media screen and (min-width: 769px) {
        background-image: url(../../assets/images/landing-bg-desktop.jpg);
      }
    }
  }

  &__content {
    --font-family: 'Jost', Futura, sans-serif;
    position: relative;
    color: var(--color-black);
    font-family: var(--font-family);
    font-size: var(--font-size3);
    line-height: 1.4;
    overflow: hidden;
  }

  &__login-btn {
    position: absolute;
    right: var(--spacing-l);
    top: var(--spacing-l);
  }

  &__section {
    $section: &;
    margin: 0 var(--spacing-l);
    padding: var(--spacing-l);
    background: var(--color-white);
    border-radius: 4px;
    box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.2);

    & + & {
      margin-top: 40px;
    }

    &-title {
      margin-bottom: var(--spacing-l);
      font-size: var(--font-size8);
      line-height: 1;
      text-align: center;
    }

    &-hero {
      margin-top: 80px;
      padding: 24px var(--spacing-l);
      text-align: center;

      a {
        color: var(--color-black) !important;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    &-intro {
      padding: 0;
      background: none;
      box-shadow: none;

      .adm-list-body {
        background-color: transparent;
        border: none;
      }

      .adm-list-item-content-main {
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1.4;
      }

      .adm-collapse-panel-header {
        border-radius: 4px;

        .adm-list-item-content-main {
          font-size: 24px;
        }

        .adm-list-item-content-arrow {
          color: var(--color-black);
          font-size: 12px;
        }
      }

      .adm-list-item-content {
        border-top: none;
      }

      .adm-collapse-panel-content {
        margin-top: -3px;
        margin-bottom: 12px;
        border-radius: 0 0 4px 4px;
      }
    }

    &-about {
      margin: 40px 0 0;
      padding-bottom: 68px;

      #{$section}-title {
        line-height: 1.4;
      }
    }
  }

  &__logo {
    display: block;
    width: auto;
    height: 24px;
    margin: 0 auto 24px;
  }

  &__form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto;

    .button {
      margin-top: var(--spacing-l);
      width: 174px;
    }
  }

  &__copy-2 {
    span {
      display: block;
      margin-top: var(--spacing-m);
    }
  }

  &__copy-3 {
    display: none;
  }

  &__login-row {
    margin-top: 24px;
  }

  @media screen and (min-width: 769px) {
    &__login-btn {
      position: absolute;
      right: 4.2328vw;
      top: 0.661vw;
      width: 100px;
    }

    &__section {
      $section: &;

      &-hero {
        margin: 246px auto 0;
        padding: 24px;
        width: 864px;

        #{$section}-title {
          br {
            display: none;
          }
        }
      }

      &-intro {
        margin: 216px 0 0 !important;
        padding: 60px;
        background: var(--color-white);
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.2);

        #{$section}-inner-wrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 60px;
          max-width: 1392px;
          margin: 0 auto;
        }

        .adm-list-body {
          filter: none;
        }

        .adm-collapse-panel-header {
          pointer-events: none;

          .adm-list-item-content-arrow {
            display: none;
          }
        }

        .adm-collapse-panel-content {
          margin-bottom: 0;
          padding-bottom: 0;

          .adm-list-item-content-main {
            padding-bottom: 0;
          }
        }
      }

      &-about {
        margin-top: 220px !important;
      }
    }

    &__logo {
      display: none;
    }

    &__form-row {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 470px;

      .ant-input {
        flex: 1;
      }

      .button {
        margin: 0 0 0 var(--spacing-l);
      }
    }

    &__copy-2 {
      span {
        display: inline;
        margin-top: 0;
      }
    }

    &__copy-3 {
      display: block;
    }

    &__copy-4 {
      width: 698px;
      margin: 0 auto;
    }
  }
}
