/* eslint-disable react/no-unescaped-entities */
import cn from 'classnames';
import Helmet from 'react-helmet';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function PrivacyPolicy({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Privacy Policy</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy describes how app.kiwidrop.com (the “Site” or “we”) collects, uses, and discloses your
          Personal Information when you visit or make a purchase from the Site.
        </p>
        <h3>COLLECTING PERSONAL INFORMATION</h3>
        <p>
          When you visit the Site, we collect certain information about your device, your interaction with the Site, and
          information necessary to process your purchases. We may also collect additional information if you contact us
          for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an
          individual (including the information below) as “Personal Information”. See the list below for more
          information about what Personal Information we collect and why.
        </p>
        <p>Device Information</p>
        <ul>
          <li>
            <strong>Examples of Personal Information collected:</strong> version of web browser, IP address, time zone,
            cookie information, what sites or products you view, search terms, and how you interact with the Site.
          </li>
          <li>
            <strong>Purpose of collection:</strong> to load the Site accurately for you, and to perform analytics on
            Site usage to optimize our Site.
          </li>
          <li>
            <strong>Source of collection:</strong> Collected automatically when you access our Site using cookies, log
            files, web beacons, tags, or pixels
          </li>
          <li>
            <strong>Disclosure for a business purpose:</strong> shared with our processor Paypal, Sezzle, Shop Pay,
            Amazon Web Services
          </li>
        </ul>
        <p>Order information</p>
        <ul>
          <li>
            <strong>Examples of Personal Information collected:</strong> name, billing address, shipping address,
            payment information (including credit card numbers), email address, and phone number.
          </li>
          <li>
            <strong>Purpose of collection:</strong> to provide products or services to you to fulfill our contract, to
            process your payment information, arrange for shipping, and provide you with invoices and/or order
            confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with
            the preferences you have shared with us, provide you with information or advertising relating to our
            products or services.
          </li>
          <li>
            <strong>Source of collection:</strong> collected from you.
          </li>
        </ul>
        <p>Minors</p>
        <p>
          The Site is not intended for individuals under the age of 13 . We do not intentionally collect Personal
          Information from children under the age of 13. If you are under 13, do not use or provide any information on
          the Site or through any of its features, register on the Website, make any purchases through the Website, use
          any of the interactive or public comment features of this Website, or provide any information about yourself
          to us, including your name, address, telephone number, email address, or any screen name or user name you may
          use. If we learn we have collected or received personal information from a child under 13 without verification
          of parental consent, we will delete that information. If you are the parent or guardian and believe your child
          has provided us with Personal Information, please contact us by email at{' '}
          <a href="mailto:support@kiwidrop.com" target="_blank" rel="noreferrer">
            support@kiwidrop.com
          </a>{' '}
          or at the address listed below to request deletion.
        </p>

        <h3>SHARING PERSONAL INFORMATION</h3>
        <p>
          We may disclose aggregated information about our users, and information that does not identify any individual,
          without restriction.
        </p>
        <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers, and other third parties we use to support our business.</li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of KiwiDrop’s assets, whether as a going concern or as
            part of bankruptcy, liquidation, or similar proceeding, in which personal information held by KiwiDrop about
            our Site users is among the assets transferred.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>With your consent.</li>
        </ul>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including to respond to any government or regulatory
            request.
          </li>
          <li>
            To enforce or apply our terms of use and other agreements, including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of KiwiDrop,
            our customers, or others.
          </li>
        </ul>

        <h3>BEHAVIORAL ADVERTISING</h3>
        <p>
          As described above, we use your Personal Information to provide you with targeted advertisements or marketing
          communications we believe may be of interest to you. For example:
        </p>
        <ul>
          <li>
            We use Google Analytics to help us understand how our customers use the Site. You can read more about how
            Google uses your Personal Information here:{' '}
            <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">
              https://policies.google.com/privacy?hl=en
            </a>
            . You can also opt-out of Google Analytics here:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </li>
          <li>
            We share information about your use of the Site, your purchases, and your interaction with our ads on other
            websites with our advertising partners. We collect and share some of this information directly with our
            advertising partners, and in some cases through the use of cookies or other similar technologies (which you
            may consent to, depending on your location).
          </li>
        </ul>
        <p>
          For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page at{' '}
          <a
            href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
            target="_blank"
            rel="noreferrer"
          >
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
          </a>
          .
        </p>
        <ul>
          <li>You can opt out of targeted advertising by:</li>
          <li>
            <em>FACEBOOK</em> -{' '}
            <a href="https://www.facebook.com/settings/?tab=ads" target="_blank" rel="noreferrer">
              https://www.facebook.com/settings/?tab=ads
            </a>
          </li>
          <li>
            <em>GOOGLE</em> -{' '}
            <a href="https://www.google.com/settings/ads/anonymous" target="_blank" rel="noreferrer">
              https://www.google.com/settings/ads/anonymous
            </a>
          </li>
          <li>
            <em>BING</em> -{' '}
            <a
              href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
              target="_blank"
              rel="noreferrer"
            >
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            </a>
          </li>
        </ul>
        <p>
          Additionally, you can opt-out of some of these services by visiting the Digital Advertising Alliance’s opt-out
          portal at:{' '}
          <a href="http://optout.aboutads.info/" target="_blank" rel="noreferrer">
            http://optout.aboutads.info/
          </a>
          .
        </p>

        <h3>USING PERSONAL INFORMATION</h3>
        <p>We use that we collect about you or that you provide to us, including your personal Information:</p>
        <ul>
          <li>To present our Site and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you request from us, which includes: offering
            products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date
            on new products, services, and offers.
          </li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any contracts entered into between you and
            us, including for billing and collection.
          </li>
          <li>To notify you about changes to our Site or any products or services we offer or provide though it.</li>
          <li>In any other way we may describe when you provide the information.</li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          We may also use your information to contact you about goods and services that may be of interest to you. If
          you do not want us to use your information in this way, please adjust your user preferences in your account
          profile.
        </p>

        <h3>LAWFUL BASIS</h3>
        <p>
          Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic
          Area (“EEA”), we process your personal information under the following lawful bases:
        </p>
        <ul>
          <li>Your consent;</li>
          <li>The performance of the contract between you and the Site;</li>
          <li>Compliance with our legal obligations;</li>
          <li>To protect your vital interests;</li>
          <li>To perform a task carried out in the public interest;</li>
          <li>For our legitimate interests, which do not override your fundamental rights and freedoms.</li>
        </ul>

        <h3>RETENTION</h3>
        <p>
          When you place an order through the Site, we will retain your Personal Information for our records unless and
          until you ask us to erase this information. For more information on your right of erasure, please see the
          ‘Your rights’ section below.
        </p>

        <h3>AUTOMATIC DECISION-MAKING</h3>
        <p>
          If you are a resident of the EEA, you have the right to object to processing based solely on automated
          decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise
          significantly affects you.
        </p>
        <p>
          We do not engage in fully automated decision-making that has a legal or otherwise significant effect using
          customer data.
        </p>
        <p>Services that include elements of automated decision-making include:</p>
        <ul>
          <li>
            Temporary denylist of IP addresses associated with repeated failed transactions. This denylist persists for
            a small number of hours.
          </li>
          <li>
            Temporary denylist of credit cards associated with denylisted IP addresses. This denylist persists for a
            small number of days.
          </li>
        </ul>

        <h3>DO NOT SELL</h3>
        <p>
          We do not sell your Personal Information in the conventional sense (i.e., for money). However, like many
          companies, we use services that collect information from you (to deliver advertisements to you for example)
          and this may classify this use as a “sale” of your Personal Information, You can request to opt-out out of
          this “sale” here: Do Not Sell My Personal Information. If you direct us not to sell your Personal Information,
          we will stop sharing your personal information if required by applicable law.
        </p>

        <h3>YOUR RIGHTS</h3>
        <p>GDPR</p>
        <p>
          If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to
          port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you
          would like to exercise these rights, please contact us by email at{' '}
          <a href="mailto:support@kiwidrop.com" target="_blank" rel="noreferrer">
            support@kiwidrop.com
          </a>{' '}
          or at the address listed below.
        </p>
        <p>
          Your Personal Information will be initially processed in Ireland and then will be transferred outside of
          Europe for storage and further processing, including to Canada and the United States.
        </p>

        <h3>CCPA</h3>
        <p>
          If you are a California resident, California law may provide you with additional rights regarding our use of
          your personal information. To learn more about your California privacy rights, visit [HYPERLINK TO CCPA
          PRIVACY NOTICE FOR CALIFORNIA RESIDENTS].
        </p>
        <p>
          If you are a resident of California, you have the right to access the Personal Information we hold about you
          (also known as the ‘Right to Know’), to port it to a new service, and to ask that your Personal Information be
          corrected, updated, or erased. If you would like to exercise these rights, please contact us through the
          contact information below.
        </p>
        <p>
          If you would like to designate an authorized agent to submit these requests on your behalf, please contact us
          at the address below.
        </p>

        <h3>COOKIES</h3>
        <p>
          A cookie is a small amount of information that’s downloaded to your computer or device when you visit our
          Site. We use a number of different cookies, including functional, performance, advertising, and social media
          or content cookies. Cookies make your browsing experience better by allowing the website to remember your
          actions and preferences (such as login and region selection). This means you don’t have to re-enter this
          information each time you return to the site or browse from one page to another. Cookies also provide
          information on how people use the website, for instance whether it’s their first time visiting or if they are
          a frequent visitor.
        </p>
        <p>
          The length of time that a cookie remains on your computer or mobile device depends on whether it is a
          “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last
          until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes
          and two years from the date they are downloaded to your device.
        </p>
        <p>
          You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can
          negatively impact your user experience and parts of our website may no longer be fully accessible.
        </p>
        <p>
          Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your
          browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to
          modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help
          file or through such sites as{' '}
          <a href="https://www.allaboutcookies.org" target="_blank" rel="noreferrer">
            https://www.allaboutcookies.org
          </a>
          .
        </p>
        <p>
          Additionally, please note that blocking cookies may not completely prevent how we share information with third
          parties such as our advertising partners. To exercise your rights or opt-out of certain uses of your
          information by these parties, please follow the instructions in the “Behavioural Advertising” section above.
        </p>

        <h3>DO NOT TRACK</h3>
        <p>
          Please note that because there is no consistent industry understanding of how to respond to “Do Not Track”
          signals, we do not alter our data collection and usage practices when we detect such a signal from your
          browser. To find out more about “Do Not Track,” please visit{' '}
          <a href="https://www.allaboutdnt.com" target="_blank" rel="noreferrer">
            https://www.allaboutdnt.com
          </a>
          .
        </p>

        <h3>CHANGES</h3>
        <p>
          We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices
          or for other operational, legal, or regulatory reasons.
        </p>

        <h3>CONTACT</h3>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a
          complaint, please contact us by email at{' '}
          <a href="mailto:support@kiwidrop.com" target="_blank" rel="noreferrer">
            support@kiwidrop.com
          </a>{' '}
          or by mail using the details provided below:
        </p>
        <p>Kiwidrop Inc, 227 Sandy Springs Pl NE Ste D-103, Sandy Springs, GA 30328</p>
        <p>
          We will make best efforts to respond in a reasonable period of time and may consult with third parties in
          addressing your inquiry. Additionally, you may have the right to provide inquiries directly to relevant data
          protection authorities, as may be applicable in your jurisdiction.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
