import { Variants } from 'framer-motion';

import { SOURCE_ID } from './types';

const SOURCE_ID_TO_SOURCE_NAME = {
  [SOURCE_ID.Recent]: 'Recent',
  [SOURCE_ID.Popular]: 'Popular',
  [SOURCE_ID.RecentlyViewed]: 'Recently Viewed',
};

const highlightAttrKeys = {
  [SOURCE_ID.QuerySuggestionsPlugin]: 'query',
  [SOURCE_ID.RecentSearchesPlugin]: 'label',
};

const variantsM: Variants = {
  show: {
    x: '0%',
    transition: { from: '-100%', type: 'spring', stiffness: 300, damping: 40 },
  },
  hide: {
    x: '-100%',
    transition: { from: '0%', type: 'spring', stiffness: 300, damping: 30 },
  },
  navigate: {
    x: '100%',
    transition: { from: '-100%', type: 'spring', stiffness: 300, damping: 40 },
  },
};

const variantsDesktop: Variants = {
  show: {
    opacity: 1,
    transition: { from: 0, ease: 'easeInOut' },
  },
  hide: {
    opacity: 0,
    transition: { from: 1, ease: 'easeInOut' },
  },
  navigate: {
    opacity: 0,
    transition: { from: 1, ease: 'easeInOut' },
  },
};

export { highlightAttrKeys, SOURCE_ID_TO_SOURCE_NAME, variantsDesktop, variantsM };
