.normal_shipping_item_wrapper {
  border-radius: var(--p-border-radius-2);
  border: 1px solid transparent;

  &.selectable {
    cursor: pointer;
  }

  &.active {
    border-color: #000;
  }

  .address_item_action_button_wrapper {
    padding: 10px 4px;
    cursor: pointer;
  }
}
