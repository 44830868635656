import { Table } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { useSkuTable } from '@/hooks/biz';
import { CollectionProduct } from '@/types';

import css from './index.module.scss';

type SkuTableProps = {
  data: CollectionProduct;
  isShow?: boolean;
};

const SkuTable = ({ data, isShow }: SkuTableProps) => {
  const { product } = data || {};
  const { columns, tableWidth, dataSource } = useSkuTable(product);
  const skuTableWrapperRef = useRef(null);
  const [skuTableWrapperWidth, setSkuTableWrapperWidth] = useState(0);

  useEffect(() => {
    if (skuTableWrapperRef.current && isShow) {
      setSkuTableWrapperWidth(skuTableWrapperRef.current.offsetWidth);
    }
  }, [isShow]);

  return (
    <div ref={skuTableWrapperRef}>
      {!!dataSource?.length && (
        <div
          style={{
            width: tableWidth > skuTableWrapperWidth ? skuTableWrapperWidth : tableWidth,
            maxWidth: skuTableWrapperWidth,
          }}
        >
          <Table
            className={css.sku_table}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={tableWidth > skuTableWrapperWidth ? { x: skuTableWrapperWidth } : null}
            size="small"
            rowKey="key"
          />
        </div>
      )}
    </div>
  );
};

export default SkuTable;
