import { ElementRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';

import useOrderForReturn from '../../useOrderForReturn';
import { openHubspotReturnForm } from '../../util';
import List from '../List';
import css from './index.module.scss';

type ListComponentHandle = ElementRef<typeof List>;

type CompHandle = {
  show: (config?: { fulFillId?: string }) => void;
};

type Props = {
  orderId: string;
};

const App = forwardRef<CompHandle, Props>(({ orderId }, ref) => {
  const [visible, setVisible] = useState(false);
  const [curFulFillId, setCurFulFillId] = useState('');
  const { orderType, contactEmail, lineItems, phone, orderName, orderService } = useOrderForReturn(
    orderId,
    curFulFillId
  );

  const [disabled, setDisabled] = useState(false);
  const listRef = useRef<ListComponentHandle>(null);

  const open = () => {
    const selectedItems = listRef.current.getData();
    openHubspotReturnForm({
      items: selectedItems,
      email: contactEmail,
      orderType,
      phone,
      orderName,
    });
  };
  const handleClose = () => {
    setVisible(false);
  };

  const handleChangeCallback = (selectedSkuS: string[]) => {
    setDisabled(!selectedSkuS?.length);
  };

  useImperativeHandle(ref, () => ({
    show: (config) => {
      setVisible(true);
      if (config?.fulFillId) {
        setCurFulFillId(config.fulFillId);
      }
    },
  }));

  const modalProps = {
    className: css.ns_com_modal_order_return_main,
    placement: 'center',
    showMask: true,
    title: 'Select Items to Return',
    forceRender: true,
    visible,
    footer: (
      <div className={css.modal_order_return_main_footer}>
        <Button block color="primary" shape="rounded" onClick={open} disabled={disabled}>
          Next
        </Button>
        <div className={css.modal_order_return_footer_tips}>
          {`Following return request approval, please submit return tracking here: `}
          <AutoLink
            to={`https://share.hsforms.com/1w7sUxljcRW2kvAgqsKAseAcdyi8?return_order_number=${orderName}`}
            target="_blank"
          >
            Return Tracking Upload
          </AutoLink>
        </div>
      </div>
    ),
    onClose: handleClose,
  };

  return (
    <Modal {...modalProps}>
      <StatusViewRenderer
        data={orderName}
        error={orderService.error}
        isLoading={orderService.isLoading}
        statusStyle={{ marginTop: 64 }}
        emptyContent={<span>No order found.</span>}
      >
        <div className={css.content}>
          {visible && <List data={lineItems} ref={listRef} onChange={handleChangeCallback} />}
        </div>
      </StatusViewRenderer>
    </Modal>
  );
});

export default App;
