import cn from 'classnames';

import { ORDER_STATUS_MAP } from '@/pages/Orders/constant';

import { OrderStatusTag } from '../../types';
import css from './index.module.scss';

const App = ({ status }: { status: string }) => {
  if (!status) return null;
  if (!ORDER_STATUS_MAP[status]) return null;

  const [desc, tagStatus] = ORDER_STATUS_MAP[status];
  const isDander = tagStatus === OrderStatusTag.Danger;
  const isSuccess = tagStatus === OrderStatusTag.Success;

  return (
    <span className={css.order_status_tag_wrapper}>
      <span className={cn(css.dot, { [css.danger]: isDander, [css.success]: isSuccess })} />
      <span className={css.order_status_desc}>{desc}</span>
    </span>
  );
};

export default App;
