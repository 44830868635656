import _ from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { getCollectionProducts } from '@/services/ds';

export default function useCollectionProducts(handle?: string, params?: any, enabled?: boolean) {
  return useInfiniteQuery(
    ['products', 'collections', 'getCollectionProducts', handle, params],
    ({ pageParam = 1 }) => {
      if (_.isNil(handle)) throw new Error('missing collection handle');
      return getCollectionProducts(handle, { ...params, page: pageParam });
    },
    {
      enabled,
      getNextPageParam: (lastPage: any) =>
        lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
    }
  );
}
