import { useInfiniteQuery } from 'react-query';

import { getPrivateInventoryTrans } from '@/services/ds';

export default function usePrivateInventoryTrans(params) {
  return useInfiniteQuery(['privateInventoryTrans', params], () => getPrivateInventoryTrans({ ...params }), {
    getNextPageParam: (lastPage: any) =>
      lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
  });
}
