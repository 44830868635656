import { PaymentMethodTypeEnum } from '@/components/PaymentMethodsDialog/types';
import usePaymentMethods from '@/hooks/queries/usePaymentMethods';

export default function useDefaultPaymentMethod() {
  const paymentMethods = usePaymentMethods();
  const paymentMethodsData = paymentMethods?.data?.data?.payment_methods || [];
  const defaultPaymentMethod = paymentMethodsData.filter(
    (paymentMethod) => paymentMethod.type === PaymentMethodTypeEnum.Card
  )?.[0];

  return { ...paymentMethods, data: defaultPaymentMethod };
}
