import { useMemo } from 'react';

export enum MatchedPos {
  PDP = 'pdp', // 1
  PLP = 'plp', // 2
}

const POS_LIST = [MatchedPos.PLP, MatchedPos.PDP];
// eslint-disable-next-line no-bitwise
const matchedPos = (posIdx, allPos) => ((1 << posIdx) & allPos) === 1 << posIdx;

const usePosMatched = (configPos: MatchedPos, rightPos: MatchedPos) => {
  const isPosMatched = useMemo(() => {
    const curIdxInPosList = POS_LIST.indexOf(rightPos);

    return matchedPos(curIdxInPosList, configPos);
  }, [configPos, rightPos]);

  return isPosMatched;
};

export default usePosMatched;
