import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { GrConnect } from 'react-icons/gr';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useAuth } from '@/hooks/auth';
import useBindStore from '@/hooks/queries/useBindStore';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ResultStatus = 'error' | 'success' | 'needLogin' | 'binding' | 'storeBoundByAnotherAccount';

function StoreConnectResult({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Store Connect Result</title>
      </Helmet>

      <StoreConnectResultDialog />
    </div>
  );
}

function StoreConnectResultDialog(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { sign, shop, shop_id: shopId, errno } = Object.fromEntries(searchParams);
  const { user } = useAuth();
  const bindStore = useBindStore();
  const [status, setStatus] = useState<ResultStatus>(() => {
    if (_.isEmpty(shopId) || _.isEmpty(sign) || errno) return 'error';
    if (_.isEmpty(user)) return 'needLogin';

    return 'binding';
  });
  const loginUrl = `/login?scene=connectStore&shop=${encodeURIComponent(shop)}&redirect=${encodeURIComponent(
    `${location.pathname}${location.search}`
  )}`;

  const handleAction = () => {
    if (status === 'error') {
      navigate('/');
    } else if (status === 'success') {
      navigate('/stores');
    } else if (['needLogin', 'storeBoundByAnotherAccount'].includes(status)) {
      navigate(loginUrl, { replace: true });
    } else if (status === 'binding') {
      bindStore.mutate(
        { sign, shop_id: shopId },
        {
          onSuccess: () => {
            setStatus('success');
          },
          onError: (error) => {
            setStatus(
              (error as Error)?.message === 'STORE_BOUND_BY_ANOTHER_ACCOUNT' ? 'storeBoundByAnotherAccount' : 'error'
            );
          },
        }
      );
    }
  };

  const handleClose = () => {
    if (status === 'error') {
      navigate('/');
    } else if (status === 'storeBoundByAnotherAccount') {
      navigate('/stores');
    }
  };

  useEffect(() => {
    ['needLogin', 'binding', 'success'].includes(status) && handleAction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  let btnText = 'Close';
  let caption: React.ReactNode;
  let descriptions: React.ReactNode;

  if (status === 'error') {
    caption = (
      <>
        Whoops!
        <br />
        Something went wrong.
      </>
    );
    descriptions = (
      <>
        <div className={`${prefixCls}__result-description`}>
          Reach out to us at <AutoLink to="mailto:support@kiwidrop.com">support@kiwidrop.com</AutoLink> about this
          issue.
        </div>
      </>
    );
  } else if (status === 'storeBoundByAnotherAccount') {
    btnText = 'Log in to another account';
    caption = (
      <>
        Whoops!
        <br />
        <br />
        {shop && (
          <>
            {shop}
            <br />
          </>
        )}
        is already connected another account.
      </>
    );
    descriptions = (
      <>
        <div className={`${prefixCls}__result-description`}>
          You’re currently logged in to <strong>{`${user?.phone_country ?? ''}${user?.phone ?? ''}`}</strong>
        </div>
      </>
    );
  }

  return (
    <StatusViewRenderer
      isEmpty={false}
      isLoading={['binding', 'success'].includes(status)}
      statusStyle={{ marginTop: 64 }}
    >
      <Modal
        className={`${prefixCls}__dialog`}
        visible
        title={status === 'error' ? 'Error' : 'Connect a store'}
        fullscreen="mobile"
        footer={
          <Button
            className={`${prefixCls}__result-ok-btn`}
            block
            color="primary"
            shape="rounded"
            loading={bindStore.isLoading}
            disabled={bindStore.isLoading}
            onClick={handleAction}
          >
            {btnText}
          </Button>
        }
        maskClosable={false}
        onClose={handleClose}
      >
        <div className={`${prefixCls}__result`}>
          <div className={`${prefixCls}__result-image`}>{status === 'error' ? <i>🤧</i> : <GrConnect />}</div>
          <div className={`${prefixCls}__result-caption`}>{caption}</div>
          {descriptions}
        </div>
      </Modal>
    </StatusViewRenderer>
  );
}

export default StoreConnectResult;
