.ns_com_address_dialog_list_view {
  .address_list_header {
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 500;
    color: #000;
    padding: 10px 0;
    &:not(:first-child) {
      padding-top: 18px;
    }
  }

  .address_list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--spacing-l);
  }

  .empty {
    margin: 80px auto;
    color: var(--color-gray4);
    font-size: var(--font-size3);
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .address_list_header {
      padding-left: 12px;
      padding-right: 12px;
    }

    .address_list {
      gap: var(--spacing-m);
    }

    .empty {
      position: absolute;
      width: 100%;
      margin: 0;
      top: 50%;
      z-index: 0;
      transform: translateY(-50%);
    }
  }
}
