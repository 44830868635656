.ns_com_button_main_wrapper_auto_link {
  color: unset;
  opacity: unset;
  transition: none;

  &:active {
    opacity: unset;
  }
}

.ns_com_button_main {
  --border-width: 0;

  &:global(.default) {
    color: var(--color-gray6);
    --border-color: var(--color-gray6);
  }

  &:global(.primary) {
    --text-color: var(--color-black);
  }

  &:global(.main) {
    --color: var(--color-gray6);
  }

  &:global(.acrylic) {
    --border-color: transparent;
    background-color: rgba(255, 255, 255, 0.75);
  }

  &:global(.white) {
    --text-color: var(--color-white);
  }

  &:global(.adm-button-loading) {
    :global(.adm-dot-loading) {
      order: 2;
    }
  }

  &:global(.adm-button-disabled) {
    opacity: 1 !important;
    color: var(--color-gray4) !important;
    background: var(--color-gray2) !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  &:global(.adm-button-fill-none) {
    --border-width: 0 !important;
  }

  &.bold {
    font-weight: 500;
  }

  &.bordered {
    --border-width: 1px;
  }

  &.shadowed {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  }

  &.underline {
    text-decoration: underline;
  }

  &::before,
  &::after {
    pointer-events: none;
  }

  .inner_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
    }

    .icon + .text {
      margin-left: var(--spacing-s);
    }
  }

  // button add variant
  &.button_add {
    --border-color: transparent;
    padding: var(--spacing-m);
    color: var(--color-gray5);
    font-size: 16px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);

    .inner_wrapper {
      .icon {
        position: absolute;
        left: var(--spacing-m);
        font-size: 24px;
        line-height: 0;
        display: flex;
        align-items: center;
      }
    }

    &.add_floating {
      position: fixed;
      width: auto;
      left: var(--spacing-l);
      right: var(--spacing-l);
      bottom: 0;
      z-index: 10;
    }

    @media screen and (max-width: 768px) {
      &.add_floating_mobile {
        position: fixed;
        width: auto;
        left: var(--spacing-l);
        right: var(--spacing-l);
        bottom: 0;
        z-index: 10;
      }
    }
  }
}
