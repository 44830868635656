/* eslint-disable no-nested-ternary */
import { Divider, Input } from 'antd';
import cn from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@/components/Button';
import { SIGN_UP_QUESTION } from '@/constants/biz';
import useSendAccountEmailCode from '@/hooks/queries/useSendAccountEmailCode';
import useSetPassword from '@/hooks/queries/useSetPassword';
import useVerifyAccountEmailCode from '@/hooks/queries/useVerifyAccountEmailCode';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type ViewType = 'email' | 'code' | 'password';

function AddEmail({ className, style }: Props): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sendAccountEmailCode = useSendAccountEmailCode();
  const verifyAccountEmailCode = useVerifyAccountEmailCode();
  const setPassword = useSetPassword();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  const phone = searchParams.get('phone') ? decodeURIComponent(searchParams.get('phone')) : '';
  const isAutoSetEmail = !_.isEmpty(email) && !_.isEmpty(code);
  const [currentView, setCurrentView] = useState<ViewType>(isAutoSetEmail ? 'code' : 'email');
  const [emailValue, setEmailValue] = useState(email || '');
  const [codeValue, setCodeValue] = useState(code || '');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const redirectUrl = searchParams.get('redirect') || '/';
  const shouldDisableContinue =
    (currentView === 'email' && !isValidEmail(emailValue)) ||
    (currentView === 'code' && codeValue.trim().length < 6) ||
    (currentView === 'password' && (_.isEmpty(passwordValue) || _.isEmpty(confirmPasswordValue)));

  const openSignUpQuestion = () => {
    // 隐藏跳出
    // window.location.assign(
    //   `https://share.hsforms.com/12Q-r7VWbSz-EoPOFf4AQmAcdyi8?email=${emailValue}&phone=${encodeURIComponent(phone)}`
    // );
    navigate(
      `/signup-question?email=${encodeURIComponent(emailValue)}${phone ? `&phone=${encodeURIComponent(phone)}` : ''}`
    );
  };

  const handleSuccess = () => {
    if (SIGN_UP_QUESTION) {
      openSignUpQuestion();
    } else {
      _.startsWith(redirectUrl, '/') ? navigate(redirectUrl) : window.location.assign(redirectUrl);
    }
  };

  const sendCode = (onSuccess?: () => void, onError?: (error?: any) => void) => {
    setCodeValue('');
    setErrorMessage('');
    sendAccountEmailCode.mutate(
      { email: _.trim(emailValue) },
      {
        onSettled: (data, error) => (error ? onError?.(error) : onSuccess?.()),
      }
    );
  };

  const verifyCode = (onSuccess?: () => void, onError?: (error?: any) => void) => {
    verifyAccountEmailCode.mutate(
      { email: _.trim(emailValue), code: codeValue },
      {
        onSettled: (data, error) => (error ? onError?.(error) : onSuccess?.()),
      }
    );
  };

  const handleSetPassword = async () => {
    setPassword.mutate(
      { password: passwordValue },
      {
        onSettled: (data, error) => {
          if (error) {
            setErrorMessage((error as Error)?.message || 'Set Password Failure');
          } else {
            if ('PasswordCredential' in window) {
              const credential = new (window as any).PasswordCredential({
                id: _.trim(emailValue),
                password: passwordValue,
              });
              navigator.credentials.store(credential);
            }
            handleSuccess();
          }
        },
      }
    );
  };

  const handleContinue = () => {
    setErrorMessage('');
    if (currentView === 'email') {
      sendCode(
        () => setCurrentView('code'),
        (error) => setErrorMessage((error as Error)?.message || 'Code failed to send')
      );
    } else if (currentView === 'code') {
      verifyCode(
        () => setCurrentView('password'),
        (error) => setErrorMessage((error as Error)?.message || 'Invalid Code')
      );
    } else if (currentView === 'password') {
      if (passwordValue !== confirmPasswordValue) {
        setErrorMessage('Passwords do not match');
        return;
      }

      handleSetPassword();
    }
  };

  const handleCodeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.validity.valid && setCodeValue(e.target.value);
  };

  const handleResendCode = () => {
    sendCode(null, (error) => setErrorMessage((error as Error)?.message || 'Code failed to send'));
  };

  const handleResetEmail = () => {
    setCurrentView('email');
    setEmailValue('');
    setCodeValue('');
    setErrorMessage('');
  };

  // auto set email (from email link scene) when has email and code params
  useEffect(() => {
    isAutoSetEmail && handleContinue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoSetEmail]);

  return (
    <div
      className={cn(prefixCls, className, `${prefixCls}--${currentView}-view`, {
        [`${prefixCls}--has-error`]: !!errorMessage,
      })}
      style={style}
    >
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Add an Email</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__form-wrapper`}>
          <h1 className={`${prefixCls}__title`}>
            {currentView === 'email' && 'Enter your email'}
            {currentView === 'code' && 'Verify your Email'}
            {currentView === 'password' && (
              <>
                Want to log in with this
                <br />
                email?
              </>
            )}
          </h1>
          <div className={`${prefixCls}__message`}>
            {currentView === 'email' && 'Add an email that we can contact you with'}
            {currentView === 'code' && (
              <>
                Enter the 6-digit code sent to <strong>{emailValue}</strong>
                <br />
                Please enter it within 10 minutes
              </>
            )}
            {currentView === 'password' && (
              <>
                Add a password for your account to log in with
                <br />
                <strong>{emailValue}</strong>
              </>
            )}
          </div>
          <form id="signupForm" method="post" onSubmit={(e) => e.preventDefault()}>
            <div className={`${prefixCls}__input-wrapper`}>
              <Input
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-email`)}
                type="email"
                name="email"
                autoComplete="username email"
                placeholder="Enter your email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                onPressEnter={handleContinue}
              />
              <Input
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-code`)}
                type="tel"
                name="code"
                autoComplete="off"
                maxLength={6}
                pattern="[0-9]*"
                placeholder="000000"
                value={codeValue}
                onChange={handleCodeValueChange}
                onPressEnter={handleContinue}
              />
              <Input.Password
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-password`)}
                type="password"
                name="password"
                autoComplete="password"
                placeholder="Enter Password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onPressEnter={handleContinue}
              />
              <Input.Password
                className={cn(`${prefixCls}__input`, `${prefixCls}__input-password`)}
                type="password"
                placeholder="Confirm Password"
                value={confirmPasswordValue}
                onChange={(e) => setConfirmPasswordValue(e.target.value)}
                onPressEnter={handleContinue}
              />
            </div>
          </form>
          <div className={`${prefixCls}__error-message`}>{errorMessage}</div>
          <Button
            className={`${prefixCls}__continue-btn`}
            color="main"
            shape="rounded"
            loading={
              (sendAccountEmailCode.isLoading && currentView === 'email') ||
              (verifyAccountEmailCode.isLoading && currentView === 'code') ||
              (setPassword.isLoading && currentView === 'password')
            }
            loadingText={currentView === 'email' ? 'Sending' : currentView === 'code' ? 'Verifying' : 'Submitting'}
            disabled={shouldDisableContinue}
            onClick={handleContinue}
          >
            Continue
          </Button>

          <div className={`${prefixCls}__actions`}>
            {currentView === 'code' && (
              <>
                <Button
                  underline
                  fill="none"
                  loading={sendAccountEmailCode.isLoading}
                  loadingText="Sending"
                  onClick={handleResendCode}
                >
                  Resend Code
                </Button>
                <Divider type="vertical" />
                <Button underline fill="none" onClick={handleResetEmail}>
                  Use Another Email
                </Button>
              </>
            )}

            {currentView === 'password' && (
              <Button className={`${prefixCls}__set-up-later-btn`} fill="none" onClick={handleSuccess}>
                Set up later
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function isValidEmail(email = ''): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (_.isEmpty(_.trim(email))) return false;
  return re.test(String(email).toLowerCase());
}

export default AddEmail;
