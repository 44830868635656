.ns_cart_empty_comp {
  color: var(--color-black);
  text-align: center;
  position: fixed;
  z-index: 999;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  .title {
    margin-bottom: 24px;
    font-size: var(--font-size4);
    line-height: 1.2;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    top: calc(50vh - var(--secondary-nav-bar-height));
  }
  @media screen and (min-width: 769px) {
    top: calc(50vh - var(--app-layout-padding-top) + var(--spacing-l));
  }
}
