.ns_com_addresses_form_main {
  position: relative;
  min-height: 400px;

  .addresses_form_mask {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 12345;
    background-color: #ffffff82;
  }
}
