import { useMutation, useQueryClient } from 'react-query';

import { phoneVerifyCode } from '@/services/lynx';

const useAccountPhoneVerifyCode = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => phoneVerifyCode(data), {
    onSuccess() {
      queryClient.invalidateQueries('authUser');
    },
  });
};

export default useAccountPhoneVerifyCode;
