.payout {
  .header {
    .title {
      font-size: 26px;
      line-height: 1.4;
      margin-bottom: 20px;
    }
  }
  .content {
    background-color: #fff;
  }

  @media screen and (max-width: 768px) {
    .header {
      display: none;
    }

    .app-layout {
      &__nav-bar {
        display: none;
      }
      &__container.container {
        padding: calc(var(--app-layout-padding-top) - 10px) 0 var(--app-layout-padding-bottom);
      }
    }
  }

  @media screen and (min-width: 769px) {
    .secondary_nav_bar {
      display: none;
    }
  }
}
