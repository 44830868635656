import { useMutation, useQueryClient } from 'react-query';

import { GET_SELLER_COLLECTION_PARAMS } from '@/constants/biz';
import { putSellerCollections } from '@/services/ds';

export default function usePutSellerCollection() {
  const queryClient = useQueryClient();
  return useMutation((data: Parameters<typeof putSellerCollections>[0]) => putSellerCollections(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['sellerCollections', 'getSellerCollections', GET_SELLER_COLLECTION_PARAMS]);
      queryClient.invalidateQueries(['products']);
    },
  });
}
