.ns_com_product_add_to_cart_dialog_main {
  @media screen and (min-width: 769px) {
    :global {
      .modal__container {
        width: var(--content-small-max-width);
      }
    }
  }

  .product_wholesale_banner {
    background-color: var(--adm-color-primary);
    color: #000;
    margin: -12px;
    padding: 12px;
  }

  .product_options {
    margin-top: var(--spacing-xxl);

    .group {
      &:not(:first-child) {
        margin-top: var(--spacing-l);
      }

      .title {
        font-size: var(--font-size3);
        font-weight: 500;
      }

      .options {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: var(--spacing-m);
        gap: var(--spacing-m);
        .item {
          .label {
            position: relative;
            padding: var(--spacing-m) var(--spacing-xxl);
            font-size: var(--font-size3);
            line-height: 18px;
            word-break: break-all;
            border: 1px solid var(--color-gray2);
            cursor: pointer;
            :global {
              .adm-badge {
                --color: var(--color-primary);
                position: absolute;
                right: 0;
                top: 0;
                transform: translateY(-50%);

                .adm-badge-content {
                  color: var(--color-black);
                  padding: 4px;
                  line-height: 14px;
                }
              }
            }
          }
          .extra {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-left: var(--spacing-xxl);
            color: var(--color-black);
            font-size: var(--font-size3);

            & > * + * {
              margin-left: var(--spacing-xxl);
            }
          }
          &.active {
            .label {
              border: 1px solid var(--color-black);
              box-shadow: inset 0 0 0 1px var(--color-black);
            }
          }
          &.out_of_stock {
            .label {
              color: #c7c7c7;
            }
            .extra {
              color: #c7c7c7;
            }
          }
        }
      }

      &.has_extra {
        .options {
          flex-flow: column nowrap;
          align-items: stretch;
          gap: 0;
          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &:last-child {
              border-bottom: none;
            }

            .label {
              padding: 0;
              border: none;
              box-shadow: none;
              cursor: default;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  @at-root .ns_or_notification {
    .title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 24px;
    }
    :global {
      .notification__header {
        padding: var(--spacing-l);
      }

      .notification__close-btn {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .ns_or_modal_footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .summary {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 calc(-1 * var(--spacing-l)) var(--spacing-l);
      padding: var(--spacing-l);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      background: #f9f9f9;

      & > * + * {
        margin-left: var(--spacing-xxl);
      }

      strong {
        font-weight: 500;
      }
    }

    .dropping_soon_message {
      margin: 0 -4px var(--spacing-m);
      color: var(--color-gray4);
      font-size: var(--font-size2);
      line-height: 1.3;
      text-align: center;

      &.error {
        color: var(--color-danger);
      }
    }
  }
  :global {
    .modal__footer {
      padding-top: 0;
      border-top: none;
    }
  }
}
