import { useInfiniteQuery } from 'react-query';

import { getPrivateInventoryBalance } from '@/services/ds';

export default function usePrivateInventoryBalance(params) {
  return useInfiniteQuery(['getPrivateInventoryBalance', params], () => getPrivateInventoryBalance({ ...params }), {
    getNextPageParam: (lastPage: any) =>
      lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
  });
}
