$prefixCls: orders-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  &-active {
    .app-layout {
      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: 0;

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
  }

  &__bottom_button_wrapper {
    text-align: center;
    padding: 12px 0 36px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    button {
      width: 250px;
    }
  }

  &__bottom_tips {
    margin-top: 12px;
  }
}
