@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin col {
  display: flex;
  flex-direction: column;
}

@mixin center {
  justify-content: center;
  align-items: center;
}

.catalogForRetail {
  .header {
    @include row;
    @include center;
    justify-content: space-between;
    margin-bottom: 15px;
    .title {
      font-size: 26px;
      line-height: 1.4;
    }
    .secondary {
      @include row;
      @include center;
      font-size: 14px;
      cursor: pointer;
      span {
        margin-right: 5px;
      }
    }
  }
  .retailContainer {
    @include col;
    gap: 10px;
  }
  @media screen and (max-width: 768px) {
    .header {
      display: none;
    }
    .retailContainer {
      gap: 1;
    }

    .app-layout {
      &__nav-bar {
        display: none;
      }

      &__container {
        padding: calc(var(--app-layout-padding-top) - 10px) 0 var(--app-layout-padding-bottom);
      }
    }
  }

  @media screen and (min-width: 769px) {
  }
}
