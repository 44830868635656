import { Text } from '@shopify/polaris';
import cn from 'classnames';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import Sticky from 'react-stickynode';
import { useMedia } from 'react-use';

import HotFlash from '@/components/biz/HotFlash';
import type { ProductFiltersChangedData } from '@/components/ProductFiltersBar';
import ProductFiltersBar from '@/components/ProductFiltersBar';
import ProductList from '@/components/ProductList';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import TopicList from '@/components/TopicList';
import useHomeProducts from '@/hooks/queries/useHomeProducts';
import { convertListProductsCoverImgs } from '@/utils/util';

import Banner from './components/Banner';
import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Home({ className, style }: Props): JSX.Element {
  const [filters, setFilters] = useState<ProductFiltersChangedData>();
  const isDesktop = useMedia('(min-width: 769px)');

  const productsParams = useMemo(
    () => ({
      page: 1,
      page_size: isDesktop ? 60 : 30,
      ...filters,
    }),
    [filters, isDesktop]
  );

  const products = useHomeProducts(productsParams, !_.isNil(filters));
  const productsData = useMemo(
    () => convertListProductsCoverImgs(_.compact(products?.data?.pages?.flatMap((page) => page?.data?.products))),
    [products]
  );

  const productList = React.useMemo(
    () => <ProductList className={`${prefixCls}__product-list`} data={productsData} />,
    [productsData]
  );
  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Home</title>
      </Helmet>
      <div className={`${prefixCls}__content`}>
        <Banner />
        <TopicList />
        <HotFlash />
        <Text variant="heading2xl" as="h3">
          Discover All
        </Text>
        <Sticky top=".app-layout__nav-bar" innerZ={2}>
          <div className={`${prefixCls}__product-filters-bar-wrapper`}>
            <ProductFiltersBar onChange={setFilters} showSort={false} />
          </div>
        </Sticky>
        <StatusViewRenderer
          data={productsData}
          error={products.error}
          isLoading={products.isLoading}
          hasMore={products.hasNextPage}
          loadMore={products.fetchNextPage}
          autoLoadMore={!isDesktop}
          statusStyle={{ marginTop: 64 }}
        >
          {productList}
        </StatusViewRenderer>
      </div>
    </div>
  );
}

export default Home;
