/**
 * sso related API
 */

import api, { responseErrorHandler } from './api';

export const errorCodes = {
  phoneNotRegistered: 60105,
  sendingFrequency: 60106,
};

const needCustomHandleErrorCodes = [errorCodes.phoneNotRegistered, errorCodes.sendingFrequency];

export async function loginByEmail(params?: { email: string; captcha: string }) {
  const res = await api.post<any>(`/sso/email/login`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function sendEmailCaptcha(params?: { email: string }) {
  const res = await api.post<any>(`/sso/email/send-captcha`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function loginByPhone(params?: { phone: string; phone_country: string; captcha: string }) {
  const res = await api.post<any>(`/sso/mobile/login`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function sendPhoneCaptcha(params?: { phone: string; phone_country: string }) {
  const res = await api.post<any>(`/sso/mobile/send-captcha`, params);
  const needCustomHandle = needCustomHandleErrorCodes.includes(res?.data?.errno);
  if (needCustomHandle) {
    throw res?.data;
  }
  responseErrorHandler(res);
  return res.data;
}

export async function bindEmail(params?: { email: string; captcha: string }) {
  const res = await api.post<any>(`/sso/account/verify-email`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function sendBindEmailCaptcha(params?: { email: string }) {
  const res = await api.post<any>(`/sso/account/update-email`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function updateEmail(params?: { email: string; email_accept_marketing?: number }) {
  const res = await api.post<any>(`/sso/account/update-email`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getUser() {
  const res = await api.get<any>(`/sso/account/info`);

  // token invalid or expired
  if (res.status === 401) throw new Error('AUTH_TOKEN_INVALID');

  responseErrorHandler(res);
  return res.data;
}

export async function updateUser(params?: any) {
  const res = await api.post<any>(`/sso/account/info`, params);
  responseErrorHandler(res);
  return res.data;
}

export async function getMultipass(url?: string) {
  const res = await api.post<any>(`/sso/account/multipass`, { return_to: url });
  responseErrorHandler(res);
  return res.data;
}
