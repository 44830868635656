.ns_com_modal_select_boards_main {
  .content {
    padding-bottom: calc(var(--spacing-l) + 40px);
  }

  .collection_search_wrapper {
    padding: var(--spacing-l) var(--spacing-l) 0;

    :global {
      .ant-input-affix-wrapper {
        border-radius: 4px;
        border-color: var(--color-gray4);
      }
    }
  }
  .create_new_collection_btn_wrapper {
    padding: var(--spacing-l);
  }
  .create_new_collection_btn {
    background-color: #f5f5f5;
    padding: 12px;
    box-shadow: none;
    margin-bottom: 8px;

    .button__inner-wrapper {
      line-height: 24px;
    }

    &.align_left {
      :global {
        .button__inner-wrapper {
          justify-content: flex-start;
        }

        .button__icon {
          position: relative;
          left: -4px;
          margin-right: -4px;
        }

        .button__text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .collection_list_wrapper {
    padding: 0 var(--spacing-l) var(--spacing-l);
    flex: 1;
    overflow: auto;
  }
  .collection_list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    gap: var(--spacing-m);

    .collection_list_section_title {
      font-weight: 500;
      font-size: var(--font-size14);

      &:not(:first-of-type) {
        margin-top: var(--spacing-l);
      }
    }

    .collection_list_item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-width: 0;
      padding: var(--spacing-xs);
      border-radius: 8px;
      background: var(--color-white);
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
      cursor: pointer;

      .images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--spacing-xs);
        width: 122px;
        height: 50px;
        margin-right: var(--spacing-m);

        :global {
          .image__fallback {
            .react-icons {
              display: none;
            }
          }
        }
      }

      .info {
        flex: 1;
        min-width: 0;
        margin-right: var(--spacing-xs);
        color: var(--color-black);
        font-size: var(--font-size2);
        line-height: 1.3;
      }

      .title {
        margin-bottom: var(--spacing-xxs);
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .actions {
        margin-right: var(--spacing-m);
        line-height: 0;

        // extend checkbox touchable area
        :global {
          .adm-checkbox::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  .button_extra {
    text-align: center;
    font-size: 14px;
    color: #999;
    padding-bottom: 8px;
  }

  @at-root .ns_com_modal_select_boards_main {
    :global {
      .modal__container {
        .modal__body {
          padding: 0;
        }
      }
    }
  }

  @at-root .ns_com_modal_select_boards_main:global(.modal--placement-topRight) {
    @media screen and (min-width: 769px) {
      :global {
        .modal__container {
          min-width: 360px;
          width: 360px;
          top: 80px;
          height: 540px;
          overflow: hidden;

          .modal__body {
            overflow: hidden;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  @at-root .seller_collection_title_edit_dialog:global(.modal--placement-topRight) {
    @media screen and (min-width: 769px) {
      :global {
        .modal__container {
          min-width: 360px;
          width: 360px;
          top: 80px;
          height: 540px;
        }
      }
    }
  }
}
