import { useInfiniteQuery } from 'react-query';

import { getOrders } from '@/services/ds';

export default function useOrders(params?: any) {
  return useInfiniteQuery(['orders', params], ({ pageParam = 1 }) => getOrders({ ...params, page: pageParam }), {
    getNextPageParam: (lastPage: any) =>
      lastPage?.data?.pagination?.has_next ? lastPage?.data?.pagination?.current + 1 : undefined,
  });
}
