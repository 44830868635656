import _ from 'lodash';
import { useMutation } from 'react-query';

import { getProductImagesDownloadUrl } from '@/services/ds';

export default function useProductImagesDownloadUrl() {
  return useMutation((id?: string) => {
    if (_.isNil(id)) throw new Error('missing product id');
    return getProductImagesDownloadUrl(id);
  });
}
