$prefixCls: cart-page;

:export {
  prefixCls: $prefixCls;
}

.#{$prefixCls} {
  $root: &;
  $actionsBarHeight: 200px;

  &-active {
    .app-layout {
      :global {
        .adm-checkbox-custom-icon {
          --color-gray5: var(--color-primary);
        }
      }

      @media screen and (max-width: 768px) {
        --app-layout-padding-top: var(--secondary-nav-bar-height);
        --app-layout-padding-bottom: #{$actionsBarHeight};

        &__nav-bar {
          display: none;
        }

        &__tab-bar {
          display: none;
        }
      }
    }
    &.management {
      @media screen and (max-width: 768px) {
        .app-layout {
          --app-layout-padding-bottom: 60px;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
  }

  &__navbar_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &_like_button {
      cursor: pointer;
      padding-right: 8px;
    }
  }

  &__switch_management_mode {
    cursor: pointer;
    &_text {
      font-size: 16px;
    }
  }

  &__product-wholesale-banner {
    background-color: var(--adm-color-primary);
    color: #000;
    padding: 12px;
  }

  @media screen and (min-width: 769px) {
    &__secondary-nav-bar {
      display: none;
    }

    &__content {
      padding: 34px var(--spacing-l);
      // cart页面需要固定中间内容的宽度，防止因商品标题长度变化影响页面横向宽度
      width: calc(774px + var(--spacing-l) * 2);

      &-layout {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: var(--spacing-xxl);
        &_body_left {
          width: 436px;
        }
        &_body_right {
          margin-left: 12px;
          position: sticky;
          top: calc(var(--app-layout-padding-top) + var(--spacing-l));
        }
      }
    }

    &__alert {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 375px;
      margin-bottom: 0;
      transform: translateY(calc(100% + var(--spacing-m)));
    }

    &__product-wholesale-banner {
      border-radius: 8px;
      margin-bottom: 12px;
    }
  }
}
