import { useMutation, useQueryClient } from 'react-query';

import { authShopCallback } from '@/services/lynx';

export default function useAuthShopCallback() {
  const queryClient = useQueryClient();

  return useMutation((params: Parameters<typeof authShopCallback>[0]) => authShopCallback(params), {
    onSuccess: () => {
      queryClient.invalidateQueries('store');
    },
  });
}
