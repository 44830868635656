import { useQuery } from 'react-query';

import { getPayout } from '@/services/jennifer';

export const usePayout = (id: string) => {
  const { data, isLoading, isError } = useQuery(['usePayout', id], () => getPayout(id), { enabled: !!id });
  return {
    data: data?.data?.payout_reports,
    isLoading,
    isError,
  };
};
