import { message } from 'antd';
import { Radio, Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import StoreSelect from '@/components/StoreSelect';
import useInventoryLocations from '@/hooks/queries/useInventoryLocations';
import useShopSettings from '@/hooks/queries/useShopSettings';
import useUpdateShopSettings from '@/hooks/queries/useUpdateShopSettings';
import { getAddressText, getFormatAddress } from '@/utils/biz';

import styles from './styles.scss';

const { prefixCls } = styles;

const PAGE_TITLE = 'Inventory Location';
const PRIMARY_LOCATION_ID = '0';

function App(): JSX.Element {
  const { shopId } = useParams();
  const shopSettings = useShopSettings(shopId, true);
  const locations = useInventoryLocations(shopId);
  const updateShopSettings = useUpdateShopSettings(shopId);
  const inventoryLocationId = shopSettings?.data?.data?.inventory_location_id;
  const addresses = locations?.data?.data?.locations || [];
  const usingPrimaryLocation = inventoryLocationId === PRIMARY_LOCATION_ID;

  const [checkedPrimaryLocation, setCheckedPrimaryLocation] = useState(true);
  const [value, setValue] = useState(null);

  // TODO: 将选中项放在第一位
  // set default address the first
  // for (let i = 0; i < addresses.length; i += 1) {
  //   if (addresses[i]?.id === inventoryLocationId) {
  //     const tmp = addresses[0];
  //     addresses[0] = addresses[i];
  //     addresses[i] = tmp;
  //     break;
  //   }
  // }

  const handleUpdate = () => {
    if (checkedPrimaryLocation && usingPrimaryLocation) {
      message.success('Save Success');
      return;
    }

    if (!checkedPrimaryLocation) {
      if (!value) {
        message.warning('Please select one of your locations');
        return;
      }

      if (value === inventoryLocationId) {
        message.success('Save Success');
        return;
      }
    }

    updateShopSettings.mutate(
      {
        inventory_location_id: Number(checkedPrimaryLocation ? PRIMARY_LOCATION_ID : value),
      },
      {
        onSuccess() {
          message.success('Save Success');
        },
        onError() {
          message.error('Save Failed');
        },
      }
    );
  };

  useEffect(() => {
    setCheckedPrimaryLocation(usingPrimaryLocation);
    setValue(usingPrimaryLocation ? null : inventoryLocationId);
  }, [inventoryLocationId, usingPrimaryLocation]);

  return (
    <div className={`${prefixCls}`}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{PAGE_TITLE}</title>
      </Helmet>

      <SecondaryNavBar className={`${prefixCls}__secondary-nav-bar`}>{PAGE_TITLE}</SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <h1 className={`${prefixCls}__title`}>{PAGE_TITLE}</h1>
        <section className={`${prefixCls}__desc`}>
          We will add products and synchronize inventory quantities to the selected location available on Shopify. This
          can only be changed once every 7 days.
        </section>

        <div className={`${prefixCls}__store-select_wrapper`}>
          <StoreSelect className={`${prefixCls}__store-select`} redirectPath="/inventory-location/:shopId" />
        </div>

        <StatusViewRenderer
          data={addresses}
          error={locations.error}
          isLoading={locations.isLoading}
          statusStyle={{ marginTop: 64 }}
        >
          <section className={`${prefixCls}__address_list`}>
            <div className={`${prefixCls}__card`}>
              <div className={`${prefixCls}__card_header`}>
                <div className={`${prefixCls}__card_header-left`}>Use my default location</div>
                <div className={`${prefixCls}__card_header-right`}>
                  <Switch checked={checkedPrimaryLocation} onChange={setCheckedPrimaryLocation} />
                </div>
              </div>
            </div>
            {!checkedPrimaryLocation && (
              <div className={cn(`${prefixCls}__locations_card_wrapper`)}>
                <div className={`${prefixCls}__card`}>
                  <div className={`${prefixCls}__card_header`}>
                    <div className={`${prefixCls}__card_header-left`}>Select one of your locations</div>
                  </div>
                  <div className={cn(`${prefixCls}__card_body`, 'locations')}>
                    <Radio.Group
                      defaultValue={inventoryLocationId}
                      value={value}
                      onChange={(val) => {
                        setValue(val as string);
                      }}
                    >
                      {addresses.map(({ id, ...rest }) => (
                        <div className={`${prefixCls}__address_list_item`} key={id}>
                          <Radio
                            block
                            value={id}
                            icon={(checked) => (
                              <div
                                className={cn(`${prefixCls}__address_list_item_icon`, {
                                  [`icon_checked`]: checked,
                                })}
                              />
                            )}
                          >
                            <div className={`${prefixCls}__address_list_item_content`}>
                              {getAddressText(getFormatAddress(rest))}
                            </div>
                          </Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            )}
          </section>
          <div className={`${prefixCls}__footer`}>
            <Button
              color="primary"
              shape="rounded"
              block
              loadingText="Saving"
              onClick={handleUpdate}
              loading={updateShopSettings.isLoading}
            >
              Save
            </Button>
          </div>
        </StatusViewRenderer>
      </div>
    </div>
  );
}

export default App;
