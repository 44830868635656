.ns_comp_hot_flash_main {
  margin-bottom: 34px;
  p,
  h1,
  h3 {
    margin: 0;
    padding: 0;
  }

  .hot_flash_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #000000;

    h1 {
      font-weight: 500;
      font-size: 30px;
      line-height: 115%;
    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      margin-top: 4px;
    }

    .hot_flash_shop_all_btn {
      &.adm-button {
        padding: 4px 8px !important;
      }
    }
  }

  .hot_flash_content_wrapper {
    margin-top: 16px;
    overflow-x: auto;
    .tabs_wrapper {
      display: grid;
      margin-bottom: 16px;
      width: 100%;
      .tab {
        position: relative;
        cursor: pointer;
        width: 100%;
        transition: all 0.6s;
        background-color: transparent;
        overflow: unset;
        margin-bottom: 10px;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
        }

        &.active::before {
          content: '';
          width: 0;
          height: 0;
          border: 10px solid rgb(255, 204, 23);
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-10px);
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom: none;
        }

        &:nth-child(1) {
          .tab_label_wrapper {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
        }

        &:last-child {
          .tab_label_wrapper {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }
      .tab_label_wrapper {
        height: 98px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          color: #000000;
        }
        .desc {
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #000000;
          padding: 8px 0;
          text-decoration: underline !important;
        }
      }
    }

    .hot_flash_products_wrapper {
      display: grid;
    }

    &.loading {
      overflow-x: hidden;
    }
  }

  @at-root .is_m {
    .ns_comp_hot_flash_main {
      .hot_flash_content_wrapper {
        .hot_flash_products_wrapper {
          gap: 8px;
          grid-template-columns: repeat(5, minmax(156px, 1fr));
        }
      }
    }

    .tabs_wrapper {
      gap: 2px;
    }
  }

  @at-root .is_pc {
    .ns_comp_hot_flash_main {
      .hot_flash_content_wrapper {
        .hot_flash_products_wrapper {
          gap: 40px;
          grid-template-columns: repeat(5, minmax(216px, 1fr));
        }
      }
    }

    .tabs_wrapper {
      gap: 1px;
    }
  }

  @media screen and (min-width: 1280px) {
    .hot_flash_content_wrapper {
      overflow: hidden;
    }
  }
}
