import type { FC } from 'react';

import AutoLink from '@/components/AutoLink';

type SubRetailerMenuPropsType = {
  store: Record<string, unknown>;
  isNoStore: boolean;
  callback: (...params: any[]) => unknown;
};

const submenus = [
  {
    title: 'My Orders',
    getTo: (store) => (store?.id ? `/orders?shopId=${store?.id}` : ''),
  },
  {
    title: 'Imported Products',
    getTo: (store) => (store?.id ? `/imported-products/${store?.id}` : ''),
  },
  {
    title: 'Brand Assets',
    getTo: (store) => (store?.id ? `/brand-assets/${store?.id}` : ''),
  },
  {
    title: 'Price Rules',
    getTo: (store) => (store?.id ? `/price-rules/${store?.id}` : ''),
  },
  {
    title: 'Inventory Location',
    getTo: (store) => (store?.id ? `/inventory-location/${store?.id}` : ''),
  },
  {
    title: 'Shipping Rules',
    getTo: (store) => (store?.id ? `/shipping-rules/${store?.id}` : ''),
  },
];

export const SubRetailerMenu: FC<SubRetailerMenuPropsType> = ({ store, isNoStore, callback }) => (
  <>
    <p
      style={{
        paddingLeft: '10px',
        backgroundColor: '#F2F2F6',
        margin: '10px 0',
      }}
    >
      Dropship from Kiwi
    </p>
    {submenus.map(({ title, getTo }) => (
      <AutoLink key={title} to={getTo(store)} onClick={isNoStore ? callback : undefined}>
        {title}
      </AutoLink>
    ))}
  </>
);
