import { useMutation, useQueryClient } from 'react-query';

import type { EmailSendCodeParams } from '@/services/lynx';
import { emailSendCode } from '@/services/lynx';

const useAccountEmailSendCode = () => {
  const queryClient = useQueryClient();
  return useMutation((data: EmailSendCodeParams) => emailSendCode(data), {
    onSuccess() {
      queryClient.invalidateQueries('authUser');
    },
  });
};
export default useAccountEmailSendCode;
