import { message } from 'antd';
import { Switch } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiEdit3 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import shareIcon from '@/assets/images/shareicon.svg';
import InvListViewSwitch, { ProductPreviewMode } from '@/components/biz/InvListViewSwitch';
import ProductCardListView from '@/components/biz/ProductCardListView';
import SideBarM from '@/components/biz/SideBar/M';
import SideBarPC from '@/components/biz/SideBar/PC';
import Button from '@/components/Button';
import ImportButton from '@/components/ImportButton';
import ModalBulkSelectSKU from '@/components/ModalBulkSelectSKU';
import ModalLikeBoardEdit from '@/components/ModalLikeBoardEdit';
import ModalPrintLiveHelper from '@/components/ModalPrintLiveHelper';
import ProductList from '@/components/ProductList';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import useSellerCollectionProducts from '@/hooks/queries/useSellerCollectionProducts';
import useUpdateSellerCollection from '@/hooks/queries/useUpdateSellerCollection';
import useUserSettings from '@/hooks/queries/useUserSettings';
import useShare from '@/hooks/useShare';
import useSideBar from '@/hooks/useSideBar';
import { getSellerCollectionProducts } from '@/services/ds';
import { hasInvView } from '@/utils/biz';

import styles from './styles.scss';

type ModalLikeBoardEditHandle = React.ElementRef<typeof ModalLikeBoardEdit>;

const { prefixCls } = styles;

const queryParams = {
  page_size: 30,
};

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const shareMessage = window.location.href;
const copyMessage = 'Link to board saved to clipboard.';
const togglePublicOnMessage = 'Board set to public.';
const togglePublicOffMessage = 'Board set to private.';
const togglePublicFailedMessage = 'Failed to set board public.';

function SellerCollectionDetail({ className, style }: Props): JSX.Element {
  const { onShare } = useShare(shareMessage, copyMessage);
  const isDesktop = useDesktop();
  const { user } = useAuth();
  const params = useParams();
  const sellerCollectionId = params.id;
  const products = useSellerCollectionProducts(sellerCollectionId, queryParams);
  const updateCollection = useUpdateSellerCollection(queryParams);
  const userSettings = useUserSettings();
  const collection = products?.data?.pages?.[0]?.data?.seller_collection;
  const collectionTitle = collection?.title || 'Board Details';
  const productsData = useMemo(
    () => _.compact(products?.data?.pages?.flatMap((page) => page?.data?.products)),
    [products?.data]
  );
  const isOwn = Number(collection?.seller_id) === Number(user?.id);
  const productsTotal = Number(products?.data?.pages?.[0]?.data?.pagination?.total || 0);
  const hasProductItems = productsTotal > 0;
  const disabledImportBoardButton = !hasProductItems;
  const showShareButton = hasProductItems;
  const showTogglePublicButton = hasProductItems && isOwn;
  const modalLikeBoardEditRef = useRef<ModalLikeBoardEditHandle>(null);
  const showPageOperations = !!products.isSuccess;
  const showEdit = collection?.seller_id && isOwn && !collection?.is_default;
  const showBoardOwnerName = !products.isLoading && !isOwn && !!collection?.name;
  const showDescription = collection?.body_html;
  const { sideBarM, sideBarPC } = useSideBar(sellerCollectionId);
  const showSideBarPC = !!sideBarPC?.length && isDesktop;
  const showSideBarM = !!sideBarM?.length && !isDesktop;
  const showPrintButton = isDesktop && hasInvView(userSettings?.data?.data?.app_collection_views);
  const showInvViewButton = showPrintButton;
  const [invViewMode, setInvViewMode] = useState<ProductPreviewMode>(ProductPreviewMode.Grid);
  const invViewUsedRef = useRef(null);
  const showInvView =
    showInvViewButton &&
    (invViewUsedRef.current || (!invViewUsedRef.current && invViewMode === ProductPreviewMode.List));

  const [isPublicValue, setIsPublicValue] = useState(false);

  const openEditModal = () => modalLikeBoardEditRef?.current?.show();

  const handleToggleSwitch = async (value) => {
    if (updateCollection.isLoading) return;
    try {
      await updateCollection.mutateAsync({ id: sellerCollectionId, is_public: value });
      const successMessgae = value ? togglePublicOnMessage : togglePublicOffMessage;
      message.success(successMessgae);
      setIsPublicValue(value);
    } catch (error) {
      message.error((error as Error)?.message || togglePublicFailedMessage);
    }
  };

  const handleChangeInvViewMode = useCallback((value) => {
    setInvViewMode(value);
  }, []);

  useEffect(() => {
    setIsPublicValue(!!collection?.is_public);
  }, [collection?.is_public]);

  useEffect(() => {
    if (invViewMode === ProductPreviewMode.List) {
      invViewUsedRef.current = true;
    }
  }, [invViewMode]);

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{collectionTitle}</title>
      </Helmet>

      <SecondaryNavBar
        className={`${prefixCls}__secondary-nav-bar`}
        right={
          showEdit && !isDesktop ? (
            <Button className={`${prefixCls}__actions-edit-btn m`} bold={false} fill="none" onClick={openEditModal}>
              <FiEdit3 fill="currentColor" />
              <span className="text">Edit</span>
            </Button>
          ) : null
        }
      >
        {collectionTitle}
      </SecondaryNavBar>
      <div className={cn(`${prefixCls}__content_wrapper`, { sidebar_pc: showSideBarPC }, { sidebar_m: showSideBarM })}>
        {showSideBarPC && (
          <div className={cn(`${prefixCls}__sidebar_wrapper`)}>
            <SideBarPC data={sideBarPC} />
          </div>
        )}
        <div className={`${prefixCls}__content`}>
          {!products.isLoading && (
            <div className={`${prefixCls}__content_header`}>
              <div className={`${prefixCls}__content_header-title`}>{collectionTitle}</div>
              {showBoardOwnerName && (
                <div className={`${prefixCls}__content_header-creator`}>by {collection?.name}</div>
              )}
              {showDescription && (
                <div
                  className={`${prefixCls}__content_header-desc`}
                  dangerouslySetInnerHTML={{ __html: collection?.body_html }}
                />
              )}
              {showPageOperations && (
                <>
                  <div className={`${prefixCls}__content_header-operations_wrapper`}>
                    <ImportButton
                      className={`${prefixCls}__actions-import-btn`}
                      importProductsQueryParams={{ seller_collection_id: Number(sellerCollectionId) }}
                      disabled={disabledImportBoardButton}
                      buttonColor="main"
                    >
                      Bulk Import to Store
                    </ImportButton>

                    <ModalBulkSelectSKU title="Bulk Add to Cart">
                      {({ show }) => (
                        <Button className={`${prefixCls}__actions-bulk-add-to-cart pc`} shape="rounded" onClick={show}>
                          Bulk Add to Cart
                        </Button>
                      )}
                    </ModalBulkSelectSKU>

                    <ModalPrintLiveHelper
                      fetchData={() =>
                        getSellerCollectionProducts(sellerCollectionId, { page: 1, page_size: 999 }).then(
                          (res) => res?.data?.products
                        )
                      }
                    >
                      {({ toggler }) =>
                        showPrintButton ? (
                          <Button
                            className={`${prefixCls}__actions-bulk-add-to-cart pc`}
                            shape="rounded"
                            onClick={toggler}
                          >
                            Print Live Helpers
                          </Button>
                        ) : null
                      }
                    </ModalPrintLiveHelper>

                    {showInvViewButton && <InvListViewSwitch value={invViewMode} onChange={handleChangeInvViewMode} />}

                    {showEdit && isDesktop && (
                      <Button
                        color="default"
                        block
                        className={`${prefixCls}__actions-edit-btn pc`}
                        shape="rounded"
                        onClick={openEditModal}
                      >
                        <FiEdit3 fill="currentColor" />
                        <span className="text">Edit</span>
                      </Button>
                    )}
                    {showShareButton && (
                      <Button
                        color="default"
                        block
                        className={`${prefixCls}__actions-share-btn`}
                        shape="rounded"
                        onClick={onShare}
                      >
                        <div className={`${prefixCls}__actions-share-btn_inner`}>
                          <img src={shareIcon} alt="icon" />
                          {isDesktop && <span className={`${prefixCls}__actions-share-btn_inner_text`}>Share</span>}
                        </div>
                      </Button>
                    )}
                  </div>
                  {showTogglePublicButton && isDesktop && (
                    <div className={`${prefixCls}__content_header-operations_wrapper-2`}>
                      <div className={`${prefixCls}__actions-public-btn`}>
                        <span className={`${prefixCls}__actions-public-btn-text`}>Make Public</span>
                        <Switch
                          onChange={handleToggleSwitch}
                          loading={updateCollection.isLoading}
                          checked={isPublicValue}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {showSideBarM && <SideBarM data={sideBarM} />}

          <StatusViewRenderer
            data={productsData}
            error={products.error}
            isLoading={products.isLoading}
            hasMore={products.hasNextPage}
            loadMore={products.fetchNextPage}
            statusStyle={{ marginTop: 64 }}
          >
            <div style={{ display: invViewMode === ProductPreviewMode.Grid ? 'block' : 'none' }}>
              <ProductList className={`${prefixCls}__product-list`} data={productsData} />
            </div>
            {showInvView && (
              <div style={{ display: invViewMode === ProductPreviewMode.List ? 'block' : 'none' }}>
                <div className={`${prefixCls}__product-list-inv`}>
                  {productsData.map((productData, idx) => (
                    <ProductCardListView
                      data={productData}
                      key={productData?.product?.id || idx}
                      isShow={invViewMode === ProductPreviewMode.List}
                      isShowBuyButton={false}
                    />
                  ))}
                </div>
              </div>
            )}
          </StatusViewRenderer>
          {!!collection?.id && <ModalLikeBoardEdit ref={modalLikeBoardEditRef} collection={collection} />}
        </div>
      </div>
    </div>
  );
}

export default SellerCollectionDetail;
