import { Button, Popover, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import React, { useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useParams, useSearchParams } from 'react-router-dom';

import StatusViewRenderer from '@/components/StatusViewRenderer';
import { useDesktop } from '@/hooks';
import type { CategoryForRetailListItemResponse } from '@/services/jennifer';
import { fetchCategoryForRetailParams } from '@/services/jennifer';

import { QUERY_KEYWORDS, QUERY_STATE } from '../../def';
import { useCategoryData } from '../../hooks/useCategoryData';
import {
  setSelectedProducts,
  showCatalogRetailGuide,
  showEditPriceModal,
  showEditStateModal,
  useCatalogRetailStore,
} from '../../store';
import { ConfirmPriceModal } from '../ConfirmPriceModal';
import { EditPriceModal } from '../EditPriceModal';
import { EditPriceSuccessModal, EditStateModal } from '../EditStateModal';
import styles from './index.module.scss';

type TablesRowsProps = CategoryForRetailListItemResponse;

const showStatus = (words?: string) =>
  words === 'not_submitted' ? (
    '--'
  ) : (
    <Tag
      style={{
        borderRadius: '12px',
        border: 'none',
      }}
    >
      {words
        ?.split('_')
        .map((word) => _.capitalize(word))
        .join(' ')}
    </Tag>
  );

const displayPrice = (min, max) => {
  const arr = [min, max].filter((num) => !Number.isNaN(num) && typeof num === 'number');

  if (arr.length === 0) {
    return '--';
  }
  if (arr.length === 1) {
    return arr[0] === 0 ? '--' : `$${arr[0]}`;
  }
  return arr[0] === arr[1] ? `${arr[0] === 0 ? '--' : `$${arr[0]}`}` : `$${arr[0]} - $${arr[1]}`;
};

const desktopColumns: ColumnsType<TablesRowsProps> = [
  {
    title: 'PRODUCT',
    dataIndex: 'title',
    sorter: true,
  },
  {
    title: (
      <div>
        KIWI STATUS
        <Popover
          placement="bottom"
          content={
            <div>
              <p>Active: retailers will find your products on Kiwi and will sell it on their store</p>
              <p>Inactive: retailers won’t find and retail your inactive products.</p>
            </div>
          }
        >
          <FiInfo
            style={{
              marginLeft: '5px',
            }}
          />
        </Popover>
      </div>
    ),
    dataIndex: 'market_status',
    render: showStatus,
  },
  // {
  //   title: 'SUBMISSION STATUS',
  //   dataIndex: 'submitted_status',
  //   render: showStatus,
  // },
  {
    title: 'KIWI PRICE',
    dataIndex: 'retailPrice',
    render(val, item) {
      return displayPrice(item.price_min, item.price_max);
    },
  },
  {
    title: 'SHOPIFY COST',
    dataIndex: 'price',
    render(val, item) {
      return displayPrice(item.cost_price_min, item.cost_price_max);
    },
  },
  {
    title: 'SHOPIFY PRICE',
    dataIndex: 'price',
    render(val, item) {
      return displayPrice(item.original_price_min, item.original_price_max);
    },
  },
  {
    title: 'INVENTORY',
    dataIndex: 'inventory_quantity',
    render(val, item) {
      return `${item.inventory_quantity} in Stock for ${item.variants.length} Variants`;
    },
  },
];

const mobileColumns: ColumnsType<TablesRowsProps> = [
  {
    title: (
      <div className={styles.catalog_retail_row}>
        <h1>
          PRODUCT <FiInfo onClick={showCatalogRetailGuide} />
        </h1>
        <div>
          KIWI STATUS
          <Popover
            placement="bottom"
            content={
              <div>
                <p>Active: retailers will find your products on Kiwi and will sell it on their store</p>
                <p>Inactive: retailers won’t find and retail your inactive products.</p>
              </div>
            }
          >
            <FiInfo
              style={{
                marginLeft: '5px',
              }}
            />
          </Popover>
        </div>
      </div>
    ),
    dataIndex: 'products',
    render(text, item) {
      return (
        <div className={styles.catalog_retail_row}>
          <section>
            <p>{item.title}</p>
            <p
              style={{
                fontSize: '12px',
              }}
            >
              {item.inventory_quantity} in Stock for {item.variants.length} Variants
            </p>
            <p>{`Shopify Cost: ${displayPrice(item.cost_price_min, item.cost_price_max)}`}</p>
            <p>{`Shopify Price: ${displayPrice(item.original_price_min, item.original_price_min)}`}</p>
            <p>{`Kiwi: ${displayPrice(item.price_min, item.price_max)}`}</p>
          </section>
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <Tag
                style={{
                  borderRadius: '12px',
                  border: 'none',
                }}
              >
                {item.export_status}
              </Tag>
            </div>
          </section>
        </div>
      );
    },
  },
];

export const DataTable: React.FC = () => {
  const isDesktop = useDesktop();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { selectedProduct } = useCatalogRetailStore();

  const keywords = searchParams.get(QUERY_KEYWORDS);
  const status = searchParams.get(QUERY_STATE);
  const queryParams: fetchCategoryForRetailParams = useMemo(
    () =>
      omitBy(
        {
          status,
          keywords,
        },
        isEmpty
      ),
    [keywords, status]
  );

  const {
    refetch,
    fetchNextPage,
    data: CategoryData,
    hasNextPage,
    error,
    isLoading,
  } = useCategoryData(params?.shopId, queryParams);

  const CategoryDataList = useMemo(
    () => compact(CategoryData?.pages?.flatMap((page) => page?.data?.data)),
    [CategoryData?.pages]
  );

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter);
  };

  const handleSelectChange = (keys: React.Key[], datas: TablesRowsProps[]) => {
    setSelectedProducts(datas);
  };

  return (
    <div className={styles.dataTable}>
      <StatusViewRenderer
        data={CategoryDataList}
        error={error}
        isLoading={isLoading}
        hasMore={hasNextPage}
        loadMore={fetchNextPage}
        statusStyle={{ marginTop: 64 }}
      >
        <Table
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            onChange: handleSelectChange,
          }}
          columns={isDesktop ? desktopColumns : mobileColumns}
          dataSource={CategoryDataList}
          pagination={false}
          onChange={handleTableChange}
        />
      </StatusViewRenderer>

      {/* action rows */}
      {selectedProduct.length ? (
        <div className={styles.action}>
          <div className={styles.btnWrapper}>
            <Button size="large" block shape="round" onClick={showEditPriceModal}>
              Edit & Submit
            </Button>
          </div>
          <div className={styles.btnWrapper}>
            <Button size="large" block shape="round" onClick={showEditStateModal}>
              Deactivate
            </Button>
          </div>
        </div>
      ) : null}

      {/* price modal */}
      <EditPriceModal />
      {/* state modal */}
      <EditStateModal refetch={refetch} />
      {/* ConfirmPriceModal */}
      <ConfirmPriceModal refetch={refetch} />
      <EditPriceSuccessModal />
    </div>
  );
};
