const disableNewRegistration = false;
const disableUnauthorizedAccess = true;
const isSystemMaintaining = false;

const LOCAL_STORAGE_KEYS = {
  hideProcessOrdersModal: 'ds2-hide-process-orders-modal',
  hideCombineAndProcessComfirmationModal: 'ds2-hide-combine-and-process-confirmation-modal',
  hideLiveSellingModal: 'ds2-hide-live-selling-modal',
  lastFavoritedCollectionId: 'lastFavoritedCollectionId',
  recentCollectionIds: 'recentCollectionIds',
};

const SESSION_STORAGE_KEYS = {
  cartOrderParams: 'cartOrderParams',
};

const PAGE_TITLE = {
  checkout: 'Checkout',
};

const ORIGIN_URLS = {
  installApp: 'https://apps.shopify.com/kiwi-drop',
};

const GOOGLE_MAP_API_KEY = 'AIzaSyAmIt-Q7UprgVyn9qEubheKGOXwArozJyc';
const BUILDER_SETTING = {
  GlobalRegistry: 'global-registry',
  HomeCarousel: 'home-carousel',
  NavPC: 'nav-pc',
  NavMobile: 'nav-mobile',
  Brand: 'brand',
  Deposit: 'deposit',
  Sidebar: 'sidebar',
  HotFlash: 'hotflash',
  Timer: 'timer',
  PrivateInvStockUp: 'private-inv-stock-up',
};

export {
  BUILDER_SETTING,
  disableNewRegistration,
  disableUnauthorizedAccess,
  GOOGLE_MAP_API_KEY,
  isSystemMaintaining,
  LOCAL_STORAGE_KEYS,
  ORIGIN_URLS,
  PAGE_TITLE,
  SESSION_STORAGE_KEYS,
};
