import css from './index.module.scss';

type Props = {
  text: string | number;
  color?: 'primary' | 'default' | '';
};

const App = ({ text, color = 'default' }: Props) => {
  const colorType = {
    default: '#E8E8E8',
  };
  const backgroundColor = colorType[color];

  return (
    <span className={css.tag_wrapper} style={{ backgroundColor }}>
      {text}
    </span>
  );
};

export default App;
