import { message } from 'antd';
import copyToClipboard from 'copy-to-clipboard';

import useDesktop from './useDesktop';

const useShare = (shareMessage: string, copyMessage: string) => {
  const isDesktop = useDesktop();

  const onShare = () => {
    try {
      const shareData = { text: shareMessage };

      if (isDesktop) {
        throw new Error();
      }

      if (!window?.navigator?.canShare?.(shareData)) {
        throw new Error();
      }

      window?.navigator?.share?.(shareData);
    } catch (err) {
      const isSuccess = copyToClipboard(shareMessage);

      if (isSuccess) {
        message.success({ key: 'copySuccess', content: copyMessage });
      } else {
        message.error({ key: 'copyFailed', content: 'Copy Failed!' });
      }
    }
  };

  return {
    onShare,
  };
};

export default useShare;
