.ns_comp_cart_manage_section_actions {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: var(--spacing-l);
  background: var(--color-white);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  // safe area bottom patch
  height: auto;
  padding-bottom: max(env(safe-area-inset-bottom), var(--spacing-l));

  .button_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .left_text_select_all {
        margin-left: 8px;
        font-size: 16px;
        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .placeholder {
    display: none;
  }

  .right {
    .save_for_later_button {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 769px) {
    background-color: #fff;
    border-top: 2px solid #c7c7c7;
    justify-content: center;
    padding: 12px 12px 54px;
    display: flex;
    position: fixed;
    flex-direction: row;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 12px #0003;

    .button_wrapper {
      width: 507px;
      margin-top: 0;
      box-sizing: border-box;
      padding: 0 12px;
    }

    .placeholder {
      display: block;
      width: 270px;
      opacity: 0;
    }
  }
}
