import { useMedia } from 'react-use';

import PriceRulesM from './m';
import PriceRulesPC from './pc';

const App = () => {
  const isDesktop = useMedia('(min-width: 769px)');
  return isDesktop ? <PriceRulesPC /> : <PriceRulesM />;
};

export default App;
