.ns_com_tab_bar_main {
  --adm-color-primary: var(--color-primary-medium);
  background: var(--color-white);

  .icon_home {
    fill: none;
    stroke-width: 2;

    [fill='none'] {
      stroke-width: 0;
    }
  }
}
