import { Form, message, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Modal from '@/components/Modal';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import useSellerCollectionProducts from '@/hooks/queries/useSellerCollectionProducts';
import useUpdateCart from '@/hooks/queries/useUpdateCart';
import { checkIsSoldOut, getVariantByOptions } from '@/utils/biz';
import { getPriceRange, tailImageSrc } from '@/utils/util';

import styles from './index.module.scss';

type Props = {
  visible: boolean;
  title: string;
  onClose: () => void;
  afterSuccessCallback: (addToCartValues: any[]) => void;
};

interface FixedDataType {
  [key: string]: string;
}

const App = ({ visible = false, title, onClose, afterSuccessCallback }: Props) => {
  const [form] = Form.useForm();
  const params = useParams();
  const collectionId = params.id;
  const updateCart = useUpdateCart();
  const products = useSellerCollectionProducts(collectionId);
  const productsData = useMemo(
    () =>
      _.compact(
        products?.data?.pages
          ?.flatMap((page) => page?.data?.products)
          .filter(({ product }) => {
            if (product?.out_of_stock || !product?.variants?.length) return false;
            return true;
          })
      ),
    [products?.data]
  );
  const [addToCartValues, setAddToCartValues] = useState([]);

  const handleClose = () => {
    form.resetFields();
    setAddToCartValues([]);
    onClose?.();
  };

  const handleAddToCart = () => {
    if (!addToCartValues.length) {
      message.warning('Please at least choose one product.');
      return;
    }

    updateCart.mutate(
      { items: addToCartValues, append: true },
      {
        onSuccess: () => {
          handleClose();
          afterSuccessCallback?.(addToCartValues);
        },
        onError: (error) => {
          message.error((error as Error)?.message || 'Add to Cart Failure!');
        },
      }
    );
  };

  const handleValuesChange = (values, allValues) => {
    const variantIds = Object.keys(allValues)?.filter((variantId) => !!allValues[variantId]);
    const addToCartItems = variantIds.map((variantId) => ({ qty: 1, variant_id: Number(variantId) }));
    setAddToCartValues(addToCartItems);
  };

  return (
    <Modal
      visible={visible}
      title={title}
      onClose={handleClose}
      className={styles.modal_bulk_select_sku_pc}
      footer={
        <Button block color="main" shape="rounded" onClick={handleAddToCart} loading={updateCart.isLoading}>
          {addToCartValues?.length ? `Add ${addToCartValues.length} to Cart` : 'Add to Cart'}
        </Button>
      }
    >
      <Form name="basic" autoComplete="off" form={form} onValuesChange={handleValuesChange} className={styles.form}>
        <StatusViewRenderer
          data={productsData}
          error={products.error}
          isLoading={products.isLoading}
          hasMore={products.hasNextPage}
          loadMore={products.fetchNextPage}
          statusStyle={{ marginTop: 64 }}
        >
          {productsData.map(({ product }) => {
            const { id, title: productTitle, cover, price_min, price_max, options, variants } = product;
            const showSKUTable = !!options?.length;
            let fixedColumnsOrigin = options.length === 1 ? options[0]?.values : [];
            let fixedDataOrigin = options.length === 1 ? [options[0]?.name] : [];
            if (options.length === 2) {
              fixedColumnsOrigin = options[1]?.values;
              fixedDataOrigin = options[0]?.values;
            }
            const fixedColumns: ColumnsType<FixedDataType> = fixedColumnsOrigin?.map((val, index) => ({
              title: val,
              dataIndex: index,
              align: 'center',
              width: val?.length * 10 > 60 ? val?.length * 10 : 60,
              render(entity) {
                let curOptions = [];
                if (options.length === 1) {
                  curOptions = [val];
                }
                if (options.length === 2) {
                  curOptions = [entity?.val, val];
                }
                const variant = getVariantByOptions(variants, curOptions);
                if (!variant?.id) return null;
                const disabled = checkIsSoldOut(variant?.inventory_quantity);

                return (
                  <Form.Item name={variant.id} noStyle>
                    <Checkbox disabled={disabled} color="main" />
                  </Form.Item>
                );
              },
            }));
            fixedColumns.unshift({
              title: ' ',
              dataIndex: 'first_header',
              fixed: 'left',
              width: 100,
              align: 'left',
            });
            const fixedData: FixedDataType[] = fixedDataOrigin?.map((val) => {
              const row = {};
              fixedColumns.forEach(({ dataIndex }) => {
                row[dataIndex] =
                  dataIndex === 'first_header'
                    ? val
                    : {
                        position: options[0]?.position,
                        val,
                      };
              });
              return row;
            });
            const tableWidth = fixedColumns
              .map(({ width }) => width)
              .reduce((total, cur) => Number(total) + Number(cur), 0);
            const maxWidth = 600;

            return (
              <div key={id} className={cn(styles.bulk_select_sku_pc_list_item)}>
                <img className={styles.left} src={tailImageSrc(cover, { width: 104, height: 140 })} alt="img" />
                <div className={styles.right}>
                  <div className={styles.title}>{productTitle}</div>
                  <div className={styles.price}>{getPriceRange(price_min, price_max)}</div>
                  {showSKUTable && (
                    <div style={{ width: tableWidth, maxWidth: `${maxWidth}px` }}>
                      <Table
                        loading={products.isLoading}
                        className={styles.sku_table}
                        columns={fixedColumns}
                        dataSource={fixedData}
                        pagination={false}
                        scroll={tableWidth > 600 ? { x: maxWidth } : null}
                        size="small"
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </StatusViewRenderer>
      </Form>
    </Modal>
  );
};

export default App;
