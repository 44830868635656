import '@fontsource/jost';

import { Input } from 'antd';
import { Collapse } from 'antd-mobile';
import cn from 'classnames';
import _ from 'lodash';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import AutoLink from '@/components/AutoLink';
import Button from '@/components/Button';
import { LOGO_BASE64 } from '@/constants/biz';

import styles from './styles.scss';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Landing({ className, style }: Props): JSX.Element {
  const [searchParams] = useSearchParams();
  const phone = searchParams.get('phone');
  const [phoneValue, setPhoneValue] = useState(phone);
  const isDesktop = useMedia('(min-width: 769px)');

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>Welcome to KiwiDrop!</title>
      </Helmet>

      <div className={`${prefixCls}__content`}>
        <Button className={`${prefixCls}__login-btn`} color="primary" size="small" shape="rounded" link="/login">
          Log In
        </Button>

        <div className={cn(`${prefixCls}__section`, `${prefixCls}__section-hero`)}>
          <img className={`${prefixCls}__logo`} src={LOGO_BASE64} alt="Kiwi & Drop" />
          <div className={`${prefixCls}__section-title`}>
            Do what you love! <br />
            We’ll do the rest.
          </div>
          <div className={`${prefixCls}__copy-1`}>
            Join the drop-ship experience built by boutique owners for boutique owners.
          </div>
          <div className={`${prefixCls}__form-row`}>
            <Input
              size="large"
              placeholder="Enter your phone number"
              value={phoneValue}
              onChange={(e) => setPhoneValue(e.target.value)}
            />
            <Button
              color="main"
              shape="rounded"
              bold={false}
              link={`https://share.hsforms.com/1Z5xcWdMOT1-5UhFQkWCyGgcdyi8?phone=${encodeURIComponent(
                _.trim(phoneValue)
              )}`}
              disabled={
                !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phoneValue)
              }
            >
              Request An Invite
            </Button>
          </div>
          <div className={`${prefixCls}__copy-2`}>
            We are on-boarding at a pace that ensures we are able to deliver on our commitments to you.
            <span>
              We believe our boutique partners deserve transparency, accurate communication and unparalleled service.
            </span>
          </div>
          <div className={`${prefixCls}__copy-3`}>We can’t wait to meet you. Talk soon!</div>
          <div className={`${prefixCls}__login-row`}>
            Already have an account? <AutoLink to="/login">Log in</AutoLink>
          </div>
        </div>

        <div className={cn(`${prefixCls}__section`, `${prefixCls}__section-intro`)}>
          <div className={`${prefixCls}__section-inner-wrapper`}>
            <Collapse defaultActiveKey={isDesktop ? ['1'] : undefined}>
              <Collapse.Panel key="1" title="Zero Inventory Risk">
                Say goodbye to picking winners and losers. Use our full domestic & import dropship inventory pool as
                your secret weapon. Keep every dollar of profit you make. No more unsold stock.
              </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={isDesktop ? ['2'] : undefined}>
              <Collapse.Panel key="2" title="Clock out of busywork…">
                And clock in to what really matters. Let us handle sourcing, intake and shipping. These time suckers
                distract you from building your communities and scaling your business. Ditch the traditional fixed
                overhead boutique model today.
              </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={isDesktop ? ['3'] : undefined}>
              <Collapse.Panel key="3" title="5-Minutes to Free(dom)">
                Our service has no monthly fees. You’ll go from setup to selling in under 5 minutes. Add your brand logo
                and message to packing slips in seconds. Enable auto-sync for inventory, order and tracking. Our tech
                makes your life easy! No more wasted time.
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>

        <div className={cn(`${prefixCls}__section`, `${prefixCls}__section-about`)}>
          <div className={`${prefixCls}__section-title`}>About Us</div>
          <div className={`${prefixCls}__copy-4`}>
            We believe the future of retail is social. We also believe that the power of the individual retailer is only
            constrained by their operations. Our goal is to build a platform that enables anyone to scale their business
            with a near zero-overhead model.
            <br />
            <br />
            We are here for you and we’re building this company to serve you. We don’t take your trust and support
            lightly. Thank you and please reach out with any questions or suggestions.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
