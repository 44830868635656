/* eslint-disable no-param-reassign */
import { Divider, Inline } from '@shopify/polaris';
import { Input, message } from 'antd';
import { Mask } from 'antd-mobile';
import cn from 'classnames';
import currency from 'currency.js';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { FiEdit3 } from 'react-icons/fi';
import { MutateOptions } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import AutoLink from '@/components/AutoLink';
import OmittedName from '@/components/biz/OmittedName';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import EmailUpdateDialog from '@/components/EmailUpdateDialog';
import OrderProductsCard from '@/components/OrderProductsCard';
import PageSpinner from '@/components/PageSpinner';
import PaymentMethodEditView from '@/components/PaymentMethodsDialog/components/EditView';
import { PaymentMethodFormRef } from '@/components/PaymentMethodsDialog/types';
import SecondaryNavBar from '@/components/SecondaryNavBar';
import { ADDRESS_DIALOG_PATH } from '@/components/ShippingAddressesDialog/cons';
import StatusViewRenderer from '@/components/StatusViewRenderer';
import Title from '@/components/Title';
import { PAGE_TITLE, SESSION_STORAGE_KEYS } from '@/constants/app';
import { useDesktop } from '@/hooks';
import { useAuth } from '@/hooks/auth';
import useCheckoutOrder from '@/hooks/queries/useCheckoutOrder';
import useCreateOrder from '@/hooks/queries/useCreateOrder';
import useDefaultPaymentMethod from '@/hooks/queries/useDefaultPaymentMethod';
import useOrder from '@/hooks/queries/useOrder';
import useSettings from '@/hooks/queries/useSettings';
import useShippingAddresses from '@/hooks/queries/useShippingAddresses';
import useStores from '@/hooks/queries/useStores';
import useUpdateOrder from '@/hooks/queries/useUpdateOrder';
import useUserSettings from '@/hooks/queries/useUserSettings';
import usePassCheckoutLimit from '@/hooks/useCheckoutLimit';
import useGetUpdatedSearchParamsPath from '@/hooks/useGetUpdatedSearchParamsPath';
import useOrderAdded from '@/hooks/useOrderAdded';
import { CostPanel } from '@/pages/Orders/components';
import { ERROR_CODES } from '@/services/constant';
import { getGroupedPreOrderProducts, getShopifyOrderDetailUrl, showShippingRulesDefaultAddressTag } from '@/utils/biz';
import { formatCurrency, formatterPrice } from '@/utils/util';

import { OrderItem, OrderStatus, OrderType } from '../Orders/types';
import SoldOutError from './SoldOutError';
import styles from './styles.scss';
import { formatAddress, generateOrderData } from './util';

const { prefixCls } = styles;

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

type UpdateOrderProps = {
  dryRun?: boolean;
  onSuccess?: (paymentMethodId: number, creditToApply: number, currentOrderData?: any) => void;
  onError?: (error: Error) => void;
  customOrderParams?: any;
};

function OrdersCheckout({ className, style }: Props): JSX.Element {
  const { checkoutLimit, passCheckoutLimit } = usePassCheckoutLimit();
  const settingsRQ = useSettings();
  const userSettings = useUserSettings();
  const { privateInvAddressSwitch } = useMemo(() => {
    const settingsData = settingsRQ?.data?.data;
    return {
      privateInvAddressSwitch: settingsData?.['private-inv-stock-up'],
    };
  }, [settingsRQ?.data?.data]);
  const isPrivateAddressesOn = !!privateInvAddressSwitch?.data;
  const isDesktop = useDesktop();
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { orderId } = params;
  const cartOrderParams: any = useMemo(
    () => JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.cartOrderParams) ?? 'null'),
    []
  );
  const isCartOrder = orderId === 'cart' && !_.isEmpty(cartOrderParams?.lineItems);
  const hasOrderId = !!orderId && orderId !== 'cart'; // 带着单号进行支付

  // get order info
  const order = useOrder(orderId, !isCartOrder);
  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const checkoutOrder = useCheckoutOrder();

  const addressesRQ = useShippingAddresses();
  const addressesOrigin = useMemo(() => addressesRQ?.data?.data || [], [addressesRQ?.data?.data]);

  const defaultPaymentMethod = useDefaultPaymentMethod();
  const getUpdatedSearchParamsPath = useGetUpdatedSearchParamsPath();
  const paymentMethodEditViewRef = useRef<PaymentMethodFormRef>();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<any>();
  const [currentDiscountCode, setCurrentDiscountCode] = useState<string | undefined>();
  const [currentKiwiWallet, setCurrentKiwiWallet] = useState<number | undefined>();
  const [discountCodeMessage, setDiscountCodeMessage] = useState<any>();
  const [currentNote, setCurrentNote] = useState<string | undefined>();
  const [emailUpdateDialogVisible, setEmailUpdateDialogVisible] = useState(false);
  const [confirmMarketingConsent, setConfirmMarketingConsent] = useState(null);
  const contactEmail = useRef(user?.email || user?.email_pending || '');
  const shopId = order?.data?.data?.shop_order?.shop_id;

  const orderDataRef = useRef<OrderItem>(null);
  const {
    orderData = {},
    shopOrder = {},
    shippingMethodsData = [],
  } = useMemo(() => {
    const newOrderData: OrderItem = isCartOrder
      ? createOrder?.data?.data ?? orderDataRef.current
      : updateOrder?.data?.data ?? orderDataRef.current ?? order?.data?.data?.order ?? createOrder?.data?.data;
    const orderData2 = generateOrderData(newOrderData, currentKiwiWallet);
    const result = {
      orderData: orderData2,
      shopOrder: newOrderData?.shop_order || order?.data?.data?.shop_order,
      shippingMethodsData: orderData2?.shipping_options || [],
    };
    orderDataRef.current = result.orderData;
    return result;
  }, [isCartOrder, order.data, createOrder.data, updateOrder.data, currentKiwiWallet]);

  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  // 数据准备阶段，页面loading
  const beforeContentLoadedLoading =
    _.isEmpty(orderData) && (order.isLoading || createOrder.isLoading) && !isCheckoutLoading;
  // 中途数据变更，页面loading
  const isDataUpdatedLoading =
    !beforeContentLoadedLoading && (order.isLoading || createOrder.isLoading || updateOrder.isLoading);

  const { shipping_address, shipping_method, order_type, isEmptyShippingAddress } = useMemo(() => {
    if (!orderData) {
      return {
        shipping_address: null,
        shipping_method: '',
        order_type: OrderType.Sample,
        isEmptyShippingAddress: false,
      };
    }

    return {
      ...orderData,
      isEmptyShippingAddress:
        (order?.isFetched || createOrder?.data || updateOrder?.data) && _.isEmpty(orderData?.shipping_address),
    };
  }, [createOrder?.data, order?.isFetched, orderData, updateOrder?.data]);

  const shippingAddressText = useMemo(() => {
    if (isEmptyShippingAddress) {
      return '';
    }

    return formatAddress(shipping_address);
  }, [isEmptyShippingAddress, shipping_address]);

  const isSavingPaymentMethod = useRef(false);
  const isSampleOrder = orderData?.order_type === OrderType.Sample;
  const isPrivateInventoryOrder = orderData?.order_type === OrderType.PrivateInv;
  const [shippingMethodErrorVisible, setShippingMethodErrorVisible] = useState(false);

  const paymentMethod = currentPaymentMethod ?? defaultPaymentMethod?.data;
  const isEmptyPaymentMethod = defaultPaymentMethod.isFetched && _.isEmpty(paymentMethod);

  // 从接口获取地址列表
  const discountCode = currentDiscountCode ?? orderData?.discount_codes?.[0]?.code;
  const orderNote = currentNote ?? (cartOrderParams?.note || orderData?.note);

  // shipping rules default address
  const useShippingRulesDefaultAddress = showShippingRulesDefaultAddressTag(orderData?.shipping_address_route_type);
  const originalAddressText = formatAddress(order?.data?.data?.shop_order?.shipping_address);

  const [notifyCustomerActivate, setNotifyCustomerActivate] = useState(null);
  const currentNotifyCustomerActivate = notifyCustomerActivate ?? order?.data?.data?.order?.fulfillment_notify_customer;
  const showNotifyCustomerCheckout = order?.data?.data?.order?.fulfillment_notifiable;

  const stores = useStores();
  const shopifyDomain = stores?.data?.data?.shops?.filter(({ id }) => id === shopOrder?.shop_id)?.[0]?.shopify_domain;
  const shopifyOrderDetailUrl = getShopifyOrderDetailUrl(shopOrder?.shopify_id, shopifyDomain);
  const orderDataAndShopData = {
    ...orderData,
    shop_order: shopOrder,
  };
  const { regardUnpaidSoldOutOrder } = useOrderAdded(orderDataAndShopData);

  const disabledPlaceOrder = checkoutLimit.isLoading || isCheckoutLoading || regardUnpaidSoldOutOrder;

  const { hasPreOrderProducts, hasNormalProducts } = getGroupedPreOrderProducts(orderData?.line_items);

  const showShippingMethodsSection =
    (isCartOrder || isSampleOrder || isPrivateInventoryOrder) && !!shippingMethodsData?.length;

  // 订单防重
  const updateOrderIdInRouteParams = (newOrderId: string, { dryRun: isDryRun }: { dryRun: boolean }) => {
    if (!newOrderId) return;
    if (isDryRun) return;
    if (!isCartOrder) return;
    navigate(`/orders/${newOrderId}/checkout`);
  };

  const handleUpdateOrder = (props?: UpdateOrderProps) => {
    const { dryRun = true, onSuccess, onError, customOrderParams } = props || {};
    const presetParams = _.pick(orderDataRef.current || {}, ['checkout_token', 'shipping_address', 'shipping_method']);

    let orderParams: any = {
      ...presetParams,
      dry_run: Number(dryRun),
    };

    if (isCartOrder && cartOrderParams?.lineItems) {
      _.assign(orderParams, {
        line_items: cartOrderParams?.lineItems,
      });
    }
    if (!_.isEmpty(discountCode)) orderParams.discount_code = _.toUpper(discountCode);
    if (!_.isEmpty(orderNote)) orderParams.note = orderNote;

    orderParams.contact_email = contactEmail.current;
    orderParams.fulfillment_notify_customer = notifyCustomerActivate;
    orderParams.confirm_sms_marketing_consent = confirmMarketingConsent;
    orderParams = {
      ...orderParams,
      ...(customOrderParams || {}),
    };

    const mutateOptions: MutateOptions<any, unknown, any> = {
      onSuccess: (data) => {
        updateOrderIdInRouteParams(data?.data?.id, { dryRun });

        if (
          orderParams.discount_code &&
          orderParams.discount_code === _.toUpper(data?.data?.discount_codes?.[0]?.code)
        ) {
          const codeRemainTimes = Math.max(0, (data?.data?.promo_code_remain_times ?? 0) - 1);
          setDiscountCodeMessage({
            type: 'success',
            message: `Code ${orderParams.discount_code} applied successfully.${
              _.isNil(data?.data?.promo_code_remain_times) ? '' : ` ${codeRemainTimes} use left.`
            }`,
          });
        } else {
          setDiscountCodeMessage(null);
        }
        onSuccess?.(currentKiwiWallet, data);
      },
      onError: (error) => {
        const err = error as Error;

        if (err.name === 'DiscountCodeError') {
          setDiscountCodeMessage({ type: 'error', message: err.message });
          return;
        }

        message.error(err?.message || 'Create Order Failure!');

        onError?.(err);
      },
    };

    isCartOrder
      ? createOrder.mutate(orderParams, mutateOptions)
      : updateOrder.mutate({ id: orderId as string, params: orderParams }, mutateOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleApplyDiscountCode = () => {
    handleUpdateOrder();
  };

  const handleDiscountCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDiscountCode(e.target.value);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentNote(e.target.value);
  };

  const handleCheckoutOrder = async (skipCheckEmail = false) => {
    if (isCartOrder && orderData?.line_items) {
      const productCounts = _(orderData.line_items)
        .groupBy((li) => li.product_id)
        .mapValues((lis) => _.sumBy(lis, (li) => li.qty))
        .value();
      if (!passCheckoutLimit(productCounts)) return;
    }
    // show email update dialog when user email or email_pending is empty
    if (!skipCheckEmail && _.isEmpty(user?.email) && _.isEmpty(user?.email_pending)) {
      setEmailUpdateDialogVisible(true);
      return;
    }

    if (showShippingMethodsSection && hasNormalProducts && !shipping_method) {
      setShippingMethodErrorVisible(true);
      return;
    }

    setIsCheckoutLoading(true);

    let checkout = (paymentMethodId: number, creditToApply: number, currentOrderData?: any) => {
      const id = currentOrderData?.data?.id ?? orderId;
      const orderName = currentOrderData?.data?.name ?? orderData?.name;
      const successCallbackQuery = new URLSearchParams(
        _.omitBy({ orderId: id, order: orderName, shopId }, (val) => !val)
      ).toString();
      const successCallbackUrl = `${window.location.origin}/order-checkout-result?${successCallbackQuery}`;
      const cancelCallbackUrl = `${window.location.origin}/orders/${id}/checkout`;

      checkoutOrder.mutate(
        {
          id,
          payment_method_id: paymentMethodId,
          credit_to_apply: creditToApply,
          success_url: successCallbackUrl,
          cancel_url: cancelCallbackUrl,
        },
        {
          onSettled: (data, error) => {
            const isError = error || _.isEmpty(data?.data);
            if (isError) {
              if (error?.errno === ERROR_CODES.duplicatePaidOrder) {
                window.location.assign(successCallbackUrl);
                return;
              }
              const errorMessage = (error as Error)?.message || 'Place Order Failure!';

              message.error(errorMessage);
              setIsCheckoutLoading(false);
            } else {
              sessionStorage.removeItem(SESSION_STORAGE_KEYS.cartOrderParams);
              window.location.assign(data.data);
            }
          },
        }
      );
    };

    if (_.isEmpty(paymentMethod)) {
      isSavingPaymentMethod.current = true;
      const savePaymentMethodResult = await paymentMethodEditViewRef.current?.save();
      isSavingPaymentMethod.current = false;

      if (savePaymentMethodResult?.error) {
        message.error(savePaymentMethodResult.error.message || 'Save Payment Method Failure!');
        setIsCheckoutLoading(false);
        return;
      }

      if (savePaymentMethodResult?.paymentMethod) {
        checkout = _.partial(checkout, Number(savePaymentMethodResult?.paymentMethod?.id));
      }
    } else {
      checkout = _.partial(checkout, Number(paymentMethod.id));
    }

    const shouldUpdateOrder =
      isCartOrder || [currentDiscountCode, currentNote, contactEmail.current].some((v) => !_.isEmpty(v));
    shouldUpdateOrder
      ? handleUpdateOrder({
          dryRun: false,
          onSuccess: checkout,
          onError: () => setIsCheckoutLoading(false),
        })
      : checkout(null, currentKiwiWallet);
  };

  const switchNotifyCustomer = (isOpen) => {
    setNotifyCustomerActivate(isOpen);
  };

  const updateCurrentShippingMethod = useCallback(
    (shippingMethod) => {
      let newOrderType = order_type;
      if (hasOrderId && order_type === OrderType.PrivateInv) {
        newOrderType = OrderType.Sample;
      }
      handleUpdateOrder({
        customOrderParams: {
          shipping_method: shippingMethod,
          order_type: newOrderType,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOrderId, order_type]
  );

  const shippingMethodsSection = useMemo(() => {
    if (!showShippingMethodsSection) return null;

    if (order_type === OrderType.PrivateInv) {
      return (
        <div className={`${prefixCls}__section`}>
          <div className={`${prefixCls}__section-header`}>
            <div className={`${prefixCls}__section-title`}>Shipping Method</div>
          </div>
          <div className={cn(`${prefixCls}__section-body`, `${prefixCls}__shipping-method-wrapper`)}>
            <div className={`${prefixCls}__preorder-shipping-wrapper`}>
              <p className={`${prefixCls}__preorder-shipping-wrapper-desc`}>Private Inventory</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`${prefixCls}__section`}>
        <div className={`${prefixCls}__section-header`}>
          <div className={`${prefixCls}__section-title`}>
            Shipping Method
            {hasNormalProducts && (
              <div className={`${prefixCls}__section-title-extra`}>
                Check if a product is International or USA from their detail pages.
              </div>
            )}
          </div>
        </div>
        <div className={cn(`${prefixCls}__section-body`, `${prefixCls}__shipping-method-wrapper`)}>
          {hasNormalProducts && (
            <div>
              <div className={`${prefixCls}__shipping-method`}>
                {shippingMethodsData?.map((item, index) => (
                  <div
                    key={item?.method}
                    className={cn(`${prefixCls}__shipping-method-item`, {
                      [`${prefixCls}__shipping-method-item--active`]: shipping_method === item?.method,
                    })}
                    onClick={() => updateCurrentShippingMethod(item.method)}
                  >
                    <div className={`${prefixCls}__shipping-method-item-header`}>
                      <div className={`${prefixCls}__shipping-method-item-title`}>
                        {item?.title}
                        {/* !!! 此判断默认第一条是标准shipping method */}
                        {index > 0 && <span className={`${prefixCls}__shipping-method-item-title-tag`}>New</span>}
                      </div>
                      <div className={`${prefixCls}__shipping-method-item-price`}>{formatCurrency(item?.price)}</div>
                    </div>
                    <div className={`${prefixCls}__shipping-method-item-body`}>{item?.body_text}</div>
                  </div>
                ))}
              </div>
              {shippingMethodErrorVisible && (
                <div className={`${prefixCls}__shipping-method-error`}>Please select a shipping method</div>
              )}
            </div>
          )}

          {hasNormalProducts && hasPreOrderProducts && <Divider />}
          {hasPreOrderProducts && (
            <div className={`${prefixCls}__preorder-shipping-wrapper`}>
              <p className={`${prefixCls}__preorder-shipping-wrapper-desc`}>
                Shipping method for preorder products will be standard and charged base on different estimated
                dates/batches.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    hasNormalProducts,
    hasPreOrderProducts,
    order_type,
    shippingMethodErrorVisible,
    shippingMethodsData,
    shipping_method,
    showShippingMethodsSection,
    updateCurrentShippingMethod,
  ]);

  const addressToggler = useMemo(() => {
    // TODO: 需要判断订单状态么
    if (isEmptyShippingAddress) {
      return (
        <AutoLink
          to={getUpdatedSearchParamsPath({
            dialog: ADDRESS_DIALOG_PATH.add,
            init: 1,
            selectMode: 1,
            from: isPrivateAddressesOn ? 'list_inv' : '',
          })}
          replace
        >
          <FiEdit3 fill="currentColor" />
          Add
        </AutoLink>
      );
    }

    if (orderData?.status === OrderStatus.Unpaid) {
      return (
        <AutoLink
          to={getUpdatedSearchParamsPath({
            dialog: isPrivateAddressesOn ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
            selectMode: 1,
            addressKey: orderData?.shipping_address?.key,
          })}
          replace
        >
          <FiEdit3 fill="currentColor" />
          Change
        </AutoLink>
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyShippingAddress, isPrivateAddressesOn, orderData?.shipping_address?.key, orderData?.status]);

  // 监听地址改变
  useEffect(() => {
    const handleShippingAddressChange = ((e: CustomEvent) => {
      const remember_address = !!e?.detail?.remember_address;
      handleUpdateOrder({
        customOrderParams: {
          shipping_address: e.detail?.address,
          remember_address,
        },
      });
    }) as EventListener;
    document.addEventListener('shippingAddresses:change', handleShippingAddressChange);

    return () => {
      document.removeEventListener('shippingAddresses:change', handleShippingAddressChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 监听 payment method改变
  useEffect(() => {
    const handlePaymentMethodChange = ((e: CustomEvent) =>
      setCurrentPaymentMethod(e.detail?.paymentMethod)) as EventListener;
    document.addEventListener('paymentMethods:change', handlePaymentMethodChange);

    return () => {
      document.removeEventListener('paymentMethods:change', handlePaymentMethodChange);
    };
  }, []);

  // 监听shipping_method改变
  useEffect(() => {
    if (shipping_method) {
      setShippingMethodErrorVisible(false);
    }
  }, [shipping_method]);

  // 正常流程下单，进入页面，dry run
  useEffect(() => {
    if (isCartOrder) {
      handleUpdateOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartOrder]);

  useEffect(() => {
    const hasAddresses = addressesRQ.isFetched && addressesOrigin?.length;
    if (isEmptyShippingAddress) {
      if (hasAddresses) {
        navigate(
          getUpdatedSearchParamsPath({
            dialog: isPrivateAddressesOn ? ADDRESS_DIALOG_PATH.list_inv : ADDRESS_DIALOG_PATH.list,
            init: 1,
            selectMode: 1,
          }),
          { replace: true }
        );
      } else {
        navigate(
          getUpdatedSearchParamsPath({
            dialog: ADDRESS_DIALOG_PATH.add,
            init: 1,
            from: isPrivateAddressesOn ? 'list_inv' : '',
            selectMode: 1,
          }),
          { replace: true }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyShippingAddress, isPrivateAddressesOn]);

  return (
    <div className={cn(prefixCls, className)} style={style}>
      <Helmet>
        <html lang="en" className={`${prefixCls}-active`} />
        <title>{PAGE_TITLE.checkout}</title>
      </Helmet>

      <SecondaryNavBar
        className={`${prefixCls}__secondary-nav-bar`}
        backReplace
        // eslint-disable-next-line no-nested-ternary
        backUrl={isCartOrder ? '' : shopId ? `/orders?shopId=${shopId}` : '/orders'}
      >
        {PAGE_TITLE.checkout}
      </SecondaryNavBar>

      <div className={`${prefixCls}__content`}>
        <div className={`${prefixCls}__title_wrapper`}>
          <Title showBackTo title={PAGE_TITLE.checkout} />
        </div>
        <StatusViewRenderer
          data={orderData}
          error={_.isEmpty(orderData) && (order.error || createOrder.error)}
          isLoading={beforeContentLoadedLoading}
          isEmpty={false}
          statusStyle={{ marginTop: 64 }}
        >
          {!isDesktop && orderData?.discount_code && (
            <div className={`${prefixCls}__product-banner`}>
              ${formatterPrice(orderData?.total_items_discounts)} Saved! Coupon {orderData?.discount_code} Applied
            </div>
          )}
          <div className={`${prefixCls}__layout`}>
            <div className={`${prefixCls}__main`}>
              {regardUnpaidSoldOutOrder && !isDesktop && (
                <div className={`${prefixCls}__sold_out-error-section`}>
                  <SoldOutError order={orderDataAndShopData} shopifyOrderDetailUrl={shopifyOrderDetailUrl} />
                </div>
              )}
              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <Inline gap="2" align="center">
                    <div className={`${prefixCls}__section-title`}>Shipping Info</div>
                  </Inline>

                  <Inline wrap={false} gap="2">
                    {addressToggler}
                  </Inline>
                </div>
                <div className={`${prefixCls}__section-body`}>
                  {isEmptyShippingAddress ? (
                    <StatusViewRenderer isEmpty emptyTitle="Please add a shipping address." />
                  ) : (
                    <div className={`${prefixCls}__shipping-info`}>
                      {useShippingRulesDefaultAddress && (
                        <div className={`${prefixCls}__shipping-info-shipping_rules-text`}>
                          We’ve routed this order based on your shipping rules
                        </div>
                      )}
                      <div className={`${prefixCls}__shipping-info-text`}>{shippingAddressText}</div>
                    </div>
                  )}
                </div>
              </div>

              {useShippingRulesDefaultAddress && (
                <div className={cn(`${prefixCls}__section`, 'original_address')}>
                  <div className={`${prefixCls}__section-header-original_address`}>Original Address</div>
                  <div className={`${prefixCls}__section-body-original_address`}>
                    {originalAddressText || 'No Address'}
                  </div>
                </div>
              )}

              {shippingMethodsSection}

              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Payment Method</div>
                  {!isEmptyPaymentMethod && !isSavingPaymentMethod.current && (
                    <div className={`${prefixCls}__section-actions`}>
                      <AutoLink
                        to={getUpdatedSearchParamsPath({
                          dialog: 'payment-methods',
                          selectMode: 1,
                          paymentMethodType: 'card',
                        })}
                        replace
                      >
                        <FiEdit3 fill="currentColor" />
                        Change
                      </AutoLink>
                    </div>
                  )}
                </div>
                <div className={`${prefixCls}__section-body`}>
                  {isEmptyPaymentMethod || isSavingPaymentMethod.current ? (
                    <PaymentMethodEditView ref={paymentMethodEditViewRef} inlineMode />
                  ) : (
                    <div className={`${prefixCls}__shipping-info`}>
                      <OmittedName name={paymentMethod?.detail?.brand} last4={paymentMethod?.detail?.last4} />
                    </div>
                  )}
                </div>
              </div>

              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Order Summary</div>
                </div>
                <div className={`${prefixCls}__section-body`}>
                  <div className={`${prefixCls}__input-item`}>
                    <div className={`${prefixCls}__input-item-input-group`}>
                      <Input
                        className={`${prefixCls}__discount-code-input`}
                        size="large"
                        allowClear
                        placeholder="Enter promo code"
                        value={discountCode}
                        onChange={handleDiscountCodeChange}
                        onPressEnter={handleApplyDiscountCode}
                      />
                      <Button color="main" onClick={handleApplyDiscountCode}>
                        Apply
                      </Button>
                    </div>
                    {!_.isEmpty(discountCodeMessage) && (
                      <div
                        className={cn(`${prefixCls}__input-item-message`, {
                          'text-error': discountCodeMessage?.type === 'error',
                          'text-success': discountCodeMessage?.type === 'success',
                        })}
                      >
                        {discountCodeMessage?.message}
                      </div>
                    )}
                  </div>
                  <CostPanel
                    order={orderData}
                    walletSelectable
                    onChangeKiwiWallet={(value) => setCurrentKiwiWallet(value)}
                  />
                </div>
              </div>

              <div className={`${prefixCls}__section`}>
                <div className={`${prefixCls}__section-header`}>
                  <div className={`${prefixCls}__section-title`}>Order Notes</div>
                </div>
                <div className={`${prefixCls}__section-body`}>
                  <Input size="large" placeholder="Enter" value={orderNote} onChange={handleNoteChange} />
                </div>
              </div>

              {showNotifyCustomerCheckout && (
                <div className={`${prefixCls}__section`}>
                  <div className={`${prefixCls}__section-header`}>
                    <div className={cn(`${prefixCls}__section-title`, 'notify_customer')}>
                      <Checkbox
                        color="primary"
                        shape="square"
                        checked={currentNotifyCustomerActivate}
                        onChange={(isChecked) => switchNotifyCustomer(isChecked)}
                      />
                      <span className={`${prefixCls}__section-title-text`}>Notify Customer of Shipment</span>
                    </div>
                  </div>
                </div>
              )}
              <OrderProductsCard className={`${prefixCls}__order-products-section`} data={orderData?.line_items} />
            </div>

            <div className={`${prefixCls}__actions-wrapper`}>
              {isDesktop && orderData?.discount_code && (
                <motion.div
                  className={`${prefixCls}__product-banner`}
                  variants={{
                    show: {
                      opacity: 1,
                    },
                    halfShow: {
                      opacity: 0.5,
                    },
                    hide: {
                      opacity: 0,
                    },
                  }}
                  transition={{ duration: 1 }}
                >
                  ${formatterPrice(orderData?.total_items_discounts)} Saved! Coupon {orderData?.discount_code} Applied
                </motion.div>
              )}
              <div className={`${prefixCls}__actions`}>
                <div className={`${prefixCls}__actions-title`}>Order Summary</div>
                <div className={`${prefixCls}__info-item-wrapper`}>
                  <div className={`${prefixCls}__info-item`}>
                    <div className={`${prefixCls}__info-item-label`}>Total to Pay</div>
                    <div className={`${prefixCls}__info-item-text`}>
                      $
                      {formatterPrice(
                        currency(orderData?.total_price)
                          .subtract(orderData?.paid_by_card)
                          .subtract(currency(orderData?.paid_by_credit).divide(100).value).value
                      )}
                    </div>
                  </div>
                </div>
                {userSettings?.data?.data?.sms_marketing_consent_state !== 'subscribed' && (
                  <div className={`${prefixCls}__marketing-consent`}>
                    <div className={`${prefixCls}__marketing-consent-checkbox`}>
                      <Checkbox
                        id="sms_consent"
                        color="primary"
                        shape="square"
                        checked={confirmMarketingConsent}
                        onChange={(isChecked) => setConfirmMarketingConsent(isChecked)}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="sms_consent">Text me with news and offers</label>
                    </div>
                    <span className={`${prefixCls}__marketing-consent-tips`}>
                      By signing up via text, you agree to receive recurring automated marketing messages, including
                      cart reminders, at the phone number provided. Consent is not a condition of purchase. Reply STOP
                      to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates may apply. View
                      our&nbsp;
                      <AutoLink target="_blank" to="https://app.kiwidrop.com/privacy-policy">
                        Privacy Policy
                      </AutoLink>
                      &nbsp;and&nbsp;
                      <AutoLink target="_blank" to="https://app.kiwidrop.com/terms-of-service">
                        Terms of Service
                      </AutoLink>
                      .
                    </span>
                  </div>
                )}
                <div className={`${prefixCls}__place-order-button`}>
                  <Button
                    color="primary"
                    shape="rounded"
                    block
                    loading={isCheckoutLoading}
                    loadingText="Processing"
                    disabled={disabledPlaceOrder}
                    onClick={() => handleCheckoutOrder()}
                    className={`${prefixCls}__place-order-button-content`}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
              {regardUnpaidSoldOutOrder && isDesktop && (
                <div className={`${prefixCls}__sold_out-error-section`}>
                  <SoldOutError order={orderDataAndShopData} shopifyOrderDetailUrl={shopifyOrderDetailUrl} />
                </div>
              )}
            </div>
          </div>

          <EmailUpdateDialog
            visible={emailUpdateDialogVisible}
            onOk={(email) => {
              contactEmail.current = email;
              setEmailUpdateDialogVisible(false);
              handleCheckoutOrder(true);
            }}
            onClose={() => setEmailUpdateDialogVisible(false)}
          />
        </StatusViewRenderer>
      </div>

      <Mask color="white" opacity={0.7} visible={isCheckoutLoading}>
        <StatusViewRenderer
          isLoading
          statusStyle={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        />
      </Mask>

      {isDataUpdatedLoading && <PageSpinner />}
    </div>
  );
}

export default OrdersCheckout;
