import { Button as AntButton } from 'antd-mobile';
import cn from 'classnames';
import React from 'react';
import { IoIosAddCircle } from 'react-icons/io';

import AutoLink from '@/components/AutoLink';

import css from './index.module.scss';

type AntButtonProps = React.ComponentProps<typeof AntButton>;
export interface Props extends Omit<AntButtonProps, 'color'> {
  icon?: React.ReactNode;
  link?: string;
  target?: React.HTMLAttributeAnchorTarget;
  color?: AntButtonProps['color'] | 'main' | 'acrylic' | 'white';
  bold?: boolean;
  bordered?: boolean;
  shadowed?: boolean;
  underline?: boolean;
  replace?: boolean;
  wrapperClassName?: string;
}

const baseColors: any = {
  main: 'primary',
  acrylic: 'default',
  white: 'default',
};

function Button({
  className,
  icon,
  link,
  color = 'default',
  bold = true,
  bordered = true,
  shadowed = false,
  underline,
  children,
  target,
  replace,
  wrapperClassName,
  ...resetProps
}: Props): JSX.Element {
  /**
   * 保留 button__icon 是因为在下面位置有样式重写
   * pages/SellerCollectionList/styles.scss
   *    .seller-collection-list-page__create-new-collection-btn .button__icon
   */
  const content = icon ? (
    <span className={cn(css.inner_wrapper, 'button__inner-wrapper')}>
      <span className={cn(css.icon, 'button__icon')}>{icon}</span>
      {children && <span className={cn(css.text, 'button__text')}>{children}</span>}
    </span>
  ) : (
    children
  );

  /**
   * 保留 button 是因为在下面位置有样式重写
   * components/ShippingAddressesDialog/style.scss
   *    .shipping-address-dialog .modal__footer .button
   *
   * pages/Orders/styles.scss
   *    .orders-page__filters .button.primary
   *    .orders-page__filters .button.default
   */
  let button = (
    <AntButton
      className={cn(css.ns_com_button_main, 'button', className, color, {
        [css.bold]: bold,
        [css.bordered]: bordered,
        [css.shadowed]: shadowed,
        [css.underline]: underline,
      })}
      color={baseColors[String(color)] ?? color}
      {...resetProps}
    >
      {content}
    </AntButton>
  );

  if (link) {
    button = (
      <AutoLink
        className={cn(css.ns_com_button_main_wrapper_auto_link, wrapperClassName)}
        to={link}
        target={target}
        replace={replace}
      >
        {button}
      </AutoLink>
    );
  }

  return button;
}

export interface ButtonAddProps extends Props {
  floating?: boolean | 'mobile';
}

export function ButtonAdd({ className, floating, children = 'Create New', ...restProps }: ButtonAddProps): JSX.Element {
  return (
    <Button
      className={cn(css.button_add, className, {
        [css.add_floating]: floating === true,
        [css.add_floating_mobile]: floating === 'mobile',
      })}
      block
      shape="rounded"
      icon={<IoIosAddCircle />}
      {...restProps}
    >
      {children}
    </Button>
  );
}

Button.Add = ButtonAdd;
export default Button;
