import _ from 'lodash';
import { useMemo } from 'react';

import { checkIsSoldOut } from '@/utils/biz';

const useCartItems = (items, normalSelectedIds = []) => {
  const [soldOutItems, inStockItems] = useMemo(
    () => _.partition(items, (item) => checkIsSoldOut(item?.available_quantity)),
    [items]
  );

  const inStockItemsGroupByProductId = useMemo(() => _.groupBy(inStockItems, 'product_id'), [inStockItems]);

  const inStockItemsSpuDimension = useMemo(
    () =>
      Object.keys(inStockItemsGroupByProductId).map((productId) => {
        const productItems = inStockItemsGroupByProductId[productId];
        const { drop_soon, ...rest } = productItems[0] || {};
        const drop_soon_qty_avail = drop_soon?.qty_avail;
        const inDropSoon = !!drop_soon?.at && Date.now() < new Date(drop_soon?.at).getTime();
        const curQty = inDropSoon
          ? productItems.reduce((total, { variant_id, qty }) => {
              if (normalSelectedIds.includes(variant_id)) {
                return total + qty;
              }
              return total;
            }, 0)
          : 0;
        const showMessage = inDropSoon && curQty > drop_soon_qty_avail;
        return {
          product_id: productId,
          items: productItems,
          curQty,
          showMessage,
          drop_soon,
          ...rest,
        };
      }),
    [inStockItemsGroupByProductId, normalSelectedIds]
  );

  return {
    soldOutItems,
    inStockItems,
    inStockItemsSpuDimension,
  };
};

export default useCartItems;
