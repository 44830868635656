import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '@/hooks/auth';
import useStores from '@/hooks/queries/useStores';

export default function useCurrentStore(waitAuth?: boolean) {
  const { user } = useAuth();
  const stores = useStores(waitAuth);
  const storesShops = stores?.data?.data?.shops;
  const storeIdFromLocalStorage = JSON.parse(localStorage.getItem('currentStoreId') ?? '{}')?.[String(user?.id)];
  const [currentStoreId, setCurrentStoreId] = useState(storeIdFromLocalStorage);
  const defaultStore = storesShops?.[0];
  const currentStore = currentStoreId ? _.find(storesShops, { id: currentStoreId }) ?? defaultStore : defaultStore;

  const setCurrentStore = useCallback(
    (id: string) => {
      let prevCurrentStoreIdMap = JSON.parse(localStorage.getItem('currentStoreId') ?? '{}');
      if (!_.isPlainObject(prevCurrentStoreIdMap)) prevCurrentStoreIdMap = {};

      if (user?.id) {
        const newCurrentStoreIdMap = {
          ...prevCurrentStoreIdMap,
          [`${user.id}`]: id,
        };
        setCurrentStoreId(id);
        localStorage.setItem('currentStoreId', JSON.stringify(newCurrentStoreIdMap));
      }
    },
    [user?.id]
  );

  useEffect(() => {
    if (currentStore && currentStore?.id !== currentStoreId) setCurrentStore(`${currentStore?.id}`);
  }, [currentStore, currentStoreId, setCurrentStore]);

  return { ...stores, data: currentStore, setCurrentStore };
}
