import _ from 'lodash';
import { useMemo } from 'react';

import { LOCAL_STORAGE_KEYS } from '@/constants/app';

import { useAuth } from '../auth';

const useLastAddedSellerCollections = (sellerCollectionsData = []) => {
  const { user } = useAuth();
  const idsOrigin = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId) ?? '{}')?.[
    String(user?.id)
  ];

  const ids = useMemo(() => (idsOrigin ? _.split(idsOrigin, ',') : []), [idsOrigin]);
  const data = useMemo(
    () => sellerCollectionsData.filter(({ id }) => ids.includes(String(id))),
    [ids, sellerCollectionsData]
  );

  const update = (collectionIds: string[]) => {
    const userId = user.id;
    const curCollectionId = _.join(collectionIds, ',');
    const idsFromLocalStorageStr = localStorage.getItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId);
    const idsFromLocalStorageObj = JSON.parse(idsFromLocalStorageStr);
    const isValidated = !!idsFromLocalStorageStr && !!Object.keys(idsFromLocalStorageObj)?.length;

    let newIdsForLocalStorageObj = {};
    if (isValidated) {
      newIdsForLocalStorageObj = { ...idsFromLocalStorageObj, [userId]: curCollectionId };
    } else {
      newIdsForLocalStorageObj = { [userId]: curCollectionId };
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.lastFavoritedCollectionId, JSON.stringify(newIdsForLocalStorageObj));
  };

  return {
    data,
    ids,
    update,
  };
};

export default useLastAddedSellerCollections;
