import { Form, Input, message } from 'antd';
import { Switch } from 'antd-mobile';
import cn from 'classnames';
import { useEffect } from 'react';

import Button from '@/components/Button';
import FormItemEditor from '@/components/FormItemEditor';
import Modal, { Props as ModalProps } from '@/components/Modal';
import { useDesktop } from '@/hooks';
import useAddSellerCollection from '@/hooks/queries/useAddSellerCollection';

import css from './index.module.scss';

export interface Props extends Omit<ModalProps, 'children'> {
  initialValues?: {
    title?: string;
    body_html?: string;
    is_public?: boolean;
  };
  values?: {
    title?: string;
  };
  onOk?: (newCollection?: any) => void;
  onCancel?: () => void;
}

const queryParams = {
  page_size: 3000,
};

function SellerCollectionTitleEditDialog({
  className,
  initialValues,
  values,
  onOk,
  onCancel,
  ...restProps
}: Props): JSX.Element {
  const isDesktop = useDesktop();
  const addSellerCollection = useAddSellerCollection(queryParams);
  const [form] = Form.useForm();

  const handleCreate = () => {
    if (addSellerCollection.isLoading) return;

    form.validateFields().then(async (val) => {
      try {
        const formdata = val || {};
        const newCollection = await addSellerCollection.mutateAsync({ ...formdata });
        message.success('Create Board Success!');
        onOk?.(newCollection);
      } catch (error) {
        message.error(
          (error as Error)?.message === 'DUPLICATED_TITLE'
            ? 'You already have a board with that name!'
            : (error as Error)?.message || 'Create Board Failure!'
        );
      }
    });
  };

  const afterShow = () => {
    if (!isDesktop) {
      window.scrollTo(0, 200);
    }
  };

  const afterClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  return (
    <Modal
      className={cn(css.ns_com_seller_collection_title_edit_dialog_main, className)}
      title="Create new Board"
      zIndex={1001}
      onClose={onCancel}
      // fix ios safari scroll issue
      afterShow={afterShow}
      afterClose={afterClose}
      footer={
        <Button
          color="main"
          block
          loading={addSellerCollection.isLoading}
          disabled={addSellerCollection.isLoading}
          onClick={handleCreate}
        >
          Create
        </Button>
      }
      {...restProps}
    >
      <Form
        className={cn(css.modal_like_board_edit, css.form_wrapper)}
        form={form}
        colon={false}
        layout="vertical"
        initialValues={initialValues}
      >
        <Form.Item name="title" label="Board Name" rules={[{ required: true }]}>
          <Input className={css.input} size="large" allowClear placeholder="Board Name" />
        </Form.Item>
        <Form.Item name="body_html" label="Board Description">
          <FormItemEditor className={css.ck_editor} placeholder="Fun, warm, loving styles for the summer" />
        </Form.Item>
        <div className={css.toggle_public}>
          <span className={css.public_title}>Make this board public</span>
          <Form.Item name="is_public" label="Make this board public" valuePropName="checked" noStyle>
            <Switch className={css.input} />
          </Form.Item>
        </div>
        <span className={css.public_desc}>Enabling this allows you to share this board</span>
      </Form>
    </Modal>
  );
}

export default SellerCollectionTitleEditDialog;
