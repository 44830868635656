import cn from 'classnames';
import moment from 'moment';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import AutoLink from '@/components/AutoLink';

import css from './index.module.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

function Footer({ className, style }: Props): JSX.Element {
  return (
    <div className={cn(css.ns_com_footer_main, className)} style={style}>
      <div className={css.content}>
        <div className={css.main}>
          <div className={css.section}>
            <div className={css.section_title}>COMPANY</div>
            <div className={css.section_body}>
              <div className={css.section_links}>
                <AutoLink to="https://help.kiwidrop.com/hc/en-us/articles/26039168642707-About-Us" target="_blank">
                  About us
                </AutoLink>
                <AutoLink to="mailto:support@kiwidrop.com" target="_blank">
                  Contact us
                </AutoLink>
              </div>
            </div>
          </div>

          <div className={css.section}>
            <div className={css.section_title}>SUPPORT</div>
            <div className={css.section_body}>
              <div className={css.section_links}>
                <AutoLink
                  to="https://help.kiwidrop.com/hc/en-us/categories/26038440549907-Getting-Started"
                  target="_blank"
                >
                  Start Selling
                </AutoLink>
                <AutoLink
                  to="https://help.kiwidrop.com/hc/en-us/articles/26041436896019-Shipping-Policy"
                  target="_blank"
                >
                  Shipping Policy
                </AutoLink>
                <AutoLink
                  to="https://help.kiwidrop.com/hc/en-us/articles/26041505447571-Returns-Refunds-Policy"
                  target="_blank"
                >
                  Returns & Refunds
                </AutoLink>
                <AutoLink to="https://help.kiwidrop.com" target="_blank">
                  More FAQ
                </AutoLink>
              </div>
            </div>
          </div>

          <div className={css.section}>
            <div className={css.section_title}>COMMUNITY</div>
            <div className={css.section_body}>
              <div className={css.section_links}>
                <AutoLink to="https://discord.gg/TMBsCXqKKX" target="_blank">
                  Discord Gems
                </AutoLink>
                <AutoLink to="https://www.facebook.com/KiwiDrop-108899051488263" target="_blank">
                  FB Crew
                </AutoLink>
              </div>
              <div className={css.section_icons}>
                <AutoLink to="https://www.instagram.com/trykiwidrop" target="_blank">
                  <FaInstagram />
                </AutoLink>
                <AutoLink to="https://www.facebook.com/KiwiDrop-108899051488263" target="_blank">
                  <FaFacebook />
                </AutoLink>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className={css.secondary}>
          <div className={css.secondary_item_wrapper}>
            <div className={css.secondary_item}>@{moment().year()} Kiwidrop Inc.</div>
            <div className={css.secondary_item}>
              <AutoLink to="/terms-of-service" onClick={() => window.scrollTo(0, 0)}>
                Terms of Service
              </AutoLink>
            </div>
            <div className={css.secondary_item} onClick={() => window.scrollTo(0, 0)}>
              <AutoLink to="/privacy-policy">Privacy Policy</AutoLink>
            </div>
            <div className={css.secondary_item} onClick={() => window.scrollTo(0, 0)}>
              <AutoLink to="/intellectual-property-policy">IP Policy</AutoLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
