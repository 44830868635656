.ns_com_status_view_renderer_main {
  $statusIconSize: 48px;
  $statusPadding: 24px 0;

  &:not(.is_success) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  & > .loading {
    padding: $statusPadding;

    :global {
      .adm-spin-loading {
        --size: #{$statusIconSize};
      }
    }
  }

  & > .error {
    padding: $statusPadding;

    :global {
      .adm-empty {
        padding: 0;

        .adm-empty-image-container {
          font-size: $statusIconSize;
          color: var(--color-gray3);
        }
      }
    }

    .error_info {
      text-align: center;
    }

    .error_message {
      color: var(--color-gray2);
      font-size: var(--font-size1);
      transform: scale(0.75);
    }
  }

  & > .empty {
    padding: $statusPadding;

    :global {
      .adm-empty {
        padding: 0;

        .adm-empty-image {
          width: auto;
          height: $statusIconSize;
        }
      }
    }
  }

  .load_more_btn {
    display: block;
    margin: var(--spacing-l) auto;
    width: 200px;

    @media screen and (min-width: 769px) {
      margin-top: 36px;
    }
  }
}
