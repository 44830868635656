import cn from 'classnames';
import { FiAlertCircle } from 'react-icons/fi';

import { UpArrow } from '@/components/Icon';

import css from './Boost.module.scss';

type Props = {
  ratioStr: string;
  earnPrice: string;
  className?: string;
};

const Boost = ({ ratioStr, earnPrice, className }: Props) => (
  <div className={cn(css.ns_com_boost_main, className)}>
    <span>
      <span className={css.ratio}>
        <UpArrow width="12" height="11" /> {ratioStr} Boost Reward
      </span>
      <span>Extra ${earnPrice} per sale!</span>
    </span>
    <a href="https://help.kiwidrop.com/knowledge/kiwi-boost" target="_blank" rel="noreferrer">
      <FiAlertCircle size={16} strokeWidth={2} color="#424242" />
    </a>
  </div>
);

export default Boost;
