.ns_com_price_rules_modal_main {
  :global {
    .modal__container .modal__body {
      text-align: center;
      font-size: 16px;
      color: var(--color-gray5);
    }
    .modal__footer {
      display: flex;
      justify-content: center;
      button {
        width: 230px;
        &:first-child {
          margin-right: 8px;
          background-color: var(--color-primary);
          border-color: var(--color-primary);
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    :global {
      .modal__container {
        min-width: 0 !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    :global {
      .modal__header {
        display: none;
      }
      .modal__container .modal__body {
        padding-top: 16px;
        padding-bottom: 4px;
      }
      .modal__footer {
        border: none !important;
      }
    }
  }
}
