import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

export enum TimeStatus {
  Coming = 'coming',
  Started = 'started',
  Ended = 'ended',
}

const timeDesc = {
  [TimeStatus.Coming]: 'Starts in',
  [TimeStatus.Started]: 'Ends in',
  [TimeStatus.Ended]: 'Ends in',
};

const calculateStatus = (beginsT, endsT) => {
  const currentT = moment();
  if (currentT.isBefore(beginsT)) {
    return TimeStatus.Coming;
  }
  if (currentT.isBefore(endsT)) {
    return TimeStatus.Started;
  }

  return TimeStatus.Ended;
};

const useTimeStatus = (startsOrigin, ends) => {
  const starts = startsOrigin || Date.now();
  const beginsT = moment(starts);
  const endsT = moment(ends);

  const [status, setStatus] = useState(calculateStatus(beginsT, endsT));

  const update = useCallback(() => {
    const targetStatus = calculateStatus(beginsT, endsT);
    setStatus(targetStatus);
  }, [beginsT, endsT]);

  useEffect(() => {
    update();
  }, [update]);

  if (!starts || !ends) {
    return {
      status: null,
      desc: '',
      update: () => {},
    };
  }

  return {
    status,
    desc: timeDesc[status],
    update,
  };
};

export default useTimeStatus;
