.ns_com_kiwi_wallet_select_main {
  .inner_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    font-size: var(--font-size3);
    line-height: 1.4;
    font-weight: 500;
  }

  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size3);
    line-height: 1.4;
    color: var(--color-black);
    cursor: pointer;

    .select_placeholder {
      color: var(--color-gray4);
    }

    :global(.react-icons) {
      margin-left: var(--spacing-xs);
      color: var(--color-black);
    }
  }

  .popup {
    min-height: 260px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .popup_title {
      position: relative;
      padding: var(--spacing-l) 30px;
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      font-weight: 500;
      text-align: center;
      border-bottom: 1px solid #e8e8e8;
    }

    .popup_close_btn {
      position: absolute;
      right: var(--spacing-l);
      top: var(--spacing-l);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-gray5);
      font-size: 24px;
      line-height: 0;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .popup_total_info {
      padding: var(--spacing-l);
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
      text-align: center;

      .popup_amount {
        margin-left: var(--spacing-l);
      }
    }

    .popup_amount {
      color: var(--color-black);
      font-size: var(--font-size3);
      line-height: 1.4;
    }

    .popup_list {
      :global {
        .adm-list-item,
        .adm-list-item-content-arrow {
          color: var(--color-black);
        }
        .adm-list-item-content {
          padding-right: 0;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .popup {
      width: var(--content-small-max-width);
      border-radius: 16px;
      min-height: auto;
      overflow: hidden;
    }
  }
}
