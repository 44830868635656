import { Empty, SpinLoading } from 'antd-mobile';
import type { SpinLoadingProps } from 'antd-mobile/es/index';
import cn from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { BsEmojiFrown } from 'react-icons/bs';

import Button from '@/components/Button';
import InfiniteScroll from '@/components/InfiniteScroll';

import css from './index.module.scss';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
  statusClassName?: string;
  statusStyle?: React.CSSProperties;
  loadingClassName?: string;
  loadingStyle?: React.CSSProperties;
  errorClassName?: string;
  errorStyle?: React.CSSProperties;
  emptyClassName?: string;
  emptyStyle?: React.CSSProperties;
  data?: any;
  error?: any;
  errorTitle?: React.ReactNode;
  emptyTitle?: React.ReactNode;
  showErrorMessage?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  loadingContent?: React.ReactNode;
  errorContent?: React.ReactNode;
  emptyContent?: React.ReactNode;
  hasMore?: boolean;
  autoLoadMore?: boolean;
  loadMore?: () => Promise<any>;
  children?: React.ReactNode;
  spinLoadingColor?: 'default' | 'primary' | 'white';
}

function StatusViewRenderer({
  className,
  style,
  statusClassName,
  statusStyle,
  loadingClassName,
  loadingStyle,
  errorClassName,
  errorStyle,
  emptyClassName,
  emptyStyle,
  data,
  error,
  errorTitle,
  emptyTitle,
  showErrorMessage = true,
  isEmpty,
  isLoading,
  loadingContent,
  errorContent,
  emptyContent,
  hasMore,
  autoLoadMore = true,
  loadMore,
  children,
  spinLoadingColor,
}: Props): JSX.Element {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const isError = !!error;
  const isEmptyData = isEmpty ?? (!isError && !isLoading && _.isEmpty(data));
  let status = 'success';
  let content = children;

  if (isLoading) {
    status = 'loading';
    content = (
      <div className={cn(css[status], statusClassName, loadingClassName)} style={{ ...statusStyle, ...loadingStyle }}>
        {loadingContent ?? <SpinLoading color={spinLoadingColor || 'primary'} />}
      </div>
    );
  } else if (isError) {
    status = 'error';
    content = (
      <div className={cn(css[status], statusClassName, errorClassName)} style={{ ...statusStyle, ...errorStyle }}>
        {errorContent ?? (
          <Empty
            image={<BsEmojiFrown />}
            description={
              <div className={css[`${status}_info`]}>
                <div className={css[`${status}_title`]}>{errorTitle ?? 'Something went wrong!'}</div>
                {showErrorMessage && <div className={css[`${status}_message`]}>{error?.message ?? error}</div>}
              </div>
            }
          />
        )}
      </div>
    );
  } else if (isEmptyData) {
    status = 'empty';
    content = (
      <div
        className={cn(css[status], 'status-view-renderer__empty', statusClassName, emptyClassName)}
        style={{ ...statusStyle, ...emptyStyle }}
      >
        {emptyContent ?? <Empty description={emptyTitle ?? 'No data'} />}
      </div>
    );
  }

  return (
    <div className={cn(css.ns_com_status_view_renderer_main, css[`is_${status}`], className)} style={style}>
      {content}

      {/* manual load more */}
      {!autoLoadMore && status === 'success' && _.isBoolean(hasMore) && hasMore && _.isFunction(loadMore) && (
        <Button
          className={css.load_more_btn}
          shape="rounded"
          block
          shadowed
          bordered={false}
          loading={isLoadingMore}
          loadingText="Loading"
          onClick={async () => {
            setIsLoadingMore(true);
            await loadMore();
            setIsLoadingMore(false);
          }}
        >
          Load More
        </Button>
      )}

      {/* infinite scroll load more */}
      {autoLoadMore && status === 'success' && _.isBoolean(hasMore) && hasMore && _.isFunction(loadMore) && (
        <InfiniteScroll
          loadMore={async () => {
            await loadMore();
          }}
          hasMore={hasMore}
        />
      )}
    </div>
  );
}

export default StatusViewRenderer;
