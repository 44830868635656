import { Form, Input, message } from 'antd';
import { Modal as ModalAntd, Switch } from 'antd-mobile';
import cn from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import FormItemEditor from '@/components/FormItemEditor';
import Modal from '@/components/Modal';
import useDeleteSellerCollection from '@/hooks/queries/useDeleteSellerCollection';
import useUpdateSellerCollection from '@/hooks/queries/useUpdateSellerCollection';
import useDesktop from '@/hooks/useDesktop';

import css from './index.module.scss';

const { Item } = Form;

type CompHandle = {
  show: () => void;
};

type CompProps = {
  collection: {
    id: string;
    is_public: boolean;
    title: string;
    is_default: string;
    body_html: string;
  };
};

const queryParams = {
  page_size: 30,
};

const confirmModalTitle = 'Delete Board';
const confirmModalContent = (boardName) => (
  <div style={{ textAlign: 'center' }}>
    Are you sure you want to delete board <br />
    <strong>{boardName}</strong>?
  </div>
);

const App: React.ForwardRefRenderFunction<CompHandle, CompProps> = ({ collection }, forwardedRef) => {
  const isDesktop = useDesktop();
  const updateCollection = useUpdateSellerCollection(queryParams);
  const deleteCollection = useDeleteSellerCollection(queryParams);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { id, title, is_public, is_default, body_html } = collection;

  const isDefaultLikeBoard = !!is_default;
  const disabledTitleInput = isDefaultLikeBoard;
  const showDeleteButton = !isDefaultLikeBoard;
  const initialValues = {
    title,
    is_public,
    body_html,
  };

  const onClose = () => setVisible(false);

  const onAfterClose = () => {
    form.resetFields();
  };

  const onAfterShow = () => {
    if (!isDesktop) {
      window.scrollTo(0, 200);
    }
  };

  const handleSave = async () => {
    form.validateFields().then(async (values) => {
      if (updateCollection.isLoading) return;
      setLoading(true);
      try {
        const formdata = values || {};
        await updateCollection.mutateAsync({ id, ...formdata });
        message.success('Edit Board Success!');
        onClose();
      } catch (error) {
        message.error(
          (error as Error)?.message === 'DUPLICATED_TITLE'
            ? 'Edit Board Failure: You already have a board with that name!'
            : (error as Error)?.message || 'Edit Board Failure!'
        );
      } finally {
        setLoading(false);
      }
    });
  };

  const handleDeleteCollection = async () => {
    if (deleteCollection.isLoading) return;

    try {
      await deleteCollection.mutateAsync({ id });
      setVisible(false);
      message.success('Delete Board Success!');
      navigate(-1);
    } catch (error) {
      message.error((error as Error)?.message || 'Delete Board Failure!');
    }
  };

  const openDeleteDialog = () => {
    ModalAntd.alert({
      className: 'ns_com_modal_for_confirm',
      title: confirmModalTitle,
      content: confirmModalContent(title),
      showCloseButton: true,
      confirmText: 'Confirm',
      onConfirm() {
        handleDeleteCollection();
      },
    });
  };

  useImperativeHandle(forwardedRef, () => ({
    show: () => {
      setVisible(true);
    },
  }));

  useEffect(() => {
    form.setFieldsValue({
      title: collection.title,
      is_public: collection.is_public,
      body_html: collection.body_html,
    });
  }, [form, collection]);

  return (
    <Modal
      visible={visible}
      className={cn(css.ns_com_like_board_modal_main)}
      title="Edit Board"
      onClose={onClose}
      // fix ios safari scroll issue
      afterShow={onAfterShow}
      afterClose={onAfterClose}
      footer={
        <div className={cn(css.ns_com_like_board_modal, css.btn_wrapper)}>
          {showDeleteButton && (
            <Button color="default" shape="rounded" loading={loading} disabled={loading} onClick={openDeleteDialog}>
              Delete Board
            </Button>
          )}
          <Button color="main" shape="rounded" loading={loading} disabled={loading} onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        colon={false}
        initialValues={initialValues}
        layout="vertical"
        className={cn(css.modal_like_board_edit, css.form_wrapper)}
      >
        <Item name="title" label="Board Name" rules={[{ required: true }]}>
          <Input
            className={css.input}
            size="large"
            allowClear
            disabled={disabledTitleInput}
            placeholder="Summer Styles"
          />
        </Item>
        <Item name="body_html" label="Board Description">
          <FormItemEditor
            className={css.ck_editor}
            disabled={disabledTitleInput}
            placeholder="Fun, warm, loving styles for the summer"
          />
        </Item>
        <div className={css.toggle_public}>
          <span className={css.public_title}>Make this board public</span>
          <Item name="is_public" label="Make this board public" valuePropName="checked" noStyle>
            <Switch className={css.input} />
          </Item>
        </div>
        <span className={css.public_desc}>Enabling this allows you to share this board</span>
      </Form>
    </Modal>
  );
};

const SellerCollectionTitleEditDialog = forwardRef(App);
export default SellerCollectionTitleEditDialog;
