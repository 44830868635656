import cn from 'classnames';
import jsBarcode from 'jsbarcode';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { CollectionProduct } from '@/types';
import { commonPrefix } from '@/utils/util';

import { DEFAULT_DATA } from './cons';
import css from './Sample.module.scss';

const Sample = ({ data, isDemo = false }: { data?: CollectionProduct; isDemo?: boolean }) => {
  const product = data?.product || DEFAULT_DATA?.product;
  const { variants, master_style, brand } = product;
  const barcodeImgRef = useRef(null);
  const skuVariants = variants.map(({ sku }) => sku);
  const commonSkuPrefix = _.trim(commonPrefix(skuVariants), '-');

  useEffect(() => {
    if (commonSkuPrefix && barcodeImgRef.current) {
      jsBarcode(barcodeImgRef.current, commonSkuPrefix, {
        height: isDemo ? 30 : 40,
        displayValue: false,
        margin: 0,
        width: 2,
      });
    }
  }, [commonSkuPrefix, isDemo]);

  return (
    <div className={cn(css.ns_modal_print_live_helper_sample, { demo: isDemo })}>
      <section className={css.section_wrapper}>
        <span>
          {brand?.name && <span className={css.bold_700}>{brand?.name} </span>}
          <span>
            Style #: <span className={css.bold_700}>{master_style}</span>
          </span>
        </span>
        {commonSkuPrefix && <img ref={barcodeImgRef} alt="" />}
        {commonSkuPrefix && <span className={css.barcode_desc}>{commonSkuPrefix}</span>}
      </section>
    </div>
  );
};

export default Sample;
