import _ from 'lodash';
import { useQuery } from 'react-query';

import { getProductById } from '@/services/ds';

export default function useProduct(id: string, enabled = true) {
  return useQuery(
    ['products', id],
    () => {
      if (_.isNil(id)) throw new Error('missing product id');
      return getProductById(id);
    },
    {
      enabled,
    }
  );
}
