import { useNavigate, useParams } from 'react-router-dom';

import StatusViewRenderer from '@/components/StatusViewRenderer';
import useOrderByName from '@/hooks/queries/useOrderByName';

const App = () => {
  const { orderName } = useParams();
  const navigate = useNavigate();
  const useOrderByNameService = useOrderByName(orderName);
  const id = useOrderByNameService?.data?.data?.order?.id;

  if (id) {
    navigate(`/orders/${id}`, { replace: true });
    return null;
  }

  return (
    <StatusViewRenderer
      error="Order is not exist."
      isLoading={useOrderByNameService.isLoading}
      isEmpty
      statusStyle={{ marginTop: 64 }}
    />
  );
};

export default App;
