/** pre fill tags when import product[s] */
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Product } from '@/types';

type ImportedProduct = {
  product: Product;
  prefill_tags?: string[];
  rel?: { [key: string]: number };
};

const usePreFillTags = (importedProducts: ImportedProduct[]) => {
  const [preFillTags, setPreFillTags] = useState([]);

  useEffect(() => {
    if (importedProducts && importedProducts?.length === 1) {
      const newPrefillTags = importedProducts.reduce((total, cur) => {
        let nowTotal = total;
        if (cur?.prefill_tags?.length) {
          nowTotal = total.concat(cur.prefill_tags);
        }
        return nowTotal;
      }, []);

      setPreFillTags(_.uniq(newPrefillTags));
    }
  }, [importedProducts]);

  return preFillTags;
};

export default usePreFillTags;
