import cn from 'classnames';

import FulfilledByVendorIcon from '@/assets/images/fulfilled-by-vendor.svg';
import FulfilledByVendorGreyIcon from '@/assets/images/fulfilled-by-vendor-gray.svg';

import css from './index.module.scss';

export default function FulfillLabel({ product }: { product: any }): JSX.Element {
  return product?.fulfilled_by_vendor ? (
    <div className={cn(css.shipping_label, css.ns_com_fulfill_label)}>
      <img src={FulfilledByVendorIcon} alt="" width={12} height={12} />
      <span>Fulfilled by {product.fulfilled_by || 'vendor'}</span>
    </div>
  ) : (
    <></>
  );
}

export function FulfillLabelGray({ product }: { product: any }): JSX.Element {
  return product?.fulfilled_by_vendor ? (
    <div className={cn(css.shipping_label_gray, css.ns_com_fulfill_label)}>
      <img src={FulfilledByVendorGreyIcon} alt="" width={14} height={14} />
      <span>Fulfilled by {product.fulfilled_by || 'vendor'}</span>
    </div>
  ) : (
    <></>
  );
}
