.arrow_button_box {
  display: flex;
  justify-content: space-between;
  .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn {
    padding-left: 10px;
    cursor: pointer;
  }
}
