import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

// import { getOrders } from '@/services/ds';
import { fetchCategoryForRetail, fetchCategoryForRetailParams } from '@/services/jennifer';

export const useCategoryData = (id: string, params: fetchCategoryForRetailParams) => {
  const { data, error, fetchNextPage, hasNextPage, isLoading, refetch } = useInfiniteQuery(
    ['fetchCategoryForRetail', id, params],
    ({ pageParam = 1 }) => fetchCategoryForRetail(id)({ ...params, page: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination?.has_next ? lastPage.data.pagination.current + 1 : undefined,
    }
  );

  return useMemo(
    () => ({
      data,
      error,
      isLoading,
      hasNextPage,
      refetch,
      fetchNextPage,
    }),
    [data, error, fetchNextPage, hasNextPage, isLoading, refetch]
  );
};
